<ng-container [ngSwitch]="passType">
    <ng-container *ngSwitchCase="'globalPass'">
        <div  *ngIf="!isLogoutGoalSubs && !isLogoutPaymentPage">
            <pass-card
            [pass]="pass"
           (selectedPass)="setSelectedPass($event)"
           [selectedPassId]="selectedPass?.id"
           [cardType]="cardType"
           [passType]="passType"
           [passIndex] = "idx"
           [coldbootParams]="coldbootParams"
           *ngFor=" let pass of passes; let idx = index">
            </pass-card>
            <div class="buy-pass show" *ngIf="selectedPass && selectedPass.title && !isLogoutGoalSubs" [ngClass]="{'show':scrolled && ctaSticky || true,'buy-pass__sticky':!ctaSticky, 'buy-pass--hidden':passType =='goalSubs'}"
             [ngStyle]="{
            paddingBottom : !isLoggedIn && referredData && referredData.isReferredUser ? '2.5rem' : '0.875rem',
            bottom : isMobile ? (stickyFooterHidden ? '0px' : '59px') : 'Initial'}">
            <coupon-code-status
                    *ngIf="!isLogoutGoalSubs && !isLogoutPaymentPage"
                    [hidden]="hiddenCouponCode"
                    [couponType]= "'passPage'"
                    [productType]="passType"
                    [sourceType]="couponStatusSourceType"
                    [productIds]="productIds"
                    [isAppliedCouponValidOnPass]="selectedPass?.isCouponApplied"
                    [productDetails]="productDetails"
                    [couponModalType]="couponModalType"
                    [goal]="goal"
                    [goalTitle]="goalTitle"
                    (couponApply)="couponApply($event)" ></coupon-code-status>

            <button class="btn btn--cta btn-block"
                    (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass')"
                    cb-await=""
                    cb-await-id="initiatePaymentcta"
                    [attr.cb-await-param]="selectedPass?.id"
                    *ngIf="isServer || !isLoggedIn ||  ( !isServer && (globalPassState && ( (!globalPassState.passExpiring && !globalPassState.passExpired) || globalPassState.passUnpurchased) )) || !globalPassState"
            >
                <ng-container *ngIf="!selectedPass?.isCouponApplied ? selectedPass?.cost <= 0 : selectedPass.afterCouponCost <= 0 ">
                    Claim for free
                </ng-container>
                <ng-container *ngIf="!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0 ">
                    Buy
                    <ng-container *ngIf="passType == 'globalPass'">
                        <span *ngIf="!isServer">{{selectedPass.title}}</span>
                        <span *ngIf="isServer">Testbook</span>
                        Pass Now
                    </ng-container>
                    <ng-container *ngIf="passType == 'goalSubs'">
                        <span *ngIf="!isServer">{{selectedPass.title}}</span>
                        <span *ngIf="isServer">Super</span>
                        Now
                    </ng-container>
                </ng-container>
            </button>

            <button class="btn btn--cta btn-block"
                    (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass')"
                    cb-await=""
                    cb-await-id="initiatePaymentcta"
                    [attr.cb-await-param]="selectedPass?.id"
                    *ngIf="!isServer && isLoggedIn && globalPassState && (globalPassState.passExpired || globalPassState.passExpiring) && !globalPassState.passUnpurchased"
            >
                <ng-container *ngIf="passType == 'globalPass'">
                    {{'RENEW_YOUR_PASS' | translate }}
                </ng-container>
                <ng-container *ngIf="passType == 'goalSubs'">
                    {{'RENEW_YOUR_SUPER_COACHING' | translate }}
                </ng-container>
            </button>

            <div class="buy-pass__referral" *ngIf="!isLoggedIn && isMobile && referredData && referredData.isReferredUser">
                <span>{{'REFERRAL_EXTRA_DISCOUNT' | translate : {'referralDiscount':referralDiscount, 'referredData.referrer.name':referredData.referrer.name} }}</span>
            </div>
        </div>
        <kind-offer
                (buyKindOfferPass)="buyKindOfferPass($event)"
        ></kind-offer>
        </div>

        <div *ngIf="isLogoutGoalSubs && isLogoutPaymentPage && !isMobile">

            <div class="plan-page {{cssModifierClass}}">
                <div>
                    <div class="h3 plan-page__form-title">Fill Your Details</div>
                    <form [formGroup]="logoutPaymentInfo" class="plan-page__form">
                        <div class="form-group">
                            <img  src="./assets/img/login-signup/person-outline.svg" alt="pass-saving" width="20" height="20">
                            <input id="name" type="text" formControlName="name" class="form-control" placeholder="Enter your name" required>
                        </div>
                        <div class="form-group">
                            <img  src="./assets/img/login-signup/mobile-outline.svg" alt="pass-saving" width="20" height="20">
                            <input id="phone" type="tel" formControlName="phone" class="form-control" placeholder="Enter your mobile no." required>
                        </div>
                        <div *ngIf="isMobile && isLogoutPaymentPage">
                            <button class="btn btn--cta btn-block"
                            (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass', '', '')"
                            cb-await=""
                            cb-await-id="initiatePaymentcta"
                            [attr.cb-await-param]="selectedPass?.id"
                            *ngIf="selectedPass && isServer || !isLoggedIn ||  ( !isServer && (globalPassState && ( (!globalPassState.passExpiring && !globalPassState.passExpired) || globalPassState.passUnpurchased) )) || !globalPassState" style="margin-bottom: 12px">
                                <ng-container *ngIf="!selectedPass?.isCouponApplied ? selectedPass?.cost <= 0 : selectedPass.afterCouponCost <= 0 ">
                                    Claim for free
                                </ng-container>
                                <ng-container *ngIf="(!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0) && isLogoutGoalSubs ">
                                    Proceed to Payment
                                </ng-container>
                            </button>
                            <div class="goal-help-box">
                                <img src="/assets/img/select/lock-grey.svg" alt="lock"> Secure Transaction
                            </div>
                        </div>
                    </form>
                </div>
                <div class="plan-page__separator"></div>
                <div class="plan-page__action card">
                    <div  *ngIf="isLogoutGoalSubs && isLogoutPaymentPage" class="plan-page__header">
                        <strong>Your Plan</strong>
                        <strong class="text-link" (click)="changePlan($event)" >Change Plan</strong>
                    </div>
                    <pass-card
                    *ngIf="selectedPass"
                    [pass]="selectedPass"
                    (selectedPass)="setSelectedPass($event)"
                    [selectedPassId]="selectedPass?.id"
                    [cardType]="cardType"
                    [passType]="passType"
                    [coldbootParams]="coldbootParams"
                    [isLogoutPaymentPage]="isLogoutPaymentPage">
                    </pass-card>
                    <coupon-code-status
                            [hidden]="true"
                            [ngClass]="{'d-none':passType=='goalSubs'}"
                            [couponType]= "'passPage'"
                            [productType]="passType"
                            [sourceType]="couponStatusSourceType"
                            [productIds]="productIds"
                            [isAppliedCouponValidOnPass]="selectedPass?.isCouponApplied"
                            [productDetails]="productDetails"
                            [couponModalType]="couponModalType"
                            [goal]="goal"
                            [goalTitle]="goalTitle"
                            (couponApply)="couponApply($event)"  style="display: none"></coupon-code-status>

                    <price-breakdown [isLogoutGoalSubs]="isLogoutGoalSubs" [passType]="passType" [priceBreakDownObj]="priceBreakDownObj" [showTBPass]="showTBPass" [cssModifierClass]="'pass-details--logout-purchase'"></price-breakdown>

                    <button class="btn btn--cta btn-block"
                            (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass', '', '')"
                            cb-await=""
                            cb-await-id="initiatePaymentcta"
                            [attr.cb-await-param]="selectedPass?.id"
                            *ngIf="selectedPass && isServer || !isLoggedIn ||  ( !isServer && (globalPassState && ( (!globalPassState.passExpiring && !globalPassState.passExpired) || globalPassState.passUnpurchased) )) || !globalPassState">
                        <ng-container *ngIf="!selectedPass?.isCouponApplied ? selectedPass?.cost <= 0 : selectedPass.afterCouponCost <= 0 ">
                            Claim for free
                        </ng-container>
                        <ng-container *ngIf="(!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0) && isLogoutGoalSubs ">
                            Proceed to Payment
                        </ng-container>

                    </button>

                    <div class="goal-help-box">
                        <img src="/assets/img/select/lock-grey.svg" alt="lock"> Secure Transaction
                    </div>
                </div>
            </div>
            <kind-offer (buyKindOfferPass)="buyKindOfferPass($event)"></kind-offer>
        </div>

        <div *ngIf="isLogoutGoalSubs && isLogoutPaymentPage && isMobile">

            <div class="plan-page {{cssModifierClass}}">
                <div  *ngIf="isLogoutGoalSubs && isLogoutPaymentPage" class="plan-page__header">
                    <strong>Your Plan</strong>
                    <strong class="text-link" (click)="changePlan($event)" >Change Plan</strong>
                </div>
                <pass-card
                *ngIf="selectedPass"
                [pass]="selectedPass"
                (selectedPass)="setSelectedPass($event)"
                [selectedPassId]="selectedPass?.id"
                [cardType]="cardType"
                [passType]="passType"
                [coldbootParams]="coldbootParams"
                [isLogoutPaymentPage]="isLogoutPaymentPage">
                </pass-card>
                <coupon-code-status
                        [hidden]="true"
                        [ngClass]="{'d-none':passType=='goalSubs'}"
                        [couponType]= "'passPage'"
                        [productType]="passType"
                        [sourceType]="couponStatusSourceType"
                        [productIds]="productIds"
                        [isAppliedCouponValidOnPass]="selectedPass?.isCouponApplied"
                        [productDetails]="productDetails"
                        [couponModalType]="couponModalType"
                        [goal]="goal"
                        [goalTitle]="goalTitle"
                        (couponApply)="couponApply($event)"  style="display: none"></coupon-code-status>

                <div class="plan-page__separator"></div>
                <div>
                    <div class="plan-page__note" *ngIf="isMobile">SuperCoaching Access is just 1 step away</div>
                    <div class="h3 plan-page__form-title">Fill Your Details</div>
                    <form [formGroup]="logoutPaymentInfo" class="plan-page__form">
                        <div class="form-group">
                            <img  src="./assets/img/login-signup/person-outline.svg" alt="pass-saving" width="20" height="20">
                            <input id="name" type="text" formControlName="name" class="form-control" placeholder="Enter your name" required>
                        </div>
                        <div class="form-group">
                            <img  src="./assets/img/login-signup/mobile-outline.svg" alt="pass-saving" width="20" height="20">
                            <input id="phone" type="tel" formControlName="phone" class="form-control" placeholder="Enter your mobile no." required>
                        </div>
                        <div *ngIf="isMobile && isLogoutPaymentPage">
                            <button class="btn btn--cta btn-block"
                            (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass', '', '')"
                            cb-await=""
                            cb-await-id="initiatePaymentcta"
                            [attr.cb-await-param]="selectedPass?.id"
                            *ngIf="selectedPass && isServer || !isLoggedIn ||  ( !isServer && (globalPassState && ( (!globalPassState.passExpiring && !globalPassState.passExpired) || globalPassState.passUnpurchased) )) || !globalPassState" style="margin-bottom: 12px">
                                <ng-container *ngIf="!selectedPass?.isCouponApplied ? selectedPass?.cost <= 0 : selectedPass.afterCouponCost <= 0 ">
                                    Claim for free
                                </ng-container>
                                <ng-container *ngIf="(!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0) && isLogoutGoalSubs ">
                                    Proceed to Payment
                                </ng-container>
                            </button>
                            <div class="goal-help-box">
                                <img src="/assets/img/select/lock-grey.svg" alt="lock"> Secure Transaction
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <kind-offer (buyKindOfferPass)="buyKindOfferPass($event)"></kind-offer>
        </div>

        <div class="plan-page {{cssModifierClass}}" *ngIf="isLogoutGoalSubs && !isLogoutPaymentPage && !isMobile">
            <div class="plan-page__coupon">
                <div *ngIf="passes?.length">
                    <pass-card [pass]="pass"
                               (selectedPass)="setSelectedPass($event)"
                               [selectedPassId]="selectedPass?.id"
                               [cardType]="cardType"
                               [passType]="passType"
                               [coldbootParams]="coldbootParams"
                               [goal]="goal"
                               [passIndex] = "idx"
                               [hasRecommendedPass]="hasRecommendedPass"
                               *ngFor=" let pass of passes; let idx = index">
                    </pass-card>
                    <kind-offer (buyKindOfferPass)="buyKindOfferPass($event)"></kind-offer>
                    <!-- <div  class="goal-help-box">Need mentor assistance? <span (click)="openRequestCallbackModal()">Request Callback</span></div> -->
                </div>

            </div>

            <div class="plan-page__separator"></div>

            <div class="plan-page__action card">
                <app-coupon-code [passType]="passType" [cssModifierClass]="'offer-state-game'" [onPage]="true" [autoApplyBestCoupon]="recommendedPass?.coupon"></app-coupon-code>
                <coupon-code-status
                        [hidden]="true"
                        [ngClass]="{'d-none':passType=='goalSubs'}"
                        [couponType]= "'passPage'"
                        [productType]="passType"
                        [sourceType]="couponStatusSourceType"
                        [productIds]="productIds"
                        [isAppliedCouponValidOnPass]="selectedPass?.isCouponApplied"
                        [productDetails]="productDetails"
                        [couponModalType]="couponModalType"
                        [goal]="goal"
                        [goalTitle]="goalTitle"
                        (couponApply)="couponApply($event)"  style="display: none"></coupon-code-status>

                <price-breakdown [priceBreakDownObj]="priceBreakDownObj" [showTBPass]="showTBPass" [cssModifierClass]="'pass-details--logout-purchase'"></price-breakdown>

                <button class="btn btn--cta btn-block"
                        (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass', '')"
                        cb-await=""
                        cb-await-id="initiatePaymentcta"
                        [attr.cb-await-param]="selectedPass?.id"
                        *ngIf="selectedPass && isServer || !isLoggedIn ||  ( !isServer && (globalPassState && ( (!globalPassState.passExpiring && !globalPassState.passExpired) || globalPassState.passUnpurchased) )) || !globalPassState">
                    <ng-container *ngIf="!selectedPass?.isCouponApplied ? selectedPass?.cost <= 0 : selectedPass.afterCouponCost <= 0 ">
                        Claim for free
                    </ng-container>
                    <ng-container *ngIf="(!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0) && !isLogoutGoalSubs ">
                        Buy
                        <ng-container *ngIf="passType == 'goalSubs'">
                            <span *ngIf="!isServer">{{selectedPass.title}}</span>
                            <span *ngIf="isServer">Super</span>
                            Now
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="(!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0) && isLogoutGoalSubs ">
                        Continue
                    </ng-container>

                </button>

                <button class="btn btn-block"
                        (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass', '', '')"
                        cb-await=""
                        cb-await-id="initiatePaymentcta"
                        [attr.cb-await-param]="selectedPass?.id"
                        *ngIf="selectedPass && !isServer && isLoggedIn && globalPassState && (globalPassState.passExpired || globalPassState.passExpiring) && !globalPassState.passUnpurchased"
                >
                    <ng-container *ngIf="passType == 'goalSubs'">
                        {{'RENEW_YOUR_SUPER_COACHING' | translate }}
                    </ng-container>
                </button>

                <div class="buy-pass__referral" *ngIf="!isLoggedIn && isMobile && referredData && referredData.isReferredUser">
                    <span>{{'REFERRAL_EXTRA_DISCOUNT' | translate : {'referralDiscount':referralDiscount, 'referredData.referrer.name':referredData.referrer.name} }}</span>
                </div>

                <div class="goal-help-box">
                    <img src="/assets/img/select/lock-grey.svg" alt="lock"> Secure Transaction
                </div>
            </div>
        </div>

        <div class="plan-page {{cssModifierClass}}" *ngIf="isLogoutGoalSubs && !isLogoutPaymentPage && isMobile">
            <div class="plan-page__coupon">

                <app-coupon-code [passType]="passType" [onPage]="true" [autoApplyBestCoupon]="recommendedPass?.coupon"></app-coupon-code>

                <coupon-code-status
                        [hidden]="true"
                        [ngClass]="{'d-none':passType=='goalSubs'}"
                        [couponType]= "'passPage'"
                        [productType]="passType"
                        [sourceType]="couponStatusSourceType"
                        [productIds]="productIds"
                        [isAppliedCouponValidOnPass]="selectedPass?.isCouponApplied"
                        [productDetails]="productDetails"
                        [couponModalType]="couponModalType"
                        [goal]="goal"
                        [goalTitle]="goalTitle"
                        (couponApply)="couponApply($event)"  style="display: none"></coupon-code-status>


            </div>

            <div class="plan-page__separator"></div>

            <div >
                        <div *ngIf="passes?.length" class="pass-cards--logout-purchase--scrollable">
                            <pass-card [pass]="pass"
                                       (selectedPass)="setSelectedPass($event)"
                                       [selectedPassId]="selectedPass?.id"
                                       [cardType]="cardType"
                                       [passType]="passType"
                                       [coldbootParams]="coldbootParams"
                                       [goal]="goal"
                                       [hasRecommendedPass]="hasRecommendedPass"
                                       [passIndex] = "idx"
                                       *ngFor=" let pass of passes; let idx = index">
                            </pass-card>
                            <kind-offer (buyKindOfferPass)="buyKindOfferPass($event)"></kind-offer>
                            <!-- <div  class="goal-help-box">Need mentor assistance? <span (click)="openRequestCallbackModal()">Request Callback</span></div> -->
                        </div>

                <price-breakdown [isLogoutGoalSubs]="isLogoutGoalSubs" [passType]="passType" [priceBreakDownObj]="priceBreakDownObj" [showTBPass]="showTBPass" [cssModifierClass]="'pass-details--logout-purchase'"></price-breakdown>

                <div style="position:fixed;bottom:0;left:0;right:0;" class="card">
                    <button class="btn btn--cta btn-block"
                            (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass', '', '')"
                            cb-await=""
                            cb-await-id="initiatePaymentcta"
                            [attr.cb-await-param]="selectedPass?.id"
                            *ngIf="selectedPass && isServer || !isLoggedIn ||  ( !isServer && (globalPassState && ( (!globalPassState.passExpiring && !globalPassState.passExpired) || globalPassState.passUnpurchased) )) || !globalPassState">
                        <ng-container *ngIf="!selectedPass?.isCouponApplied ? selectedPass?.cost <= 0 : selectedPass.afterCouponCost <= 0 ">
                            Claim for free
                        </ng-container>
                        <ng-container *ngIf="(!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0) && !isLogoutGoalSubs ">
                            Buy
                            <ng-container *ngIf="passType == 'goalSubs'">
                                <span *ngIf="!isServer">{{selectedPass.title}}</span>
                                <span *ngIf="isServer">Super</span>
                                Now
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="(!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0) && isLogoutGoalSubs ">
                            Continue
                        </ng-container>

                    </button>
                </div>

                <button class="btn btn-block"
                        (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass', '', '')"
                        cb-await=""
                        cb-await-id="initiatePaymentcta"
                        [attr.cb-await-param]="selectedPass?.id"
                        *ngIf="selectedPass && !isServer && isLoggedIn && globalPassState && (globalPassState.passExpired || globalPassState.passExpiring) && !globalPassState.passUnpurchased"
                >
                    <ng-container *ngIf="passType == 'goalSubs'">
                        {{'RENEW_YOUR_SUPER_COACHING' | translate }}
                    </ng-container>
                </button>

                <div class="buy-pass__referral" *ngIf="!isLoggedIn && isMobile && referredData && referredData.isReferredUser">
                    <span>{{'REFERRAL_EXTRA_DISCOUNT' | translate : {'referralDiscount':referralDiscount, 'referredData.referrer.name':referredData.referrer.name} }}</span>
                </div>

                <div class="goal-help-box">
                    <img src="/assets/img/select/lock-grey.svg" alt="lock"> Secure Transaction
                </div>
            </div>
        </div>

    </ng-container>

    <!--    Pass Pro Markup    -->
    <ng-container *ngSwitchCase="'passPro'">
        <div class="pass-card-pro" *ngIf="!isLogoutGoalSubs && !isLogoutPaymentPage">
            <div class="h3 desktop">Pick your plan</div>
            <div *ngIf="hasPassAccess && !hasProAccess && announcementPitch" class="strip-pro">
                <img src="/assets/img/pass-new/pass-pro-yello-strip-icon.svg" alt="icon">
                {{announcementPitch}}
            </div>
            <pass-card
                    [pass]="pass"
                    (selectedPass)="setSelectedPass($event)"
                    [selectedPassId]="selectedPass?.id"
                    [cardType]="cardType"
                    [passType]="passType"
                    [coldbootParams]="coldbootParams"
                    [passIndex] = "idx"
                    *ngFor=" let pass of passes; let idx = index" [cssModifierClass]="'card--pass-pro'">
            </pass-card>
            <div class="buy-pass show" *ngIf="selectedPass && selectedPass.title && !isLogoutGoalSubs" [ngClass]="{'show':scrolled && ctaSticky || true,'buy-pass__sticky':!ctaSticky, 'buy-pass--hidden':passType =='goalSubs'}"
                 [ngStyle]="{
            paddingBottom : !isLoggedIn && referredData && referredData.isReferredUser ? '2.5rem' : '0.875rem',
            bottom : isMobile ? (stickyFooterHidden ? '0px' : '59px') : 'Initial'}">
                <coupon-code-status
                        *ngIf="!isLogoutGoalSubs && !isLogoutPaymentPage"
                        [hidden]="hiddenCouponCode"
                        [couponType]= "'passPage'"
                        [productType]="passType"
                        [sourceType]="couponStatusSourceType"
                        [productIds]="productIds"
                        [isAppliedCouponValidOnPass]="selectedPass?.isCouponApplied"
                        [productDetails]="productDetails"
                        [couponModalType]="couponModalType"
                        [goal]="goal"
                        [goalTitle]="goalTitle"
                        (couponApply)="couponApply($event)"></coupon-code-status>

                <button class="btn btn--cta btn-block"
                        (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass')"
                        cb-await=""
                        cb-await-id="initiatePaymentcta"
                        [attr.cb-await-param]="selectedPass?.id"
                        *ngIf="isServer || !isLoggedIn ||  ( !isServer && (globalPassState && ( (!globalPassState.passExpiring && !globalPassState.passExpired) || globalPassState.passUnpurchased) )) || !globalPassState"
                >
                    <ng-container *ngIf="!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0 ">{{passProBuyCtaText}}</ng-container>
                    <ng-container *ngIf="!selectedPass?.isCouponApplied ? selectedPass?.cost <= 0 : selectedPass.afterCouponCost <= 0 ">Upgrade for Free</ng-container>
                </button>

                <button class="btn btn--cta btn-block"
                        (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass')"
                        cb-await=""
                        cb-await-id="initiatePaymentcta"
                        [attr.cb-await-param]="selectedPass?.id"
                        *ngIf="!isServer && isLoggedIn && globalPassState && (globalPassState.passExpired || globalPassState.passExpiring) && !globalPassState.passUnpurchased"                 >
                </button>

                <div class="buy-pass__referral" *ngIf="!isLoggedIn && isMobile && referredData && referredData.isReferredUser">
                    <span>{{'REFERRAL_EXTRA_DISCOUNT' | translate : {'referralDiscount':referralDiscount, 'referredData.referrer.name':referredData.referrer.name} }}</span>
                </div>
            </div>
            <kind-offer (buyKindOfferPass)="buyKindOfferPass($event)"></kind-offer>
        </div>
        <!--Logout Purchase Flow Pass Pro for Web Stage 2-->
        <div *ngIf="isLogoutGoalSubs && isLogoutPaymentPage && !isMobile">

            <div class="plan-page {{cssModifierClass}}">
                <div>
                    <div class="h3 plan-page__form-title">Fill Your Details</div>
                    <form [formGroup]="logoutPaymentInfo" class="plan-page__form">
                        <div class="form-group">
                            <img  src="./assets/img/login-signup/person-outline.svg" alt="pass-saving" width="20" height="20">
                            <input id="name" type="text" formControlName="name" class="form-control" placeholder="Enter your name" required>
                        </div>
                        <div class="form-group">
                            <img  src="./assets/img/login-signup/mobile-outline.svg" alt="pass-saving" width="20" height="20">
                            <input id="phone" type="tel" formControlName="phone" class="form-control" placeholder="Enter your mobile no." required>
                        </div>
                        <div *ngIf="isMobile && isLogoutPaymentPage">
                            <button class="btn btn--cta btn-block"
                                    (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass', '', '')"
                                    cb-await=""
                                    cb-await-id="initiatePaymentcta"
                                    [attr.cb-await-param]="selectedPass?.id"
                                    *ngIf="selectedPass && isServer || !isLoggedIn ||  ( !isServer && (globalPassState && ( (!globalPassState.passExpiring && !globalPassState.passExpired) || globalPassState.passUnpurchased) )) || !globalPassState" style="margin-bottom: 12px">
                                <ng-container *ngIf="!selectedPass?.isCouponApplied ? selectedPass?.cost <= 0 : selectedPass.afterCouponCost <= 0 ">
                                    Claim for free
                                </ng-container>
                                <ng-container *ngIf="(!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0) && isLogoutGoalSubs ">
                                    Proceed to Payment
                                </ng-container>
                            </button>
                            <div class="goal-help-box">
                                <img src="/assets/img/select/lock-grey.svg" alt="lock"> Secure Transaction
                            </div>
                        </div>
                    </form>
                </div>
                <div class="plan-page__separator"></div>
                <div class="plan-page__action card">
                    <div  *ngIf="isLogoutGoalSubs && isLogoutPaymentPage" class="plan-page__header">
                        <strong>Your Plan</strong>
                        <strong class="text-link" (click)="changePlan($event)" >Change Plan</strong>
                    </div>
                    <pass-card
                            *ngIf="selectedPass"
                            [pass]="selectedPass"
                            (selectedPass)="setSelectedPass($event)"
                            [selectedPassId]="selectedPass?.id"
                            [cardType]="cardType"
                            [passType]="passType"
                            [coldbootParams]="coldbootParams"
                            [isLogoutPaymentPage]="isLogoutPaymentPage" [cssModifierClass]="'card--pass-pro'">
                    </pass-card>
                    <coupon-code-status
                            [hidden]="true"
                            [ngClass]="{'d-none':passType=='goalSubs'}"
                            [couponType]= "'passPage'"
                            [productType]="passType"
                            [sourceType]="couponStatusSourceType"
                            [productIds]="productIds"
                            [isAppliedCouponValidOnPass]="selectedPass?.isCouponApplied"
                            [productDetails]="productDetails"
                            [couponModalType]="couponModalType"
                            [goal]="goal"
                            [goalTitle]="goalTitle"
                            (couponApply)="couponApply($event)"  style="display: none"></coupon-code-status>

                    <price-breakdown [isLogoutGoalSubs]="isLogoutGoalSubs" [passType]="passType" [priceBreakDownObj]="priceBreakDownObj" [showTBPass]="showTBPass" [cssModifierClass]="'pass-details--logout-purchase'"></price-breakdown>

                    <button class="btn btn--cta btn-block"
                            (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass', '', '')"
                            cb-await=""
                            cb-await-id="initiatePaymentcta"
                            [attr.cb-await-param]="selectedPass?.id"
                            *ngIf="selectedPass && isServer || !isLoggedIn ||  ( !isServer && (globalPassState && ( (!globalPassState.passExpiring && !globalPassState.passExpired) || globalPassState.passUnpurchased) )) || !globalPassState">
                        <ng-container *ngIf="!selectedPass?.isCouponApplied ? selectedPass?.cost <= 0 : selectedPass.afterCouponCost <= 0 ">
                            Claim for free
                        </ng-container>
                        <ng-container *ngIf="(!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0) && isLogoutGoalSubs ">
                            Proceed to Payment
                        </ng-container>

                    </button>

                    <div class="goal-help-box">
                        <img src="/assets/img/select/lock-grey.svg" alt="lock"> Secure Transaction
                    </div>
                </div>
            </div>
        </div>
        <!--Logout Purchase Flow Pass Pro for Web Stage 1-->
        <div class="plan-page {{cssModifierClass}}" *ngIf="isLogoutGoalSubs && !isLogoutPaymentPage && !isMobile">
            <div class="plan-page__coupon">
                <div *ngIf="passes?.length">
                    <pass-card [pass]="pass"
                               (selectedPass)="setSelectedPass($event)"
                               [selectedPassId]="selectedPass?.id"
                               [cardType]="cardType"
                               [passType]="passType"
                               [coldbootParams]="coldbootParams"
                               [goal]="goal"
                               [hasRecommendedPass]="hasRecommendedPass"
                               [passIndex] = "idx"
                               *ngFor=" let pass of passes; let idx = index" [cssModifierClass]="'card--pass-pro'">
                    </pass-card>
                    <kind-offer (buyKindOfferPass)="buyKindOfferPass($event)"></kind-offer>
                    <!-- <div  class="goal-help-box">Need mentor assistance? <span (click)="openRequestCallbackModal()">Request Callback</span></div> -->
                </div>

            </div>

            <div class="plan-page__separator"></div>

            <div class="plan-page__action card">
                <app-coupon-code [passType]="passType" [cssModifierClass]="'offer-state-game'" [onPage]="true" [autoApplyBestCoupon]="recommendedPass?.coupon"></app-coupon-code>
                <coupon-code-status
                        [hidden]="true"
                        [ngClass]="{'d-none':passType=='goalSubs'}"
                        [couponType]= "'passPage'"
                        [productType]="passType"
                        [sourceType]="couponStatusSourceType"
                        [productIds]="productIds"
                        [isAppliedCouponValidOnPass]="selectedPass?.isCouponApplied"
                        [productDetails]="productDetails"
                        [couponModalType]="couponModalType"
                        [goal]="goal"
                        [goalTitle]="goalTitle"
                        (couponApply)="couponApply($event)"  style="display: none"></coupon-code-status>

                <price-breakdown [priceBreakDownObj]="priceBreakDownObj" [showTBPass]="showTBPass" [cssModifierClass]="'pass-details--logout-purchase'"></price-breakdown>

                <button class="btn btn--cta btn-block"
                        (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass', '')"
                        cb-await=""
                        cb-await-id="initiatePaymentcta"
                        [attr.cb-await-param]="selectedPass?.id"
                        *ngIf="selectedPass && isServer || !isLoggedIn ||  ( !isServer && (globalPassState && ( (!globalPassState.passExpiring && !globalPassState.passExpired) || globalPassState.passUnpurchased) )) || !globalPassState">
                    <ng-container *ngIf="!selectedPass?.isCouponApplied ? selectedPass?.cost <= 0 : selectedPass.afterCouponCost <= 0 ">
                        Claim for free
                    </ng-container>
                    <ng-container *ngIf="(!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0) && !isLogoutGoalSubs ">
                        Buy
                        <ng-container *ngIf="passType == 'goalSubs'">
                            <span *ngIf="!isServer">{{selectedPass.title}}</span>
                            <span *ngIf="isServer">Super</span>
                            Now
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="(!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0) && isLogoutGoalSubs ">
                        Continue
                    </ng-container>

                </button>

                <button class="btn btn-block"
                        (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass', '', '')"
                        cb-await=""
                        cb-await-id="initiatePaymentcta"
                        [attr.cb-await-param]="selectedPass?.id"
                        *ngIf="selectedPass && !isServer && isLoggedIn && globalPassState && (globalPassState.passExpired || globalPassState.passExpiring) && !globalPassState.passUnpurchased"
                >
                    <ng-container *ngIf="passType == 'goalSubs'">
                        {{'RENEW_YOUR_SUPER_COACHING' | translate }}
                    </ng-container>
                </button>

                <div class="buy-pass__referral" *ngIf="!isLoggedIn && isMobile && referredData && referredData.isReferredUser">
                    <span>{{'REFERRAL_EXTRA_DISCOUNT' | translate : {'referralDiscount':referralDiscount, 'referredData.referrer.name':referredData.referrer.name} }}</span>
                </div>

                <div class="goal-help-box">
                    <img src="/assets/img/select/lock-grey.svg" alt="lock"> Secure Transaction
                </div>
            </div>
        </div>
        <!--Logout Purchase Flow Pass Pro for mWeb Stage 2-->
        <div *ngIf="isLogoutGoalSubs && isLogoutPaymentPage && isMobile">

            <div class="plan-page {{cssModifierClass}}">
                <div  *ngIf="isLogoutGoalSubs && isLogoutPaymentPage" class="plan-page__header">
                    <strong>Your Plan</strong>
                    <strong class="text-link" (click)="changePlan($event)" >Change Plan</strong>
                </div>
                <pass-card
                        *ngIf="selectedPass"
                        [pass]="selectedPass"
                        (selectedPass)="setSelectedPass($event)"
                        [selectedPassId]="selectedPass?.id"
                        [cardType]="cardType"
                        [passType]="passType"
                        [coldbootParams]="coldbootParams"
                        [isLogoutPaymentPage]="isLogoutPaymentPage" [cssModifierClass]="'card--pass-pro'">
                </pass-card>
                <coupon-code-status
                        [hidden]="true"
                        [ngClass]="{'d-none':passType=='goalSubs'}"
                        [couponType]= "'passPage'"
                        [productType]="passType"
                        [sourceType]="couponStatusSourceType"
                        [productIds]="productIds"
                        [isAppliedCouponValidOnPass]="selectedPass?.isCouponApplied"
                        [productDetails]="productDetails"
                        [couponModalType]="couponModalType"
                        [goal]="goal"
                        [goalTitle]="goalTitle"
                        (couponApply)="couponApply($event)"  style="display: none"></coupon-code-status>

                <div class="plan-page__separator"></div>
                <div>
                    <div class="h3 plan-page__form-title">Fill Your Details</div>
                    <form [formGroup]="logoutPaymentInfo" class="plan-page__form">
                        <div class="form-group">
                            <img  src="./assets/img/login-signup/person-outline.svg" alt="pass-saving" width="20" height="20">
                            <input id="name" type="text" formControlName="name" class="form-control" placeholder="Enter your name" required>
                        </div>
                        <div class="form-group">
                            <img  src="./assets/img/login-signup/mobile-outline.svg" alt="pass-saving" width="20" height="20">
                            <input id="phone" type="tel" formControlName="phone" class="form-control" placeholder="Enter your mobile no." required>
                        </div>
                        <div *ngIf="isMobile && isLogoutPaymentPage">
                            <button class="btn btn--cta btn-block"
                                    (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass', '', '')"
                                    cb-await=""
                                    cb-await-id="initiatePaymentcta"
                                    [attr.cb-await-param]="selectedPass?.id"
                                    *ngIf="selectedPass && isServer || !isLoggedIn ||  ( !isServer && (globalPassState && ( (!globalPassState.passExpiring && !globalPassState.passExpired) || globalPassState.passUnpurchased) )) || !globalPassState" style="margin-bottom: 12px">
                                <ng-container *ngIf="!selectedPass?.isCouponApplied ? selectedPass?.cost <= 0 : selectedPass.afterCouponCost <= 0 ">
                                    Claim for free
                                </ng-container>
                                <ng-container *ngIf="(!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0) && isLogoutGoalSubs ">
                                    Proceed to Payment
                                </ng-container>
                            </button>
                            <div class="goal-help-box">
                                <img src="/assets/img/select/lock-grey.svg" alt="lock"> Secure Transaction
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
        <!--Logout Purchase Flow Pass Pro for mWeb Stage 1-->
        <div class="plan-page {{cssModifierClass}}" *ngIf="isLogoutGoalSubs && !isLogoutPaymentPage && isMobile">
            <div class="plan-page__coupon">

                <app-coupon-code [passType]="passType" [onPage]="true" [autoApplyBestCoupon]="recommendedPass?.coupon"></app-coupon-code>

                <coupon-code-status
                        [hidden]="true"
                        [ngClass]="{'d-none':passType=='goalSubs'}"
                        [couponType]= "'passPage'"
                        [productType]="passType"
                        [sourceType]="couponStatusSourceType"
                        [productIds]="productIds"
                        [isAppliedCouponValidOnPass]="selectedPass?.isCouponApplied"
                        [productDetails]="productDetails"
                        [couponModalType]="couponModalType"
                        [goal]="goal"
                        [goalTitle]="goalTitle"
                        (couponApply)="couponApply($event)"  style="display: none"></coupon-code-status>


            </div>

            <div class="plan-page__separator"></div>

            <div >
                <div *ngIf="passes?.length" class="pass-cards--logout-purchase--scrollable">
                    <pass-card [pass]="pass"
                               (selectedPass)="setSelectedPass($event)"
                               [selectedPassId]="selectedPass?.id"
                               [cardType]="cardType"
                               [passType]="passType"
                               [coldbootParams]="coldbootParams"
                               [goal]="goal"
                               [hasRecommendedPass]="hasRecommendedPass"
                               [passIndex] = "idx"
                               *ngFor=" let pass of passes; let idx = index" [cssModifierClass]="'card--pass-pro'">
                    </pass-card>
                    <kind-offer (buyKindOfferPass)="buyKindOfferPass($event)"></kind-offer>
                    <!-- <div  class="goal-help-box">Need mentor assistance? <span (click)="openRequestCallbackModal()">Request Callback</span></div> -->
                </div>

                <price-breakdown [isLogoutGoalSubs]="isLogoutGoalSubs" [passType]="passType" [priceBreakDownObj]="priceBreakDownObj" [showTBPass]="showTBPass" [cssModifierClass]="'pass-details--logout-purchase'"></price-breakdown>

                <div style="position:fixed;bottom:0;left:0;right:0;" class="card">
                    <button class="btn btn--cta btn-block"
                            (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass', '', '')"
                            cb-await=""
                            cb-await-id="initiatePaymentcta"
                            [attr.cb-await-param]="selectedPass?.id"
                            *ngIf="selectedPass && isServer || !isLoggedIn ||  ( !isServer && (globalPassState && ( (!globalPassState.passExpiring && !globalPassState.passExpired) || globalPassState.passUnpurchased) )) || !globalPassState">
                        <ng-container *ngIf="!selectedPass?.isCouponApplied ? selectedPass?.cost <= 0 : selectedPass.afterCouponCost <= 0 ">
                            Claim for free
                        </ng-container>
                        <ng-container *ngIf="(!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0) && !isLogoutGoalSubs ">
                            Buy
                            <ng-container *ngIf="passType == 'goalSubs'">
                                <span *ngIf="!isServer">{{selectedPass.title}}</span>
                                <span *ngIf="isServer">Super</span>
                                Now
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="(!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0) && isLogoutGoalSubs ">
                            Continue
                        </ng-container>

                    </button>
                </div>

                <button class="btn btn-block"
                        (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass', '', '')"
                        cb-await=""
                        cb-await-id="initiatePaymentcta"
                        [attr.cb-await-param]="selectedPass?.id"
                        *ngIf="selectedPass && !isServer && isLoggedIn && globalPassState && (globalPassState.passExpired || globalPassState.passExpiring) && !globalPassState.passUnpurchased"
                >
                    <ng-container *ngIf="passType == 'goalSubs'">
                        {{'RENEW_YOUR_SUPER_COACHING' | translate }}
                    </ng-container>
                </button>

                <div class="buy-pass__referral" *ngIf="!isLoggedIn && isMobile && referredData && referredData.isReferredUser">
                    <span>{{'REFERRAL_EXTRA_DISCOUNT' | translate : {'referralDiscount':referralDiscount, 'referredData.referrer.name':referredData.referrer.name} }}</span>
                </div>

                <div class="goal-help-box">
                    <img src="/assets/img/select/lock-grey.svg" alt="lock"> Secure Transaction
                </div>
            </div>
        </div>
    </ng-container>

    <!--    plan page layout    -->
    <ng-container *ngSwitchCase="'goalSubs'">
        <ng-container *ngIf="goal">
            <ng-container *ngIf="!isInGoalPageHeader">
                <!--    mobile view -->
                <ng-container *ngIf="isMobile && !isLogoutPaymentPage">
                    <app-coupon-code [passType]="passType" [onPage]="true" [cssModifierClass]="'coupon-offers_state--plan'" [autoApplyBestCoupon]="recommendedPass?.coupon"></app-coupon-code>
                    <coupon-code-status
                            [couponType]= "'passPage'"
                            [productType]="passType"
                            [sourceType]="couponStatusSourceType"
                            [productIds]="productIds"
                            [isAppliedCouponValidOnPass]="selectedPass?.isCouponApplied"
                            [productDetails]="productDetails"
                            [couponModalType]="couponModalType"
                            [goal]="goal"
                            [goalTitle]="goalTitle"
                            (couponApply)="couponApply($event)" style="display: none">
                    </coupon-code-status>

                    <div class="emi-pitch" *ngIf="goal?.properties?.isClientEMIAvailable">
                        <img src="/assets/img/pass-new/rupee-green.svg" alt="rupee">
                        <span style="flex: 1">EMI Available</span>
                        <span style="color: #000">View EMI Plans</span>
                        <label class="emi-toggle" for="emiToggle" (change)="toggleViewEmis()">
                            <input type="checkbox" [checked]="showEMIPlans" id="emiToggle">
                            <span class="emi-toggle__handler"></span>
                        </label>
                    </div>
                    <h3 class="pick-plan">{{!showEMIPlans ? 'Select your plan' : 'Select your EMI plan'}}</h3>
                </ng-container>
                <!--    mobile view -> log out purchase flow -->
                <ng-container *ngIf="isMobile && isLogoutPaymentPage">
                    <div class="plan-page__header">
                        <strong>Your Plan</strong>
                        <strong class="text-link" (click)="changePlan($event)" >Change Plan</strong>
                    </div>
                    <pass-card *ngIf="selectedPass"   [pass]="selectedPass"
                                   (selectedPass)="setSelectedPass($event)"
                                   [selectedPassId]="selectedPass?.id"
                                   [cardType]="cardType"
                                   [passType]="passType"
                                   [coldbootParams]="coldbootParams"
                                   [goal]="goal"
                                   [hasRecommendedPass]="hasRecommendedPass">
                    </pass-card>
                     <div class="saving-amount-strip" *ngIf="priceBreakDownObj?.isCouponApplied">
                        Yay! Coupon is applied. Additional ₹{{priceBreakDownObj?.couponDiscount}} savings
                    </div>
                </ng-container>

                <!--    web view -->
                <div class="plan-page {{cssModifierClass}}" *ngIf="!isLogoutPaymentPage" >
                    <div class="plan-page__coupon">
                        <div *ngIf="passes?.length && goal?.properties?.slug">
                            <ng-container *ngIf="!showEMIPlans">
                                <pass-card [pass]="pass"
                                           (selectedPass)="setSelectedPass($event)"
                                           [selectedPassId]="selectedPass?.id"
                                           [cardType]="cardType"
                                           [passType]="passType"
                                           [coldbootParams]="coldbootParams"
                                           [goal]="goal"
                                           [hasRecommendedPass]="hasRecommendedPass"
                                           [passIndex] = "idx"
                                           [showEMIPlansUI]="false"
                                           *ngFor=" let pass of passes; let idx = index">
                                </pass-card>
                            </ng-container>
                            <ng-container *ngIf="showEMIPlans">
                                <pass-card [pass]="pass"
                                           (selectedPass)="setSelectedPass($event)"
                                           [selectedPassId]="selectedPass?.id"
                                           [cardType]="cardType"
                                           [passType]="passType"
                                           [coldbootParams]="coldbootParams"
                                           [goal]="goal"
                                           [hasRecommendedPass]="hasRecommendedPass"
                                           [passIndex] = "idx"
                                           [showEMIPlansUI]="true"
                                           *ngFor=" let pass of passesWithEmi; let idx = index">
                                </pass-card>
                            </ng-container>
                            <kind-offer (buyKindOfferPass)="buyKindOfferPass($event)"></kind-offer>
                            <div class="goal-help-box">Need mentor assistance? <span (click)="openRequestCallbackModal()">Request Callback</span></div>
                        </div>
                    </div>
                    <div class="plan-page__separator"></div>
                    <div class="plan-page__action card">
                        <app-coupon-code [passType]="passType" [cssModifierClass]="'coupon-offers_state--plan'" [onPage]="true"
                                          [autoApplyBestCoupon]="recommendedPass?.coupon"></app-coupon-code>
                        <coupon-code-status
                                [hidden]="true"
                                [ngClass]="{'d-none':passType=='goalSubs'}"
                                [couponType]= "'passPage'"
                                [productType]="passType"
                                [sourceType]="couponStatusSourceType"
                                [productIds]="productIds"
                                [isAppliedCouponValidOnPass]="selectedPass?.isCouponApplied"
                                [productDetails]="productDetails"
                                [couponModalType]="couponModalType"
                                [goal]="goal"
                                [goalTitle]="goalTitle"
                                (couponApply)="couponApply($event)"  style="display: none"></coupon-code-status>

                        <price-breakdown [showEMIPlans]="showEMIPlans" [priceBreakDownObj]="priceBreakDownObj" [showTBPass]="showTBPass" [cssModifierClass]="'pass-details--logout-purchase'"></price-breakdown>

                        <button class="btn btn--cta btn-block"
                                (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass', '', 'Goal-landing')"
                                cb-await=""
                                cb-await-id="initiatePaymentcta"
                                [attr.cb-await-param]="selectedPass?.id"
                                *ngIf="selectedPass && isServer || !isLoggedIn ||  ( !isServer && (globalPassState && ( (!globalPassState.passExpiring && !globalPassState.passExpired) || globalPassState.passUnpurchased) )) || !globalPassState">
                            <ng-container *ngIf="!selectedPass?.isCouponApplied ? selectedPass?.cost <= 0 : selectedPass.afterCouponCost <= 0 ">
                                Claim for free
                            </ng-container>
                            <ng-container *ngIf="(!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0) && !isLogoutGoalSubs && !showEMIPlans">
                                <ng-container *ngIf="goal?.properties?.isClientEMIAvailable && selectedPass?.emis?.length">
                                    Pay Now - ₹{{priceBreakDownObj.finalAmount}}
                                </ng-container>
                                <ng-container *ngIf="!goal?.properties?.isClientEMIAvailable || !selectedPass?.emis?.length">
                                    Buy
                                    <ng-container *ngIf="passType == 'goalSubs'">
                                        <span *ngIf="!isServer">{{selectedPass.title}}</span>
                                        <span *ngIf="isServer">Super</span>
                                        Now
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="(!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0) && !isLogoutGoalSubs && showEMIPlans">
                               Pay Down Payment - ₹{{priceBreakDownObj?.emiDownPaymentAmount}}
                            </ng-container>
                            <ng-container *ngIf="(!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0) && isLogoutGoalSubs ">
                                Continue
                            </ng-container>

                        </button>

                        <button class="btn btn--cta btn-block"
                                (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass', '', '')"
                                cb-await=""
                                cb-await-id="initiatePaymentcta"
                                [attr.cb-await-param]="selectedPass?.id"
                                *ngIf="selectedPass && !isServer && isLoggedIn && globalPassState && (globalPassState.passExpired || globalPassState.passExpiring) && !globalPassState.passUnpurchased"
                        >
                            <ng-container *ngIf="passType == 'goalSubs'">
                                <ng-container *ngIf="goal?.properties?.isClientEMIAvailable && selectedPass?.emis?.length">
                                    {{showEMIPlans ?  'Pay Down Payment - ₹'+priceBreakDownObj?.emiDownPaymentAmount : 'Pay Now - ₹'+priceBreakDownObj?.finalAmount}}
                                </ng-container>
                                <ng-container *ngIf="!goal?.properties?.isClientEMIAvailable || !selectedPass?.emis?.length">
                                    {{'RENEW_YOUR_SUPER_COACHING' | translate }}
                                </ng-container>
                            </ng-container>
                        </button>

                        <div class="buy-pass__referral" *ngIf="!isLoggedIn && isMobile && referredData && referredData.isReferredUser">
                            <span>{{'REFERRAL_EXTRA_DISCOUNT' | translate : {'referralDiscount':referralDiscount, 'referredData.referrer.name':referredData.referrer.name} }}</span>
                        </div>


                        <ng-container *ngIf="goal?.properties?.isClientEMIAvailable && !showEMIPlans && selectedPass?.emis?.length && !isLogoutGoalSubs">
                            <div class="border-divider"><span>or</span></div>
                            <div class="emi-strip" (click)="initiateEMIPaymentFlow()" *ngIf="!selectedPass?.isCouponApplied">
                                <span class="badge-bg-green">EMI Available</span>
                                <span>Pay {{selectedPass?.emis[0]?.splits}} EMIs of <span class="text-success">₹{{selectedPass?.emis[0]?.payments[0]?.amount}}</span></span>
                            </div>
                            <div class="emi-strip" (click)="initiateEMIPaymentFlow()" *ngIf="selectedPass?.isCouponApplied">
                                <span class="badge-bg-green">EMI Available</span>
                                <span>Pay {{selectedPass?.couponAppliedEmis?.splits || selectedPass?.emis?.[0]?.splits}} EMIs of <span class="text-success">₹{{selectedPass?.couponAppliedEmis?.payments[0]?.amount || priceBreakDownObj?.emiDownPaymentAmount}}</span></span>
                            </div>
                        </ng-container>

                        <div class="goal-help-box">
                            <img src="/assets/img/select/lock-grey.svg" alt="lock"> Secure Transaction
                        </div>
                    </div>
                </div>
                <!-- log out purchase flow second stage -->
                <div class="plan-page {{cssModifierClass}}" *ngIf="isLogoutPaymentPage">
                    <div>
                        <div class="plan-page__note" *ngIf="isMobile">SuperCoaching Access is just 1 step away</div>
                        <div class="h3 plan-page__form-title">Fill Your Details</div>
                        <form [formGroup]="logoutPaymentInfo" class="plan-page__form">
                            <div class="form-group">
                                <img src="./assets/img/login-signup/person-outline.svg" alt="pass-saving" width="20" height="20">
                                <input id="name" type="text" formControlName="name" class="form-control" placeholder="Enter your name" required>
                            </div>
                            <div class="form-group">
                                <img src="./assets/img/login-signup/mobile-outline.svg" alt="pass-saving" width="20" height="20">
                                <input id="phone" type="tel" formControlName="phone" class="form-control" placeholder="Enter your mobile no." required>
                            </div>
                            <div *ngIf="isMobile && isLogoutPaymentPage">
                                <button class="btn btn--cta btn-block"
                                (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass', '', '')"
                                cb-await=""
                                cb-await-id="initiatePaymentcta"
                                [attr.cb-await-param]="selectedPass?.id"
                                *ngIf="selectedPass && isServer || !isLoggedIn ||  ( !isServer && (globalPassState && ( (!globalPassState.passExpiring && !globalPassState.passExpired) || globalPassState.passUnpurchased) )) || !globalPassState" style="margin-bottom: 12px">
                                    <ng-container *ngIf="!selectedPass?.isCouponApplied ? selectedPass?.cost <= 0 : selectedPass.afterCouponCost <= 0 ">
                                        Claim for free
                                    </ng-container>
                                    <ng-container *ngIf="(!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0) && isLogoutGoalSubs ">
                                        Proceed to Payment
                                    </ng-container>
                                </button>
                                <div class="goal-help-box">
                                    <img src="/assets/img/select/lock-grey.svg" alt="lock"> Secure Transaction
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="plan-page__separator"></div>
                    <div class="plan-page__action card">
                        <div  *ngIf="isLogoutGoalSubs && isLogoutPaymentPage" class="plan-page__header">
                            <strong>Your Plan</strong>
                            <strong class="text-link" (click)="changePlan($event)" >Change Plan</strong>
                        </div>
                        <pass-card *ngIf="selectedPass"   [pass]="selectedPass"
                                       (selectedPass)="setSelectedPass($event)"
                                       [selectedPassId]="selectedPass?.id"
                                       [cardType]="cardType"
                                       [passType]="passType"
                                       [coldbootParams]="coldbootParams"
                                       [goal]="goal"
                                       [hasRecommendedPass]="hasRecommendedPass">
                        </pass-card>
                        <coupon-code-status
                                [hidden]="true"
                                [ngClass]="{'d-none':passType=='goalSubs'}"
                                [couponType]= "'passPage'"
                                [productType]="passType"
                                [sourceType]="couponStatusSourceType"
                                [productIds]="productIds"
                                [isAppliedCouponValidOnPass]="selectedPass?.isCouponApplied"
                                [productDetails]="productDetails"
                                [couponModalType]="couponModalType"
                                [goal]="goal"
                                [goalTitle]="goalTitle"
                                (couponApply)="couponApply($event)"  style="display: none"></coupon-code-status>

                        <price-breakdown [priceBreakDownObj]="priceBreakDownObj" [showTBPass]="showTBPass" [cssModifierClass]="'pass-details--logout-purchase'"></price-breakdown>

                        <button class="btn btn--cta btn-block"
                                (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass', '', '')"
                                cb-await=""
                                cb-await-id="initiatePaymentcta"
                                [attr.cb-await-param]="selectedPass?.id"
                                *ngIf="selectedPass && isServer || !isLoggedIn ||  ( !isServer && (globalPassState && ( (!globalPassState.passExpiring && !globalPassState.passExpired) || globalPassState.passUnpurchased) )) || !globalPassState">
                            <ng-container *ngIf="!selectedPass?.isCouponApplied ? selectedPass?.cost <= 0 : selectedPass.afterCouponCost <= 0 ">
                                Claim for free
                            </ng-container>
                            <ng-container *ngIf="(!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0) && isLogoutGoalSubs ">
                                Proceed to Payment
                            </ng-container>
                        </button>
                        <div class="goal-help-box">
                            <img src="/assets/img/select/lock-grey.svg" alt="lock"> Secure Transaction
                        </div>
                    </div>
                </div>

                <!--    sticky footer   -->
                <div class="card card--plan-sticky" *ngIf="isMobile && !isLogoutPaymentPage && !hideStickyPlan" [ngClass]="{'sticky-goal-page': passType == 'goalSubs' && !scrolled && false, 'isLoggedOut':isLogoutGoalSubs, 'force-bottom-sticky': isMGoal}" [hidden]="isWhatsappFlow">
                    <div *ngIf="priceBreakDownObj?.totalSavings > 0" class="btn-success-gradient">Your total savings: <b>₹{{priceBreakDownObj?.totalSavings}}</b></div>
                    <div class="card__body">
                        <ng-container *ngIf="!showEMIPlans">
                            <h2><span>₹{{priceBreakDownObj?.price}}</span>₹{{priceBreakDownObj?.finalAmount}}</h2>
                        </ng-container>
                        <ng-container *ngIf="showEMIPlans">
                            <h2><span>₹{{priceBreakDownObj.finalAmount}}</span>₹{{priceBreakDownObj?.emiDownPaymentAmount}}</h2>
                        </ng-container>
                        <button class="btn btn--cta btn-block"
                                (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass', '', '')"
                                cb-await=""
                                cb-await-id="initiatePaymentcta"
                                [attr.cb-await-param]="selectedPass?.id"
                                *ngIf="selectedPass && isServer || !isLoggedIn ||  ( !isServer && (globalPassState && ( (!globalPassState.passExpiring && !globalPassState.passExpired) || globalPassState.passUnpurchased) )) || !globalPassState">
                            <ng-container *ngIf="!selectedPass?.isCouponApplied ? selectedPass?.cost <= 0 : selectedPass.afterCouponCost <= 0 ">
                                Claim for free
                            </ng-container>
                            <ng-container *ngIf="(!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0) && !isLogoutGoalSubs && !showEMIPlans">
                                <ng-container *ngIf="goal?.properties?.isClientEMIAvailable && selectedPass?.emis?.length">
                                    Pay Now - ₹{{priceBreakDownObj.finalAmount}}
                                </ng-container>
                                <ng-container *ngIf="!goal?.properties?.isClientEMIAvailable || !selectedPass?.emis?.length">
                                    Buy
                                    <ng-container *ngIf="passType == 'goalSubs'">
                                        <span *ngIf="!isServer">{{selectedPass.title}}</span>
                                        <span *ngIf="isServer">Super</span>
                                        Now
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="(!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0) && !isLogoutGoalSubs && showEMIPlans">
                               Pay Down Payment - ₹{{priceBreakDownObj?.emiDownPaymentAmount}}
                            </ng-container>
                            <ng-container *ngIf="(!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0) && isLogoutGoalSubs && !isLogoutPaymentPage">
                                Continue
                            </ng-container>
                        </button>
                        <button class="btn btn--cta btn-block"
                                *ngIf="selectedPass && !isServer && isLoggedIn && globalPassState && (globalPassState.passExpired || globalPassState.passExpiring) && !globalPassState.passUnpurchased"
                                (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass', '', '')"
                                cb-await=""
                                cb-await-id="initiatePaymentcta"
                                [attr.cb-await-param]="selectedPass?.id">
                            <ng-container *ngIf="passType == 'goalSubs' && !goal?.properties?.isClientEMIAvailable">
                                {{'RENEW_YOUR_SUPER_COACHING' | translate }}
                            </ng-container>
                            <ng-container *ngIf="passType == 'goalSubs' && goal?.properties?.isClientEMIAvailable">
                                Pay Now - ₹{{showEMIPlans ? priceBreakDownObj?.emiDownPaymentAmount : priceBreakDownObj?.finalAmount}}
                            </ng-container>
                        </button>
                    </div>
                    <ng-container *ngIf="goal?.properties?.isClientEMIAvailable && !showEMIPlans && selectedPass?.emis?.length && !isLogoutGoalSubs">
                        <div class="emi-strip emi-strip--sticky" (click)="initiateEMIPaymentFlow()" *ngIf="!selectedPass?.isCouponApplied">
                            <span class="badge-bg-green">EMI Available</span>
                            <span>Pay {{selectedPass?.emis[0]?.splits}} EMIs of <span class="text-success">₹{{selectedPass?.emis[0]?.payments[0]?.amount}}</span></span>
                        </div>
                        <div class="emi-strip emi-strip--sticky" (click)="initiateEMIPaymentFlow()" *ngIf="selectedPass?.isCouponApplied">
                            <span class="badge-bg-green">EMI Available</span>
                            <span>Pay {{selectedPass?.couponAppliedEmis?.splits || selectedPass?.emis?.[0]?.splits}} EMIs of <span class="text-success">₹{{selectedPass?.couponAppliedEmis?.payments[0]?.amount || priceBreakDownObj?.emiDownPaymentAmount}}</span></span>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="isInGoalPageHeader">
                <div class="goal-page">
                    <app-coupon-code [passType]="passType" [onPage]="true" [autoApplyBestCoupon]="recommendedPass?.coupon"></app-coupon-code>
                    <coupon-code-status
                            [hidden]="true"
                            [ngClass]="{'d-none':passType=='goalSubs'}"
                            [couponType]= "'passPage'"
                            [productType]="passType"
                            [sourceType]="couponStatusSourceType"
                            [productIds]="productIds"
                            [isAppliedCouponValidOnPass]="selectedPass?.isCouponApplied"
                            [productDetails]="productDetails"
                            [couponModalType]="couponModalType"
                            [goal]="goal"
                            [goalTitle]="goalTitle"
                            (couponApply)="couponApply($event)"  style="display: none"></coupon-code-status>
                    <div class="h4">Select your plan <a class="view-plan" [href]="plansPageUrl">See All Plans</a></div>
                    <pass-card [pass]="pass"
                               (selectedPass)="setSelectedPass($event)"
                               [selectedPassId]="selectedPass?.id"
                               [cardType]="cardType"
                               [passType]="passType"
                               [coldbootParams]="coldbootParams"
                               [goal]="goal"
                               [hasRecommendedPass]="hasRecommendedPass"
                               [passIndex] = "idx"
                               [showEMIPlansUI]="pass?.emis?.length"
                               *ngFor=" let pass of passes; let idx = index">
                    </pass-card>
                    <div>
                        <ng-container *ngIf="goal?.properties?.isClientEMIAvailable && selectedPass?.emis?.length">
                            <div class="emi-strip" (click)="initiateEMIPaymentFlow()" *ngIf="!selectedPass?.isCouponApplied">
                                <span class="badge-bg-green">EMI Available</span>
                                <span>Pay {{selectedPass?.emis[0]?.splits}} EMIs of <span class="text-success">₹{{selectedPass?.emis[0]?.payments[0]?.amount}}</span></span>
                            </div>
                            <div class="emi-strip" (click)="initiateEMIPaymentFlow()" *ngIf="selectedPass?.isCouponApplied">
                                <span class="badge-bg-green">EMI Available</span>
                                <span>Pay {{selectedPass?.couponAppliedEmis?.splits}} EMIs of <span class="text-success">₹{{selectedPass?.couponAppliedEmis?.payments[0]?.amount}}</span></span>
                            </div>
                            <div class="border-divider"><span>or</span></div>
                        </ng-container>
                        <button class="btn btn--cta btn-block"
                                (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass', '', 'LowAOV-Firstfold')"
                                cb-await=""
                                cb-await-id="initiatePaymentcta"
                                [attr.cb-await-param]="selectedPass?.id"
                                *ngIf="selectedPass && isServer || !isLoggedIn ||  ( !isServer && (globalPassState && ( (!globalPassState.passExpiring && !globalPassState.passExpired) || globalPassState.passUnpurchased) )) || !globalPassState">
                            <ng-container *ngIf="!selectedPass?.isCouponApplied ? selectedPass?.cost <= 0 : selectedPass.afterCouponCost <= 0 ">
                                Claim for free
                            </ng-container>
                            <ng-container *ngIf="!selectedPass?.isCouponApplied ? selectedPass?.cost > 0 : selectedPass.afterCouponCost > 0 ">
                                <ng-container *ngIf="goal?.properties?.isClientEMIAvailable">
                                    Pay Now - ₹{{selectedPass?.isCouponApplied ? selectedPass.afterCouponCost : selectedPass.cost}}
                                </ng-container>
                                <ng-container *ngIf="!goal?.properties?.isClientEMIAvailable">
                                    Buy
                                    <ng-container *ngIf="passType == 'goalSubs'">
                                        <span *ngIf="!isServer">{{selectedPass.title}}</span>
                                        <span *ngIf="isServer">Super</span>
                                        Now
                                    </ng-container>
                                </ng-container>
                            </ng-container>

                        </button>

                        <button class="btn btn--cta btn-block"
                                (click)="initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass', '', '')"
                                cb-await=""
                                cb-await-id="initiatePaymentcta"
                                [attr.cb-await-param]="selectedPass?.id"
                                *ngIf="selectedPass && !isServer && isLoggedIn && globalPassState && (globalPassState.passExpired || globalPassState.passExpiring) && !globalPassState.passUnpurchased"
                        >
                            <ng-container *ngIf="passType == 'goalSubs'">
                                <ng-container *ngIf="goal?.properties?.isClientEMIAvailable">
                                    Pay Now - ₹{{selectedPass?.isCouponApplied ? selectedPass.afterCouponCost : selectedPass.cost}}
                                </ng-container>
                                <ng-container *ngIf="!goal?.properties?.isClientEMIAvailable">
                                    {{'RENEW_YOUR_SUPER_COACHING' | translate }}
                                </ng-container>
                            </ng-container>
                        </button>
                        <div *ngIf="goal?.properties?.isClientEMIAvailable && !selectedPass?.emis?.length">
                            <div class="border-divider"><span>or</span></div>
                            <div class="emi-strip" (click)="initiateEMIPaymentFlow()">
                                <span class="badge-bg-green">EMI Available</span>
                                <span>Get EMI Options <span class="text-success">View More</span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>
