import { Component, OnInit } from '@angular/core';
import {PlatformService} from "@core/services/platform-service";
import {Store} from "@ngrx/store";
import {
    selectActivePageType,
    selectStickyFooterTypeState,
    selectContinueInAppDeeplinkData,
    selectStudent,
    selectContinueInAppState
} from "@core/application-state/app.selectors";
import {selectStickyFooterState} from "@core/application-state/app.selectors";
import {AutoUnsub, getPageText} from "@shared/utility/tb-common";
import {isServer} from "@shared/utility/tb-common";
import { globalPass } from '@angular-commons/models/entity/passes/passes.adapter';
import { GAEventService } from '@angular-commons/core/services/ga-event.service';
import { ContinueInAppPageMap, getAppInstallPopupPageDeeplink } from '@angular-commons/shared/utility/android-app-utilities';
import { TbcoreService } from '@angular-commons/core/services/tbcore.service';
import { checkWaffleComponent } from '@angular-commons/shared/utility/vanilla';
@Component({
  selector: 'sticky-footer',
  templateUrl: './sticky-footer.component.html',
  styleUrls: ['./sticky-footer.component.scss']
})
@AutoUnsub()
export class StickyFooterComponent implements OnInit {
  isMobile: boolean = this.platformService.isMobile();
  isLoggedIn: boolean = this.platformService.isLoggedIn();
  isIosDevice = this.platformService.isIosDevice();
  showStickyFooter :boolean = false;
  showContinueInApp :boolean = false;
  stickyFooterSub$ : any;
  continueInAppSub$ : any;
  isPassActive: boolean = false;
  isPassExpired: boolean = false;
  isServer = isServer();
  student : any = {};
  type:string='default';
  navigationTab:any;
  showSuperCoachingActive = false;
  currentPageActive:any;
  continueInAppDeeplinkData:any='';
  isStaticRender: boolean;
  continueInAppDeeplink: string;
  ciaKey: any;

  constructor(private store: Store<{}>,private platformService: PlatformService, 
    private gaEventService: GAEventService, private tbCore: TbcoreService) {
    this.stickyFooterSub$ = store.select(selectStickyFooterState).subscribe( (value)=>{
      this.showStickyFooter = value;
    })
    this.continueInAppSub$ = store.select(selectContinueInAppState).subscribe( (value)=>{
      this.showContinueInApp = value;
    })
    this.isStaticRender = this.platformService.isStaticRender();
    this.store.select(selectStickyFooterTypeState).subscribe(res => {
      if(res){
        this.type=res;
      }
    });
    this.store.select(selectActivePageType).subscribe(res => {
      if(res){
        this.currentPageActive=res;
      }
    });
  }

  ngOnInit(): void {
    this.navigationTab = [
				{
					icon: "#live-class",
					name: "Live Classes",
					gaName:'Live Classes',
					pathname:'/free-live-classes',
					isFree:true,
					isLive:true,
          currentPageActive:'freeLiveClassPage',
				},
         {
					icon: "#icon-practice",
					name: "Practice",
					gaName:'Practice',
					pathname:'/practice-questions',
          currentPageActive:'practicePage',
				},
        {
					icon: "#pyp",
					name: "Prev. papers",
					gaName:'Prev. Year Papers',
					pathname:'/previous-year-papers',
          currentPageActive:'previousYearPaper',
				},
				{
					icon: "#tests",
					name: "Live Tests",
					gaName:'Live Test & Quizzes',
					pathname:'/free-live-tests-and-quizzes',
					isLive: true,
					isFree:true,
          currentPageActive:'freeLiveTestsAndQuizzesPage',
				}
			];
    if(this.platformService.isLoggedIn() && !isServer()){
      this.store.select(selectStudent).subscribe((data) => {
        this.student = data;
      });
      let studentPassState = globalPass.getStuPassState(this.student.globalPassExpiry);
      this.isPassActive = globalPass.isPassActive(this.student.globalPassExpiry, true);
      this.isPassExpired = studentPassState.passExpired;
    }
    if(!isServer()){
    this.tbCore.firePageReadyEvent();
    }
    let dlPage = getPageText();
    this.continueInAppDeeplink ='https://link.testbook.com/CIA-Fallback-mWeb';
    this.ciaKey = (dlPage && ContinueInAppPageMap[dlPage]) ? dlPage : this.currentPageActive;
    this.store.select(selectContinueInAppDeeplinkData).subscribe(res => {
        if(res && res?.deeplinkPath && res?.id){
          this.continueInAppDeeplinkData=res;
          }else if(this.ciaKey && ContinueInAppPageMap[this.ciaKey]){
            this.continueInAppDeeplinkData=ContinueInAppPageMap[this.ciaKey];
          }
        if(this.continueInAppDeeplinkData?.trackingInfo){
            this.continueInAppDeeplink = getAppInstallPopupPageDeeplink(
              this.continueInAppDeeplinkData?.trackingInfo,
              this.continueInAppDeeplinkData?.id,
              this.continueInAppDeeplinkData?.deeplinkPath);
          }
      });
  }

  continueToApp(){
      let params:any = {
        href: this.continueInAppDeeplink,
        gaevent: {
          event:'continue_in_app',
          info:{
            pagePath:this.platformService.getPathName(true),
            deeplink:this.continueInAppDeeplink,
            trackingInfo:this.continueInAppDeeplinkData?.trackingInfo,
            id:this.continueInAppDeeplinkData?.id,
            deeplinkPath:this.continueInAppDeeplinkData?.deeplinkPath,
            ciaKey:this.ciaKey
          }
        }
      };
      return params;
  }

  ngAfterViewInit() {
    checkWaffleComponent('multiNav');
  }

  gaEventFire(tab){
     if(tab=='getApp'){
        this.gaEventService.sendPdfViewerUserClickEvent({
          client: this.isMobile ? 'mweb':'web',
          module: 'PYPPdfPage',
          category: 'getApp',
          clickText: 'getApp',
          destinationPageCategory:'getApp-getApp',
          destinationURL:'getApp~getApp',
          landingPage:'https://testbook.app.link/d2djnh0Yr6',
          type:'PYPPdfPage'
        });
      }else{
        this.gaEventService.sendPdfViewerUserClickEvent({
          client: this.isMobile ? 'mweb':'web',
          module: 'PYPPdfPage',
          category: tab.gaName,
          clickText: tab.gaName,
          destinationPageCategory:tab.gaName+'-'+tab.gaName,
          destinationURL:tab.gaName+'~'+tab.gaName,
          landingPage:tab.pathname,
          type:'PYPPdfPage'
        });
      }
    }
}
