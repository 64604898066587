<div [ngClass]="isInBanner ? 'timer-corner' : 'goal-pass-strip'" class="{{cssModifierClass}}" *ngIf="showSaleStrip && stripType === 'default'">
    <ng-container *ngIf="!isMobile">{{salePitchText}},</ng-container>
    <b>{{'Ends in ' + timerText}}</b>
</div>

<div class="strip-text {{cssModifierClass}}" *ngIf="showSaleStrip && stripType === 'timerOnly'">
    <img *ngIf="!isInStrip" src="/assets/img/ui-kit/clock-red-strip.svg" loading="lazy" alt="clock" >
    <img *ngIf="isInStrip" src="/assets/img/ui-kit/clock-white-strip.svg" loading="lazy" alt="clock" >
     {{timerTitle +' '+ timerText}}
</div>
