import { Validator as _ } from "@shared/utility/validator";
import {baseModel, setupModel} from "@models/types";
import {arrayModel, propertyMaps, SourceProjectionMapper} from "@models/mapping-framework";
import {Network} from "@core/services/model-manager/main";

export class TargetSuperGroupModel extends baseModel{

    @propertyMaps('_id', _.str)
    public _id;

    @propertyMaps('properties', _.obj)
    public properties;

    @propertyMaps('targetsCount')
    public targetsCount;

}
setupModel(TargetSuperGroupModel,{type:'targets'});

export class TargetSuperGroupWrapper extends baseModel{

    @propertyMaps('superGroup',arrayModel(TargetSuperGroupModel), TargetSuperGroupModel)
    public superGroup;

}
setupModel(TargetSuperGroupWrapper,{type: 'targets'});


export class GetTargetSuperGroupsApi{
    static apiEndpoint = 'v1/target-family-details';

    static projection;
    static get  __projection(){ 
        if(!GetTargetSuperGroupsApi.projection){
            GetTargetSuperGroupsApi.projection = JSON.stringify(new SourceProjectionMapper(TargetSuperGroupWrapper).map());
        }
        return GetTargetSuperGroupsApi.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(GetTargetSuperGroupsApi.apiEndpoint,{...params,__projection:GetTargetSuperGroupsApi.__projection});
    }
}