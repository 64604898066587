import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@angular-commons/core/core.module';
import { LoggedOutPurchaseIframeComponent } from './logged-out-purchase-iframe/logged-out-purchase-iframe.component';
import { LoggedOutPurchaseModalModule } from '@angular-commons/logged-out-purchase-modal/logged-out-purchase-modal.module';
import { LoggedOutPurchaseWrapperRoutingModule } from './logged-out-purchase-wrapper.routing.module';
import { StoreModule } from '@ngrx/store';
import { loggedoutModalReducer } from './feature-state/logged-out-purchase-iframe.reducer';

@NgModule({
  declarations: [LoggedOutPurchaseIframeComponent],
  exports: [LoggedOutPurchaseIframeComponent],
  imports: [
    CommonModule,
    LoggedOutPurchaseWrapperRoutingModule,
    RouterModule,
    CoreModule,
    LoggedOutPurchaseModalModule,
    StoreModule.forFeature('loggedoutIFrame', loggedoutModalReducer)
  ]
})
export class LoggedOutPurchaseIframeWrapperModule { }
