<div *ngIf="!isServer" onclick="event.stopPropagation()">
    <div class="card nav-dropdown" *ngIf="!isMobile && !onPage">
        <div class="nav-dropdown__super">
            <a class="tab-grid" href="javascript:void(0)" *ngFor="let superGroup of superGroups; let ind = index" ng-class="{'active':selectedSuperGroupIndex === ind}" (click)="switchToSuperGroup(superGroup._id)" (mouseenter)="switchToSuperGroup(superGroup._id)">
                <img loading="lazy"  [src]="superGroup?.properties?.logo"  alt="logo"/>
                <div>{{superGroup?.properties?.title}}</div>
            </a>
        </div>
        <div class="nav-dropdown__target">
            <ng-container *ngIf="!fullNavigation">
                <a class="target-exam-list card" *ngFor="let target of targets" [routerLink]="'/' + target?.slug + routeSuffix" (click)="targetClick(target)">
                    <img height="28" width="28" class="target-exam-list__img" loading="lazy"
                         [src]="target?.properties?.logo
                    || defaultTargetLogo" alt="exam-logo" style="height: 28px;width: 28px" />
                    <div class="target-exam-list__title" *ngIf="target?.properties?.title" title="{{target?.properties?.title}}">{{target?.properties?.title}}</div>
                    <div class="target-exam-list__subtitle" *ngIf="target?.properties?.subtitle" title="{{target?.properties?.subtitle}}">{{target?.properties?.subtitle}}</div>
                    <div class="target-exam-list__tag" *ngIf="target?.tags && target?.tags.length>0">
                        <span *ngFor="let tag of target?.tags" class="">{{target}}</span>
                    </div>
                </a>
            </ng-container>
            <ng-container *ngIf="fullNavigation">
                <a class="target-exam-list card" *ngFor="let target of targets" [href]="'/' + target?.slug + routeSuffix" (click)="targetClick(target)">
                    <img height="28" width="28" class="target-exam-list__img" loading="lazy"
                         [src]="target?.properties?.logo
                    || defaultTargetLogo" alt="exam-logo" style="height: 28px;width: 28px" />
                    <div class="target-exam-list__title" *ngIf="target?.properties?.title" title="{{target?.properties?.title}}">{{target?.properties?.title}}</div>
                    <div class="target-exam-list__subtitle" *ngIf="target?.properties?.subtitle" title="{{target?.properties?.subtitle}}">{{target?.properties?.subtitle}}</div>
                    <div class="target-exam-list__tag" *ngIf="target?.tags && target?.tags.length>0">
                        <span *ngFor="let tag of target?.tags" class="">{{target}}</span>
                    </div>
                </a>
            </ng-container>
        </div>
    </div>
    <div *ngIf="isMobile && !onPage">
        <div class="side-panel-close" (click)="targetClick({})" >Exams</div>
        <div class="side-panel-group" *ngFor="let superGroup of superGroups; let ind = index">
<!--             add show class in "side-panel-group__heading" to open accordion box -->
            <a href="javascript:void(0)" class="side-panel-group__heading" [ngClass]="{'show': accordionIndex == ind}"  (click)="switchToSuperGroup(superGroup._id);toggleAccordion(ind)">
                <img height="14" width="22" loading="lazy" [src]="superGroup?.properties?.logo" alt="exam-icon" />{{superGroup?.properties?.title}}
            </a>
            <div class="side-panel-group__body">
                <ng-container *ngIf="!fullNavigation">
                    <a class="target-exam-list card" *ngFor="let target of targetsSupergroupMap[superGroup._id]" [routerLink]="'/' + target?.slug + routeSuffix" target="_blank" (click)="targetClick(target)">
                        <div class="target-exam-list__title" *ngIf="target?.properties?.title" title="{{target?.properties?.title}}">{{target?.properties?.title}}</div>
                    </a>
                </ng-container>
                <ng-container *ngIf="fullNavigation">
                    <a class="target-exam-list card" *ngFor="let target of targetsSupergroupMap[superGroup._id]" [href]="'/' + target?.slug + routeSuffix" target="_blank" (click)="targetClick(target)">
                        <div class="target-exam-list__title" *ngIf="target?.properties?.title" title="{{target?.properties?.title}}">{{target?.properties?.title}}</div>
                    </a>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<div *ngIf="onPage && superGroups" [ngClass]="{'scroller-tabs':tabsType === 'scroller'}">
    <span class="lms-custom-style">
        <h2 translate [innerHTML]="title"></h2>
        <p translate [innerHTML]="description"></p>
    </span>
    <div class="exams-card {{cssModifierClass}}">
        <scrollable-tabs
        *ngIf="tabsType === 'scroller'"
        [tabs]="superGroupsList"
        [scrollWidth]="scrollWidth"
        [currentTabId]="selectedSuperGroupId"
        (selectedTab)="switchToSuperGroup($event)"
        ></scrollable-tabs>
        <div class="exams-card__list" *ngIf="tabsType !== 'scroller' && superGroups && superGroups.length">
            <a class="tab tab-grid" *ngFor="let superGroup of superGroups; let ind = index"
                [ngClass]="{'active':selectedSuperGroupIndex === ind}"
                (mouseenter)="switchToSuperGroup(superGroup._id,ind)"
                cb-await="" [attr.cb-await-id]="'identifierswitchsupergroup_'+superGroup._id+'_'+ind">
                <img loading="lazy" [src]="superGroup.properties?.logo|| defaultTargetLogo"
                      alt="{{superGroup.properties?.title}} {{imgAltSuffix}}" title="{{superGroup.properties?.title}} {{imgAltSuffix}}" />
                <span class="tab__text">{{superGroup.properties?.title}}</span>
            </a>
        </div>
        <div class="exams-card__list skeleton" *ngIf="tabsType !== 'scroller' && superGroups && !superGroups.length">
            <a class="tab tab-grid sk-t sk-anim" *ngFor="let superGroup of [1,2,3,4,5,6,7,8,9,10,11,12];"
               style="margin: 4px 0">
                <img class="sk-anim sk-b" alt="" title="" /> title
            </a>
        </div>
        
        <div class="exams-card__content" *ngIf="haveTargetsLoaded">
            <div class="card-grid--explore" *ngIf="tabsType && tabsType === 'scroller'">
                <ng-container  *ngFor="let target of targets | slice : 0:11;let i = index" >
                    <a *ngIf="!fullNavigation" class="card card--explore" [routerLink]="'/' + target?.slug + routeSuffix" (click)="targetClick(target)" >
                        <img loading="lazy"  [src]="target?.properties?.logo|| defaultTargetLogo"
                             alt="{{target?.properties?.title}}" title="{{target?.properties?.title}}" height="40px" width="40px"/>
                        <span class="h4" *ngIf="target?.properties?.title" title="{{target?.properties?.title}}">{{target?.properties?.title}}</span>
                    </a>
                    <a *ngIf="fullNavigation" class="card card--explore" [href]="'/' + target?.slug + routeSuffix" (click)="targetClick(target)" >
                        <img loading="lazy" [src]="target?.properties?.logo|| defaultTargetLogo"
                             alt="{{target?.properties?.title}}" title="{{target?.properties?.title}}" height="40px" width="40px"/>
                        <span class="h4" *ngIf="target?.properties?.title" title="{{target?.properties?.title}}">{{target?.properties?.title}}</span>
                    </a>
                </ng-container>
                <a class="card card--explore-all" [href]="superGroupUrl" *ngIf="targets.length > 11">
                        Explore all exams
                </a>
            </div>
            <div class="card-grid--explore" *ngIf="tabsType !== 'scroller'">
                <ng-container *ngIf="!fullNavigation">
                    <a class="card card--explore" *ngFor="let target of targets;let i = index" [routerLink]="'/' + target?.slug + routeSuffix" (click)="targetClick(target)">
                        <img loading="lazy" [src]="target?.properties?.logo|| defaultTargetLogo"
                             alt="{{target?.properties?.title}} {{imgAltSuffix}}" title="{{target?.properties?.title}} {{imgAltSuffix}}" />
                        <span class="h4" *ngIf="target?.properties?.title" title="{{target?.properties?.title}}">{{target?.properties?.title}}</span>
                        <div class="count" *ngIf="pageType === 'pyp'">{{target?.stats.pypCount.count}} Papers</div>
                        <div class="count" *ngIf="pageType === 'passPage' && target?.entityCount?.test">{{target?.entityCount.test}} Tests</div>
                    </a>
                </ng-container>
                <ng-container *ngIf="fullNavigation">
                    <a class="card card--explore" *ngFor="let target of targets;let i = index" [href]="'/' + target?.slug + routeSuffix" (click)="targetClick(target)">
                        <img loading="lazy" [src]="target?.properties?.logo|| defaultTargetLogo"
                             alt="{{target?.properties?.title}} {{imgAltSuffix}}" title="{{target?.properties?.title}} {{imgAltSuffix}}" />
                        <span class="h4" *ngIf="target?.properties?.title" title="{{target?.properties?.title}}">{{target?.properties?.title}}</span>
                        <div class="count" *ngIf="pageType === 'pyp'">{{target?.stats.pypCount.count}} Papers</div>
                        <div class="count" *ngIf="pageType === 'passPage' && target?.entityCount?.test">{{target?.entityCount.test}} Tests</div>
                    </a>
                </ng-container>
            </div>
        </div>
        <div class="exams-card__content" *ngIf="!haveTargetsLoaded">
            <div class="card-grid--explore">
               <ng-container *ngFor="let i of [0,1,2]">
                    <span  class="skeleton" *ngFor="let target of [0,1,2,3,4,5,6,7,8];let i = index">
                        <a class="card card--explore">
                            <img  class="sk-b sk-anim" alt="loading"  height="40px" width="40px"/>
                            <span class="sk-t sk-anim">sk-t sk-anim</span>
                        </a>
                    </span>
               </ng-container>
            </div>
        </div>
    </div>

    <div class="exams-card-mweb">
        <div class="card-grid card-grid--explore" >
            <ng-container *ngIf="superGroups && superGroups.length">
                <a *ngFor="let superGroup of superGroups; let ind = index"
                   (click)="showMwebPage(superGroup._id,ind);"
                   class="card card--explore"
                   cb-await="" [attr.cb-await-id]="'identifierswitchsupergroup_'+superGroup._id+'_'+ind">
                    <img class="exams-card-mweb__img" loading="lazy" [src]="superGroup.properties?.logo||
                    defaultTargetLogo" alt="{{superGroup.properties?.title}} {{imgAltSuffix}}" title="{{superGroup.properties?.title}} {{imgAltSuffix}}" />
                    <span class="h3">{{superGroup.properties?.title}}</span>
                    <div class="count">{{superGroup.targetsCount}} Exams</div>
                </a>
            </ng-container>
            <ng-container *ngIf="superGroups && !superGroups.length">
                <a *ngFor="let superGroup of [1,2,3,4,5,6,7,8,9,10,11,12];"
                   class="card card--explore skeleton">
                    <span class="exams-card-mweb__img sk-b sk-anim"
                          style="position: absolute;left: 1rem;width: 40px;height: 40px"></span>
                    <span class="h3 sk-t sk-anim">txt</span>
                    <div class="count sk-t sk-anim">txt</div>
                </a>
            </ng-container>
        </div>
        <div class="exams-card-mweb__modal" *ngIf="showMwebModal">
            <h3><span (click)="hideMwebModal()">
                <img loading="lazy" src="/assets/img/ui-kit/grey-arrow-left.svg" alt="icon-forward"></span>Exams</h3>
            <ng-container *ngIf="!fullNavigation">
                <div *ngFor="let target of targets" [routerLink]="'/' + target?.slug + routeSuffix" (click)="targetClick(target)">
                    <a class="card card--explore">
                        <img loading="lazy" [src]="target?.properties?.logo|| defaultTargetLogo"
                             alt="{{target?.properties?.title}} {{imgAltSuffix}}" title="{{target?.properties?.title}} {{imgAltSuffix}}" />
                        <span class="h4" *ngIf="target?.properties?.title" title="{{target?.properties?.title}}">{{target?.properties?.title}}</span>
                        <div class="count" *ngIf="pageType === 'pyp'">{{target?.stats.pypCount.count}} Papers</div>
                        <div class="count" *ngIf="pageType === 'passPage' && target?.entityCount?.test">{{target?.entityCount.test}} Tests</div>
                    </a>
                </div>
            </ng-container>
            <ng-container *ngIf="fullNavigation">
                <div *ngFor="let target of targets" (click)="targetClick(target)">
                    <a class="card card--explore" [href]="'/' + target?.slug + routeSuffix" >
                        <img loading="lazy" [src]="target?.properties?.logo|| defaultTargetLogo"
                             alt="{{target?.properties?.title}} {{imgAltSuffix}}" title="{{target?.properties?.title}} {{imgAltSuffix}}" />
                        <span class="h4" *ngIf="target?.properties?.title" title="{{target?.properties?.title}}">{{target?.properties?.title}}</span>
                        <div class="count" *ngIf="pageType === 'pyp'">{{target?.stats.pypCount.count}} Papers</div>
                        <div class="count" *ngIf="pageType === 'passPage' && target?.entityCount?.test">{{target?.entityCount.test}} Tests</div>
                    </a>
                </div>
            </ng-container>
        </div>
    </div>
</div>
