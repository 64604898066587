<ng-container *ngIf="priceBreakDownObj">
    <div *ngIf="!( isLogoutGoalSubs && passType == 'globalPass')" class="saving-amount-strip" [ngClass]="{'show': priceBreakDownObj.isCouponApplied}">
        Yay! Coupon is applied. Additional ₹{{priceBreakDownObj?.couponDiscount}} savings
    </div>
    <div class="pass-details {{cssModifierClass}}">
        <div class="pass-details__flex pass-details__saving" *ngIf="priceBreakDownObj.totalSavings !== 0 && !showEMIPlans">
        <span>
            <img src="./assets/img/pass/goal-pass-saving.svg" alt="pass-saving" width="20" height="20"> Your Total Savings
        </span>
            <span>₹{{priceBreakDownObj?.totalSavings}}</span>
        </div>
        <hr>
        <div class="pass-details__flex pass-details__payable" *ngIf="!showEMIPlans">
            <span>Amount to be paid</span> <span>₹{{priceBreakDownObj?.finalAmount}}</span>
        </div>
        <div class="pass-details__flex pass-details__payable" *ngIf="showEMIPlans && priceBreakDownObj.emiDownPaymentAmount">
            <span>Amount to be paid</span> <span class="old-cost">₹{{priceBreakDownObj?.finalAmount}}</span><span>₹{{priceBreakDownObj?.emiDownPaymentAmount}}</span>
        </div>
    </div>
</ng-container>
