import { Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable, ReplaySubject} from 'rxjs';
import {Store} from "@ngrx/store";
import {PlatformService} from "@core/services/platform-service";
import {selectGoal, selectGoalMap} from "../../../../angular-commons/core/application-state/app.selectors";
import {getGoalBySlug} from "../../../../angular-commons/core/application-state/app.actions";
import {GOAL_EXPIRY} from "../../../../angular-commons/shared/utility/constants";
import {AuthenticationService} from "../../../../angular-commons/core/services/authentication.service";
import {TransferStateManager} from "../../../../angular-commons/core/services/transferStateManager";
import {getGoalDashboardPageUrl} from "@shared/utility/goal-utility";
import {ProductAccessService} from "@core/services/product-access.service";
import {getQueryStringFromParams} from "@shared/utility/url";
import {isServer, setToSessionStorage} from "@shared/utility/tb-common";

@Injectable({
  providedIn: 'root'
})
export class PrimaryGoalGuard implements CanActivate {
  transferStateValue

    constructor(private router:Router,
                private store: Store<{}>,
                private platformService: PlatformService,
                private auth: AuthenticationService,
                private transferStateManager : TransferStateManager,
                private productAccess : ProductAccessService) {
    }

    canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
      let goalSlug = route.params.goalSlug;
      const sub = new ReplaySubject<boolean>();
      if(goalSlug) {
        this.transferStateValue = this.transferStateManager.get(`currentGoalDetails${goalSlug}`,
          getGoalBySlug({slug: goalSlug}),
          this.store.select(selectGoalMap),
          (goal)=>{
            if(!(goal[goalSlug]?.id)) {
              this.platformService.gotoErrorPage();
              sub.next(false);
              sub.complete();
            } else {
                if(goalSlug) {
                    this.auth.setGoal({id: goal[goalSlug]?.id, slug: goal[goalSlug]?.properties?.slug}, GOAL_EXPIRY, false, false);
                    if(!this.platformService.isLoggedIn() && !isServer()){
                        setToSessionStorage('currentActiveGoal', {id: goal[goalSlug]?.id, slug: goal[goalSlug]?.properties?.slug});
                    }
                }
                this.productAccess.hasGoalSubscription(goal[goalSlug]?.id).then( hasAccess => {
                    if(hasAccess && !isServer()){
                        let url =  getGoalDashboardPageUrl(goalSlug) + getQueryStringFromParams(route.queryParams)
                        this.platformService.redirect(url,302);
                    }
                }).finally(()=>{
                    sub.next(true);
                    sub.complete();
                });
            }
            if(this.transferStateValue){
              this.transferStateValue.unsubscribe()
            }
          });
        return sub;
      }
      return false;
    }
}
