import {arrayModel, objModel, propertyMaps, SourceProjectionMapper} from "../../mapping-framework";
import {Network} from "../../../core/services/model-manager/main";
import {baseModel, setupModel} from "../../types";
import {Validator as _} from "../../../shared/utility/validator";
import{faqModifier} from "@shared/utility/faqs-utilities";
import {_Faq} from "@models/entity/targets/target.adapter";
import {Benefits} from "@models/entity/passes/pass-pro-adapter";



export class passDetails extends baseModel{

    @propertyMaps()
    public activeUsers;

    @propertyMaps()
    public examCount;

    @propertyMaps()
    public mockTestsInfo;

    @propertyMaps()
    public onlineCoursesInfo;

    @propertyMaps()
    public totalPYPInfo;

    @propertyMaps()
    public faqs;

    @propertyMaps()
    public benefits;

}
setupModel(passDetails,{});

export class _detailsCount extends baseModel{
    @propertyMaps('countInt')
    public count
}
setupModel(_detailsCount,{})

export class _passDetails extends baseModel{

    @propertyMaps('activeGlobalPasses',objModel(_detailsCount),_detailsCount)
    public activeUsers;

    @propertyMaps('examCountInfoInt')
    public examCount;

    @propertyMaps('mockTests',objModel(_detailsCount),_detailsCount)
    public mockTestsInfo;

    @propertyMaps('onlineCourses',objModel(_detailsCount),_detailsCount)
    public onlineCoursesInfo;

    @propertyMaps('totalPYPCount',objModel(_detailsCount),_detailsCount)
    public totalPYPInfo;

    @propertyMaps('faq',arrayModel(_Faq),_Faq)
    public faqs;

    @propertyMaps('benefits', arrayModel(Benefits), Benefits)
    public benefits;

}
setupModel(_passDetails,{});

export class getPassDetailsApi{

    static apiEndpoint = 'v1/pass-screen';

    static projection;
    static get  __projection(){ 
        if(!getPassDetailsApi.projection){
            getPassDetailsApi.projection = JSON.stringify(new SourceProjectionMapper(_passDetails).map());
        }
        return getPassDetailsApi.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(getPassDetailsApi.apiEndpoint,{__projection:getPassDetailsApi.__projection});
    }

}
