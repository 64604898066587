import {arrayModel, propertyMaps, SourceProjectionMapper} from "@models/mapping-framework";
import {Network} from "@core/services/model-manager/main";
import { Validator as _ } from "@shared/utility/validator";
import {baseModel, setupModel} from "@models/types";


export class Classes extends baseModel {

  @propertyMaps('_id',_.str)
  public id;
  @propertyMaps('titles',_.str)
  public title;
  @propertyMaps('count',_.num)
  public count;

}
setupModel(Classes, {});
export class CourseLanguage extends baseModel{
  @propertyMaps('_id',_.str)
  public id;
  @propertyMaps('titles',_.str)
  public title;
}
setupModel(CourseLanguage, {});
export class CustomTags extends baseModel {

  @propertyMaps('classes', arrayModel(Classes), Classes)
  public classes;

  @propertyMaps('masterClassSeries', arrayModel(Classes), Classes)
  public masterClassSeries;

  @propertyMaps("courseLanguages",arrayModel(CourseLanguage),CourseLanguage)
  public courseLanguages;

}
setupModel(CustomTags, {});

export class GetCustomTagsByGoalApi {
  static apiEndpoint = 'v1/goals/:goal-slug/tag-stats';

  static projection;
  static get  __projection(){ 
    if(!GetCustomTagsByGoalApi.projection){
      GetCustomTagsByGoalApi.projection = JSON.stringify(new SourceProjectionMapper(CustomTags).map());
    }
    return GetCustomTagsByGoalApi.projection;
  }

  static apiCall(network: Network, params) {
    return network.get(GetCustomTagsByGoalApi.apiEndpoint.replace(':goal-slug', params.goalSlug), {__projection: GetCustomTagsByGoalApi.__projection });
  }
}