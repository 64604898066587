<div class="callback" *ngIf = "!bannerMeta.submitted && !showBannerInput">
    <div>
        {{'HAVE_DOUBTS' | translate}}
        <span (click)="setShowBannerInput(true)" style="margin-left: 10px;" translate>{{'GET_A_CALLBACK'}}</span>
    </div>
</div>
<div class="callback" *ngIf = "showBannerInput">
    <div translate>{{'RECEIVE_CALL_FROM_EXPERTS'}}</div>
    <div class="callback__holder">
        <div class="callback__group">
            <input type="text" [(ngModel)]="mobile" placeholder="{{'PHONE_NO' | translate}}" cb-persist="mobilenoinput">
        </div>
        <button (click)="requestCallback()" cb-await="" cb-await-id="requestcallbackcta" translate>{{'SUBMIT'}}</button>
    </div>
    <span (click)="setShowBannerInput(false)" translate>{{'CLOSE'}}</span>
</div>
<div *ngIf = "bannerMeta.submitted && !showBannerInput">
    <div translate>{{'RECEIVED_CALL_DETAILS'}}</div>
    <div>
        <div [innerHtml]="'EXPERT_WILL_CALL_SOON' | translate: {'mobile' : bannerMeta.mobile }"></div>
        <a href="javascript:void(0)" (click)="setShowBannerInput(true)" cb-await="" cb-await-id="changenumbercta" translate>{{'CHANGE_NUMBER'}}</a>
    </div>                
</div>
