

export const selectStudentCoupons = (state: any) => {return state.coupon.studentCoupons};

export const selectCouponProducts = (state: any) => {return state.coupon.couponProducts};

export const selectCoupon = (state: any) => {return state.coupon.coupon};

export const selectCouponCTAData = (state: any) => {return state.coupon.ctaDetails};

export const selectCouponSuccessModalState = (state: any) => {return state.coupon.successModal};
