import { showAlert } from '@angular-commons/core/application-state/app.actions';
import { Store } from '@ngrx/store';
import { isServer } from './tb-common';
declare var window:any;

export class ExposeNgrxToWindow{

  constructor(private store: Store<{}>) {
        if(!isServer()){
            window.showAlert = (msg, state='error', size='small', autoCloseTime=1000)=>{this.store.dispatch(showAlert({message:msg,timer:autoCloseTime}))};
        }
    }   
}

