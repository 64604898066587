import {Injectable} from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    ActivatedRoute,
    UrlTree
} from '@angular/router';
import {Observable, of} from "rxjs";
import {Store} from "@ngrx/store";
import {PlatformService} from "@core/services/platform-service";
import { ENROLLMENT } from '@angular-commons/shared/utility/constants';


@Injectable()
export class OnboardingGuard implements CanActivate {

    constructor(private router:Router,
                private route: ActivatedRoute,
                private store: Store<{}>,
                private platformService: PlatformService) {
    }

    canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean|UrlTree> {

        let pathName=encodeURI(this.platformService.getPathName());
        // smart /my redirection
        let canNavigateToEnrollment = this.platformService.isLoggedIn() && pathName.endsWith(ENROLLMENT);
        let isPlutus= this.platformService.currentProjectName() == 'plutus';
        if(!isPlutus){
            if(!canNavigateToEnrollment){
                let url=`/login?redirect_url=`+encodeURI(state.url)
                this.platformService.redirect(url,302);
                return of(false);
            }else{
                return of(true);
            }
        }else{
            this.platformService.redirect('/home',302);
            return of(false);
        }
        
    }

}
