import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebSignupStickyFooterComponent } from './web-signup-sticky-footer.component';
import { CoreModule } from '@angular-commons/core/core.module';


@NgModule({
  declarations: [ WebSignupStickyFooterComponent],
  imports: [
    CoreModule,
    CommonModule
  ],
  exports: [WebSignupStickyFooterComponent]
})
export class WebSignupStickyFooterModule { }
