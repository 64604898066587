import { Validator as _ } from "@shared/utility/validator";
import {Network} from "@core/services/model-manager/main";


export class GetPopupDataApi{
    static apiEndpoint = 'v1/popup';

    static apiCall(network:Network,params:any){
        return network.get(GetPopupDataApi.apiEndpoint, params);
    }
}