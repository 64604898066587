import {baseModel, setupModel} from "@models/types";
import {arrayModel, pipeModel, propertyMaps, SourceProjectionMapper} from "@models/mapping-framework";
import {Validator as _} from "@shared/utility/validator";
import {_Faq} from "@models/entity/targets/target.adapter";
import {Network} from "@core/services/model-manager/main";

export class Benefits extends baseModel{
    @propertyMaps('title', _.str)
    public title;

    @propertyMaps('lightIconUrl', _.str)
    public lightIconUrl;

    @propertyMaps('darkIconUrl', _.str)
    public darkIconUrl;

    @propertyMaps('subtitle', _.str)
    public subTitle;
}
setupModel(Benefits, {});
export class PassProWidget extends baseModel{
    @propertyMaps('features', arrayModel(Benefits), Benefits)
    public features;
}
setupModel(PassProWidget, {});

export function transformTitleToInnerHtml(benefits){
    let _benefits = benefits.map(benefit => {
        let _benefit = {...benefit};
        let _title = `<b>${benefit.title.trim().replace('<pass_icon>', '<img src="/assets/img/pass-new/pass-txt-white.svg" alt="pass">')} ${benefit.subTitle}</b>`;
        let onlyTitle = `<b>${benefit.title.trim().replace('<pass_icon>', '<img src="/assets/img/pass-new/pass-txt-white.svg" alt="pass">')}`;
        _benefit.pitchOnlyTitle = onlyTitle;
        _benefit.pitchTitle = _title;
        return _benefit;
    });
    return _benefits;
}

export class WhatYouGet extends baseModel{
    @propertyMaps('title', _.str)
    public title;
}
setupModel(WhatYouGet, {});

export class WhyMustHave extends baseModel{
    @propertyMaps('title', _.str)
    public title;

    @propertyMaps('lightIconUrl', _.str)
    public lightIconUrl;

    @propertyMaps('darkIconUrl', _.str)
    public darkIconUrl;

    @propertyMaps('subtitle', _.str)
    public subTitle;
}
setupModel(WhyMustHave, {});
export class PassProDetails extends baseModel{
    @propertyMaps('faq', arrayModel(_Faq), _Faq)
    public faqs;

    @propertyMaps('whatYouGet', arrayModel(WhatYouGet), WhatYouGet)
    public whatYouGet;

    @propertyMaps('whyMustHave', arrayModel(WhyMustHave), WhyMustHave)
    public whyMustHave;

    @propertyMaps('getAccessTo', pipeModel(arrayModel(Benefits), transformTitleToInnerHtml), Benefits)
    public getAccessTo;

    @propertyMaps('benefits', pipeModel(arrayModel(Benefits), transformTitleToInnerHtml), Benefits)
    public benefits;

    @propertyMaps('announce', _.str)
    public announcementPitch;
}
setupModel(PassProDetails, {});

export class getPassProDetailsApi{

    static apiEndpoint = 'v1/pass-screen';

    static projection;
    static get  __projection(){ 
        if(!getPassProDetailsApi.projection){
            getPassProDetailsApi.projection = JSON.stringify(new SourceProjectionMapper(PassProDetails).map());
        }
        return getPassProDetailsApi.projection;
    }

    static apiCall(network:Network,_params:any){
        var params :any = {};
        if(_params.pageType){
            params.type = _params.pageType;
        }
        return network.get(getPassProDetailsApi.apiEndpoint,{...params, __projection:getPassProDetailsApi.__projection});
    }
}
