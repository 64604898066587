import {baseModel, setupModel} from "@models/types";
import {propertyMaps, SourceProjectionMapper,arrayModel,objModel, pipeModel} from "@models/mapping-framework";
import {Network} from "@core/services/model-manager/main";
import { padZero } from "@angular-commons/shared/utility/math-utilities";
import { getCourseURL, getEntityPageUrl } from "@angular-commons/shared/utility/course-utils";
import { entityToUrl } from "../classes/schedule.adapter";
import {filterObjectKeys, Validator as _} from "../../../shared/utility/validator";
import {getPrimaryInstructors,getMoreinstructors} from '../classes/classes.adapter';

export function transformModules(items){

    items.forEach(item => {
        item.isVideo = (item.type === 'Video' || item.type === 'Live Class' || item.type === 'Doubt Class');
        item.isLive = item.type === 'Live Class' || item.type === 'Doubt Class';
        item.isQuiz = item.type === 'Quiz';
        item.isPractice = item.type === 'Practice';
        item.isTest = item.type === 'Test';
        item.isCode = item.type ==='CodingProblem';
        item.isNotes = item.type === 'Notes';
        item.isLesson = item.type === 'Lesson';
        item.isAssignment = item.type === 'Assignment';
        let questionCount=item.qCount || item.qsAdded || '';

        item.route=entityToUrl(item);
        item.hasResumedState=false;

        // item.url=getEntityPageUrl(item);

        if(item.summary){
            item.practiceString= `${(item.summary.attemptInfo.correct + item.summary.attemptInfo.incorrect) || 0}/${questionCount} Ques`
            item.marksObtained=item.summary.marks
        }
        else{
            item.practiceString=`${questionCount} Ques`
        }

        if(item.isVideo){
            let durationInSecs = Math.floor(item.duration/1000000000);
            let minutes = Math.floor(durationInSecs / 60);
            item.durationString = `${padZero(minutes)} mins`;
        }
        else if(item.isLesson){
            let durationInSecs = Math.floor(item.modules?.[0]?.duration/1000000000);
            let minutes = Math.floor(durationInSecs / 60);
            item.durationString = `${padZero(minutes)} mins`;
        }

        //url to be added for suggested activities

    });
    return items;
}


export function transformCourses(items){

    return items.map(item => {
        let urlObj=getCourseURL(item)
        item.courseUrl=urlObj.url;
        item.completedPercentage=Math.ceil((item.completedModules/item.totalModules)*100)
        item.completedPercentage = !isNaN(item.completedPercentage)? item.completedPercentage : 0;
        item.completedPercentageString=item.completedPercentage+'%'
        let features=item.classInfo?.classFeature?.features || [];
        let videoFeature=features.find((ele)=>ele.type === 'Live Class');
        item.videoModules=videoFeature?.count || 0;
        if(item.classProperties && item.classProperties.instructors){
            let instructors = item.classProperties.instructors.map(instructor => instructor.name);
            item.primaryInstructor = getPrimaryInstructors(instructors);
            item.moreInstructors = getMoreinstructors(instructors);
        }
        item.type='courses';
        item.videosCount='';
        item.lastActivityTime=new Date(item.lastActivityDate).getTime()
        item.hasResumedState=false;
        if(item && item.modules && item.modules.length){
            if(item.modules[0].type === 'Lesson' && item.modules[0].summaryModules?.length && item.modules[0].summaryModules[0]?.status){
                item.hasResumedState=(item.modules[0].summaryModules[0].status === 'incomplete')
            }
            else{
                item.hasResumedState=(item.modules[0]?.summary?.status === 'incomplete')
            }
        }

        if(item.classFeature && item?.classFeature?.features?.length && item?.classFeature?.features[0] && item?.classFeature?.features[0]?.type === 'Video'){
            item.videosCount=item.classFeature.features[0].count;
        }
        return item;
    });
}

export class CourseModules extends baseModel{
    @propertyMaps('_id')
    public id;

    @propertyMaps(['name','entityName'])
    public name;

    @propertyMaps('duration')
    public duration;

    @propertyMaps('type')
    public type;

    @propertyMaps('qCount')
    public qCount;

    @propertyMaps('qsAdded')
    public qsAdded;

    @propertyMaps('summary')
    public summary;

    @propertyMaps('modules')
    public modules

    @propertyMaps('summaryModules')
    public summaryModules;

    @propertyMaps('maxMarks')
    public maxMarks

    @propertyMaps('isDemoClass')
    public isDemo

    public durationString;

    public marksObtained

    public isLive;

    public isVideo;

    public isPractice;

    public isCode

    public isTest;

    public isNotes;

    public isQuiz;

    public isLesson;

    public isAssignment;

    public practiceString;

    public url

    public route 

    // public hasResumedState

    public lessonVideoDuration
}
setupModel(CourseModules,{})


export class classProperties extends baseModel{
    @propertyMaps('instructors')
    public instructors;

    @propertyMaps('languageInfo')
    public languageInfo;

    @propertyMaps('courseBadge')
    public courseBadge;

    @propertyMaps('courseCardBadgeDetails')
    public courseCardBadgeDetails;
}
setupModel(classProperties,{})

export class enrolledCourse extends baseModel{
    @propertyMaps('id')
    public id

    @propertyMaps('titles')
    public titles

    @propertyMaps('slug')
    public slug

    @propertyMaps('courseLogo',_.iconUrl)
    public courseLogo

    @propertyMaps('totalModules')
    public totalModules

    @propertyMaps('completedModules')
    public completedModules

    @propertyMaps('lastActivityDate')
    public lastActivityDate;

    @propertyMaps('classFeature')
    public classFeature;

    @propertyMaps('courseBadge')
    public courseBadge;

    @propertyMaps('courseCardBadgeDetails')
    public courseCardBadgeDetails;

    public videosCount;

    public completedPercentage

    public courseUrl;

    public type;

    public lastActivityTime;

    public hasResumedState;

    @propertyMaps('coachingName')
    public coachingName;

    @propertyMaps('modules',pipeModel(arrayModel(CourseModules),transformModules),CourseModules)
    public modules

}
setupModel(enrolledCourse,{})


export class coachingCourse extends baseModel{
    @propertyMaps('_id')
    public id

    @propertyMaps('titles')
    public titles

    public courseUrl

    @propertyMaps('completedModules')
    public completedModules

    @propertyMaps('slug')
    public slug

    @propertyMaps('courseLogo',_.iconUrl)
    public courseLogo

    @propertyMaps('totalModules')
    public totalModules

    @propertyMaps('enrolled')
    public enrolled

    @propertyMaps('unEnrolled')
    public unEnrolled

    @propertyMaps('classProperties',pipeModel(objModel(classProperties)),classProperties)
    public classProperties
    
    @propertyMaps('videoModules')
    public videoModules;

    @propertyMaps('classInfo')
    public classInfo;

    public primaryInstructor;

    public moreInstructors;

}
setupModel(coachingCourse,{})


export class EnrolledCourseWrapper extends baseModel{
    @propertyMaps('classes',pipeModel(arrayModel(enrolledCourse),transformCourses),enrolledCourse)
    public courses

    @propertyMaps('total')
    public total
}
setupModel(EnrolledCourseWrapper,{})

export class CourseWrapper extends baseModel{
    @propertyMaps('classes',pipeModel(arrayModel(coachingCourse),transformCourses),coachingCourse)
    public courses

    @propertyMaps('total')
    public total
}
setupModel(CourseWrapper,{})



export class GetEnrolledCoachingCourseApi{
    static apiEndpoint = 'v2/classes/toast';

    static projection;
    static get  __projection(){ 
        if(!GetEnrolledCoachingCourseApi.projection){
            GetEnrolledCoachingCourseApi.projection = JSON.stringify(new SourceProjectionMapper(EnrolledCourseWrapper).map());
        }
        return GetEnrolledCoachingCourseApi.projection;
    }

    static apiCall(network:Network,params:any){
        // delete params.goalIds; // only for testing untill course in this api get added
        return network.get(GetEnrolledCoachingCourseApi.apiEndpoint,{__projection:GetEnrolledCoachingCourseApi.__projection,...params});
    }
}

export class GetCoachingCourseApi{
    static apiEndpoint = 'v2.1/classes';

    static projection;
    static get  __projection(){ 
        if(!GetCoachingCourseApi.projection){
            GetCoachingCourseApi.projection = JSON.stringify(new SourceProjectionMapper(CourseWrapper).map());
        }
        return GetCoachingCourseApi.projection;
    }

    static apiCall(network:Network,params:any){
        // delete params.ids; // only for testing untill course in this api get added
        params = filterObjectKeys(params, ['type']);
        return network.get(GetCoachingCourseApi.apiEndpoint,{__projection:GetCoachingCourseApi.__projection,...params});
    }
}

export class SearchedResults extends baseModel{
    @propertyMaps('courses',pipeModel(arrayModel(coachingCourse),transformCourses),coachingCourse)
    public courses
}
setupModel(SearchedResults,{})

export class SearchedCourseWrapper extends baseModel{
    @propertyMaps('count')
    public count

    @propertyMaps('results',objModel(SearchedResults),SearchedResults)
    public results

    @propertyMaps('searchId')
    public searchId;
}
setupModel(SearchedCourseWrapper,{})



export class GetSearchedCourseApi{
    static apiEndpoint = 'v1/search/global';

    static projection;
    static get  __projection(){ 
        if(!GetSearchedCourseApi.projection){
            GetSearchedCourseApi.projection = JSON.stringify(new SourceProjectionMapper(SearchedCourseWrapper).map());
        }
        return GetSearchedCourseApi.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(GetSearchedCourseApi.apiEndpoint,{__projection:GetSearchedCourseApi.__projection,...params});
    }
}


export class EnrollCoachingCourseApi{
    static apiEndpoint = 'v1/courses/{cid}/activity';

    static  __projection = 1;

    static apiCall(network:Network,params:any){
        return network.post(EnrollCoachingCourseApi.apiEndpoint.replace('{cid}',params.courseId),params.payload);
    }
}

export class UnEnrollCoachingCourseApi{
    static apiEndpoint = 'v1/classes/{cid}/unenroll';

    static  __projection = 1;

    static apiCall(network:Network,params:any) {
        return network.post(UnEnrollCoachingCourseApi.apiEndpoint.replace('{cid}',params.courseId));
    }
}
