<div class="modal" *ngIf="showModal" [ngClass]="{'show':showModal}" id="loggedOutPurchaseModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-close" (click)="closeModal()"></div>
            <div class="modal-body">
                <progress-indicator *ngIf="formStage != 2" [stageNameArray]="stageNameArray" [stageCount]="'3'"
                    [formStage]="formStage" [cssModifierClass]="'progress-holder--logout-purchase'"></progress-indicator>
                <logged-out-purchase-stage-one [passType]="passType" [goalId]="goalId" *ngIf="formStage == 0"></logged-out-purchase-stage-one>
                <logged-out-purchase-stage-two [passType]="passType" [goalId]="goalId" [pId]="pId" *ngIf="formStage == 1"></logged-out-purchase-stage-two>
                <ecards [selectedPass]="selectedPass" [userDetails]="userDetails" [activationCode]="activationCode" *ngIf="formStage == 2"></ecards>
            </div>
        </div>
    </div>
    <div class="modal-backdrop" (click)="closeModal()" *ngIf="!isMobile"></div>
</div>
