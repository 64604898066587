import { Component, OnInit } from '@angular/core';
import {PlatformService} from "@core/services/platform-service";
import {getGoalById, setEmiIframeState, transactionPopupAction} from "@core/application-state/app.actions";
import {Store} from "@ngrx/store";
import {clientOnly} from "@shared/utility/platform-decorators";
import {selectGoal, selectGoalMap, selectGoals, selectStudent} from "@core/application-state/app.selectors";
import {TransferStateManager} from "@core/services/transferStateManager";
import {toShortFormat} from "@shared/utility/date-utilities";
import { EMI_BLOCKER, EMI_MANDATE } from '@angular-commons/shared/utility/constants';
import { getMandateGuideLines } from '@angular-commons/feature-modules/emi-mandate-popup/feature-state/emi-mandate.actions';
import { selectMandateGuideLines } from '@angular-commons/feature-modules/emi-mandate-popup/feature-state/emi-mandate.selector';
import { GAEventService } from '@angular-commons/core/services/ga-event.service';

@Component({
  selector: 'payment-receipt',
  templateUrl: './payment-receipt.component.html',
  styleUrls: ['./payment-receipt.component.scss']
})
export class PaymentReceiptComponent implements OnInit {
  txnId = '';
  modalOpen = false;
  isCourse:boolean;
  courseUrl:string;
  isGoalPass: boolean = false;
  goal : any = {};
  goalPlanValidity = "VALIDITY";
  goal$: any = this.store.select(selectGoalMap);
  goalTransferState$: any;
  student : any = {};
  isPassPro = false;
  isEmiFlow: boolean = false;
  mandateGuideLinesPdfLink: string = '';
  emiEventInfo: any;
  isPlutus:boolean = this.platformService.currentProjectName() == 'plutus';
  constructor(
      private platformService : PlatformService,
      private store:Store<{app : boolean}>,
      private transferStateManager : TransferStateManager,
      private gaEventService: GAEventService
      ) {
    this.checkTxnId();
  }

  @clientOnly()
  checkStudentGoalValidity(){
    if(!this.student || !this.goal){ return;}
    if(this.student.goalPlanState && this.student.goalPlanState[this.goal.id]){
      if(this.student.goalPlanState[this.goal.id].expiresOn){
        this.goalPlanValidity = toShortFormat(this.student.goalPlanState[this.goal.id].expiresOn,true);
      }
      this.modalOpen = true;
    }
  }

  @clientOnly()
  getGoal(){
    let key=`goalInfo${this.goal.id}`;
    this.goalTransferState$ = this.transferStateManager.get(key,getGoalById({id:this.goal.id}),this.goal$,(data)=>{
      if(data && data[this.goal.id] && data[this.goal.id].id == this.goal.id){
          this.goal=data[this.goal.id];
          this.checkStudentGoalValidity();
      }
    })
  }

  @clientOnly()
  checkTxnId(){
    this.txnId = this.platformService.getCookie('tb_txnid');
    if(this.txnId){
      let txnObj=JSON.parse(this.txnId);
      if(!txnObj.isLoggedOutTrans){
        if(txnObj.classObj && txnObj.classObj.isClassCase){
          this.isCourse=true;
          this.courseUrl=txnObj.classObj.classUrl;
        }
        this.modalOpen = true;
        this.isGoalPass = txnObj.passInfo && txnObj.passInfo.passType == 'goalSubs';
        this.isPassPro = txnObj.passInfo && txnObj.passInfo.passType == 'passPro';
        if((txnObj.emiData && txnObj.emiData?.isEmiFlow && txnObj.emiData?.isDownPaymentDone)){
          this.store.dispatch(getMandateGuideLines({commonDataType:'digioGuidelines'}));
          this.goal = txnObj?.passInfo?.goal;
          this.setEmiEventInfo(txnObj?.passInfo);
          this.getGoal();
          this.isGoalPass = false;
          this.isEmiFlow = true; // open the emi success modal
          this.modalOpen = true;
        }else if(this.isGoalPass){
          this.modalOpen = false; // open only once we have all the data
          this.goal = txnObj.passInfo.goal;
          this.getStudent();
          this.getGoal();
        }

      }
      this.platformService.setCookie('tb_txnid','',-1);
    }
  }

  @clientOnly()
  getStudent(){
    this.store.select(selectStudent).subscribe((student) => {
      if (student && student.id !== "-1") {
        this.student = student;
        this.checkStudentGoalValidity()
      }
    })
  }
  ngOnInit(): void {
    this.store.select(selectMandateGuideLines).subscribe((data) => {
      if (data?.isLoaded && data?.pdfLink) {
        this.mandateGuideLinesPdfLink = data.pdfLink;
      }
    });
  }

  @clientOnly()
  closeModel(type=""){
    this.modalOpen = false;
    if(type.length > 0){
      this.store.dispatch(transactionPopupAction({id:this.txnId,payloadtype: type,payload:{goal:this.goal}}));
    }
    if(this.platformService.getPageText() === 'PassPage' && this.isPassPro){
      this.platformService.redirect('/pass-pro');
    }
  }

  emiDownPaymentContinueCTA(){
    if(this.goal?.id){
      //open emi mandate popup
      //first close the success popup and then open the mandate popup
      this.closeModel();
      this.store.dispatch(setEmiIframeState({
        openModal: true, 
        modalType: EMI_MANDATE,
        goalId: this.goal.id
      }));
    }
  }

  setEmiEventInfo(passInfo) {
    this.emiEventInfo = {
      goalID: passInfo?.goal?.id || '',
      goalName: passInfo?.goal?.title || '',
      productID: passInfo?.pass?.id || '',
      productName: passInfo?.pass?.title || '',
      emiPlanPrice: passInfo?.pass?.emiPlanPrice || 0,
      payableAmount: passInfo?.pass?.payableAmount || 0,
      referrer: 'Transaction Success Screen'
    }
  }

  fireEmiInfoScreenVisitedEvent() {
    this.gaEventService.sendEmiInfoScreenVisitedEvent(this.emiEventInfo);
  }

}
