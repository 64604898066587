import { Validator as _ } from "@shared/utility/validator";
import {baseModel, setupModel} from "@models/types";
import {arrayModel, objModel, pipeModel, propertyMaps, SourceProjectionMapper} from "@models/mapping-framework";
import {Network} from "@core/services/model-manager/main";

export class _Notification extends baseModel{

    @propertyMaps()
    public results;

}
setupModel(_Notification,{type:'Notification'});

export class Notification extends baseModel{

    @propertyMaps('results', pipeModel(mapBackendMess))
    public results;
}
setupModel(Notification,{});

function mapBackendMess(arr) {
    return arr.map(item => {
        if(!item.activities || !item.activities[0]) {
            return {};
        }
        let activityIds = item.activities.map(item => item.id);
        item = item.activities[0];
        item.activityIds = activityIds;
        return item;
    })
}

export class NotificationModel extends baseModel {
    @propertyMaps('notifications', objModel(Notification), Notification)
    public notifications;
}
setupModel(NotificationModel,{});

export class _NotificationModel extends baseModel {
    @propertyMaps('notifications', objModel(_Notification), _Notification)
    public notifications;
}
setupModel(_NotificationModel,{});

export class NotificationCountModel extends baseModel {
    @propertyMaps('total', _.num)
    public total;

    @propertyMaps('unseen', _.num)
    public unseen;

    @propertyMaps('unread', _.num)
    public unread;

}
setupModel(NotificationCountModel, {type: 'notification'});

export class DeleteNotificationParams {
    @propertyMaps('activities', _.arr)
    public activities;

    @propertyMaps('sid', _.str)
    public sid;

    @propertyMaps('id', _.str)
    public id;

}
setupModel(DeleteNotificationParams, {type: 'notification'});

export class GetNotificationsApi{
    static apiEndpoint = 'v2/students/{sid}/notification';

    static projection;
    static get  __projection(){ 
        if(!GetNotificationsApi.projection){
            GetNotificationsApi.projection = JSON.stringify(new SourceProjectionMapper(_NotificationModel).map());
        }
        return GetNotificationsApi.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(GetNotificationsApi.apiEndpoint.replace('{sid}', params.sid),{__projection:GetNotificationsApi.__projection, skip: params.skip, limit: params.limit});
    }
}

export class GetNotificationsCountApi {
    static apiEndpoint = 'v2/students/{sid}/notification/count';

    static projection;
    static get  __projection(){ 
        if(!GetNotificationsCountApi.projection){
            GetNotificationsCountApi.projection = JSON.stringify(new SourceProjectionMapper(NotificationCountModel).map());
        }
        return GetNotificationsCountApi.projection;
    }

    static apiCall(network: Network, params: any) {
        return network.get(GetNotificationsCountApi.apiEndpoint.replace('{sid}', params.sid), {__projection: GetNotificationsCountApi.__projection})
    }
}

export class DeleteNotificationApi {
    static apiEndpoint = 'v2/students/{sid}/notification';

    static apiCall(network: Network, params: any) {
        return network.delete(DeleteNotificationApi.apiEndpoint.replace('{sid}', params.sid), {}, {activities: params.activities});
    }
}
