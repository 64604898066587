<div class="pass-includes__group" *ngIf="passType == 'globalPass'">
    <div class="pass-includes">
        <span class="pass-includes__icon icon-exams"></span>
        <div class="pass-includes__content">
            <span class="h3">{{passPageInfo.targetCount}}+</span>
            <span class="text-light-md" translate>EXAMS_COVERED_WEB</span>
            <span class="mweb" translate> EXAMS</span>
        </div>
    </div>
    <div class="pass-includes">
        <span class="pass-includes__icon icon-test"></span>
        <div class="pass-includes__content">
            <span class="h3">{{passPageInfo.mockTestCount}}+</span>
            <span class="text-light-md" translate>MOCK_TESTS</span>
            <span class="mweb" translate> MOCK_TESTS</span>
        </div>
    </div>
</div>

<div class="pass-includes__group--super" *ngIf="passType == 'goalSubs' && passPageInfo?.properties?.pitch?.length">
    <!-- <div class="h4" translate>
        What will you get in {{passPageInfo?.properties?.title}} Coaching
    </div> -->
    <div class="pass-includes__container">
        <ng-container *ngFor="let pitch of passPageInfo.properties.pitch">
            <div class="pass-includes" *ngIf="pitch.showInSummary">
                <div class="pass-includes__icon">
                    <img loading="lazy" height="20" width="20" [src]="pitch.icon">
                </div>
                <div class="pass-includes__content">
                    <span class="count"><strong>{{pitch.count}}</strong></span>
                    <span class="heading" translate>{{pitch.heading}}</span>
                    <span class="mweb" translate>{{pitch.subheading}}</span>
                </div>
            </div>
        </ng-container>
    </div>
</div>
