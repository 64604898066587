import {getDeferredPromise} from "@shared/utility/defer-promise";


declare var window:any;
declare var navigator:any;

export class webOtp {
    ac : AbortController;
    constructor() {}

    abort(){
        this.ac?.abort();
        this.ac = undefined;
    }

    async getCredentials(){
        if(AbortController){
            if(this.ac){
                this.abort();
            }
            this.ac = new AbortController();
        }
        return navigator.credentials.get({
            otp: { transport:['sms'] },
            signal: this.ac.signal
        });
    }

    async checkForWebOTP(){
        if (!('OTPCredential' in window) || !('AbortController' in window)){
            return "";
        }
        try{
            let otpObj = await this.getCredentials();
            return otpObj.code;
        } catch(e){
            console.error(e);
            return "";
        }
    }
}
