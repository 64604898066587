import {createAction, props} from "@ngrx/store";

export const getAllEducatorData = createAction('[app Coaching] getAllEducatorData',props<{params:any}>());
export const getAllEducatorDataLoaded = createAction('[app Coaching] getAllEducatorDataLoaded' , props<{educatorsData:any,goalId:any,skip:any,limit:any,excludeIds?:any}>());

export const getAllSubjectEducatorData = createAction('[app Coaching] getAllSubjectEducatorData',props<{params:any}>());
export const getAllSubjectEducatorDataLoaded = createAction('[app Coaching] getAllSubjectEducatorDataLoaded' , props<{educatorsData:any,subId:any,goalId:any,skip:any,limit:any}>());

export const getEducatorData = createAction('[app Coaching] getEducatorData',props<{idOrSlug:any, goalIdOrSlug: string}>());
export const getEducatorDataLoaded = createAction('[app Coaching] getEducatorDataLoaded' , props<{educatorData:any,idOrSlug:string}>());

export const getEducatorsSubjectList = createAction('[app Coaching] getEducatorsSubjectList',props<{goalId:any}>());
export const getEducatorsSubjectListLoaded = createAction('[app Coaching] getEducatorsSubjectListLoaded' , props<{subjectList:any}>());

export const getEducatorsFollowDetails = createAction('[app Coaching] getEducatorsFollowDetails',props<{sid:any,eid:any}>());
export const getEducatorsFollowDetailsLoaded = createAction('[app Coaching] getEducatorsFollowDetailsLoaded' , props<{followDetails:any}>());

export const setEducatorsFollow = createAction('[app Coaching] setEducatorsFollow',props<{body:any}>());
export const setEducatorsUnfollow = createAction('[app Coaching] setEducatorsUnfollow',props<{activity:any,eid:string}>());
export const setEducatorsFollowStatus = createAction('[app Coaching] setEducatorsFollowStatus',props<{followStatus:boolean}>());

export const getClassesByParentId = createAction('[app] Get Classes by Educator id', props<{params: any}>());
export const getClassesByParentIdSuccess = createAction('[app] Get Classes by Educator id success', props<{payload: any,parentId:string, parentType: string}>());
