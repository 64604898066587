import { Validator as _ } from "@shared/utility/validator";
import {baseModel, setupModel} from "@models/types";
import {arrayModel, objModel, propertyMaps, SourceProjectionMapper} from "@models/mapping-framework";
import {Network} from "@core/services/model-manager/main";

export class PageSeoModel extends baseModel{

    @propertyMaps()
    public URL;

    @propertyMaps()
    public id;

    @propertyMaps()
    public name;

    @propertyMaps()
    public courseInfo;

    @propertyMaps('sections', _.arr)
    public sections;

}
setupModel(PageSeoModel,{type: 'SEO'});


export class _anchorTags extends baseModel {
    @propertyMaps('tag', _.str)
    public anchorTag;

    @propertyMaps('url', _.str)
    public link;
}
setupModel(_anchorTags, {});

export class _subFooter extends baseModel {
    @propertyMaps('section', _.str)
    public title;

    @propertyMaps('anchorTags', arrayModel(_anchorTags))
    public links;
}
setupModel(_subFooter, {});

export class _SeoGroupSubFooterModel extends baseModel {
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps()
    public slug;

    @propertyMaps()
    public properties;

    @propertyMaps('subFooter', arrayModel(_subFooter))
    public subFooter;

    @propertyMaps()
    public meta;
}
setupModel(_SeoGroupSubFooterModel,{type: 'SEO'});

export class _SubFooterWrapperModel extends baseModel {
    @propertyMaps('seoGroup', objModel(_SeoGroupSubFooterModel))
    public seoGroup;
}
setupModel(_SubFooterWrapperModel,{type: 'SEO'});

export class SubFooterWrapperModel extends baseModel {
    @propertyMaps()
    public seoGroup;
}
setupModel(SubFooterWrapperModel,{type: 'SEO'});



export class GetPageSeoApi{
    static apiEndpoint = 'v2/seo';

    static projection;
    static get  __projection(){ 
        if(!GetPageSeoApi.projection){
            GetPageSeoApi.projection = JSON.stringify(new SourceProjectionMapper(PageSeoModel).map());
        }
        return GetPageSeoApi.projection;
    }

    static apiCall(network:Network,params:any){
        let url = 'testbook.com' + params.pathname;
        return network.get(GetPageSeoApi.apiEndpoint,{...params, url, __projection:GetPageSeoApi.__projection});
    }
}

export class GetSubFooterApi {
        
    static projection;static apiEndpoint = 'v1/seo-groups/{targetId}/details';
    static get  __projection(){ 
        if(!GetSubFooterApi.projection){
            GetSubFooterApi.projection = JSON.stringify(new SourceProjectionMapper(_SubFooterWrapperModel).map());
        }
        return GetSubFooterApi.projection;
    }

    static apiCall(network:Network,params:any){
        let fields = 'subFooter';
        return network.get(GetSubFooterApi.apiEndpoint.replace('{targetId}', params.targetId),{fields, __projection: GetSubFooterApi.__projection});
    }
}
