import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PlatformService} from "@core/services/platform-service";
import {isServer} from "@shared/utility/tb-common";


@Component({
  selector: 'global-search-bar-modal',
  templateUrl: './global-search-bar-modal.component.html',
  styleUrls: ['./global-search-bar-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalSearchBarModalComponent implements OnInit {

  isMobile: boolean = this.platformService.isMobile();
  isServer = isServer();
  isModalOpen = false;

  suggestions: any = [];

  @Output() onModalToggle? = new EventEmitter<boolean>();


  constructor(private platformService: PlatformService) {
  }

  ngOnInit(): void {

  }

  showModal() {
    this.isModalOpen = true;
    this.onModalToggle.emit(true);
  }

  hideModal() {
    this.isModalOpen = false;
    this.onModalToggle.emit(false);
  }
}
