import {baseModel, setupModel} from "@models/types";
import {propertyMaps, SourceProjectionMapper,arrayModel,pipeModel,objModel} from "@models/mapping-framework";
import {Network} from "@core/services/model-manager/main";
import {formatDate,createTimeText, shortMonths} from "@angular-commons/shared/utility/date-utilities";
import {Validator as _} from "../../../shared/utility/validator";
import {getMasterClassDeepLink} from '@angular-commons/shared/utility/android-app-utilities';
import {WEEKDAYS_CODES} from "@shared/utility/constants";


export function transformProperties(item:any){
    const currentTime:any = Date.now();
    let it:any={};
    it.name=item.name;
    it.startTime=item.startTime;
    it.endTime=item.endTime;
    it.isLiveNow=(new Date(it.startTime).getTime() <= currentTime) && (currentTime <= new Date(it.endTime).getTime());
    it.isUpcoming=(new Date(it.startTime).getTime() > currentTime)
    it.startDateText=formatDate(new Date(item.startTime),true)
    it.startTimeText=createTimeText(new Date(item.startTime));
    it.dateToShow = new Date(item.startTime).getDate();
    it.monthToShow = shortMonths[new Date(item.startTime).getMonth()];
    return it;
}

export function transformOngoingProperties(item: any) {
        let _item: any = {};
        _item.title = item.title;
        _item.instructors = item.instructors;
        _item.schedule = item.schedule;
        _item.stage = item.stage;
        _item.features = item.features;
        _item.endTime = item.endTime;
        _item.startTime = item.startTime;
        let startTime = '';
        const dateObj = new Date(item.startTime);
        let hour = dateObj.getHours();
        let minute: any = dateObj.getMinutes();
        minute = minute <= 9 ? '0' + minute : minute;
        if (hour < 12) {
            startTime = hour + ':' + minute + ' AM';
        } else  if (hour > 12)  {
            startTime = hour - 12 + ':' + minute + ' PM';
        } else if (hour === 12) {
            startTime = hour  + ':' + minute + ' PM';
        } else if (hour === 0) {
            startTime = 12 + ':' + minute + ' AM';
        }
        _item.startTimeStr = startTime;
        _item.schedule.fromTimeStr=createTimeText(new Date(item?.schedule?.from),true);

        return _item;
}

export function transformLesson(items:any){
    items.forEach(item => {
        item.appDownloadUrl=getMasterClassDeepLink(item.masterSeriesId,item.id,item.masterSeriesName);
        let seriesIdOrSlug = item.masterSeriesSlug || item.masterSeriesId
        item.seriesLink=`/${seriesIdOrSlug}/live-classes-series`;
        item.lessonLink=`/live-classes-series/${item.masterSeriesId}/lesson/${item.id}`
    });

    return items;
}

export function transformMasterSeriesDays(seriesArr: any){
    let series = [...seriesArr];
    const weekDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
    series.forEach((item, idx) => {
        let generatedDays = [];
        if(item?.properties?.schedule?.days){
            item = {...item};
            item.properties = {...item.properties};
            item.properties.schedule = {...item.properties.schedule};
            item.properties.schedule.days = item.properties.schedule.days?.filter( i => weekDays.includes(i));
        }
        if(item?.properties?.schedule && item?.properties?.schedule?.days?.length) {
            const days = item?.properties?.schedule?.days;
            let sequenceCount = 0;
            let start = 0;
            for (let seriesIdx = 0; seriesIdx < days.length; ){
                start = seriesIdx;
                let inSequence = false;
                for (let weekdaysIdx = seriesIdx; weekdaysIdx < 7 && seriesIdx < days.length; weekdaysIdx++){
                    if (isValidSequence(weekDays, weekdaysIdx, days, seriesIdx)){
                        sequenceCount++;
                        inSequence = true;
                        seriesIdx++;
                    } else{
                        inSequence = false;
                    }
                    if ((inSequence === false && sequenceCount > 0)){
                        generateScheduleDays(sequenceCount, start, days, seriesIdx, generatedDays);
                        sequenceCount = 0;
                        break;
                    }
                }
                if (seriesIdx === days.length && sequenceCount > 0) {
                    generateScheduleDays(sequenceCount, start, days, seriesIdx, generatedDays);
                }
            }
            item = {...item};
            item.properties = {...item.properties,
                scheduleDays: generatedDays.join(', ')
            };
            series[idx] = item;
        }
    });
    return series;
}

function isValidSequence(weekDays, weekdaysIdx, days, seriesIdx) {
    if (weekDays[weekdaysIdx] === days[seriesIdx]){
        return true;
    } else{
        return false;
    }
}

function generateScheduleDays(sequenceCount, start, days, seriesIdx, generatedDays){
    if (sequenceCount === 1){
        generatedDays.push(WEEKDAYS_CODES[days[start]]);
    } else if (sequenceCount === 2){
        generatedDays.push(WEEKDAYS_CODES[days[start]] + ', ' + WEEKDAYS_CODES[days[seriesIdx - 1]]);
    } else{
        generatedDays.push(days[start].toUpperCase() + '-' + days[seriesIdx - 1].toUpperCase());
    }
}

export class _LessonProperties extends baseModel{

    @propertyMaps('name')
    public name;

    @propertyMaps('startTime')
    public startTime;

    @propertyMaps('endTime')
    public endTime;

    @propertyMaps('isLiveNow')
    public isLiveNow;

    @propertyMaps('startDateText')
    public startDateText;

    @propertyMaps('startTimeText')
    public startTimeText;

    @propertyMaps('dateToShow')
    public dateToShow;

    @propertyMaps('monthToShow')
    public monthToShow;

    @propertyMaps('isUpcoming')
    public isUpcoming;

}
setupModel(_LessonProperties,{})

export class _Lessons extends baseModel{

    @propertyMaps('_id')
    public id;

    @propertyMaps('properties',pipeModel(objModel(_LessonProperties),transformProperties),_LessonProperties)
    public properties;

    @propertyMaps('modules')
    public modules;

    @propertyMaps('reminderFlag')
    public remindMe;

    @propertyMaps('mcSeriesId')
    public masterSeriesId;

    @propertyMaps('mcSeriesSlug')
    public masterSeriesSlug;

    @propertyMaps('mcSeriesName')
    public masterSeriesName;

    @propertyMaps('instructors')
    public instructors;

}
setupModel(_Lessons,{});


export class _MasterClass extends baseModel{
    @propertyMaps('lessons',pipeModel(arrayModel(_Lessons),transformLesson),_Lessons)
    public lessons;
}
setupModel(_MasterClass,{});



export class GetSuperGroupMasterClassesApi{
    static apiEndpoint = 'v1/mclass-series/lessons';

    static projection;
    static get  __projection(){ 
        if(!GetSuperGroupMasterClassesApi.projection){
            GetSuperGroupMasterClassesApi.projection = JSON.stringify(new SourceProjectionMapper(_MasterClass).map());
        }
        return GetSuperGroupMasterClassesApi.projection;
    }

    static apiCall(network:Network,params:any){
        if(typeof params.isRecommended !== 'undefined'){
            let _params = {...params}
            _params.isForYou = _params.isRecommended;
            params = Object.assign({}, _params)
            delete params.isRecommended
        }
        return network.get(GetSuperGroupMasterClassesApi.apiEndpoint,{...params,__projection:GetSuperGroupMasterClassesApi.__projection});
    }
}

export function transformFallbackImg(items: any) {
    let defaultImg = '/assets/img/other/teacher-default.svg';
    if (!items?.length){
        items.push({image: defaultImg});
    }else {
        items.forEach(item => {
            if(!item?.image){
                item.image = defaultImg;
            }
        });
    }

    return items;
}

export class _OngoingMasterSeriesMarketingDetails extends baseModel{
    @propertyMaps('seriesImage',_.str)
    public image;

    @propertyMaps('seriesImageWeb',_.str)
    public imageWeb;

    @propertyMaps('slug',_.str)
    public slug;

    @propertyMaps('thumb',_.str)
    public thumb;

    @propertyMaps('meta',_.str)
    public meta;
}
setupModel(_OngoingMasterSeriesMarketingDetails,{});

export class Instructor extends baseModel{
    @propertyMaps('id',_.str)
    public id;

    @propertyMaps('name',_.str)
    public name;

    @propertyMaps('image',_.str)
    public image;
}
setupModel(Instructor,{});

export class Schedule extends baseModel{
    @propertyMaps('days', _.arr)
    public days;

    @propertyMaps('from',_.str)
    public from;

    @propertyMaps('to',_.str)
    public to;

}
setupModel(Schedule,{});

export class _Properties extends baseModel{
    @propertyMaps('title',_.str)
    public title;

    @propertyMaps('features',_.str)
    public features;

    @propertyMaps('stage',_.str)
    public stage;

    @propertyMaps('startTime',_.date)
    public startTime;

    @propertyMaps('endTime',_.date)
    public endTime;

    @propertyMaps('schedule', objModel(Schedule), Schedule)
    public schedule;

    @propertyMaps('instructors', pipeModel(arrayModel(Instructor), transformFallbackImg), Instructor)
    public instructors;
}
setupModel(_Properties,{});


export class _OngoingMasterSeries extends baseModel{

    @propertyMaps('_id',_.str)
    public id;

    @propertyMaps('marketingDetails',objModel(_OngoingMasterSeriesMarketingDetails),_OngoingMasterSeriesMarketingDetails)
    public marketingDetails;

    @propertyMaps('properties',pipeModel(objModel(_Properties), transformOngoingProperties),_Properties)
    public properties;

}
setupModel(_OngoingMasterSeries,{});


export class _OngoingMasterSeriesWrapper extends baseModel{
    @propertyMaps('ongoingSeries',arrayModel(_OngoingMasterSeries),_OngoingMasterSeries)
    public ongoingSeries;
}
setupModel(_OngoingMasterSeriesWrapper,{});

export class GetOngoingMasterSeriesApi{
    static apiEndpoint = 'v1/mclass-series/ongoing';

    static projection;
    static get  __projection(){ 
        if(!GetOngoingMasterSeriesApi.projection){
            GetOngoingMasterSeriesApi.projection = JSON.stringify(new SourceProjectionMapper(_OngoingMasterSeriesWrapper).map());
        }
        return GetOngoingMasterSeriesApi.projection;
    }

    static apiCall(network:Network,params:any){
        let _params = {...params};
        if(params.parentId){
            let key = 'superGroupId';
            if(params.parentType) {
                key = params.parentType === 'target' ? 'targetId' : 'superGroupId';
                delete _params.parentType;
            }
            _params = {
                ..._params,
                [key]: params.parentId
            };
            delete _params.parentId;
        }

        if(typeof params.isRecommended !== 'undefined'){
            _params.isForYou = _params.isRecommended;
            delete _params.isRecommended
        }
        if(params.customTagIds === 'all'){
            delete _params.customTagIds;
        }
        return network.get(GetOngoingMasterSeriesApi.apiEndpoint,{..._params,__projection:GetOngoingMasterSeriesApi.__projection});
    }
}
