
    <div class="progress-holder {{cssModifierClass}}">
        <div class="progress-holder__banner" *ngIf="title" [ngStyle]="{'background':  bgPath ? 'url(' + bgPath+')' : ''}">
            <h4>{{title}}</h4>
            <p translate>SHARE_AND_WIN</p>
        </div>
        <div class="progress">
            <div class="progress__stage" [ngClass]="{'progress__stage--active':currentStageIndex === formStage || formStage > currentStageIndex}" *ngFor="let item of stageCountArr;let currentStageIndex = index">
                <div class="progress__indicator">{{currentStageIndex + 1}}
                    <div class="progress__label" *ngIf="stageNameArray && stageNameArray[currentStageIndex]">
                        {{stageNameArray[currentStageIndex].text}}
                    </div>
                </div>
                <div class="progress__track" *ngIf="currentStageIndex !== stageCount - 1">
                    <span *ngIf="formStage !== stageCount - 1" [ngStyle]="{'width': (formStage === currentStageIndex)? '50%' : (formStage > currentStageIndex)? '100%' : '0%'}"></span>
                    <span *ngIf="formStage === stageCount - 1" [ngStyle]="{'width': '100%' }"></span>
                </div>
            </div>
        </div>
    </div>
