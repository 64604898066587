import {OnboardingParams} from "@shared/types/login";
import {Validator as _} from "@shared/utility/validator";
import { isServer} from "@shared/utility/tb-common";

export function isWhitelisted(url){
    try{
        let host = new URL(url).hostname;
        return (host === "testbook.com" || host.endsWith('.testbook.com') || host === "plutuseducation.com" || host.endsWith('.plutuseducation.com') || host === 'kmatkerala.in' || host === 'gondwana.university' || isYoutubeUrl(url));
    } catch(e){
        return false;
    }
}

export function isValidHost(url){
    try{
        let host = new URL(url).hostname;
        return (host === "localhost" || host.includes('.'))
    } catch(e){
        return false;
    }
}

export function isYoutubeUrl(url){
    try{
        var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        return url.match(p);
    }catch (e){
        return false;
    }
}

export function getRedirectUrl(url, pathname , useHost = 'testbook.com'){
    let finalUrl = url || '/home';
    let location;
    if(isServer()) {
        location = new URL(`https://${useHost}${pathname}`);
    } else{
        location = window && window.location;
    }


    if(finalUrl[0] === '/' && finalUrl[1] !== '/'){
        //single slash
        finalUrl = `${location.protocol}//${location.host}${finalUrl}`
    } else if(!finalUrl.startsWith('http')){
        // not starts with http & not single slash
        if(finalUrl.startsWith('//')){
            // double slash add protocol
            finalUrl = `${location.protocol}${finalUrl}`;
        } else {
            // is it a domain or relative url without "/" we dont know
            let testUrl = `${location.protocol}//${finalUrl}`;
            if(isValidHost(testUrl)){
                finalUrl = testUrl;
            } else {
                finalUrl = `${location.protocol}//${location.host}/${finalUrl}`;
            }
        }
    }

    if(!isWhitelisted(finalUrl)){
        finalUrl = `${location.protocol}//${location.host}${('/super-coaching' || '/home')}`;
    }

    return finalUrl;
}

export function isIntrovertUA(ua = ''){
    return ua.toLowerCase().includes('phonepe-webview');
}

export function stringToBool(str: string | null | undefined): boolean {
    return str === 'true' || str === '1';
}

export function populateOnboardingParams(params, ua, pathname){
    let paramsObj : OnboardingParams = <OnboardingParams>{};

    paramsObj.original = {...params};
    paramsObj.isPlutus = stringToBool(decodeURIComponent(params["isPlutus"]));
    paramsObj.isInModal = stringToBool(decodeURIComponent(params["modal"]));
    paramsObj.hideSocialSignups = stringToBool(decodeURIComponent(params["hideSocialSignups"])) || isIntrovertUA(ua);
    paramsObj.hideAppSignup = stringToBool(decodeURIComponent(params["hideSocialSignups"]));
    paramsObj.hideMobileField = stringToBool(decodeURIComponent(params["hideMobileField"]));
    paramsObj.redirect_url = getRedirectUrl(params["redirect_url"] && decodeURIComponent(params["redirect_url"]), pathname);
    paramsObj.fragment = !_.str(params["forceTile"]) ? 'signup' : (_.str(params["tile"]) || 'signup');
    paramsObj.gtmExtras = _.safeJsonParse(params["gtmExtras"])
    paramsObj.targets = _.str(paramsObj.gtmExtras.target);
    paramsObj.masterClassSeries=params["masterClassSeries"] || ''
    paramsObj.heading = _.str(params["headerText"]);
    paramsObj.page = _.str(params["page"]);
    paramsObj.category = _.str(params["category"]);
    paramsObj.pagePath = _.str(params["pagePath"]);
    paramsObj.skipOnboarding = _.bool(!!params["skipOnboarding"]);
    paramsObj.referrer = _.str(params["referrer"]);
    paramsObj.referrerType = decodeURIComponent(_.str(params["referrerType"]));

    // password reset
    paramsObj.token = _.str(params["t"]);
    paramsObj.otp = _.str(params["o"]);
    paramsObj.mobile = _.str(params["m"]);
    paramsObj.email = _.str(params["e"]);

    if(paramsObj.token){
        paramsObj.fragment = "forgotpassword"
    }

    //google signin
    paramsObj.googleState = _.safeJsonParse(params["state"]);
    paramsObj.googleCode = _.str(params["code"]);

    paramsObj.redirect_url = paramsObj.googleState.redirect_url || paramsObj.redirect_url;

    // override from google signin params if available
    paramsObj.referrer = paramsObj.referrer || _.str(paramsObj.googleState.referrer);
    paramsObj.referrerType = paramsObj.referrerType || _.str(paramsObj.googleState.referrerType);

    let methods =  _.arr( params["methods"] && params["methods"].split(",").map(i=>i.trim()).filter(i=>!!i)); // login methods
    paramsObj.methods = { email : !params["methods"] || methods.includes('email') , mobile : !params["methods"] || methods.includes('mobile')  }
    paramsObj.referralCode = _.str(params["referralCode"]); // referral code
    paramsObj.referral = !!_.bool(params["referral"]);
    return paramsObj;
}
