import { Validator as _ } from "@shared/utility/validator";
import {baseModel, setupModel} from "@models/types";
import {arrayModel, objModel, pipeModel, propertyMaps, SourceProjectionMapper } from "@models/mapping-framework";
import {Network} from "@core/services/model-manager/main";
import {getGradient} from "@shared/utility/color-util";
import {getInMultipleOfK} from "@shared/utility/tb-common";
import {_Test, TestSubmissions} from "@models/entity/targets/target.test.adapter";
import {
    processTests,
    processTest,
    languageModifier,
    addTestAvailableKey,
    addTestAvailableKeys
} from "@shared/utility/test-utilities";
import {PurchaseInfo} from "@models/entity/purchaseInfo";

function calculateMoreTests(arr) {
        return arr && arr.slice(3).reduce((total,curr) =>{return total + Number(curr.paidTestCount) + Number(curr.freeTestCount)} ,0);
}

function calculateTotalAttemptDecimal(number){
    return (number/1000).toFixed(1);
}

function processLanguage(languages){
    return languages.slice(0,2).join(", ");
}

function processStudentCountIn10K(noOfStudents){
    return noOfStudents> 10000 ? getInMultipleOfK(noOfStudents) : noOfStudents;
}

function processSections(sections) {
    if (sections) {
        let processedSections = (sections.length && sections.filter((section) => section.paidTestCount !== 0 || section.freeTestCount !== 0)) || [];
        let remainingSections = {};
        if (processedSections.length > 3) {
            remainingSections = processedSections.splice(3).reduce( (acc, section) => {
                let paidTestCount = acc.paidTestCount + section.paidTestCount
                let freeTestCount = acc.freeTestCount + section.freeTestCount;
                acc = {...acc, paidTestCount, freeTestCount}
                return acc;
            });
            remainingSections = {...remainingSections, 
                name: 'more tests'
            }
            processedSections.push(remainingSections);
        }
        return processedSections;
    }
    return [];
}

export function processTestSeries(testSeries) {
    if(testSeries?.colourHex){
        testSeries.gradient = getGradient(testSeries?.colourHex);
    }
    if(testSeries?.sections){
        testSeries.moreTests = calculateMoreTests(testSeries?.sections);
    }
    if(testSeries?.languages){
        testSeries.initialLanguages = processLanguage(testSeries?.languages);
    }
    if(testSeries?.totalAttempts){
        testSeries.totalAttemptsDecimal = calculateTotalAttemptDecimal(testSeries?.totalAttempts);
    }
    testSeries.totalTestCount = (testSeries?.paidTestCount + testSeries?.freeTestCount) || 0;
    testSeries.url = '/' + testSeries?.slug + '/test-series';

    let allBranchId='639b2fa5dad6971351a809cb';

    if(testSeries?.branches?.values?.length > 0 && testSeries?.branches?.values?.[0]?.id != allBranchId){
        let element=[{id:allBranchId,name:'All'}];
        testSeries.branches.values = element.concat(testSeries.branches.values);
    }

    if(testSeries?.target) {
        testSeries.primaryTarget = testSeries.target.filter(item => item.isPrimary)[0];
    }
    return testSeries;
}

export function processTestSeriesArr(arr) {
    for(let testSeries of arr) {
        testSeries = processTestSeries(testSeries);
    }
    return arr;
}

export function processStudentStage(student) {
    if (student?.selectedStage && student?.stageWiseStats?.length > 0) {
      const selectedStageExists = student?.stageWiseStats.some(
        (stats) => stats?.stage === student?.selectedStage
      );
  
      if (!selectedStageExists && student?.stageWiseStats?.[0]?.stage) {
        student.selectedStage = student?.stageWiseStats?.[0]?.stage;
      }
    }
    return student;
}
  

export function mapTestSeriesArr(testSeriesArr) {
    if (Array.isArray(testSeriesArr) && testSeriesArr?.length) {
        return testSeriesArr.map(obj => ({details: obj, studentStats: obj?.studentStats})) || [];
    }
    return (testSeriesArr.testSeries?.length && testSeriesArr.testSeries.map(obj => ({details: obj, studentStats: obj?.studentStats}))) || [];
}

export function processTestSeriesSummary(testSummary){
    return testSummary.map((item)=>{
        if (item?.attemptRate){
            item.attemptGraph = _.numToFixed(item.attemptRate - item.prevAttemptRate);
        }
        if (item?.accuracy){
            item.accuracyGraph = _.numToFixed(item?.accuracy - item?.prevAccuracy);
        }
        if (item?.averageScore){
            item.averageScoreGraph = _.numToFixed(item?.averageScore - item?.prevAverageScore);
        }
        if (item?.rank){
            item.rankGraph = _.numToFixed(item?.prevRank - item?.rank);
            item.rankGraphInk =processStudentCountIn10K(item.rankGraph);
        }
        return item;
    })
}

export class SubSection extends baseModel {
    @propertyMaps('id',_.str)
    public id;

    @propertyMaps('name',_.str)
    public name;

    @propertyMaps('paidTestCount',_.num)
    public paidTestCount;

    @propertyMaps('freeTestCount',_.num)
    public freeTestCount;

    @propertyMaps('order',_.num)
    public order;
}
setupModel(SubSection, {});

export class Section extends baseModel {
    @propertyMaps('id',_.str)
    public id;

    @propertyMaps('name',_.str)
    public name;

    @propertyMaps('shortName',_.str)
    public shortName;

    @propertyMaps('subsections',arrayModel(SubSection), SubSection)
    public subsections;

    @propertyMaps('paidTestCount',_.num)
    public paidTestCount;

    @propertyMaps('freeTestCount',_.num)
    public freeTestCount;

    @propertyMaps('order',_.num)
    public order;

    @propertyMaps("isPro",_.bool)
    public isPro;

    @propertyMaps("superSectionId",_.str)
    public superSectionId;
}
setupModel(Section,{});

export class Branches extends baseModel {
    @propertyMaps('id',_.str)
    public id;

    @propertyMaps('name',_.str)
    public name;
}
setupModel(Branches,{});

export class BranchesInfo extends baseModel {
    @propertyMaps('label',_.str)
    public label;

    @propertyMaps('values',arrayModel(Branches), Branches)
    public values;
}
setupModel(BranchesInfo,{});

export class stageWiseStats extends baseModel {
    @propertyMaps('stage',_.str)
    public stage;

    @propertyMaps('fullTestAttempted',_.num)
    public fullTestAttempted;

    @propertyMaps('attemptRate',_.num)
    public attemptRate;

    @propertyMaps('prevAttemptRate',_.num)
    public prevAttemptRate;

    @propertyMaps('averageScore',_.num)
    public averageScore;

    @propertyMaps('averageScore',_.numToFixed)
    public averageScoreRoundOff;

    @propertyMaps('prevAverageScore',_.num)
    public prevAverageScore;

    @propertyMaps('maxScore',_.num)
    public maxScore;

    @propertyMaps('rank',_.num)
    public rank;

    @propertyMaps('prevRank',_.num)
    public prevRank;

    @propertyMaps('accuracy',_.num)
    public accuracy;

    @propertyMaps('prevAccuracy',_.num)
    public prevAccuracy;

    @propertyMaps('totalStudents',_.num)
    public totalStudents;

    @propertyMaps('totalStudents',processStudentCountIn10K)
    public totalStudentsCountInK;

    @propertyMaps('rank',processStudentCountIn10K)
    public rankCountInK;

    public remainingTest;

    public attemptGraph;

    public accuracyGraph;

    public averageScoreGraph;

    public rankGraph;
    
    public rankGraphInk;
}
setupModel(stageWiseStats, {});
export class AccessDetails {
    @propertyMaps('canAccess', _.bool)
    public canAccess;

    @propertyMaps('enrolled', _.bool)
    public enrolled;

    @propertyMaps('autoEnrolled', _.bool)
    public autoEnrolled;

}
setupModel(AccessDetails, {type: 'test-series'})

export class StudentStats {
    @propertyMaps('testsAttempted', _.num)
    public testsAttempted;

    @propertyMaps('selectedStage', _.str)
    public selectedStage;

    @propertyMaps('selectedBranch', _.str)
    public selectedBranch;

    @propertyMaps('accessDetails', objModel(AccessDetails), AccessDetails)
    public accessDetails;
    
    @propertyMaps('stageWiseStats',pipeModel(arrayModel(stageWiseStats), processTestSeriesSummary), stageWiseStats)
    
    public stageWiseStats;

    public maxFullTestAttempted;

}
setupModel(StudentStats, {type: 'test-series'});

export class SuperSections{
    @propertyMaps('id', _.str)
    public id;

    @propertyMaps('name', _.str)
    public name;

    @propertyMaps('isPro', _.bool)
    public isPro;
}
setupModel(SuperSections,{type:'test-series'});

export class StudentStatsCard {
    @propertyMaps('testsAttempted', _.num)
    public testsAttempted;

    @propertyMaps('accessDetails', objModel(AccessDetails), AccessDetails)
    public accessDetails;

}
setupModel(StudentStatsCard, {type: 'test-series'});

export class Rankers {
    @propertyMaps('name', _.str)
    public name;

    @propertyMaps('dp', _.profilePic)
    public dp;

    @propertyMaps('rank', _.num)
    public rank;

    @propertyMaps('percentile', _.numToFixed)
    public percentile;

    @propertyMaps('id', _.str)
    public id;
}
setupModel(Rankers, {});

export class TestSeriesFeature{
    @propertyMaps('title', _.str)
    public title;

    @propertyMaps('subtitle', _.str)
    public subtitle;

    @propertyMaps('iconUrl', _.str)
    public iconUrl;

    @propertyMaps('clientToShow', _.str)
    public clientToShow;
}
setupModel(TestSeriesFeature, {});

//ToDo: to be completed
export class TestSeriesSeo {
    @propertyMaps('ogProperties', _.obj)
    public ogProperties;

    @propertyMaps('isHindiPresent', _.bool)
    public isHindiPresent;

    @propertyMaps('metaTitle', _.str)
    public metaTitle;

    @propertyMaps('metaDescription', _.str)
    public metaDescription;

    @propertyMaps('metaKeywords', _.str)
    public metaKeywords;
}
setupModel(TestSeriesSeo, {});

export class TestSeriesTarget {
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('_id', _.str)
    public _id;

    @propertyMaps('title', _.str)
    public title;

    @propertyMaps('isPrimary', _.bool)
    public isPrimary;

    @propertyMaps('slug', _.str)
    public slug;
}
setupModel(TestSeriesTarget, {})

export class SectionCard extends baseModel {
    @propertyMaps('id',_.str)
    public id;

    @propertyMaps('name',_.str)
    public name;

    @propertyMaps('paidTestCount',_.num)
    public paidTestCount;

    @propertyMaps('freeTestCount',_.num)
    public freeTestCount;
}
setupModel(SectionCard,{});

export class TestSeries {
    @propertyMaps('id', _.str)
    public id;

    @propertyMaps('name', _.str)
    public name;

    @propertyMaps('description', _.str)
    public description;

    @propertyMaps('features', arrayModel(TestSeriesFeature), TestSeriesFeature)
    public features;

    @propertyMaps('icon', _.str)
    public icon;

    @propertyMaps('colourHex', _.str)
    public colourHex;

    @propertyMaps('showSyllabus', _.bool)
    public showSyllabus;

    @propertyMaps('faqs')
    public faqs;

    @propertyMaps('menus')
    public menus;

    @propertyMaps('updatedOn')
    public updatedOn;

    @propertyMaps('seo', objModel(TestSeriesSeo), TestSeriesSeo)
    public seo;

    @propertyMaps('sections', arrayModel(Section), Section)
    public sections;

    @propertyMaps('branches', objModel(BranchesInfo), BranchesInfo)
    public branches;

    @propertyMaps('sections', processSections, Section)
    public condensedSections;

    @propertyMaps('paidTestCount', _.num)
    public paidTestCount;

    @propertyMaps('freeTestCount', _.num)
    public freeTestCount;

    @propertyMaps('canPurchaseThrough', _.str)
    public canPurchaseThrough;

    @propertyMaps('slug', _.str)
    public slug;

    @propertyMaps('slugUrl', _.str)
    public slugUrl;

    @propertyMaps('isFree', _.bool)
    public isFree;

    @propertyMaps('totalAttempts', _.str)
    public totalAttempts;

    @propertyMaps('target', arrayModel(TestSeriesTarget), TestSeriesTarget)
    public target;

    @propertyMaps('targetGroup', arrayModel(TestSeriesTarget), TestSeriesTarget)
    public targetGroup;

    @propertyMaps('targetSuperGroup', arrayModel(TestSeriesTarget), TestSeriesTarget)
    public targetSuperGroup;

    @propertyMaps('totalAttempts', getInMultipleOfK)
    public testSeriesEnrolledStr;

    @propertyMaps('languages', _.arr)
    public languages;

    @propertyMaps('languages', languageModifier(', ',false))
    public languagesStr;

    @propertyMaps('studentStats', objModel(StudentStats), StudentStats)
    public studentStats;

    @propertyMaps('purchaseInfo', arrayModel(PurchaseInfo), PurchaseInfo)
    public purchaseInfo;

    @propertyMaps('showAnalysis', _.bool)
    public showAnalysis; 

    @propertyMaps('superSections',arrayModel(SuperSections),SuperSections)
    public superSections;

    @propertyMaps('servesFrom', _.arr)
    public servesFrom;

    public gradient;

    public moreTests;

    public primaryTarget;

    public totalAttemptsDecimal;

    public initialLanguages;

    public totalTestCount;

}
setupModel(TestSeries, {type: 'test-series'})

export class TestSeriesRelated {
    @propertyMaps('id', _.str)
    public id;

    @propertyMaps('name', _.str)
    public name;

    @propertyMaps('icon', _.str)
    public icon;

    @propertyMaps('branches', objModel(BranchesInfo), BranchesInfo)
    public branches;

    @propertyMaps('paidTestCount', _.num)
    public paidTestCount;

    @propertyMaps('freeTestCount', _.num)
    public freeTestCount;

    @propertyMaps('canPurchaseThrough', _.str)
    public canPurchaseThrough;

    @propertyMaps('slug', _.str)
    public slug;

    @propertyMaps('slugUrl', _.str)
    public slugUrl;

    @propertyMaps('isFree', _.bool)
    public isFree;

    @propertyMaps('totalAttempts', _.str)
    public totalAttempts;

    @propertyMaps('target', arrayModel(TestSeriesTarget), TestSeriesTarget)
    public target;

    @propertyMaps('totalAttempts', getInMultipleOfK)
    public testSeriesEnrolledStr;

    @propertyMaps('studentStats', objModel(StudentStats), StudentStats)
    public studentStats;

    public primaryTarget;

    public totalTestCount;

}
setupModel(TestSeriesRelated, {type: 'test-series-related'})

export class TestSeriesCard {
    @propertyMaps('id', _.str)
    public id;

    @propertyMaps('name', _.str)
    public name;

    @propertyMaps('icon', _.str)
    public icon;

    @propertyMaps('colourHex', _.str)
    public colourHex;

    @propertyMaps('sections', arrayModel(SectionCard), SectionCard)
    public sections;

    @propertyMaps('paidTestCount', _.num)
    public paidTestCount;

    @propertyMaps('freeTestCount', _.num)
    public freeTestCount;

    @propertyMaps('slug', _.str)
    public slug;

    @propertyMaps('isFree', _.bool)
    public isFree;

    @propertyMaps('totalAttempts', _.str)
    public totalAttempts;

    @propertyMaps('totalAttempts', getInMultipleOfK)
    public testSeriesEnrolledStr;

    @propertyMaps('languages', _.arr)
    public languages;

    @propertyMaps('studentStats', objModel(StudentStatsCard), StudentStatsCard)
    public studentStats;

    public gradient;

    public moreTests;
}
setupModel(TestSeriesCard, {type: 'test-series-card'})

export class testSeriesByCategoryWrapper extends baseModel{
    @propertyMaps('categories')
    public categories;

}
setupModel(testSeriesByCategoryWrapper,{});

export class RegisteredTests extends baseModel {
    @propertyMaps('registeredQuizzes', _.arr)
    public registeredQuizzes;

    @propertyMaps('registeredTests', _.arr)
    public registeredTests;
}
setupModel(RegisteredTests, {});

export class SequenceDataModal extends baseModel {
    @propertyMaps('id', _.str)
    public id;

    @propertyMaps('title', _.str)
    public title;

    @propertyMaps('component', _.str)
    public component;

    @propertyMaps('weight', _.num)
    public weight;

    @propertyMaps('isHidden', _.bool)
    public isHidden;
}
setupModel(SequenceDataModal, {});

export class SequenceModal extends baseModel {
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('type', _.str)
    public type;

    @propertyMaps('role', _.num)
    public role;

    @propertyMaps('userType', _.str)
    public userType;

    @propertyMaps('attemptedTest', _.bool)
    public attemptedTest;

    @propertyMaps('sequences', arrayModel(SequenceDataModal), SequenceDataModal)
    public sequences;
}
setupModel(SequenceModal, {});

export class baseTestSeriesByCategoryWrapper extends baseModel{

    @propertyMaps('count', _.num )
    public count;

    @propertyMaps('categoryId', _.str)
    public id;

    @propertyMaps('categoryName', _.str)
    public title;

     @propertyMaps('icon', _.str)
    public icon;

    @propertyMaps('order', _.num)
    public order;

}
setupModel(baseTestSeriesByCategoryWrapper,{});

export class _testSeriesByCategoryWrapper extends baseTestSeriesByCategoryWrapper{}
setupModel(_testSeriesByCategoryWrapper,{},arrayModel(baseTestSeriesByCategoryWrapper));

export class TestSeriesMe extends TestSeries{
    @propertyMaps('studentStats', objModel(StudentStats), StudentStats)
    public studentStats;
}
setupModel(TestSeriesMe,{type:  'test-series'})

export class TestSeriesDetails extends baseModel {
    @propertyMaps('details', pipeModel(objModel(TestSeries), processTestSeries), TestSeries)
    public details;

    @propertyMaps('studentStats', pipeModel(objModel(StudentStats),processStudentStage), StudentStats)
    public studentStats;
    
    public stageWiseDetails: any;

    public maxfulltestAttemptedCount: any;

}
setupModel(TestSeriesDetails,{});

export class TestSeriesDetailsCards extends baseModel {
    @propertyMaps('details', pipeModel(objModel(TestSeriesCard), processTestSeries), TestSeriesCard)
    public details;

    @propertyMaps('studentStats', objModel(StudentStatsCard), StudentStatsCard)
    public studentStats;
}
setupModel(TestSeriesDetailsCards,{});

export class RelatedTestSeries extends baseModel {
    @propertyMaps('testSeries', pipeModel(arrayModel(TestSeriesRelated), processTestSeriesArr), TestSeriesRelated)
    public testSeries;
}
setupModel(RelatedTestSeries, {})

export class RecentTestSeries extends baseModel{
    @propertyMaps('testSeries', pipeModel(arrayModel(TestSeriesMe), processTestSeriesArr), TestSeriesMe)
    public testSeries;
}
setupModel(RecentTestSeries, {});

export class PopularTestSeriesArr extends baseModel {
    @propertyMaps('popularTestSeries', pipeModel(arrayModel(TestSeriesCard), processTestSeriesArr), TestSeries)
    public popularTestSeries;
}
setupModel(PopularTestSeriesArr,{});

export class LatestTestSeriesArr extends baseModel {
    @propertyMaps('latestTestSeries', pipeModel(arrayModel(TestSeriesCard), processTestSeriesArr), TestSeries)
    public latestTestSeries;
}
setupModel(LatestTestSeriesArr,{});

export class ChapterTests extends baseModel {
    @propertyMaps('tests', pipeModel(arrayModel(_Test), processTests, addTestAvailableKeys), _Test)
    public tests;
}
setupModel(ChapterTests, {});

export class DashboardModel extends baseModel {
    @propertyMaps('sequence', arrayModel(SequenceModal), SequenceModal)
      public sequence;
  }
  setupModel(DashboardModel, {});

export class SuggestedTest extends baseModel {
    @propertyMaps('isNextTest', _.bool)
    public isNextTest;

    @propertyMaps('isResumbleTest', _.bool)
    public isResumbleTest;

    @propertyMaps('testDetails', pipeModel(objModel(_Test), processTest, addTestAvailableKey), _Test)
    public testDetails;
}
setupModel(SuggestedTest, {});

export class Leaderboard extends baseModel {
    @propertyMaps('stageName', _.str)
    public stageName;
   
    @propertyMaps('me', objModel(Rankers), Rankers)
    public me;

    @propertyMaps('rankers',arrayModel(Rankers), Rankers)
    public rankers;
}
setupModel(Leaderboard, {});

export class enrollUnenrollParams extends baseModel {
    @propertyMaps('tsId', _.str)
    public tsId;
}
setupModel(enrollUnenrollParams, {});

export class GoalTestSeries extends baseModel {
    @propertyMaps('testSeries', arrayModel(TestSeriesDetails), TestSeriesDetails)
    public testSeries;
}
setupModel(GoalTestSeries, {});

export class categoryList extends baseModel {
    @propertyMaps('testSeries', arrayModel(TestSeriesDetailsCards), TestSeriesDetailsCards)
    public testSeries;
}
setupModel(categoryList, {});

export class _GoalEnrolledTestSeries extends baseModel {
    @propertyMaps('testSeries', arrayModel(TestSeries), TestSeries)
    public testSeries;
}
setupModel(_GoalEnrolledTestSeries, {});

export class GoalEnrolledTestSeries extends baseModel {
    @propertyMaps('testSeries', pipeModel(mapTestSeriesArr, arrayModel(TestSeriesDetails)), TestSeriesDetails)
    public testSeries;
}
setupModel(GoalEnrolledTestSeries, {});
export class GetPopularTestSeries{
    static apiEndpoint = 'v1/test-series/popular';

    static projection;
    static get  __projection(){ 
        if(!GetPopularTestSeries.projection){
            GetPopularTestSeries.projection = JSON.stringify(new SourceProjectionMapper(PopularTestSeriesArr).map());
        }
        return GetPopularTestSeries.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(GetPopularTestSeries.apiEndpoint,{__projection:GetPopularTestSeries.__projection,...params});
    }
}
export class GetLatestTestSeries{
    static  apiEndpoint = 'v1/test-series/latest';
    static projection;
    static get  __projection(){ 
        if(!GetLatestTestSeries.projection){
            GetLatestTestSeries.projection = JSON.stringify(new SourceProjectionMapper(LatestTestSeriesArr).map());
        }
        return GetLatestTestSeries.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(GetLatestTestSeries.apiEndpoint,{__projection:GetLatestTestSeries.__projection,...params});
    }
}

export class GetTestSeriesDetails {
    static apiEndpoint = 'v1/test-series/slug';
    static projection;
    static get  __projection(){ 
        if(!GetTestSeriesDetails.projection){
            GetTestSeriesDetails.projection = JSON.stringify(new SourceProjectionMapper(TestSeriesDetails).map());
        }
        return GetTestSeriesDetails.projection;
    }
    static apiCall(network:Network,params:any){
        return network.get(GetTestSeriesDetails.apiEndpoint,{
            __projection:GetTestSeriesDetails.__projection,
            url: params.idOrSlug,
            branchId:params?.branchId || ''
        });
    }
}

export class getLeaderboardApi {
    static apiEndpoint = 'v1/test-series/{testSeriesId}/leaderboard';
    static projection;
    static get  __projection(){ 
        if(!getLeaderboardApi.projection){
            getLeaderboardApi.projection = JSON.stringify(new SourceProjectionMapper(Leaderboard).map());
        }
        return getLeaderboardApi.projection;
    }

     static apiCall(network:Network,params:any){
        return network.get(getLeaderboardApi.apiEndpoint.replace('{testSeriesId}', params.testSeriesId), {stageName: params.stageName , __projection:getLeaderboardApi.__projection});
    }
}

export class GetRelatedTestSeries {
    static apiEndpoint = 'v2/test-series/{id}/related-ts';
    static projection;
    static get  __projection(){ 
        if(!GetRelatedTestSeries.projection){
            GetRelatedTestSeries.projection = JSON.stringify(new SourceProjectionMapper(RelatedTestSeries).map());
        }
        return GetRelatedTestSeries.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(GetRelatedTestSeries.apiEndpoint.replace('{id}', params.id),{__projection:GetRelatedTestSeries.__projection});
    }
}

export class GetRecentTestSeries {
    
    static apiEndpoint = 'v2/test-series/me';
    static projection;
    static get  __projection(){ 
        if(!GetRecentTestSeries.projection){
            GetRecentTestSeries.projection = JSON.stringify(new SourceProjectionMapper(RecentTestSeries).map());
        }
        return GetRecentTestSeries.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(GetRecentTestSeries.apiEndpoint,{__projection:GetRecentTestSeries.__projection});
    }
}

export class GetChapterTests {
    static apiEndpoint = 'v2/test-series/{id}/tests/details';
    static projection;
    static get  __projection(){ 
        if(!GetChapterTests.projection){
            GetChapterTests.projection = JSON.stringify(new SourceProjectionMapper(ChapterTests).map());
        }
        return GetChapterTests.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(GetChapterTests.apiEndpoint.replace('{id}', params.tsId),{
                __projection:GetChapterTests.__projection,
                testType: params.testType,
                sectionId: params.sectionId,
                subSectionId: params.subSectionId,
                skip: params.skip,
                limit: params.limit,
                branchId:params.branchId
            }
        );
    }
}

export class GetChapterAttemptedTestsApi {
    static apiEndpoint = 'v2/test-series/{id}/tests/submissions';

    static projection;
    static get  __projection(){ 
        if(!GetChapterAttemptedTestsApi.projection){
            GetChapterAttemptedTestsApi.projection = JSON.stringify(new SourceProjectionMapper(TestSubmissions).map());
        }
        return GetChapterAttemptedTestsApi.projection;
    }

    static apiCall(network: Network, params: any) {
        return network.get(GetChapterAttemptedTestsApi.apiEndpoint.replace('{id}', params.tsId), {
            __projection: GetChapterAttemptedTestsApi.__projection,
            sectionId: params.sectionId,
            subSectionId: params.subSectionId,
            skip: params.skip,
            limit: params.limit,
            branchId:params.branchId,
            testType: params.testType,
        })
    }
}


export class GetSuggestedTestApi {
    static apiEndpoint = 'v2/test-series/{id}/suggested-tests';
    static projection;
    static get  __projection(){ 
        if(!GetSuggestedTestApi.projection){
            GetSuggestedTestApi.projection = JSON.stringify(new SourceProjectionMapper(SuggestedTest).map());
        }
        return GetSuggestedTestApi.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(GetSuggestedTestApi.apiEndpoint.replace('{id}', params.id),{__projection:GetSuggestedTestApi.__projection,branchId:params.branchId});
    }
}

export class GetSuggestedFullTestApi {
    static apiEndpoint = 'v1/test-series/{testSeriesId}/suggested-full-test';
    static projection;
    static get  __projection(){ 
        if(!GetSuggestedFullTestApi.projection){
            GetSuggestedFullTestApi.projection = JSON.stringify(new SourceProjectionMapper(SuggestedTest).map());
        }
        return GetSuggestedFullTestApi.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(GetSuggestedFullTestApi.apiEndpoint.replace('{testSeriesId}', params.testSeriesId),{__projection:GetSuggestedFullTestApi.__projection,examStage:params?.examStage});
    }
}
export class GetRegisteredTestsApi {
    static apiEndpoint = 'v1/tests/registered';

    static projection;
    static get  __projection(){ 
        if(!GetRegisteredTestsApi.projection){
            GetRegisteredTestsApi.projection = JSON.stringify(new SourceProjectionMapper(RegisteredTests).map());
        }
        return GetRegisteredTestsApi.projection;
    }

    static apiCall(network: Network, params: any) {
        return network.get(GetRegisteredTestsApi.apiEndpoint,{__projection:GetRegisteredTestsApi.__projection, tests: params.testIds});
    }
}


export class EnrollInTestSeriesApi {
    static apiEndpoint = 'v2/test-series/{id}/enroll';

    static apiCall(network:Network,params:any){
        return network.post(EnrollInTestSeriesApi.apiEndpoint.replace('{id}', params.tsId));
    }
}

export class UnEnrollInTestSeriesApi {
    static apiEndpoint = 'v2/test-series/{id}/unenroll';

    static apiCall(network:Network,params:any){
        return network.post(UnEnrollInTestSeriesApi.apiEndpoint.replace('{id}', params.tsId));
    }
}

export class GetDashboardInfoSequence {
    static apiEndpoint = 'v1/dashboard-info';

    static projection;
    static get  __projection(){ 
        if(!GetDashboardInfoSequence.projection){
            GetDashboardInfoSequence.projection = JSON.stringify(new SourceProjectionMapper(DashboardModel).map());
        }
        return GetDashboardInfoSequence.projection;
    }

    static apiCall(network: Network, params: any) {
        return network.get(GetDashboardInfoSequence.apiEndpoint,{__projection:GetDashboardInfoSequence.__projection, ...params.params});
    }
}

export class GetTestSeriesByGoalIdApi {
    static apiEndpoint = 'v2/test-series';

    static projection;
    static get  __projection(){ 
        if(!GetTestSeriesByGoalIdApi.projection){
            GetTestSeriesByGoalIdApi.projection = JSON.stringify(new SourceProjectionMapper(GoalTestSeries).map());
        }
        return GetTestSeriesByGoalIdApi.projection;
    }

    static apiCall(network: Network, params: any) {
        let qParams = {
            goalIds: params.goalId,
            skip: params?.skip || 0,
            limit: params?.limit || 0,
            notEnrolled: params?.notEnrolled || false,
            customTagIds: params?.customTagIds || ''
        }
       return network.get(GetTestSeriesByGoalIdApi.apiEndpoint, {...qParams, __projection: GetTestSeriesByGoalIdApi.__projection});
    }
}

export class GetEnrolledTestSeriesByGoalIdApi {
    static apiEndpoint = 'v2/test-series/me';

    static projection;
    static get  __projection(){ 
        if(!GetEnrolledTestSeriesByGoalIdApi.projection){
            GetEnrolledTestSeriesByGoalIdApi.projection = JSON.stringify(new SourceProjectionMapper(_GoalEnrolledTestSeries).map());
        }
        return GetEnrolledTestSeriesByGoalIdApi.projection;
    }

    static apiCall(network: Network, params: any) {
       return network.get(GetEnrolledTestSeriesByGoalIdApi.apiEndpoint, {goalIds: params.goalId, skip: params.skip, limit: params.limit, __projection: GetEnrolledTestSeriesByGoalIdApi.__projection});
    }
}

export class GetSuggestedTestByGoalApi {
    static apiEndpoint = 'v2/test-series/suggested-tests';
    static projection;
    static get  __projection(){ 
        if(!GetSuggestedTestByGoalApi.projection){
            GetSuggestedTestByGoalApi.projection = JSON.stringify(new SourceProjectionMapper(SuggestedTest).map());
        }
        return GetSuggestedTestByGoalApi.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(GetSuggestedTestByGoalApi.apiEndpoint, {goalIds: params.goalId , __projection:GetSuggestedTestByGoalApi.__projection});
    }
}
export class GetTesSeriesCategoryApi {
    static apiEndpoint = 'v1/test-series/categories';

    static projection;
    static get  __projection(){ 
        if(!GetTesSeriesCategoryApi.projection){
            GetTesSeriesCategoryApi.projection = JSON.stringify(new SourceProjectionMapper(_testSeriesByCategoryWrapper).map());
        }
        return GetTesSeriesCategoryApi.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(GetTesSeriesCategoryApi.apiEndpoint, {__projection:GetTesSeriesCategoryApi.__projection});
    }
}

export class GetTesSeriestByCategoriesApi {
    static apiEndpoint = 'v2/test-series';

    static projection;
    static get  __projection(){ 
        if(!GetTesSeriestByCategoriesApi.projection){
            GetTesSeriestByCategoriesApi.projection = JSON.stringify(new SourceProjectionMapper(categoryList).map());
        }
        return GetTesSeriestByCategoriesApi.projection;
    }

    static apiCall(network: Network, params: any) {
       return network.get(GetTesSeriestByCategoriesApi.apiEndpoint, {__projection: GetTesSeriestByCategoriesApi.__projection,...params});
    }
}

export class SendSelectedStageApi {
    static apiEndpoint = 'v1/test-series/{testSeriesId}/selected-stage';
    static  __projection = 1;

    static apiCall(network:Network,params:any){
        return network.post(SendSelectedStageApi.apiEndpoint.replace('{testSeriesId}', params.testSeriesId),{examStage:params.examStage,__projection:SendSelectedStageApi.__projection});
    } 
}

export class sendSelectedBranchInTestSeriesApi {
    static apiEndpoint = 'v1/test-series/{testSeriesId}/select-branch';

    static apiCall(network:Network,params:any){
        return network.post(sendSelectedBranchInTestSeriesApi.apiEndpoint.replace('{testSeriesId}',params.testSeriesId),{},{branchId:params?.selectedBranch?.id});
    }
}
