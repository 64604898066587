<div class="dropdown dropdown--notification" (click)="showNotifications()">
    <svg width="24" height="24">
        <use xlink:href="#bell-notify"></use>
    </svg>
    <div class="count" [hidden]="unseenCount == 0">{{unseenCount}}</div>
    <div class="dropdown-menu" [ngClass]="{'show': showNotifs}">
        <div *ngIf="loading">
            <div class="skeleton notification-content" *ngFor="let index of [{},{},{}]">
                <img src="//testbook.com/assets/img/ui-kit/notes-icon.svg" alt="skeleton" class="sk-t sk-anim" width="34" height="34">
                <div>
                    <div class="sk-t sk-anim">Some blank notification content for showing skeleton</div>
                    <div class="icon-close sk-t sk-anim"></div>
                    <div>
                        <span class="h4 sk-t sk-anim"> Some text </span>
                        <span class="day sk-t sk-anim">2d</span>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!loading">
            <div class="notification-content animate-in-card" [ngClass]="{'animate-out-card': notification.removed}" *ngFor="let notification of notifications">
                <img loading="lazy" [src]="notification.iconWeb" alt="iconWeb" width="34" height="34">
                <div (click)="cardClick(notification, $event)">
                    <div [innerHTML]="notification.title"></div>
                    <div class="icon-close" (click)="closeNotification(notification, $event)"></div>
                    <div>
                        <span class="h4" [innerHTML]="notification.text"> </span>
                        <span class="day">{{notification.createdOn | timediff }}</span>
                    </div>
                    <button class="btn btn--outline" *ngFor="let cta of notification.ctas" (click)="buttonClick(cta.action)">{{cta.name}}</button>
                </div>
            </div>

            <div class="more-link" *ngIf="notifications.length > 0 && notifications.length < notifCount">
                <a href="javascript:void(0)" (click)="loadMoreNotifs($event)">Load More...</a>
            </div>
        </div>
        <div class="empty-state" *ngIf="notifications.length === 0 && !loading" >
            <img style="width: 60%;" src="/assets/img/ui-kit/empty-state.svg">
            <h4>You don't have any new notifications </h4>
            <div>All new notifications will appear here</div>
        </div>
    </div>
</div>
