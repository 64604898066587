export const selectTestSeriesData = (state: any) => {return state.testSeriesState.testSeriesData};
export const selectRelatedTestSeries = (state: any) => {return state.testSeriesState.relatedTS};
export const selectChapterTests = (state: any) => {return state.testSeriesState.chapterTests};
export const selectChapterTestsLimit = (state: any) => {return state.testSeriesState.chapterTestsLimitReached};
export const testSeriesFreeTests = (state: any) => {return state.testSeriesState.freeTests};
export const selectSuggestedTest = (state: any) => {return state.testSeriesState.suggestedTest};
export const selectLeaderboardList = (state: any) => {return state.testSeriesState.leaderboardData};
export const selectSuggestedFullTest = (state: any) => {return state.testSeriesState.suggestedFullTest};
export const selectCurrentStage = (state: any) => {return state.testSeriesState.testSeriesStages};
export const selectUnAttemptedModalStatus = (state: any) => {return state.testSeriesState.showUnattemptedModal};
export const selectTestSeriesPerformaceModal = (state: any) => {return state.testSeriesState.showTestSeriesPerformanceModal};
export const selectSuggestFullTestModalStatus = (state: any) => {return state.testSeriesState.showSuggestFullTestModal};
export const selectCurrentBranch = (state: any) => {return state.testSeriesState.currentSelectedBranch};
export const selectBranchModal = (state: any) => {return state.testSeriesState.showBranchModal};
