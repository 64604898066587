import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PassPitchComponent } from './pass-pitch.component';

@NgModule({
  declarations: [PassPitchComponent],
  imports: [
    CommonModule,
  ],
  exports: [PassPitchComponent]
})
export class PassPitchExportModule { }
