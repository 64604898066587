import { Component, OnInit } from '@angular/core';
import {Store} from "@ngrx/store";
import {selectStudent} from "@core/application-state/app.selectors";
import {PlatformService} from "@core/services/platform-service";
import {isServer} from "@shared/utility/tb-common";
import {isValidDate} from "@shared/utility/date-utilities";
import {Router} from "@angular/router";
import {ProductAccessService} from "@core/services/product-access.service";

@Component({
  selector: 'pass-button',
  templateUrl: './pass-button.component.html',
  styleUrls: ['./pass-button.component.scss']
})
export class PassButtonComponent implements OnInit {

  student$ = this.store.select(selectStudent);

  passState : any = {};
  passProState : any = {};

  isServer = isServer();
  activationCode = "";

  constructor(private store: Store<{}>, private platformService: PlatformService, private router:Router,private productAccessService: ProductAccessService) {}

  ngOnInit(): void {
    this.activationCode = this.platformService.getCookie('trans_code');

    if(this.platformService.isLoggedIn()) {
      this.student$.subscribe(data => {
        this.passState = data.globalPassState;
        this.passProState = data.passProState;
      });
    }
  }

  goToPass() {
    this.router.navigateByUrl('/pass');
  }

  purchasePro(){
    this.productAccessService.triggerPassPurchase({passType:'passPro'});
  }

  goToPassPro() {
    this.router.navigateByUrl('/pass-pro');
  }


}
