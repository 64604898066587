import { Validator as _ } from "../../../shared/utility/validator";
import { baseModel ,setupModel } from "../../types";
import {arrayModel, objModel, propertyMaps, SourceProjectionMapper} from "../../mapping-framework";
import { Network } from '../../../core/services/model-manager/main';

export class _MandateDetail extends baseModel{
    @propertyMaps('link',_.str)
    public link;

    @propertyMaps('mandateId',_.str)
    public mandateId;
}
setupModel(_MandateDetail,{});

export class EmiMandate extends baseModel{
    @propertyMaps('mandateDetail',objModel(_MandateDetail),_MandateDetail)
    public mandateDetail;
}
setupModel(EmiMandate,{});

export class _Mandate extends baseModel{
    @propertyMaps('_id', _.num)
    public id;

    @propertyMaps('emiId', _.num)
    public emiId;

    @propertyMaps('status', _.str)
    public status;
}
setupModel(_Mandate,{});

export class _PartialEmi extends baseModel{
    @propertyMaps('_id', _.num)
    public id;

    @propertyMaps('paidAmount', _.num)
    public paidAmount;

    @propertyMaps('totalAmountToPay', _.num)
    public totalAmountToPay;

    @propertyMaps('productId', _.str)
    public productId;

    @propertyMaps('productName', _.str)
    public productName;
    
    @propertyMaps('mandate', objModel(_Mandate), _Mandate)
    public mandate;
}
setupModel(_PartialEmi,{});

export class StudentPartialEmi extends baseModel{
    @propertyMaps('emis', arrayModel(_PartialEmi),_PartialEmi)
    public emis;
}
setupModel(StudentPartialEmi,{});

export class StudentMandateGuideLines extends baseModel{
    @propertyMaps('pdfLink', _.str)
    public pdfLink;
}
setupModel(StudentMandateGuideLines,{});

export class emiMandateApi{
    static apiEndpoint = 'v2/emi/:emiId/mandate';

    static projection;
    static get  __projection(){ 
        if(!emiMandateApi.projection){
            emiMandateApi.projection = JSON.stringify(new SourceProjectionMapper(EmiMandate).map());
        } 
        return emiMandateApi.projection;
    }

    static apiCall(network:Network,params:any) {
        return network.get(emiMandateApi.apiEndpoint.replace(':emiId', params.emiId), {__projection:emiMandateApi.__projection, ...params});
    }
}

export class studentPartialEmiApi{
    static apiEndpoint = 'v2/student/partial-emis';

    static projection;
    static get  __projection(){ 
        if(!studentPartialEmiApi.projection){
            studentPartialEmiApi.projection = JSON.stringify(new SourceProjectionMapper(StudentPartialEmi).map());
        } 
        return studentPartialEmiApi.projection;
    }

    static apiCall(network:Network,params:any) {
        return network.get(studentPartialEmiApi.apiEndpoint, {__projection:studentPartialEmiApi.__projection, ...params});
    }
}
