import {makeStateKey, TransferState} from "@angular/platform-browser";
import {isServer} from "@shared/utility/tb-common";
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";


@Injectable({
    providedIn: 'root'
})
export class TransferStateManager{
    serverCache : any = {}; //Used to cache transfer state manager API calls on server
    pending : any = {}; //Used to mark pending requests on server

    constructor(private transferState: TransferState,private store:Store<{}>) {}
    isEmpty(value){
       return  (!value || (Array.isArray(value) && !value.length) || (typeof value === "object" && !Object.keys(value).length))
    }

    isInitialState(value){
        return value && ( (typeof value === "object" && value.isInitialState))
    }

    get(keyname,action,selector,set,revalidateOnClient=false){
        let key = makeStateKey(keyname);
        let data;
        if(!isServer()){
            data = this.transferState.get(key,undefined);
        } else {
            data = this.serverCache[key];
        }
        let isValidData = !this.isEmpty(data) && !this.isInitialState(data);
        if(isValidData){
            set(data, {transferStateData : data, selectorData: undefined});
        }
        if(!isValidData || (revalidateOnClient && !isServer())){
            if(!this.pending[key] || !isServer()){
                this.store.dispatch(action)
            }
            this.pending[key] = true;
        }
        return selector.subscribe((apiData)=>{
            if(!this.isEmpty(apiData) && !this.isInitialState(apiData))  {
                this.pending[key] = false;
                set(apiData, {transferStateData : data, selectorData: apiData});
                this.transferState.remove(key);
                if(isServer()){
                    this.serverCache[key] = apiData;
                    this.transferState.set(key, apiData);
                }
            };
        });
    }
}
