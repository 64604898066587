<div class="coupon" [ngClass]="{'coupon--full-flex': fullWidth}" *ngIf="couponType === 'default' && couponState === 'default'">
    <div class="coupon__img">
        <img class="desktop" loading="lazy" src="./assets/img/coupon/offers-star.svg" alt="icon" width="24" height="46">
        <img class="mweb" loading="lazy" src="./assets/img/coupon/offer-tag.svg" alt="icon">
    </div>
    <div class="coupon__content">
        <h3 class="coupon__title" translate>{{ctaDetails.offersText}}</h3>
        <div class="coupon__link" (click)="openCouponModal()" translate>{{ctaDetails.viewOffersCTA}}</div>
    </div>
</div>

<div class="coupon" [ngClass]="{'coupon--full-flex': fullWidth}" *ngIf="couponType === 'default' && couponState === 'couponApplied' ">
    <div class="coupon__img">
        <img class="desktop" loading="lazy" src="./assets/img/brand/logo.svg" alt="icon" width="36" height="36">
        <img class="mweb" loading="lazy" src="./assets/img/coupon/green-tick.svg" alt="tick" width="16" height="16">
    </div>
    <div class="coupon__content">
        <h4 class="coupon__title">{{'OFFER_APPLIED' | translate}}</h4>
        <div class="coupon__success">{{'COUPON_SAVING_TEXT' | translate: {couponDiscount: couponDiscount} }}</div>
    </div>
    <div class="coupon__link" (click)="cancelCode()">
        <img loading="lazy" src="./assets/img/coupon/close.svg" alt="close">
    </div>
</div>

<div class="coupon-code {{cssModifierClass}}" *ngIf="couponType === 'inline' && couponState === 'default'">
    <div [ngClass]="{'text-white': isSkill}" class="coupon-code__link" (click)="openCouponModal()" translate>APPLY_COUPON</div>
</div>
<div class="coupon-code" *ngIf="couponType === 'inline' && couponState === 'couponApplied' &&  !showCouponText">
    <div class="coupon-code__inner">
        <div><b><span translate >OFFER_APPLIED</span>. <span>{{'COUPON_SAVING_TEXT' | translate: {couponDiscount: couponDiscount} }}</span></b></div>
        <div class="coupon-code__link" (click)="openCouponModal()" translate>{{ctaDetails.moreOffersCTA}}</div>
    </div>
</div>

<div class="sticky-coupon" *ngIf="couponType === 'sticky' && couponState === 'default'">
    <div>
        <img src="./assets/img/coupon/offer-tag.svg" alt="tag-icon">
    </div>
    {{ctaDetails.offersText}}<a href="javascript:void(0)" (click)="openCouponModal()" translate>APPLY_COUPON</a>
</div>
<div class="sticky-coupon" *ngIf="couponType === 'sticky' && couponState === 'couponApplied'">
    <div>
        <img src="./assets/img/coupon/green-tick.svg" alt="green-tick-icon">
    </div>
    <span><span translate>OFFER_APPLIED</span>(<a href="javascript:void(0)" (click)="cancelCode()" translate>REMOVE</a>)</span>
    <a href="javascript:void(0)" (click)="openCouponModal()">{{ctaDetails.viewOffersCTA}}</a>
</div>


<!--    pass coupon apply   -->

<!--    default couponState-->
<div class="pass-coupon" *ngIf="couponType === 'passPage' && couponState === 'default'">
   <p class="pass-coupon__default" translate>GET_OFFERS_INSIDE</p>
    <a href="javascript:void(0)" (click)="openCouponModal()" translate>APPLY_COUPON</a>
</div>
<!--    applied couponState-->
<div class="pass-coupon" *ngIf="couponType === 'passPage' && couponState === 'couponApplied'">
    <span *ngIf="isAppliedCouponValidOnPass"><p class="pass-coupon__applied" [innerHTML]="'COUPON_APPLIED_SUCCESS' | translate"></p></span>
    <span *ngIf="!isAppliedCouponValidOnPass"><p class="pass-coupon__invalid" translate>COUPON_NOT_APPLICABLE</p></span>
    <a href="javascript:void(0)" (click)="cancelCode()"  translate>REMOVE_COUPON</a>
</div>
