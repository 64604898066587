<div class="account-block" *ngIf="loaded">
    <div [innerHTML]="icon | safeHtml"></div>
    <div [innerHTML]="title | safeHtml"></div>

    <div [innerHTML]="description | safeHtml"></div>

    <label for="accountBlocked">
        <input *ngIf="!isBlockedUser && !isSelfUnblockWait" type="checkbox" id="accountBlocked"
            [checked]="isBtnDisabled" (change)="updateCheckboxState()">
        <span [innerHTML]="checkBoxText | safeHtml"></span>
    </label>

    <button *ngIf="isShowWarning" class="btn" [disabled]="!isBtnDisabled"
        (click)="hideWarningModal()">Acknowledge</button>

    <button *ngIf="isFirstTimeBlocked && !isSelfUnblockWait" class="btn" [disabled]="!isBtnDisabled"
        (click)="restRequestApi()">Unblock Account</button>

    <a *ngIf="isBlockedUser" class="btn" href="mailto:support@testbook.com">Write an Email to Us</a>
</div>