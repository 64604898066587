import { Injectable} from '@angular/core';
import {
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    CanActivateChild
} from '@angular/router';
import {Observable, of} from "rxjs";
import {Store} from "@ngrx/store";
import {PlatformService} from "@core/services/platform-service";
import {SELECTIONS} from "@angular-commons/shared/utility/constants";


@Injectable()
export class SelectionsGuard implements CanActivateChild {


    constructor(private router:Router,
                private store: Store<{}>,
                private platformService: PlatformService) {
    }

    canActivateChild(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean|UrlTree> {
                   let pathName=encodeURI(this.platformService.getPathName());
                    
                if(!this.platformService.isLoggedIn() && !pathName.endsWith(SELECTIONS)) {
                    let url="/login?redirect_url="+encodeURI(state.url)
                    this.platformService.redirect(url,302);
                    return;
                }

                return of(true);
    }

}