<header class="header">
    <!-- Main Header for logged out state -->
    <div class="header__post" [ngClass]="{'open': showHeaderMweb}" >
        <div class="logo">
            <div class="logo__hamburger js-trigger-hamburger-button" (click)="toggleHeaderMweb($event)"><span></span></div>
            <a class="logo__testbook" routerLink="/" *ngIf="!isMobile">
                <svg>
                    <use xlink:href="#testbook-logo"></use>
                </svg>
            </a>
            <a class="logo__testbook" routerLink="/" *ngIf="isMobile">
                <svg width="30" height="30">
                    <use xlink:href="#testbook-icon"></use>
                </svg>
            </a>
            <div class="mweb" *ngIf="isMobile">
                <app-lang-dropdown></app-lang-dropdown>

                <div class="search-container">
                    <global-search-bar-modal (onModalToggle)="toggleSearchModal()"></global-search-bar-modal>
                </div>
                <a href="{{signupUrl}}"
                   gtmextras='{"module":"TopNavigation"}'
                   title="Login" class="btn btn--cta btn--mweb ui-js-login"
                   [attr.data-onboarding-params]="openLoginParams() | json"
                   translate>Get Started</a>
            </div>
        </div>
        <!-- Main Header right panel logged out case-->
        <ul class="action-nav" *ngIf="!isMobile">
            <li class="desktop language-container">
                <app-lang-dropdown></app-lang-dropdown>
            </li>
            <li>
                <a href="{{signupUrl}}" title="Sign Up"
                   [attr.data-onboarding-params]="openLoginParams() | json"
                   class="btn btn--cta btn--signup ui-js-login" translate>Get Started</a>
            </li>
        </ul>
        <div class="container">
            <!-- Main Header logged out case-->
            <ul class="main-nav">
                <li>
                    <a *ngIf="isServer" (mouseenter)="showTargetDropdown()" (mouseleave)="hideTargetDropdown()" href="/exams" (click)="showTargetDropdown($event)" prevent-default="false" [ngClass]="{'show': showTargets}" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#exams-inactive"></use>
                        </svg>Exams
                    </a>
                    <a *ngIf="!isServer" class="exam-nav" (mouseenter)="showTargetDropdown()" (mouseleave)="hideTargetDropdown()" href="javascript:void(0)" (click)="showTargetDropdown($event)" prevent-default="false" [ngClass]="{'show': showTargets}" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#exams-inactive"></use>
                        </svg>Exams
                        <target-list *ngIf="isMobile && targetDropdownTouched" [hidden]="!showHeaderMweb" class="target-list" (onTargetClick)="hideTargetDropdown()"> </target-list>
                        <target-list *ngIf="!isMobile && targetDropdownTouched" class="target-list" (onTargetClick)="hideTargetDropdown()"> </target-list>
                    </a>

                </li>

                <li>
                    <a *ngIf="isServer" routerLinkActive="active-link" [routerLink]="goalSelectionPageUrl" target="_self" [ngClass]="{'active-link': activePageType === 'superCoaching','coaching-nav':!isServer}" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#coaching-inactive"></use>
                        </svg>SuperCoaching
                    </a>

                    <a *ngIf="!isServer" href="javascript:void(0)"  [routerLink]="goalSelectionPageUrl" routerLinkActive="active-link" prevent-default="false" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#coaching-inactive"></use>
                        </svg>SuperCoaching

                    </a>
                </li>
                <li class="nav-hidden">
                    <a routerLink="/free-live-classes" routerLinkActive="active-link" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#master-class-inactive"></use>
                        </svg> Live Classes <span class="badge badge--free">FREE</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/online-test-series" routerLinkActive="active-link" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#test-series-inactive"></use>
                        </svg>Test Series
                    </a>
                </li>
                <li class="nav-hidden">
                    <a routerLink="/previous-year-papers" routerLinkActive="active-link" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#pyp-inactive"></use>
                        </svg>Previous Year Papers
                    </a>
                </li>
                <li>
                    <a routerLink="/skill-academy" routerLinkActive="active-link" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#skills-inactive"></use>
                        </svg> Skill Academy
                    </a>
                </li>

                <li class="dropdown" *ngIf="!isMobile">
                    <a class="more-nav" translate>Pass</a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" routerLink="/pass" routerLinkActive="active-link" target="_self" translate>
                            <svg class="main-nav__img" width="16" height="16">
                                <use xlink:href="#pass-inactive"></use>
                            </svg>
                            HEADER_PASS
                        </a>
                        <a class="dropdown-item" routerLink="/pass-pro" routerLinkActive="active-link" target="_self" translate>
                            <svg class="main-nav__img" width="16" height="16">
                                <use xlink:href="#pro-inactive"></use>
                            </svg>HEADER_PASS_PRO
                        </a>
                    </div>
                </li>

                <li class="nav-hidden">
                    <a routerLink="/pass" routerLinkActive="active-link" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#pass-inactive"></use>
                        </svg>HEADER_PASS
                    </a>
                </li>
                <li class="nav-hidden">
                    <a routerLink="/pass-pro" routerLinkActive="active-link" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#pro-inactive"></use>
                        </svg>HEADER_PASS_PRO
                    </a>
                </li>

                <li class="nav-hidden">
                    <a routerLink="/ias-preparation" routerLinkActive="active-link" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#ias-preparation-inactive"></use>
                        </svg>HEADER_IAS_PREPARATION
                    </a>
                </li>

                <li *ngIf="!isMobile" class="dropdown">
                    <a class="more-nav" translate>
                        More
                    </a>
                    <div class="dropdown-menu">
                        <!-- <a routerLink="/pass" routerLinkActive="active-link"  class="dropdown-item" target="_self" translate>
                            Pass
                        </a> -->
                        <a routerLink="/free-live-classes"  class="dropdown-item"  routerLinkActive="active-link" target="_self" translate>
                            Free Live Classes
                        </a>
                        <a routerLink="/free-live-tests-and-quizzes"  class="dropdown-item"  routerLinkActive="active-link" target="_self" translate>
                            Free Live Tests & Quizzes
                        </a>
                        <a routerLink="/free-online-quizzes"  class="dropdown-item"  routerLinkActive="active-link" target="_self" translate>
                            Free Quizzes
                        </a>
                        <a routerLink="/previous-year-papers"  class="dropdown-item"  routerLinkActive="active-link" target="_self" translate>
                            Previous Year Papers
                        </a>
                        <a href="/doubts"  class="dropdown-item"  routerLinkActive="active-link" target="_self" translate>
                            Doubts
                        </a>
                        <a href="/practice-questions"  class="dropdown-item"  target="_self" translate>
                            Practice
                        </a>
                        <a href="/referrals"  class="dropdown-item"  target="_self" translate>
                            Refer and Earn
                        </a>
                        <a href="/exams"  class="dropdown-item"  target="_self" translate>
                            All Exams
                        </a>
                        <a routerLink="/success-stories"  class="dropdown-item"  routerLinkActive="active-link" target="_self" translate>
                            Our Selections
                        </a>
                        <a href="/careers"  class="dropdown-item"  target="_self" translate>
                            Careers
                        </a>
                        <a routerLink="/ias-preparation"  class="dropdown-item"  routerLinkActive="active-link" target="_self" translate>
                            IAS Preparation
                        </a>
                        <a routerLink="/current-affairs"  class="dropdown-item"  routerLinkActive="active-link" target="_self" translate>
                            Current Affairs
                        </a>
                    </div>
                </li>
            <!--  =======  M-WEB HIDDEN  -->
                <!-- <li class="nav-hidden">
                    <a href="/free-live-classes" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#pass-inactive"></use>
                        </svg>Free Live Classes
                    </a>
                </li> -->
                <li class="nav-hidden">
                    <a href="/practice-questions" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#practice-inactive"></use>
                        </svg>Practice
                        <!-- <span class="badge badge--free">FREE</span> -->
                    </a>
                </li>
                <hr class="main-nav__divider">
                <li class="nav-hidden">
                    <a href="/current-affairs/current-affairs-quiz" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#gk-ca-inactive"></use>
                        </svg>GK & Current Affairs
                    </a>
                </li>

                <li *ngIf="isMobile">
                    <a href="/doubts" target="_self" translate>
                        <svg class="main-nav__img" width="15" height="16">
                            <use xlink:href="#doubts-inactive"></use>
                        </svg>Doubts
                    </a>
                </li>

                <li class="nav-hidden">
                    <a href="/blog/" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#blog-inactive"></use>
                        </svg>Blog
                    </a>
                </li>
                <hr class="main-nav__divider">
                <li class="nav-hidden">
                    <a href="/referrals" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#refer-earn-inactive"></use>
                        </svg>Refer & Earn
                    </a>
                    <a [routerLink]="['/success-stories']" target="_self" translate>
                        <svg class="main-nav__img" width="16" height="16">
                            <use xlink:href="#selections-inactive"></use>
                        </svg>HEADER_SELECTIONS
                    </a>
                </li>
                <li *ngIf="!isMobile" class="global-search w-100">
                    <global-search-bar class="w-100"></global-search-bar>
                </li>
            </ul>
        </div>
        <div class="header-backdrop js-trigger-header-backdrop">

        </div>
    </div>
    <div class="container">
        <div class="global-search-wrap">
            <div class="global-search">
                <!-- <app-global-search-modal></app-global-search-modal> -->
            </div>
        </div>
    </div>
</header>

