<div class="popup">
    <div class="popup__content">
        <div class="popup__header">
            <img src="assets/img/super/super-coaching.svg" alt="super" width="174" height="24">
            <div class="h4">Your SuperCoaching courses are<br>just a step away</div>
        </div>
        <div class="popup__body">
            <svg class="rupee-circle" width="52" height="52">
                <use xlink:href="#rupee-circle"></use>
            </svg>
            <div class="h4">Enable Auto EMI Payment for Your<br>Remaining Installments</div>
            <a [href]="mandateGuideLinesPdfLink" target="_blank" class="btn btn--block btn--outline btn-outline-brand" (click)="fireEmiInfoScreenVisitedEvent()">
                Learn How to Enable Auto EMI Payment <svg width="24" height="24" fill="transparent"><use xlink:href="#next-icon"></use></svg>
            </a>
            <button (click)="openMandatePopup()" class="btn btn--block">Continue</button>
        </div>
        <div class="popup__footer">
            <a href="mailto:support@testbook.com">Need help? Contact us at support@testbook.com</a>
        </div>
    </div>
</div>


<svg style="display:none;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <symbol id="rupee-circle" viewBox="0 0 52 52">
        <rect width="52" height="52" rx="4" fill="#EDF3FE"/>
        <path d="M37.5871 12.0683C37.5871 11.4778 37.1084 10.999 36.5178 10.999C35.9273 10.999 35.4485 11.4778 35.4485 12.0683V14.5678C33.4663 12.9336 31.0814 11.8314 28.52 11.3905C25.0815 10.7987 21.5444 11.4362 18.5291 13.1914C15.5137 14.9466 13.2125 17.7073 12.029 20.9895C10.8455 24.2717 10.8553 27.8657 12.0567 31.1414C12.26 31.6958 12.8743 31.9804 13.4288 31.7771C13.9832 31.5737 14.2678 30.9594 14.0645 30.405C13.0361 27.601 13.0277 24.5245 14.0408 21.7149C15.0539 18.9054 17.0237 16.5421 19.6049 15.0397C22.1861 13.5373 25.2138 12.9915 28.1572 13.4981C30.2609 13.8602 32.2255 14.7435 33.88 16.05H31.9264C31.3358 16.05 30.8571 16.5288 30.8571 17.1193C30.8571 17.7099 31.3358 18.1886 31.9264 18.1886H36.1614C36.9488 18.1886 37.5871 17.5503 37.5871 16.7629V12.0683Z" fill="#4788F4"/>
        <path d="M14.4141 39.9932C14.4141 40.5838 14.8928 41.0625 15.4834 41.0625C16.0739 41.0625 16.5527 40.5838 16.5527 39.9932V37.4869C18.5348 39.1211 20.9197 40.2233 23.4812 40.6642C26.9196 41.256 30.4567 40.6185 33.4721 38.8633C36.4875 37.1081 38.7887 34.3474 39.9722 31.0652C41.1557 27.783 41.1459 24.189 39.9445 20.9133C39.7412 20.3589 39.1269 20.0742 38.5724 20.2776C38.018 20.4809 37.7333 21.0952 37.9367 21.6497C38.9651 24.4537 38.9734 27.5302 37.9604 30.3398C36.9473 33.1493 34.9774 35.5125 32.3962 37.015C29.815 38.5174 26.7873 39.0632 23.844 38.5565C21.7402 38.1944 19.7756 37.3112 18.1211 36.0046H20.4892C21.0797 36.0046 21.5585 35.5259 21.5585 34.9353C21.5585 34.3448 21.0797 33.866 20.4892 33.866H15.8398C15.0524 33.866 14.4141 34.5044 14.4141 35.2918V39.9932Z" fill="#4788F4"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.7449 21.9256H29.7352C30.165 21.9256 30.5134 21.5772 30.5134 21.1474C30.5134 20.7176 30.165 20.3691 29.7352 20.3691H24.7974H22.8652C22.4354 20.3691 22.0869 20.7176 22.0869 21.1474C22.0869 21.5772 22.4354 21.9256 22.8652 21.9256H24.7974C25.3312 21.9256 25.8431 22.1377 26.2206 22.5151L26.2218 22.5164H22.8652C22.4354 22.5164 22.0869 22.8648 22.0869 23.2946C22.0869 23.7244 22.4354 24.0728 22.8652 24.0728H26.8056C26.7731 24.5575 26.5662 25.0159 26.2206 25.3615C25.8431 25.739 25.3312 25.951 24.7974 25.951H22.8652C22.5437 25.951 22.2552 26.1487 22.1393 26.4486C22.0234 26.7485 22.1038 27.0888 22.3417 27.3051L27.0648 31.5989C27.3828 31.888 27.875 31.8645 28.1642 31.5465C28.4533 31.2285 28.4298 30.7363 28.1118 30.4471L24.8772 27.5066C25.7949 27.4861 26.6707 27.1126 27.3212 26.4621C27.9587 25.8245 28.3302 24.9706 28.364 24.0728H29.7352C30.165 24.0728 30.5134 23.7244 30.5134 23.2946C30.5134 22.8648 30.165 22.5164 29.7352 22.5164H28.0711C27.9815 22.3101 27.8724 22.1123 27.7449 21.9256Z" fill="#4788F4"/>
    </symbol>
    <symbol id="next-icon" viewBox="0 0 24 24">
        <circle cx="12" cy="12" r="10" fill="white"/>
        <path d="M10 7.5L15 12L10 16.5" stroke="#0AD0F4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </symbol>
</svg>