import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileVerificationComponent } from './mobile-verification/mobile-verification.component';
import { TranslateModule} from "@ngx-translate/core";
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [MobileVerificationComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule
  ],
  providers: [
  ],
  exports: [
      MobileVerificationComponent
  ]
})
export class MobileVerificationModule { }
