import { Injectable } from '@angular/core';
import { PlatformService } from './platform-service';
import {
    pypSearchResultEvent,
    SearchResultEvent,
    selectCourseEntityEvent
} from '@angular-commons/shared/utility/tracker-events';
import { HttpClient} from '@angular/common/http';
import { APIConstants } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class TrackerService {
    private trackerAPIPrefix:string=APIConstants.TrackerAPIPrefix;
    private isProduction=APIConstants.isProduction;

    constructor(private platformService:PlatformService,private http: HttpClient) { 
    }

    getUrl(){
        return this.platformService.getProtocol() + "//" + this.platformService.getHost() + this.platformService.getPathName();
    }

    sendToTracker(obj) {
        let url=this.trackerAPIPrefix+obj.apiEndpoint;
        this.http.post(url,obj.info,{}).subscribe((data:any) => {
            if(data.success && !this.isProduction){
                console.info("Tracker Push", obj);
            }
        });
    }

    setSelectCourseEntityTracker(trackerObj:any){
        let finalTrackerObj= {
            url:this.getUrl()
        };
        finalTrackerObj={...finalTrackerObj,...trackerObj}
        let SelectCourseEntityTracker=new selectCourseEntityEvent(finalTrackerObj)
        this.sendToTracker(SelectCourseEntityTracker);
    }

    setPypSearchResultTracker(trackerObj:any){
        let pypSearchResultTracker=new pypSearchResultEvent(trackerObj)
        this.sendToTracker(pypSearchResultTracker);
    }

    setSearchResultTracker(trackerObj: any){
        let searchResultEvent = new SearchResultEvent(trackerObj);
        this.sendToTracker(searchResultEvent);


    }


}
