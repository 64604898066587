import { Pipe, PipeTransform } from '@angular/core';
import {getDaysDiff, getHoursDiff, getMinsDiff} from "@shared/utility/tb-common";

@Pipe({name: 'timediff'})
export class TimediffPipe implements PipeTransform {
    transform(value: number): string {
        try {
            let now = new Date();

            let date = new Date(value);

            let minsDiff = getMinsDiff(now, date);

            if (minsDiff < 60) {
                return Math.round(minsDiff) + 'm';
            } else {
                let hoursDiff = getHoursDiff(now, date);
                if (hoursDiff < 24) {
                    return Math.round(hoursDiff) + 'h';
                } else {
                    return getDaysDiff(now, date) + 'd';
                }
            }
        }
        catch (e) {
            console.error(e);
        }
    }
}
