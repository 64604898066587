<div class="ecard">
    <strong class="ecard__payment-text text-green">Payment Successfull!</strong>
    <img class="ecard__img" src="/assets/img/ui-kit/green-tick.svg" width="80" height="80">
    <div *ngIf="selectedPass?.type == 'goalSubs'">
        <div>
            Welcome to 
            <img src="/assets/img/super/super-coaching.svg" width="120">
        </div>
        <div class="h3">{{ selectedPass?.titleWithoutPass || selectedPass?.title }}</div>
    </div>
    <div *ngIf="selectedPass?.type == 'globalPass'" style="margin-bottom: 1rem;">
        <img src="/assets/img/pass-new/logo_pass.svg" width="125" height="30">
    </div>
    <div class="ecard__activation">
        <div class="h3 text-green" *ngIf="selectedPass?.type && productTitle"> {{productTitle}} Unlocked Successfully</div>
        <div class="h3 text-green" *ngIf="!selectedPass?.type">Your PASS Unlocked Successfully</div>
        <div class="ecard__activation-code">
            <span>Activation Code:</span>
            <strong>{{ activationCode }}</strong>
            <span (click)="copyToClipboard(activationCode,true)" style="cursor: pointer;">
                <img src="/assets/img/super/copy.svg" width="16" height="16" style="margin-right: 2px;">
                {{ !isCodeCopied ? 'Copy' : 'Copied' }}
            </span>
        </div>
        <div class="ecard__activation-link">
            <span>Activation Link:</span>
            <span (click)="redirectToEcards($event)" class="text-link text-underline">{{host}}{{ url }}</span>
            <span (click)="copyToClipboard(generateURL(true),false)" style="cursor: pointer;">
                <img src="/assets/img/super/copy.svg" width="16" height="16" style="margin-right: 2px;">
                {{ !isUrlCopied ? 'Copy' : 'Copied' }}
            </span>
        </div>
    </div>
    <div class="ecard__note">
        <img src="/assets/img/select/lock-grey.svg" width="16" height="16">
        Don’t share your activation code with anyone
    </div>
    <div class="ecard__separator"></div>
    <div class="h3">How to use Activation Code</div>
    <div class="ecard__footer">
        <div>
            <div class="h4">Step 1</div>
            <span>Open activation link & sign in with your mobile no.</span>
        </div>
        <div>
            <div class="h4">Step 2</div>
            <span>Enter your activation Code & Submit to get your package</span>
        </div>
        <img src="assets/img/super/activation-img.png">
    </div>
</div>