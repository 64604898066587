import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CoreModule} from "@core/core.module";
import {PassProFeaturePitchComponent} from "@features/pass-pro-feature-pitch/pass-pro-feature-pitch.component";

@NgModule({
    declarations: [PassProFeaturePitchComponent],
    imports: [
        CommonModule
    ],
    exports: [
        PassProFeaturePitchComponent
    ],
})
export class PassProFeaturePitchExportModule { }