//https://stackoverflow.com/questions/1248302/how-to-get-the-size-of-a-javascript-object
export function roughSizeOfObject( object ) {
    let stack = [ object ] , processed = [];
    let bytes = 0;
    while ( stack.length ) {
        let value = stack.pop();
        let t = (typeof value)[0];
        if ( t === 'b' ) {
            bytes += 4;
        } else if ( t === 's' ) {
            bytes += value.length * 2;
        } else if ( t === 'n' ) {
            bytes += 8;
        } else if ( t === 'o' && processed.indexOf(value) === -1)
        {
            for(let i in value) {
                stack.push( value[ i ] );
            }
            processed.push(value);
        }
    }
    return bytes;
}
