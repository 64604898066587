
import { Network } from "@angular-commons/core/services/model-manager/main";
import { arrayModel, propertyMaps, SourceProjectionMapper } from "@angular-commons/models/mapping-framework";
import { baseModel, setupModel } from "@angular-commons/models/types";
import { Validator as _ } from "../../../shared/utility/validator";

export class NoteModel extends baseModel {
  @propertyMaps('_id', _.str)
  public id;

  @propertyMaps('name', _.str)
  public title;

  @propertyMaps('isCompleted', _.bool)
  public isCompleted;

  @propertyMaps('subjectId', _.str)
  public subjectId;

  @propertyMaps('chapterId', _.str)
  public chapterId;

  @propertyMaps('sectionId', _.str)
  public sectionId;
}
setupModel(NoteModel, {});
export class NotesModel extends baseModel {
  @propertyMaps('notes', arrayModel(NoteModel), NoteModel)
  public notes;
}
setupModel(NotesModel, {});

export interface Note {
  id: string,
  title: string,
  isCompleted: boolean,
  subjectId: string,
  chapterId: string,
  sectionId: string
}

export class GetRecentlyReadNotesApi {
    static apiEndpoint = 'v2/study-tab/recent-notes';

    static projection;
    static get  __projection(){ 
      if(!GetRecentlyReadNotesApi.projection){
        GetRecentlyReadNotesApi.projection = JSON.stringify(new SourceProjectionMapper(NotesModel).map());
      }
      return GetRecentlyReadNotesApi.projection;
  }
  
    static apiCall(network: Network, params) {
      return network.get(GetRecentlyReadNotesApi.apiEndpoint, {...params, __projection: GetRecentlyReadNotesApi.__projection });
    }
}