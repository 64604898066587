import { Validator as _ } from "@shared/utility/validator";
import {baseModel, setupModel} from "@models/types";
import {arrayModel, objModel, pipeModel, propertyMaps, SourceProjectionMapper} from "@models/mapping-framework";
import {Network} from "@core/services/model-manager/main";

export function transformFeatures (item){
    return item.map(processBanner);
}

export function processBanner(item){
    item.bannerLink=item.action.data.link;
    return item;
}

export class Banner extends baseModel{

    @propertyMaps()
    public name;

    @propertyMaps()
    public timerFrom;

    @propertyMaps()
    public timerTill;

    @propertyMaps()
    public startsFrom;

    @propertyMaps()
    public endsAt;

    @propertyMaps()
    public mobileFgImage;

    @propertyMaps()
    public mobileBgImage;

    @propertyMaps()
    public mobileBannerLink;

    @propertyMaps()
    public mobileBgColor;

    @propertyMaps()
    public desktopFgImage;

    @propertyMaps()
    public desktopBgImage;

    @propertyMaps()
    public desktopBannerLink;

    @propertyMaps()
    public desktopBgColor;

    @propertyMaps()
    public timerText;

    @propertyMaps()
    public timerColor;

}
setupModel(Banner,{type:'promo_banner'});

export class BannerWrapper extends baseModel{

    @propertyMaps('enabled',_.bool)
    public enabled;

    @propertyMaps('banners',arrayModel(Banner), Banner)
    public banners;

    @propertyMaps()
    public date;

}
setupModel(BannerWrapper,{type: 'promo_banner'});

export class TimerData extends baseModel{

    @propertyMaps('endTime', _.str)
    public timerTill;

    @propertyMaps('color', _.str)
    public timerColor;

    @propertyMaps('enabled', _.bool)
    public enabled;

    @propertyMaps('startTime', _.str)
    public timerFrom;

    @propertyMaps('text', _.str)
    public timerText;

}
setupModel(TimerData,{});

export class DistributionData extends baseModel{

    @propertyMaps('distribution_end_time', _.str)
    public endTime;

    @propertyMaps('color', _.str)
    public color;

    @propertyMaps('enabled', _.bool)
    public enabled;

    @propertyMaps('startTime', _.str)
    public startTime;

    @propertyMaps('text', _.str)
    public text;

}
setupModel(DistributionData,{});

export class linkData extends baseModel{

    @propertyMaps('link', _.str)
    public link;

}
setupModel(linkData,{});


export class ActionData extends baseModel{

    @propertyMaps('data', objModel(linkData),linkData)
    public data;

    @propertyMaps('type', _.str)
    public type;

}
setupModel(ActionData,{});

export class BannerData extends baseModel{

    @propertyMaps('action', objModel(ActionData), ActionData)
    public action;

    @propertyMaps('backgroundColor', _.str)
    public backgroundColor;

    @propertyMaps('distribution', objModel(DistributionData),DistributionData)
    public distribution;

    @propertyMaps('id', _.str)
    public id;

    @propertyMaps('image', _.backgroundImage)
    public bannerImage;

    @propertyMaps('product_id', _.str)
    public productId;

    @propertyMaps('product_name', _.str)
    public productName;

    @propertyMaps('timer', objModel(TimerData),TimerData)
    public timer;
}
setupModel(BannerData,{});

export class BannerModel extends baseModel {
    @propertyMaps('data',pipeModel(arrayModel(BannerData),transformFeatures), BannerData)
    public banner;
}
setupModel(BannerModel, {});

export class GetPromotionsBannerData{
    static apiEndpoint = 'v1/promotions-banner-data';

    static projection;
    static get  __projection(){ 
        if(!GetPromotionsBannerData.projection){
            GetPromotionsBannerData.projection = JSON.stringify(new SourceProjectionMapper(BannerWrapper).map())
        }
        return GetPromotionsBannerData.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(GetPromotionsBannerData.apiEndpoint,{pageName: params.pageName, __projection:GetPromotionsBannerData.__projection}, 'node');
    }
}

export class GetHomePagePromotionsBanner{
    static apiEndpoint = 'app/banners';

    static projection;
    static get  __projection(){ 
        if(!GetHomePagePromotionsBanner.projection){
            GetHomePagePromotionsBanner.projection = JSON.stringify(new SourceProjectionMapper(BannerModel).map());
        }
        return GetHomePagePromotionsBanner.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(GetHomePagePromotionsBanner.apiEndpoint,{__projection:GetHomePagePromotionsBanner.__projection, ...params}, 'jarvis');
    }
}

