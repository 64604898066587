import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    ActivatedRoute,
} from '@angular/router';
import {ReplaySubject, combineLatest} from "rxjs";
import {Store} from "@ngrx/store";
import {map, skipWhile} from "rxjs/operators";
import {PlatformService} from "@core/services/platform-service";

import { getEducatorData } from '../route-modules/educators/application-state/Educators.action';
import { selectEducator } from '../route-modules/educators/application-state/Educators.selector';
import { AuthenticationService } from '@angular-commons/core/services/authentication.service';
import {
    getGoalBySlug,
    showGoalSelectionModal
} from '@angular-commons/core/application-state/app.actions';
import {getEducatorsPageUrl, getGoalPageUrl} from "@shared/utility/goal-utility";
import {selectGoalMap} from "@core/application-state/app.selectors";
import {TransferStateManager} from "@core/services/transferStateManager";


@Injectable()
export class EducatorGuard implements CanActivate {

    constructor(private router:Router,
                private route: ActivatedRoute,
                private store: Store<{}>,
                private auth: AuthenticationService,
                private platformService: PlatformService,
                private transferStateManager: TransferStateManager) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot):  any {

        const sub = new ReplaySubject<boolean>();
        let goalSlug=route.params.goalSlug
        let educatorSlug = route.params.teacherSlug;
        
        let navigated = false;

        let goalLoaded = false;
        let facultyLoaded = false;

        let goalExists = false;
        let facultyExists = false;

        let finalNav = () => {
            if(!goalLoaded || !facultyLoaded){ return; }
            navigated = true;
            if(goalExists && facultyExists){
                sub.next(true);
                sub.complete();
            } else {
                this.platformService.gotoErrorPage();
                sub.next(false);
                sub.complete();
            }
        }

        if(goalSlug) {
            this.store.dispatch(getGoalBySlug({slug: goalSlug}));
            this.transferStateManager.get(
                `educator_${educatorSlug}_${goalSlug}`,
                getEducatorData({idOrSlug: educatorSlug, goalIdOrSlug: goalSlug}),
                this.store.select(selectEducator),
                (item) => {
                    if(navigated) { return; }
                    facultyLoaded = true;
                    if (!(item && item.id)) {
                        facultyExists = false;
                        finalNav();
                    } else {
                        facultyExists = true;
                        finalNav();
                    }
                });
            this.store.select(selectGoalMap).subscribe( goalmap => {
                if(navigated) { return; }
                if(goalmap && goalmap[goalSlug] === false){
                    goalLoaded = true;
                    goalExists = false;
                    finalNav();
                } else if(goalmap && goalmap[goalSlug]){
                    goalExists = true;
                    goalLoaded = true;
                    finalNav();
                }
            });
        } else {
            let goal = this.auth.getGoal();
            if(goal && goal.slug){
                this.platformService.redirect(`${getGoalPageUrl(goal.slug)}${getEducatorsPageUrl()}`);
                sub.next(false);
                sub.complete();
            }
            else{
                this.store.dispatch(showGoalSelectionModal({}))
                sub.next(true);
                sub.complete();
            }
            
        }
        return sub;
    }

}
