<div class="pitch" *ngIf="!(tabs && tabs.length && hasLinks) && !tabs?.length">
    <div class="pitch__scroll skeleton">
        <a href="javascript:void(0)" class="pitch-tab sk-t sk-anim" *ngFor="let tab of [1,2,3,4,5,6,7]">sk-t sk-anim</a>
    </div>
</div>
<div class="pitch" [ngClass]="useWebDesign && 'live-pitch'" *ngIf="tabs && tabs.length && !hasLinks">
    <div class="pitch__scroll" [ngClass]="useWebDesign && 'live-panel'" [hidden]="!tabs.length" #scrollContainer (scroll)="onScroll($event)">
        <a href="javascript:void(0)" [ngClass]="{'pitch-tab-live':useWebDesign, 'pitch-tab':!useWebDesign, 'active':currentTabId === (tab.id)}" *ngFor="let tab of tabs" (click)="selectTab(tab)">{{tab.title}}</a>
    </div>
    <button class="pitch-controller prev" (click)="scrollLeft()" *ngIf="!isServer && currentScrollCount>0">
        <svg width="32" height="62">
            <use xlink:href="#pitch-prev"></use>
        </svg>
    </button>
    <button class="pitch-controller next" (click)="scrollRight()" *ngIf="!isServer && currentScrollCount<maxScrollCount">
        <svg width="32" height="62">
            <use xlink:href="#pitch-next"></use>
        </svg>
    </button>
</div>
<div id="sticky-pitch"  class="pitch pitch--full-flex"
            [ngClass]="{'offset-header': (isLoggedIn || isMobile) && !isCurrentAffairs,'pitch--current-affairs': isCurrentAffairs}"
            *ngIf="tabs?.length && hasLinks">
    <div class="container" [ngClass]="{'side-spacer': isCurrentAffairs}">
        <button class="pitch-controller prev" (click)="scrollLeft()" *ngIf="!isServer && currentScrollCount>0">
            <svg width="32" height="60">
                <use xlink:href="#pitch-prev"></use>
            </svg>
        </button>
        <div class="pitch__scroll ui-js-scroll-container-to-child" #scrollContainer (scroll)="onScroll($event)" [attr.data-scrollto]="'.active'">
            <a [routerLink]="tab?.tabUrl" class="class-tab__link pitch-link" *ngFor="let tab of tabs" [ngClass]="{'active':currentTabId === (tab.id)}">
                <img loading="lazy" *ngIf="showLogo" [src]="tab.logo || 'https://cdn.testbook.com/resources/productionimages/banking_All_1583571298.png'" alt="exam-icon">
                <span translate>{{tab.title}}</span>
            </a>
        </div>
        <button class="pitch-controller next" (click)="scrollRight()" *ngIf="!isServer && currentScrollCount<maxScrollCount">
            <svg width="32" height="60">
                <use xlink:href="#pitch-next"></use>
            </svg>
        </button>
    </div>
</div>




<!-- super pitch tab    -->
<!-- <div class="pitch pitch--tab" *ngIf="tabs && tabs.length && !hasLinks && type === 'bar'">
    <div class="container">
        <button class="pitch-controller prev" (click)="scrollLeft()" *ngIf="!isServer && currentScrollCount>0">
            <svg width="7" height="12">
                <use xlink:href="#pitch-prev"></use>
            </svg>
        </button>
        <div class="pitch__scroll">
            <a href="javascript:void(0)" class="pitch-link" *ngFor="let tab of tabs" (click)="selectTab(tab)" [ngClass]="{'active':currentTabId === tab.id}">{{tab.title}}</a>
        </div>
        <button class="pitch-controller next" (click)="scrollRight()" *ngIf="!isServer && currentScrollCount<maxScrollCount">
            <svg width="7" height="12">
                <use xlink:href="#pitch-next"></use>
            </svg>
        </button>
    </div>
</div> -->



<svg style="display: none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <symbol id="pitch-next" viewBox="0 0 31 62">
        <g clip-path="url(#clip0)">
            <circle cx="28" cy="31" r="28" fill="#F0F2F7"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9504 23.9897C15.2323 23.6861 15.7068 23.6686 16.0103 23.9504L23.0103 30.4504C23.1632 30.5923 23.25 30.7914 23.25 31C23.25 31.2086 23.1632 31.4077 23.0103 31.5496L16.0103 38.0496C15.7068 38.3314 15.2323 38.3139 14.9504 38.0103C14.6686 37.7068 14.6861 37.2323 14.9897 36.9504L21.3978 31L14.9897 25.0496C14.6861 24.7677 14.6686 24.2932 14.9504 23.9897Z" fill="#5B5F69"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="31" height="62" rx="8" fill="white"/>
            </clipPath>
        </defs>
    </symbol>
    <symbol id="pitch-prev" viewBox="0 0 31 62">
        <g clip-path="url(#clip0)">
            <circle cx="3" cy="32" r="28" transform="rotate(-180 3 31)" fill="#F0F2F7"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0496 38.0103C15.7677 38.3139 15.2932 38.3314 14.9897 38.0496L7.98966 31.5496C7.83683 31.4077 7.75 31.2086 7.75 31C7.75 30.7914 7.83683 30.5923 7.98966 30.4504L14.9897 23.9504C15.2932 23.6686 15.7677 23.6861 16.0496 23.9897C16.3314 24.2932 16.3139 24.7677 16.0103 25.0496L9.60221 31L16.0103 36.9504C16.3139 37.2323 16.3314 37.7068 16.0496 38.0103Z" fill="#5B5F69"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect x="31" y="62" width="31" height="62" rx="8" transform="rotate(-180 31 62)" fill="white"/>
            </clipPath>
        </defs>
    </symbol>
    <symbol id="arrow-next" viewBox="0 0 7 12">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.10374 10.6037L4.30207 6.39624L0.10374 2.18874L1.39624 0.89624L6.89624 6.39624L1.39624 11.8962L0.10374 10.6037Z" fill="#0AD0F4"/>
    </symbol>

</svg>