export const selectMasterSeriesDetails = (state: any) => {return state.masterClassSeries.masterSeries};

export const selectPastMasterClassLessons = (state: any) => {return state.masterClassSeries.past};

export const selectUpcomingMasterClassLessons = (state: any) => {return state.masterClassSeries.upcoming};

export const selectMasterClassInstructors = (state: any) => {return state.masterClassSeries.instructors};

export const selectMasterClassPitch = (state:any) => {return state.masterClassSeries.pitch};

export const selectMasterSeriesEnrollmentStatus = (state:any) => {return state.masterClassSeries.enrollmentToSeries}

export const selectLesson = (state: any) => {return state.masterClassSeries && state.masterClassSeries.lessonData};
