import {Component, OnDestroy, OnInit,Input,SimpleChanges, Output,EventEmitter} from '@angular/core';
import {isServer} from "@shared/utility/tb-common";
import {TimerService} from "@core/services/timer.service";
import {getTimeLeft} from "@shared/utility/date-utilities";
import {pad} from "@shared/utility/string-utilities";
import {getPassPurchaseUrl} from "@shared/utility/pass-utils";
import {Store} from "@ngrx/store";
import {showKindOfferModal} from '@angular-commons/core/application-state/app.actions';
import {PlatformService} from "@core/services/platform-service";
import { selectGoal } from '@angular-commons/core/application-state/app.selectors';
import {passTypeProducts} from "@shared/utility/constants";


@Component({
  selector: 'pass-card',
  templateUrl: './pass-card.component.html',
  styleUrls: ['./pass-card.component.scss']
})

export class PassCardComponent implements OnInit,OnDestroy {

    @Input() pass : any;
    @Input() goal : any;
    @Input() passType : any;
    @Input() isLogoutPaymentPage : any;
    @Input() cssModifierClass: string = '';
    @Input() passIndex:number = 0;
    @Input() showEMIPlansUI:boolean = false;
    @Input() selectedPassId:string = ""
    @Input() hasRecommendedPass:boolean = false;
    @Input() coldbootParams:any;
    @Input() cardType:string;
    @Output() selectedPass = new EventEmitter<any>();
    passProducts = passTypeProducts
    showAnimation:boolean=false
    showOffer:boolean=false;
    productDetails:any={};
    countDown:string;
    purchaseUrl:string=""
    isServer:boolean;
    coldbootsub:any;
    passSuffix : string = "";
    selectGoal$ = this.store.select(selectGoal)
    showDiscountedPrice:boolean = false;
    couponDiscount:number = 0;

    constructor(
        private platformService : PlatformService,
        private timerService:TimerService,
        private store:Store<{}>)
    {}

    ngOnInit(): void {
        this.passSuffix = this.platformService.getQueryParamValue("p_suffix");
        this.isServer=isServer();
        this.showOfferCountDown();
        if(!isServer()){
            this.timerService.startTimer().subscribe(() => {
                this.showOfferCountDown();

            });
        }

        this.calculateDiscount();
        this.showDiscountedPrice=this.goal?.properties?.showEntirePrice || false;
    }

    ngOnChanges(changes : SimpleChanges){
        if(changes.pass){
            if(changes?.pass?.currentValue){
                this.pass = changes.pass.currentValue;
                this.calculateDiscount();
                this.purchaseUrl=getPassPurchaseUrl(changes.pass?.currentValue?.id,changes.pass.currentValue?.coupon)
            }
        }
        if(changes.goal && changes.goal.currentValue) {
            this.showDiscountedPrice= changes.goal?.currentValue?.properties?.showEntirePrice;
        }
    }

    calculateDiscount(){
        if(this.pass?.isCouponApplied){
            this.couponDiscount = this.pass.cost - this.pass?.afterCouponCost
        }
        this.couponDiscount = this.couponDiscount < 0 ? 0 : this.couponDiscount;
    }

    showOfferCountDown(){
        let timeDiff:any;
        if(this.pass && this.pass.offers && this.pass.offers.offerType && this.pass.offers[this.pass.offers.offerType]){
            timeDiff  = getTimeLeft(this.pass.offers[this.pass.offers.offerType].endTime);

            this.showOffer=!(timeDiff.h === 0 && timeDiff.m === 0 && timeDiff.s === 0)

            if(this.showOffer){
                this.countDown = pad(timeDiff.h.toString(), '0', 2) + ':' + pad(timeDiff.m.toString(), '0', 2) + ':' + pad(timeDiff.s.toString(), '0', 2);
            }

        }
    }

    selectPass(pass,isPreserved=false){
        this.selectedPass.emit({pass:pass,isPreserved:isPreserved, passIndex: this.passIndex});
    }


    ngOnDestroy(): void {
    }

    selected($event){
        if($event.target.value === this.pass.id){
            this.selectPass(this.pass,true);
        }
    }

    kindOffer(){
        this.store.dispatch(showKindOfferModal({pass:this.pass}))
    }



}
