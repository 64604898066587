
import {isServer} from "@shared/utility/tb-common";
import {retainObjectKeys} from "@shared/utility/validator";
import { langMap, languageCodeMap } from "./language";

export function isWhitelisted(url){
    try{
        let host = new URL(url).hostname;
        return (host === "testbook.com" || host.endsWith('.testbook.com'))
    } catch(e){
        return false;
    }
}

export function isValidHost(url){
    try{
        let host = new URL(url).hostname;
        return (host === "localhost" || host.includes('.'))
    } catch(e){
        return false;
    }
}

export function getRedirectUrl(url, pathname , useHost = 'testbook.com'){
    let finalUrl = url || '/home';
    let location;
    if(isServer()) {
        location = new URL(`https://${useHost}${pathname}`);
    } else{
        location = window && window.location;
    }


    if(finalUrl[0] === '/' && finalUrl[1] !== '/'){
        //single slash
        finalUrl = `${location.protocol}//${location.host}${finalUrl}`
    } else if(!finalUrl.startsWith('http')){
        // not starts with http & not single slash
        if(finalUrl.startsWith('//')){
            // double slash add protocol
            finalUrl = `${location.protocol}${finalUrl}`;
        } else {
            // is it a domain or relative url without "/" we dont know
            let testUrl = `${location.protocol}//${finalUrl}`;
            if(isValidHost(testUrl)){
                finalUrl = testUrl;
            } else {
                finalUrl = `${location.protocol}//${location.host}/${finalUrl}`;
            }
        }
    }

    if(!isWhitelisted(finalUrl)){
        finalUrl = `${location.protocol}//${location.host}${('/super-coaching' || '/home')}`;
    }

    return finalUrl;
}

export function getValidResourceUrl(url, useHost = 'testbook.com'){
    let location;
    if(isServer()) {
        location = new URL(`https://${useHost}`)
    } else{
        location = window && window.location;
    }
    if(!url.startsWith('http')){
        // not starts with http & not single slash
        if(url.startsWith('//')){
            // double slash add protocol
            url = `${location.protocol}${url}`;
        }
        else if (url[0] === '/' && url[1] !== '/'){
            // single slash
            url = `${location.protocol}//${location.host}${url}`;
        }
        else {
            // is it a domain or relative url without "/" we dont know
            let testUrl = `${location.protocol}//${url}`;
            if(isValidHost(testUrl)){
                url = testUrl;
            } else {
                url = `${location.protocol}//${location.host}/${url}`;
            }
        }
    }
    return url;
}

export function getQueryStringFromParams(params:any = {}, overrideParams: any = {}, skipUndefined: boolean = false){
  let query : string = '';
  let finalParams = {...params,...overrideParams};
  let keys = Object.keys(finalParams);
  if(keys.length > 0){
    if(skipUndefined){
       keys =  keys.filter(key =>  finalParams[key] !== undefined);
    }
    query = '?' + keys.map(key => `${key}=${finalParams[key]}`).join('&')
  }
  return query;

}
export function sanitizeImageUrl(url:string){
   return url.replace(/\(/g,"%28").replace(/\)/g,"%29");
}

let superCoachingRegex = /^\/super-coaching$/,
superCoachingTeacherRegex = /^\/.*-coaching\/.*-super-teacher$/,
superCoachingTeachersRegex = /^\/.*-coaching\/super-teachers$/,
superCoachingGoalRegex = /^\/.*-coaching$/,
lessonRegex = /^\/lesson\/.*$/,
classesLessonRegex = /^\/class\/.*\/lesson\/.*$/,
classVideoRegex = /^\/course\/.*\/video\/.*$/,
freeLiveClassesLessonRegex = /^\/live-classes-series\/.*\/lesson\/.*$/,
freeLiveTestsAndQuizRegex = /^\/free-live-tests-and-quizzes$/,
pypSearchRegex = /^\/pyp-search\/.*$/,
pdfViewerRegex = /^\/pdf-viewer$/,
passRegex = /^\/pass$/,
referralRegex = /^\/invite\/.*$/,
previousYearPapersRegex = /^\/previous-year-papers$/,
couponCodeRegex = /^\/coupon-code$/,
successStoriesRegex = /^\/success-stories$/,
freeLiveClassesRegex = /^\/free-live-classes$/,
livePanelMasterClass = /^\/live-panel\/master-class$/,
testStatusRegex = /^\/.*\/tests\/.*\/status$/,
scholarshipTestRegex = /^\/.*\/scholarship-test$/,
individualTestSeriesRegex = /^\/.*\/test-series$/,
individualFreeLiveClassSeriesRegex = /^\/.*\/live-classes-series$/,
individualPypRegex = /^\/.*\/previous-year-papers$/;

let routeMatcherArray = [
    superCoachingRegex,
    superCoachingTeacherRegex,
    superCoachingTeachersRegex,
    superCoachingGoalRegex,
    lessonRegex,
    classesLessonRegex,
    classVideoRegex,
    freeLiveClassesLessonRegex,
    freeLiveTestsAndQuizRegex,
    pypSearchRegex,
    pdfViewerRegex,
    passRegex,
    referralRegex,
    previousYearPapersRegex,
    couponCodeRegex,
    successStoriesRegex,
    freeLiveClassesRegex,
    livePanelMasterClass,
    testStatusRegex,
    scholarshipTestRegex,
    individualTestSeriesRegex,
    individualFreeLiveClassSeriesRegex,
    individualPypRegex
];

export function useClientNavigation(url: string) {
    let flag = false, path = getPathFromUrl(url);

    for (let route of routeMatcherArray){
        flag  = routeMatcher(path, route);
        if (flag) {
            break;
        }
    }
    return flag;
}

export function routeMatcher(path: string, regex) {
    if (!path) {
        return false;
    }
    let matches = path.match(regex);
    return !!(matches && matches.length > 0);
}

export function getPathFromUrl(url) {
    let urlSegment;
    if (url) {
        urlSegment = new URL(getValidResourceUrl(url));
    }
    return urlSegment?.pathname || '';
}

export function getUrlWithoutQueryParams(url) {
    let urlSegment;
    if (url) {
        urlSegment = new URL(getValidResourceUrl(url));
    }
    return urlSegment?.origin + urlSegment?.pathname || '';
}

export function getUrlQueryParams(pathname , useHost = 'testbook.com'){
    let finalPathname = pathname || '';
    if(!finalPathname?.startsWith('/')){
        finalPathname='/'+finalPathname;    
    }
    let location;
    location = new URL(`https://${useHost}${finalPathname}`);

     let queryObject={};
    if(location.search.length){
        for (let [name, value] of location.searchParams) {
            queryObject[name]=value;
        }  
    } 
    return queryObject;
}

export function getPathNameOnlyFromUrl(url,useHost = 'testbook.com'){
    let finalPathname = url || '';
    if(!finalPathname?.startsWith('/')){
        finalPathname='/'+finalPathname;    
    }
    let location;
    location = new URL(`https://${useHost}${finalPathname}`);

    return location.pathname.toLowerCase();
}

export function getMergeTwoUrlWithQueryUrl(urlFromRequest,urlFromSheet){
    let redirection_query=getUrlQueryParams(urlFromSheet);
    let redirect_url=getPathNameOnlyFromUrl(urlFromSheet);
    let url_query=getUrlQueryParams(urlFromRequest);
    if(redirection_query["language"]){
        url_query["language"] = redirection_query["language"];
    }
    let merge_qp=getQueryStringFromParams(redirection_query,url_query);
    let finalUrl =redirect_url + merge_qp;
    return finalUrl;
}

export function mergeQueryParamInUrl(url:string = '',url_query:Object = {}){
    let redirection_query=getUrlQueryParams(url);
    let redirect_url=getUrlWithoutQueryParams(url);
    let merge_qp=getQueryStringFromParams(redirection_query,url_query);
    let finalUrl =redirect_url + merge_qp;
    return finalUrl;
}

export function getPathNameWithQueryUrl(url,useHost = 'testbook.com'){
    let finalPathname = url || '';
    if(!finalPathname?.startsWith('/')){
        finalPathname='/'+finalPathname;    
    }
    let location;
    location = new URL(`https://${useHost}${finalPathname}`);

    return location.pathname.toLowerCase()+location.search;
}

export function getTextFragment(){
    if(isServer()) { return "";}
    try{
        let navigationObj = performance.getEntries().find((p:any) => p.type === 'navigate');
        if (navigationObj && navigationObj?.name) {
            let url = new URL(navigationObj.name);
            return url && url?.hash || '';
        }
    } catch (e) {
        console.error(e);
    }
    return '';
}

export function retainUtmParams(url: any, redirect_url: any) {
    if (url === redirect_url) {
        return getUrlQueryParams(url);
    }

    let utm_params_keys = ["utm_source","utm_campaign","utm_medium","utm_term","utm_content"];
    let url_qp = getUrlQueryParams(url);
    let redirect_url_qp = getUrlQueryParams(redirect_url);
    let redirect_url_params =  Object.keys(redirect_url_qp);
    const isUtmPresent = (element) => utm_params_keys.includes(element);

    let finalParamObj = {};

    if(redirect_url_params.some(isUtmPresent)){
        finalParamObj = redirect_url_qp;
    }
    else{
        finalParamObj = {...retainObjectKeys(url_qp, utm_params_keys), ...redirect_url_qp};
    }

    return finalParamObj;
}

export function getRedirectionUrl(url, pathname, useHost = 'testbook.com') {

    let windowLocationUrl;
    if(isServer()) {
        try{
            let urlObject = new URL(`https://${useHost}${pathname}`);
            windowLocationUrl = urlObject.href;
        }
        catch (e) {
            windowLocationUrl = '';
        }
    } else {
        windowLocationUrl = window && window.top.location.href;
    }
    let qp = retainUtmParams(windowLocationUrl , url);
    return getUrlWithoutQueryParams(url) + getQueryStringFromParams(qp);
}

export function getOriginFromURL(url:string) {
    const urlObject = new URL(url);
    return `${urlObject.protocol}//${urlObject.host}`;
}
  
export function getPathSegmentsFromURL(url:string) {
    const urlObject = new URL(url);
    return urlObject.pathname.split('/').filter(segment => segment.trim() !== '');
}

export function getLanguageSegmentUrl(lang:string, href:string, queryParamPreserve:boolean = false){ // this function will give url with added language segment
    lang = lang.toLowerCase();

    if(!langMap[lang]){
        return href;
    }

    let url = '';
    if(lang === 'english'){
      url = `${getOriginFromURL(href)}`;
    }else{
      let langCode = langMap[lang];
      url = href.includes('/amp/') ? `${getOriginFromURL(href)}/amp/${langCode}` : `${getOriginFromURL(href)}/${langCode}`; // check if amp is present or not
    }
    let pathSegments = getPathSegmentsFromURL(href);
    if(pathSegments?.[0] && (languageCodeMap[pathSegments[0]] || pathSegments[0] === 'amp')){
      pathSegments = pathSegments.slice(1);
    }
    url += `/${pathSegments.join('/')}`;
    if(queryParamPreserve){
      let qp = getUrlQueryParams(href);
      url += getQueryStringFromParams(qp);
    }
    return url;
}

export function getLanguageQueryParamUrl(lang:string, href:string, queryParamPreserve:boolean = false){ // this function will give url with added language query parameter
    lang = lang.toLowerCase();

    if(!langMap[lang]){
        return href;
    }

    let url = '';
    if(lang === 'english'){ //do not add language param in case of english
        url = queryParamPreserve ? href : getUrlWithoutQueryParams(href);
    }else if(queryParamPreserve){
      url = mergeQueryParamInUrl(href, {language:lang});
    }else{
        url = getUrlWithoutQueryParams(href);
        url += '?language=' + lang;
    }
    return url;
}

