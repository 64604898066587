import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EmiIframeComponent} from "./emi-iframe.component";
import {EmiWrapperExportModule} from "@features/emi-wrapper/emi-wrapper.export.module";
import {EmiRoutingModule} from "./emi.routing.module";
import {StoreModule} from "@ngrx/store";
import {emiIframeReducer} from "./feature-state/emi-iframe.reducer";

@NgModule({
    declarations: [EmiIframeComponent],
    exports: [EmiIframeComponent],
    imports: [
        CommonModule,
        EmiWrapperExportModule,
        EmiRoutingModule,
        StoreModule.forFeature('emiIframe', emiIframeReducer)
    ]
})
export class EmiModule { }
