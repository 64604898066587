<div class="pass-header {{cssModifierClass}}" *ngIf="passType=='globalPass'">
    <div class="wrapper">
        <div class="pass-header__content"  *ngIf="!isLogoutGoalSubs && !isLogoutPaymentPage">
            <img class="pass-header__logo" src="/assets/img/pass-new/logo_pass.svg" alt="logo" width="125" height="30">
            <h3 class="h1" [innerHTML]="'PASS_HEADING' | translate"></h3>
            <pass-includes [passType]="passType" [passPageInfo]="passPageInfo"></pass-includes>
            <pass-pitch class="wrapper" [showPassPitch]="passPageInfo?.properties?.showPassPitch"></pass-pitch>
            <div class="pass-header__users">
                <image-bubble [bubbleImages]="activePassStudents"></image-bubble>
                <span *ngIf="passType=='globalPass'" [innerHTML]="'PASS_USERS_HEADING' | translate : {'passPageInfo.activeUsersCount' :passPageInfo?.activeUsersCount}"></span>
            </div>
        </div>

        <div class="pass-header__cards">
            <app-coupon-code *ngIf="!isLogoutGoalSubs && !isLogoutPaymentPage" [isLogoutPaymentPage]="isLogoutPaymentPage" [passType]="passType" [onPage]="true"></app-coupon-code>
            <pass-status
                    *ngIf="passPageInfo && !isLogoutGoalSubs && !isLogoutPaymentPage"
                    [activeUsersCount]="passPageInfo?.activeUsersCount"
                    [activePassStudents]="activePassStudents"
                    [openPricingModal]="openPricingModal"
                    [referralDiscount]="referralDiscount"
                    [isEMIPlan]="showEMIPlans"
                    [passType]="passType">
                </pass-status>
            <pass-cards
                [goalId]="goalId"
                [pId]="pId"
                [passType]="passType"
                [ctaSticky]='isStickyOnScroll'
                [openPricingModal]="openPricingModal"
                [referralDiscount]="referralDiscount"
                [cardType]="'Header'"
                [goalTitle]="goalTitle"
                [coldbootParams]="coldbootParams"
                [couponModalType]="couponModalType"
                (purchaseButtonClick)="purchaseButtonLead($event)"
                [isLogoutGoalSubs]="isLogoutGoalSubs"
                [isLogoutPaymentPage]="isLogoutPaymentPage"
                [cssModifierClass]="'plan-page--logout-purchase'"
            ></pass-cards>
        </div>
    </div>
</div>

<div class="{{cssModifierClass}}" *ngIf="passType=='passPro'"
     [ngClass]="{'pro-header': !isLogoutGoalSubs && !isLogoutPaymentPage,
                'pro-header__logged-in': !isLogoutGoalSubs && !isLogoutPaymentPage && isLoggedIn}">
    <div class="wrapper">
        <div class="pro-header__feature" *ngIf="!isLogoutGoalSubs && !isLogoutPaymentPage">
            <pass-status
                    *ngIf="passPageInfo"
                    [activeUsersCount]="passPageInfo?.activeUsersCount"
                    [activePassStudents]="activePassStudents"
                    [openPricingModal]="openPricingModal"
                    [referralDiscount]="referralDiscount"
                    [isEMIPlan]="showEMIPlans"
                    [passType]="passType">
            </pass-status>
            <pass-pro-features [showHeading]="true" [iconType]="iconType" [cssModifierClass]="'on-page'"></pass-pro-features>
            <pass-user-announcement></pass-user-announcement>
        </div>
        <div class="pass-header__content">
            <app-coupon-code *ngIf="!isLogoutGoalSubs && !isLogoutPaymentPage" [onPage]="true"
            [cssModifierClass]="'coupon-offers_state__pro'" [passType]="passType"></app-coupon-code>
            <pass-cards
                    [goalId]="goalId"
                    [pId]="pId"
                    [passType]="passType"
                    [ctaSticky]='isStickyOnScroll'
                    [openPricingModal]="openPricingModal"
                    [referralDiscount]="referralDiscount"
                    [cardType]="'Header'"
                    [goalTitle]="goalTitle"
                    [coldbootParams]="coldbootParams"
                    [couponModalType]="couponModalType"
                    (purchaseButtonClick)="purchaseButtonLead($event)"
                    [isLogoutGoalSubs]="isLogoutGoalSubs"
                    [isLogoutPaymentPage]="isLogoutPaymentPage"
                    [cssModifierClass]="'plan-page--logout-purchase'"
                    [announcementPitch]="announcementPitch"
            ></pass-cards>
        </div>
    </div>
</div>

<!--    goal pass header    -->
<div class="plan-header {{cssModifierClass}}" *ngIf="passType=='goalSubs'"
     [ngClass]="{'loggedin': isLoggedIn, 'sidebar-open':isLeftSideBarOpen && isGoalCourse,
      'page-cie':isGoalCourse, 'plan-header': !isInGoalPageHeader, 'goal-header': isInGoalPageHeader}">
    <div class="wrapper">
        <div class="plan-header__strip" *ngIf="!isInGoalPageHeader && !isLogoutGoalSubs">
            <h2 *ngIf="!showStaticGoalHeading">{{goalTitle}}<img src="/assets/img/super/super-coaching.svg?v=2" alt="logo" width="174" height="24"></h2>
            <h2 *ngIf="showStaticGoalHeading" class="strip-goal">
                <span class="text-danger">Best <span *ngIf="isPrimaryGoalPage">{{goalTitle}} Coaching</span> Plans</span> for your preparation
            </h2>
        </div>
        <sale-offer-strip *ngIf="!isMobile && goal && !isLogoutGoalSubs" [goalId]="goal?.id" [fileName]="'goalSale.json'"></sale-offer-strip>
        <pass-status *ngIf="passPageInfo && !isLogoutPaymentPage"
                     [activeUsersCount]="passPageInfo?.activeUsersCount"
                     [activePassStudents]="activePassStudents"
                     [openPricingModal]="openPricingModal"
                     [referralDiscount]="referralDiscount"
                     [passType]="passType"
                     [isEMIPlan]="showEMIPlans"
                     [isInGoalPageHeader]="isInGoalPageHeader"
                     [isLogoutPaymentPage]="isLogoutPaymentPage"
        ></pass-status>
        <!--    emi pitch   -->
        <div class="emi-pitch" *ngIf="!isMobile && goal?.properties?.isClientEMIAvailable && !isInGoalPageHeader && !isLogoutPaymentPage && !isLogoutGoalSubs">
            <img src="/assets/img/pass-new/rupee-green.svg" alt="rupee">
            <span style="flex: 1">EMI Available</span>
            <span style="color: #000">View EMI Plans</span>
            <label class="emi-toggle" for="emiToggle" (change)="toggleViewEmis()">
                <input type="checkbox" [checked]="showEMIPlans" id="emiToggle">
                <span class="emi-toggle__handler"></span>
            </label>
        </div>

        <pass-cards
                [goalId]="goalId"
                [pId]="pId"
                [passType]="passType"
                [ctaSticky]='isStickyOnScroll'
                [openPricingModal]="openPricingModal"
                [referralDiscount]="referralDiscount"
                [cardType]="'Header'"
                [goalTitle]="goalTitle"
                [coldbootParams]="coldbootParams"
                [couponModalType]="couponModalType"
                (purchaseButtonClick)="purchaseButtonLead($event)"
                [showTBPass]="passPageInfo?.properties?.showPassPitch"
                [isInGoalPageHeader]="isInGoalPageHeader"
                [forcedLimit]="forcedLimit"
                [recommendedPass]="recommendedPass"
                [isLogoutGoalSubs]="isLogoutGoalSubs"
                [isLogoutPaymentPage]="isLogoutPaymentPage"
                [cssModifierClass]="'plan-page--logout-purchase'"
                [goalSlug]="goal?.properties?.slug"
                [setCoupon]="setCoupon"
                [hideStickyPlan]="hideStickyPlan"
                [showEMIPlans]="showEMIPlans"
        ></pass-cards>
        <pass-pitch *ngIf="isMobile" [showPassPitch]="passPageInfo?.properties?.showPassPitch"></pass-pitch>
    </div>
    <request-callback [bannerType]="'banner'" [isInModal]="true" [goal]="goal" [leadParentId]="'goal?.id'" [action]="'createLeads'" (generateLeadEvent)="generateLeadEvent($event)" id="request-callback"></request-callback>
</div>

