import { Component, OnInit, Input, EventEmitter,Output, OnChanges, SimpleChanges } from '@angular/core';
import {selectStudent} from "@angular-commons/core/application-state/app.selectors";
import {showAlert, showWhatsappModal} from "@angular-commons/core/application-state/app.actions";
import { Store } from '@ngrx/store';
import { getCurrentVisitSource } from '@angular-commons/shared/utility/user';
import { isServer} from '@angular-commons/shared/utility/tb-common';
import { createLeads, getCallbackStatus, getLeadsData } from '../../feature-state/request-callback.actions';
import { selectBannerMeta, selectLeadMeta, selectLeadsData, selectRequestData } from '../../feature-state/request-callback.selector';
import { _CallbackData, CreateLeadsParams, LeadsData } from '@angular-commons/models/entity/request-callback/request-callback.adapter';
import { PlatformService } from '@angular-commons/core/services/platform-service';
import { GAEventService } from '@angular-commons/core/services/ga-event.service';
import { TbcoreService } from '@angular-commons/core/services/tbcore.service';
import { LeadGenerationService } from '@angular-commons/core/services/lead-generation.service';
import { getQueryStringFromParams } from '@angular-commons/shared/utility/url';
import { ActivatedRoute } from '@angular/router';
import { Course } from '@angular-commons/models/entity/classes/classes.adapter';

export interface BannerMeta {
  type: string,
  id: string,
  mobile: string,
  submitted: boolean
}

@Component({
  selector: 'request-callback',
  templateUrl: './request-callback.component.html',
  styleUrls: ['./request-callback.component.scss']
})
export class RequestCallbackComponent implements OnInit, OnChanges {
  @Output() sendMgoalRequestCallbackEvent=new EventEmitter();
  @Output() sendRequestCallbackEvent=new EventEmitter();
  @Output() generateLeadEvent=new EventEmitter();
  @Input() bannerType: string = 'banner';
  @Input() leadParentId: string = '';
  @Input() action: string = 'createLeads';
  @Input() coldbootParams;
  @Input() course:Course;
  @Input() cssModifierClass?:string = '';
  @Input() goal:any;
  @Input() leadData?:any;
  @Input() isInModal? = false;
  @Input() showDynamicComponent = false;
  @Input() description:string;
  @Input() icon;
  @Input() ctaText:string;
  @Input() title:string;
  @Input() fireGaEvent:boolean = false;
  @Input() from:string = '';
  @Input() plutusLeadId?:string;
  @Input() plutusLeadProdName?:string;

  private isServer: boolean = isServer();
  private isLoggedIn: boolean = this.platformService.isLoggedIn();
  private studentData: any;
  isMobile:boolean = this.platformService.isMobile();
  isPlutus:boolean = this.platformService.currentProjectName() == 'plutus';
  isMobileVerified:boolean=false;
  isWhatsappFlow:boolean=false;
  public bannerMeta: BannerMeta = {
    type: this.bannerType,
    id: this.leadParentId,
    mobile: '',
    submitted: false
  }
  constructor(private store: Store<{}>, private platformService: PlatformService,
    private gaEventService: GAEventService, private tbCore:TbcoreService, private route:ActivatedRoute, private leadGenerationService: LeadGenerationService) { }

    ngOnChanges(changes: SimpleChanges) {
      if(changes.leadData?.currentValue && this.route.snapshot.queryParams["callbackRequest"]){
        this.leadGenerationService.generateLead(changes.leadData.currentValue)
      }
    }

  ngOnInit(): void {

    this.bannerMeta.type = this.bannerType;
    this.bannerMeta.id = this.leadParentId;
    this.isWhatsappFlow = this.platformService.getQueryParamValue('wa_chat') === '1';

    this.store.select(selectStudent).subscribe( (studentData: any) => {
      if (studentData && studentData.id && studentData.id !== "-1") {
        this.studentData = studentData;
        if (this.studentData.mobile && this.studentData.mobile.length >= 10) {
          if (this.bannerMeta.id) {
            if(this.action === 'createLeads') {
              this.store.dispatch(getLeadsData({params: {id: this.bannerMeta.id}}));
            }
            else if (this.action === 'requestCallback') {
              this.store.dispatch(getCallbackStatus({params: {id: this.bannerMeta.id}}));
            }
          }
          this.bannerMeta.mobile = this.studentData.mobile;
          if (this.bannerMeta.mobile.length > 10) {
            this.bannerMeta.mobile = this.bannerMeta.mobile.substring(this.bannerMeta.mobile.length - 10);
          }
        }
        if(studentData.mobileVerified){
          this.isMobileVerified = true;
        }
      }
    });

    this.store.select(selectRequestData).subscribe( (requestData: _CallbackData) => {
      if (requestData.requested && requestData.requested.length > 0) {
        this.setBannerData(requestData.requested);
      }
    });

    this.store.select(selectLeadsData).subscribe( (requestData: LeadsData) => {
      if (requestData.leads && requestData.leads.length > 0) {
        this.setBannerData(requestData.leads);
      }
    });

    this.store.select(selectBannerMeta).subscribe(bannerMeta => {
      this.setBannerMeta(bannerMeta);
    });

    this.store.select(selectLeadMeta).subscribe(leadData => {
      if(this.leadParentId && leadData[this.leadParentId]) {
        this.setBannerMeta(leadData[this.leadParentId]);
      }
    });

  }

  setBannerData(data) {
    this.bannerMeta.submitted = true;
    let lastRequest = data[data.length - 1];
    if (lastRequest.mobile) {
      this.bannerMeta.mobile = lastRequest.mobile;
      this.bannerMeta.mobile = this.bannerMeta.mobile.substring(this.bannerMeta.mobile.length - 10);
    }
  }

  setBannerMeta(bannerMeta) {
    if (bannerMeta.mobile && bannerMeta.submitted) {
      this.bannerMeta = {
        ...this.bannerMeta,
        mobile: bannerMeta.mobile,
        submitted: bannerMeta.submitted,
      };
      if (bannerMeta.id && this.bannerMeta.id === bannerMeta.id) {
        this.bannerMeta.id = bannerMeta.id;
      }
    }
  }

  submitRequestCallback() {
    if(this.isPlutus && (this.course?.id || this.plutusLeadId)){
      let leadsData = {
        action:'requestCallback',
        client: this.isMobile ? "mweb":"web",
        mobile: this.bannerMeta.mobile,
        type: 'Plutus_Education',
        prodName:this.plutusLeadProdName || '',
        prodId: this.plutusLeadId || this.course?.id,
        prodType: 'Plutus_Career_Program',
        source:'plutus'
      };
      this.leadGenerationService.generateLead(leadsData);
      this.sendRequestCallbackEvent.emit();
    }else{
      let leadsData = {
        action:'requestCallback',
        client: this.isMobile ? "mweb":"web",
        mobile: this.bannerMeta.mobile,
        type: this.course?.isGoalCourse ? 'other' : this.course.isSkillCourse ? 'SkillAcademy' : 'SelectCourse',
        prodId: this.course?.id,
        prodType: this.course?.isGoalCourse ? 'other' : this.course.isSkillCourse ? 'SkillAcademy' : 'SelectCourse',
      };
      this.leadGenerationService.generateLead(leadsData);
      this.store.dispatch(showAlert({message: 'Request submitted successfully!'}));
      this.sendRequestCallbackEvent.emit();
    }
      
  }

  submitCreateLeadsRequest() {
    let request = new CreateLeadsParams();
    request.body = {
      client: this.isMobile ? 'mweb' : 'web',
      mobile: this.bannerMeta.mobile,
      goalId: this.bannerMeta.id,
      utm: getCurrentVisitSource(),
      action: 'requestCallback'
    }
    this.sendMgoalRequestCallbackEvent.emit();
    this.sendRequestCallbackEvent.emit();
    this.store.dispatch(createLeads({details: request}));
    // this.store.dispatch(showAlert({message: 'Request submitted successfully!'}));
  }


  requestCallback(mobile) {
    this.bannerMeta.mobile = mobile;

    if(this.from === "plutus") {
      this.gaEventService.sendPlutusCallBackRequestedEvent();
    }

    if(this.isWhatsappFlow){
      this.store.dispatch(showWhatsappModal());
      return; // Don't want to go any further.
    }

    if(this.action === 'createLeads' && !this.goal && !this.isPlutus) {
      this.submitCreateLeadsRequest();
    }
    else if(this.action === 'requestCallback') {
      this.submitRequestCallback();
    }
    if(this.goal && this.isLoggedIn){
      this.generateLeadEvent.emit("request_callback")
    }
    this.bannerMeta.submitted = true;
    if(this.fireGaEvent){
      this.gaEventService.sendPageExploreActivity({
        category:'callbackRequested'
      })
    }
    if(!this.platformService.isLoggedIn() || (this.platformService.isLoggedIn() && !this.isMobileVerified)){  
      let qp = getQueryStringFromParams(this.route?.snapshot?.queryParams,{callbackRequest:1}) 
      let params:any = {
        isMobileSignUpVerification: true,
        headerText: 'Book free counselling session by registering your mobile number',
        m: this.bannerMeta.mobile,
        isMobileVerified : false,
        redirect_url:`${this.platformService.getPathName()}${qp}`
      };

      if(this.course && !this.isPlutus){
        params.gtmExtras = JSON.stringify({
          type: this.course.isSkillCourse ? 'SkillAcademy' : 'SelectCourse',
          prodId: this.course.id,
          prodType: this.course.isSkillCourse ? 'SkillAcademy' : 'SelectCourse'
        });
      }else if(this.isPlutus){
        params.skipOnboarding = true;
        params.isPlutus=true;
        if(this.isPlutus && this.plutusLeadId){
          params.gtmExtra = JSON.stringify({
            type: 'Plutus_Education',
            prodId: this.plutusLeadId || this.course?.id,
            prodType: 'Plutus_Career_Program',
            source:'plutus',
            prodName:this.plutusLeadProdName || '',
          })
        }
      }
      if(this.isPlutus){
        this.tbCore.openSignUpSignInModal(params, false,true);
      }else{
        this.tbCore.openSignUpSignInModal(params, false);
      }
    }

  }

  pushRequestedCallbackEvent() {
    if (this.isServer) {
      return;
    }
    let eventInfo = {
      productID: this.leadParentId,
      path: (window.location.pathname + window.location.search) || ''
    }
    this.gaEventService.sendRequestedCallbackEvent(eventInfo);
  }

}
