declare var global;

function isServer() {
    return (typeof global !== "undefined");
}
enum platforms {
    SERVER,
    CLIENT
}

function platform(name: platforms, onFail?) {
    let allow = (name === platforms.SERVER && isServer()) || (name === platforms.CLIENT && !isServer());
    return function (target: any, propertyKey: string, descriptor){
        let orig = target[propertyKey];
        target[propertyKey] = function(...rest: any) {
            if (allow) {
                return orig.bind(this, ...rest)();
            }
            else if (onFail) {
                return onFail.bind(this)();
            }
        };
        return target;
    };
}

export function serverOnly(onFail?){
    return platform(platforms.SERVER, onFail);
}

export function clientOnly(onFail?){
    return platform(platforms.CLIENT, onFail);
}