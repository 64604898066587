import { Component, OnInit } from '@angular/core';
import {PlatformService} from "@core/services/platform-service";
import {AuthenticationService} from "@core/services/authentication.service";
import {webOtp} from "@shared/utility/web-otp";
import {hideLoader, showLoader} from "@shared/utility/tb-common";

const MAX_RETRIES = 3 , TIME_BETWEEN_RETRY = 30;

@Component({
  selector: 'mobile-verification',
  templateUrl: './mobile-verification.component.html',
  styleUrls: ['./mobile-verification.component.scss']
})
export class MobileVerificationComponent implements OnInit {

  isMobile = this.platformService.isMobile();
  disableCloseBtn:boolean=false;
  //modal position bottom variable

  uiState = {
    timerStarted: false,
    mobile: {
      mobileNumber: '',
      error: ''
    },

    otp: {
      value: '',
      error: '',
      nextRequestIn: 0,
      requested: 0,
      interval:null
    },
    showModal: false,
    editMode: true,
    isSkippable: false,
    strings: {
      tryAnotherMethod: 'TRY_ANOTHER_METHOD',
      enterCompletOtp: 'ENTER_COMPLETE_OTP',
      required: 'REQUIRED',
      mobileAlertText: "ENTER_VALID_MOBILE",
      enterOtp: "ENTER_OTP",
      incompleteOtp: "ENTER_VALID_OTP"
    }
  };

  webOtp: webOtp;

  constructor(private platformService: PlatformService, private authenticationService: AuthenticationService) {
    this.authenticationService.showMobileVerificationModal$.subscribe(data => {
      this.uiState.showModal = data.showModal;
      this.uiState.mobile.mobileNumber = data.mobile.length >= 10  ? data.mobile.slice(data.mobile.length - 10, data.mobile.length) : data.mobile;
      this.uiState.isSkippable = data.isSkippable;
        this.disableCloseBtn=data.disableCloseBtn
    });
  }

  ngOnInit(): void {
  }

  requiredField(model,$event) {
    let key = ($event.key || '').toLowerCase();
    model.error = "";
    if(model.value.length === 0){
      model.error = this.uiState.strings.required;
    }
    if(key.length == 1 && (key.match(/[^0-9]/g) || model.value.length >=6) && (!$event.ctrlKey && !$event.metaKey) ){
      $event.preventDefault();
    }
  }

  changeNumber() {
    this.uiState.editMode = true;
    this.uiState.otp.nextRequestIn = 30;
    this.uiState.timerStarted = false;
    this.uiState.otp.error = '';
    this.uiState.otp.value='';
    clearInterval(this.uiState.otp.interval);
  }

  verifyOtp() {
    let userOTP = this.uiState.otp;
    if(userOTP.error){
      return userOTP.error = this.uiState.strings.enterOtp;
    }
    if(userOTP.value.length < 6){
      return userOTP.error = this.uiState.strings.incompleteOtp;
    }
    showLoader();
    this.authenticationService.webOtp.abort();
    this.authenticationService.verifyMobileUpdate(userOTP.value).subscribe((response : any)=>{
      userOTP.error = response && !response.success && response.message;
      if(!userOTP.error){
        this.authenticationService.onMobileVerifySuccess({
          mobileNumber: this.uiState.mobile.mobileNumber
        }, true);
      }else {
        hideLoader();
      }
    },({error})=>{
      userOTP.error = error.message;
      hideLoader();
    })
  }

  otpField() {
    this.uiState.otp.error = "";
    if(this.uiState.otp.value.length < 6){
      this.uiState.otp.error = this.uiState.strings.enterCompletOtp;
    }
  }

  validateMobile() {
    if(this.uiState.mobile.mobileNumber.length !== 10 || !'6789'.includes(this.uiState.mobile.mobileNumber[0])){
      return this.uiState.mobile.error = this.uiState.strings.mobileAlertText;
    }

    this.requestOTP(false,true);
  }

  requestOTP(bool=false, isCall = false) {

    if(this.uiState.otp.requested >= MAX_RETRIES) {
      if(this.uiState.editMode){
        return this.uiState.mobile.error = this.uiState.strings.tryAnotherMethod;
      }
      return this.uiState.otp.error = this.uiState.strings.tryAnotherMethod;
    }

    this.authenticationService.webOtp.checkForWebOTP().then((otp)=>{
      if(otp){
        this.uiState.otp.value = otp;
        this.uiState.otp.error = '';
        this.verifyOtp();
      }
    })
    this.uiState.otp.requested +=1;

    this.uiState.mobile.error = '';
    this.uiState.otp.error = '';
    let networkRequest;

    networkRequest = this.authenticationService.updateMobile(this.uiState.mobile.mobileNumber, isCall)
    networkRequest.subscribe((response : any)=>{
      this.uiState.otp.error = response && !response.success && response.message;
      if(this.uiState.otp.error){ return;}

      this.uiState.editMode = false;

      this.uiState.otp.nextRequestIn = TIME_BETWEEN_RETRY;
      this.uiState.otp.interval = setInterval(()=>{
        this.uiState.otp.nextRequestIn -= 1;
        if(this.uiState.otp.nextRequestIn <= 0){
          clearInterval(this.uiState.otp.interval);
        }
      },1000);

    },({error})=>{
      this.uiState.otp.error = error.message;
    })

  }

  closeModal() {
    this.authenticationService.onMobileVerifyFailure({});
  }

  mobileKeys($event : any){
    let key = ($event.key || '').toLowerCase();
    let model = this.uiState.mobile;
    //prevent keys
    if(key.length == 1 && (key.match(/[^0-9]/g) || model.mobileNumber.length >=10) && (!$event.ctrlKey && !$event.metaKey) ){
      $event.preventDefault();
    }
    //enforce for cases like copy paste
    model.mobileNumber = model.mobileNumber.replace(/[^0-9]/g, '');
    if(model.mobileNumber.length >=10) {
      model.mobileNumber = model.mobileNumber.slice(0,10);
    }
  }
}