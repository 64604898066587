import {EntityRecipe, DataRecipe, ToBackendRecipe, PersistentDataRecipe} from './recipe';
import { Cache , DEFAULT_CACHE_CONTROLS} from "./cache";
import { Observable } from 'rxjs';
import {IdentityAdapter} from "@models/mapping-framework";

export interface Network {
    get  : (url,params?, endpointType?,forceAuthOnServer?) => Observable<any> ,
    post : (url,body?,params?,forceAuthOnServer?) => Observable<any>,
    put  : (url, body?, params?,forceAuthOnServer?) => Observable<any>,
    delete: (url, body?, params?,forceAuthOnServer?) => Observable<any>,
    upload: (url, file, body?, params?,option?,forceAuthOnServer?,skipBaseUrl?,skipHeaders?) => Observable<any>,
    patch : (url,body?,params?,forceAuthOnServer?) => Observable<any>,

}


export abstract class ModelManager {
    abstract network : any;
    abstract platformService : any;

    public cacheStore : Cache;

    public makeEntityRecipe(frontendModel,adapter,requestModel,cacheControls = DEFAULT_CACHE_CONTROLS) : EntityRecipe{
        return new EntityRecipe(this.network,this.platformService,frontendModel,adapter,requestModel,this.cacheStore,cacheControls);
    };
    public makeDataRecipe(frontendModel,adapter,requestModel,cacheControls = DEFAULT_CACHE_CONTROLS) : DataRecipe{
        return new DataRecipe(this.network,this.platformService,frontendModel,adapter,requestModel,this.cacheStore,cacheControls);
    };
    public makePersistentDataRecipe(adapter,requestModel,cacheControls = DEFAULT_CACHE_CONTROLS) : PersistentDataRecipe{
        return new PersistentDataRecipe(this.network,this.platformService,IdentityAdapter,adapter,requestModel,this.cacheStore,cacheControls);
    }
    public makeToBackendRecipe(frontendModel,adapter,requestModel, adaptFrontendModel?, cacheControls = DEFAULT_CACHE_CONTROLS) : ToBackendRecipe{
        return new ToBackendRecipe(this.network,this.platformService, frontendModel,adapter,requestModel, this.cacheStore,cacheControls, adaptFrontendModel);
    };

    constructor(isServer){
       if(isServer){
           if(!globalThis.globalCache){
               globalThis.globalCache = new Cache();
           }
           this.cacheStore = globalThis.globalCache;
       }  else {
           this.cacheStore = new Cache();
       }
       (<any>globalThis).exposedCache = this.cacheStore;
    }
}
