import {arrayModel, propertyMaps, SourceProjectionMapper} from '@models/mapping-framework';
import {Network} from '@core/services/model-manager/main';
import {baseModel, setupModel} from '@models/types';

export class Sequences extends baseModel {
  @propertyMaps('id')
  public id;

  @propertyMaps('title')
  public title;

  @propertyMaps('component')
  public component;

  @propertyMaps('weight')
  public order;

  @propertyMaps('isHidden')
  public isHidden;
}
setupModel(Sequences,{});

export class GoalPageSequence extends baseModel {
  @propertyMaps('_id')
  public id;

  @propertyMaps('type')
  public pageType;

  @propertyMaps('sequences', arrayModel(Sequences), Sequences)
  public sequences;

}
setupModel(GoalPageSequence,{});

export class GoalPageSequenceWrapper extends baseModel {
  @propertyMaps('sequence', arrayModel(GoalPageSequence), GoalPageSequence)
  public sequence;

}
setupModel(GoalPageSequenceWrapper,{});

export class GoalPageSequenceApi {
  static apiEndpoint = 'v1/dashboard-info';

  static projection;
  static get  __projection(){ 
    if(!GoalPageSequenceApi.projection){
      GoalPageSequenceApi.projection = JSON.stringify(new SourceProjectionMapper(GoalPageSequenceWrapper).map());
    }
    return GoalPageSequenceApi.projection;
  }

  static apiCall(network: Network, params) {
    return network.get(GoalPageSequenceApi.apiEndpoint, {type: params.pageType, goalSlug: params.slug, client: params.client, __projection: GoalPageSequenceApi.__projection });
  }
}
