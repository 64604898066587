import { NgModule } from '@angular/core';
import {BrowserTransferStateModule} from '@angular/platform-browser';
import {CoreModule} from "@core/core.module";

@NgModule({
    declarations: [
    ],
    imports: [
        CoreModule,
        BrowserTransferStateModule
    ],
    exports: [
        CoreModule
    ],
    providers:[]
})
export class CoreClientModule { }
