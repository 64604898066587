import { Cache , cacheControls} from "./cache";
import { Observable } from "rxjs";
import { Projector } from "../../../shared/utility/projector";
import {getLocalStorage, isServer, setLocalStorage} from "@shared/utility/tb-common";
import { HttpEventType } from '@angular/common/http';
import {DAY, HOUR} from "@shared/utility/date-utilities";
import {tap} from "rxjs/operators";

interface recipe {
    frontendModel : any;
    adapter       : any; // this is BE model
    requestModel  : any; // this is the api call class
    cacheControls : cacheControls;
    cacheStore    : Cache;
}

export interface getRecipe extends recipe {
    get : (arg:String|Object) => Observable<Object>,
}

export interface sendRecipe extends recipe{
    send : (arg:Object) => Observable<Object>,
}


// these are the contracts that model-manager expects , these may have more keys but must have these keys
interface FrontendModel {
    adapt     : (rawObj:Object,_forceNotEmptyObjectArrays?:boolean,cacheStore?:any) => any
    __metadata: { type : string },
}

interface Adapter<FrontendModelType> {
    adapt        : (rawObj:Object,_forceNotEmptyObjectArrays?:boolean) => FrontendModelType
    __projection : Object
}

interface RequestModel {
    apiEndpoint : string,
    apiCall     : (network:any,arg:Object|String) => Observable<Object>
}

class BaseRecipe implements recipe{
    frontendModel       : FrontendModel;
    adapter             : Adapter<FrontendModel>;
    requestModel        : RequestModel;
    cacheControls       : cacheControls;
    cacheStore          : Cache;
    network             : any;
    platformService     : any;
    adaptFrontendModel  : boolean;

    constructor(network : any, platformService:any, frontendModel : FrontendModel,adapter,requestModel,cacheStore,cacheControls, adaptFrontendModel?){
        this.frontendModel = frontendModel;
        this.adapter = adapter;
        this.requestModel = requestModel;
        this.cacheStore = cacheStore;
        this.cacheControls = cacheControls;
        this.network = network;
        this.platformService = platformService;
        this.adaptFrontendModel = adaptFrontendModel;
    }
}

export class EntityRecipe extends BaseRecipe implements getRecipe{

    handleCached(subscriber, cached , complete = false){
        let projectedData = cached && cached.value; // projected result | false
        if( cached && (this.cacheControls.optimistic ||  (!Cache.isStale(cached) && !this.cacheControls.alwaysStale) ) && projectedData){
            //send optimistic/not stale response
            subscriber.next(this.frontendModel.adapt(projectedData));
            if(complete){
                subscriber.complete();
            }
        }
    }
    get(id : String){
        let cacheKey = this.frontendModel.__metadata.type + this.platformService.getSiteLang();
        return new Observable(subscriber => {
            let cached = this.cacheStore.getEntity(cacheKey,id);
            if(cached.value && cached.value.__inFlight__){
                cached.value.__inFlight__.promise.then(
                    ($cached) => {
                        this.handleCached(subscriber,$cached,true)
                    }, (e) => console.error("error getting cached data",e));
            }
            else {
                this.handleCached(subscriber,cached)
            }
            if(!cached || (this.cacheControls.alwaysStale || Cache.isStale(cached) )){
                this.cacheStore.waitForEntity(cacheKey,id);
                //get new data from API
                this.requestModel.apiCall(this.network,id).subscribe({
                    next:(response:any)=>{
                        //update cache
                        let processedData = this.frontendModel.adapt(this.adapter.adapt(response.data,false),false,this.cacheStore); // response is automatically projected internally
                        if(this.frontendModel.__metadata && cacheKey){
                            this.cacheStore.setEntity(cacheKey,id,processedData);
                        }
                        //send latest response
                        subscriber.next(processedData);
                        subscriber.complete();
                    },
                    error:(error) => {
                        this.cacheStore.invalidateEntity(cacheKey,id);
                        subscriber.error(error);
                        subscriber.complete();
                    }
                })
            } else if(!(cached.value && cached.value.__inFlight__)) {
                subscriber.complete();
            }
        });
    }

}

export class PersistentDataRecipe extends BaseRecipe implements getRecipe{
    private validity = 24 * HOUR;
    setValidity(ms){
        this.validity = ms;
        return this;
    }
    private dataRecipe;
    constructor(network : any, platformService:any, frontendModel : FrontendModel,adapter,requestModel,cacheStore,cacheControls, adaptFrontendModel?) {
        super(network, platformService, frontendModel,adapter,requestModel,cacheStore,cacheControls, adaptFrontendModel);
        this.dataRecipe = new DataRecipe(network, platformService, frontendModel,adapter,requestModel,cacheStore,cacheControls, adaptFrontendModel);
    }
    get(params : Object){
        if(isServer()){
            //only persist data on client
            return this.dataRecipe.get(params);
        }
        let cacheKey = "persistent_0" + this.requestModel.apiEndpoint + JSON.stringify({language:this.platformService.getSiteLang(),...params});
        let cachedData
        try{
            let validity = getLocalStorage(cacheKey,'validity');
            if(new Date().getTime() < parseInt(validity)){
                cachedData = JSON.parse(getLocalStorage(cacheKey));
            }
        } catch(e){
            console.error(e);
        }
        if(cachedData){
            return new Observable(subscriber => {
                subscriber.next(cachedData);
                subscriber.complete();
            });
        } else {
            return this.dataRecipe.get(params).pipe(tap(value => {
               try {
                   setLocalStorage(cacheKey,JSON.stringify(value),this.validity/DAY)
               } catch (e) {
                   console.error(e);
               }
            }));
        }
    }
}


export class DataRecipe extends BaseRecipe implements getRecipe{

    handleCached(subscriber, cached, complete = false){
        let projectedData = cached?.value ; // && Projector.project(cached.value,this.adapter.__projection); // projected result | false
        if( cached && (this.cacheControls.optimistic || (!Cache.isStale(cached) && !this.cacheControls.alwaysStale) ) && projectedData){
            //send optimistic/not stale response
            try {
                subscriber.next(this.frontendModel.adapt(projectedData));
            } catch(e) {
                subscriber.next(projectedData);
                console.error(e);
            }
            if(complete){
                subscriber.complete();
            }
        }
    }
    get(params : any){
        let cacheKey = this.requestModel.apiEndpoint + JSON.stringify({language: params.language || this.platformService.getSiteLang(),...params});
        //try{ console.time(cacheKey); } catch {}
        return new Observable(subscriber => {
            let cached = this.cacheStore.getData(cacheKey);
            if(cached.value && cached.value.__inFlight__){
                cached.value.__inFlight__.promise.then(
                    ($cached) => {
                        this.handleCached(subscriber,$cached,true);
                    }, (e) => console.error("error getting cached data",e));
            }
            else {
                this.handleCached(subscriber,cached);
            }
            if(!cached || (this.cacheControls.alwaysStale || Cache.isStale(cached)) ){
                this.cacheStore.waitForData(cacheKey);
                //get new data from API
                this.requestModel.apiCall(this.network,params).subscribe({
                    next:(response:any)=>{
                        //try{ console.timeEnd(cacheKey); } catch {}
                        //update cache
                        let _response = response;
                        if (typeof response === 'object' && response.hasOwnProperty('data')){
                            _response = response.data;
                        }
                        let processedData = this.frontendModel.adapt(this.adapter.adapt(_response,false),false,this.cacheStore); // response is automatically projected internally
                        this.cacheStore.setData(cacheKey, processedData);
                        //send latest response
                        subscriber.next(processedData);
                        subscriber.complete();
                    },
                    error:(error) => {
                        //try{ console.timeEnd(cacheKey); } catch {}
                        console.error("error making api call ", error,this.platformService.getPathName());
                        this.cacheStore.invalidateData(cacheKey);
                        subscriber.error(error);
                        subscriber.complete();
                    }
                })
            } else if(!(cached.value && cached.value.__inFlight__)){
                //try{ console.timeEnd(cacheKey); } catch {}
                subscriber.complete(); // case for when cached data is returned can't complete earlier due to optimistic cache
            }
        });
    }

}

export class ToBackendRecipe extends BaseRecipe implements sendRecipe{

    send(params : Object){
        return new Observable<any>(subscriber => {
            let backendAcceptableData = this.adapter.adapt(params,false);
            this.requestModel.apiCall(this.network,backendAcceptableData).subscribe({
                    next:(response:any) => {
                        //send call status

                        if(this.adaptFrontendModel) {
                            let processedData;
                            if(typeof response === 'string' || typeof response.data === 'string'){
                                processedData = this.frontendModel.adapt(response.data || response, false, this.cacheStore); // response is automatically projected internally
                            }
                            else {
                                processedData = this.frontendModel.adapt(response.data, false, this.cacheStore); // response is automatically projected internally
                            }
                            subscriber.next(processedData);
                        }
                        else {
                            subscriber.next(response);
                        }
                        subscriber.complete();
                    },
                    error:(error) =>{
                        console.error("error from api call",error);
                        subscriber.error(error);
                        subscriber.next(error);
                        subscriber.complete();
                    }
                })
        });
    }

    upload(params: Object){
        return new Observable<any>(subscriber => {
            let backendAcceptableData = this.adapter.adapt(params,false);
            this.requestModel.apiCall(this.network,backendAcceptableData).subscribe({
                    next:(response:any) => {

                        if(response.type == HttpEventType.UploadProgress && response.loaded && response.total){
                            let progress= Math.round(100 * response.loaded / response.total);
                            subscriber.next({progress:progress});
                        }
                        else if(response.body && response.body.data){
                            let processedData;
                            if(typeof response.body.data === 'string'){
                                processedData = this.frontendModel.adapt(response, false, this.cacheStore); // response is automatically projected internally
                            }
                            else {
                                processedData = this.frontendModel.adapt(response.body, false, this.cacheStore); // response is automatically projected internally
                            }
                            subscriber.next({progress:100,url:processedData.url});
                            subscriber.complete();
                        }
                    },
                    error:(error) =>{
                        console.error(error);
                        subscriber.error(error);
                        subscriber.complete();
                    }
                })
        });
    }

}
