<div class="dropdown" *ngIf="!availableContentLanguages">
    <a class="btn btn--language" type="button" data-toggle="dropdown" >
        <span class="language__icon pref-lang-selector lang-english" [ngClass]="{'lang-english': selectedLang == 'english','lang-hindi': selectedLang == 'hindi'}"></span>
        <span class="desktop" *ngIf="selectedLang == 'english'" translate>HEADER_ENGLISH</span>
         <span class="desktop" *ngIf="selectedLang == 'hindi'" translate>HEADER_HINDI</span>
    </a>
    <div class="dropdown-menu" *ngIf="isServer">
        <a *ngIf="selectedLang !== 'english'" class="dropdown-item" [href]="pathName">
            <span class="language__icon lang-english"></span>
            <span translate>HEADER_ENGLISH</span>
        </a>
        <a *ngIf="selectedLang !== 'hindi'" class="dropdown-item" [href]="pathName+'?language=hindi'">
            <span class="language__icon lang-hindi"></span>
            <span translate>HEADER_HINDI</span>
        </a>
    </div>
    <div class="dropdown-menu" *ngIf="!isServer">
        <a *ngIf="selectedLang !== 'english'" class="dropdown-item" (click)="switchLang('english')">
            <span class="language__icon lang-english"></span>
            <span translate>HEADER_ENGLISH</span>
        </a>
        <a *ngIf="selectedLang !== 'hindi'" class="dropdown-item" (click)="switchLang('hindi')">
            <span class="language__icon lang-hindi"></span>
            <span translate>HEADER_HINDI</span>
        </a>
    </div>
</div>

<div class="dropdown" *ngIf="availableContentLanguages">
    <a class="btn btn--language" type="button" data-toggle="dropdown" >
        <span class="language__icon pref-lang-selector lang-english" [ngClass]="{'lang-english': selectedLang == 'English','lang-hindi': selectedLang == 'Hindi'}"></span>
         <span class="desktop">{{selectedLang}}</span>
    </a>
    <div class="dropdown-menu" *ngIf="isServer">
        <ng-container *ngIf="isCurrentAffairs && langArray.length">
            <a *ngFor="let lang of langArray" class="dropdown-item" [href]="languageMap[lang?.lang].toLowerCase()">
                <span class="language__icon lang-english"></span>
                {{lang?.lang}}
            </a>
        </ng-container>
        <ng-container *ngFor="let lang of availableContentLanguages">
            <a class="dropdown-item" *ngIf="!isCurrentAffairs && lang.url" [href]="lang.url">
                <span class="language__icon lang-english"></span>
                {{lang?.lang}}
            </a>
        </ng-container>
    </div>
    <div class="dropdown-menu" *ngIf="!isServer">
        <ng-container *ngIf="isCurrentAffairs && langArray.length">
            <a *ngFor="let lang of langArray" class="dropdown-item" (click)="switchLang(lang?.lang)">
                <span class="language__icon lang-english"></span>
                {{lang?.lang}}
            </a>
        </ng-container>
        <ng-container *ngIf="!isCurrentAffairs">
            <a *ngFor="let lang of availableContentLanguages" class="dropdown-item" (click)="switchLang(lang?.lang)">
                <span class="language__icon lang-english"></span>
                {{lang?.lang}}
            </a>
        </ng-container>
    </div>
</div>

