import {Injectable} from '@angular/core';
import {getDeferredPromise, IDeferPromise} from '@shared/utility/defer-promise';
import {getLocalStorage, isServer, setLocalStorage} from '@shared/utility/tb-common';

interface  IDeferPromiseMap {
    property: IDeferPromise;
}
declare var window: any;

@Injectable({
    providedIn: 'root',
})
export class GAService{
    vanillaServicePromise = getDeferredPromise();

    getVanillaGaEventService(tryCount=0){
        if(!isServer()){
            setTimeout(() =>{
                if(window.gaEventService){
                    this.vanillaServicePromise.resolve(window.gaEventService);
                } else {
                    if(tryCount < 100){
                        this.getVanillaGaEventService(tryCount++);
                    } else {
                        this.vanillaServicePromise.reject('Cannot find vanilla ga event service');
                    }
                }
            },50);
        }
        return this.vanillaServicePromise.promise;
    }
    constructor() {
        if (!isServer()){
            window.getVanillaGaEventService = () => this.getVanillaGaEventService();
            window.gtag = (function gtag(){
                try {
                    window.getVanillaGaEventService().then((s) => {
                        s.getCurrentWindow().dataLayer.push(arguments);
                    });
                } catch (e) {
                    console.error(e);
                }
            });
            // prefetch fields
            this.prefetch();
        }
    }
    private fields = {} as IDeferPromiseMap;
    fieldValue = {} as {
        property: any;
    };
    private fieldValuesPopulated = false;
    GA_MEASUREMENT_ID = 'G-P3YHHH05L4';
    private prefetchRetries = 100;

    private prefetch() {
        this.getFields(['client_id', 'session_id']).catch(() => {
            setTimeout(() => {
                if (this.prefetchRetries > 0){
                    this.prefetchRetries --;
                    this.prefetch();
                }
            }, 50);
        });
    }

    getFields(fieldNames){
        const promises = fieldNames.map( fieldName => this.getField(fieldName));
        return Promise.all(promises).then(() => {
            this.fieldValuesPopulated = true;
        }).catch(console.error);
    }

    getField(fieldName){
        if (!this.fields[fieldName]){
            this.fields[fieldName] = getDeferredPromise();
        }
        if (!isServer()){
            window.gtag('get', this.GA_MEASUREMENT_ID, fieldName, (fieldValue) => {
                this.fieldValue[fieldName] = fieldValue;
                this.fields[fieldName].resolve(fieldValue);
            });
        } else {
            this.fields[fieldName].reject();
            throw new Error('Tried to access GA on server');
        }
        return this.fields[fieldName].promise;
    }


    get client_id(){
        return this.fieldValue['client_id'];
    }
    get session_id(){
        return this.fieldValue['session_id'];
    }

    getFieldsFromLS(){
        return new Promise((resolve, reject) => {
            if(isServer()){
                resolve({});
            }
            let gaParams = getLocalStorage('gaParams');
            if(gaParams){
                try {
                    gaParams = JSON.parse(gaParams);
                    let params = {};
                    if(gaParams && gaParams.clientId){
                        params['clientId'] = gaParams.clientId;
                    }
                    if(gaParams && gaParams.sessionId){
                        params['sessionId'] = gaParams.sessionId;
                    }
                    resolve(params);
                } catch (error) {
                    console.error("error in parsing gtag params", error);
                    reject({});
                }
            }else{
                this.updateFields()
                .then(({clientId, sessionId}:any) => {
                    if(clientId && sessionId){
                        resolve({clientId,sessionId});
                    }
                }).catch((err) => {
                    console.error("error fetching gtag parameters", err);
                    reject({});
                })
            }
        }).catch((err) => {
            console.error("error fetching gtag parameters", err);
        })
    }

    updateFields(){
        return new Promise((resolve, reject) => {
            if(isServer() || !window.gtag){
                resolve({});
            }
            Promise.all([
                this.getField('client_id'),
                this.getField('session_id')
            ]).
            then(([clientId, sessionId]) => {
                if(clientId && sessionId){
                    setLocalStorage("gaParams", JSON.stringify({clientId,sessionId}),1);
                    resolve({clientId,sessionId});
                }
            }).catch((err) => {
                console.error("error fetching gtag parameters", err);
                reject({});
            })
        })
    }
}
