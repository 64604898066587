import { AfterViewInit, Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {EMI_BLOCKER, EMI_DOWN_PAYMENT, EMI_MANDATE} from "@shared/utility/constants";
import {PlatformService} from "@core/services/platform-service";
import { hideLoader } from '@angular-commons/shared/utility/tb-common';
import { GAEventService } from '@angular-commons/core/services/ga-event.service';

@Component({
    selector: 'emi-wrapper',
    templateUrl: './emi-wrapper.component.html',
    styleUrls: ['./emi-wrapper.component.scss']
})
export class EmiWrapperComponent implements OnInit,  AfterViewInit {
    constructor(private route: ActivatedRoute,
                private platformService: PlatformService,
                private gaEventService: GAEventService) { }
    showDownPayment = false;
    showMandate = false;
    showBlocker = false;
    queryParams: any = {};

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.queryParams = params;
            this.platformService.addClass('body-transparent');
            if(params?.modalType === EMI_DOWN_PAYMENT){
                this.showDownPayment = true;
            }
            else if(params?.modalType === EMI_MANDATE){
                this.showMandate = true;
            }
            else if(params?.modalType === EMI_BLOCKER){
                this.showBlocker = true;
            }
        });
    }

    ngAfterViewInit(){
        hideLoader();
    }

    switchPopup(data:any){
        this.platformService.postMessageToParentWindow({source: 'emiModal', openEmiModal: true , ...data});
    }

    handleClose(){
        this.platformService.postMessageToParentWindow({source: 'emiModal', closeEmiModal: true});
    }

    handleGtmEvents(type) {
        const eventInfo = {
            goalID: this.queryParams?.goalId || '',
            goalName: this.queryParams?.goalTitle || '',
            productID: this.queryParams?.productId || '',
            productName: this.queryParams?.productName || '',
            emiPlanPrice: this.queryParams?.paidAmount || 0,
            payableAmount: this.queryParams?.totalAmountToPay || 0
        }
        switch(type) {
            case 'blockerPopupView':
                this.gaEventService.sendBlockerPopupViewEvent(eventInfo);
                break;
            case 'emiInfoScreenVisited':
                eventInfo['referrer'] = 'Blocker Popup';
                this.gaEventService.sendEmiInfoScreenVisitedEvent(eventInfo);
                break;
            case 'mandatePopupViewed':
                this.gaEventService.sendEnableAutoEmiPaymentClicked(eventInfo);
                break;
            case 'madateInfoCollected':
                this.gaEventService.sendEmiMandateInformationCollected(eventInfo);
                break;
        }
    }
}
