import { Injectable, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Router , NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter,map ,mergeMap} from 'rxjs/operators';
import { META_TAGS_TO_MERGE } from '@angular-commons/shared/utility/seo-utilities';
import { isServer } from '@angular-commons/shared/utility/tb-common';
import { getLanguageQueryParamUrl, getLanguageSegmentUrl } from '@angular-commons/shared/utility/url';

@Injectable()
export class SeoService {
    constructor(private title: Title,
                private meta: Meta,
                @Inject(DOCUMENT) private document,
                private activatedRoute : ActivatedRoute,
                private router: Router) { }

    existingLinkTags = [];

    private findExistingLink(attributes: any): HTMLLinkElement | null {
        if(isServer()){ //always add link tag on server side
            return null;
        }
        if(!this.existingLinkTags.length){
            const existingLinks = this.document.head.querySelectorAll('link.spa-meta') as HTMLLinkElement[]; //find all our custom links with spa-meta class
            this.existingLinkTags = existingLinks;
        }
        if(!this.existingLinkTags.length){
            return null;
        }

        this.existingLinkTags.forEach(link => {
            let hasAllAttributes = true;
            for (const att in attributes) {
                if (link.getAttribute(att) !== attributes[att]) {
                    hasAllAttributes = false;
                    break;
                }
            }
    
            if (hasAllAttributes) {
                return link;
            }
        });
        return null;
    }

    private removeAllLinkTags(){
        const existingLinks = this.document.head.querySelectorAll('link.spa-meta') as HTMLLinkElement[];
        if(!existingLinks.length){
            return;
        }

        existingLinks.forEach(link => {
            link.parentNode.removeChild(link);
        });
    }

    addlinkTag( attributes: any) {
        const existingLink = this.findExistingLink(attributes);
        if (!existingLink) {
            const link: HTMLLinkElement = this.document.createElement('link');
            for (let att in attributes) {
                link.setAttribute(att, attributes[att]);
            }
            this.document.head.appendChild(link);
        }
    }

    addSeo = (eventObj: any) => {
        let event = eventObj.tags;
        this.removeAllLinkTags(); //remove all existing link tags first
        for (let e in event){
            if(event[e].type && event[e].type === "title"){
                this.title.setTitle(event[e].attributes.title)
            } else if (event[e].type && event[e].type === "meta"){
                this.addMetaTag(event[e].attributes);
            } else if (event[e].type && event[e].type === "link"){
                this.addlinkTag({class: 'spa-meta' , ...event[e].attributes})
            }
        }
    };

    clearSeo(){
        let metaTags = this.document.getElementsByClassName('spa-meta');
        for(let tag of metaTags){
            tag.remove();
        }
    }
    setSeo(){
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
                while (route.firstChild) route = route.firstChild;
                return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data)
        ).subscribe( (event) => {
            this.clearSeo();
            this.addSeo(event);
        });
    }

    private addMetaTag(attributes:any = {}) {
        if(!(attributes.name || attributes.property)  || !attributes.content){
            return;
        }
        const existingName = attributes.name || attributes.property;
        const existingMetaTag = this.meta.getTag(`name='${existingName}'`);
        if (existingMetaTag && META_TAGS_TO_MERGE.includes(existingName)) {
          const existingContent = existingMetaTag.content.split(',').map(ele => ele.trim());
          if(!existingContent.includes(attributes.content)){
            const updatedContent = `${existingContent}, ${attributes.content}`;
            this.meta.updateTag({class:'spa-meta', ...attributes, content: updatedContent});
          }
        } else {
          this.meta.addTag({class: 'spa-meta', ...attributes});
        }
    }  

    clearHrefLangs() {
        const existingLinks = this.document.head.querySelectorAll('link.spa-href[hreflang]');
        if (!existingLinks.length) {
            return;
        }
        existingLinks.forEach(link => {
            link.parentNode?.removeChild(link);
        });
    }
    
    setHrefLangs(currentUrl: string, isSegmentStrategy: boolean, availableContentLangs: { code: string; lang: string; }[]) {
        this.clearHrefLangs();
    
        const getLanguageUrl = isSegmentStrategy ? getLanguageSegmentUrl : getLanguageQueryParamUrl;
    
        // add default tag
        this.addlinkTag({ class: 'spa-href', rel: 'alternate', hreflang: 'x-default', href: getLanguageUrl('english', currentUrl) });
    
        // add tags based on available languages
        availableContentLangs.forEach(obj => {
            const href = getLanguageUrl(obj.lang, currentUrl);
            this.addlinkTag({ class: 'spa-href', rel: 'alternate', href, hreflang: obj.code });
        });
    }
}
