import {NetworkService} from "./network.service";
import {
    clearStudentCookie,
    clearStudentData,
    getCurrentVisitSource, getFakeFp,
    getFirstVisitSource, setStudentData
} from "@shared/utility/user";
import {BehaviorSubject, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {addExamsApi} from "@models/entity/onboarding/onboarding.adapter";
import {
    getClientQueryParamValue,
    getFingerPrintId,
    getPageText, isServer, getPageType,
    setClientCookie,
    setToSessionStorage, showLoader, getLocalStorage, delay
} from "@shared/utility/tb-common";
import {OnboardingParams} from "@shared/types/login";
import {nonEmptyParams} from "@shared/utility/validator";
import {GAEventService} from "@core/services/ga-event.service";
import {onboardingPreference, signInEvent, signUpEvent} from "@shared/utility/ga-events";
import {PlatformService} from "@core/services/platform-service";
import {TbcoreService} from "@core/services/tbcore.service";
import {getDeferredPromise} from "@shared/utility/defer-promise";
import {getGoals, getStudentMe, sendClientErrorLogToBackend, showAlert, setStudentGCLID,} from "@core/application-state/app.actions";
import {Store} from "@ngrx/store";
import {clientOnly} from "@shared/utility/platform-decorators";
import {GOAL_EXPIRY} from "@shared/utility/constants";
import {selectGoals} from "@core/application-state/app.selectors";
import {TransferStateManager} from "@core/services/transferStateManager";
import {ProductAccessService} from "@core/services/product-access.service";
import {studentMeApi} from "@models/entity/student/student.adapter";
import {getPathFromUrl, getRedirectionUrl, getUrlQueryParams, getQueryStringFromParams, getUrlWithoutQueryParams} from "@shared/utility/url";
import { StudentService } from "./student.service";
import {webOtp} from "@shared/utility/web-otp";
import {GAService} from "@core/services/ga.service";

declare var yoloLib;
declare var window:any;

const KEY_COOKIE_JUST_SIGNED_UP = 'justSignedUp';

interface otpResponse {
    success:boolean,
    message:string,
    data:{
        isSignUp: boolean;
        tokenExpiry:number,
        token:string,
        loginState?: any;
        sid?: any;
    }
};

export const KEY_REFERRAL_INFO = 'referralInfo';
export let KEY_SID_CHECK: any = "KEY_SID_CHECK"

@Injectable()
export class AuthenticationService {

    medium:string = 'TestbookMobile';

    showMobileVerificationModal$ = new BehaviorSubject({
        showModal: false,
        mobile: '',
        isSkippable: false,
        disableCloseBtn:false
    });

    enrollmentFlow;
    isSignUp;
    postLoginResponse;
    onBoardingParams;

    postLoginWorkMap={
        'targets':{success:false,apiCall:()=>this.targetAddFlow(),toBeExecuted:false,done:false},
        'masterClassSeries':{success:false,apiCall:()=>this.masterLessonEnrollmentFlow(),toBeExecuted:false,done:false}
    };
    webOtp = new webOtp();

    constructor(private network: NetworkService,
                private gaEventService: GAEventService,
                private platformService: PlatformService,
                private studentService: StudentService,
                private coreService: TbcoreService,
                private transferStateManager: TransferStateManager,
                private productAccessService: ProductAccessService,
                private store: Store<any>,
                private ga: GAService) {

    }

    targetAddFlow(this:any){
        if(!this.onBoardingParams.targets){
            return;
        }
        this.postLoginWorkMap.targets.toBeExecuted=true
        this.setTargets()
    }

    setTargets(){
        let api='v1/students/targets'
        let param=this.onBoardingParams.targets
        this.network.post(api,{},{ids:param}).subscribe((res)=>{
            this.postLoginWorkCompletion('targets',true,true)
        },
        (err)=>{
            this.postLoginWorkCompletion('targets',true,false)
        })
    }

    masterLessonEnrollmentFlow(this){
        if(!this.onBoardingParams.masterClassSeries){
            return;
        }
        this.postLoginWorkMap.masterClassSeries.toBeExecuted = true;
        this.getMasterSeriesAccess();
    }

    getMasterSeriesAccess(){
        if(isServer()){
            this.postLoginWorkCompletion('masterClassSeries',true,false);
        }
        let api = `v1/mclass-series/${this.onBoardingParams.masterClassSeries}/enrollment`;
        let postApi=`v1/mclass-series/${this.onBoardingParams.masterClassSeries}/activity`;
        this.network.get(api).subscribe((res:any)=>{
            if(!res.data.isEnrolled){
                this.network.post(postApi,{enroll:true,isAutoEnroll:true,unroll:false}).subscribe((res)=>{
                    this.postLoginWorkCompletion('masterClassSeries',true,true);
                })
            }
            else{
                this.postLoginWorkCompletion('masterClassSeries',true,true);
            }
        },
        (err)=>{
            this.postLoginWorkCompletion('masterClassSeries',true,false);
        })
    }

    postLoginWorkCompletion(key,isDone,isSuccess){
        this.postLoginWorkMap[key].done=isDone;
        this.postLoginWorkMap[key].success=isSuccess;
        this.callBackForStatusCheck();
    }

    callBackForStatusCheck(){
        for(let key of Object.keys(this.postLoginWorkMap) ){
            if(this.postLoginWorkMap[key].toBeExecuted && !this.postLoginWorkMap[key].done){
                return;
            }
        }
        this.goForPostLoginFlow()
    }

    goForPostLoginFlow(){
        if(this.postLoginWorkMap.targets.toBeExecuted){
            if(this.postLoginWorkMap.targets.success){
                this.postLoginHelper(this.onBoardingParams,this.postLoginResponse);
                return;
            }
        }
        this.postLoginHelper(this.isSignUp ? this.enrollmentFlow : this.onBoardingParams,this.postLoginResponse)
    }

    addTarget(ids){
        return addExamsApi.apiCall(this.network,{ids})
    }

    setMedium(params){
        if(params.network === 'fb'){
            this.medium = 'Facebook';
        } else if(params.network === 'gplus'){
            this.medium = 'Google';
        } else if(params.email || params.identifier.includes('@')){
            this.medium = 'TestbookEmail';
        } else {
            this.medium = 'TestbookMobile';
        }
    }

    fireOnboardingPreferenceEvent(params){
        let event = new onboardingPreference({
            ...params,
            page:getPageText(),
            pageType:getPageType()
        });

        this.gaEventService.pushToDataLayer(event);
    }

    signUpInEvent(onboardingParams:OnboardingParams, response : any){
        let isSignUp = response.data.isSignUp;
        let gtmExtras = onboardingParams.gtmExtras;
        let eventInfo = {
            ...gtmExtras,
            medium:this.medium,
            page: getPageText(),
            pageType: getPageType(),
            pagePath:  this.platformService.getPathName(true) + window.top.location.search,
            referrerPagePath: onboardingParams.googleState.referrerPagePath,
            referrer: onboardingParams.referrer || onboardingParams.googleState.referrer,
            referrerType: onboardingParams.referrerType || onboardingParams.googleState.referrerType
        }
        if(this.platformService.currentProjectName() === 'plutus') {
            eventInfo["source"] = "plutus";
        }
        let event = isSignUp? new signUpEvent(eventInfo) : new signInEvent(eventInfo);
        if(isSignUp) {
            (<signUpEvent>event).sid = response.data.sid;
        }
        return event;
    }

    leadGeneratedEvent(eventInfo) {
        if (!eventInfo.pagePath) {
            eventInfo = {...eventInfo,
                pagePath: window.location.pathname + window.location.search
            };
        }
        this.gaEventService.sendLeadGeneratedEvent(eventInfo);
    }

    postLoginHelper(onboardingParams:OnboardingParams, response : any){
        let isSignUp = false;
        if(response){
           isSignUp = response.data.isSignUp;
           if(isSignUp){
               setClientCookie(KEY_COOKIE_JUST_SIGNED_UP, 'yes', 1);
               setClientCookie('newlySignedUp',"true",1);
           } else {
               setClientCookie('newlySignedUp',"false",1);
           }
        }
        this.eventWithSidPromise.finally(() => {
            if (onboardingParams.gtmExtras && onboardingParams.gtmExtras.leadGeneratedEventInfo && onboardingParams.gtmExtras.mobileSignUpIn) {
                this.leadGeneratedEvent(onboardingParams.gtmExtras.leadGeneratedEventInfo);
            }
            this.postLoginRedirection(onboardingParams);
        });
    }

    postLoginRedirection(onboardingParams: OnboardingParams) {
        if (onboardingParams?.original?.base_url !== '/pdf-viewer'){
            this.platformService.setCookie("justLoggedIn",true,1);
        }
        // fire logged in event or go to enrollment flow
        if(onboardingParams.isInModal){
            if(onboardingParams.redirect_url.startsWith('/onboarding')){
                window.parent.postMessage({isDesktopOnboarding:true,source:'signupInitiated'}, '*');
                return window.location.href = getRedirectionUrl(onboardingParams.redirect_url, this.platformService.href);
            }
            let urlToRedirect = onboardingParams.redirect_url;
            setToSessionStorage('redirect_url', '');
            const goal = this.getGoal();
            if(goal && goal.id && onboardingParams.redirect_url.endsWith('super-coaching')){
                this.productAccessService.getGoalPageOrDashboardUrl(goal.id, goal.slug).then((url) => {
                    urlToRedirect = url;
                    this.platformService.dispatchCustomEvent('logged-in', { key: getRedirectionUrl(urlToRedirect, this.platformService.href)});
                    return window.parent.location.href = getRedirectionUrl(urlToRedirect, this.platformService.href);
                });
                return;
            } else{
                if(onboardingParams.redirect_url.endsWith('super-coaching')){
                    urlToRedirect = '/home';        // hack to land new user in home pages post login. might break
                }
                let urlToRedirectdispatch= getRedirectionUrl(urlToRedirect, this.platformService.href)
                if(this.platformService.currentProjectName() != 'plutus'){
                    this.platformService.dispatchCustomEvent('logged-in', { key: urlToRedirectdispatch});
                }else{
                    return window.parent.location.href = onboardingParams.original.redirect_url;
                }
                
                return window.parent.location.href = getRedirectionUrl(urlToRedirect, this.platformService.href);
            }
        }else{
            if(onboardingParams.redirect_url && onboardingParams.redirect_url.startsWith('/onboarding')){
                setToSessionStorage('redirect_url', '');
                window.location.href = getRedirectionUrl('/enrollment?redirect_url='+encodeURI(onboardingParams?.original?.redirect_url), this.platformService.href);
            }else{
                if (this.isPdfUrl(onboardingParams.redirect_url)) {
                    window.open(onboardingParams.redirect_url);
                    setTimeout(() => {
                        window.location.href = '/home';
                    });
                } else{
                    try{
                        const url = new URL(onboardingParams.redirect_url);
                        const validPaths = ['/super-coaching', '/home'];
                        if (!validPaths.includes(url.pathname)) {
                          const redirection_query = getUrlQueryParams(onboardingParams.redirect_url);
                          const redirect_url = getUrlWithoutQueryParams(onboardingParams.redirect_url);
                          const merge_qp = getQueryStringFromParams(redirection_query, { stay: 1 });
                          const finalUrl = redirect_url + merge_qp;
                          window.location.href = getRedirectionUrl(finalUrl, this.platformService.href);
                        } else {
                          window.location.href = getRedirectionUrl(onboardingParams.redirect_url, this.platformService.href);
                        } 
                    }catch(err){
                        console.log(err,'url not valid');
                        window.location.href='/home';
                    }
                }
            }
        }
    }

    isPdfUrl(url) {
        let ext = '.pdf';
        let result = url.match(ext);
        if (result && result.length > 0){
            return result[0] === ext;
        }
        return false;
    }

    onboardingParams;
    response;
    eventWithSidPromise = Promise.resolve();
    triggerPostLoginTasks(onboardingParams:OnboardingParams,response:any){
        this.onboardingParams = onboardingParams;
        this.response = response;
        let signUpInEvent = this.signUpInEvent(onboardingParams, response);
        this.eventWithSidPromise = this.coreService.fireEventWithSid(signUpInEvent, false);
        this.getSelectedGoal();
    }
    @clientOnly()
    accountBlockedApi(sid) {
       return this.network.get(`v1/students/${sid}/login-state`);
   }
    @clientOnly()
    accountBlockedResetApi(sid) {
        return this.network.post(`v1/students/${sid}/reset-block`, {});
    }

    getSignupDetails(signupSource:any){
        let signupDetails : any = {};
        let backupReferrer = undefined;
        let backupReferrerType = undefined;
        try{
            backupReferrer = getClientQueryParamValue('referrer', signupSource.entrance) || getLocalStorage('tbreferrer') || undefined;
            backupReferrerType = getClientQueryParamValue('referrerType', signupSource.entrance)  || getLocalStorage('tbreferrerType') || undefined;
        } catch (e) {}
        signupDetails.page = this.platformService.getReferrer() ? (this.onBoardingParams?.referrer || backupReferrer) : getPageText();
        signupDetails.pagePath = getPathFromUrl(this.platformService.getReferrer()) || this.platformService.getPathName(true);
        signupDetails.pageType = this.platformService.getReferrer() ? (this.onBoardingParams?.referrerType || backupReferrerType) : getPageType();
        return signupDetails;
    }

    loginHelper(relativeUrl,_body,_params){
        let subject = new Subject<any>();
        let body = {firstVisitSource: getFirstVisitSource(), signupSource: getCurrentVisitSource(),..._body};
        body.signupDetails = this.getSignupDetails(body.signupSource);

        let deviceId = getFakeFp(); //fallback fp to allow logins
        let fingerprint = getFingerPrintId() || deviceId;
        let params : any = {
            client : this.platformService.isMobile() ? "mweb" :"web",
            ..._params
        };
        if(fingerprint){
            params.browserFpId = fingerprint;
        }
        if(deviceId){
            params.tbDeviceId = deviceId;
        }
        if(!params.tbDeviceId){
            params.tbDeviceId = 'fakeID';
        }

        Promise.race([this.ga.getFieldsFromLS(),delay(2000)]).then((data:any) => {
            params = {...params, ...data};
            this.loginApiCall(relativeUrl,body,params,subject);
        }).catch((error) => {
            console.error(error);
            this.loginApiCall(relativeUrl,body,params,subject);
        });

        return subject;
    }

    loginApiCall(relativeUrl,body,params,subject){
        this.network.post(relativeUrl, body, params).subscribe({
            next: (response: otpResponse) => {
                let resData = response.data;
                let resToken = response.data.token;
                if (response.success && resData && resToken) {
                    setToSessionStorage(KEY_SID_CHECK, resData.sid);
                    this.platformService.setToken(response.data.token, response.data.tokenExpiry);
                    subject.next(response);
                }
                else {
                    subject.error(response.message);
                }
            },
            error: (error) => {
                if (error?.error) {
                    if (error.error.data && error.error.data?.loginState) {
                        if (error.error.data.loginState?.firstTimeBlocked || error.error.data.loginState?.selfUnblockWait || error.error.data.loginState?.blockedUser) {
                            let sid = error.error.data.loginState.sid;
                            let url = `/account-blocked?sid=${sid}`;
                            this.platformService.redirect(url, 302, true);
                        }
                    }
                    if (error.error.message) {
                        subject.error(error.error.message);
                        subject.complete();
                    }
                }
            }
        })
    }

    registerApiCall(relativeUrl,body,params,isSignUp,subject){
        this.network.post(relativeUrl,body,params).subscribe((response:otpResponse) => {
            if(response.success && response.data && response.data.token){
                this.platformService.setToken(response.data.token, response.data.tokenExpiry);
                response.data.isSignUp = response.data.isSignUp || isSignUp;
                subject.next(response);
            } else {
                subject.error(response.message);
            }
            subject.complete();

        }, ({error}) => {
            if (error && error.message) {
                subject.error(error.message);
                subject.complete();
            }
        });
    }

    registerHelper(relativeUrl,_body,_params,isSignUp = false){
        let subject = new Subject<any>();
        let body = {firstVisitSource: getFirstVisitSource(), signupSource: getCurrentVisitSource(),..._body};

        body.signupDetails = this.getSignupDetails(body.signupSource);

        let params : any = {
            client : this.platformService.isMobile() ? "mweb" :"web",
            ..._params,
            refLink: _params.refLink ? _params.refLink.toUpperCase() : ''
        };

        let partnerInfo =  this.platformService.getCookie('tb_partner');
        if (partnerInfo) {
            params.partner = partnerInfo;
        }

        Promise.race([this.ga.getFieldsFromLS(),delay(2000)]).then((data:any) => {
            params = {...params, ...data};
            this.registerApiCall(relativeUrl,body,params,isSignUp,subject);
        }).catch((error) => {
            console.error(error);
            this.registerApiCall(relativeUrl,body,params,isSignUp,subject);
        });

        return subject;
    }

    updateSelectedGoal({gid,activity,productName,page,pagePath}){
        return this.network.post('v2/students/goals',{}, {gid,activity,page,pagePath,productName})
    }

    studentGoalDirty = false;
    getGoal() {
        return this.platformService._getGoalCookie() || {};
    }

    setGoal(value, expiry, reload = false, updateBackend = true,gtmExtras={}) {
        let dpromise = getDeferredPromise();

        if(!(value && value.id && value.slug)){
            dpromise.reject();
            return dpromise.promise;
        }

        let oldGoal = this.getGoal();
        if(oldGoal && oldGoal.id == value.id){
            dpromise.resolve(oldGoal);
            return dpromise.promise;
        }

        let goal = this.platformService._setGoalCookie(value,expiry);
        if(reload){
            showLoader("");
        }
        if(updateBackend){
            this.studentGoalDirty = true;
        }
        if(updateBackend && !isServer() && this.platformService.isLoggedIn()) {
            let obj:any={... gtmExtras,... {gid:value.id,activity:'currentActiveGoal'}}
            this.updateSelectedGoal(obj).subscribe(()=>{
                this.store.dispatch(getStudentMe()); // reload student data
                dpromise.resolve(goal);
                if(reload){
                    window.top.location.reload();
                }
            })
        } else {
            dpromise.resolve(goal);
            if(!isServer() && reload){
                window.top.location.reload();
            }
        }
        return dpromise.promise;
    }

    generateOTP(_params){
        let subject = new Subject<any>();
        let body = {firstVisitSource: getFirstVisitSource(), signupSource: getCurrentVisitSource(),..._params};

        body.signupDetails = this.getSignupDetails(body.signupSource);

        let params = {..._params};
        Promise.race([this.ga.getFieldsFromLS(),delay(2000)]).then((data:any) => {
            params = {...params, ...data};
            this.generateOTPApiCall(body,params,subject);
        }).catch((error) => {
            console.error(error);
            this.generateOTPApiCall(body,params,subject);
        });
        return subject;
    }

    generateOTPApiCall(body,params,subject){
        this.network.post('v2/mobile/signup',body,params).subscribe((response:any) => {
            subject.next(response);
            subject.complete();
        },({error}) => {
            if (error && error.message) {
                subject.error(error.message);
                subject.complete();
            }
        });
    }

    verifyOTP(emailOrMobile,otp,name?,email?,referrerCode?){
        let params : any = {emailOrMobile,otp,refLink:referrerCode||''};
        if(name){
            params.name = name
        }
        if(email){
            params.email = email
        }
        return this.loginHelper('v2/otp/login',{emailOrMobile,otp},params);
    }

    updateMobile(mobile:string , isCall : boolean = false){
        let params : any = {mobile};
        params.onCall = isCall
        return this.network.post('v2/students/me/mobile/getotp',{},params)
    }

    verifyMobileUpdate(otp){
        let params : any = {otp};
        return this.network.post('v2/students/me/mobile/confirm',{},params);
    }
    // new login-signup flows

    register({name= "",mobile= "",email= "",refLink= "",password= ""}){
        this.setMedium({mobile,email});
        return this.registerHelper('v2/signon/register', {},{name,mobile,email,refLink,password},true)
    }

    socialSignup(params){ //{token= "",network= "",email= "",refLink= "",redirect_uri= "",code= ""}
        this.setMedium(params);
        return this.registerHelper('v2/signon/social/connect', {},nonEmptyParams(params))
    }

    socialSignin(params){//{token= "",network= "",email= "",refLink= "",redirect_uri= "",code= ""}
        this.setMedium(params);
        return this.loginHelper('v2/signon/social/connect', {},nonEmptyParams(params))
    }

    login(loginIdentifier:string,password:string){
        this.setMedium({identifier:loginIdentifier});
        return this.loginHelper('v2/signon/login',{},{emailOrUserNameOrMobile:loginIdentifier,password})
    }

    requestOTP(emailOrMobile:string , isCall : boolean = false , resend : boolean = false){
        let params : any = {emailOrMobile};
        if(isCall){
            params.otpSentVia = 'call'
        }
        if(resend){
            params.resend = true;
        }
        return this.network.post('v2/otp/send',{},params)
    }

    requestPasswordReset({email = "",mobile = ""}){
        return this.network.post('v2/signon/password/forgot',{},{email,mobile})
    }
    updatePassword({token = "", password = ""}){
        return this.network.post('v2/signon/password/update',{},{auth_code:token,password})
    }

    getUserDetails(loginIdentifier:string){
        return this.network.get('v2/signon/login-details', {emailOrUserNameOrMobile:loginIdentifier})
    }

    getReferrerInfo(refLink:string){
        let subject = new Subject<any>();
        this.network.get(`v2/students/refid/${refLink}`,{}).subscribe((response:any) => {
            if(response.success && response.data && response.data.student){
                let referrer : any = {};
                referrer.name = response?.data?.student?.name.split(" ")[0] || "";
                referrer.image = response?.data?.student?.image || "";
                referrer.code = refLink;
                referrer.paytmAmount = response.data.cashbackAmount || '25';
                setToSessionStorage(KEY_REFERRAL_INFO, referrer || '{}');
                subject.next(response);
            } else {
                subject.error(response.message);
            }
            subject.complete();
        }, ({error}) => {
            if (error && error.message) {
                subject.error(error.message);
                subject.complete();
            }
        });
        return subject;
    }

    mobileVerificationPromise = getDeferredPromise();
    verifyMobile(isSkippable = true,disableCloseBtn=false,isChangeMobile = false) {
        let invalidateStudentData = false;
        this.studentService.loadStudent().then((student: any) => {
            if(!student.mobileVerified || isChangeMobile) {
                invalidateStudentData = true;
                this.showMobileVerificationModal$.next({
                    showModal: true,
                    mobile: student.mobile,
                    isSkippable,
                    disableCloseBtn:disableCloseBtn
                });
            }
            else {
                this.onMobileVerifySuccess({
                    mobileNumber: student.mobileVerified
                }, invalidateStudentData);
            }
        });

        return this.mobileVerificationPromise.promise;
    }

    onMobileVerifySuccess(data, invalidateStudentData) {
        //clear student data from localstorage for ajs
        if(invalidateStudentData) {
            clearStudentData();
        }

        this.mobileVerificationPromise.resolve({
            success: true,
            data
        });

        this.showMobileVerificationModal$.next({
            showModal: false,
            mobile: '',
            isSkippable: false,
            disableCloseBtn:false
        });
    }
    onMobileVerifyFailure(data) {
        this.mobileVerificationPromise.resolve({
            success: false,
            data
        });

        this.showMobileVerificationModal$.next({
            showModal: false,
            mobile: '',
            isSkippable: false,
            disableCloseBtn:false
        });
    }

    triggerTrueCaller(onboardingParams:OnboardingParams,truecallerParams:any = {}){
        if(!window['trueCallerSdk']) {
            return new Promise((resolve,reject)=>{reject();})
        }
        return window['trueCallerSdk'].initFlow(truecallerParams).then((response) => {
            if(response && response.data && response.data.token) {
                this.response = response;
                this.platformService.setCookie('tb_token', response.data.token, response.data.tokenExpiry / (60 * 60 * 24));
                this.triggerPostLoginTasks(onboardingParams,response);
            }
            return response;
        })
    }

    onLoadInitYoloNg(apiPrefix?) {
        if (typeof yoloLib !== 'undefined') {
            yoloLib.init({
                APIPrefix: apiPrefix || 'https://api.testbook.com/api/'
            }, (response) => this.onTokenRecieveScb(response));
            yoloLib.initYolo();
        }
    }

    onTokenRecieveScb(response) {
        if (response.data && response.success) {
            // if signup set the cookie for adwords/conversions.js
            if (response && response.data && response.data.isSignUp) {
                setClientCookie(KEY_COOKIE_JUST_SIGNED_UP, 'yes', 1);
            }

            var eventName = (response.data.isSignUp) ? 'signUp' : 'signIn';
            var pageURL = (window.top.location.href) ? window.top.location.href : '';
            const event = {
                event: eventName,
                sid: response.data.sid,
                info: {
                    channel: 'Google',
                    page: getPageText(),
                    pageType: getPageType(),
                    pagePath: pageURL,
                    module: 'Yolo',
                    medium: 'Google',
                    client: 'Website'
                }
            };

            this.coreService.fireEventWithSid(event,true).finally(() => {
                setTimeout(function () {
                    let windowUrl = this.platformService.getPathName(true).startsWith('/login') ? window.top.location.href : window.location.href
                    if(windowUrl){
                        window.top.location.href = decodeURIComponent(getClientQueryParamValue('redirect_url',windowUrl) || '/super-coaching' || '/home');
                    } else {
                        window.top.location.reload();
                    }
                }, 100);
            });

        }
    };

    getCRMLead(params){
       let url = '/v1/student/search-lead'
       return this.network.get(url,params);
    }
    addStudentEnquiry(params,body){
        let url = 'v1/student/'+params.sid+'/enquiry-form'
        return this.network.post(url,body,{});
    }
    getStudentFormDetails(params){
        let url = 'v1/student/'+params.sid+'/enquiry-form'
        return this.network.get(url,{});
    }
    createLeadOnCRM(params){
        let body = {
            name: params.name,
            mobile: params.mobile,
        }
        let leadParams={};
        if(params.empId && params.empId.length){
            leadParams['empId' ]=  params.empId;
        }
        if(params.referral && params.referral.length){
            leadParams['referral']= params.referral;
        }

        let url = 'v2/student/create-lead';
        return this.network.post(url,body,leadParams);
    }

    referralCrmValidation(params) {
        let url = 'v1/employee/validate-referral-code/' + params['referralCode'];
        return this.network.get(url, {}, 'crm')
    }

    @clientOnly()
    handleValidSession(response){
        if(response.success && response.data && response.data.activeSessionToken){
            this.platformService.setCookie('activeSessionToken', response.data.activeSessionToken, 100);
        } else if (!response.success && response.data && response.data.shouldLogout){
            this.store.dispatch(showAlert({message:"This account is active on a different device. Logging you out in 5 Seconds",timer:5000,autoClose:true}))
            setTimeout(()=>{
                //logout
                clearStudentCookie();
                let pathName = encodeURI(this.platformService.getPathName());
                window.location.href = `${window.location.origin}/login?redirect_url=${pathName}`;
            });
        }
    }

    @clientOnly()
    handleSessionError(response){

        if(!response.success){
            this.store.dispatch(sendClientErrorLogToBackend({payload : response}));
            if (!this.platformService.isIosDevice()){
                this.store.dispatch(showAlert({message:"This account is active on a different device. Logging you out in 5 Seconds",timer:5000,autoClose:true}))
                setTimeout(()=>{
                    //logout
                    clearStudentCookie();
                    let pathName = encodeURI(this.platformService.getPathName());
                    window.location.href = `${window.location.origin}/login?redirect_url=${pathName}`;
                });
            }
        }
    }

    @clientOnly()
    checkActiveSessions(){
        let body : any = {};
        let application;
        if(this.platformService.isMobile() && this.platformService.isIosDevice()){
            application = 'ios';
        } else if(this.platformService.isMobile()){
            application = 'm-web';
        } else {
            application = 'web';
        }
        let params : any = {
            application: application
        }
        let activeSessionToken = this.platformService.getCookie('activeSessionToken');
        if (activeSessionToken) {
            params['activeSessionToken'] = activeSessionToken;
        }

        this.network.post('v2/students/session',body,params)
        .subscribe({
            next :response => this.handleValidSession(response),
            error:response => this.handleSessionError(response)
        });
    }

    goals;
    student;
    goalsTransferState$
    goals$: any = this.store.select(selectGoals);


    setCurrentGoal(){ // sets the cookie to currently selected goal from BE
        if(this.goals && this.student && this.student.currentGoal){
            let selectedGoal = this.goals.find( goal => goal.id == this.student.currentGoal)
            if(selectedGoal && selectedGoal.properties.slug){
                if (!this.studentGoalDirty){
                    this.setGoal({id:selectedGoal.id,slug:selectedGoal.properties.slug},GOAL_EXPIRY,false,false);
                }
            }
            this.handlePostLogin();
        } else if(this.goals && this.student && this.student.currentGoal === ''){
            this.handlePostLogin();
        }
    }

    getAllGoals(){
        this.goalsTransferState$ = this.transferStateManager.get('allGoals', getGoals(), this.goals$,(value)=>{
            if(value){
                this.goals = value.goals;
                this.setCurrentGoal();
            }
        });
    }

    getSelectedGoal(){
        this.getAllGoals();
        studentMeApi.apiCall(this.network, {}).toPromise()
            .then((studentData: any) => {
                setStudentData(studentData.data);
                this.student = studentData.data;
                this.setCurrentGoal();
            }).catch((error) => {
            console.log("error getting student",error);
            if(error?.error && !error?.error?.success){
                this.platformService.redirect('logout',302,true)
            }
        });
    }

    handlePostLogin() {
        let redirectUrl = '/onboarding' + window.location.search;
        this.isSignUp = !!(this.response && this.response.data && this.response.data.isSignUp);
        if (this.onboardingParams.skipOnboarding && this.isSignUp) {
            redirectUrl = '/home' + window.location.search;
        }
        this.enrollmentFlow = {...this.onboardingParams, redirect_url: encodeURI(redirectUrl)};
        this.postLoginResponse=this.response;
        this.onBoardingParams=this.onboardingParams
        for (let key of Object.keys(this.postLoginWorkMap)){
            this.postLoginWorkMap[key].apiCall(this);
        }
        this.callBackForStatusCheck();
        let gclid = this.platformService.getCookie('gclid')
        if(gclid){
            this.store.dispatch(setStudentGCLID({gclid: gclid}));
            this.platformService.setCookie('gclid', gclid, -1);

        }
    }



}
