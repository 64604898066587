<div class="global-search">
    <ng-container *ngIf="isServer">
        <form action="/search" method="get" style="width: 100%;">
            <input type="text" name="q" class="searchbox input" [ngClass]="{'searchbox--loggedout':!isLoggedIn}"  placeholder="Search">
            <button type="submit" style="border: none;margin-left: 35px;outline: none;background: transparent;position: absolute;right: 0;">
                <svg class="searchicon" height="20px" width="20px">
                    <use xlink:href="#search"></use>
                </svg>
            </button>
        </form>
    </ng-container>

    <ng-container *ngIf="!isServer">
        <input type="text" class="searchbox input" [ngClass]="{'searchbox--loggedout':!isLoggedIn}" (keyup)="filterSuggestions()" (keyup.enter)="performSearch()" [(ngModel)]="partialQuery" placeholder="Search">
        <svg (click)="performSearch()" class="searchicon" height="20px" width="20px">
            <use xlink:href="#search"></use>
        </svg>
        <div class="global-search__suggestion" *ngIf="showPastQueries && !hideSuggestions">
            <ul class="ul-content ul-content--search">
                <li class="cursor-pointer" *ngFor="let query of filteredQueries" (click)="performSearch(query)">{{query}}</li>
            </ul>
        </div>
    </ng-container>
</div>

