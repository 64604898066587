import {createAction, props} from "@ngrx/store";
import {_ScholarshipDetail} from '@models/entity/scholarship-detail/scholarship-detail.adapter';
import {ScholarshipCards} from '@angular-commons/models/entity/scholarship-cards/scholarship-cards.adapter';
import {ScholarshipFeedback,Feedback} from '@angular-commons/models/entity/scholarship-feedback/scholarship-feedback.adapter';

export const getScholarshipDetail= createAction('[app Scholarship] getScholarshipDetail',props<{slug:string}>());
export const getScholarshipDetailLoaded = createAction('[app Scholarship] getScholarshipDetail Loaded', props<{payload: _ScholarshipDetail}>());
export const getScholarshipDetailError= createAction('[app Scholarship] getScholarshipDetail ERROR',props<{payload:any}>());
export const setScholarshipRegisterStatus= createAction('[app Scholarship] setScholarshipDetail Status',props<{payload:boolean}>());

export const getScholarshipCards= createAction('[app Scholarship] getScholarshipCards',props<{params:any}>());
export const getScholarshipCardsLoaded = createAction('[app Scholarship] getScholarshipCards Loaded', props<{payload: ScholarshipCards}>());

export const getScholarshipFeedback= createAction('[app Scholarship] getScholarshipFeedback');
export const getScholarshipFeedbackLoaded = createAction('[app Scholarship] getScholarshipFeedback Loaded', props<{payload: ScholarshipFeedback}>());

export const submitScholarshipFeedback = createAction('[app Scholarship] submitScholarshipFeedback ', props<{postData:{request: Array<Feedback>,id:string}}>());
export const submittedScholarshipFeedback = createAction('[app Scholarship] submittedScholarshipFeedback Loaded');
export const showFeedbackModel=createAction('[app Scholarship] showFeedbackModel',props<{showModel:boolean}>());
