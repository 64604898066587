
const GOAL_EDUCATOR_PAGE_MWEB={
    sequence:[
        {
            pageType: "goalEducatorPage",
            sequences : [ 
                {
                    id : "page_header",
                    title : "Page Header",
                    component : "Page Header",
                    weight : 1.0,
                    isHidden : false
                }, 
                {
                    id : "top_educators",
                    title : "Top Educators",
                    component : "Top Educators",
                    weight : 2.0,
                    isHidden : false
                }, 
                {
                    id : "pass_banner",
                    title : "Pass Banner",
                    component : "Pass Banner",
                    weight : 3.0,
                    isHidden : true
                }, 
                {
                    id : "subject_wise_educators",
                    title : "Subject Wise Teachers",
                    component : "Subject Wise Teachers",
                    weight : 4.0,
                    isHidden : false
                }
            ]

        }
    ]

}

const GOAL_EDUCATOR_PAGE_WEB={
    sequence:[
        {
            pageType: "goalEducatorPage",
            sequences : [ 
                {
                    id : "page_header",
                    title : "Page Header",
                    component : "Page Header",
                    weight : 1.0,
                    isHidden : false
                }, 
                {
                    id : "top_educators",
                    title : "Top Educators",
                    component : "Top Educators",
                    weight : 2.0,
                    isHidden : false
                }, 
                {
                    id : "pass_banner",
                    title : "Pass Banner",
                    component : "Pass Banner",
                    weight : 3.0,
                    isHidden : true
                }, 
                {
                    id : "subject_wise_educators",
                    title : "Subject Wise Teachers",
                    component : "Subject Wise Teachers",
                    weight : 4.0,
                    isHidden : false
                }
            ]

        }
    ]

}

const GOAL_LANDING_PAGE={
    sequence:[
        {
            pageType:"goalLandingPage",
            sequences:[
                {
                    id : "page_header",
                    title : "Page Header",
                    component : "Page Header",
                    weight : 1.0,
                    isHidden : false
                }, 
                {
                    id : "top_educators",
                    title : "Top Educators",
                    component : "Top Educators",
                    weight : 2.0,
                    isHidden : false
                }, 
                {
                    id : "pass_banner_cta_only",
                    weight : 3.0,
                    isHidden : false
                }, 
                {
                    id : "goal_features",
                    title : "Goal Features",
                    component : "Goal Features",
                    weight : 4.0,
                    isHidden : false
                }, 
                {
                    id : "pass_banner",
                    title : "Pass Banner",
                    component : "Pass Bannerl",
                    weight : 5.0,
                    isHidden : true
                }, 
                {
                    id : "courses",
                    title : "Courses",
                    component : "Courses",
                    weight : 6.0,
                    isHidden : false
                }, 
                {
                    id : "about_goal",
                    title : "About Goal",
                    component : "About Goal",
                    weight : 7.0,
                    isHidden : false
                }, 
                {
                    id : "live_series",
                    title : "Live Series",
                    component : "Live Series",
                    weight : 8.0,
                    isHidden : false
                }, 
                {
                    id : "test_series",
                    title : "Test Series",
                    component : "Test Series",
                    weight : 9.0,
                    isHidden : false
                },
                {
                    id : "scholarship_tests",
                    title : "Scholarship Tests",
                    component : "Scholarship Tests",
                    weight : 10.0,
                    isHidden : false
                },  
                {
                    id : "goal_pass",
                    title : "Goal Pass",
                    component : "Pitch Card",
                    weight : 11.0,
                    isHidden : false
                }, 
                {
                    id : "request_callback",
                    title : "Request Callback",
                    component : "Request Callback",
                    weight : 12.0,
                    isHidden : false
                }, 
                {
                    id : "faq",
                    title : "Faq",
                    component : "Faq",
                    weight : 13.0,
                    isHidden : false
                }  
            ]
        }
        

    ]
}

const INDIVIDUAL_EDUCATOR_PAGE_WEB={
    sequence:[
        {
            pageType: "individualEducatorPage",
            sequences : [ 
                {
                    id : "educator_info",
                    title : "Educator Info",
                    component : "Educator Info",
                    weight : 1,
                    isHidden : false
                }, 
                {
                    id : "pass_banner",
                    title : "Pass Banner",
                    component : "Pass Banner",
                    weight : 2,
                    isHidden : true
                }, 
                {
                    id : "free_lessons",
                    title : "Free Lessons",
                    component : "Free Lessons",
                    weight : 3,
                    isHidden : false
                }, 
                {
                    id : "courses",
                    title : "Courses",
                    component : "Courses",
                    weight : 4,
                    isHidden : false
                }, 
                {
                    id : "testimonials",
                    title : "Testimonials",
                    component : "Testimonials",
                    weight : 5,
                    isHidden : false
                }, 
                {
                    id : "all_educators",
                    title : "All Teachers",
                    component : "All Teachers",
                    weight : 6,
                    isHidden : false
                }
            ]

        }
    ]

}

const GLOBAL_GOAL_PAGE ={
    comingSoonText: "Can’t find your goal? SUPER will be available for more goals soon, stay tuned! ",
    goalSelectionHeading: "Select Your Goal",
    pitchSubtitle: "Learn from over 50+ nationally recognised educators",
    pitchTitle: "Unique Feature of Super Coaching",
    pitches: [
        {
            heading: "",
            icon: "",
            subHeading: ""
        }
    ],
    preText: "INTRODUCING",
    title: "Complete Coaching with India's top faculty",
    type: "superCoachingPage",
}


export function getDefaultSequenceData(pageType,isMobile){
    switch(pageType){
        case "goalEducatorPage":
            return (isMobile) ? GOAL_EDUCATOR_PAGE_MWEB : GOAL_EDUCATOR_PAGE_WEB;
        case "goalLandingPage":
            return GOAL_LANDING_PAGE;
        case "individualEducatorPage":
            return INDIVIDUAL_EDUCATOR_PAGE_WEB
    }
}

export function getDefaultData(pageType){
    switch(pageType){
        case "globalGoalPage":
            return GLOBAL_GOAL_PAGE;
    }
}