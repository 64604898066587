import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {DataRecipe} from "@core/services/model-manager/recipe";
import {ModelManager} from "@core/services/model-manager.service";

import {EMPTY, of} from "rxjs";
import {switchMap, map,catchError} from 'rxjs/operators';
import {
    CouponsArray,
    StudentCouponsApi,
    _CouponProductsArray,
    CouponProductsApi,
    _CouponCTAData,
    CouponCTAData,
    CouponCTADataApi
} from "@models/entity/coupon/coupon.adapter";
import {
   getStudentCoupons, studentCouponsLoaded, getCouponProducts, couponProductsLoaded, couponProductsError, getCouponCTAData, couponCTADataLoaded
} from "./coupon.actions"
import {IdentityAdapter} from "@models/mapping-framework";


@Injectable()
export class CouponEffects{

    getStudentCouponsRecipe : DataRecipe

    loadStudentCoupons$ = createEffect(()=> this.action$.pipe(
        ofType(getStudentCoupons),
        switchMap((action)=>{
            return this.getStudentCouponsRecipe.get(action).pipe(
                map((data: any) => studentCouponsLoaded({payload: data})),
                catchError(() => EMPTY)
            );
        })
    ));

    getCouponProductsRecipe : DataRecipe

    loadCouponProducts$ = createEffect(()=> this.action$.pipe(
        ofType(getCouponProducts),
        switchMap((action)=>{
            return this.getCouponProductsRecipe.get(action).pipe(
                map((data:_CouponProductsArray) => {
                    return couponProductsLoaded({payload: data})
                }),
                catchError((error) => of(couponProductsError({payload: error.error}))),
            );
        })
    ));

    getCouponCTADataRecipe : DataRecipe

    loadCouponCTAData$ = createEffect(()=> this.action$.pipe(
        ofType(getCouponCTAData),
        switchMap((action)=>{
            return this.getCouponCTADataRecipe.get(action).pipe(
                map((data:_CouponCTAData) => {
                    return couponCTADataLoaded({payload: data})
                }),
                catchError(() => EMPTY),
            );
        })
    ));

    constructor(private modelManager: ModelManager,private action$ : Actions){

        this.getStudentCouponsRecipe = modelManager.makeDataRecipe(IdentityAdapter, CouponsArray, StudentCouponsApi);
        this.getCouponProductsRecipe = modelManager.makeDataRecipe(IdentityAdapter, _CouponProductsArray, CouponProductsApi);
        this.getCouponCTADataRecipe = modelManager.makeDataRecipe(_CouponCTAData, CouponCTAData, CouponCTADataApi);
    }
}

