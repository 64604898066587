<!-- overe enrollment -->
<div class="enrollment">
    <div>
        <!-- enrollment over state -->
        <p *ngIf = "!bannerMeta.submitted">
            <img src="./assets/img/select/red-flag.svg" alt="icon" style="margin-right: 8px;">
            <span translate>{{'ENROLLMENT_OVER_FOR_BATCH'}}</span>
        </p>

        <!-- enrollment action state -->
        <p *ngIf = "bannerMeta.submitted">
            <img src="./assets/img/select/clock-grey.svg" alt="clocl-grey-icon">
            <span translate>{{'INFORM_YOU_FOR_NEW_BATCH'}}</span>
        </p>

        <!-- enrollment over state -->
        <div class="enrollment__cta">
            <button class="btn" (click)="setShowBannerInput(true)" cb-await="" cb-await-id="requestnewbatchcta" translate>{{'REQUEST_NEW_BATCH'}}</button>
            <button class="btn btn--outline-primary" (click)="goToSelectPage()" cb-await="" cb-await-id="exploreothercoursescta" translate>{{'EXPLORE_OTHER_COURSES'}}</button>
        </div>

        <!-- enrollment action state -->
        <div class="enrollment__group" *ngIf = "showBannerInput">
            <div class="enrollment__input">
                <input type="text" placeholder="{{'PHONE_NO' | translate}}" [(ngModel)]="mobile" cb-persist="mobilenoinput">
            </div>
            <button class="btn" (click)="requestCallback()" cb-await="" cb-await-id="requestcallbackcta" translate>{{'SUBMIT_REQUEST'}}</button>
            <div class="enrollment__close" (click)="setShowBannerInput(false)">
                <img src="./assets/img/ui-kit/close.svg" alt="close-icon" height="15" width="15">
            </div>
        </div>
    </div>
</div>