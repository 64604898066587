
export const PYP_LOGIN_HEADER = "Get access to all Previous Year Papers";

export const SELECTIONS= "success-stories";

export const SELECTIONS_JOURNEY_URL="/"+SELECTIONS+"/share-your-journey/stage/0";
export const SCHOLARSHIP_INFO="scholarshipInfo_";
export const TRANSACTION_LS_KEY="transactionData_";

export const LESSON_LOGIN_HEADER = "Get started with Testbook!";

export const PAGE_TYPE_TARGET = 'targetpage';
export const PAGE_TYPE_CUSTOM = 'custompage';

export const DESCRIPTION = 'description';
export const DYNAMIC_TABLE = 'dynamicTable';
export const TEST_SERIES = 'testSeries';
export const LIVE_COACHING = 'liveCoaching';
export const QUIZZES = 'quizzes';
export const ENROLLMENT= "enrollment";
export const HOME="home";

export const ONGOING_SERIES_GETCOUNT=27;

export const GOAL_EXPIRY = 30*60*60*24;

export const CURRENT_PRACTICE_GROUP_DATA_KEY = 'CurrentPracticeGroupDataKey';
export const SUPER_WHATSAPP_SHARE_OBJECT= {
    show: true,
    data:{
        sharetext: 'Hi Testbook! ',
        contact: 918591108589
    }
};

export const supercoachingStudyPlanMap = {
    '5e6189da5f66e94f14a21f58': {
        downloadLink: 'https://link.testbook.com/study-plan-ssc-cgl',
        webImg: '/assets/img/other/study-plan-web.webp?v=2',
        mWebImg: '/assets/img/other/study-plan-mweb.webp?v=2'
    }
};

export const WEBVIEW_TO_ANDROID_BRIDGE = 'PaymentJsBridge';

export const WEBVIEW_PAYMENT_ERROR_CODES = {
    USER_CANCELED: 'USER_CANCELED',
    USER_UNAUTHORIZED: 'USER_UNAUTHORIZED',
    EXCEEDED_CREDIT_LIMIT: 'EXCEEDED_CREDIT_LIMIT',
    INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
    OTHERS: 'Something went wrong',
    PAYMENT_LINK_ERROR: 'Payment link not received from BE',
    BNPL_ELIGIBILITY_ERROR: 'BNPL eligibility failed',
    INVALID_PG: 'Invalid paymentgateway'
};

export const PG_SIMPL = 10;

export const PG_BNPL = 11;

export const REDIRECT_PAYMENT_GATEWAYS = [PG_SIMPL, PG_BNPL];

export const PAY_IN_3 = 'payIn3';

export const BNPL = 'bnpl';

export const STUDY_PLAN_LEAD_FIRED = 'studyPlanLeadFired';

export const WEEKDAYS_CODES = {
    'mon': 'MO',
    'tue': 'TU',
    'wed': 'WE',
    'thu': 'TH',
    'fri': 'FR',
    'sat': 'SA',
    'sun': 'SU'
};

export const SIMPL_TRANSACTION_LS_KEY = 'SIMPL_TRANSACTION_LS_KEY';

export function getSimplFailedUrl(errorCode) {
    return `/initiate-payment/continue?status=FAILED&error_code=${errorCode}`;
}

export function getSimplSuccessUrl(data) {
    return `/initiate-payment/continue?status=SUCCESS&transaction_id=${data}`;
}

export const goalRetargettingPageVisited = 'goal_retargetting_page_visited';
export const reverseDynamicEventMap = {
    [goalRetargettingPageVisited]:0,
};

export const FIRE_DYNAMIC_EVENT = 'fire_dynamic_event'

export const CUSTOM_CHILD_MAX_TOTAL_CHILD_PAGES = 20
export const CUSTOM_CHILD_MAX_PREV_CROSS_LINKS = 5
export const CUSTOM_CHILD_MAX_NEXT_CROSS_LINKS = 5

export const practisePage = 'SkillAcademyInternalPage - Practice'
export const videoPage = 'SkillAcademyInternalPage - Video'
export const notesPage = 'SkillAcademyInternalPage - Notes'

export const TargetsActive = 'ActiveTargets'

export const passTypeProducts = ['globalPass', 'passPro'];

export interface PricingModalObj  {
    passType?: string;
    module?: string;
    eventData?: any;
    leadData?: any;
    goalSlug?: string;
    referrer?:string;
    showComparisonModal?: boolean;
}

export interface Student{
    img:string;
    name:string;
    degrees:any;
    dob:any;
    category:string;
    location:any;
    defaultLang:string;
}

export const ATTEMPTED_TEST_INITIAL_LIMIT = 10;
export const ATTEMPTED_TEST_FILTER_LIMIT = 5;

export const referrerType = 'referrerType';

export const skillPassType = 'skillAcademy';

export const currentAffairsSlug = 'current-affairs';

export const currentAffairsRegex =/^current-affairs(-in-(hindi|marathi|bengali))?$/

export const productTypeFromUrlMap = {
    passpro: 'passPro',
    globalpass: 'globalPass'
};

export const studentConstantFields = ['casteCategories','academicDegrees'];

export const tabsIncludedInHeaderLinks = [
    'courses', 'super_teachers', 'demo_classes', 'live_series', 'test_series', 'study_notes', 'practice_entity', 'pricing'
];

export const componentScrollThresholdMap = {
    'courses': 0.25,
    'pricing': 0.5
};

export const EMI_DOWN_PAYMENT = 'downPayment';
export const EMI_MANDATE = 'mandate';
export const EMI_BLOCKER = 'blocker';

export const waitListGoalsIds = ['64dcac027f0774520aad3570'];

export interface EMI_WRAPPER_QUERY_PARAMS {
    openModal: boolean, 
    modalType: string, 
    goalId:string,
    planId?:string, 
    coupon?:string, 
    productId?:string, 
    redirectUrl?:string, 
    module?:string,
    passType?:string,
    goalTitle?:string,
    goalSlug?:string,
    disableCloseBtn?:boolean,
    productName?: string,
    paidAmount?: number,
    totalAmountToPay?: number
}

export const ORDER_STATUS = ['Order Placed', 'shipped', 'out_for_delivery', 'delivered'];

export const enabledVernacularCodes = {'hi':'hindi'}; 
export const PROJECT_PLUTUS = "plutus"; 
export const PROJECT_TESTBOOK = "testbook";
