<!--<div class="" id="course-info" *ngIf="pageSeo.courseInfo">-->
<!--    <div class="wrapper lms-content">-->
<!--        <span [innerHTML]="pageSeo.courseInfo" class="js-seo-content"></span>-->
<!--    </div>-->
<!--</div>-->
<div class="subfooter" *ngIf="isSubFooterPresent">
    <div class="wrapper" *ngFor="let section of subFooterData" >
        <div class="h4">{{section?.title}}</div>
        <div class="category-list">
            <div class="category-item">
                <a *ngFor="let link of getNthElements(section.links, 4, 0)"  [href]="link.link">{{link.anchorTag}}</a>
            </div>
            <div class="category-item">
                <a *ngFor="let link of getNthElements(section.links, 4, 1)" [href]="link.link">{{link.anchorTag}}</a>
            </div>
            <div class="category-item">
                <a *ngFor="let link of getNthElements(section.links, 4, 2)" [href]="link.link">{{link.anchorTag}}</a>
            </div>
            <div class="category-item">
                <a *ngFor="let link of getNthElements(section.links, 4, 3)" [href]="link.link">{{link.anchorTag}}</a>
            </div>
        </div>
    </div>
</div>
