import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@angular-commons/core/core.module';
import { LoaderModule } from '../loader/loader-export.module';
import { DownPaymentPopupComponent } from './down-payment-popup.component';
import { StoreModule } from '@ngrx/store';
import { emisDetailReducer } from './feature-state/down-payment.reducer';
import { EffectsModule } from '@ngrx/effects';
import { EmiDetailsEffects } from './feature-state/down-payment.effects';

@NgModule({
  declarations: [DownPaymentPopupComponent],
  imports: [
    CommonModule,
    CoreModule,
    LoaderModule,
    StoreModule.forFeature('emiDetails', emisDetailReducer),
    EffectsModule.forFeature([EmiDetailsEffects])
  ],
  exports:[DownPaymentPopupComponent]
})
export class DownPaymentPopupModule { }