import { Validator as _ } from "../../../shared/utility/validator";
import { baseModel ,setupModel } from "../../types";
import {propertyMaps, arrayModel, SourceProjectionMapper, objModel, pipeModel} from "../../mapping-framework";
import { Network } from '../../../core/services/model-manager/main';
import { SECS_IN_ONE_HR } from '@angular-commons/shared/utility/tb-common';

export class student extends baseModel{

    @propertyMaps('_id',_.str)
    public id;

    @propertyMaps()
    public createdOn;

    @propertyMaps('lastSessionTime',_.date)
    public lastSessionTime;

    @propertyMaps()
    public classes;

    @propertyMaps()
    public globalPassExpiry;

    @propertyMaps()
    public passProExpiry;

    @propertyMaps()
    public goalSubs;

    @propertyMaps()
    public currentGoal;

    @propertyMaps()
    public currentStudyGroup;

    @propertyMaps()
    public currLearningPass;

    @propertyMaps('image',_.profilePic)
    public image;

    @propertyMaps("mobile",_.str)
    public mobile;

    @propertyMaps('name',_.str)
    public name;

    @propertyMaps('dob',_.date)
    public dob;

    @propertyMaps()
    public isPaidUser;

    @propertyMaps()
    public isSelectUser;

    @propertyMaps('email',_.str)
    public email;

    @propertyMaps()
    public mobileVerified;

    @propertyMaps()
    public cobrandingInfo;

    @propertyMaps()
    public curPass;

    @propertyMaps()
    public referrer;

    @propertyMaps()
    public referral;

    @propertyMaps('activeGoalIds', _.arr)
    public activeGoalIds;

    @propertyMaps()
    public activeTargetIds;

    @propertyMaps()
    public activeSuperGroupIds;

    @propertyMaps('targetInfo', _.arr)
    public targetInfo;
    
    @propertyMaps()
    public goalEnrolled;

    @propertyMaps("meta",_.obj)
    public meta;

    @propertyMaps("username",_.str)
    public userName;

    @propertyMaps('location',_.obj)
    public location;

    @propertyMaps('tblang',_.str)
    public tbLang;

    @propertyMaps('vpa', _.str)
    public upiId;

}
setupModel(student,{});

export class _activity extends baseModel{


    @propertyMaps('sectionId',_.str)
    public sectionId;
    @propertyMaps('sectionName',_.str)
    public sectionName;
    @propertyMaps('moduleId',_.str)
    public moduleId;
    @propertyMaps('moduleName',_.str)
    public moduleName;
    @propertyMaps('moduleType',_.str)
    public moduleType;

    @propertyMaps()
    public lastActivityDate;

    @propertyMaps()
    public TotalQuestions;

    @propertyMaps()
    public duration;

    @propertyMaps()
    public progress;

    @propertyMaps()
    public progressPerc;

    @propertyMaps()
    public isLive;
}
setupModel(_activity,{});

export class currentActivity extends baseModel{

    @propertyMaps('currentActivity',pipeModel(objModel(_activity),transformCurrentActivity),_activity)
    public currentActivity;

}
setupModel(currentActivity,{});

function transformCurrentActivity(activity){

    let durationInSec = activity.duration/1000000000;
    activity.progressPerc = Math.ceil((activity.progress * 100)/durationInSec);

    return activity;
}

export class _currentActivity extends baseModel{
    @propertyMaps('currentActivity',objModel(_activity),_activity)
    public currentActivity;

}
setupModel(_currentActivity,{});


export class _classLastActivity extends baseModel {
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('name', _.str)
    public name;

    @propertyMaps('type', _.str)
    public type;

    @propertyMaps('instructors', _.arr)
    public instructors;
}
setupModel(_classLastActivity,{});

export class LastActivity extends baseModel {
    @propertyMaps('available', _.bool)
    public isAvailable;

    @propertyMaps('lastActivity', objModel(_classLastActivity), _classLastActivity)
    public lastActivity;
}
setupModel(LastActivity,{});


export class studentMeApi{

    static apiEndpoint = 'v2/students/me';

    static projection;
    static get  __projection(){ 
        if(!studentMeApi.projection){
            studentMeApi.projection = JSON.stringify(new SourceProjectionMapper(student).map());
        } 
        return studentMeApi.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(studentMeApi.apiEndpoint,{__projection:studentMeApi.__projection,  ...params});
    }

}

export class studentProfileAPI{
    static apiEndpoint = 'v2/students/me/partial';

    static  __projection = 1;

    static apiCall(network:Network,params:any) {
        return network.post(studentProfileAPI.apiEndpoint, {gclid : params.gclid}, null);
    }
}

export class studentCurrentActivity{

    static apiEndpoint = 'v1/students/me/current-activity';

    static projection;
    static get  __projection(){ 
        if(!studentCurrentActivity.projection){
            studentCurrentActivity.projection = JSON.stringify(new SourceProjectionMapper(_currentActivity).map());
        }
        return studentCurrentActivity.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(studentCurrentActivity.apiEndpoint,{__projection:studentCurrentActivity.__projection, ... params});
    }

}

export class studentLastActivityApi{
    static apiEndpoint = 'v1/students/me/last-activity?';

    static projection;
    static get  __projection() {
        if(!studentLastActivityApi.projection){
            studentLastActivityApi.projection = JSON.stringify(new SourceProjectionMapper(LastActivity).map());
        }
        return studentLastActivityApi.projection;
    }

    static apiCall(network:Network, params:any) {
        return network.get(studentLastActivityApi.apiEndpoint,{__projection:studentLastActivityApi.__projection, ...params});
    }
}

export class _attendance extends baseModel{
    @propertyMaps('classesPlanned',_.num)
    public classesPlanned;
    @propertyMaps('presentDays',_.num)
    public presentDays;
    @propertyMaps('totalDays',_.num)
    public totalDays;
    @propertyMaps('modulesCompleted',_.num)
    public modulesCompleted;
    @propertyMaps('modulesReleased',_.num)
    public modulesReleased;
    @propertyMaps('totalModules',_.num)
    public totalModules; 
    @propertyMaps('classesPlanned',_.num)
    public studentProgressPerc; 
    @propertyMaps('totalModules',_.num)
    public classProgressPerc; 
}
setupModel(_attendance, {type: 'attendance'});

export class _leaderboard extends baseModel{
    @propertyMaps('classId')
    public classId;
    @propertyMaps('leaderboard')
    public leaderboard;
    @propertyMaps('currentLeaders')
    public currentLeaders;
    @propertyMaps('maxAccuracy')
    public maxAccuracy;
    @propertyMaps('maxAccuracy', maxAccuracy)
    public maxAccuracyVal;
    @propertyMaps('maxQsAtt')
    public maxQsAtt;
    @propertyMaps('maxQsAtt', returnFromVal)
    public maxQsAttVal;
    @propertyMaps('maxTScore')
    public maxTScore;
    @propertyMaps('maxTScore', returnFromVal)
    public maxTScoreVal;
    @propertyMaps('maxTimeSpent')
    public maxTimeSpent;
    @propertyMaps('maxTimeSpent', returnFromVal)
    public maxTimeSpentVal;
    @propertyMaps('boardInfo')
    public boardInfo;
    @propertyMaps('currBoardInfo')
    public currBoardInfo;
    @propertyMaps('stuMap')
    public stuMap;
    @propertyMaps('maxTimeSpent', returnFromVal)
    public bestStudyTime;
    @propertyMaps('bestStudyTime', getBestStudyHrs)
    public bestStudyHrs;
}
setupModel(_leaderboard, {type: '_leaderboard'});

export class leaderboard extends baseModel{
    @propertyMaps('classId', _.str)
    public classId;
    @propertyMaps('leaderboard')
    public leaderboard;
    @propertyMaps('currentLeaders', _.arr)
    public currentLeaders;
    @propertyMaps('maxAccuracy')
    public maxAccuracy;
    @propertyMaps('maxAccuracy', maxAccuracy)
    public maxAccuracyVal;
    @propertyMaps('maxQsAtt', _.obj)
    public maxQsAtt;
    @propertyMaps('maxQsAtt', returnFromVal)
    public maxQsAttVal;
    @propertyMaps('maxTScore', _.obj)
    public maxTScore;
    @propertyMaps('maxTScore', returnFromVal)
    public maxTScoreVal;
    @propertyMaps('maxTimeSpent')
    public maxTimeSpent;
    @propertyMaps('boardInfo')
    public boardInfo;
    @propertyMaps('currBoardInfo')
    public currBoardInfo;
    @propertyMaps('stuMap')
    public stuMap;
    @propertyMaps('currentBoard')
    public currentBoard;
    @propertyMaps('maxTimeSpent', returnFromVal)
    public bestStudyTime;
    @propertyMaps('bestStudyTime', getBestStudyHrs)
    public bestStudyHrs;
}
setupModel(leaderboard, {type: 'leaderboard'});

function returnFromVal(obj :any = {}){
    return obj.value || 0
}

function maxAccuracy(obj){
    return returnFromVal(obj)* 100
}

function getBestStudyHrs(bestStudyTime){
    return bestStudyTime/SECS_IN_ONE_HR;
}

export class _watchlog extends baseModel{
    @propertyMaps('duration')
    public duration;
    @propertyMaps('date')
    public date;
}
setupModel(_watchlog, {type: 'watchlog'});

export class _comparision extends baseModel{
    @propertyMaps('timeSpent')
    public timeSpent;
    @propertyMaps('quesCount', _.num)
    public quesCount;
    @propertyMaps('correctQs', _.num)
    public correctQs;
    @propertyMaps('scoresCollected', _.num)
    public scoresCollected;
    @propertyMaps('watchLogs', arrayModel(_watchlog), _watchlog)
    public watchLogs;
}
setupModel(_comparision, {type: 'comparision'});

export class _classDetails extends baseModel{
    @propertyMaps('classId')
    public classId;
    @propertyMaps('createdOn')
    public createdOn;
    @propertyMaps('enrolledOn')
    public enrolledOn;
    @propertyMaps('sid')
    public sid;
    @propertyMaps('_id')
    public _id;
}
setupModel(_classDetails, {type: 'classDetails'});

export class _enrollmentDetails extends baseModel{
    @propertyMaps('class', objModel(_classDetails), _classDetails)
    public class;
    @propertyMaps('moduleCount')
    public moduleCount;
    @propertyMaps('canPurchaseThrough')
    public canPurchaseThrough;
}
setupModel(_enrollmentDetails, {type: 'enrollmentDetails'});

export class _stats extends baseModel{
    @propertyMaps('comparison', transformComparisionObj)
    public comparison;
    @propertyMaps('overallStats', transformOverallStatsObj)
    public overallStats;
}
setupModel(_stats, {type: 'stats'});

function transformComparisionObj(_comparisionObj: any = {}){
    let comparisionObj = {..._comparisionObj}
    comparisionObj.quesCount = comparisionObj.quesCount || 0;
    comparisionObj.correctQs = comparisionObj.correctQs || 0;
    comparisionObj.studyHrs = comparisionObj.timeSpent || 0;
    comparisionObj.testScore = comparisionObj.scoresCollected || 0;
    comparisionObj.totlQuesAttempted = comparisionObj.quesCount || 0;
    comparisionObj.avgAccuracy = comparisionObj.correctQs * 100 / (comparisionObj.quesCount || 1);

    return comparisionObj;
} 

function transformOverallStatsObj(_overallStatsObj: any = {}){
    let overallStatsObj = {..._overallStatsObj}
    overallStatsObj.watchDr = overallStatsObj.watchDr || 0;
    overallStatsObj.quesCount = overallStatsObj.quesCount || 0;
    overallStatsObj.totalScore = overallStatsObj.totalScore || 0;
    overallStatsObj.totalMarks = overallStatsObj.totalScore ;
    overallStatsObj.accuracy  = overallStatsObj.accuracy || 0;

    return overallStatsObj;
}


export class getAttendanceApi {
    
    static apiEndpoint = 'v1/class/{classId}/attendance/me';

    static projection;
    static get  __projection(){ 
        if(!getAttendanceApi.projection){
            getAttendanceApi.projection = JSON.stringify(new SourceProjectionMapper(_attendance).map());
        }
        return getAttendanceApi.projection;
    }


    static apiCall(network:Network,params:any){
        return network.get(getAttendanceApi.apiEndpoint.replace('{classId}', params.classId), params);
    }

}

export class getStatsApi {
    
    static apiEndpoint = 'v1/class/{classId}/stats/me';

    static projection;
    static get  __projection(){ 
        if(!getStatsApi.projection){
            getStatsApi.projection = JSON.stringify(new SourceProjectionMapper(_stats).map());
        }
        return getStatsApi.projection;
    }


    static apiCall(network:Network,params:any){
        return network.get(getStatsApi.apiEndpoint.replace('{classId}', params.classId), params);
    }

}

export class getLeaderBoardApi {
    
    static apiEndpoint = 'v1/class/{classId}/leaderboard/me';

    static projection;
    static get  __projection(){ 
        if(!getLeaderBoardApi.projection){
            getLeaderBoardApi.projection = JSON.stringify(new SourceProjectionMapper(leaderboard).map());
        }
        return getLeaderBoardApi.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(getLeaderBoardApi.apiEndpoint.replace('{classId}', params.classId), params);
    }

}

export class getEnrollmentDetailsApi {
    static apiEndpoint = 'v2/class/{classId}/student/{sid}';

    static projection;
    static get  __projection(){ 
        if(!getEnrollmentDetailsApi.projection){
            getEnrollmentDetailsApi.projection = JSON.stringify(new SourceProjectionMapper(_enrollmentDetails).map());
        }
        return getEnrollmentDetailsApi.projection;
    }


    static apiCall(network:Network,params:any){

        let replaceMap = {
            '{classId}': params.classId,
            '{sid}': params.sid,
        }
        let apiEndpoint = getEnrollmentDetailsApi.apiEndpoint.replace(/{classId}|{sid}/gi, (matched) =>  replaceMap[matched]);
        return network.get(apiEndpoint, params);
    }

}
