
export const selectWildCardRoutes = (state: any) => {return state.targetPage.wildCardRoutesMeta};
export const selectCustomPageInfo = (state: any) => {return state.targetPage.wildCardRoutes.customPageInfo};
export const selectTarget = (state: any) => {return state.targetPage.wildCardRoutes.targetInfo};

export const selectWildCardChildRoutes = (state: any) => {return state.targetPage.wildCardChildRoutesMeta};
export const selectCustomChildPageInfo = (state: any) => {return state.targetPage.wildCardChildRoutes.customPageChildInfo};
export const selectTargetChild = (state: any) => {return state.targetPage.wildCardChildRoutes.targetChildInfo};

export const selectTargetEntity = (state: any) => {return state.targetPage.targetEntities};
export const selectAttemptedTest = (state: any) => {return state.targetPage.attemptedTests};
export const selectDynamicTableData = (state: any) => {return state.targetPage.dynamicTableInfo};

export const selectMultipleTargetData = (state: any) => {return state.targetPage.multipleTargetData};

export const selectUniqueId = (state: any) => {return state.targetPage.uniqueId};

export const selectAllPypTargetTests = (state: any) => {
    return state.targetPage.allPypYearWiseTests
};

export const selectPypTargetTests = (state: any) => {
    return state.targetPage.pypTargetTests
};

export const selectPypTargetTestsYearFilters = (state: any) => {
    return state.targetPage.yearFilters
};

export const selectPypTargetTestsStageFilters = (state: any) => {
    return state.targetPage.stageFilters
};

export const selectCanUnlockTest = (state: any) => {
    return state.targetPage.canUnlockTest
};

export const selectPypFreeTests = (state: any) => {
    return state.targetPage.pypFreeTests
};


