import { Injectable} from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import {Observable, of} from "rxjs";
import {Store} from "@ngrx/store";
import {PlatformService} from "@core/services/platform-service";



@Injectable()
export class MasterClassLessonGuard implements CanActivate {


    constructor(private router:Router,
                private store: Store<{}>,
                private platformService: PlatformService) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean|UrlTree> {
                let parentId=route.params['parentId']
                    
                if(!this.platformService.isLoggedIn() && parentId) {
                    let url=`/login?masterClassSeries=${parentId}&redirect_url=`+encodeURI(state.url)
                    this.platformService.redirect(url,302);
                    return;
                }

                return of(true);
    }

}