<div class="modal" [ngClass]="{'show':showModel}" *ngIf="showModel">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <account-block></account-block>
            </div>
        </div>
    </div>

    <div class="modal-backdrop"></div>
</div>