import { Injectable} from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    ActivatedRoute,
    UrlTree
} from '@angular/router';
import {Observable, of} from "rxjs";
import {Store} from "@ngrx/store";
import {map} from "rxjs/operators";
import {
    getWildCardRoutes,
    resetWildCardChildRoutes,
    resetWildCardRoutes
} from "../route-modules/wild-card-routes/application-state/WildCardRoutesActions";
import {selectWildCardRoutes} from "../route-modules/wild-card-routes/application-state/WildCardRoutesSelector";
import { skipInitialState } from '@angular-commons/shared/utility/ngrx-utils';
import {PAGE_TYPE_CUSTOM, PAGE_TYPE_TARGET, currentAffairsRegex} from "../../../../angular-commons/shared/utility/constants";
import {PlatformService} from "../../../../angular-commons/core/services/platform-service";
import { langMap, languageCodeMap } from '@angular-commons/shared/utility/language';
import { getLanguageSegmentUrl, getOriginFromURL } from '@angular-commons/shared/utility/url';
import { isLanguageAvailable } from '@angular-commons/shared/utility/tb-common';

@Injectable()
export class WildCardRoutesGuard implements CanActivate {

    constructor(private router:Router,
                private route: ActivatedRoute,
                private store: Store<{}>,
                private platformService: PlatformService) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean|UrlTree> {

        let idOrSlug = route.params.idOrSlug;
        let isCurrentAffairs = idOrSlug.startsWith('current-affairs');

        if(idOrSlug.includes(".")){
            // custom page urls will never have a '.' in them (it indicates that most likely a file was requested)
            this.platformService.gotoErrorPage();
            return of(false);
        }

        if(route.params.languageCode && route.params.languageCode === 'en'){
            let url = getOriginFromURL(this.platformService.href) + '/' + idOrSlug;
            this.platformService.redirect(url,301);
            return of(false);
        }
        if(languageCodeMap[idOrSlug]){
            //idOrSlug should not be any language code
            this.platformService.gotoErrorPage();
            return of(false);
        }
        if(idOrSlug) {
            let params:any = {idOrSlug, updateRoute: true, language: this.platformService.getContentLang()};
            if (isCurrentAffairs) {
                params.caType = 'categoryTag';
            }

            this.store.dispatch(resetWildCardRoutes());
            this.store.dispatch(resetWildCardChildRoutes());
            this.store.dispatch(getWildCardRoutes({...params}));

            return this.store.select(selectWildCardRoutes).pipe(skipInitialState(), map((item: any) => {
                let queryLanguage = this.platformService.getQueryParamValue('language').toLowerCase();
                if(!(item.slugType)){
                    if(queryLanguage && queryLanguage !== 'english'){
                        this.platformService.redirect('/'+idOrSlug,301); //redirect to english url once
                        return false;
                    }
                    this.platformService.gotoErrorPage();
                    return false;
                }
                else if(item.slugType === PAGE_TYPE_TARGET ){ //&& route.params.languageCode && item.availableLanguages?.length
                    if(route.params.languageCode && !item.availableLanguages?.length){
                        this.platformService.gotoErrorPage();
                        return false;
                    }
                    if(route.params.languageCode && (!languageCodeMap[route.params.languageCode] || !isLanguageAvailable(languageCodeMap[route.params.languageCode],item.availableLanguages))){
                        this.platformService.gotoErrorPage();
                        return false;
                    }
                    if(!route.params.languageCode && isLanguageAvailable(queryLanguage,item.availableLanguages)){
                        this.platformService.redirect(getLanguageSegmentUrl(queryLanguage,this.platformService.href),301);
                    }
                }
                else if(item.slugType === PAGE_TYPE_CUSTOM && item.availableLanguages?.length && !currentAffairsRegex.test(idOrSlug)){
                    let queryLanguage = this.platformService.getQueryParamValue('language').toLowerCase();
                    let isLanguageSegmentFlow = !!route.params.languageCode;
                    let isQueryParamFlow = !!queryLanguage;

                    if(isQueryParamFlow && !isLanguageSegmentFlow && langMap[queryLanguage] && isLanguageAvailable(queryLanguage,item.availableLanguages)){
                        let segmentedLanguageUrl = getLanguageSegmentUrl(queryLanguage,this.platformService.href);
                        this.platformService.redirect(segmentedLanguageUrl,301);
                        return false;
                    }
                    else if(!isQueryParamFlow && isLanguageSegmentFlow && languageCodeMap[route.params.languageCode] && !isLanguageAvailable(languageCodeMap[route.params.languageCode], item.availableLanguages)){
                        this.platformService.gotoErrorPage();
                        return false;
                    }
                    else if(isQueryParamFlow && isLanguageSegmentFlow){
                        let segmentedLanguageUrl = getLanguageSegmentUrl(languageCodeMap[route.params.languageCode],this.platformService.href); //segmented language without language query param
                        this.platformService.redirect(segmentedLanguageUrl,301);
                        return false;
                    }
                }
                else if(!(item.slugType == PAGE_TYPE_TARGET  || item.slugType == PAGE_TYPE_CUSTOM)){
                    this.platformService.gotoErrorPage();
                    return false;
                }
                return true;
            }))
        }
    }

}
