import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountBlockComponent } from './account-block/account-block.component';
import { AccountBlockModalComponent } from './account-block-modal/account-block-modal.component';
// import { AccountBlockRoutingModule } from './account-blocked-routing.module';
import {CoreModule} from "@core/core.module";



@NgModule({
  declarations: [AccountBlockComponent,AccountBlockModalComponent],
  imports: [
    CommonModule,
    CoreModule
  ],
  exports: [
    AccountBlockComponent,
    AccountBlockModalComponent,
  ]

})
export class AccountBlockModule { }
