import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Store} from "@ngrx/store";
import {PlatformService} from "@core/services/platform-service";
import {getGoalBySlug} from "@core/application-state/app.actions";
import {selectGoal, selectGoalMap} from "@core/application-state/app.selectors";
import {Observable, ReplaySubject} from "rxjs";
import {TransferStateManager} from "../../../../angular-commons/core/services/transferStateManager";


@Injectable()
export class GoalPlansGuard implements CanActivate {
    private response:any;
    transferStateValue
    constructor(private router:Router, private store: Store<{}>,
                private platformService: PlatformService,
                private transferStateManager : TransferStateManager) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        let goalSlug = route.params.goalSlug;
        const sub = new ReplaySubject<boolean>();
        if(goalSlug){
        this.transferStateValue = this.transferStateManager.get(`currentGoalDetails${goalSlug}`,
            getGoalBySlug({slug: goalSlug}),
            this.store.select(selectGoalMap),
            (goal)=>{
                if(goal[goalSlug] && goal[goalSlug]?.properties?.isPitchDisabled) {
                    this.platformService.redirect('/'+goalSlug+'-coaching');
                    sub.next(false);
                }
                if(!(goal[goalSlug]?.id)) {
                    this.platformService.gotoErrorPage();
                    sub.next(false);
                } else {
                    sub.next(true);
                }
                sub.complete();
                if(this.transferStateValue){
                    this.transferStateValue.unsubscribe()
                }
            });
        }
        return sub;
    }

}
