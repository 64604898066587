import {createAction, props} from "@ngrx/store";

export const getTarget = createAction('[app] Get Target', props<{idOrSlug: string}>());
export const getTargetSuccess = createAction('[app] Get Target Success', props<{payload: any}>());

export const getWildCardRoutes = createAction('[app] Get Wild Card Routes', props<{idOrSlug: string, updateRoute?: boolean, fields?: string, language?:string, caType?: string}>());
export const getWildCardRoutesSuccess = createAction('[app] Get Wild Card Routes Success', props<{payload: any}>());

export const getWildCardChildRoutes = createAction('[app] Get Wild Card Child Routes', props<{idOrSlug: string, childIdOrSlug: string, updateRoute?: boolean, language?:string, maxChildPages?: number, maxPrevCrosslinks?: number, maxNextCrosslinks?: number, caType?: string}>());
export const getWildCardChildRoutesSuccess = createAction('[app] Get Wild Card Child Success', props<{_payload: any,language:string}>());

export const setWildCardRoutesMeta = createAction('[app] Set Wild Card Routes Meta', props<{slugType: string,availableLanguages:any}>());
export const setWildCardChildRoutesMeta = createAction('[app] Set Wild Card Child Routes Meta', props<{slugType: string,availableLanguages:any}>());

export const resetWildCardRoutes = createAction('[app] Reset Wild Card Routes');
export const resetWildCardChildRoutes = createAction('[app] Reset Wild Card Child Routes');

export const getTargetEntities = createAction('[Target Page] Get Target Entities', props<{id: string}>());
export const getTargetEntitiesSuccess = createAction('[Target Page] Get Target Entities Success', props<{payload: any}>());

export const getEntitiesBySeoGroup = createAction('[Target Page] Get Entities By Seo Group', props<{slug: string}>());

export const getTargetChild = createAction('[Target Child] Get Target Child', props<{idOrSlug: string, childIdOrSlug: string}>());
export const getTargetChildSuccess = createAction('[Target Child] Get Target Child Success', props<{payload: any}>());
export const resetTargetChild = createAction('[Target Child] Reset Target Child');

export const getDynamicTableData = createAction('[app] Get Dynamic Table Data', props<{targetIds: string}>());
export const getDynamicTableDataSuccess = createAction('[app] Get Dynamic Table Data Success', props<{payload: any}>());

export const getMultipleTargetDetails = createAction('[app] Get Multiple Target Details', props<{targetIds: string, limit?: number}>());
export const getMultipleTargetDetailsSuccess = createAction('[app] Get Multiple Target Details Success', props<{payload: any}>());

export const getAttemptedTests = createAction('[Target Page] Get Target Attempted Tests', props<{testIds: string}>());
export const getAttemptedTestsSuccess = createAction('[Target Page] Get Target Attempted Tests Success', props<{payload: any}>());

export const setUniqueId = createAction('[Target Page] Set Unique Req Id', props<{id: any}>());

export const getPypTargetTests = createAction('[Target Page] getPypTargetTests', props<{id: string, skip: number, limit: number, year: string, stage: string}>());
export const getPypTargetTestsLoaded = createAction('[Target Page] getPypTargetTestsLoaded', props<{payload: any,params:any}>());
export const setLoadMorePypYearWiseTests = createAction('[Target Page] setLoadMorePypYearWiseTests');

export const setPypTestsInStore = createAction('[Target Page] setPypTestsInStore', props<{payload: any}>());
export const filtersApplied = createAction('[Target Page] filtersApplied');
