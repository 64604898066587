import { baseModel ,setupModel } from "../../types";
import {propertyMaps, arrayModel, SourceProjectionMapper, objModel} from "../../mapping-framework";
import {Validator as _} from "../../../shared/utility/validator";
import {Network} from "../../../core/services/model-manager/main";
export class _SuperGroupsInfo extends baseModel {
    @propertyMaps('_id', _.str)
    public id;
    
    @propertyMaps('title', _.str)
    public title;

}
setupModel(_SuperGroupsInfo, {});

export class StateInfo extends baseModel{
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('title', _.str)
    public title;

}
setupModel(StateInfo, {});

export class _Property extends baseModel{
    @propertyMaps('title', _.str)
    public title;

    @propertyMaps('logo', _.logoValidator)
    public logo;

    @propertyMaps('superGroupInfo', arrayModel(_SuperGroupsInfo), _SuperGroupsInfo)
    public superGroupInfo;
    
    @propertyMaps('stateTags', arrayModel(StateInfo), StateInfo)
    public stateTags;

    @propertyMaps('groupInfo', arrayModel(StateInfo), StateInfo)
    public groupInfo;
}
setupModel(_Property, {});

export class CategoriesTargetModel extends baseModel {
    
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('properties', objModel(_Property), _Property)
    public properties;

    @propertyMaps('isEnrolled', _.bool)
    public isEnrolled;

     @propertyMaps('slug', _.str)
    public slug;

}
setupModel(CategoriesTargetModel, {});

export class SelectedTargetModel extends baseModel {
    
    @propertyMaps('id', _.str)
    public id;

    @propertyMaps('isEnrolled', _.bool)
    public isEnrolled;

    @propertyMaps('title', _.str)
    public title;

    @propertyMaps('logo', _.logoValidator)
    public logo;

    @propertyMaps('superGroupInfo', arrayModel(_SuperGroupsInfo), _SuperGroupsInfo)
    public superGroupInfo;
    
    @propertyMaps('stateInfo', arrayModel(StateInfo), StateInfo)
    public stateTags;

    @propertyMaps('slug', _.str)
    public slug;
}
setupModel(SelectedTargetModel, {});


export class Targets extends baseModel {
    @propertyMaps('targets', arrayModel(CategoriesTargetModel), CategoriesTargetModel)
    public targets;
}
setupModel(Targets, {});

export class SelectedTargets extends baseModel {
    @propertyMaps('targets', arrayModel(SelectedTargetModel), SelectedTargetModel)
    public targets;
}
setupModel(SelectedTargets, {});

export class SearchTargets extends baseModel {
    @propertyMaps('results', objModel(Targets), Targets)
    public results;

    @propertyMaps('searchId', _.str)
    public searchId;
}
setupModel(SearchTargets, {});
export class _TargetGroupsProperty extends baseModel {   
    @propertyMaps('logo', _.logoValidator)
    public logo;
    
    @propertyMaps('title', _.str)
    public title;

    @propertyMaps('description', _.str)
    public description;
    
}
setupModel(_TargetGroupsProperty, {});

export class TargetGroup extends baseModel {
    
    @propertyMaps('_id', _.str)
    public id;
    
    @propertyMaps('title', _.str)
    public title;

    @propertyMaps('targetCount', _.bool)
    public targetCount;
    
    @propertyMaps('order', _.bool)
    public order;

}
setupModel(TargetGroup, {});

export class TargetGroupsModel extends baseModel {
    
    @propertyMaps('_id', _.str)
    public id;
    
    @propertyMaps('properties', objModel(_TargetGroupsProperty),_TargetGroupsProperty)
    public properties;
    
    @propertyMaps('isPrivate', _.bool)
    public isPrivate;

    @propertyMaps('discoverableTGs', arrayModel(TargetGroup),TargetGroup)
    public discoverableTGs;

}
setupModel(TargetGroupsModel, {});
export class TargetGroups extends baseModel {
    @propertyMaps('targetCategory', objModel(TargetGroupsModel), TargetGroupsModel)
    public targetCategory;
}
setupModel(TargetGroups, {});
export class TopTargetModel extends baseModel {
    @propertyMaps('id', _.str)
    public id;
    
    @propertyMaps('title', _.str)
    public title;

}
setupModel(TopTargetModel, {});
export class SuperGroup extends baseModel{
    @propertyMaps('id', _.str)
    public id;
    
    @propertyMaps('title', _.str)
    public title;
    
    @propertyMaps('logo', _.logoValidator)
    public logo;
    
    @propertyMaps('topTargets', arrayModel(TopTargetModel),TopTargetModel)
    public topTargets;
    
    @propertyMaps('targetCount', _.num)
    public targetCount;
    
    @propertyMaps('description', _.str)
    public description;
    
    @propertyMaps('tgCount', _.str)
    public tgCount;
    
     @propertyMaps('isPrivate', _.bool)
    public isPrivate;

}
setupModel(SuperGroup, {});

export class SuperGroups extends baseModel {
    @propertyMaps('superGroup', arrayModel(SuperGroup), SuperGroup)
    public superGroup;

}
setupModel(SuperGroups, {});

export class _NewSuperGroupsCount extends baseModel {
    @propertyMaps()
    public stateCount:any;
    
    @propertyMaps()
    public superGroupCount:any;
}
setupModel(_NewSuperGroupsCount, {});

export class NewSuperGroupsCount extends baseModel {
    @propertyMaps('stateCount', _.num)
    public stateCount;
    
    @propertyMaps('superGroupCount', _.num)
    public superGroupCount;
}
setupModel(NewSuperGroupsCount, {});

export class _StateProperty extends baseModel {   
    @propertyMaps('logo', _.logoValidator)
    public logo;
    
    @propertyMaps('title', _.str)
    public title;

    @propertyMaps('description', _.str)
    public description;

    @propertyMaps('smallTitle', _.str)
    public smallTitle;
    
}
setupModel(_StateProperty, {});


export class StateSuperGroup extends baseModel{
    @propertyMaps('_id', _.str)
    public id;
    
    @propertyMaps('properties', objModel(_StateProperty),_StateProperty)
    public properties;
    
    @propertyMaps('targetCount', _.num)
    public targetCount;
    j
     @propertyMaps('isEnrolled', _.bool)
    public isEnrolled;

    @propertyMaps('isHomeState', _.bool)
    public isHomeState;

}
setupModel(StateSuperGroup, {});

export class StateSuperGroups extends baseModel {
    @propertyMaps('states', arrayModel(StateSuperGroup), StateSuperGroup)
    public states;

}
setupModel(StateSuperGroups, {});
export class GetPopularTargetApi {
    static apiEndpoint = 'v2/target/popular';

    static projection;
    static get  __projection(){
        if(!GetPopularTargetApi.projection){
          GetPopularTargetApi.projection = JSON.stringify(new SourceProjectionMapper(Targets).map());
        }
        return GetPopularTargetApi.projection;
    }
    
    static apiCall(network: Network, params) {
        return network.get(GetPopularTargetApi.apiEndpoint, { __projection: GetPopularTargetApi.__projection, ...params});
    }
}

export class GetSuperGroupApi {
    static apiEndpoint = 'v1/tsg';
    
    static projection;
    static get  __projection(){
        if(!GetSuperGroupApi.projection){
            GetSuperGroupApi.projection = JSON.stringify(new SourceProjectionMapper(SuperGroups).map());
        }
        return GetSuperGroupApi.projection;
    }
    
    static apiCall(network: Network, params) {
        return network.get(GetSuperGroupApi.apiEndpoint, {__projection: GetSuperGroupApi.__projection, ...params});
    }

}

export class GetSuperGroupCountApi {
    static apiEndpoint = 'v1/target-categories/count';
    
    static projection;
    static get  __projection(){
        if(!GetSuperGroupCountApi.projection){
            GetSuperGroupCountApi.projection = JSON.stringify(new SourceProjectionMapper(_NewSuperGroupsCount).map());
        }
        return GetSuperGroupCountApi.projection;
    }
    
    static apiCall(network: Network, params) {
        return network.get(GetSuperGroupCountApi.apiEndpoint, {__projection: GetSuperGroupCountApi.__projection});
    }

}

export class GetStateSuperGroupApi {
    static apiEndpoint = 'v1/student/target-states';
    
    static projection;
    static get  __projection(){
        if(!GetStateSuperGroupApi.projection){
            GetStateSuperGroupApi.projection = JSON.stringify(new SourceProjectionMapper(StateSuperGroups).map());
        }
        return GetStateSuperGroupApi.projection;
    }
    
    static apiCall(network: Network, params) {
        return network.get(GetStateSuperGroupApi.apiEndpoint, {__projection: GetStateSuperGroupApi.__projection});
    }

}

export class GetTargetGroupApi {
    static apiEndpoint = 'v1/target-categories/{superGroupId}';
    
    static projection;
    static get  __projection(){
        if(!GetTargetGroupApi.projection){
            GetTargetGroupApi.projection = JSON.stringify(new SourceProjectionMapper(TargetGroups).map());
        }
        return GetTargetGroupApi.projection;
    }
    
    static apiCall(network: Network, params) {
        return network.get(GetTargetGroupApi.apiEndpoint.replace('{superGroupId}', params.id), {__projection: GetTargetGroupApi.__projection});
    }
}

export class GetAllExamTargetApi {
    static apiEndpoint = 'v1/student/target-categories/{superGroupId}/targets';
    
    static projection;
    static get  __projection(){
        if(!GetAllExamTargetApi.projection){
            GetAllExamTargetApi.projection = JSON.stringify(new SourceProjectionMapper(Targets).map());
        }
        return GetAllExamTargetApi.projection;
    }
    
    static apiCall(network: Network, params) {
        return network.get(GetAllExamTargetApi.apiEndpoint.replace('{superGroupId}', params.id), {__projection: GetAllExamTargetApi.__projection});
    }
}

export class GetTargetApi {
    static apiEndpoint = 'v1/student/target-group/{targetGroupId}/targets';
    
    static projection;
    static get  __projection(){
        if(!GetTargetApi.projection){
            GetTargetApi.projection = JSON.stringify(new SourceProjectionMapper(Targets).map());
        }
        return GetTargetApi.projection;
    }
    
    static apiCall(network: Network, params) {
        return network.get(GetTargetApi.apiEndpoint.replace('{targetGroupId}', params.id), {__projection: GetTargetApi.__projection});
    }
}

export class GetSearchAllTargetApi {
    static apiEndpoint = 'v1/search/individual';
    
    static projection;
    static get  __projection(){
        if(!GetSearchAllTargetApi.projection){
            GetSearchAllTargetApi.projection = JSON.stringify(new SourceProjectionMapper(SearchTargets).map());
        }
        return GetSearchAllTargetApi.projection;
    }
    
    static apiCall(network: Network, params) {
        return network.get(GetSearchAllTargetApi.apiEndpoint, {__projection: GetSearchAllTargetApi.__projection, ...params});
    }
}

export class GetOthersTargetApi {
    static apiEndpoint = 'v1/student/target-categories/{targetSuperGroup}/targets/other';
    
    static projection;
    static get  __projection(){
        if(!GetOthersTargetApi.projection){
            GetOthersTargetApi.projection = JSON.stringify(new SourceProjectionMapper(Targets).map());
        }
        return GetOthersTargetApi.projection;
    }
    
    static apiCall(network: Network, params) {
        return network.get(GetOthersTargetApi.apiEndpoint.replace('{targetSuperGroup}', params.id), {__projection: GetOthersTargetApi.__projection});
    }
}

export class GetSelectedTargetApi {
    static apiEndpoint = 'v1/students/targets';

    static projection;
    static get  __projection(){
        if(!GetSelectedTargetApi.projection){
            GetSelectedTargetApi.projection = JSON.stringify(new SourceProjectionMapper(SelectedTargets).map());
        }
        return GetSelectedTargetApi.projection;
    }

    static apiCall(network: Network, params) {
        return network.get(GetSelectedTargetApi.apiEndpoint, {__projection: GetSelectedTargetApi.__projection,  ...params });
    }
}

export class addExamsApi {
    static apiEndpoint = 'v1/students/targets';
     static  __projection = 1

    static apiCall(network: Network, params) {
        return network.post(addExamsApi.apiEndpoint,{}, params);
    }
}

export class removeExamsApi {
    static apiEndpoint = 'v1/students/targets';
     static  __projection = 1

    static apiCall(network: Network, params) {
        return network.delete(removeExamsApi.apiEndpoint, {}, params);
    }
}

