import { Injectable} from '@angular/core';
import {EMPTY, Observable} from "rxjs";
import {isServer} from "@shared/utility/tb-common";

@Injectable({
    providedIn: 'root'
})
export class TimerService {

    timer: Observable<void> = EMPTY;
    timerStarted: boolean = false;

    constructor() {
    }

    startTimer() {
        if(!this.timerStarted) {
            this.timer = new Observable(subscriber => {
                setInterval(() => {
                    subscriber.next();
                }, 1000)
            });
        }
        return this.timer;
    }
}
