import { hideAlert} from '@angular-commons/core/application-state/app.actions';
import { selectAlertState } from '@angular-commons/core/application-state/app.selectors';
import {AutoUnsub, isServer} from '@angular-commons/shared/utility/tb-common';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'alert-toast',
  templateUrl: './alert-toast.component.html',
  styleUrls: ['./alert-toast.component.scss']
})
@AutoUnsub()
export class AlertToastComponent implements OnInit {
  
  showAlert: boolean = false;
  messages: any=[];
  isCompletedAlert: boolean = false;
  alertTitle: string = ''; // Title for alerts if isCompletedAlert is true ...
  alertState$: any;
  alertType:any='default'
  autoClose: boolean = true;
  timer:number = 1000;
  constructor(private store: Store<{}>) {
    this.alertState$ = this.store.select(selectAlertState)
  }

  ngOnInit(): void {
    

    if(!isServer()){
      this.alertState$.subscribe( state => {
        this.messages = state.messages;
        this.isCompletedAlert = state.isCompletedAlert || false;
        this.alertTitle = state.alertTitle || '';
        this.alertType=state.alertType || '';
        this.timer = state.timer || 1000;
        this.autoClose = state.autoClose;

          setTimeout(()=>{
            if(this.autoClose && this.messages && this.messages.length>0){
              let currentTime=new Date().getTime();
              for(let i in this.messages){
                  if(this.messages[i]?.expiry<currentTime){
                    this.dismissAlert(this.messages[i].id);
                  }
              }
            }
          },this.timer)

      })
    }


  }

  dismissAlert(id:number) {

      this.store.dispatch(hideAlert({id}))


  }

}