import {baseModel, setupModel} from "@models/types";
import {
    propertyMaps,
    SourceProjectionMapper,
    arrayModel,
    objModel,
    pipeModel,
    SourceFieldsMapper
} from "@models/mapping-framework";
import {Network} from "@core/services/model-manager/main";
import {filterObjectKeys, Validator as _} from "../../../shared/utility/validator";
import { SeoMenuWrapper } from "../page-seo/seo-menu.adapter";
import {costPerDay, costPerMonth, costPerYear} from "@models/entity/passes/passes.adapter";
import {addDiscountText, Coupon} from "@models/entity/coupon/coupon.adapter";
import {NS_IN_A_DAY} from "@shared/utility/date-utilities";


const GOAL_DASHBOARD_HEADERS_TABS:Array<any> =
[
  {title:'Dashboard',routeSuffix:'/dashboard',isHidden:false},
  {title:'Courses',routeSuffix:'/courses',isHidden:false},
  {title:'Study Notes',routeSuffix:'/notes',isHidden:false},
  {title:'Practice',routeSuffix:'/practice',isHidden:false},
  {title:'Test Series',routeSuffix:'/tests',isHidden:false},
  {title:'Mentorship',routeSuffix:'/mentorship',isHidden:false},
];
export class Classes extends baseModel {
    @propertyMaps('id')
    public id;
}
setupModel(Classes,{})

export class CustomTags extends baseModel {

    @propertyMaps('id')
    public id;

    @propertyMaps("title",_.str)
    public title;

    @propertyMaps("weight",_.num)
    public weight;

    @propertyMaps("classes",arrayModel(Classes),Classes)
    public classes;
}
setupModel(CustomTags,{})

export class Meta extends baseModel{

    @propertyMaps("title",_.str)
    public title;

    @propertyMaps("description",_.str)
    public description;

    @propertyMaps("ogImage",_.str)
    public ogImage;

    @propertyMaps("ogTitle",_.str)
    public ogTitle;
}
setupModel(Meta,{})

export class Pitch extends baseModel{

    @propertyMaps("heading",_.str)
    public heading;

    @propertyMaps("subHeading",_.str)
    public subHeading;

    @propertyMaps("icon",_.iconUrl)
    public icon;

    @propertyMaps("count",_.str)
    public count;

    @propertyMaps("type",_.str)
    public type;

    @propertyMaps("showInSummary",_.bool)
    public showInSummary;

    @propertyMaps("isPrimary",_.bool)
    public isPrimary;

}
setupModel(Pitch,{})

export class GenericContent extends baseModel{
    @propertyMaps("type",_.str)
    public type;

    @propertyMaps("value",_.str)
    public value;
}
setupModel(GenericContent,{})

export class GenericPitch extends baseModel{

    @propertyMaps("id",_.str)
    public id;

    @propertyMaps("order",_.str)
    public order;

    @propertyMaps("title",_.str)
    public title;

    @propertyMaps("isHidden",_.bool)
    public isHidden;

    @propertyMaps("webLink",_.str)
    public webLink;

    @propertyMaps("content",arrayModel(GenericContent),GenericContent)
    public content;

}
setupModel(GenericPitch,{})


export class Faculties extends baseModel{
    @propertyMaps('id')
    public id;
}
setupModel(Faculties,{})


export class Faq extends baseModel{
    @propertyMaps('question',_.str)
    public question;

    @propertyMaps('answer',_.str)
    public answer;
}
setupModel(Faq,{})

export class Books extends baseModel{
    @propertyMaps('id',_.str)
    public question;

    @propertyMaps('title',_.str)
    public answer;
}

setupModel(Books,{})


export class target extends baseModel {
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('title', _.str)
    public title;

    @propertyMaps('logo', _.str)
    public logo;

    @propertyMaps('isPrimary', _.bool)
    public isPrimary;
}
setupModel(target, {});

export class Pdf extends baseModel{
    @propertyMaps('deeplink', _.str)
    public deeplink;
}
setupModel(Pdf, {});

export class StudyPlanMeta extends baseModel{
    @propertyMaps('title', _.str)
    public title;

    @propertyMaps('description', _.str)
    public description;
}
setupModel(StudyPlanMeta, {});

export class StudyPlan extends baseModel{
    @propertyMaps('pdf', objModel(Pdf), Pdf)
    public pdf;

    @propertyMaps('meta', objModel(StudyPlanMeta), StudyPlanMeta)
    public meta;
}
setupModel(StudyPlan, {});

function filterEmptyImageUrls(imgArr) {
    return imgArr.filter(arr => arr && arr.imageUrl);
}

export class PitchCarousel extends baseModel {
    @propertyMaps('url', _.str)
    public imageUrl;

    @propertyMaps('webLink', _.str)
    public clickUrl;

    @propertyMaps('type', _.str)
    public type;

    @propertyMaps('videoUrl', _.str)
    public videoUrl;
}
setupModel(PitchCarousel, {});

export class AllowedPaymentPartners extends baseModel {
    @propertyMaps('name', _.str)
    public name;

    @propertyMaps('plan', _.str)
    public plan;
}
setupModel(AllowedPaymentPartners, {});

export class GoalTabs extends baseModel{

    @propertyMaps('id',_.str)
    public title;

    @propertyMaps('isHidden',_.bool)
    public isHidden;

    @propertyMaps()
    public routeSuffix;
}
setupModel(GoalTabs,{})

export class CustomerGlu extends baseModel {
    @propertyMaps('id', _.str)
    public id;

    @propertyMaps('isHidden', _.bool)
    public isHidden;

    @propertyMaps('isGamePlayed', _.bool)
    public isGamePlayed;

    @propertyMaps('campaignType', _.str)
    public campaignType;
}
setupModel(CustomerGlu, {});

function transformTabs(webTabs) {
    let tabs = [];
    if(webTabs.length > 0){
        webTabs.forEach(tab =>{
            if(!tab.isHidden){
                tab.routeSuffix = GOAL_DASHBOARD_HEADERS_TABS.find(i => i?.title == tab?.title)?.routeSuffix;
                tabs.push(tab);
            }
        })
       return tabs;
    }
    return GOAL_DASHBOARD_HEADERS_TABS;
}

export class Mentoring extends baseModel {
    @propertyMaps('hasMentorshipSupport', _.bool)
    public hasMentorshipSupport;
    @propertyMaps('maxAllowedCredits', _.num)
    public maxAllowedCredits;
}
setupModel(Mentoring, {});

export class Properties extends baseModel {

    @propertyMaps("heading",_.str)
    public heading;

    @propertyMaps("subHeading",_.str)
    public subHeading;

    @propertyMaps("title",_.str)
    public title;

    @propertyMaps("facultyTitle",_.str)
    public facultyTitle;

    @propertyMaps("icon",_.iconUrl)
    public icon;

    @propertyMaps("primaryTitle",_.str)
    public primaryTitle;

    @propertyMaps("description",_.str)
    public description;


    @propertyMaps("slug",_.str)
    public slug;

    @propertyMaps("isComboGoal",_.bool)
    public isComboGoal;

    @propertyMaps("meta",objModel(Meta),Meta)
    public meta;

    @propertyMaps("allFacultyMeta",objModel(Meta),Meta)
    public allFacultyMeta;

    @propertyMaps("sectionPitch",arrayModel(Pitch),Pitch)
    public sectionPitch;

    @propertyMaps("genericComponent",arrayModel(GenericPitch),GenericPitch)
    public genericComponent;

    @propertyMaps("customTags",arrayModel(CustomTags),CustomTags)
    public customTags;

    @propertyMaps("pitch",arrayModel(Pitch),Pitch)
    public pitch;

    @propertyMaps("faculties",arrayModel(Faculties),Faculties)
    public faculties;

    @propertyMaps("faqs",arrayModel(Faq),Faq)
    public faq;

    @propertyMaps("books",arrayModel(Books),Books)
    public books;

    @propertyMaps("currPdf",_.str)
    public currPdf;

    @propertyMaps("target",arrayModel(target),target)
    public target;

    @propertyMaps('showExams', _.bool)
    public showExams;

    @propertyMaps('pitchTitle')
    public pitchTitle;

    @propertyMaps('menus', objModel(SeoMenuWrapper), SeoMenuWrapper)
    public menus;

    @propertyMaps('showPassPitch', _.bool)
    public showPassPitch;

    @propertyMaps('showEntirePrice', _.bool)
    public showEntirePrice;

    @propertyMaps('marketingDesc', _.str)
    public marketingDesc;

    @propertyMaps('aovType', _.str)
    public aovType;

    @propertyMaps('studyPlan', objModel(StudyPlan), StudyPlan)
    public studyPlan;

    @propertyMaps ('canonicalURL', _.str)
    public canonicalUrl;
    
    @propertyMaps('pitchCarousel', pipeModel(arrayModel(PitchCarousel), filterEmptyImageUrls), PitchCarousel)
    public pitchCarousel;

    @propertyMaps('allowedPaymentPartners', arrayModel(AllowedPaymentPartners), AllowedPaymentPartners)
    public allowedPaymentPartners;

    @propertyMaps ('customerGluCampaign', objModel(CustomerGlu), CustomerGlu)
    public customerGluCampaign;

    @propertyMaps ('intercomPriority', _.str)
    public intercomPriority;

    @propertyMaps ('mentoring', objModel(Mentoring), Mentoring)
    public mentoring;

    @propertyMaps ('isClientEMIAvailable', _.bool)
    public isClientEMIAvailable;

    @propertyMaps('isPitchDisabled', _.bool)
    public isPitchDisabled;
}
setupModel(Properties,{})

export class GoalStats extends baseModel{

    @propertyMaps('mclassSeriesCount',_.num)
    public mclassSeriesCount;

}
setupModel(GoalStats,{})

export class ThumbnailInfo extends baseModel{
    @propertyMaps()
        public url;
}
setupModel(ThumbnailInfo,{})

export class Languages extends baseModel{
    @propertyMaps("title",_.str)
        public title;
}
setupModel(Languages,{})

export class productTags extends baseModel{
    @propertyMaps('languageTags', arrayModel(Languages),Languages)
        public languageTags;
}
setupModel(productTags,{})

export class Products extends baseModel{

    @propertyMaps("titles",_.str)
    public title;

    @propertyMaps("descriptions",_.str)
    public description;

    @propertyMaps()
    public images;

    @propertyMaps("thumbnailInfo", objModel(ThumbnailInfo),ThumbnailInfo)
    public thumbnailInfo;

    @propertyMaps("productTags", objModel(productTags),productTags)
    public productTags;

}
setupModel(Products,{})

export class AllProductsWrapper extends baseModel{

    @propertyMaps("products",arrayModel(Products),Products)
    public products;

}
setupModel(AllProductsWrapper,{})

export class Goal extends baseModel{

    @propertyMaps('_id')
    public id;

    @propertyMaps("stage",_.str)
    public stage;

    @propertyMaps("isDeListed",_.bool)
    public isDeListed;
    
    @propertyMaps("properties",objModel(Properties),Properties)
    public properties;

    @propertyMaps('stats',objModel(GoalStats))
    public stats;
}
setupModel(Goal,{})

export class SimpleGoalProperties extends baseModel{
    @propertyMaps("title",_.str)
    public title;

    @propertyMaps("facultyTitle",_.str)
    public facultyTitle;

    @propertyMaps("icon",_.iconUrl)
    public icon;

    @propertyMaps("slug",_.str)
    public slug;

    @propertyMaps("isComboGoal",_.bool)
    public isComboGoal;

    @propertyMaps('webTabs', pipeModel(arrayModel(GoalTabs), transformTabs),GoalTabs)
    public webTabs;

    @propertyMaps('isClientEMIAvailable', _.bool)
    public isClientEMIAvailable;
}
setupModel(SimpleGoalProperties,{})

export class SimpleGoal extends baseModel{

    @propertyMaps('_id')
    public id;

    @propertyMaps("properties",objModel(SimpleGoalProperties),SimpleGoalProperties)
    public properties;

    @propertyMaps("isDeListed",_.bool)
    public isDeListed;
}
setupModel(SimpleGoal,{})

export class SimpleGoals extends baseModel{

    @propertyMaps('count',_.num)
    public count;

    @propertyMaps("goals",arrayModel(SimpleGoal),SimpleGoal)
    public goals;

}
setupModel(SimpleGoals,{})

export class GoalsWrapper extends baseModel{
    @propertyMaps('category',_.str)
    public category;

    @propertyMaps('isRecommended',_.bool)
    public isRecommended;

    @propertyMaps('length',_.num)
    public length;

}
setupModel(GoalsWrapper,{})

export class CategoryWiseGoals extends baseModel{
    @propertyMaps('categoryGoals',pipeModel(arrayModel(GoalsWrapper)),GoalsWrapper)
    public categoryGoals;
}
setupModel(CategoryWiseGoals,{})


export class ComboGoalCardProperties extends baseModel {
    @propertyMaps('title',_.str)
    public title;

    @propertyMaps('icon',_.str)
    public icon;

    @propertyMaps('cardTitle',_.str)
    public cardTitle;

    @propertyMaps('cardDescription',_.str)
    public cardDescription;

    @propertyMaps('cardIcon',_.obj)
    public cardIcon;

    @propertyMaps('slug',_.str)
    public slug;

    @propertyMaps('aovType',_.str)
    public aovType;

    @propertyMaps('heading',_.str)
    public heading;

    @propertyMaps('currPdf', _.str)
    public currPdf;

    @propertyMaps('pitchCarousel', pipeModel(arrayModel(PitchCarousel), filterEmptyImageUrls), PitchCarousel)
    public pitchCarousel;
}
setupModel(ComboGoalCardProperties, {});

export class ComboGoalCards extends baseModel {

    @propertyMaps('_id',_.str)
    public id;

    @propertyMaps('properties', objModel(ComboGoalCardProperties), ComboGoalCardProperties)
    public properties;

    @propertyMaps('stats',_.obj)
    public stats;

    @propertyMaps('isDeListed',_.bool)
    public isDeListed;

    @propertyMaps('goalExpiry',_.str)
    public goalExpiry;

    @propertyMaps('isEnrolled',_.bool)
    public isEnrolled;

    @propertyMaps('isSubscribed',_.bool)
    public isSubscribed;

    @propertyMaps('isEnrolledTarget',_.bool)
    public isEnrolledTarget;

    @propertyMaps('discountPercent',_.str)
    public discountPercent;

    @propertyMaps('goalSubs', transformGoalSub)
    public goalSubs;

    @propertyMaps('coupon', pipeModel(objModel(Coupon), addDiscountText), Coupon)
    public coupon;

    @propertyMaps('stage',_.str)
    public stage;
}
setupModel(ComboGoalCards, {});

export class SearchGoalWapppper extends baseModel{
    @propertyMaps('goal',_.arr)
    public goal;

    @propertyMaps('goalCards', arrayModel(ComboGoalCards), ComboGoalCards)
    public goalCards;
}
setupModel(SearchGoalWapppper,{})
export class SearchGoals extends baseModel{
    @propertyMaps('results', objModel(SearchGoalWapppper),SearchGoalWapppper)
    public results;

    @propertyMaps('searchId', _.str)
    public searchId;
}
setupModel(SearchGoals,{})


export class GoalWrapper extends baseModel{

    @propertyMaps("goal",objModel(Goal),Goal)
    public goal;

}
setupModel(GoalWrapper,{})

export class TargetGoalMap extends baseModel{
    @propertyMaps("targetGoalMap")
    public targetGoalMap;

    @propertyMaps("primaryGoal")
    public primaryGoal;
}
setupModel(TargetGoalMap,{})

export class PitchMap extends baseModel{
    @propertyMaps("pitchMap",objModel(TargetGoalMap),TargetGoalMap)
    public pitchMap;
}
setupModel(PitchMap,{})

export class _Banner extends baseModel{

    @propertyMaps('start_time')
    public startTime;

    @propertyMaps('end_time')
    public endTime;

    @propertyMaps('goalId')
    public goalId;

    @propertyMaps('mweb_image')
    public mWebImage;

    @propertyMaps('web_image')
    public webImage;

    @propertyMaps('click_url')
    public clickUrl;

}
setupModel(_Banner,{});

export class _GlobalBanner extends baseModel{

    @propertyMaps('start_time')
    public startTime;

    @propertyMaps('end_time')
    public endTime;

    @propertyMaps('goalId')
    public goalId;

    @propertyMaps('mweb_image')
    public mWebImage;

    @propertyMaps('web_image')
    public webImage;

    @propertyMaps('click_url')
    public clickUrl;

    @propertyMaps('pages')
    public pages;
}
setupModel(_GlobalBanner,{});

export class _GoalPageBanner extends baseModel{
    @propertyMaps('goalPageBanner', arrayModel(_Banner), _Banner)
    public goalPageBanner;
}
setupModel(_GoalPageBanner, {});

export class _GlobalPopupBanner extends baseModel{
    @propertyMaps('globalPopupBanner', pipeModel(arrayModel(_GlobalBanner), transformBannerData) , _GlobalBanner)
    public globalPopupBanner;
}
setupModel(_GlobalPopupBanner, {});

export function transformBannerData(data) {
    let tranformedData = {};
    if (data?.length) {
        data.forEach((x) => {
            if (x.goalId){
                tranformedData[x.goalId] = x;
            } else if (x.targetId){
                tranformedData[x.targetId] = x;
            }
        });
    }
    return tranformedData;
}

export class _CategoryWiseGoals extends baseModel{
    @propertyMaps('categoryGoals')
    public categoryGoals;
}
setupModel(_CategoryWiseGoals, {});

function transformGoalSub(goalSub){
    if (!goalSub){
        return null;
    }
    let pass = {...goalSub};
    pass.costPerYear = costPerYear(pass.cost, pass.validity);
    pass.costPerMonth = costPerMonth(pass.cost,pass.validity);
    pass.costPerDay = costPerDay(pass.cost,pass.validity);
    pass.validityInDays=(pass.validity/NS_IN_A_DAY)
    pass.discountPercentage=(pass.oldCost !== 0) ? Math.round(((pass.oldCost - pass.cost) * 100) / pass.oldCost) : 0;
    pass.validityString=pass.validityString || (pass.validity/NS_IN_A_DAY) + ' days';
    pass.isCouponApplied=false;
    pass.afterCouponCost=-1;
    pass.coupon="";
    pass.titleWithoutPass=pass.title.replace('Pass', '');
    pass.titleWithoutGoal=pass.title.split('-')[0].trim();
    pass.discount = pass.oldCost - pass.cost;
    pass.isMaxDiscount = pass.discount === Math.max(pass.oldCost - pass.cost);
    return pass;
}


export class ComboGoalPacks extends baseModel {
    @propertyMaps('goalCards', arrayModel(ComboGoalCards), ComboGoalCards)
    public goalCards;
}
setupModel(ComboGoalPacks, {});

export class ClassesModel extends baseModel{
    @propertyMaps('title', _.str)
        public title;

    @propertyMaps('_id', _.str)
        public _id;

    @propertyMaps('courseLogo', _.str)
        public courseLogo;

    @propertyMaps('preCourseLogo', _.str)
        public preCourseLogo;

    @propertyMaps('releaseDate', _.date)
        public releaseDate;

    @propertyMaps('pitchPoints', _.str)
        public pitchPoints;

    @propertyMaps()
        public classProperties;

    @propertyMaps('isPremium', _.bool)
        public isPremium;

    }

setupModel(ClassesModel, {});

export class AllLiveLessonsWrapper extends baseModel{
    @propertyMaps('classes', arrayModel(ClassesModel), ClassesModel)
        public classes;
}

setupModel(AllLiveLessonsWrapper, {});

export class PassSaleBannerData extends baseModel{
@propertyMaps('enabled', _.str)
    public enabled;

@propertyMaps('web_image', _.str)
    public webImg;

@propertyMaps('mweb_image', _.str)
    public mwebImg;

@propertyMaps('click_url', _.str)
    public clickUrl;

    @propertyMaps('page', _.str)
    public page;
}
setupModel(PassSaleBannerData, {});

export class PassSaleBanner extends baseModel{
    @propertyMaps('passSaleBanner', arrayModel(PassSaleBannerData), PassSaleBannerData)
    public passSaleBanner;
}
setupModel(PassSaleBanner, {});

export class PostEnrollEmailId extends baseModel {
    @propertyMaps('pageId', _.str)
    public pageId;

    @propertyMaps('pageType', _.str)
    public pageType;

    @propertyMaps('sid', _.str)
    public sid;

    @propertyMaps('email', _.str)
    public email;
}
setupModel(PostEnrollEmailId, {});

function transformRating(studentReviews) {
    if(!studentReviews?.reviews.length) {
        return studentReviews;
    }
    let _reviews = studentReviews.reviews;
    _reviews.forEach(review => review['ratingInDecimal'] = review.rating.toFixed(1));
    studentReviews['averageRatingInDecimal'] = studentReviews.averageRating.toFixed(1);
    studentReviews.reviews = _reviews;
    return studentReviews;
}

export class Reviews extends baseModel{
    @propertyMaps('name', _.str)
    public name;
    @propertyMaps('image', _.str)
    public image;
    @propertyMaps('review', _.str)
    public review;
    @propertyMaps('rating', _.num)
    public rating;
}
setupModel(Reviews, {});

export class StudentReviews extends baseModel{
    @propertyMaps('totalStudents', _.num)
    public totalStudents;

    @propertyMaps('totalReviews', _.num)
    public totalReviews;

    @propertyMaps('averageRating', _.num)
    public averageRating;

    @propertyMaps('reviews', arrayModel(Reviews), Reviews)
    public reviews;
}
setupModel(StudentReviews, {});

export class StudentFeedbacks extends baseModel {
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('goalId', _.str)
    public goalId;

    @propertyMaps('studentReviews', pipeModel(objModel(StudentReviews), transformRating), StudentReviews)
    public studentReviews;
}
setupModel(StudentFeedbacks, {});

export class GetAllGoalsApi{
        
    static projection;static apiEndpoint = 'v1/goals';
    static get  __projection(){ 
        if(!GetAllGoalsApi.projection){
            GetAllGoalsApi.projection = JSON.stringify(new SourceProjectionMapper(SimpleGoals).map());
        }
        return GetAllGoalsApi.projection;
    }
    static __fields = new SourceFieldsMapper(SimpleGoal).map().join(',');
    static apiCall(network:Network,params:any){
        params = filterObjectKeys(params, ['type']);
        return network.get(GetAllGoalsApi.apiEndpoint,{fields:GetAllGoalsApi.__fields,__projection:GetAllGoalsApi.__projection,...params});
    }
}

export class GetCategoryWiseGoalsApi{
        
    static projection;static apiEndpoint = 'v1/goals/category';
    static get  __projection(){ 
        if(!GetCategoryWiseGoalsApi.projection){
            GetCategoryWiseGoalsApi.projection = JSON.stringify(new SourceProjectionMapper(CategoryWiseGoals).map());
        }
        return GetCategoryWiseGoalsApi.projection;
    }
    static apiCall(network: Network, params:any){
        return network.get(GetCategoryWiseGoalsApi.apiEndpoint,{...params,__projection: GetCategoryWiseGoalsApi.__projection});
    }
}

export class SearchGoalsApi{
        
    static projection;static apiEndpoint = 'v1/search/global';
    static get  __projection(){ 
        if(!SearchGoalsApi.projection){
            SearchGoalsApi.projection = JSON.stringify(new SourceProjectionMapper(SearchGoals).map());
        }
        return SearchGoalsApi.projection;
    }
    static apiCall(network: Network, params:any){
        return network.get(SearchGoalsApi.apiEndpoint,{...params,__projection: SearchGoalsApi.__projection});
    }
}

export class GetGoalDetailsApi{
    static apiEndpoint = 'v1/goals/{gid}';
    static _apiEndpoint = 'v1/goals/';

    static projection;
    static get  __projection(){ 
        if(!GetGoalDetailsApi.projection){
            GetGoalDetailsApi.projection = JSON.stringify(new SourceProjectionMapper(GoalWrapper).map());
        }
        return GetGoalDetailsApi.projection;
    }

    static apiCall(network:Network,params:any){ //goalId
        return network.get(GetGoalDetailsApi._apiEndpoint+params.id,{__projection:GetGoalDetailsApi.__projection,...params});
    }
}

export class GetGoalDetailsBySlugApi {
    static apiEndpoint = 'v1/goals/{slug}';

    static projection;
    static get  __projection(){ 
        if(!GetGoalDetailsBySlugApi.projection){
            GetGoalDetailsBySlugApi.projection = JSON.stringify(new SourceProjectionMapper(GoalWrapper).map());
        }
        return GetGoalDetailsBySlugApi.projection;
    }

    static apiCall(network: Network, params) {
        return network.get(GetGoalDetailsBySlugApi.apiEndpoint.replace('{slug}',params.slug), {__projection: GetGoalDetailsBySlugApi.__projection });
    }
}

export class GetGoalPitchMapApi {
    static apiEndpoint = 'v1/goals/pitch-mapping';

    static projection;
    static get  __projection(){ 
        if(!GetGoalPitchMapApi.projection){
            GetGoalPitchMapApi.projection = JSON.stringify(new SourceProjectionMapper(PitchMap).map());
        }
        return GetGoalPitchMapApi.projection;
    }

    static apiCall(network: Network, params) {
        return network.get(GetGoalPitchMapApi.apiEndpoint, {__projection: GetGoalPitchMapApi.__projection });
    }
}

export class GetGoalPageBannerApi {
    static apiEndpoint = 'v1/goal-page-banner';

    static apiCall(network: Network) {
        return network.get(GetGoalPageBannerApi.apiEndpoint,{__projection:1},'node');
    }
}

export class GetGlobalPopupBannerApi {
    static apiEndpoint = 'v1/global-popup-banner';

    static apiCall(network: Network) {
        return network.get(GetGlobalPopupBannerApi.apiEndpoint,{__projection:1},'node');
    }
}

export class GetComboGoalPacksApi {
        
    static projection;static apiEndpoint = 'v1/goals/cards';
    static get  __projection(){ 
        if(!GetComboGoalPacksApi.projection){
            GetComboGoalPacksApi.projection = JSON.stringify(new SourceProjectionMapper(ComboGoalPacks).map());
        }
        return GetComboGoalPacksApi.projection;
    }

    static apiCall(network: Network, params) {
        return network.get(GetComboGoalPacksApi.apiEndpoint, {...params, __projection: GetComboGoalPacksApi.__projection });
    }
}

export class GetAllProductsApi{
    static apiEndpoint = 'v1/products';

    static projection;
    static get  __projection(){ 
        if(!GetAllProductsApi.projection){
            GetAllProductsApi.projection = JSON.stringify(new SourceProjectionMapper(AllProductsWrapper).map());
        }
        return GetAllProductsApi.projection;
    }

    static apiCall(network:Network,params:any){
        let queryParams = {pids:params?.productIds?.join(), type:'book'}
        return network.get(GetAllProductsApi.apiEndpoint,{...queryParams, __projection:GetAllProductsApi.__projection});
    }
}
export class GetLiveLessonsApi {
    static apiEndpoint = 'v2.1/classes';

    static projection;
    static get  __projection(){ 
        if(!GetLiveLessonsApi.projection){
            GetLiveLessonsApi.projection = JSON.stringify(new SourceProjectionMapper(AllLiveLessonsWrapper).map());
        }
        return GetLiveLessonsApi.projection;
    }

    static apiCall(network: Network, params: any) {
       return network.get(GetLiveLessonsApi.apiEndpoint, {...params, __projection: GetLiveLessonsApi.__projection});
    }
}

export class PostEmailId {
    static apiEndpoint = 'v1/newsletter';
    static apiCall(network: Network, params) {
        return network.post(PostEmailId.apiEndpoint, params);
    }
}

export class GetStudentReviewsApi {
    static apiEndpoint = 'v1/goals/{goalId}/student-reviews';
    static  __projection = JSON.stringify(new SourceProjectionMapper(StudentFeedbacks).map());
    static apiCall(network: Network, params: any) {
        return network.get(GetStudentReviewsApi.apiEndpoint.replace('{goalId}', params.goalId), {...params.params, __projection: GetStudentReviewsApi.__projection});
    }
}

