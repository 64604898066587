import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CoreModule} from "@core/core.module";
import {PassFeatureComponent} from "@features/pass-feature-component/pass-feature.component";


@NgModule({
    declarations: [PassFeatureComponent],
    imports: [
        CoreModule,
        CommonModule
    ],
    exports: [
        PassFeatureComponent
    ],
})
export class  PassFeatureExportModule{ }
