import { Validator as _ } from "../../../shared/utility/validator";
import { baseModel, CLASS, setupModel } from "../../types";
import { propertyMaps, arrayModel, SourceProjectionMapper, objModel, pipeModel, arrayToMapModel, arrayToMap } from "../../mapping-framework";
import { Network } from '@core/services/model-manager/main';
import { getYoutubeVideoId } from "@shared/utility/tb-common";
import { getDateByFormat, getExactDaysDiff } from "@angular-commons/shared/utility/date-utilities";


const DEFAULT_IMAGE_FEATURE = '/assets/img/select/default-class-image-feature.svg';
const DEFAULT_WHAT_WILL_YOU_GET_IMAGE = '/assets/img/select/default-what-will-you-get.svg';


export class _ClassImageFeature extends baseModel {
    @propertyMaps('image', _.str)
    public image;

    @propertyMaps('title', _.str)
    public title;
}
setupModel(_ClassImageFeature, {});

export class _WhatWillYouGet extends baseModel {
    @propertyMaps('image', _.str)
    public image;

    @propertyMaps('title', _.str)
    public title;
}
setupModel(_WhatWillYouGet, {});


export class _MarketingTag extends baseModel {
    @propertyMaps('color', _.str)
    public color;

    @propertyMaps('title', _.str)
    public title;
}
setupModel(_MarketingTag, {});

export class _MarketingData extends baseModel {
    @propertyMaps('rating', _.str)
    public rating;

    @propertyMaps('skills', _.arr)
    public skills;

    @propertyMaps('tags', arrayModel(_MarketingTag), _MarketingTag)
    public tags;
}
setupModel(_MarketingData, {});
export class _ReviewData extends baseModel {
    @propertyMaps('rating', _.str)
    public rating;

    @propertyMaps()
    public textPrefix;

    @propertyMaps()
    public textSuffix;

    @propertyMaps('images', _.arr)
    public images;

}
setupModel(_ReviewData, {});
export class _Feature extends baseModel {
    @propertyMaps()
    public name;

    @propertyMaps()
    public type;

    @propertyMaps()
    public description;

    @propertyMaps()
    public showInSummary;

    @propertyMaps()
    public count;

    @propertyMaps()
    public title;
}
setupModel(_Feature, {})

export class _Testimonial extends baseModel {
    @propertyMaps()
    public image;

    @propertyMaps()
    public name;

    @propertyMaps()
    public review;

    @propertyMaps()
    public videoUrls;

    @propertyMaps()
    public ratingPercentageString;
}
setupModel(_Testimonial, {})
export class _TargetFamilyModel extends baseModel {
    @propertyMaps()
    public id;

    @propertyMaps()
    public title;

    @propertyMaps()
    public isPrimary;
}
setupModel(_TargetFamilyModel, {})
export class _Slug extends baseModel {
    @propertyMaps('prefix', _.str)
    public prefix;

    @propertyMaps('suffix', _.str)
    public suffix;
}
setupModel(_Slug, {})

export class _pageTitles extends baseModel {
    @propertyMaps('courseTitle', _.str)
    public courseTitle;

    @propertyMaps('techTitle', _.str)
    public techTitle;

    @propertyMaps('salaryInfoTitle', _.str)
    public salaryInfoTitle;

    @propertyMaps('curriculumTitle', _.str)
    public curriculumTitle;

    @propertyMaps('certificateTitle', _.str)
    public certificateTitle;

    @propertyMaps('faqTitle', _.str)
    public faqTitle;

    @propertyMaps('instructorTitle', _.str)
    public instructorTitle;

    @propertyMaps('industryExpertTitle', _.str)
    public industryExpertTitle;

    @propertyMaps('jobReadyTitle', _.str)
    public jobReadyTitle;

    @propertyMaps('demoTitle', _.str)
    public demoTitle;

    @propertyMaps('studentsTitle', _.str)
    public studentsTitle;

    @propertyMaps('overviewTitle', _.str)
    public overviewTitle;

}
setupModel(_pageTitles, {})

export class _pageDescriptions extends baseModel {
    @propertyMaps('techDesc', _.str)
    public techDesc;

    @propertyMaps('courseDesc', _.str)
    public courseDesc;

    @propertyMaps('projectDesc', _.str)
    public projectDesc;

    @propertyMaps('jobReadyDesc', _.str)
    public jobReadyDesc;

    @propertyMaps('certificateDesc', _.str)
    public certificateDesc;
    
    @propertyMaps('demoDesc', _.str)
    public demoDesc;
    
    @propertyMaps('studentsDesc', _.str)
    public studentsDesc;
    
    @propertyMaps('faqDesc', _.str)
    public faqDesc;

    @propertyMaps('salaryInfoDesc', _.str)
    public salaryInfoDesc;

    @propertyMaps('curriculumDesc', _.str)
    public curriculumDesc;

    @propertyMaps('keyHighlightsDesc', _.str)
    public keyHighlightsDesc;
  
}
setupModel(_pageDescriptions, {})

export class _CourseModel extends baseModel {
    @propertyMaps()
    public id;

    @propertyMaps()
    public title;

    @propertyMaps()
    public hasDemoModules;

    @propertyMaps()
    public originalCost;

    @propertyMaps()
    public oldCost;

    @propertyMaps()
    public isCareerProgram;

    @propertyMaps()
    public cost;

    @propertyMaps()
    public productCost;

    @propertyMaps()
    public totalDiscount;

    @propertyMaps()
    public shortDescription;

    @propertyMaps()
    public longDescription;

    @propertyMaps()
    public logo;

    @propertyMaps()
    public isSkillCourse;

    @propertyMaps()
    public source;

    @propertyMaps('classImageFeatures', arrayModel(_ClassImageFeature), _ClassImageFeature)
    public classImageFeatures;

    @propertyMaps('whatWillYouGet', arrayModel(_WhatWillYouGet), _WhatWillYouGet)
    public whatWillYouGet;

    @propertyMaps('marketingTags', objModel(_MarketingData), _MarketingData)
    public marketingTags;

    @propertyMaps('reviewData', objModel(_ReviewData), _ReviewData)
    public reviewData;

    @propertyMaps('faqs', _.obj)
    public faqs;

    @propertyMaps('features', arrayModel(_Feature), _Feature)
    public features;

    @propertyMaps()
    public introVideo;

    @propertyMaps()
    public introVideoId;

    @propertyMaps()
    public testimonialVideo;

    @propertyMaps()
    public introThumnail;

    @propertyMaps('testimonials', arrayModel(_Testimonial), _Testimonial)
    public testimonials;

    @propertyMaps()
    public isOffer;

    @propertyMaps()
    public type;

    @propertyMaps()
    public lastEnrollmentDate;

    @propertyMaps()
    public daysToEnroll;

    @propertyMaps()
    public classFrom;

    @propertyMaps()
    public classTill;

    @propertyMaps()
    public duration;

    @propertyMaps()
    public quantity;

    @propertyMaps()
    public numPurchased;

    @propertyMaps()
    public enrollmentOver;

    @propertyMaps()
    public costUpfront;

    @propertyMaps()
    public startsOnPrefix;

    @propertyMaps()
    public startsOnString;

    @propertyMaps()
    public durationString;

    @propertyMaps()
    public languageString;

    @propertyMaps()
    public languagesTags;

    @propertyMaps()
    public isCoachNotAvailable;

    @propertyMaps()
    public curriculumPdfData;

    @propertyMaps()
    public selectionProofPdfData;

    @propertyMaps('slug', objModel(_Slug), _Slug)
    public slug;

    @propertyMaps()
    public courseSlugUrl;

    @propertyMaps('target', arrayModel(_TargetFamilyModel), _TargetFamilyModel)
    public target;

    @propertyMaps('targetGroup', arrayModel(_TargetFamilyModel), _TargetFamilyModel)
    public targetGroup;

    @propertyMaps('targetSuperGroup', arrayModel(_TargetFamilyModel), _TargetFamilyModel)
    public targetSuperGroup;

    @propertyMaps()
    public seoMeta;

    @propertyMaps()
    public rating;

    @propertyMaps()
    public isPremium;

    @propertyMaps()
    public availTill;

    @propertyMaps()
    public canAvail;

    @propertyMaps()
    public courseLanguage;

    @propertyMaps()
    public instructors;

    @propertyMaps()
    public primaryInstructor;

    @propertyMaps()
    public moreInstructors;

    @propertyMaps()
    public displayFeature;

    @propertyMaps()
    public classInfo;

    @propertyMaps()
    public promotedMedia;

    @propertyMaps()
    public servesFrom;

    @propertyMaps()
    public purchaseInfo;

    @propertyMaps('isGoalCourse',_.bool)
    public isGoalCourse;

    @propertyMaps()
    public promotedMediaVideo;

    @propertyMaps()
    public promotedMediaImages;

    @propertyMaps()
    public showPromotedVideo;

    @propertyMaps()
    public coachingName;

    @propertyMaps()
    public secondaryTextInstructors;

    @propertyMaps()
    public secondaryTextGuestSpeakers;

    @propertyMaps()
    public keyHighlights;

    @propertyMaps()
    public ourPrograms;

    @propertyMaps()
    public cuttingEdgeTechnologies;

    @propertyMaps()
    public certificateData;

    @propertyMaps()
    public projectsTaught;

    @propertyMaps()
    public specialisationInfoImage

    @propertyMaps()
    public canonicalUrl

    @propertyMaps()
    public courseBadge;

    @propertyMaps()
    public courseCardBadgeDetails;

    @propertyMaps()
    public isDelisted;

    @propertyMaps()
    public redirectDetails;

}
setupModel(_CourseModel, { __projection: true, type: CLASS })


export class ClassImageFeature extends baseModel {

    @propertyMaps('image', _.str)
    public image;

    @propertyMaps('title', _.str)
    public title;
}
setupModel(ClassImageFeature, {});

export class WhatWillYouGet extends baseModel {

    @propertyMaps('image', _.str)
    public image;

    @propertyMaps('title', _.str)
    public title;
}
setupModel(WhatWillYouGet, {});

export class MarketingTag extends baseModel {
    @propertyMaps('color', _.str)
    public color;

    @propertyMaps('title', _.str)
    public title;
}
setupModel(MarketingTag, {});
export class MarketingData extends baseModel {
    @propertyMaps('rating', _.str)
    public rating;

    @propertyMaps('learnSkill', _.arr)
    public skills;

    @propertyMaps('extraTags', arrayModel(MarketingTag), MarketingTag)
    public tags;
}
setupModel(MarketingData, {});

export class ReviewData extends baseModel {
    @propertyMaps('reviewText', _.str)
    public text;

    @propertyMaps('reviewImages', _.arr)
    public images;

}
setupModel(ReviewData, {});


export class Feature extends baseModel {
    @propertyMaps('name', _.str)
    public name;

    @propertyMaps('type', _.str)
    public type;

    @propertyMaps('description', _.str)
    public description;

    @propertyMaps('showInSummary', _.bool)
    public showInSummary;

    @propertyMaps('count', _.num)
    public count;

    @propertyMaps('title', _.str)
    public title;
}
setupModel(Feature, {})
export class ClassFeature extends baseModel {
    @propertyMaps('features', arrayModel(Feature), Feature)
    features;
}
setupModel(ClassFeature, {})
export class Testimonial extends baseModel {
    @propertyMaps('image', _.str)
    public image;

    @propertyMaps('name', _.str)
    public name;

    @propertyMaps('review', _.str)
    public review;

    @propertyMaps('rating', _.num)
    public rating;

    @propertyMaps('videoUrls', _.arr)
    public videoUrls;
}
setupModel(Testimonial, {})

export class KeyHighlightsImage extends baseModel {
    @propertyMaps('bannerApp', _.str)
    public image;
}
setupModel(KeyHighlightsImage,{})

export class KeyHighlightsDetails extends baseModel {
    @propertyMaps('name', _.str)
    public name;
    @propertyMaps('title', _.str)
    public title;
    @propertyMaps('description', _.str)
    public description;
    @propertyMaps('image', objModel(KeyHighlightsImage), KeyHighlightsImage)
    public image;
}
setupModel(KeyHighlightsDetails,{})

export class KeyHighlight extends baseModel {
    @propertyMaps('title', _.str)
    public title;
    @propertyMaps('details', arrayModel(KeyHighlightsDetails), KeyHighlightsDetails)
    public details;
}
setupModel(KeyHighlight,{})

export class bannerImg extends baseModel {
    @propertyMaps('app',_.str)
    public image;
}
setupModel(bannerImg,{})

export class Certificate extends baseModel {
    @propertyMaps('details', _.arr)
    public details;
    @propertyMaps('banner', objModel(bannerImg), bannerImg)
    public image;
}
setupModel(Certificate,{})

export class ClassType extends baseModel {
    @propertyMaps('type', _.str)
    public type;

    @propertyMaps('classFrom', _.date)
    public classFrom;

    @propertyMaps('classTill', _.date)
    public classTill;

    @propertyMaps('lastEnrollmentDate', _.date)
    public lastEnrollmentDate;

    @propertyMaps('courseDuration', _.num)
    public courseDuration;

}
setupModel(ClassType, {})

export class Curriculum extends baseModel {

    @propertyMaps('name', _.str)
    public name;

    @propertyMaps('url', _.str)
    public url;
}
setupModel(Curriculum, {})


export class SelectionProof extends baseModel {

    @propertyMaps('name', _.str)
    public name;

    @propertyMaps('url', _.str)
    public url;
}
setupModel(SelectionProof, {})


export class Slug extends baseModel {
    @propertyMaps('prefix', _.str)
    public prefix;

    @propertyMaps('suffix', _.str)
    public suffix;
}
setupModel(Slug, {})

export class RedirectDetails extends baseModel {
    @propertyMaps('courseId', _.str)
    public courseId;

    @propertyMaps('courseTitle', _.str)
    public courseTitle;

    @propertyMaps('reason', _.str)
    public reason;
}
setupModel(RedirectDetails, {})
export class ClassProperties extends baseModel {
    @propertyMaps('classType', objModel(ClassType), ClassType)
    public classType;

    @propertyMaps('languageInfo', _.str)
    public languageInfo;

    @propertyMaps('materialLangInfo', _.str)
    public materialLangInfo;

    @propertyMaps('languagesInfo', _.obj)
    public languagesInfo;

    @propertyMaps('languagesTags', _.obj)
    public languagesTags;

    @propertyMaps('isCoachNotAvailable', _.bool)
    public isCoachNotAvailable;

    @propertyMaps('curriculum', objModel(Curriculum), Curriculum)
    public curriculum;

    @propertyMaps('selectionProof', objModel(SelectionProof), SelectionProof)
    public selectionProof;

    @propertyMaps('slug', objModel(Slug), Slug)
    public slug;

    @propertyMaps('instructors', _.arr)
    public instructors;

    @propertyMaps('secondaryTextInstructors',_.str)
    public secondaryTextInstructors;

    @propertyMaps('secondaryTextGuestSpeakers',_.str)
    public secondaryTextGuestSpeakers;

    @propertyMaps('courseBadge')
    public courseBadge;

    @propertyMaps('courseCardBadgeDetails')
    public courseCardBadgeDetails;

    @propertyMaps('redirect',objModel(RedirectDetails),RedirectDetails)
    public redirectDetails;
}
setupModel(ClassProperties, {})

export class ClassInfo extends baseModel {
    @propertyMaps('classImageFeature', pipeModel(processImageFeatures, arrayModel(ClassImageFeature)), ClassImageFeature)
    public classImageFeature;

    @propertyMaps('whatWillYouGet', pipeModel(processWhatWillYouGet, arrayModel(WhatWillYouGet)), WhatWillYouGet)
    public whatWillYouGet;

    @propertyMaps('marketingTags', objModel(MarketingData), MarketingData)
    public marketingTags;

    @propertyMaps('reviewText', _.str)
    public reviewText;

    @propertyMaps('specialisationInfoImage', _.str)
    public specialisationInfoImage;

    @propertyMaps('reviewImages', _.arr)
    public reviewImages;

    @propertyMaps('promotedMedia', _.arr)
    public promotedMedia;

    @propertyMaps('faqs', pipeModel(_.arr, transformFaqs))
    public faqs;

    @propertyMaps('classFeature', objModel(ClassFeature), ClassFeature)
    public classFeature;

    @propertyMaps('introVideoUrl', _.str)
    public introUrl;

    @propertyMaps('testimonials', arrayModel(Testimonial), Testimonial)
    public testimonials;

    @propertyMaps('keyHighlights', objModel(KeyHighlight), KeyHighlight)
    public keyHighlights;

    @propertyMaps('features', objModel(KeyHighlight), KeyHighlight)
    public ourPrograms;

    @propertyMaps('technologiesTaught', objModel(KeyHighlight), KeyHighlight)
    public cuttingEdgeTechnologies;

    @propertyMaps('projectsTaught', objModel(KeyHighlight), KeyHighlight)
    public projectsTaught;

    @propertyMaps('certificate', arrayModel(Certificate), Certificate)
    public certificateData;

}
setupModel(ClassInfo, {})
export class _OurPrograms extends baseModel {
    @propertyMaps('features', objModel(KeyHighlight), KeyHighlight)
    public ourPrograms;
}
setupModel(_OurPrograms, {});
export class _TechnologiesTaught extends baseModel {
    @propertyMaps('technologiesTaught', objModel(KeyHighlight), KeyHighlight)
    public cuttingEdgeTechnologies;
}
setupModel(_TechnologiesTaught, {});

export class _KeyHighlights extends baseModel {
    @propertyMaps('title', _.str)
    public title;
    @propertyMaps('details', arrayModel(KeyHighlightsDetails), KeyHighlightsDetails)
    public details;
}
setupModel(_KeyHighlights,{})


export class _ProjectsTaught extends baseModel {
    @propertyMaps('projectsTaught', objModel(KeyHighlight), KeyHighlight)
    public projectsTaught;
}
setupModel(_ProjectsTaught, {});

export class _CertificateData extends baseModel {
    @propertyMaps('certificate', arrayModel(Certificate), Certificate)
    public certificateData;
}
setupModel(_CertificateData, {});

export class TargetFamilyModel extends baseModel {
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('title', _.str)
    public title;

    @propertyMaps('isPrimary', _.str)
    public isPrimary;
}
setupModel(TargetFamilyModel, {})
export class CourseModel extends baseModel {
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('title', _.str)
    public title;

    @propertyMaps('oldCost', _.num)
    public oldCost;

    @propertyMaps('isCareerProgram', _.bool)
    public isCareerProgram;

    @propertyMaps('isDemoModuleAvail', _.bool)
    public hasDemoModules;

    @propertyMaps('cost', _.num)
    public cost;

    @propertyMaps('originalCost',_.num)
    public productCost;

    @propertyMaps('totalDiscount',_.num)
    public totalDiscount;

    @propertyMaps('servesFrom', _.arr)
    public servesFrom;

    @propertyMaps('purchaseInfo', _.arr)
    public purchaseInfo;

    @propertyMaps('isOffer', _.bool)
    public isOffer;

    @propertyMaps('titles', _.str)
    public titles;

    @propertyMaps('shortDesc', _.str)
    public shortDescription;

    @propertyMaps('description', _.str)
    public description;

    @propertyMaps('descriptions', _.str)
    public descriptions;

    @propertyMaps('courseLogo', _.str)
    public logo;

    @propertyMaps('classInfo', objModel(ClassInfo), ClassInfo)
    public classInfo;

    @propertyMaps('classProperties', objModel(ClassProperties), ClassProperties)
    public classProperties;

    @propertyMaps('isSkillCourse', _.bool)
    public isSkillCourse;

    @propertyMaps('source', _.str)
    public source;

    @propertyMaps('quantity', _.num)
    public quantity;

    @propertyMaps('numPurchased', _.num)
    public numPurchased;

    @propertyMaps('costUpfront', _.bool)
    public costUpfront;

    @propertyMaps('target', arrayModel(TargetFamilyModel), TargetFamilyModel)
    public target;

    @propertyMaps('targetGroup', arrayModel(TargetFamilyModel), TargetFamilyModel)
    public targetGroup;

    @propertyMaps('targetSuperGroup', arrayModel(TargetFamilyModel), TargetFamilyModel)
    public targetSuperGroup;

    @propertyMaps('meta', _.obj)
    public seoMeta;

    @propertyMaps('summary', _.obj)
    public summary;

    @propertyMaps('isPremium', _.bool)
    public isPremium;

    @propertyMaps("availTill", _.str)
    public availTill;

    @propertyMaps("coachingName", _.str)
    public coachingName;

    @propertyMaps("canonicalURL", _.str)
    public canonicalUrl;

    @propertyMaps("isDelisted", _.bool)
    public isDelisted;

}
setupModel(CourseModel, {})

function transformFaqs(faqs) {
    if(!faqs) return
    let languageFaqsMap = {}
    faqs.map((faq) => faq.map(({ language, question, answer }) => {
        let lang = language.toLowerCase();
        if (!languageFaqsMap[lang] || languageFaqsMap[lang].length === 0) {
            languageFaqsMap[lang] = [];
        }
        languageFaqsMap[lang].push({
            question,
            answer
        })
    }))

    Object.keys(languageFaqsMap).forEach(function(lang){
        if (!languageFaqsMap[lang] || languageFaqsMap[lang].length === 0 ||
            languageFaqsMap[lang][0].question.length === 0 || languageFaqsMap[lang][0].answer.length === 0) {
            languageFaqsMap[lang] = languageFaqsMap['english'];
        }
    });

    return languageFaqsMap;
}
function processWhatWillYouGet(features) {
    if (!features) {
        return;
    }
    return features.map(feature => {
        if (!feature.image || feature.image.length === 0) {
            feature.image = DEFAULT_WHAT_WILL_YOU_GET_IMAGE;
        }
        return feature;
    })
}

function processImageFeatures(features) {
    if (!features) {
        return;
    }
    return features.map(feature => {
        if (!feature.image || feature.image.length === 0) {
            feature.image = DEFAULT_IMAGE_FEATURE;
        }
        return feature;
    })
}

export class _Offer extends baseModel {
    @propertyMaps()
    public discountType;

    @propertyMaps()
    public offerDisplayText;

    @propertyMaps()
    public offerDisplayTextAlias;

    @propertyMaps()
    public discountText;

    @propertyMaps()
    public startTime;

    @propertyMaps()
    public endTime;
}
setupModel(_Offer, {});

export class _Instructor extends baseModel {
    @propertyMaps()
    public id;

    @propertyMaps()
    public name;

    @propertyMaps()
    public shortBio;

    @propertyMaps()
    public longBio;

    @propertyMaps()
    public photo;

    @propertyMaps()
    public videoUrls;

    @propertyMaps()
    public isGuestSpeaker;

    @propertyMaps()
    public isInstructor;
}
setupModel(_Instructor, {});

export class _CourseProduct extends baseModel {

    @propertyMaps('course', objModel(_CourseModel), _CourseModel)
    public course;

    @propertyMaps('offers', objModel(_Offer), _Offer)
    public offers;

    @propertyMaps()
    public labels;

    @propertyMaps()
    public instructors;

    @propertyMaps()
    public hasPurchased;

    @propertyMaps()
    public hasEnrolled;

    @propertyMaps()
    public hasUnEnrolled;

    @propertyMaps()
    public availTill;

    @propertyMaps()
    public canAvail;

    @propertyMaps('guestSpeakers', _.arr)
    public guestSpeakers;

    @propertyMaps('testbookInstructors', _.arr)
    public testbookInstructors;
}

setupModel(_CourseProduct, { type: 'classes' },transformCourseProduct);

function transformCourseProduct(_items){
    let item = {..._items};
    let guestSpeakers=[];
    let testbookInstructors=[];
    let instructors = {...item?.instructors};

    item?.course?.instructors?.forEach(instructor => {
        instructors[instructor.id] = {...instructors[instructor.id], ...item[instructor.id]};
        if (instructor?.isGuestSpeaker && instructors[instructor.id]) {
            instructors[instructor.id].isGuestSpeaker = true;
            guestSpeakers.push(item.instructors[instructor.id]);
        }
        if (instructor?.isInstructor && instructors[instructor.id]){
            instructors[instructor.id].isInstructor = true;
            testbookInstructors.push(item.instructors[instructor.id]);
        }
    });
    item.guestSpeakers = guestSpeakers;
    item.testbookInstructors = testbookInstructors;
    item.instructors = instructors;
    return item;
}

export class Deal extends baseModel {
    @propertyMaps('discountType', _.str)
    public discountType;

    @propertyMaps('discountValue', _.str)
    public discountValue;

    @propertyMaps('startTime', _.date)
    public startTime;

    @propertyMaps('endTime', _.date)
    public endTime;
}
setupModel(Deal, {});
export class Offer extends baseModel {
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('offerType', _.str)
    public offerType;

    @propertyMaps('offerDisplayText', _.str)
    public offerDisplayText;

    @propertyMaps('offerDisplayTextAlias', _.str)
    public offerDisplayTextAlias;

    @propertyMaps('offerStartTime', _.date)
    public offerStartTime;

    @propertyMaps('offerEndTime', _.date)
    public offerEndTime;

    @propertyMaps('lightningDeal', objModel(Deal), Deal)
    public lightningDeal;

    @propertyMaps('normalDeal', objModel(Deal), Deal)
    public normalDeal;

}
setupModel(Offer, {});

export class Instructor extends baseModel {
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('name', _.str)
    public name;

    @propertyMaps('shortBio', _.str)
    public shortBio;

    @propertyMaps('companyLogo', _.str)
    public companyLogo;

    @propertyMaps('linkedInProfile', _.str)
    public linkedInProfile;

    @propertyMaps('fullBio', _.str)
    public longBio;

    @propertyMaps('photo', _.str)
    public photo;

    @propertyMaps('videoUrls', _.arr)
    public videoUrls;
}
setupModel(Instructor, {});
export class CourseProduct extends baseModel {

    @propertyMaps('product', pipeModel(objModel(CourseModel), processCourse), CourseModel)
    public course;

    @propertyMaps('offers', pipeModel(objModel(Offer), processOffer), Offer)
    public offers;

    @propertyMaps('labels', _.obj)
    public labels;

    @propertyMaps('instructors', pipeModel(mapToArray, arrayModel(Instructor),arrayToMap('id')))
    public instructors;

    @propertyMaps('hasPurchased', _.bool)
    public hasPurchased;

    @propertyMaps('hasUnEnrolled', _.bool)
    public hasUnEnrolled;

    @propertyMaps('hasEnrolled', _.bool)
    public hasEnrolled
}
setupModel(CourseProduct, {});

export class classesSkillSeoData extends baseModel {

    @propertyMaps('pageTitles',objModel(_pageTitles),_pageTitles )
    public pageTitles;

    @propertyMaps('pageDescriptions',objModel(_pageDescriptions),_pageDescriptions )
    public pageDescriptions;

}
setupModel(classesSkillSeoData, {});

export class classes extends baseModel {
    @propertyMaps('classes',objModel(classesSkillSeoData),objModel(classesSkillSeoData) )
    public classes;
}
setupModel(classes, {});

function mapToArray(mapObj) {
    return Object.values(mapObj);
}
function processOffer(offer: Offer) {
    const OFFER_TYPE_LIGHTNING_DEAL = 'lightningDeal';
    const OFFER_TYPE_BOTH = 'both';
    const OFFER_TYPE_NORMAL = 'normalDeal';
    var processedOffer = new _Offer();
    const now = Date.now();
    processedOffer.offerDisplayText=offer.offerDisplayText;
    processedOffer.offerDisplayTextAlias=offer.offerDisplayTextAlias;
    offer.lightningDeal.startTime = offer.lightningDeal.startTime;
    offer.lightningDeal.endTime = offer.lightningDeal.endTime;
    offer.normalDeal.startTime = offer.normalDeal.startTime;
    offer.normalDeal.endTime = offer.normalDeal.endTime;

    if(offer.offerType === OFFER_TYPE_BOTH){
            if(offer.lightningDeal.startTime.getTime() <= now && offer.lightningDeal.endTime.getTime() >= now){
                    offer.offerType = OFFER_TYPE_LIGHTNING_DEAL;
            } else if(offer.normalDeal.startTime.getTime() <= now && offer.normalDeal.endTime.getTime() >= now){
                    offer.offerType = OFFER_TYPE_NORMAL;
            }
    }
    var deal = new Deal();

    if (offer.offerType === OFFER_TYPE_LIGHTNING_DEAL) {
        deal = offer.lightningDeal;
    } else if (offer.offerType === OFFER_TYPE_NORMAL) {
        deal = offer.normalDeal;
    }

    processedOffer.startTime = deal.startTime;
    processedOffer.endTime = deal.endTime;

    if (deal.discountType === 'percent') {
        processedOffer.discountText = `Extra ${deal.discountValue}% off`;
    } else {
        processedOffer.discountText = `Extra Rs.${deal.discountValue} off`;

    }
    return processedOffer;
}

export function processCourse(course: CourseModel) {

    const DEFAULT_THUMBNAIL = 'https://testbook.com/assets/img/select/selection-video-thumbnail.jpg';
    const currTime = new Date();
    var videoId = getYoutubeVideoId(course.classInfo.introUrl);
    var thumbnailUrl = videoId ? 'https://i.ytimg.com/vi/' + videoId + '/hqdefault.jpg' : course.logo.replace("(", "%28").replace(")", "%29");
    let processedCourse = new _CourseModel();

    processedCourse.id = course.id;
    processedCourse.title = course.title || course.titles;
    processedCourse.shortDescription = course.shortDescription;
    processedCourse.longDescription = course.descriptions || course.description;
    processedCourse.logo = course.logo.replace("(", "%28").replace(")", "%29");
    processedCourse.introVideo = course.classInfo.introUrl;
    processedCourse.introVideoId = videoId;
    processedCourse.introThumnail = thumbnailUrl || DEFAULT_THUMBNAIL;
    processedCourse.ourPrograms = course.classInfo.ourPrograms;
    processedCourse.projectsTaught = course.classInfo.projectsTaught;
    processedCourse.keyHighlights = course.classInfo.keyHighlights;
    processedCourse.certificateData = course.classInfo.certificateData;
    processedCourse.cuttingEdgeTechnologies = course.classInfo.cuttingEdgeTechnologies;
    processedCourse.features = course.classInfo.classFeature.features.filter(feature => course.servesFrom.includes("goalSubs") || ["Live Class", "Test", "Notes", "Questions"].includes(feature.type));
    processedCourse.displayFeature = course.classInfo.classFeature.features.filter(feature => {
     return !!feature.showInSummary;
    });
    processedCourse.faqs = course.classInfo.faqs;
    processedCourse.hasDemoModules = course.hasDemoModules;
    processedCourse.testimonials = (course.classInfo.testimonials).map(testimonial => {
        testimonial.ratingPercentageString = testimonial.rating * 20 + '%';
        return testimonial;
    });
    processedCourse.whatWillYouGet = course.classInfo.whatWillYouGet;
    processedCourse.specialisationInfoImage = course.classInfo.specialisationInfoImage;
    processedCourse.classImageFeatures = course.classInfo.classImageFeature;
    processedCourse.marketingTags = course.classInfo.marketingTags;
    processedCourse.reviewData = {
        rating: course.classInfo.marketingTags.rating,
        textPrefix: course.classInfo.reviewText.split(' ').slice(0, 2).join(' '),
        textSuffix: course.classInfo.reviewText.split(' ').slice(2).join(' '),
        images: course.classInfo.reviewImages
    };

    processedCourse.servesFrom = course.servesFrom;
    processedCourse.purchaseInfo = course.purchaseInfo;
    processedCourse.promotedMedia = course.classInfo.promotedMedia;
    processedCourse.promotedMediaVideo=course.classInfo.promotedMedia.filter((ele)=>ele.type === 'video')
    processedCourse.promotedMediaImages=course.classInfo.promotedMedia.filter((ele)=>ele.type === 'image')
    if(processedCourse.promotedMediaVideo.length){
        processedCourse.showPromotedVideo=true;
    }
    processedCourse.isGoalCourse = processedCourse.servesFrom.includes("goalSubs");
    processedCourse.coachingName=course.coachingName
    processedCourse.isSkillCourse = course.isSkillCourse;
    processedCourse.source = course.source;
    processedCourse.oldCost = course.oldCost;
    processedCourse.isCareerProgram=course.isCareerProgram;
    processedCourse.originalCost = course.cost;
    processedCourse.cost = course.cost;
    processedCourse.productCost = course.productCost;
    processedCourse.totalDiscount = course.totalDiscount;
    processedCourse.isOffer = course.isOffer;
    processedCourse.seoMeta = course.seoMeta;
    const currentDate = new Date();
    const asciiValue = processedCourse.id ? processedCourse.id.charCodeAt(23) : 65;
    const divisor = (currentDate.getDate()*asciiValue) % 4 ? 4 : 3;
    let fallbackRating = (4.5 + Math.ceil(((currentDate.getDate()*asciiValue)%divisor))/10).toFixed(1);
    fallbackRating = Number(fallbackRating) > 4.8 ? '4.8' : fallbackRating;
    processedCourse.rating  = course.summary && course.summary.rating && course.summary.rating.value || fallbackRating;
    processedCourse.rating = Number(processedCourse.rating).toFixed(1);
    processedCourse.type = course?.classProperties?.classType?.type || '';
    processedCourse.secondaryTextGuestSpeakers = course?.classProperties?.secondaryTextGuestSpeakers || '';
    processedCourse.secondaryTextInstructors = course?.classProperties?.secondaryTextInstructors || '';
    processedCourse.lastEnrollmentDate = (new Date(course?.classProperties?.classType?.lastEnrollmentDate));
    processedCourse.daysToEnroll = getExactDaysDiff(processedCourse?.lastEnrollmentDate, currTime);
    processedCourse.classFrom = (new Date(course?.classProperties?.classType?.classFrom));
    processedCourse.classTill = (new Date(course?.classProperties?.classType?.classTill));
    processedCourse.duration = course?.classProperties?.classType?.courseDuration ? getExactDaysDiff(course?.classProperties?.classType?.courseDuration / 1000000,0) : getExactDaysDiff(processedCourse?.classTill, processedCourse?.classFrom); 
    processedCourse.durationString = processedCourse?.duration + ' Day' + (processedCourse?.duration > 1 ? 's' : '');
    processedCourse.quantity = course.quantity;
    processedCourse.numPurchased = course.numPurchased;
    processedCourse.isPremium = course.isPremium;
    processedCourse.costUpfront = course.costUpfront;
    processedCourse.enrollmentOver = (processedCourse.daysToEnroll <= 0) || (!(processedCourse.isSkillCourse || processedCourse.source == 'plutus') && ((processedCourse.quantity > -1) && (processedCourse.quantity <= processedCourse.numPurchased)));
    processedCourse.startsOnPrefix = processedCourse.classFrom < currTime ? 'Started on' : 'Starts on';
    processedCourse.startsOnString = getDateByFormat(processedCourse.classFrom, 'short', 'long');
    if (processedCourse.enrollmentOver) {
        processedCourse.startsOnPrefix = 'Batch closed on';
        processedCourse.startsOnString = getDateByFormat(processedCourse.lastEnrollmentDate, 'short', 'long')
    }
    processedCourse.languageString = course.classProperties.languagesInfo || course.classProperties.languageInfo;
    processedCourse.courseLanguage = course.classProperties.materialLangInfo;
    processedCourse.isCoachNotAvailable = course.classProperties.isCoachNotAvailable;
    processedCourse.curriculumPdfData = {
        name: course.classProperties.curriculum.name,
        url: course.classProperties.curriculum.url
    }
    processedCourse.selectionProofPdfData = {
        name: course.classProperties.selectionProof.name,
        url: course.classProperties.selectionProof.url
    }
    processedCourse.slug = {
        prefix: course.classProperties.slug.prefix,
        suffix: course.classProperties.slug.suffix
    }
    processedCourse.languagesTags = course.classProperties.languagesTags;
    if((course.isSkillCourse || course.source == 'plutus')  && ['career-program','course'].includes(course.classProperties.slug.suffix)){
        processedCourse.courseSlugUrl = `/${course.classProperties.slug.prefix}-${course.classProperties.slug.suffix}`;
    }else{
        processedCourse.courseSlugUrl = `/${course.classProperties.slug.prefix}/online-coaching-${course.classProperties.slug.suffix}`;
    }
    processedCourse.instructors = course.classProperties.instructors;
    let instructors = processedCourse.instructors.map(instructor => instructor.name);
    processedCourse.primaryInstructor = getPrimaryInstructors(instructors);
    processedCourse.moreInstructors = getMoreinstructors(instructors);
    processedCourse.target = course.target;
    processedCourse.targetGroup = course.targetGroup;
    processedCourse.targetSuperGroup = course.targetSuperGroup;
    processedCourse.availTill = course.availTill;
    processedCourse.canonicalUrl = course.canonicalUrl;
    processedCourse.courseBadge = course?.classProperties?.courseBadge || 'none';
    processedCourse.courseCardBadgeDetails = course?.classProperties?.courseCardBadgeDetails || {courseCardTag: "",textColor:"#FFFFFF",backgroundColor:"#F04354"};
    processedCourse.isDelisted = !!course?.isDelisted;
    processedCourse.redirectDetails = course?.classProperties?.redirectDetails;
    return processedCourse;

}

export function getPrimaryInstructors(instructors) {
    let primaryInstructors = [];
    if (instructors.length > 1) {
        primaryInstructors = instructors.slice(0, 2);
    }
    if (instructors.length === 1) {
        primaryInstructors = instructors.slice(0, 1);
    }
    return (primaryInstructors.length && primaryInstructors.join(', ')) || ''
}


export function getMoreinstructors(instructors) {
    let moreInstructors = [];
    if (instructors.length > 2) {
        moreInstructors = instructors.splice(2);
        return {name: moreInstructors.join(', '), count: moreInstructors.length};
    }
    return '';
}

export function transformProduct(product: _CourseProduct) {
    if (!product || !product.course || product.course.id.length === 0) {
        return {};
    }
    const currTime = new Date()
    let transformedProduct: Course
    transformedProduct = {
        ...product.course,
    }
    if (product?.guestSpeakers) {
        transformedProduct.guestSpeakers = product.guestSpeakers;
    }
    if (product?.testbookInstructors) {
        transformedProduct.testbookInstructors = product.testbookInstructors;
    }

    if (transformedProduct?.isOffer) {
        transformedProduct.offers = product.offers;
    }
    transformedProduct.labels = {
        text: _.str(product?.labels[transformedProduct.id]?.label),
        condition: _.str(product?.labels[transformedProduct.id]?.condition)
    }
    if(product.course.instructors){
        transformedProduct.instructors = product.course.instructors.map(value => product.instructors[value.id]);
    }else{
        transformedProduct.instructors = Object.values(product.instructors);
    }
    transformedProduct.hasPurchased = product.hasPurchased;
    transformedProduct.hasUnEnrolled = product.hasUnEnrolled;
    transformedProduct.hasEnrolled = product.hasEnrolled;
    const availTillDate = new Date(product.course.availTill);
    transformedProduct.canAvail = availTillDate.getTime() >= currTime.getTime();
    return transformedProduct;
}

interface Label {
    text: string,
    condition: string
}
export interface Course {
    id: string;
    title: string;
    courseLanguage:string;
    hasDemoModules: boolean;
    originalCost: number;
    oldCost: number;
    isCareerProgram: boolean;
    cost: number;
    productCost:number;
    totalDiscount:number;
    shortDescription: string;
    longDescription: string;
    logo: string;
    isSkillCourse: boolean;
    source:string;
    classImageFeatures: _ClassImageFeature[]
    specialisationInfoImage:any
    whatWillYouGet: _WhatWillYouGet[]
    marketingTags: _MarketingData
    reviewData: _ReviewData
    faqs: any
    features: _Feature[];
    introVideo: string;
    introVideoId: string;
    introThumnail: string;
    testimonials: _Testimonial[]
    isOffer: boolean;
    type: string;
    lastEnrollmentDate: Date;
    daysToEnroll: Date;
    classFrom: Date;
    classTill: Date;
    duration: Number;
    quantity: Number
    numPurchased: Number;
    enrollmentOver: boolean;
    costUpfront: boolean;
    startsOnPrefix: string;
    startsOnString: string;
    durationString: string;
    languageString: string;
    languagesTags: any;
    isCoachNotAvailable: boolean;
    curriculumPdfData : {name: string, url: string};
    selectionProofPdfData : {name: string, url: string};
    slug: _Slug;
    courseSlugUrl: string;
    target: _TargetFamilyModel[];
    targetGroup: _TargetFamilyModel[];
    targetSuperGroup: _TargetFamilyModel[];
    seoMeta: any;
    rating: string;
    offers: _Offer;
    labels: Label;
    instructors: _Instructor[];
    primaryInstructor: string ;
    moreInstructors: any;
    secondaryTextInstructors: string;
    secondaryTextGuestSpeakers: string;
    hasPurchased: boolean;
    hasUnEnrolled: boolean;
    hasEnrolled: boolean;
    isPremium: boolean;
    canAvail: boolean;
    promotedMedia : any;
    servesFrom: any;
    purchaseInfo: any
    isGoalCourse : boolean;
    promotedMediaVideo:any;
    promotedMediaImages:any;
    showPromotedVideo:boolean;
    coachingName:string;
    testbookInstructors:any;
    guestSpeakers:any;
    classInfo:any;
    canonicalUrl:any;
    ourPrograms:_OurPrograms;
    cuttingEdgeTechnologies:_TechnologiesTaught;
    projectsTaught:_ProjectsTaught;
    certificateData:_CertificateData;
    keyHighlights:_KeyHighlights;
    courseBadge: string;
    courseCardBadgeDetails: any;
    isDelisted:boolean;
    redirectDetails:any;
}

export type GetCourseParams = {
    prefix?: string,
    suffix?: string
}
export type GetClassesParams = {
    prefix?: string,
    suffix?: string,
    slug?:boolean,
    isSkillCourse?:boolean,
}

export enum PDF_TYPE{
    SELECTIONS_PDF="selectionsPdf"
}

export class GetCourseApi{
    static apiEndpoint = 'v2_1/products/slug';

    static projection;
    static get  __projection(){ 
        if(!GetCourseApi.projection){
            GetCourseApi.projection = JSON.stringify(new SourceProjectionMapper(CourseProduct).map());
        }
        return GetCourseApi.projection
    }

    static apiCall(network: Network, params: GetCourseParams) {
        return network.get(GetCourseApi.apiEndpoint, { ...params, __projection: GetCourseApi.__projection });
    }
}

export class setSelectionsProofLeadApi {
    static apiEndpoint = 'v1/download-curriculum/{courseId}';

    static  __projection = 1;

    static apiCall(network:Network,params:any){
        return network.post(setSelectionsProofLeadApi.apiEndpoint.replace('{courseId}',params.courseId),{},{__projection:setSelectionsProofLeadApi.__projection,pdfType:params.pdfType});
    }
}

export class GetClassesApi{
    static apiEndpoint = 'v2.1/classes';

    static projection;
    static get  __projection(){ 
        if(!GetClassesApi.projection){
            GetClassesApi.projection = JSON.stringify(new SourceProjectionMapper(classes).map());
        }
        return GetClassesApi.projection;
    }
    static apiCall(network: Network, params: GetClassesParams) {
        return network.get(GetClassesApi.apiEndpoint, { ...params, __projection: GetClassesApi.__projection });
    }
}