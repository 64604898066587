import { Validator as _ } from "../../../shared/utility/validator";
import { baseModel ,setupModel,  } from "../../types";
import {
    propertyMaps,
    arrayModel,
    SourceProjectionMapper,
    pipeModel,
    arrayToMap,
    wrapInObj,
    objModel
} from "../../mapping-framework";
import { Network } from '../../../core/services/model-manager/main';
import {NS_IN_A_DAY} from "@shared/utility/date-utilities";
import {getNonNullParams} from "@shared/utility/tb-common";


export class Medium extends baseModel{
    @propertyMaps('type',_.str)
    public type;
}
setupModel(Medium, {});

export class Coupon extends baseModel{
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('type', _.str)
    public type;

    @propertyMaps('code', _.str)
    public code;

    @propertyMaps('expiresOn', _.date)
    public expiresOn;

    @propertyMaps('shortDesc', _.str)
    public shortDesc;

    @propertyMaps('longDesc', _.str)
    public longDesc;

    @propertyMaps('image', _.str)
    public image;

    @propertyMaps('', _.obj)
    public timer;

    @propertyMaps('discountValue',_.num)
    public discountValue

    @propertyMaps('discountType',_.str)
    public discountType

    @propertyMaps('medium', objModel(Medium), Medium)
    public medium;
}
setupModel(Coupon,{type: 'coupon'});

export function addDiscountText(coupon){
    coupon.isPassDays = coupon.discountType === 'passDays';
    coupon.discountUnitValue = "";
    coupon.discountUnit = "";
    if(coupon.isPassDays){
        let days = coupon.discountValue / NS_IN_A_DAY;
        let months = days / 30;
        let years = months / 12;
        if(years >= 1){
            coupon.discountUnitValue = Math.round(years);
            coupon.discountUnit = years==1?`Year`:`Years`;
        }
        else if(months >= 1){
            coupon.discountUnitValue = Math.round(months);
            coupon.discountUnit = months==1?`Month`:`Months`;
        }
        else {
            coupon.discountUnitValue = Math.round(days);
            coupon.discountUnit = days==1?`Day`:`Days`;
        }
    }
    return coupon;
}

function addDiscountTextToCoupons(coupons){
    return coupons.map(addDiscountText);
}

export class CouponsArray extends baseModel{
    @propertyMaps('coupons', pipeModel(arrayModel(Coupon),addDiscountTextToCoupons), Coupon)
    public coupons;
}
setupModel(CouponsArray,{});

export class _CouponProduct extends baseModel{
    @propertyMaps()
    public id;

    @propertyMaps()
    public cost;

    @propertyMaps()
    public oldCost;

}
setupModel(_CouponProduct,{});

export class Emi extends baseModel {
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('split', _.num)
    public splits;

    @propertyMaps('totalAmount', _.num)
    public totalAmount;

    @propertyMaps('mode', _.str)
    public mode;

    @propertyMaps('payments')
    public payments;
}
setupModel(Emi, {});


export class BaseCouponProduct extends baseModel{
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('cost', _.num)
    public cost;

    @propertyMaps('oldCost', _.num)
    public oldCost;

    @propertyMaps('emis', arrayModel(Emi), Emi)
    public emis;
}
setupModel(BaseCouponProduct,{});


export class _CouponProductsArray extends baseModel{
    @propertyMaps('coupon', pipeModel(objModel(Coupon),addDiscountText), Coupon)
    public coupon;

    @propertyMaps('products',pipeModel( arrayModel(BaseCouponProduct), arrayToMap('id')),BaseCouponProduct)
    public products;
}
setupModel(_CouponProductsArray,{});

export class _CouponCTAData extends baseModel{
    @propertyMaps('offersText', _.str)
    public offersText;

    @propertyMaps('viewOffersCTA', _.str)
    public viewOffersCTA;

    @propertyMaps('moreOffersCTA', _.num)
    public moreOffersCTA;

}
setupModel(_CouponCTAData, {});

export class CouponCTAData extends baseModel{
    @propertyMaps('offersText', _.str)
    public offersText;

    @propertyMaps('viewOffersCTA', _.str)
    public viewOffersCTA;

    @propertyMaps('moreOffersCTA', _.num)
    public moreOffersCTA;

}
setupModel(CouponCTAData, {});

export class StudentCouponsApi{

    static apiEndpoint = 'v2/student-coupons';

    static projection;
    static get  __projection(){ 
        if(!StudentCouponsApi.projection){
            StudentCouponsApi.projection = JSON.stringify(new SourceProjectionMapper(CouponsArray).map());
        }
        return StudentCouponsApi.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(StudentCouponsApi.apiEndpoint,{prodIds: params.prodIds.join(','), __projection:StudentCouponsApi.__projection});
    }

}

export class CouponProductsApi{

    static apiEndpoint = 'v2.1/students/coupon';

    static projection;
    static get  __projection(){ 
        if(!CouponProductsApi.projection){
            return CouponProductsApi.projection = JSON.stringify(new SourceProjectionMapper(_CouponProductsArray).map());
        }
        return CouponProductsApi.projection;
    }

    static apiCall(network:Network,params:any){
        let paramsObj=getNonNullParams(params)
        return network.get(CouponProductsApi.apiEndpoint,{...paramsObj, __projection:CouponProductsApi.__projection});
    }

}

export class CouponCTADataApi{

    static apiEndpoint = 'v1/coupon-cta-data';

    static projection;
    static get  __projection(){ 
        if(!CouponCTADataApi.projection){
            CouponCTADataApi.projection = JSON.stringify(new SourceProjectionMapper(CouponCTAData).map());
        }
        return CouponCTADataApi.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(CouponCTADataApi.apiEndpoint,{__projection:CouponCTADataApi.__projection}, 'node');
    }

}


export interface CouponDataObject {
    id?: string,
    code?: string,
    state: string,
    cost?:number,
    oldCost?: number,
    
}

