import {baseModel, setupModel} from "@models/types";
import {propertyMaps} from "@models/mapping-framework";
import {Validator as _} from "@shared/utility/validator";

export class PurchaseInfo extends baseModel{
    @propertyMaps('type', _.str)
    public type;

    @propertyMaps('id', _.str)
    public id;

    @propertyMaps('showInPitch', _.bool)
    public showInPitch;
}
setupModel(PurchaseInfo, {type: 'PurchaseInfo'})
