import {Injectable} from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    ActivatedRoute,
    UrlTree
} from '@angular/router';
import {Observable, of} from "rxjs";
import {PlatformService} from "@core/services/platform-service";
import { HOME } from '@angular-commons/shared/utility/constants';
import { Store } from '@ngrx/store';


@Injectable()
export class HomeGuard implements CanActivate {

    constructor(private router:Router,private route: ActivatedRoute,private platformService: PlatformService,private store: Store<{}>) {
    }

    canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean|UrlTree> {

        let pathName = encodeURI(this.platformService.getPathName());

        let canNavigateToHome = this.platformService.isLoggedIn() && pathName.endsWith(HOME);
        if(!canNavigateToHome){
            let url=`/login?redirect_url=`+encodeURI(state.url)
            this.platformService.redirect(url,302);
            return of(false);
        }else{
            return of(true);
        }
    }

}