import { NgModule, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestCallbackComponent } from './features/request-callback/request-callback.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { BookCounsellingSessionComponent } from './components/book-counselling-session/book-counselling-session.component';
import { AssistanceAboutCourseComponent } from './components/assistance-about-course/assistance-about-course.component';
import { RequestCallbackEffects } from './feature-state/request-callback.effects';
import { requestCallbackReducer } from './feature-state/request-callback.reducer';
import { FormsModule } from '@angular/forms';
import { InlineRequestCallbackComponent } from './components/inline-request-callback/inline-request-callback.component';
import { RequestCourseBatchComponent } from './components/request-course-batch/request-course-batch.component';
import {CoreModule} from "@core/core.module";

@NgModule({
  declarations: [
    RequestCallbackComponent,
    BookCounsellingSessionComponent,
    AssistanceAboutCourseComponent,
    InlineRequestCallbackComponent,
    RequestCourseBatchComponent
  ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        EffectsModule.forFeature([RequestCallbackEffects]),
        StoreModule.forFeature('requestCallback', requestCallbackReducer),
        CoreModule
    ],
  exports: [RequestCallbackComponent]
})
export class RequestCallbackModule {
  constructor() {
  }
}
