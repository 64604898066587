import { createReducer, on } from '@ngrx/store';
import {
studentCouponsLoaded, couponProductsLoaded, couponProductsError,
setCoupon,
couponCTADataLoaded,
resetCoupon,
showCouponSuccessModal,
hideCouponSuccessModal
} from './coupon.actions';
import {resetOnNavigation} from '@angular-commons/core/application-state/app.actions';

// @todo Check sheet to ctaDetails flow. Currently using default.
export const initialState = {
    studentCoupons: {},
    couponProducts: {
        isInitialState: true,
        error: {}
    },
    coupon: {
        code: '',
        state: 'default'
    },
    ctaDetails: {
        offersText : 'Get exciting offers inside',
        viewOffersCTA : 'Apply Coupon',
        moreOffersCTA : 'More Offers'
    },
    successModal: false
};

const _couponReducer = createReducer(initialState,
    on(studentCouponsLoaded, (state,{payload}) => {
        let nextState = {...state}
        nextState.studentCoupons = payload;
        return nextState;
    }),
    on(couponProductsLoaded, (state,{payload}) => {
        let nextState = {...state};
        nextState.couponProducts = {...payload, isInitialState:undefined, error:{}};
        return nextState;
    }),
    on(couponProductsError, (state,{payload}) => {
        let nextState = {...state};
        nextState.couponProducts = {...nextState.couponProducts, error: {...payload}};
        return nextState;
    }),
    on(setCoupon, (state,{coupon}) => {
        let nextState = {...state};
        nextState.coupon = {...nextState.coupon, ...coupon};
        return nextState;
    }),
    on(resetCoupon, (state) => {
        let nextState = {...state};
        nextState.coupon = {code: '', state: 'default'};
        nextState.couponProducts = {isInitialState:undefined, error: {}};
        return nextState;
    }),
    on(couponCTADataLoaded, (state,{payload}) => {
        let nextState = {...state};
        nextState.ctaDetails = {...payload};
        return nextState;
    }),
    on(showCouponSuccessModal, (_state)=>{
        let nextState = {..._state};
        nextState.successModal = true;
        return nextState;
    }),
    on(hideCouponSuccessModal, (_state)=>{
        let nextState = {..._state};
        nextState.successModal = false;
        return nextState;
    }),
    on(resetOnNavigation, (_state)=>{
        let nextState = {...initialState};
        return nextState;
    }),
    
);

export function couponReducer(state, action) {
    return _couponReducer(state, action);
}
