import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {EmiIframeComponent} from "./emi-iframe.component";

const routes: Routes = [
    {
        path: '',
        component: EmiIframeComponent,
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EmiRoutingModule {}