import {arrayModel, objModel, propertyMaps, SourceProjectionMapper} from "../../mapping-framework";
import {Network} from "../../../core/services/model-manager/main";
import {baseModel, setupModel} from "../../types";
import {Validator as _} from "../../../shared/utility/validator";
import {
    _Author,
    _DoubtDetailsModel,
    _DoubtModel,
    _User,
    Author,
    DoubtDetailsModel, DoubtModel
} from "./doubts.adapter";
import {User} from "./doubts.adapter";

export class _AnswerModel extends baseModel {
    @propertyMaps()
    public id;

    @propertyMaps('did', _.str)
    public did;

    @propertyMaps('goalId', _.str)
    public goalId;

    @propertyMaps('details', objModel(_DoubtDetailsModel), _DoubtDetailsModel)
    public details;

    @propertyMaps('on', _.str)
    public on;

    @propertyMaps('user', objModel(_User), _User)
    public user;

    @propertyMaps('upVotes', _.num)
    public upVotes;

    @propertyMaps('type', _.num)
    public replies;

    @propertyMaps('isBestAnswer', _.bool)
    public isBestAnswer;

    @propertyMaps('upVoted', _.bool)
    public upVoted;

    @propertyMaps('commentsCount', _.num)
    public commentsCount;

    @propertyMaps()
    public timeDiffInMins;

    @propertyMaps()
    public showAnswerBox;

    @propertyMaps()
    public isModeratorAns;
}
setupModel(_AnswerModel, {});

export class _AnswersModel extends baseModel {
    @propertyMaps('answers', arrayModel(_AnswerModel), _AnswerModel)
    public answers;

    @propertyMaps('topAnswers', arrayModel(_AnswerModel), _AnswerModel)
    public topAnswers;
}
setupModel(_AnswersModel, {type: 'doubts'});

export class AnswerModel extends baseModel {
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('did', _.str)
    public did;

    @propertyMaps('goalId', _.str)
    public goalId;

    @propertyMaps('details', objModel(DoubtDetailsModel), DoubtDetailsModel)
    public details;

    @propertyMaps('on', _.str)
    public on;

    @propertyMaps('upVotes', _.num)
    public upVotes;

    @propertyMaps('type', _.num)
    public replies;

    @propertyMaps('isBestAnswer', _.bool)
    public isBestAnswer;

    @propertyMaps('upVoted', _.bool)
    public upVoted;

    @propertyMaps('user', objModel(User), User)
    public user;

    @propertyMaps('commentsCount', _.num)
    public commentsCount;

    @propertyMaps('isModeratorAns', _.bool)
    public isModeratorAns;
}
setupModel(AnswerModel, {});

export class MyDoubtModel extends baseModel {
    @propertyMaps('answerCount', _.str)
    public answerCount

    @propertyMaps('doubt', objModel(DoubtModel))
    public doubt;

    @propertyMaps('answer', objModel(AnswerModel))
    public answer;
}
setupModel(MyDoubtModel, {});

export class _MyDoubtModel extends baseModel {
    @propertyMaps('answerCount', _.str)
    public answerCount;

    @propertyMaps('doubt', objModel(_DoubtModel))
    public doubt;

    @propertyMaps('answer', objModel(_AnswerModel))
    public answer;
}
setupModel(_MyDoubtModel, {});

export class MyDoubtsModel extends baseModel {
    @propertyMaps('doubts', arrayModel(MyDoubtModel), MyDoubtModel)
    public doubts;

}
setupModel(MyDoubtsModel, {});

export class _MyDoubtsModel extends baseModel {
    @propertyMaps('doubts', arrayModel(_MyDoubtModel), _MyDoubtModel)
    public doubts;
}
setupModel(_MyDoubtsModel, {});

export class AnswersModel extends baseModel {
    @propertyMaps('answers', arrayModel(AnswerModel), AnswerModel)
    public answers;

    @propertyMaps('topAnswers', arrayModel(AnswerModel), AnswerModel)
    public topAnswers;
}

setupModel(AnswersModel, {type: 'doubts'});

export class GetAllAnswersForDoubtApi {
    static apiEndpoint = 'v2/doubt/{doubtId}/answer';

    static projection;
    static get  __projection(){ 
        if(!GetAllAnswersForDoubtApi.projection){
            GetAllAnswersForDoubtApi.projection = JSON.stringify(new SourceProjectionMapper(AnswersModel).map());
        }
        return GetAllAnswersForDoubtApi.projection;
    }

    static apiCall(network: Network, doubtId: any) {
        return network.get(GetAllAnswersForDoubtApi.apiEndpoint.replace('{doubtId}', doubtId), {limit:0,__projection: GetAllAnswersForDoubtApi.__projection})
    }
}

export class GetMyAnswerApi {
    static apiEndpoint = 'v2/doubts/myanswers';

    static projection;
    static get  __projection(){ 
        if(!GetMyAnswerApi.projection){
            GetMyAnswerApi.projection = JSON.stringify(new SourceProjectionMapper(MyDoubtsModel).map());;
        }
        return GetMyAnswerApi.projection;
    }

    static apiCall(network: Network, params) {
        let backendParams: any = {};
        backendParams.type = params.entityType;
        backendParams.skip = params.skip;
        backendParams.limit = params.limit;
        if(params?.entityType === 'mainsAnswer' ) {
            params?.goalIds && (backendParams.goalIds = params.goalIds);
        }
        if(params.entityId) {
            backendParams.entityId = params.entityId;
        }
        return network.get(GetMyAnswerApi.apiEndpoint, {__projection: GetMyAnswerApi.__projection, ...backendParams});
    }
}

export class Dummy extends baseModel {
    @propertyMaps()
    public dummy: boolean;
}
setupModel(Dummy, {});

export class MarkBestAnswerParams extends baseModel {
    @propertyMaps('doubtId', _.str)
    public doubtId;

    @propertyMaps('answerId', _.str)
    public answerId;
}
setupModel(MarkBestAnswerParams, {});

export class FeedbackResponseParams extends baseModel {
    @propertyMaps()
    public answerId;

    @propertyMaps()
    public doubtId;

    @propertyMaps()
    public commentId;

    @propertyMaps()
    public requiresReview;
}
setupModel(FeedbackResponseParams, {});

export class VoteAnswerParams extends baseModel {
    @propertyMaps()
    public answerId;

    @propertyMaps()
    public doubtId;
}
setupModel(VoteAnswerParams, {});

export class DeleteAnswerParams extends baseModel {
    @propertyMaps()
    public answerId;

    @propertyMaps()
    public doubtId;
}
setupModel(DeleteAnswerParams, {});

export class MarkBestAnswerAPi {
    static apiEndpoint = 'v2/doubt/{doubtId}/answer/{answerId}/mark-best';

    static apiCall(network: Network, {doubtId, answerId}) {
        return network.put(MarkBestAnswerAPi.apiEndpoint.replace('{doubtId}', doubtId).replace('{answerId}', answerId))
    }
}

export class RemoveBestAnswerApi {
    static apiEndpoint = 'v2/doubt/{doubtId}/answer/{answerId}/remove-best';

    static apiCall(network: Network, {doubtId, answerId}) {
        return network.post(RemoveBestAnswerApi.apiEndpoint.replace('{doubtId}', doubtId).replace('{answerId}', answerId));
    }
}

export class UpvoteAnswerApi {
    static apiEndpoint = 'v2/doubt/{doubtId}/answer/{answerId}/up-vote'

    static apiCall(network: Network, voteParams: VoteAnswerParams) {
        return network.post(UpvoteAnswerApi.apiEndpoint.replace('{doubtId}', voteParams.doubtId).replace('{answerId}', voteParams.answerId));
    }

}

export class FeedbackApi {
    static apiEndpoint = 'v2/doubt/{doubtId}/feedback'

    static apiCall(network: Network, feedbackParams: FeedbackResponseParams) {
        let networkParam:any = {requiresReview:feedbackParams.requiresReview,answerId: feedbackParams.answerId}
        feedbackParams.commentId && (networkParam.commentId = feedbackParams.commentId)
        return network.post(FeedbackApi.apiEndpoint.replace('{doubtId}', feedbackParams.doubtId),{},networkParam);
    }

}


export class RemoveUpvoteAnswerApi {
    static apiEndpoint = 'v2/doubt/{doubtId}/answer/{answerId}/remove-vote';

    static apiCall(network: Network, voteParams: VoteAnswerParams) {
        return network.post(RemoveUpvoteAnswerApi.apiEndpoint.replace('{doubtId}', voteParams.doubtId).replace('{answerId}', voteParams.answerId));
    }

}

export class DeleteAnswerApi {
    static apiEndpoint = 'v2/doubt/{doubtId}/answer/{answerId}';

    static apiCall(network: Network, params) {
        return network.delete(DeleteAnswerApi.apiEndpoint.replace('{doubtId}', params.doubtId).replace('{answerId}', params.answerId));
    }
}
