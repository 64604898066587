import {DefaultUrlSerializer, UrlSerializer, UrlTree} from "@angular/router";
import { getPathNameWithQueryUrl } from "@angular-commons/shared/utility/url";
export class CustomUrlSerializer implements UrlSerializer {
  parse(url: any): UrlTree {
    // exclude first occurrence and encode multiple '?' in query params
    let firstOccuranceIndex = url.indexOf('?') + 1;
    let resultUrl = url.substr(0, firstOccuranceIndex) + url.slice(firstOccuranceIndex).replace(/\?/g, '%3F');

    let dus = new DefaultUrlSerializer();
    let urlLowerCase=getPathNameWithQueryUrl(resultUrl);
    if(urlLowerCase.indexOf("//") && /([^:]\/)\/+/g.test(urlLowerCase)) {  //regex will replace multiple occurrences of the slashes with a single slash
      urlLowerCase = urlLowerCase.replace(/([^:]\/)\/+/g, '$1');
    }
    return dus.parse(urlLowerCase);
  }

  serialize(tree: UrlTree): any {
    let dus = new DefaultUrlSerializer();
    return dus.serialize(tree);
  }
}