import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    ActivatedRoute,
    UrlTree
} from '@angular/router';
import {Observable, ReplaySubject} from "rxjs";
import {Store} from "@ngrx/store";
import {PlatformService} from "@core/services/platform-service";
import { AuthenticationService } from '@angular-commons/core/services/authentication.service';
import { getGoalBySlug, showGoalSelectionModal } from '@angular-commons/core/application-state/app.actions';
import {selectGoalMap} from "../../../../angular-commons/core/application-state/app.selectors";
import {TransferStateManager} from "@core/services/transferStateManager";


@Injectable()
export class EducatorsGuard implements CanActivate {
    transferStateValue;

    constructor(private router:Router,
                private route: ActivatedRoute,
                private store: Store<{}>,
                private auth: AuthenticationService,
                private platformService: PlatformService,
                private transferStateManager: TransferStateManager) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot):  Observable<boolean | UrlTree> {

        const sub = new ReplaySubject<boolean>();
        let goalSlug = route.params.goalSlug;
        if(!goalSlug || goalSlug==='undefined'){
            goalSlug = this.auth.getGoal().slug;
        }
        if(goalSlug) {
            this.transferStateValue = this.transferStateManager.get(
                `currentGoal_${goalSlug}`,
                getGoalBySlug({slug: goalSlug}),
                this.store.select(selectGoalMap),
                (goal) => {
                if(!(goal && goal[goalSlug] && goal[goalSlug]?.id)) {
                    this.platformService.gotoErrorPage();
                    sub.next(false);
                    sub.complete();
                }
                if(this.transferStateValue){
                    this.transferStateValue?.unsubscribe();
                }
            sub.next(true);
            sub.complete();
        });
        }
        else{
            this.store.dispatch(showGoalSelectionModal({}))
            sub.next(true);
            sub.complete();

        }
        return sub;
    }

}
