import { createReducer, on } from '@ngrx/store'
import { getEmiMandateDataLoaded, getMandateGuideLinesLoaded, getStudentPartialEmiLoaded, setErrorMessage } from './emi-mandate.actions';

export const initialState = {
    mandate: {
        isLoaded: false,
        mandateDetails: {}
    },
    partialEmi: {
        isLoaded: false,
        emiData: {}
    },
    errorMessage:'',
    mandateGuideLines: {
        isLoaded: false,
        pdfLink:''
    }
};

const  _emiMandateReducer = createReducer(initialState,
    on(getEmiMandateDataLoaded,(_state,{payload})=>{
        let state = {..._state};
        state.mandate = { mandateDetails:payload, isLoaded: true };
        return state;
    }),
    
    on(getStudentPartialEmiLoaded,(_state,{payload})=>{
        let state = {..._state};
        let emi = payload?.length ? payload[0] : {};
        state.partialEmi = { emiData: emi , isLoaded: true };
        return state;
    }),

    on(getMandateGuideLinesLoaded,(_state,{payload})=>{
        let state = {..._state};
        state.mandateGuideLines = { pdfLink: payload?.pdfLink || '', isLoaded: true };
        return state;
    }),

    on(setErrorMessage,(_state,{message})=>{
        let state = {..._state};
        state.errorMessage = message;
        return state;
    }),
)

export function EmiMandateReducer(action, state){
    return _emiMandateReducer(action, state);
}