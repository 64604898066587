import { languageCodeMap } from '@angular-commons/shared/utility/language';
import { Inject, NgModule, Optional } from '@angular/core';
import { Routes, RouterModule, UrlSegment, UrlSegmentGroup, Route, UrlMatchResult, Router } from '@angular/router';
import {WildCardRoutesGuard} from "./route-guards/wild-card-routes.guard";
import {ScholarshipGuard} from "./route-guards/scholarship-guard";
import {PassPageGuard} from './route-guards/pass-page-guard';
import {MasterClassLessonGuard} from './route-guards/master-class-lesson-guard';
import {TestSeriesGuard} from "./route-guards/test-series-guard";
import { SelectPageGuard } from './route-guards/select-page-guard';
import {SelectionsGuard} from './route-guards/selections-guard';
import {PAGE_SEO_SUBFOOTER, SEO_GROUP_SUBFOOTER} from "@shared/utility/seo-utilities";
import {WildCardChildRoutesGuard} from "./route-guards/wild-card-child-routes.guard";
import {MasterSeriesGuard} from './route-guards/master-series-guard';
import { ExploreTestsQuizzesGuard } from './route-guards/explore-tests-quizzes-guard';
import {PrimaryGoalGuard} from "./route-guards/primary-goal.guard";
import { EducatorGuard } from './route-guards/educator-guard';
import {GoalSelectionGuard} from "./route-guards/goal-selection-guard";
import {GoalPlansGuard} from "./route-guards/goal-plans-guard";
import { EducatorsGuard } from './route-guards/educators-guard';
import { GoalEntityGuard } from './route-guards/goal-entity-guard';
import { OnboardingGuard } from './route-guards/onboarding-guard';
import { HomeGuard } from './route-guards/home-guard';
import {RequiresLoginGuard} from "./route-guards/requires-login-guard";
import { TestPromotionGuard } from './route-guards/test-promotion-guard';
import { CurrentPracticeGroupSelectionGuard } from './route-guards/current-practice-group-selection-guard';
import {NewMgoalGuard} from "./route-guards/new-mgoal-guard";
import {PassProPageGuard} from "./route-guards/pass-pro-page-guard";
import { MentorshipGuard } from './route-guards/mentorship.guard';
import { currentAffairsRegex, enabledVernacularCodes } from '@angular-commons/shared/utility/constants';
import {TermsPageGuard} from "./route-guards/terms-page-guard";
import { MgoalMentorShipGuard } from './route-guards/mgoal-mentorship.guard';
import { getClientCookie, isServer, setClientCookie } from '@angular-commons/shared/utility/tb-common';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { PlatformService } from '@angular-commons/core/services/platform-service';

export function match(names: string[],routeRegExpArr: RegExp[],url :UrlSegment[]):any{
    if(routeRegExpArr.length === url.length){
        let matches = true;
        for(let i=0 ; i<url.length ;i++){
            if(!routeRegExpArr[i].test(url[i].path)){
                matches = false;
                break;
            }
        }
        if(matches){
            return {
                consumed : url,
                posParams: {}
            };
        }
    }
    return null;
}

function processSecondarySlug(url: UrlSegment[]) {
    if(url[1]?.path.includes('online-coaching-')) {
        return new UrlSegment(url[1]?.path.split('online-coaching-')[1], {});
    }
    else if(url[1]?.path.includes('-course')) {
        return new UrlSegment(url[1]?.path.split('-course')[0], {});
    }
}

export function selectMatcher(url: UrlSegment[], group: UrlSegmentGroup, route: Route){

    let slugRouteRegExpArr = [/.+/, /^online-coaching-|-course$/];
    let matchResult = match(['slug1','slug2'], slugRouteRegExpArr,url);
    let slugRouteRegExpArrForSkill = [/-career-program$|-course$/];
    let matchResultForSkill = match(['slug1'], slugRouteRegExpArrForSkill,url);
    if (matchResult) {
        matchResult.posParams = {
            slug1: new UrlSegment(url[0]?.path, {}),
            slug2: processSecondarySlug(url)
          }
          return matchResult;
    }
    else if(matchResultForSkill){
        let slug1;
        let slug2;

        if(url[0]?.path.includes('-career-program')) {
            slug1=url[0].path.split('-career-program')[0];
            slug2='career-program'
        }
        else if(url[0]?.path.includes('-course')) {
            slug1=url[0].path.split('-course')[0];
            slug2='course';
        }
        matchResultForSkill.posParams = {
            slug1: new UrlSegment(slug1, {}),
            slug2:  new UrlSegment(slug2, {})
          }
          return matchResultForSkill;
    }
}

export function goalCoachingMatcher(url: UrlSegment[], group: UrlSegmentGroup, route: Route){
    let slugRouteRegExpArr = [/-coaching$/];
    let matchResult = match(['goalSlug'], slugRouteRegExpArr,url);
    if (matchResult) {
        matchResult.posParams = {
            goalSlug: new UrlSegment(url[0]?.path.split('-coaching')[0], {})
        }
    }
    return matchResult;
}

export function goalCoachingTermsMatcher(url: UrlSegment[], group: UrlSegmentGroup, route: Route){
    let slugRouteRegExpArr = [/-coaching$/,/terms-and-condition$/];
    let matchResult = match(['goalSlug'], slugRouteRegExpArr,url);
    if (matchResult) {
        matchResult.posParams = {
            goalSlug: new UrlSegment(url[0]?.path.split('-coaching')[0], {})
        }
    }
    return matchResult;
}

export function superTeachersMatcher(url: UrlSegment[], group: UrlSegmentGroup, route: Route){
    let slugRouteRegExpArr = [/-coaching$/,/super-teachers$/];
    let matchResult = match(['goalSlug'], slugRouteRegExpArr,url);
    if (matchResult) {
        matchResult.posParams = {
            goalSlug: new UrlSegment(url[0]?.path.split('-coaching')[0], {})
        }
    }
    return matchResult;
}

export function superTeacherMatcher(url: UrlSegment[], group: UrlSegmentGroup, route: Route){
    let slugRouteRegExpArr = [/-coaching$/,/-super-teacher$/];
    let matchResult = match(['goalSlug','teacherSlug'], slugRouteRegExpArr,url);
    if (matchResult) {
        matchResult.posParams = {
            goalSlug: new UrlSegment(url[0]?.path.split('-coaching')[0], {}),
            teacherSlug: new UrlSegment(url[1]?.path.split('-super-teacher')[0], {})
        }
    }
    return matchResult;
}


export function childPageGroupMatcher(url: UrlSegment[], group: UrlSegmentGroup, route: Route){
    let slugRouteRegExpArr = [/^admit-card$/,/^syllabus-exam-pattern$/,/^result$/,/^cut-off$/,/^eligibility-criteria$/,/^books$/,/^salary-job-profile$/,/^answer-key$/];
    let matchResult;
    for(var regex of slugRouteRegExpArr){
        matchResult = match(['slug'], [regex],url);
        if (matchResult) {
            matchResult.posParams = {
                slug: new UrlSegment(url[0].path, {})
            }
            return matchResult;
        }
    }

    return matchResult;
}

export function freePracticeGroupMatcher(url: UrlSegment[], group: UrlSegmentGroup, route: Route){
    let slugRouteRegExpArr = [/-questions$/];
    let groupIdMatchResult = match(['groupId'], slugRouteRegExpArr, url);
    if (groupIdMatchResult) {
        groupIdMatchResult.posParams = {
            groupIdOrSlug: new UrlSegment(url[0]?.path.split('-questions')[0], {})
        }
        return groupIdMatchResult
    }
}

export function freePracticeGroupChapterMatcher(url: UrlSegment[], group: UrlSegmentGroup, route: Route){
    let slugRouteRegExpArr = [/-questions--cq$/];
    let matchResult = match(['chapterIdOrChapterSlug'], slugRouteRegExpArr,url);
    if (matchResult) {
        matchResult.posParams = {
            chapterIdOrChapterSlug: new UrlSegment(url[0]?.path.split('-questions--cq')[0], {})
        }
    }
    return matchResult;
}

export function wildCardRoutesLanguageMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult | null {
    if(segments.length === 2 && languageCodeMap[segments[0].path.toLowerCase()] && !currentAffairsRegex.test(segments[1].path.toLowerCase())){
        const [languageCode, idOrSlug] = segments;
        return {
            consumed: segments,
            posParams: {
                languageCode: new UrlSegment(languageCode.path.toLowerCase(), {}),
                idOrSlug
            }
        };
    }
    return null;
}

export function wildCardChildRoutesLanguageMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult | null {
    if(segments.length === 3) {
        const [languageCode, idOrSlug, childIdOrSlug] = segments;
        // Check if languageCode is a valid language code
        if ( languageCodeMap[languageCode.path.toLowerCase()] && !(currentAffairsRegex.test(segments[1].path.toLowerCase()) || currentAffairsRegex.test(segments[2].path.toLowerCase())) ){
          return {
            consumed: segments,
            posParams: {
              languageCode : new UrlSegment(languageCode.path.toLowerCase(), {}),
              idOrSlug,
              childIdOrSlug
            }
          };
        }
    }
    return null;
}


export function vernacularHomePageRouteMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult | null {
    if(segments.length === 1 && enabledVernacularCodes[segments[0].path.toLowerCase()]){
        const [languageCode] = segments;
        return {
            consumed: segments,
            posParams: {
                languageCode: new UrlSegment(languageCode.path.toLowerCase(), {}),
            }
        };
    }
    return null;
}
export function vernacularWildcardRouteMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult | null {
    if(segments.length === 2 && enabledVernacularCodes[segments[0].path.toLowerCase()]){
        const [languageCode, idOrSlug] = segments;
        return {
            consumed: segments,
            posParams: {
                languageCode: new UrlSegment(languageCode.path.toLowerCase(), {}),
                idOrSlug
            }
        };
    }
    return null;
}

export function mgoalMentorShipMatcher(url: UrlSegment[], group: UrlSegmentGroup, route: Route){
    let slugRouteRegExpArr = [/mgoal$/,/-program$/];
    let matchResult = match(['mgoalSlug'], slugRouteRegExpArr,url);
    if (matchResult) {
        matchResult.posParams = {
            mgoalSlug: new UrlSegment(url?.[1]?.path?.split('-program')?.[0]?.split('free-')?.[1], {})
        }
    }
    return matchResult;
}

export function vernacularPypChildRouteMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult | null {
    if(segments.length === 3  && enabledVernacularCodes[segments[0].path.toLowerCase()]) {
        const [languageCode, idOrSlug, childIdOrSlug] = segments;
        // Check if languageCode is a valid language code
        if ( enabledVernacularCodes[languageCode.path.toLowerCase()] && childIdOrSlug.path.toLowerCase() === 'previous-year-papers'){
          return {
            consumed: segments,
            posParams: {
              languageCode : new UrlSegment(languageCode.path.toLowerCase(), {}),
              idOrSlug,
              childIdOrSlug
            }
          };
        }
    }
    return null;
}



function getRoutes(req: any): Routes {
    // Determine the domain based on your logic
    let currentHostName= req?.host;
    if (currentHostName?.includes('plutuseducation.com') && isServer() ||  !isServer() && getClientCookie("projectName") === 'plutus') {
        const routes: Routes = [
            {
                path: '',
                loadChildren: () => import('./route-modules/plutus-academy-landing/plutus-academy.module').then(m => m.PlutusAcademyModule),
                data: {
                    hideIntercom: true
                }
            },
            {
                path: 'programs',
                loadChildren: () => import('./route-modules/plutus-academy-landing/plutus-academy.module').then(m => m.PlutusAcademyModule),
                data: {
                    hideIntercom: true
                }
            },
            {
                path: 'webinar',
                loadChildren: () => import('./route-modules/plutus-academy-landing/plutus-academy.module').then(m => m.PlutusAcademyModule),
                data: {
                    hideIntercom: true
                }
            },
            {
                path: 'home',
                canActivate:[HomeGuard],
                loadChildren: () => import('./route-modules/plutus-academy-landing/plutus-academy.module').then(m => m.PlutusAcademyModule),
                data: {
                    hideIntercom: true
                }
            },
            {
                path: 'initiate-payment',
                loadChildren: () => import('./route-modules/payment-initiate/payment-initiate.module').then(m => m.PaymentInitiateModule),
            },
            {
                path: 'lesson/:lessonId',
                canActivate: [RequiresLoginGuard],
                loadChildren: () => import('./route-modules/lesson/lesson.module').then(m => m.LessonModule),
                data: {
                    hideIntercom: true
                }
            },
            {
                path: 'login',
                loadChildren: () => import('./route-modules/login/login-module').then(m => m.LoginModule),
            },
            {
                matcher:freePracticeGroupChapterMatcher,
                loadChildren: () => import('./route-modules/free-practice/free-practice-explore-section-by-chapter/free-practice-explore-section-by-chapter.module').then(m => m.FreePracticeExploreSectionByChapterModule),
                data: {
                    pageType:'practicePage',
                    hideIntercom: true
                }
            },
            {
                matcher:freePracticeGroupMatcher,
                loadChildren: () => import('./route-modules/free-practice/free-practice-explore-chapter-by-subject/free-practice-explore-chapter-by-subject.module').then(m => m.FreePracticeExploreChapterBySubjectModule),
                data: {
                    pageType:'practicePage',
                    hideIntercom: true
                }
            },
            {
                path: 'class/:parentId/lesson/:lessonId',
                loadChildren: () => import('./route-modules/lesson/lesson.module').then(m => m.LessonModule),
                data: {
                    hideIntercom: true
                }
            },
            {
                path: 'course/:classId/video/:videoId',
                loadChildren: () => import('./route-modules/course-video-module/course-video-module.module').then(m => m.CourseVideoModuleModule),
                data: {
                    hideIntercom: true
                }
            },
            {
                path:'live-classes-series/:parentId/lesson/:lessonId',
                canActivate:[MasterClassLessonGuard],
                loadChildren: () => import('./route-modules/lesson/lesson.module').then(m => m.LessonModule),
                data: {
                    hideIntercom: true
                }
            },
            {
                path: ':idOrSlug/live-classes-series',
                canActivate: [MasterSeriesGuard],
                loadChildren: () => import('./route-modules/master-class-series/master-class-series.module').then(m => m.MasterClassSeriesModule),
                data: {
                    hideIntercom: true
                }
            },
            {
                matcher: selectMatcher ,
                canActivate: [SelectPageGuard],
                loadChildren: () => import('./route-modules/select-selling-page/select-selling.module').then(m => m.SelectSellingModule),
                data:{
                    hideIntercom: true
                }
            },
            {
                path: 'pdf-viewer',
                loadChildren: () => import('./route-modules/pdf-viewer/pdf.module').then(m => m.PdfModule)
            },
            {
                path: 'view/:selectedItem',
                loadChildren: () => import('./route-modules/legal-info/legal-info.module').then(m => m.LegalInfoModule),
            },
            {
                path: 'plutus-blogs',
                loadChildren: () => import('./route-modules/blogs/blogs.module').then(m => m.BlogsModule),
            },
            {
                path: '**',
                loadChildren: () => import('./route-modules/404-not-found/error-module').then(m => m.ErrorModule)
            }
        ];
        return routes;
    }
    if(currentHostName?.includes('testbook.com') && isServer() ||  !isServer() && getClientCookie("projectName") === 'testbook'){
        //   // Define different routes arrays for different domains
        const routes: Routes = [
            {
                path: 'emi',
                loadChildren: () => import('./route-modules/emi/emi.module').then(m => m.EmiModule),
            },
            {
                path: 'transactions',
                canActivate: [RequiresLoginGuard],
                loadChildren: () => import('./route-modules/transactions/transactions.module').then(m => m.TransactionsModule),
            },
            {
                path: 'settings',
                canActivate: [RequiresLoginGuard],
                loadChildren: () => import('./route-modules/settings/settings.module').then(m => m.SettingsModule),
            },
            {
                path: 'skill-academy',
                loadChildren: () => import('./route-modules/skill-academy-landing-page/skill-academy-landing-page.module').then(m => m.SkillAcademyLandingPageModule),
                data: {
                    pageType:'skillAcademy'
                }
            },
            {
                matcher: mgoalMentorShipMatcher,
                canActivate: [MgoalMentorShipGuard],
                loadChildren: () => import('./route-modules/mgoal-mentorship/mgoal-mentorship.module').then(m => m.MgoalMentorShipModule)
            },
            {
                path: 'mgoal/:goalSlug',
                canActivate: [NewMgoalGuard],
                loadChildren: () => import('./route-modules/new-mgoal/new-mgoal.module').then(m => m.NewMgoalModule)
            },
            {
                path:'iframe-pricing-modal',
                loadChildren: () => import('./route-modules/pricing-modal/pricing-modal-wrapper.module').then(m => m.PricingModalWrapperModule)
            },
            {
                path: 'practice-questions',
                canActivate: [CurrentPracticeGroupSelectionGuard],
                loadChildren: () => import('./route-modules/free-practice/free-practice/free-practice.module').then(m => m.FreePracticeModule),
                data: {
                    pageType:'practicePage'
                }
            },
            {
                path: 'e-cards',
                canActivate: [RequiresLoginGuard],
                loadChildren: () => import('./route-modules/e-cards/e-cards-module').then(m => m.ECardsModule),
            },
            {
                path: 'saved-questions',
                canActivate: [RequiresLoginGuard],
                loadChildren: () => import('./route-modules/saved-questions/saved-questions.module').then(m => m.SavedQuestionsModule),
            },
            {
                path: 'reported-questions',
                canActivate: [RequiresLoginGuard],
                loadChildren: () => import('./route-modules/reported-questions/reported-questions.module').then(m => m.ReportedQuestionsModule),
            },
            {
                path: 'subjective-questions',
                canActivate: [RequiresLoginGuard],
                loadChildren: () => import('./route-modules/subjective-questions/subjective-questions.module').then(m => m.SubjectiveQuestionsModule),
            },
            {
                matcher:freePracticeGroupChapterMatcher,
                loadChildren: () => import('./route-modules/free-practice/free-practice-explore-section-by-chapter/free-practice-explore-section-by-chapter.module').then(m => m.FreePracticeExploreSectionByChapterModule),
                data: {
                    pageType:'practicePage'
                }
            },
            {
                matcher:freePracticeGroupMatcher,
                loadChildren: () => import('./route-modules/free-practice/free-practice-explore-chapter-by-subject/free-practice-explore-chapter-by-subject.module').then(m => m.FreePracticeExploreChapterBySubjectModule),
                data: {
                    pageType:'practicePage'
                }
            },
            {
                path: 'account-blocked',
                loadChildren: () => import('./route-modules/account-block-wrapper/account-block-wrapper.module').then(m => m.AccountBlockWrapperModule)
            },
            {
                path: 'vendor-payment',
                loadChildren: () => import('./route-modules/book-vendor-payment-page/book-vendor-payment-page.module').then(m => m.BookVendorPaymentModule)
            },
            {
                path: 'offline-center-login',
                loadChildren: () => import('./route-modules/ocrm/ocrm.module').then(m => m.OcrmModule),
            },
            {
                path: 'initiate-payment',
                loadChildren: () => import('./route-modules/payment-initiate/payment-initiate.module').then(m => m.PaymentInitiateModule),
            },
            {
                path: 'super-coaching/:goalSlug/dashboard',
                canActivate: [GoalEntityGuard],
                loadChildren: () => import('./route-modules/goal-page-modules/goal-dashboard/goal-dashboard.module').then(m => m.GoalDashboardModule),
                data: {
                    pageType:'superCoaching'
                }
            },
            {
                path: 'super-coaching/:goalSlug/courses',
                canActivate: [GoalEntityGuard],
                loadChildren: () => import('./route-modules/goal-page-modules/goal-courses-tab/goal-courses-tab.module').then(m => m.GoalCoursesTabModule),
                data: {
                    pageType:'superCoaching'
                }
            },
            {
                path: 'attempted-tests',
                canActivate: [RequiresLoginGuard],
                loadChildren: () => import('./route-modules/attempted-test-quiz/attempted-test-quiz.module').then(m => m.AttemptedTestQuizComponentModule)
            },
            {
                path: 'free-online-quizzes',
                loadChildren: () => import('./route-modules/quizzes-page/quizzes-page.module').then(m => m.QuizzesComponentModule),
                data: {
                    pageType:'quizzesPage'
                }
            },
            {
                path: 'current-affairs/current-affairs-quiz',
                loadChildren: () => import('./route-modules/gk-and-ca/gk-ca-quizzes.module').then(m => m.GkCaQuizzesModule),
                data: {
                    pageType:'gkQuizzesPage',
                    subFooterType: PAGE_SEO_SUBFOOTER
                }
            },
            {
                path: 'super-coaching/:goalSlug/practice',
                canActivate: [GoalEntityGuard],
                loadChildren: () => import('./route-modules/goal-page-modules/goal-practice/goal-practice.module').then(m => m.GoalPracticeModule),
                data: {
                    pageType:'superCoaching'
                }
            },
            {
                path: 'super-coaching/:goalSlug/notes',
                canActivate: [GoalEntityGuard],
                loadChildren: () => import('./route-modules/goal-page-modules/goal-study-notes/goal-study-notes.module').then(m => m.GoalStudyNotesModule),
                data: {
                    pageType:'superCoaching'
                }
            },
            {
                path: 'super-coaching/:goalSlug/chapter/:chapterId',
                canActivate: [GoalEntityGuard],//,GoalChapterGuard],
                loadChildren: () => import('./route-modules/goal-page-modules/goal-chapter-page/goal-chapter-page.module').then(m => m.GoalChapterPageModule),
                data: {
                    pageType:'superCoaching'
                }
            },
            {
                path: 'super-coaching/:goalSlug/tests',
                canActivate: [GoalEntityGuard],
                loadChildren: () => import('./route-modules/goal-page-modules/goal-tests-tab/goal-tests-tab.module').then(m => m.GoalTestsTabModule),
                data: {
                    pageType:'superCoaching'
                }
            },
            {
                path: 'super-coaching/:goalSlug/plans',
                canActivate: [GoalPlansGuard],
                loadChildren: () => import('./route-modules/goal-plans/goal-plans.module').then(m => m.GoalPlansModule),
                data: {
                    pageType:'superCoaching'
                }
            },
            {
                path: 'super-coaching/:goalSlug/mentorship',
                canActivate: [MentorshipGuard],
                loadChildren: () => import('./route-modules/goal-page-modules/goal-mentorship-tab/goal-mentorship.module').then(m => m.GoalMentorshipModule),
                data: {
                    pageType:'superCoaching'
                }
            },
            {
                path: 'super-coaching',
                canActivate:[GoalSelectionGuard],
                loadChildren: () => import('./route-modules/goal-selection-page/goal-selection-page.module').then(m => m.GoalSelectionPageModule),
                data: {
                    pageType:'superCoaching'
                }
            },
            {
                matcher:superTeacherMatcher,
                canActivate:[EducatorGuard],
                loadChildren: () => import('./route-modules/educator/educator.module').then(m => m.EducatorModule),
                data: {
                    pageType:'superCoaching'
                }
            },
            {
                matcher: goalCoachingMatcher,
                canActivate: [PrimaryGoalGuard],
                loadChildren: () => import('./route-modules/primary-goal-page/primary-goal-page.module').then(m => m.PrimaryGoalPageModule),
                data: {
                    pageType:'superCoaching'
                }
            },
            {
                matcher: goalCoachingTermsMatcher,
                canActivate: [TermsPageGuard],
                loadChildren: () => import('./route-modules/super-terms/super-terms.module').then(m => m.SuperTermsModule),
                data: {
                    pageType:'superCoachingTerms'
                }
            },
            {
                path: 'lesson/:lessonId',
                canActivate: [RequiresLoginGuard],
                loadChildren: () => import('./route-modules/lesson/lesson.module').then(m => m.LessonModule),
                data: {
                    hideIntercom: true
                }
            },
            {
                path: 'online-test-series',
                loadChildren: () => import('./route-modules/test-series-dashboard/test-series-dashboard.module').then(m => m.TestSeriesDashboardModule),
                data: {
                    subFooterType: PAGE_SEO_SUBFOOTER,
                    pageType:'testSeriesPage'
                }
            },
            {
                path: 'class/:parentId/lesson/:lessonId',
                loadChildren: () => import('./route-modules/lesson/lesson.module').then(m => m.LessonModule),
                data: {
                    hideIntercom: true
                }
            },
            {
                path: 'exam-photo-crop-resize-tool',
                loadChildren: () => import('./route-modules/exam-photo-crop-tool/exam-photo-crop-tool.module').then(m => m.ExamPhotoCropToolModule)
            },
            {
                path: 'course/:classId/video/:videoId',
                loadChildren: () => import('./route-modules/course-video-module/course-video-module.module').then(m => m.CourseVideoModuleModule),
                data: {
                    hideIntercom: true
                }
            },
            {
                path:'live-classes-series/:parentId/lesson/:lessonId',
                canActivate:[MasterClassLessonGuard],
                loadChildren: () => import('./route-modules/lesson/lesson.module').then(m => m.LessonModule),
                data: {
                    hideIntercom: true
                }
            },
            {
                path: 'free-live-tests-and-quizzes',
                canActivate:[ExploreTestsQuizzesGuard],
                loadChildren: () => import('./route-modules/explore-tests-quizzes/explore-tests-quizzes.module').then(m => m.ExploreTestsQuizzesModule),
                data: {
                    pageType:'freeLiveTestsAndQuizzesPage'
                }
            },
            {
                path: 'pyp-search/:searchTerm',
                loadChildren: () => import('./route-modules/pyp-search/pyp-search.module').then(m => m.PypSearchModule),
            },
            {
                path: 'pdf-viewer',
                loadChildren: () => import('./route-modules/pdf-viewer/pdf.module').then(m => m.PdfModule)
            },
            {
                path: 'closed-access-pdf-viewer',
                loadChildren: () => import('./route-modules/closed-access-pdf-viewer/closed-access-pdf-viewer.module').then(m => m.ClosedAccessPdfViewerModule)
            },
            {
                matcher:superTeachersMatcher,
                canActivate:[EducatorsGuard],
                loadChildren: () => import('./route-modules/educators/educators.module').then(m => m.EducatorsModule),
                data: {
                    pageType:'superCoaching'
                }
            },
            {
                path: 'pass-pro',
                canActivate: [PassProPageGuard],
                loadChildren: () => import('./route-modules/pass-pro-page/pass-pro-page.export.module').then(m => m.PassProPageExportModule),
                data: {
                    hideIntercom: true
                }
            },
            {
                path: 'pass-pro/my',
                canActivate: [PassProPageGuard],
                loadChildren: () => import('./route-modules/pass-pro-page/pass-pro-page.export.module').then(m => m.PassProPageExportModule),
                data: {
                    hideIntercom: true
                }
            },
            {
                path: 'pass',
                canActivate:[PassPageGuard],
                loadChildren: () => import('./route-modules/product-comparison/product-comparison-module').then(m => m.ProductComparisonModule),
                data: {
                    hideIntercom: true,
                    subFooterType: PAGE_SEO_SUBFOOTER,
                    pageType:'passPage'
                }
            },
            {
                path: 'pass/my',
                canActivate:[PassPageGuard],
                loadChildren: () => import('./route-modules/product-comparison/product-comparison-module').then(m => m.ProductComparisonModule),
                data: {
                    hideIntercom: true
                }
            },
            {
                path: 'invite/:refLink',
                canActivate:[PassPageGuard],
                loadChildren: () => import('./route-modules/product-comparison/product-comparison-module').then(m => m.ProductComparisonModule),
            },
            {
                path: 'invite/:refLink/my',
                canActivate:[PassPageGuard],
                loadChildren: () => import('./route-modules/product-comparison/product-comparison-module').then(m => m.ProductComparisonModule),
                data: {
                    hideIntercom: true
                }
            },
            {
                // path: ':languageCode/:idOrSlug',
                matcher: wildCardRoutesLanguageMatcher,
                canActivate: [WildCardRoutesGuard],
                loadChildren: () => import('./route-modules/wild-card-routes/wild-card-routes.module').then(m => m.WildCardRoutesModule),
                data: {
                    hideIntercom: true,
                    subFooterType: SEO_GROUP_SUBFOOTER
                }
            },
            {
                //path: ':languageCode/:idOrSlug/previous-year-papers',
                matcher: vernacularPypChildRouteMatcher,
                canActivate: [WildCardRoutesGuard],
                loadChildren: () => import('./route-modules/target-pyp-child-page/target-pyp-child-page.module').then(m => m.TargetPypChildPageModule),
                data: {
                    hideIntercom: true,
                    subFooterType: SEO_GROUP_SUBFOOTER
                }
            },
            {
                // path: ':languageCode/:idOrSlug/:childIdOrSlug',
                matcher: wildCardChildRoutesLanguageMatcher,
                canActivate: [WildCardChildRoutesGuard],
                loadChildren: () => import('./route-modules/wild-card-child-routes/wild-card-child-routes.module').then(m => m.WildCardChildRoutesModule),
                data: {
                    hideIntercom: true,
                    subFooterType: SEO_GROUP_SUBFOOTER
                }
            },
            {
                path: 'previous-year-papers',
                loadChildren: () => import('./route-modules/pyp-dashboard/pyp-dashboard.module').then(m => m.PypDashboardModule),
                data: {
                    hideIntercom: true,
                    pageType:'previousYearPaper',
                    subFooterType: PAGE_SEO_SUBFOOTER
                }
            },
            {   path: 'coupon-code',
                loadChildren: () => import('./route-modules/coupon-code-wrapper/coupon-code-wrapper.module').then(m => m.CouponCodeWrapperModule),
            },
            {   path: 'success-stories',
                canActivateChild:[SelectionsGuard],
                loadChildren: () => import('./route-modules/selections-page/selections.module').then(m => m.SelectionsModule),
                data: {
                    hideIntercom: true
                }
            },
            {   path: 'home',
                canActivate:[HomeGuard],
                loadChildren: () => import('./route-modules/home/home.module').then(m => m.HomeModule),
                data: {
                    pageType:'homePage'
                }
            },
            {   path: 'nps-inline',
                loadChildren: () => import('./route-modules/net-promoter-score/nps-inline-routing.module').then(m => m.NpsInlineRoutingModule),
            },
            {   path: 'nps-modal',
                loadChildren: () => import('./route-modules/net-promoter-score/nps-modal-routing.module').then(m => m.NpsModalRoutingModule),
            },
            {   path: 'reattempt-modal',
                loadChildren: () => import('./route-modules/reattempt-modal/reattempt-modal-routing.module').then(m => m.ReattemptModalRoutingModule),
            },
            {
                path:'free-live-classes',
                // canActivate: [MasterExploreGuard],
                loadChildren: () => import('./route-modules/master-class-explore/master-class-explore.module').then(m => m.MasterClassExploreModule),
                data: {
                    pageType:'freeLiveClassPage'
                }
            },
            {
                path:'live-panel/master-class',
                loadChildren: () => import('./route-modules/master-class-live-panel/master-class-live-panel.module').then(m => m.MasterClassLivePanelModule)
            },
            {
                path:'goal-pitch',
                loadChildren: () => import('./route-modules/goal-pitch/goal-pitch-wrapper.module').then(m => m.GoalPitchIframeWrapperModule)
            },
            {
                path:'logged-out-purchase',
                loadChildren: () => import('./route-modules/logged-out-purchase/logged-out-purchase-wrapper.module').then(m => m.LoggedOutPurchaseIframeWrapperModule)
            },
            {
                path: ':parentSlugOrId/tests/:testId/promotions',
                canActivate:[TestPromotionGuard],
                loadChildren: () => import('./route-modules/test-promotion/test-promotion.module').then(m => m.TestPromotionModule),
            },
            {
                path: 'courses/:parentSlugOrId/tests/:testId/promotions',
                canActivate:[TestPromotionGuard],
                loadChildren: () => import('./route-modules/test-promotion/test-promotion.module').then(m => m.TestPromotionModule),
            },
            {   path: ':slug/scholarship-test',
                canActivate: [ScholarshipGuard],
                loadChildren: () => import('./route-modules/scholarship/scholarship.module').then(m => m.ScholarshipModule),

            },
            {
                matcher: selectMatcher ,
                canActivate: [SelectPageGuard],
                loadChildren: () => import('./route-modules/select-selling-page/select-selling.module').then(m => m.SelectSellingModule),
            },
            {
                path: 'login',
                loadChildren: () => import('./route-modules/login/login-module').then(m => m.LoginModule),
            },
            {
                path: 'enrollment',
                canActivate: [OnboardingGuard],
                loadChildren: () => import('./route-modules/enrollment/enrollment.module').then(m => m.EnrollmentModule),

            },
            {
                path: 'download-current-affairs',
                loadChildren: () => import('./route-modules/download-current-affairs/download-current-affairs.module').then(m => m.DownloadCurrentAffairsModule),
            },
            {
                path: 'pdf-activity/:pdfId',
                loadChildren: () => import('./route-modules/pyp-test-pdf-viewer/pyp-test-pdf-viewer.module').then(m => m.PypTestPdfViewerModule),

            },
            {
                path: 'm/profile/password/reset',
                loadChildren: () => import('./route-modules/login/login-module').then(m => m.LoginModule),
            },
            {
                path: 'creative-login',
                loadChildren: () => import('./route-modules/creative-login/creative-login.module').then(m => m.CreativeLoginModule),
            },
            {
                path: 'error',
                loadChildren: () => import('./route-modules/404-not-found/error-module').then(m => m.ErrorModule)
            },
            {
                path: ':idOrSlug/test-series',
                canActivate: [TestSeriesGuard],
                loadChildren: () => import('./route-modules/test-series/test-series-module').then(m => m.TestSeriesModule),
                data: {
                    hideIntercom: true,
                    subFooterType: SEO_GROUP_SUBFOOTER
                }
            },
            {
                path: ':idOrSlug/test-series/my',
                canActivate: [TestSeriesGuard],
                loadChildren: () => import('./route-modules/test-series/test-series-module').then(m => m.TestSeriesModule),
                data: {
                    hideIntercom: true
                }
            },
            {
                path: ':idOrSlug/live-classes-series',
                canActivate: [MasterSeriesGuard],
                loadChildren: () => import('./route-modules/master-class-series/master-class-series.module').then(m => m.MasterClassSeriesModule),
                data: {
                    hideIntercom: true
                }
            },
            {
                path: '',
                loadChildren: () => import('./route-modules/index-page/index-module').then(m => m.IndexModule),
                pathMatch: 'full',
                data: {
                    subFooterType: PAGE_SEO_SUBFOOTER,
                    hideIntercom: true
                }
            },
            {
                matcher:vernacularHomePageRouteMatcher,
                loadChildren: () => import('./route-modules/index-page/index-module').then(m => m.IndexModule),
                data: {
                    subFooterType: PAGE_SEO_SUBFOOTER,
                    hideIntercom: true
                }
            },
            {   //for :language-code/slug (target and custom pages)
                matcher: vernacularWildcardRouteMatcher,
                canActivate: [WildCardRoutesGuard],
                loadChildren: () => import('./route-modules/wild-card-routes/wild-card-routes.module').then(m => m.WildCardRoutesModule),
                data: {
                    hideIntercom: true,
                    subFooterType: SEO_GROUP_SUBFOOTER
                }
            },
            {
                path: ':idOrSlug/previous-year-papers',
                canActivate: [WildCardRoutesGuard],
                loadChildren: () => import('./route-modules/target-pyp-child-page/target-pyp-child-page.module').then(m => m.TargetPypChildPageModule),
                data: {
                    hideIntercom: true,
                    subFooterType: SEO_GROUP_SUBFOOTER
                }
            },
            {
                path: ':idOrSlug/current-affairs',
                canActivate: [WildCardRoutesGuard],
                loadChildren: () => import('./route-modules/target-current-affairs/target-current-affairs.module').then(m => m.TargetCurrentAffairsModule),
                data: {
                    hideIntercom: true,
                    subFooterType: SEO_GROUP_SUBFOOTER
                }
            },
            {
                path: ':idOrSlug/:childIdOrSlug',
                canActivate: [WildCardChildRoutesGuard],
                loadChildren: () => import('./route-modules/wild-card-child-routes/wild-card-child-routes.module').then(m => m.WildCardChildRoutesModule),
                data: {
                    hideIntercom: true,
                    subFooterType: SEO_GROUP_SUBFOOTER
                }
            },
            {
                path: 'government-exam-calendar',
                loadChildren: () => import('./route-modules/exam-calendar/exam-calendar.module').then(m => m.ExamCalendarModule)
            },
            {
                path: 'study-planner',
                loadChildren: () => import('./route-modules/study-planner-page/study-planner-page.module').then(m => m.StudyPlannerModule)
            },
            {
                path: 'empty-page', //for routing hacks
                loadChildren: () => import('./route-modules/404-not-found/error-module').then(m => m.ErrorModule)
            },
            {
                path: 'exams',
                loadChildren: () => import('./route-modules/new-exams/new-exams.module').then(m => m.NewExamsModule)
            },
            {
                path: 'pass-wrapped',
                loadChildren: () => import('./route-modules/pass-wrapped/pass-wrapped.module').then(m => m.PassWrappedModule)
            },
            {
                matcher:childPageGroupMatcher,
                loadChildren: () => import('./route-modules/child-page-group/child-page-group.module').then(m => m.ChildPageGroupModule)
            },
            {
                path: ':idOrSlug',
                canActivate: [WildCardRoutesGuard],
                loadChildren: () => import('./route-modules/wild-card-routes/wild-card-routes.module').then(m => m.WildCardRoutesModule),
                data: {
                    hideIntercom: true,
                    subFooterType: SEO_GROUP_SUBFOOTER
                }
            },
            {
                path: '**',
                loadChildren: () => import('./route-modules/404-not-found/error-module').then(m => m.ErrorModule)
            }
        ];

        return routes;
    }
    return [];
}
@NgModule({
  imports: [RouterModule.forRoot(getRoutes(null), {
      initialNavigation: "enabledBlocking",
      anchorScrolling: "enabled",
      scrollPositionRestoration: 'disabled',
      onSameUrlNavigation:'reload',
      enableTracing:false,
})],
  exports: [RouterModule]
})
export class AppRoutingModule { 
    constructor(@Optional() @Inject(REQUEST) req, router: Router, private platformService: PlatformService){
        if(!isServer() && !getClientCookie("projectName")){
            let hostValue = window.location.host.includes('plutuseducation.com') ? 'plutus' : 'testbook';
            setClientCookie('projectName',hostValue);
        }
        const routes = getRoutes(req);
        router.resetConfig(routes);
    }
}
