import {baseModel, setupModel} from "@models/types";
import {propertyMaps, arrayModel, objModel, SourceProjectionMapper} from "@models/mapping-framework";
import {Network} from "@core/services/model-manager/main";
import {SearchTargets} from "@models/entity/onboarding/onboarding.adapter";
import { Validator as _ } from "@angular-commons/shared/utility/validator";
export class Properties extends baseModel{
    @propertyMaps('title')
    public title;

    @propertyMaps('logo')
    public logo;
}
setupModel(Properties,{})

export class ExamModel extends baseModel{
    @propertyMaps('properties', objModel(Properties), Properties)
    public properties;

    @propertyMaps('slug')
    public slug;
    @propertyMaps('_id')
    public id;
}
setupModel(ExamModel,{})

export class Exams extends baseModel {
    @propertyMaps('targets', arrayModel(ExamModel), ExamModel)
    public targets;
}
setupModel(Exams, {});

export class SearchExams extends baseModel {
    @propertyMaps('results', objModel(Exams), Exams)
    public results;

    @propertyMaps('searchId', _.str)
    public searchId;
}
setupModel(SearchExams, {});

export class RowDetail extends baseModel {
    @propertyMaps('dateType',_.str)
    public dateType;

    @propertyMaps('examDate',_.str)
    public examDate;

    @propertyMaps('examLink',_.str)
    public examLink;

    @propertyMaps('examLogo',_.str)
    public examLogo;

    @propertyMaps('examMonth',_.str)
    public examMonth;

    @propertyMaps('examName',_.str)
    public examName;
}
setupModel(RowDetail, {});


export class ExamCalendar extends baseModel {
    @propertyMaps('rows', arrayModel(RowDetail), RowDetail)
    public rows;
}
setupModel(ExamCalendar,{});


export class GetExamCalendarApi{
    static apiEndpoint = 'v1/upcoming-exam-data';

    static projection;
    static get __projection(){
        if(!GetExamCalendarApi.projection){
            GetExamCalendarApi.projection = JSON.stringify(new SourceProjectionMapper(ExamCalendar).map());
        }
        return GetExamCalendarApi.projection;
    }

    static apiCall(network:Network){
        return network.get(GetExamCalendarApi.apiEndpoint,{__projection:GetExamCalendarApi.__projection}, 'node');
    }
}

