import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AuthenticationService } from "@core/services/authentication.service";
import { getPageText, hideLoader, isServer, showLoader } from "@shared/utility/tb-common";
import { webOtp } from "@shared/utility/web-otp";
import { OnboardingParams } from "@shared/types/login";
import { isValidEmail } from "@shared/utility/validator";
import { populateOnboardingParams } from "@shared/utility/onboarding";
import { ActivatedRoute } from "@angular/router";
import { PlatformService } from "@core/services/platform-service";
import { getCurrentVisitSource } from "@shared/utility/user";
import { LeadGenerationService } from "@core/services/lead-generation.service";
import {
	selectCurrentPageGoal,
	selectSignupAndVerificationModal
} from "@core/application-state/app.selectors";
import {
	hideMobileSignupAndVerificationModal,
	mobileVerificationSuccess,
} from "@core/application-state/app.actions";
import { TranslateService } from "@ngx-translate/core";
import {GAEventService} from "@core/services/ga-event.service";

const MAX_RETRIES = 3,
	TIME_BETWEEN_RETRY = 30;

@Component({
	selector: "mobile-signup-and-verification",
	templateUrl: "./mobile-signup-and-verification.component.html",
	styleUrls: ["./mobile-signup-and-verification.component.scss"],
})
export class MobileSignupAndVerificationComponent implements OnInit {
	@Input() cssModifierClass:string = '';
	@Input() userCount:string='';
	@Input() onPage:boolean=false;
	@Input() isMgoal:boolean=false;
	@Input() mGoalData: any;
	@Input() mGoal: any;
	@Input() otpFlowHeading;
	@Input() subscriberCount;
	@Input() ctaText;
	@Input() ctaLink;
	@Input() parentId;
	@Input() parentName;
	@Input() isCreativeLogin = false;
	@Input() callReq = false;
	@Input() popupType = 'generic';
	@Input() popupTitle = '';
	@Input() popupCategory = 'landing';
	@Input() popupPagePath = '';
	@Input() page = '';
	@Input() isCurrentAffairs:boolean=false;
	@Input() parentType = '';
	@Input() skipLeadGenerationFired?:boolean=false;
	@Input() shouldFireGAEvent:boolean = false;
	@Input() targetId:string = '';
	@Input() isTrueCallerFlow:boolean = false;

	isMobile = this.platformService.isMobile();
	isSignupOngoing = false;
	truecallerTriggeredOnce:boolean = false;

	creativeLoginParams : any={
		target : {
			landing:{
				popupHeading : popupTitle => `Get Unlimited Access to <span>${popupTitle} Test Series For FREE!</span>`,
				popupSubHeading: "<strong>30,000+ Tests</strong> for all Govt. Exams based on new pattern. ",
				popupFooterText:"<strong>679.8k+</strong> Students already boost their preparation",
				popupTypeImage: "assets/img/signup-popups/target-illustration.svg"},
			leaving: {
				popupHeading : popupTitle => `Leaving Soon? Don’t Miss Out <span> 100+ Free ${popupTitle} Test Series</span>`,
				popupSubHeading: "Also Mock Tests, Free Videos, Quizzes & Study Notes + More",
				popupFooterText: "Valued by <strong>3.6 Crore+</strong> Students",
				popupTypeImage:  "assets/img/signup-popups/leaving-target-illustration.svg"
			}
		},
		targetChild : {
			landing : {
				popupHeading : popupTitle => `Get <span>Latest Updates on ${popupTitle} Exam</span> Date, Salary, Eligibility & More Info`,
				popupSubHeading: "Stay ahead with latest updates on your exams",
				popupFooterText: "Valued by <strong>3.6 Crore+</strong> Students",
				popupTypeImage: "assets/img/signup-popups/target-child-illustration.svg",
			},
			leaving: {
				popupHeading : popupTitle => `Leaving Soon? Don’t Miss Out <span> 100+ Free ${popupTitle} Test Series</span>`,
				popupSubHeading: "Also Mock Tests, Free Videos, Quizzes & Study Notes + More",
				popupFooterText: "Valued by <strong>3.6 Crore+</strong> Students",
				popupTypeImage:  "assets/img/signup-popups/leaving-target-illustration.svg"
			}
		},
		generic: {
			landing : {
				popupHeading : popupTitle => `Start Your Dream <span>Sarkari Naukri Preparation For Free!</span>`,
				popupSubHeading: " Test Series, Free Videos, Quizzes & Study PDF Notes + More",
				popupFooterText: "Valued by <strong>3.6 Crore+</strong> Students",
				popupTypeImage: "assets/img/signup-popups/generic-popup-illustration.svg",
			},

			leaving: {
				popupHeading : popupTitle => `Before You Go! Try Out <span>Free Study Material For Your Exam</span>`,
				popupSubHeading: "Test Series, Free Videos, Quizzes & Study PDF Notes + More",
				popupFooterText: "Valued by <strong>3.6 Crore+</strong> Students",
				popupTypeImage: "assets/img/signup-popups/leaving-generic-illustration.svg"
			}
		},

	}

	uiState: any = {
		isLogin: false,
		isSkippable: false,
		isSignUp: false,
		isVerification: false,
		showModal: false,
		redirect_url: "",
		user: {
			name: { value: "", error: "" },
			email: { value: "", error: "" },
			password: { value: "", error: "", show: false },
			otp: { value: "", error: "" },
			mobile: { value: "", isValid: false, error: "" },
		},
		otp: { requested: 0, nextRequestIn: TIME_BETWEEN_RETRY, error: "" },
		truecaller : {loading:false,error:''},
		strings: {
			mobileAlertText: "",
			checkFieldsAlert: "",
			emptyReferral: "",
			inValidReferral: "",
			requiredAlert: "",
			tryAnotherMethod: "",
			required: "",
			enterValidEmail: "",
			enterCompletOtp: "",
			minimumPasswordLength: "",
			headerText: "",
		}
	};
	onboardingParams: OnboardingParams = {} as OnboardingParams;
	webOtp: webOtp;
	coldbootParams;
	coldbootsub;
	isVerification = false;
	interval = null;
	lastMobileSignup = 0;
	selectedGoal: any;
	signupCtaText: string;
	constructor(
		private store: Store<{}>,
		private authenticationService: AuthenticationService,
		private route: ActivatedRoute,
		private platformService: PlatformService,
		private leadGenerationService: LeadGenerationService,
		private translateService: TranslateService,
		private gAEventService: GAEventService
	) {
		if (!isServer() && (window as any).coldboot) {
			this.coldbootParams = (window as any).coldboot.onComplete;
		}
	}

	ngOnInit(): void {
		this.signupCtaText = this.isCurrentAffairs ? 'TAKE_FREE_TESTS' : 'SIGNUP';
 		this.store.select(selectSignupAndVerificationModal).subscribe((data) => {
			if (data) {
				// if (data.show) {
					let params = {
						redirect_url : (this.page=='QnA' || this.page == 'MCQ') ? '/' : this.popupPagePath,
						...this.route.snapshot.queryParams,
						...data.data,
						modal: true,
						gtmExtras : JSON.stringify({target: this.targetId})
					};
					if(this.onPage){
						params.modal=false;
						if(this.ctaLink){
							params.redirect_url = this.ctaLink;
						}
					}
					this.onboardingParams = populateOnboardingParams(
						params,
						this.platformService.getUserAgent(),
						this.platformService.getPathName()
					);
					this.uiState.isSkippable = data.isSkippable;
					this.uiState.strings.headerText = data.data?.headerText;
					this.uiState.redirect_url = data.data?.redirect_url;
					this.isVerification =
						this.platformService.isLoggedIn() && !data.data?.isMobileVerified;
					this.uiState.user.mobile.value = this.onboardingParams.mobile;
					if (this.uiState.user.mobile.value) {
						this.requestOTP();
					}
				// }
				if(!data?.data?.show){
					this.buildUiState();
				}
				this.uiState.showModal = data?.data?.show;
			}
		});

		this.store.select(selectCurrentPageGoal).subscribe((goal) => {
			if (!goal) {
				return;
			}
			this.selectedGoal = goal;
		});
		this.coldbootsub =
			this.coldbootParams &&
			this.coldbootParams.then((params) => {
				switch (params.awaitId) {
					case "mobilesignupcta":
						this.mobileSignup();
						break;
				}
			});
	}

	fireSignUpActivityEvent(eventName:any){
		this.gAEventService.sendSignupActivityEvent({
		  type : eventName,
		  category :this.popupCategory ,
		  pagePath : this.popupPagePath,
		  page: this.page || getPageText()
		  });
		}

	buildUiState() {
		if(this.otpFlowHeading){
			this.onboardingParams.heading = this.otpFlowHeading || 'Grab the opportunity by registering your mobile number';
		}
		
		let uiState: any = {
			isSignUp: false,
			isLogin: false,
			isVerification: false,
			redirect_url: this.onboardingParams.redirect_url,
			showModal: true,
			user: {
				name: { value: "", error: "" },
				email: { value: "", error: "" },
				password: { value: "", error: "", show: false },
				otp: { value: "", error: "" },
				mobile: { value: "", isValid: false, error: "" },
			},
			otp: { requested: 0, nextRequestIn: TIME_BETWEEN_RETRY, error: "" },
			truecaller : {loading:false,error:''},
			strings: {
				mobileAlertText: "",
				checkFieldsAlert: "",
				emptyReferral: "",
				requiredAlert: "",
				inValidReferral: "",
				tryAnotherMethod: "",
				required: "",
				enterValidEmail: "",
				enterCompletOtp: "",
				minimumPasswordLength: "",
				headerText:
					(this.onboardingParams && this.onboardingParams?.heading) || "",
			},
			subscriberCount: this.subscriberCount,
			ctaText: this.ctaText
		};
		this.uiState = uiState;
		this.loadTranslations();
	}

	loadTranslations() {
		this.uiState.strings = {
			mobileAlertText: this.translateService.instant("MOBILE_ALERT_TEXT"),
			checkFieldsAlert: this.translateService.instant("CHECK_FIELDS_ALERT"),
			emptyReferral: this.translateService.instant("EMPTY_REFERRAL"),
			inValidReferral: this.translateService.instant("INVALID_REFERRAL"),
			requiredAlert: this.translateService.instant("REQUIRED_ALERT"),
			tryAnotherMethod: this.translateService.instant("INVALID_REFERRAL"),
			required: this.translateService.instant("REQUIRED"),
			enterValidEmail: this.translateService.instant("ENTER_VALID_EMAIL"),
			enterCompletOtp: this.translateService.instant("ENTER_COMPLETE_OTP"),
			minimumPasswordLength: this.translateService.instant(
				"MINIMUM_PASSWORD_LENGTH"
			),
			headerText: this.translateService.instant(
				this.onboardingParams?.heading || "GET_STARTED_TEXT"
			),
			enterOtp: this.translateService.instant("ENTER_OTP"),
			incompleteOtp: this.translateService.instant("ENTER_VALID_OTP"),
		};
	}

	closeModal() {
		this.buildUiState();
		this.store.dispatch(hideMobileSignupAndVerificationModal());
	}

	enforceMobile($event: any) {
		let key = ($event.key || "").toLowerCase();
		let model = this.uiState.user.mobile;
		model.isValid = false;
		model.error = "";
		this.mobileKeys($event);
		if (model.value.length !== 10 || !"6789".includes(model.value[0])) {
			return (model.error = this.uiState.strings.mobileAlertText);
		} else {
			model.isValid = true;
		}
	}

	mobileKeys($event: any) {
		const key = ($event.key || "").toLowerCase();
		const model = this.uiState.user.mobile;
		// prevent keys
		if (
			key.length == 1 &&
			(key.match(/[^0-9]/g) || model.value.length >= 10) &&
			!$event.ctrlKey &&
			!$event.metaKey
		) {
			$event.preventDefault();
		}
		// enforce for cases like copy paste
		model.value = model.value.replace(/[^0-9]/g, "");
		if (model.value.length >= 10) {
			model.value = model.value.slice(0, 10);
		}
	}

	signupOnEnter($event: any) {
		const key = ($event.key || "").toLowerCase();
		const model = this.uiState.user.mobile;
		const otp = this.uiState.user.otp;
		this.requiredField(otp);
		if (key === "enter") {
			if (!(model.value.length !== 10 || !"6789".includes(model.value[0]))) {
				this.mobileSignup();
			} else {
				this.uiState.user.mobile.error = this.uiState.strings.mobileAlertText;
			}
		}
	}

	mobileSignup() {
		if(this.isSignupOngoing){return;}
		// do not allow enter to trigger multiple calls within 200ms
		if (Date.now() < this.lastMobileSignup + 200) {
			return;
		}
		this.lastMobileSignup = Date.now();

		const model = this.uiState.user.mobile;
		if (model.value.length !== 10 || !"6789".includes(model.value[0])) {
			return (model.error = this.uiState.strings.mobileAlertText);
		}
		this.isSignupOngoing = true;
		this.authenticationService.getUserDetails(model.value).subscribe(
			(response: any) => {
				model.error = response && !response.success && response.message;
				if (!model.error) {
					this.uiState.user.name.value = response.data.name || "Friend";
					this.requestOTP();
				}
				this.isSignupOngoing = false;
			},
			({ error }) => {
				if (error && error.message) {
					// if number is valid
					this.authenticationService.fireOnboardingPreferenceEvent({
						concern: "signup",
						medium: "TestbookMobile",
					});
					this.isSignupOngoing = false;
					this.requestOTP(false, true);
				}
			}
		);
	}

	requestOTP(call = false, generateOTP = false) {
		if (this.uiState.otp.requested >= MAX_RETRIES) {
			return (this.uiState.user.otp.error =
				this.uiState.strings.tryAnotherMethod);
		}
		let resend;
		if (this.uiState.otp.requested > 0) {
			resend = true;
		}
		this.authenticationService.webOtp.checkForWebOTP().then((otp) => {
			if (otp) {
				this.uiState.user.otp.value = otp;
				this.uiState.user.otp.error = "";
				if(this.uiState.isSignUp){
					this.signupOTP();
				} else if(this.uiState.isLogin){
					this.loginOTP();
				} else if(this.uiState.isVerification){
					this.verifyOtp();
				}
			}
		});
		if(this.isCreativeLogin){
			this.fireSignUpActivityEvent('OTPGenerated');
		}
		this.uiState.otp.requested += 1;
		this.uiState.otp.nextRequestIn = TIME_BETWEEN_RETRY;
		if (this.interval) {
			clearInterval(this.interval);
		}
		this.interval = setInterval(() => {
			this.uiState.otp.nextRequestIn -= 1;
			if (this.uiState.otp.nextRequestIn <= 0) {
				clearInterval(this.interval);
				this.interval = null;
			}
		}, 1000);

		let networkRequest;
		if (generateOTP) {
			networkRequest = this.authenticationService.generateOTP({
				mobile: this.uiState.user.mobile.value,
			});
		} else {
			if (this.isVerification) {
				networkRequest = this.authenticationService.updateMobile(
					this.uiState.user.mobile.value,
					call
				);
			} else {
				networkRequest = this.authenticationService.requestOTP(
					this.uiState.user.mobile.value,
					call,
					resend
				);
			}
		}
		networkRequest.subscribe(
			(response: any) => {
				this.uiState.otp.error =
					response && !response.success && response.message;
				if (this.uiState.otp.error) {
					return;
				}

				if (this.isVerification) {
					this.signupMethod(false, false, true);
				} else if (
					response.data?.isSignUp ||
					(this.uiState.isSignUp && resend)
				) {
					this.signupMethod(false, true, false);
				} else {
					this.signupMethod(true, false, false);
				}
			},
			({ error }) => {
				this.uiState.otp.error = error.message;
			}
		);
	}

	verifyOtp() {
		if (!this.platformService.isLoggedIn()) {
			this.loginOTP();
		} else {
			let userOTP = this.uiState.user.otp;
			const stateOTP = this.uiState.otp;

			if (stateOTP.error) {
				if (userOTP.error) {
					return (stateOTP.error = this.uiState.strings.enterOtp);
				}
			}
			if (userOTP.value.length < 6) {
				return (stateOTP.error = this.uiState.strings.incompleteOtp);
			}
			showLoader();
			this.authenticationService.webOtp.abort();
			this.authenticationService.verifyMobileUpdate(userOTP.value).subscribe(
				(response: any) => {
					stateOTP.error = response && !response.success && response.message;
					if (!stateOTP.error) {
						this.store.dispatch(mobileVerificationSuccess());
						if (this.uiState.redirect_url) {
							window.location.href = this.uiState.redirect_url;
						}
					} else {
						hideLoader();
					}
				},
				({ error }) => {
					stateOTP.error = error.message;
					hideLoader();
				}
			);
		}
	}

	requiredField(model) {
		model.error = "";
		if (model.value.length === 0) {
			model.error = this.uiState.strings.required;
		}
	}

	otpField(model) {
		model.error = "";
		if (model.value.length < 6) {
			model.error = this.uiState.strings.enterCompletOtp;
		}
	}

	signupOTP() {
		const otp = this.uiState.user.otp;
		// const name = this.uiState.user.name;
		// const email = this.uiState.user.email;
		this.otpField(otp);
		// this.requiredField(name);
		// name.error || otp.error || email.error
		if (otp.error) {
			this.uiState.otp.error = this.uiState.strings.requiredAlert;
			return;
		}
		showLoader();
		this.authenticationService.webOtp.abort();
		// removing name and email from sign up, name.value, email.value
		this.authenticationService
			.verifyOTP(this.uiState.user.mobile.value, otp.value)
			.subscribe(
				(response: any) => {
					this.uiState.otp.error =
						response && !response.success && response.message;
					response.data.isSignUp = true; // api doesnt send it but in this case it is always true
					if (!this.uiState.otp.error) {
						this.postLoginSignUpTasks(response);
					} else {
						hideLoader();
					}
				},
				(error) => {
					this.uiState.otp.error = error;
					hideLoader();
				}
			);
	}

	loginOTP() {
		const userOTP = this.uiState.user.otp;
		const stateOTP = this.uiState.otp;
		stateOTP.error = "";
		if (userOTP.error) {
			return (stateOTP.error = this.uiState.strings.enterOtp);
		}
		if (userOTP.value.length < 6) {
			return (stateOTP.error = this.uiState.strings.incompleteOtp);
		}
		if (this.authenticationService.webOtp) {
			this.authenticationService.webOtp.abort();
		}
		showLoader();
		this.authenticationService
			.verifyOTP(this.uiState.user.mobile.value, userOTP.value)
			.subscribe(
				(response: any) => {
					stateOTP.error = response && !response.success && response.message;
					if (!stateOTP.error) {
						this.postLoginSignUpTasks(response);
					} else {
						hideLoader();
					}
				},
				(error) => {
					stateOTP.error = error;
					this.uiState.user.otp.error = error;
					hideLoader();
				}
			);
	}

	signupMethod(isLogin, isSignUp, isVerification) {
		this.uiState.isLogin = !!isLogin;
		this.uiState.isSignUp = !!isSignUp;
		this.uiState.isVerification = !!isVerification;

		// reset ui state on going back to first page but preserve mobile number
		if (
			!this.uiState.isLogin &&
			!this.uiState.isSignUp &&
			!this.uiState.isVerification
		) {
			let preservedMobile = this.uiState.user.mobile.value;
			let isSkippable = this.uiState.isSkippable;
			this.buildUiState();
			if (preservedMobile) {
				this.uiState.user.mobile.value = preservedMobile;
			}
			if (isSkippable) {
				this.uiState.isSkippable = isSkippable;
			}
		}
	}

	focusNextInput($event: any, ele, error: any) {
		const key = ($event.key || "").toLowerCase();
		const email = this.uiState.user.email;
		const name = this.uiState.user.name;
		this.emailField(email);
		this.requiredField(name);
		const isEmailError = email.error && email.value !== "";
		const isNameError = name.error;
		if (key === "enter") {
			if (!error && !isEmailError && !isNameError) {
				ele.focus();
			}
		}
	}

	emailField(model) {
		model.error = "";
		if (model.value.length === 0) {
			model.error = this.uiState.strings.required;
		}
		if (!isValidEmail(model.value)) {
			model.error = this.uiState.strings.enterValidEmail;
		}
	}

	initTruecallerFlow(silent= false){
		if(!silent){
		  this.uiState.truecaller.error = "";
		  this.uiState.truecaller.loading = true;
		}
		const gtmExtras = { ...this.onboardingParams.gtmExtras };
   		gtmExtras.mobileSignUpIn = true;
		this.authenticationService.triggerTrueCaller({ ...this.onboardingParams, gtmExtras })
		.then(response => {
			if(response && response.success){
				this.postLoginSignUpTasks(response);
		  	}
		})
		.catch(()=>{
		  if(!silent){
			this.uiState.truecaller.error = "Please try another method";
			this.uiState.truecaller.loading = false;
		  }
		}).finally(()=>{
			this.truecallerTriggeredOnce = false;
		});
	}

	truecallerOnFocus(){
		if(!this.truecallerTriggeredOnce && this.isTrueCallerFlow){
		  this.initTruecallerFlow(true);
		  this.truecallerTriggeredOnce = true;
		}
	}

	postLoginSignUpTasks(response){
		if(!this.skipLeadGenerationFired && !this.isCreativeLogin){
			let type = 'other';
			let prodId = this.onboardingParams.gtmExtras?.prodId || "";
			if(this.platformService.getQueryParamValue("mGoal") === "1" || this.platformService.getPathName().includes('/mgoal')){
				type = this.parentType;
				if(this.parentType === 'SkillAcademy'){
					prodId = this.parentId;
				}
			} else if(this.onboardingParams.gtmExtras?.type) {
				type = this.onboardingParams.gtmExtras?.type;
			}
			if(this.platformService.getPathName().includes('/mgoal') && this.parentId){
				if (this.parentType === 'goal'){
					this.gAEventService.sendSupercoachingEntityExplore({
						category: 'EnrollNow',
						clickText: 'Enroll Now',
						goalName: this.parentName || '',
						goalID: this.parentId,
						productID: this.parentId,
						page: 'goal',
						productName: this.parentName || ''
					});
				} else if (this.parentType === 'SkillAcademy'){
					this.gAEventService.sendLearnCourseEnrollNowEvent({
						productName: this.parentName,
						productID: this.parentId,
						pagePath: window.top.location.pathname
					})
				}
			}
			const leadObj = {
				action: "complete_signup",
				type: type,
				sid: response?.data?.sid || '',
				mobile: response?.data?.mobile || this.uiState.user.mobile.value,
				parentId:
					this.parentId ||
					this.selectedGoal?.id ||
					this.authenticationService.getGoal()?.id ||
					"",
				client: this.isMobile ? "mweb" : "web",
				utm: getCurrentVisitSource(),
				prodId: prodId,
				prodType: this.onboardingParams.gtmExtras?.prodType || "",
			};
			leadObj.mobile = leadObj?.mobile?.length === 12 ? leadObj?.mobile.substring(2,12) : leadObj?.mobile;
			if(this.parentId){
				this.leadGenerationService.generateLead(leadObj);
			}
		}
		const gtmExtras = { ...this.onboardingParams.gtmExtras };
		gtmExtras.mobileSignUpIn = true;
		if(this.isCreativeLogin){
			this.fireSignUpActivityEvent('signupComplete');
		}
		this.authenticationService.triggerPostLoginTasks(
			{ ...this.onboardingParams, gtmExtras },
			response
		);
	}

}
