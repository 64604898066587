import {Projector} from "../shared/utility/projector";
import {MappingFrameWorkException, ModelMapper} from "./mapping-framework";

interface Metadata{
    type:string
}

export function validateMetadata(subclass) {
    if(!subclass.__metadata || !subclass.__metadata.hasOwnProperty('type')){
        throw '__metadata must have a key \'type\'';
    }
}

export class baseModel {
    static __metadata : Metadata;
    static __projection;
    static adapt;
}

export function setupModel(modelObj,params, customAdapt?){
    // Todo: Engineering task
    // if(modelObj.prototype.__proto__ && modelObj.prototype.__proto__.constructor._propertyMap) {
    //     modelObj.constructor._propertyMap = {...modelObj.prototype.__proto__.constructor._propertyMap};
    // }

    if (customAdapt) {
        modelObj.adapt = customAdapt;
    } else {
        modelObj.adapt = function(rawObj,_forceNotEmptyObjectArrays,cacheStore?){
            try {
                return new ModelMapper(modelObj,_forceNotEmptyObjectArrays,cacheStore).map(rawObj) ;
            } catch (err) {
                console.error(err);
                throw new MappingFrameWorkException(`in MODEL: ${modelObj.name}`);
            }
        };
    }

    if(params){
        if(params.__projection){
            modelObj.__projection = 1;
        }

        if(params.type !== undefined){
            modelObj.__metadata = {type : params.type}
        }
    }
}


export const ENTITY_CLASS = 'classes_entity';

export const ENTITY_CLASS_ENTITY = 'classes_entity_entity';

export const ENTITY_CLASS_INSTRUCTOR = 'classes_instructor_entity';

export const CLASS = 'classes';
export type dashboardTabs =  'leaderboard' | 'currentBoard' | 'allTimeBoard';
