import { Component, OnInit} from '@angular/core';
import { Store } from '@ngrx/store';
import { PlatformService } from "@core/services/platform-service";
import { setLoggedOutPurchaseModalSelectedPass, setLoggedOutPurchaseModalStateUsingIFrame } from '@angular-commons/core/application-state/app.actions';
import { selectRecommendedGoalPlan } from '@angular-commons/core/application-state/app.selectors';
import { Subscription, Observable } from 'rxjs';
import { loggedoutPurchaseModalStateInIFrame } from 'angular-tb-ssr/src/app/route-modules/logged-out-purchase/feature-state/logged-out-purchase-iframe.selector';
@Component({
  selector: 'logged-out-purchase-modal',
  templateUrl: './logged-out-purchase-modal.component.html',
  styleUrls: ['./logged-out-purchase-modal.component.scss']
})
export class LoggedOutPurchaseModalComponent implements OnInit {

recommendedGoalPlan$ = this.store.select(selectRecommendedGoalPlan);
passType:string;
goalId:string="";
pId:string="";

formStage:any=0;
showModal = true;
isMobile:boolean = this.platformService.isMobile();
userDetails:any;
activationCode:string;
passesSub$ : Subscription;
selectedPass:any;
couponCode: string;
loggedoutPurchaseModalStateInIFrame$:  Observable<any> = this.store.select(loggedoutPurchaseModalStateInIFrame);
  stageNameArray = [
    {
      text:'Select Your Plan',
    },
    {
      text:'Your Details',
    },
    {
      text:'Get Access',
    }
]
  constructor(private store:Store<{}>, private platformService:PlatformService) { }

  ngOnInit(): void {

    this.loggedoutPurchaseModalStateInIFrame$.subscribe(state =>{
      this.showModal = state.open;
      // this.formStage = state.formStage;
      if(state.activationCode.trim() != ""){
        this.activationCode = state.activationCode;
      }
      if(state.couponCode.trim() != ""){
        this.couponCode = state.couponCode;
      }
      if(state.userDetails){
        this.userDetails = state.userDetails;
      }
      if(state.selectedPass){
        this.selectedPass = state.selectedPass;
      }
      if(state.open){
        this.platformService.addClass('modal-open');
        this.platformService.hideIntercom();
        this.passType = state.pType;
        this.pId = state.pId;
        this.goalId = state.goalId;
        this.formStage = state.pId ?  1 : 0;
        if(this.passType === "passPro" && !state.stageOne){
          this.formStage = 1;
        }
        if(state.transactionCompleted){
          this.formStage = 2;
        }
      }
    })
  }

  closeModal(){
    this.store.dispatch(setLoggedOutPurchaseModalSelectedPass({selectedPass:null}));
    this.store.dispatch(setLoggedOutPurchaseModalStateUsingIFrame({open:false}));
    this.platformService.postMessageToParentWindow({source:'loggedOutModal',closingLoggedOutModal:true});
    this.platformService.removeClass('modal-open');
  }
}
