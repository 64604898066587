import {MAX_TIMEOUT_DELAY} from "@shared/utility/browser-quirks";
import {getClientCookie, getClientSiteLang, getFromSessionStorage, isServer, setClientCookie, setToSessionStorage} from "@shared/utility/tb-common";
import {getDeferredPromise} from "@shared/utility/defer-promise";

export function getBroadcastingStatus(chatInfo) {
    if(!chatInfo) { return undefined;}
    if(!chatInfo.hasOwnProperty("classStatus")) { return undefined; }
    let streamStatus = {
        isClassCreated        : chatInfo.classStatus === "created",
        isTeacherFinished     : chatInfo.classStatus === "finished",
        isTeacherBroadcasting : chatInfo.classStatus === "broadcasting",
        isLive                : chatInfo.isLive
    };
    return streamStatus;
}

export function isLivePromise(entity:any){
    return new Promise((resolve, reject) => {
        if(entity && (entity.availFrom || entity.startTime)){
            let time=(entity.availFrom || entity.startTime)
            let lessonTime = new Date(time).getTime();
            let curTime = Date.now();
            if(lessonTime < curTime){
                resolve(true);
            } else if(lessonTime - curTime < MAX_TIMEOUT_DELAY && !isServer()){
                setTimeout(() => {
                    resolve(true);
                },lessonTime - curTime);
            } else {
                resolve(lessonTime < curTime);
            }
        }
    })
}

export function m3u8LivePromise(url:string){
    let promise = getDeferredPromise();
    let interval = setInterval(()=>{
        fetch(url).then((response) => {
            if(response.status == 200){
                clearInterval(interval);
                promise.resolve();
            }
        });
    },3000);
    return promise.promise;
}
//this is a hack and ideally should be done in reducer / model instead
//this might be called multiple times so it needs to not modify existing keys
export function addAdditionalKeys(_entity){
    if(!_entity){ return; }
    let entity = {..._entity}
    entity.recordingNotAvailable = entity?.isLive && !(entity?.streamStatus == 'recording' || (entity?.streamStatus == '' && entity?.m3u8));
    entity.isLive = entity.type === 'Live Class' || entity.type === 'Doubt Class';
    //todo ideally this would not be required but some times streams break and chatReplayBecomes true even when it is not supposed to be
    let curTime = new Date().getTime();
    let isLive = entity.isLiveCurrently || (new Date(entity.startTime).getTime() <= curTime) && (curTime <= new Date(entity.endTime).getTime());
    entity.isClassOngoing = entity.streamStatus === 'broadcasting' || entity.streamStatus === 'broken';
    entity.hasChatReplay = !isLive && entity.hasChatReplay;
    //note : this hack has been carried over from entity to lesson, at this point i have no hope of this being fixed.
    return entity;
}


export function getCourseURL(course) {
    if(!course) return {url:'',type:'undefined'};
	let slugs = (course.classProperties && course.classProperties.slug) || (course.slug);
	let isPremium = course.isPremium;

	if(slugs && slugs.prefix && slugs.suffix){
		let prefix = slugs.prefix;
    let suffix = '';

    if (course?.isGoalCourse) {
          suffix = `${slugs.suffix}-course`;
    } else {
          suffix = isPremium ? `online-coaching-${slugs.suffix}` : `${slugs.suffix}-online-course`;
    }
		return {url :`/${prefix}/${suffix}`, type : 'slug' , prefix , suffix, isPremium};

	} else {
		let id = course.id || course._id;
		let title = 'course';
		let slug = title.toLowerCase().replace(/\W+/g,"-").replace(/-$/,'');
		let courses = isPremium ? 'select' : 'courses';

		return {url:`/${courses}/${id}/${slug}`, type:'id',id,slug,isPremium};
	}
}

export function getEntityPageUrl(module,courseId) {
    return `/courses/${courseId}/class/entity/${module.route.join('/')}${module.isDemo ? '?defaultTab=subject' : ''}`;
}

export function createResourseArray(entityData){
    let res=[];
    let resourceArr = [];
    if(entityData?.resourceUrls?.length){
        resourceArr = entityData.resourceUrls;
    } else if(entityData?.resources?.length){
        resourceArr = entityData.resources;
    }

    resourceArr.forEach(resources=>{
        
        for(let key in resources){
            if(resources[key]?.url?.length){
                resources = {...resources, [key]:{
                    ...resources[key],
                    viewUrl: '/pdf-viewer?n='+encodeURIComponent(resources[key].name)+'&u='+encodeURIComponent(resources[key].url)
                }}
                // resources[key].viewUrl = '/pdf-viewer?n='+encodeURIComponent(resources[key].name)+'&u='+encodeURIComponent(resources[key].url);
            }
            res.push(resources[key]);
        }
    });
    return res;
}

export function setRedirectCourseDetails(courseId:string){
    if(courseId){
      let delistedCourseVisitedObjSession = getFromSessionStorage('delistedCourse') || {};
      let delistedCourseVisitedObjCookie = JSON.parse(getClientCookie('delistedCourse') || "{}");
      if(!delistedCourseVisitedObjSession[courseId]){
        delistedCourseVisitedObjSession = {
          [courseId] : 1
        }
        delistedCourseVisitedObjCookie = {
          [courseId]  : delistedCourseVisitedObjCookie[courseId] ? delistedCourseVisitedObjCookie[courseId] + 1 : 1
        }
        setToSessionStorage('delistedCourse',delistedCourseVisitedObjSession);
        setClientCookie('delistedCourse',JSON.stringify(delistedCourseVisitedObjCookie));
        if(delistedCourseVisitedObjCookie[courseId] <= 3){
          return true;
        }
      }
    }
    return false;
  }