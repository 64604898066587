export class CompactObject{
    b?: string;
    n?: number[];
    d?: number[];
    s?: string[];
}

export class SpaceConstrainedSerializer {
    //helpers
    boolIndex = 0;
    numIndex = 0;
    dateIndex = 0;
    strIndex = 0;

    //storage
    bools : string = ""; // bools
    numbers : number[] = [];
    dates : number[] = [];
    strings : string[] = [];

    WriteBool(val:boolean){
        this.bools += val ? '1' : '0';
    }
    ReadBool(): boolean{
        let value = this.bools[this.boolIndex];
        this.boolIndex++;
        return value == '1';
    }

    WriteNum(val:number){
        this.numbers.push(val);
    }
    ReadNum(){
        let value = this.numbers[this.numIndex];
        this.numIndex++;
        return value;
    }

    WriteDate(val:Date){
        this.dates.push(new Date(val).getTime());
    }

    ReadDate(){
        let value = new Date(this.dates[this.dateIndex]);
        this.dateIndex++;
        return value;
    }

    WriteString(val:string){
        this.strings.push(val);
    }

    ReadString(){
        let value = this.strings[this.strIndex];
        this.strIndex++;
        return value;
    }

    getCompactObject(){
        let compactObject: CompactObject = {};
        if(this.bools.length > 0){
            compactObject.b = this.bools;
        }
        if(this.numbers.length > 0){
            compactObject.n = this.numbers;
        }
        if(this.dates.length > 0){
            compactObject.d = this.dates;
        }
        if(this.strings.length > 0){
            compactObject.s = this.strings;
        }
        return compactObject;
    }

    setCompactObject(compactObject: CompactObject){
        this.bools = compactObject.b || '';
        this.numbers = compactObject.n || [];
        this.dates = compactObject.d || [];
        this.strings = compactObject.s || [];
    }
}