<ng-container *ngIf="modalOpen">
    <div class="modal modal--test show"  *ngIf="!isGoalPass && !isEmiFlow" tabindex="-1" role="dialog" >
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="test-image">
                    <a (click)="closeModel()" class="modal-close"></a>
                    <h2 translate>Transaction Successful</h2>
                    <p translate> Continue learning with {{ isPlutus ? 'Plutus Education' : 'Testbook' }} </p>
                    <img loading="lazy" [src]="'/assets/img/scholarship/modal-test.png'" alt="feedback-img">
                    <a *ngIf="isPassPro" (click)="closeModel()"  class="btn btn-primary" style="margin-top: 100px;color:#fff;">Okay</a>
                    <a *ngIf="!isCourse && !isPassPro" (click)="closeModel('TEST_SERIES')"  class="btn btn-primary" style="margin-top: 100px;color:#fff;">Go To Test Series</a>
                    <a *ngIf="isCourse" [href]="courseUrl"  class="btn btn-primary" style="margin-top: 100px;color:#fff;">Go To Course</a>
                </div>
            </div>
        </div>
        <div class="modal-backdrop show"></div>
    </div>

    <div class="modal modal-lg modal--pricing show" *ngIf="isGoalPass && !isEmiFlow">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content" >
                <div class="modal-icon" (click)="closeModel()">
                    <span class="modal-close" ></span>
                </div>
                <div class="modal-body">
                    <div class="modal-heading">
                        <img src="/assets/img/ui-kit/success-rounded.svg" alt="success-icon" height="64" width="64">
                        <h3>Transaction Successful!</h3>
                    </div>
                    <h4>
                        Welcome to
                    </h4>
                    <h3 translate><img src="/assets/img/super/super-coaching.svg?v=2" alt="logo" *ngIf="isGoalPass" width="134" height="32">
                        {{goal?.properties?.title}}</h3>
                    <p>You have unlocked unlimited access to:</p>
                    <div class="pricing-holder" *ngIf="goal?.properties?.pitch?.length > 0">
                        <div class="flex-post" *ngFor="let point of goal?.properties?.pitch">
                            <span class="flex-post-bg">
                                <img loading="lazy" [src]="point.icon" alt="icon" class="flex-post-pic" height="16" width="14">
                            </span>
                            <div class="flex-post-content"><strong>{{point.count}} </strong>{{point.heading}}</div>
                        </div>
                    </div>
                    <p class="validity" *ngIf="goalPlanValidity">
                        Your {{goal?.properties?.title}} Coaching is valid till <strong>{{goalPlanValidity}}.</strong>
                    </p>
                    <a (click)="closeModel('GOAL_DASHBOARD')" href="javascript:void(0)" class="btn btn-primary btn-block">Start your {{goal?.properties?.title}} preparation</a>
                </div>
            </div>
        </div>
        <div class="modal-backdrop show"></div>
    </div>

    <div class="popup" *ngIf="!isGoalPass && isEmiFlow">
        <div class="popup__content">
            <div class="popup__close" (click)="closeModel()"></div>
            <div class="popup__body">
                <div class="h4 text-success">Payment Successful!</div>
                <svg class="icon-success" width="80" height="80">
                    <use xlink:href="#success-icon"></use>
                </svg>
                <div class="h4">Welcome to</div>
                <img class="icon-super" src="/assets/img/super/super-coaching.svg" alt="super" width="188" height="26">
                <div class="h4">{{goal?.properties?.title || goal?.title}}</div>
                <div class="card card--success">
                    <div class="h4 text-center">Your SuperCoaching courses are just a step away</div>
                    <div class="card__flex">
                        <svg width="25" height="25">
                            <use xlink:href="#circle-icon"></use>
                        </svg>
                        <div class="h4">Enable Auto EMI Payment</div>
                    </div>
                    <p>For this step, you'll need your <b>Bank Name</b> and <b>Account Number</b> as well as a <b>Debit Card</b> or <b>Net Banking</b> for verification.</p>
                    <div class="card__help">
                        <svg width="17" height="16">
                            <use xlink:href="#secure-icon"></use>
                        </svg>This process is completely secure and there are no charges involved
                    </div>
                    <a [href]="mandateGuideLinesPdfLink" target="_blank" class="card__footer" (click)="fireEmiInfoScreenVisitedEvent()">
                        Learn How to Enable Auto EMI Payment
                        <svg width="18" height="18" fill="transparent"><use xlink:href="#forward-icon"></use></svg>
                    </a>
                </div>
                <button class="btn" (click)="emiDownPaymentContinueCTA()">Enable Auto EMI Payment</button>
            </div>
        </div>
    </div>
</ng-container>

<svg style="display:none;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <symbol id="circle-icon" viewBox="0 0 25 25">
        <path d="M20.2234 3.25682C20.2234 2.86311 19.9043 2.54395 19.5106 2.54395C19.1169 2.54395 18.7977 2.86311 18.7977 3.25682V4.92311C17.4762 3.83365 15.8863 3.09887 14.1787 2.80493C11.8864 2.41037 9.52833 2.83541 7.51808 4.00552C5.50783 5.17565 3.97368 7.01614 3.18467 9.20426C2.39569 11.3924 2.40222 13.7884 3.20314 15.9722C3.3387 16.3418 3.74825 16.5315 4.11787 16.396C4.48749 16.2604 4.67726 15.8509 4.5417 15.4813C3.8561 13.6119 3.85052 11.5609 4.52589 9.68787C5.20127 7.81485 6.51452 6.23935 8.23532 5.23772C9.9561 4.23611 11.9746 3.87226 13.9368 4.21002C15.3393 4.45142 16.6491 5.04023 17.7521 5.91127H16.4496C16.0559 5.91127 15.7367 6.23044 15.7367 6.62414C15.7367 7.01786 16.0559 7.33701 16.4496 7.33701H19.2729C19.7979 7.33701 20.2234 6.91147 20.2234 6.38651V3.25682Z" fill="#4788F4"/>
        <path d="M4.77539 21.8731C4.77539 22.2668 5.09454 22.586 5.48826 22.586C5.88196 22.586 6.20113 22.2668 6.20113 21.8731V20.2022C7.52256 21.2917 9.1125 22.0265 10.8201 22.3204C13.1124 22.7149 15.4705 22.2899 17.4808 21.1198C19.491 19.9497 21.0252 18.1092 21.8142 15.9211C22.6031 13.733 22.5966 11.3369 21.7957 9.15314C21.6601 8.78351 21.2506 8.59377 20.881 8.72933C20.5113 8.8649 20.3216 9.27442 20.4571 9.64407C21.1427 11.5134 21.1483 13.5644 20.4729 15.4375C19.7975 17.3105 18.4843 18.8859 16.7635 19.8876C15.0427 20.8892 13.0242 21.2531 11.062 20.9153C9.65951 20.6739 8.34975 20.0851 7.24677 19.214H8.82545C9.21915 19.214 9.53832 18.8949 9.53832 18.5012C9.53832 18.1075 9.21915 17.7883 8.82545 17.7883H5.72589C5.20093 17.7883 4.77539 18.2138 4.77539 18.7388V21.8731Z" fill="#4788F4"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6636 9.82769H14.9904C15.277 9.82769 15.5093 9.59541 15.5093 9.30887C15.5093 9.02233 15.277 8.79004 14.9904 8.79004H11.6986H10.4104C10.1239 8.79004 9.89162 9.02233 9.89162 9.30887C9.89162 9.59541 10.1239 9.82769 10.4104 9.82769H11.6986C12.0544 9.82769 12.3957 9.96906 12.6474 10.2207L12.6483 10.2216H10.4104C10.1239 10.2216 9.89162 10.4539 9.89162 10.7404C9.89162 11.027 10.1239 11.2592 10.4104 11.2592H13.0374C13.0157 11.5823 12.8778 11.8879 12.6474 12.1183C12.3957 12.3699 12.0544 12.5113 11.6986 12.5113H10.4104C10.1961 12.5113 10.0038 12.6431 9.92653 12.843C9.84924 13.043 9.90284 13.2698 10.0614 13.414L13.2102 16.2765C13.4222 16.4693 13.7504 16.4536 13.9431 16.2416C14.1358 16.0296 14.1202 15.7015 13.9082 15.5087L11.7518 13.5483C12.3636 13.5347 12.9474 13.2857 13.3811 12.852C13.8061 12.427 14.0538 11.8577 14.0763 11.2592H14.9904C15.277 11.2592 15.5093 11.027 15.5093 10.7404C15.5093 10.4539 15.277 10.2216 14.9904 10.2216H13.8811C13.8213 10.0841 13.7486 9.95214 13.6636 9.82769Z" fill="#4788F4"/>
    </symbol>
    <symbol id="secure-icon" viewBox="0 0 16 17">
        <g clip-path="url(#clip0_130_29600)">
            <path d="M13.4488 1.87403C13.42 1.66774 13.2716 1.49805 13.071 1.44228L6.64029 -0.346097C6.54861 -0.371613 6.45177 -0.371613 6.36002 -0.346097L-0.0706525 1.44228C-0.271297 1.49805 -0.419724 1.66767 -0.448448 1.87403C-0.485746 2.14223 -1.33664 8.47921 0.845844 11.6317C3.02575 14.7804 6.24157 15.5873 6.37738 15.6201C6.41775 15.6298 6.45888 15.6347 6.50015 15.6347C6.54143 15.6347 6.58256 15.6298 6.62293 15.6201C6.7588 15.5873 9.97463 14.7804 12.1545 11.6317C14.3369 8.47928 13.4861 2.1423 13.4488 1.87403ZM10.6471 5.57362L6.26075 9.95999C6.15868 10.0621 6.02483 10.1132 5.89104 10.1132C5.75725 10.1132 5.6234 10.0621 5.52133 9.95999L2.80928 7.24794C2.71119 7.14992 2.65611 7.0169 2.65611 6.87823C2.65611 6.73957 2.71126 6.60655 2.80928 6.50853L3.34777 5.97003C3.55197 5.7659 3.88306 5.76583 4.08719 5.97003L5.89104 7.77388L9.36921 4.29565C9.46723 4.19755 9.60025 4.14248 9.73892 4.14248C9.87758 4.14248 10.0106 4.19755 10.1086 4.29565L10.6471 4.83414C10.8513 5.03834 10.8513 5.36942 10.6471 5.57362Z" fill="#12B870"/>
        </g>
        <defs>
            <clipPath id="clip0_130_29600">
                <rect width="16" height="16" fill="white" transform="translate(0 0.544922)"/>
            </clipPath>
        </defs>
    </symbol>
    <symbol id="forward-icon" viewBox="0 0 16 17">
        <circle cx="7.99998" cy="8.5449" r="6.99998" fill="#E7FAFE"/>
        <path d="M6.66699 5.54492L10.0003 8.54492L6.66699 11.5449" stroke="#0AD0F4" stroke-linecap="round" stroke-linejoin="round"/>
    </symbol>
    <symbol id="success-icon" viewBox="0 0 16 16">
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Tags-&amp;-Indicators/Tick">
                <g id="Tick">
                    <circle id="Oval" fill="#25CD71" cx="8" cy="8" r="8"></circle>
                    <polyline id="Correct" stroke="#FFFFFF" stroke-width="1.4" stroke-linecap="square" points="5 8.97065799 6.89553096 10.6733909 11.5555556 6"></polyline>
                </g>
            </g>
        </g>
    </symbol>
</svg>


