import {createAction, props} from "@ngrx/store";
import { TestSeriesDetails, RelatedTestSeries, ChapterTests, SuggestedTest, Leaderboard} from "@models/entity/test-series/test-series.adapter";
import {TestSubmissions} from "../../../../../../angular-commons/models/entity/targets/target.test.adapter";
import {RegisteredTests} from "../../../../../../angular-commons/models/entity/test-series/test-series.adapter";

export interface ChapterTestSeriesParams {
    tsId: string,
    subSectionId?: string,
    sectionId?: string,
    testType: string,
    skip: number,
    limit: number,
    branchId?:string
}

export const getTestSeriesDetails = createAction('[Test Series] Get Details' , props<{idOrSlug: string,branchId?:string}>());
export const getTestSeriesDetailsLoaded = createAction('[app Sample] Get Details Loaded' , props<{payload:TestSeriesDetails,branchId?:string}>());

export const getRelatedTestSeries = createAction('[Test Series] Get Related TestSeries', props<{id: string}>());
export const getRelatedTestSeriesLoaded = createAction('[Test Series] Get Related TestSeries Loaded', props<{relatedTS: RelatedTestSeries}>());

export const getChapterTests = createAction('[Test Series] Get Chapter Tests', props<ChapterTestSeriesParams>());
export const getChapterTestsLoaded = createAction('[Test Series] Get Chapter Tests Loaded', props<{chapterTests: ChapterTests, skip: number, limit: number}>());
export const getFreeTestsLoaded = createAction('[Test Series] Get Free Tests Loaded', props<{freeTests: ChapterTests}>())

export const getSuggestedTest = createAction('[Test Series] Get Suggested Test', props<{id: string,branchId?:string}>());
export const getSuggestedTestLoaded = createAction('[Test Series] Get Suggested Test Loaded', props<{suggestedTest: SuggestedTest}>());

export const enrollInTestSeries = createAction('[Test Series] Enroll In Test Series', props<{tsId: string}>());
export const unEnrollInTestSeries  = createAction('[Test Series] UnEnroll In Test Series', props<{tsId: string}>());

export const getChapterAttemptedTests = createAction('[Test Series] Get chapter Attempted Tests', props<ChapterTestSeriesParams>());
export const getChapterAttemptedTestsLoaded = createAction('[Test Series] Get chapter Attempted Tests Loaded', props<{attemptedTests: TestSubmissions}>());

export const getTestSeriesRegisteredTests = createAction('[Test Series] Get Registered Tests', props<{testIds: string}>());
export const getTestSeriesRegisteredTestsSuccess = createAction('[Test Series] Get Registered Tests Success', props<{registeredTests: RegisteredTests}>());

export const setStageSelected  = createAction('[Test Series] Send the Current Stage Selected By User', props<{testSeriesId: string,examStage:string}>());

export const getLeaderboardList = createAction('[Test Series] getLeaderboardList', props<{stageName: string, testSeriesId: string}>());
export const getLeaderboardListLoaded = createAction('[Test Series] getLeaderboardListLoaded', props<{payload:Leaderboard}>());

export const getSuggestedFullTest = createAction('[Test Series] Get Suggested Full Test', props<{testSeriesId: string, examStage: string}>());
export const getSuggestedFullTestLoaded = createAction('[Test Series] Get Suggested Full Test Loaded', props<{suggestedFullTest: SuggestedTest}>());

export const currentStageDetails  = createAction('[Test Series] Show the current stage selected by user', props<{testSeriesId: string,currentStage:string}>());

export const showUnattemptedModalStatus  = createAction('[Test Series] Show unAtempted Modal Status', props<{showModal:boolean}>());

export const showTestSeriesPerformaceModal  = createAction('[Test Series] Show TestSeries Performace Modal Status', props<{showModal:boolean}>());

export const showSuggestFullTestModalStatus  = createAction('[Test Series] show Suggest FullTest Modal Status', props<{showModal:boolean}>());

export const sendSelectedBranch = createAction('[Test Series] Send Selected Branch Test Series', props<{testSeriesId: string,selectedBranch?:any,slug:string}>());

export const showBranchModal = createAction('[Test Series] Show Branch Modal Test Series', props<{showModal: boolean}>());

export const currentSelectedBranch = createAction('[Test Series] Current Selected Branch Test Series', props<{selectedBranch: any}>());
