import {Component, OnInit, Input, OnDestroy, Output, EventEmitter} from '@angular/core';
import {hideCouponModal, showCouponModal} from '@core/application-state/app.actions';
import { selectCouponModal, selectAutoCouponApply, selectEventExtras } from '@core/application-state/app.selectors';
import {Store} from "@ngrx/store";
import {TbcoreService as TBCore} from "@core/services/tbcore.service";
import {getTimeLeft} from "../../../../shared/utility/date-utilities";
import {padZero} from "../../../../shared/utility/math-utilities";
import {GAEventService} from "@core/services/ga-event.service"
import {PlatformService} from "@core/services/platform-service";
import {getPageText, hideLivePanel, inIframe, isServer, showLivePanel} from "@shared/utility/tb-common";
import { getQueryStringFromParams } from '@angular-commons/shared/utility/url';
import {ActivatedRoute, Router} from "@angular/router";
import {CouponCodeService} from "@core/services/coupon-code-service";
import {makeStateKey, TransferState} from "@angular/platform-browser";
@Component({
  selector: 'app-coupon-code',
  templateUrl: './coupon-code.component.html',
  styleUrls: ['./coupon-code.component.scss']
})
export class CouponCodeComponent implements OnInit,OnDestroy {
  @Input() onPage = false;
  @Input() isModel = false;
  @Input() course;
  @Input() showCouponText:boolean=false;
  @Input() cssModifierClass:string = '';
  @Input() autoApplyBestCoupon;
  @Input() autoApplyFirstCoupon = true;
  @Input() passType:string = 'default';
  @Input() isLogoutPaymentPage = false;
  @Output() invalidCouponApplied = new EventEmitter<{}>();

  onServer:boolean = isServer();
  isLoggedIn:boolean = this.platformService.isLoggedIn();
  showCouponModal: boolean;
  couponcode: any;
  isMobile:boolean = this.platformService.isMobile();
  coupons: any = [];
  state: string = 'default';
  isLoading = true;
  ticker:any;
  needTimer = false;
  productIds: string[];
  productDetails:object;
  isOpenedFromAJS:boolean = false;
  isServer = isServer();
  productType:string="";
  couponModalType:string='globalPass'
  isCoachingPage:boolean = true;
  autoApplySub$:any;
  modalProductDetailSub$:any;
  destroyed:any = false;
  isInIframe: boolean= false;
  couponFromUrl;
  couponData;
  couponKey;
  showSkeleton: any;
  constructor(private store: Store<{}>,
              private tbCore : TBCore,
              private platformService: PlatformService,
              private route:ActivatedRoute,
              private couponCodeService: CouponCodeService,
              private transferState: TransferState) {
  }
  ngOnDestroy() {
      if(this.autoApplySub$ && this.autoApplySub$.unsubscribe){
          this.autoApplySub$.unsubscribe();
      }
      if(this.modalProductDetailSub$ && this.modalProductDetailSub$.unsubscribe){
          this.modalProductDetailSub$.unsubscribe();
      }
      this.destroyed = true;
      this.couponData.unsubscribe();
  }

    eventExtras:any={};
  getAppliedCoupon(){
      return this.couponCodeService.getCurrAppliedCoupon(this.passType);
  }

  get couponDataTransferState(){
      return this.transferState.get(this.couponKey, null)?.coupons?.[this.passType];
  }
  ngOnInit() {
      let hasCouponKey: any = makeStateKey(`hasCoupon_${this.passType}`);
      const hasCouponKeyFromServer = this.transferState.get(hasCouponKey, null);
      if(hasCouponKeyFromServer !== null && !hasCouponKeyFromServer){
          this.showSkeleton = this.transferState.get(hasCouponKey, null);
      } else {
          this.showSkeleton = this.platformService.isLoggedIn();
      }
      this.couponFromUrl = this.platformService.getQueryParamValue('coupon');
      this.couponKey = makeStateKey(`couponData_${this.passType}`);
      if(this.couponDataTransferState?.length){
          this.coupons = this.transferState.get(this.couponKey, null).coupons?.[this.passType];
          this.isLoading = false;
      }
      if( !this.isLoggedIn) {
        this.isLoading = false;
      }

      this.route.queryParams.subscribe(params => {
        this.isInIframe = Boolean(params['isInIframe']);
      });

      this.isOpenedFromAJS = inIframe() && window.self.location.href.includes('coupon-code');
      this.store.select(selectEventExtras).subscribe((data)=>{
        if(data){
          this.eventExtras=data;
        }
      });

      this.couponData = this.couponCodeService.coupons.subscribe((couponData: any) => {
          if(couponData.coupons?.[this.passType]?.length || this.couponDataTransferState){
              this.coupons = couponData.coupons?.[this.passType] || this.transferState.get(this.couponKey, null).coupons[this.passType];
              this.coupons = this.coupons.map((_coupon)=>{
                  let coupon = {..._coupon}
                  if (new Date(coupon?.expiresOn).getTime() > new Date("0001-01-01T00:00:00Z").getTime()) {
                      const timeLeft = getTimeLeft(coupon.expiresOn);
                      coupon.timer = {h:padZero(timeLeft.h),m:padZero(timeLeft.m),s:padZero(timeLeft.s)};
                      if (!this.needTimer) {
                          if(!isServer()){
                              this.ticker = setInterval( () => this.updateTimer(), 1000);
                          }
                          this.needTimer = true;
                      }

                  } else {
                      coupon.timer = {h:'00', m:'00', s:'00'};
                  }
                  return coupon;
              })
              if(this.coupons && this.coupons.length){
                  this.state = 'default';
                  this.isLoading = false;
              }
              this.productDetails = couponData.productDetails?.[this.passType];
              this.productIds = couponData.productIds?.[this.passType];
              if(this.autoApplyFirstCoupon && this.coupons.length>0){
                  this.couponcode = this.coupons[0].code;
                  this.applyCoupon({code: this.couponFromUrl || this.couponcode});
              }
              if(!this.autoApplyFirstCoupon){
                  this.showSkeleton = false;
              }
              this.isLoading = false;
          } else {
              if(couponData?.coupons && (!couponData.coupons?.[this.passType] || !couponData.coupons?.[this.passType]?.length)){
                  this.showSkeleton = false;
              }
              this.isLoading = false;
              this.coupons = [];
          }
      });
      this.store.select(selectCouponModal).subscribe(couponModal => {
        if(couponModal.show){
            hideLivePanel();
        } else if(this.platformService.currentProjectName() != 'plutus'){
            showLivePanel();
        }
        this.showCouponModal = couponModal.show;
        this.couponModalType = couponModal.ModalType;
      });

  }

  closeModal() {
    if (this.isOpenedFromAJS && !this.onServer) {
      this.sendEvent({close: true});
    } else {
      this.store.dispatch(hideCouponModal());
    }
    this.couponcode = '';
    this.state = 'default';
  }

  //Used to send event to AngularJs parent component when couponcode is opened as Iframe in it.
  public sendEvent(message){
    const TARGET_ORIGIN = window.location.protocol + "//" + window.location.hostname;
    window.parent.postMessage(message, TARGET_ORIGIN);
  }

  applyCoupon(coupon?) {
    this.state = 'isLoading';
    if (coupon) {
      this.couponcode = coupon.code;
    }
    if (this.couponcode) {
      this.couponcode = this.couponcode.toUpperCase();
    }
    if (!this.isServer && this.couponcode != "" && this.couponcode !== true) {
        this.couponCodeService.applyCoupon(this.couponcode).then(
            (data: any) => {
                if(this.state !== 'isLoading' || data.isInitialState){
                    return;
                }
                if ((data?.error?.message)) {
                    this.state = 'invalid';
                    this.invalidCouponApplied.emit();
                } else if (data.products && Object.keys(data.products).length > 0) {
                    this.state = 'couponApplied';
                }
                if (this.state === 'couponApplied') {
                    this.couponCodeService.checkCouponForProduct(data.products, data.coupon, this.passType);
                    if (this.isOpenedFromAJS) {
                        this.sendEvent({close: true, couponcode: this.couponcode});
                    } else {
                        this.store.dispatch(hideCouponModal());
                    }
                }
                this.showSkeleton = this.isLoggedIn && isServer();
            }
        );
    }
  }

  removeCoupon(){
      this.couponCodeService.removeCoupon(this.passType);
  }

  login(fromModal= false){
    this.store.dispatch(hideCouponModal());
    if (this.isOpenedFromAJS) {
      this.sendEvent({close: true, redirectToLogin: true});
    }
    let extraParams: any = {productFirst: 1};
    if (fromModal) {
        extraParams.couponModal = 1;
    }
    let queryParam = getQueryStringFromParams(this.route?.snapshot?.queryParams, extraParams);
    let params = {
      redirect_url : `${this.platformService.getPathName()}${queryParam}`,
    }
    this.tbCore.openSignUpSignInModal(params,true);
  }

  updateTimer(){
      let timerCount = 0;
      for (let coupon of this.coupons) {
        if (coupon.timer && Object.keys(coupon.timer).length > 0) {
          const timeLeft = getTimeLeft(coupon.expiresOn);
          coupon.timer = {h:padZero(timeLeft.h),m:padZero(timeLeft.m),s:padZero(timeLeft.s)};
          if(!(coupon.timer.h === "00" && coupon.timer.m === "00" && coupon.timer.s == "00")){
            timerCount++;
          }
          if(!timerCount) {
            clearInterval(this.ticker);
          }
        }
      }
  }

    openModal() {
        this.store.dispatch(showCouponModal({ModalType:'default',productType:this.productType}));
    }

    cardClick(coupon: any) {
      if(coupon?.code === this.getAppliedCoupon()?.code){
        this.removeCoupon();
      } else {
        this.applyCoupon(coupon);
      }
    }
}
