<div class="modal show" *ngIf="showKindOfferModal">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-icon" (click)="closeKindOfferModal()">
                <span class="modal-close"></span>
            </div>
            <img loading="lazy" [src]="kindOfferDetails.imageInfo.bannerImage" alt="banner" width="100%">
            <div class="modal-body">
                <h3 translate>CONTEST_RULES</h3>
                <p *ngFor="let rule of kindOfferPass.offers.luckyDeal.contestRules">{{rule}}</p>
                <button class="btn btn--cta btn--block" (click)="buyPass()">{{'BUY_KIND_OFFER' | translate : {'title': kindOfferPass.title} }}</button>
            </div>
        </div>
    </div>
    <div class="modal-backdrop show"></div>
</div>