import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaleOfferStripComponent } from './sale-offer-strip.component';


@NgModule({
  declarations: [
    SaleOfferStripComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [SaleOfferStripComponent]
})
export class SaleOfferStripModule { }
