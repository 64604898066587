import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubFooterComponent } from '../sub-footer/sub-footer.component';
import {FooterComponent} from "./footer.component";
import {RouterModule} from "@angular/router";


@NgModule({
  declarations: [SubFooterComponent, FooterComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [SubFooterComponent, FooterComponent]
})
export class FooterModule { }
