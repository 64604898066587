import { PlatformService } from '@angular-commons/core/services/platform-service';
import { isServer } from '@angular-commons/shared/utility/tb-common';
import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChildren} from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { debounceTime, fromEvent, merge, Observable, Subscription} from 'rxjs';
import { StudentService } from "@angular-commons/core/services/student.service";
import { Store } from '@ngrx/store';
import { getEmiMandateData, getStudentPartialEmi } from './feature-state/emi-mandate.actions';
import { selectEmiMandate, selectErrorMessage, selectPartialEmi } from './feature-state/emi-mandate.selector';
import { skipInitialState } from '@angular-commons/shared/utility/ngrx-utils';
import { showAlert } from '@angular-commons/core/application-state/app.actions';


@Component({
  selector: 'emi-mandate-popup',
  templateUrl: './emi-mandate-popup.component.html',
  styleUrls: ['./emi-mandate-popup.component.scss']
})
export class EmiMandatePopup implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren(FormControlName, { read: ElementRef }) formControls: ElementRef[];
  message: { [key: string]: string } = {};
  private validationMessages: {
    [key: string]: { [key: string]: string | { [key: string]: string } };
  };
  mandateForm: FormGroup;
    
  isMobile: boolean = this.platformService.isMobile();
  isServer: boolean = isServer();
  isLoggedIn: boolean = this.platformService.isLoggedIn();
  student:any = {};
  isLoading: boolean = false;
  selectEmiMandate$: Subscription;
  selectErrorMessage$: Subscription;
  selectStudentPartialEmi$: Subscription;
  emiMandateData:any = {};
  partialEmiData:any = {};
  @Input() goalId:string = '';
  @Input() disableCloseBtn: boolean = false;

  @Output() closeModal = new EventEmitter<any>();
  @Output() mandateInfoCollectedEvent = new EventEmitter<any>();
  @Output() mandatePopupViewedEvent = new EventEmitter<any>(); 

  constructor(private fb: FormBuilder,
    private store: Store<{}>,
    private platformService: PlatformService, 
    private studentService:StudentService){}

  ngOnInit(): void {
    this.mandatePopupViewedEvent.emit();
    this.mandateForm = this.fb.group({
      name:['', [Validators.required, Validators.pattern("^[a-zA-Z ]+$")]],
      mobile:['',[Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]
    });

    if(this.isLoggedIn && !this.isServer){
      this.studentService.loadStudent(true).then( (student:any) => {
        let mobile = student.mobile ? student.mobile.substring(2,12) : "";
        student.name && this.mandateForm.get('name').setValue(student.name);
        student.mobile && this.mandateForm.get('mobile').setValue(mobile);
        this.student = student || {};
      });
    }

    this.selectEmiMandate$ = this.store.select(selectEmiMandate).pipe(skipInitialState()).subscribe(data => {
      if(data?.isLoaded){
        this.emiMandateData = data?.mandateDetails;
        if(this.emiMandateData?.link){
          this.isLoading = false;
          window.open(this.emiMandateData?.link, "_blank");
          this.store.dispatch(showAlert({message:"Please reload the page after you have signed the mandate, to access your coaching.", autoClose:false}));
        }else{
          this.isLoading = false;
          this.store.dispatch(showAlert({message:"Something went wrong, Please try again!",timer:5000}));
          this.handleCloseModal();
        }
      }
    });

    this.selectStudentPartialEmi$ = this.store.select(selectPartialEmi).pipe(skipInitialState()).subscribe(data => {
      if(data?.isLoaded){
        this.partialEmiData = data?.emiData;
        let emiId = this.partialEmiData?.id;
        if(emiId){
          let {value} = this.mandateForm;
          this.store.dispatch(getEmiMandateData({ emiId, name: value.name, mobile: value.mobile }));
        }
        else{
          this.isLoading = false;
          this.store.dispatch(showAlert({message:"Something went wrong, Please try again!",timer:5000}));
          this.handleCloseModal();
        }
      }
    });

    this.selectErrorMessage$ = this.store.select(selectErrorMessage).pipe(skipInitialState()).subscribe(error => {
      if(!error){
        return;
      }
      this.isLoading = false;
      this.store.dispatch(showAlert({message:error,timer:5000}));
    })
  }

  ngAfterViewInit(): void {
    const addBlurs: Observable<any>[] = this.formControls.map((formControl: ElementRef) =>
    fromEvent(formControl.nativeElement, 'blur')
    );
    merge(this.mandateForm.valueChanges, ...addBlurs).pipe(debounceTime(500)).subscribe((value) => {
      this.message = this.invalidInputs(
        this.mandateForm
      );
    });
    this.validationMessages = {
      name: {
        required: "Please enter your name",
        pattern: "Please enter valid name"
      },
      mobile: {
        required: "Please enter your number",
        pattern: "Please enter valid number"
      },
    };
  }

  invalidInputs(formGroup: FormGroup): { [key: string]: string } {
    let messages = {};
    for (const input in formGroup.controls) {
      const key = formGroup.controls[input];
      if (key instanceof FormGroup) {
        const nestedGroupMessages = this.invalidInputs(key);
        Object.assign(messages, nestedGroupMessages)
      }
      else {
        if (this.validationMessages[input]) {
          messages[input] = '';
          if (key.errors && (key.dirty || key.touched)) {
            Object.keys(key.errors).map(messageKey => {
              if (this.validationMessages[input][messageKey]) {
                messages[input] = this.validationMessages[input][messageKey];
              }
            });
          }
        }
      }
    }
    return messages;
  }

  onSubmit(){
    if(!this.mandateForm.valid){
      return;
    }
    this.mandateInfoCollectedEvent.emit();
    if(this.goalId){
      this.isLoading = true;
      this.store.dispatch(getStudentPartialEmi({goalId: this.goalId, productType: "goalSubs"}));
    }
  }

  ngOnDestroy(): void {
    if(this.selectEmiMandate$){
      this.selectEmiMandate$.unsubscribe();
    }
    if(this.selectEmiMandate$){
      this.selectEmiMandate$.unsubscribe();
    }
    if(this.selectStudentPartialEmi$){
      this.selectStudentPartialEmi$.unsubscribe();
    }
  }

  handleCloseModal(){
    this.closeModal.emit();
  }

}