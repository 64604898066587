import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StickyFooterComponent } from './sticky-footer.component';
import {RouterModule} from "@angular/router";


@NgModule({
  declarations: [ StickyFooterComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [StickyFooterComponent]
})
export class StickyFooterModule { }
