/*
setTimeout has a maximum value , exceeding which will cause the timer to execute instantly
https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/setTimeout#:~:text=Maximum%20delay%20value
*/
import {isServer} from "@shared/utility/tb-common";
declare var document:any;
export const MAX_TIMEOUT_DELAY = 2147483647;

// some platforms do not work if video is triggered automatically so we treat them as a special case
export function isQuirkyVideoPlatform(){
    if(isServer()){return false;}
    return  /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

export function isVideoFullScreen(){
    if(isServer()){return false;}
    return !!(document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.webkitCurrentFullScreenElement);
}

export function openInNewTab(url) {
    let isPopUpBlocked = !window.open(url, '_blank');
    if (isPopUpBlocked) {
       window.location.href = url;
    }
}
