import {_Test} from "@models/entity/targets/target.test.adapter";
import {_AttemptedTest} from "@models/entity/tests/test.adapter";
import {getCustomMap, isObjectEmpty, isServer, sortObjToArr} from "@shared/utility/tb-common";
import {
    formatDate,
    getDateFormat,
    getExactDaysDiff,
    getOnlyDateFormat, getTimeString,
    isValidDate,
    separateIntoMonths
} from '@shared/utility/date-utilities';
import {RegisteredTests} from "@models/entity/test-series/test-series.adapter";

function isTestArray(obj): obj is _Test[] {
    return Array.isArray(obj);
}

export function mergeRegisteredDetails(tests: _Test[], registeredObj: RegisteredTests) {
    if(!tests || !registeredObj) {
        return tests;
    }

    let regObj = {};
    for(let id of registeredObj.registeredTests) {
        regObj[id] = 1;
    }

    for(let test of tests) {
        if(regObj[test.id]) {
            test.hasRegistered = true;
        }
    }

    return tests;
}

export function mergeLiveRegisteredDetails(_livePayload){
    let livePayload = {..._livePayload}
    if(!livePayload.registeredTests) {
        return livePayload;
    }
    let regObj = {};
    for(let id of livePayload.registeredTests) {
        regObj[id] = 1;
    }
    livePayload.registeredTests = regObj
    livePayload.liveTests = livePayload.liveTests.map((test)=>{
        let _test = {...test}
        _test.hasRegistered = !!regObj[_test.id];
        test = Object.assign({}, _test)
        return test
    })

    return livePayload;
}

export function mergeAttemptDetails(tests: _Test[] | _Test, attemptDetails: _AttemptedTest[] | _AttemptedTest, resumableTests?: Array<string>|boolean): any {

    if (isTestArray(tests)) {
        let attemptedObj = getCustomMap(attemptDetails, 'id');

        let resumableObj = {};
        if (resumableTests && typeof resumableTests === 'object') {
            for (let i in resumableTests) {
                resumableObj[resumableTests[i]] = true;
            }
        }

        let processedTests = [];
        for (let test of tests) {
            if (test.id in attemptedObj) {
                test = {...test, progress: attemptedObj[test.id]};
            }
            if (resumableObj[test.id]) {
                test = {...test, isResumable: true};
            }
            processedTests.push(test);
        }

        return processedTests;
    } else if (tests) {
        let _tests = {...tests};
        _tests.progress = attemptDetails;
        _tests.isResumable = resumableTests;
        return _tests;
    }
}

export function languageModifier(separator,isMixed){
   
    return function (item) {
        return CombineLanguage(item,separator,isMixed);
    }

}

export function CombineLanguage(items,separator,isMixed){
    let combinedLanguage="";

    if(!items){
        return combinedLanguage;
    }

    if(items.length > 1 && items.join){
        combinedLanguage=items.join(separator);
        if(isMixed){
            combinedLanguage+='(mixed)'
        }        
    }
    else{
        combinedLanguage=items[0];
    }   
    return combinedLanguage;
}


export function GetStartTimeFull(time){

    let date = new Date(time);
    let dateAndMonth=formatDate(date,true,true);
    var fullDate=date.toString().split(" ");
    let fullTime=fullDate[4];
    let hour = parseInt(fullTime, 10);
    let ampm=(hour>=12) ? 'PM':'AM';
    hour = hour % 12;
	hour = hour ? hour : 12; // the hour '0' should be '12'
    fullTime=hour.toString()+fullTime.substring(2);
    

    return dateAndMonth + " "+ date.getFullYear() + ", "+ fullTime + " "+ ampm;
    
}
export function generateParentUrls(test, testParentSlug, urlType) {
    let createParentUrl;

   if(test?.isQuiz){
    let testType = test.isLive ? 'tests' : 'quizzes';
    if (urlType === "analysis") {
        createParentUrl = `/${testParentSlug}/${testType}/${test.id}/analysis`;
    } else if(urlType === "solution"){
        createParentUrl = `/${testParentSlug}/tests/${test.id}/#lt-solutions`;
    } else if (urlType === "status") {
        createParentUrl = `/${testParentSlug}/tests/${test.id}/promotions`;
    } else {
        createParentUrl = `/${testParentSlug}/${testType}/${test.id}`;
    }
   } else {
         if (urlType === "analysis") {
            createParentUrl = '/' + testParentSlug + '/tests/' + test.id + '/analysis';
        } else if (urlType === "solution") {
            createParentUrl = '/' + testParentSlug + '/tests/' + test.id + '/#lt-solutions';
        } else if (urlType === "status") {
            createParentUrl = '/' + testParentSlug + '/tests/' + test.id + '/promotions';
        } else {
            createParentUrl = '/' + testParentSlug + '/tests/' + test.id;
        }
    }
        return createParentUrl;
}

export function generateUrls(item,type){
    if(type === "scholarship"){
        item.attemptUrl="ST-"+item.slug+"/tests/"+item.id;
        item.solutionUrl="ST-"+item.slug+"/tests/"+item.id+"#/lt-solutions";
        item.resultUrl="ST-"+item.slug+"/tests/"+item.id+"/analysis";
        item.statusUrl="ST-"+item.slug+"/tests/"+item.id+"/promotions";
        item.autoRegisterUrl=item.slug+"/scholarship-test?autoRegister=1";
        item.pageUrl=item.slug+"/scholarship-test";
        if(item.target){
            item.primaryTarget = item.target[0]
        }
    }
    else if(type === "testSeries"){
        let primaryTarget: any = {
            title: ''
        };
        if(item.target) {
            primaryTarget = item.target.find(itm => itm.isPrimary);
        }
        if(!primaryTarget){
            primaryTarget = item.target[0]
        }
        let id = (primaryTarget && primaryTarget._id) || 'online';
        // TODO Slug to be added
        let testSeriesSlug = 'TS-' + id;
    
    
        item.primaryTarget = primaryTarget;
        item.analysisUrl = `/${testSeriesSlug}/tests/${item.id}/analysis`;
        item.solutionsUrl = `/${testSeriesSlug}/tests/${item.id}/#lt-solutions`;
        item.statusUrl= `/${testSeriesSlug}/tests/${item.id}/promotions`;
        item.testUrl = `/${testSeriesSlug}/tests/${item.id}`;
        item.pdfUrl = `/pdf-viewer?id=${item.pdfId}`;
    }
    else if(type === "quizzes"){
        let primaryTarget: any = {
            title: ''
        };
        if(item.target) {
            primaryTarget = item.target.find(itm => itm.isPrimary);
        }
        if(!primaryTarget){
            primaryTarget = item.target[0];
        }
        item.primaryTarget = primaryTarget;
        let testType = item.isLive ? 'tests' : 'quizzes';
        item.analysisUrl = `/free-online-quizzes/${testType}/${item.id}/analysis`;
        item.solutionsUrl = `/free-online-quizzes/tests/${item.id}/#lt-solutions`;
        item.statusUrl= `/free-online-quizzes/tests/${item.id}/promotions`;
        item.testUrl = `/free-online-quizzes/${testType}/${item.id}/`; 
    }

}

export function setTestStatus(item,startTime,endTime){
    let now = Date.now();
    if (isValidDate(endTime)) {
        item.hasTestExpired = now > new Date(endTime).getTime();
    }

    item.hasTestStarted = true;
    if (isValidDate(startTime)) {
        const startingTime = new Date(startTime);
        item.hasTestStarted = now > startingTime.getTime();

        if(!item.hasTestStarted) {
            item.testStartStr = formatDate(startingTime);
        }
    }

    item.liveTestTimeString = `${getTimeString(new Date(item.startTime))} to ${getTimeString(new Date(item.endTime))}`;
    item.isLiveNow=false;
    if(item.hasTestStarted && !item.hasTestExpired){
        item.isLiveNow=true;
    }

}

export function processTests(testarr) {
    return testarr.map(processTest);

}

export function processAttemptedTestsMonthWise(tests){
    let weeks  = [];
    let today = item => !(getExactDaysDiff(new Date(),item.attemptedOn) > 0);
    if(tests?.length > 0){
        let {data,totalItems} = sortObjToArr(separateIntoMonths(tests.filter(item=>!today(item)),'attemptedOn'),true);
        weeks = data;
        return {weeks,totalItems};
    }   
    return {weeks:[],totalItems:0}
}

export function processPurchaseInfo(info, currentPurchase) {
    info.forEach(ele => {
        switch (ele?.type) {
            case 'passPro':
                currentPurchase.isPassProAccess = true;
                break;
            case 'goalSubs':
                if (ele?.showInPitch) {
                    currentPurchase.isGoalSubsPitchAccess = true;
                    currentPurchase.goalSubsPitch = ele;
                }
                break;
            case 'globalPass':
                currentPurchase.isPassAccess = true;
                break;
        }
    });
}

export function processItemPurchase(item) {
    item.currentPurchase = {
        isPassProAccess: false,
        isPassAccess: false,
        isGoalSubsPitchAccess: false,
        goalSubsPitch: {},
        isGoalSubsAccess: false,
        goalSubs: {}
    };

    if (item?.purchaseInfo?.length) {
        processPurchaseInfo(item.purchaseInfo, item.currentPurchase);
    }

    if (!item?.currentPurchase?.isPassProAccess && !item?.currentPurchase?.isGoalSubsPitchAccess) {
        item.currentPurchase.goalSubs = item?.purchaseInfo?.find(ele => ele.type === 'goalSubs');
        item.currentPurchase.isGoalSubsAccess = true;
    }
}

export function processItemReattemptPurchase(item) {
    item.currentReattemptPurchase = {
        isPassProAccess: false,
        isPassAccess: false,
        isGoalSubsPitchAccess: false,
        goalSubsPitch: {},
        isGoalSubsAccess: false,
        goalSubs: {}
    };

    if (item?.reattemptPurchaseInfo?.length) {
        processPurchaseInfo(item.reattemptPurchaseInfo, item.currentReattemptPurchase);
    }

    if (!item?.currentReattemptPurchase?.isPassProAccess && !item?.currentReattemptPurchase?.isGoalSubsPitchAccess) {
        item.currentReattemptPurchase.goalSubs = item?.reattemptPurchaseInfo?.find(ele => ele.type === 'goalSubs');
        item.currentReattemptPurchase.isGoalSubsAccess = true;
    }
}


export function createWeeksMapFromKey(oldMap,newTests,isAddMoreTest,key){
    let weeksMap = {...oldMap};
    if(!isObjectEmpty(weeksMap) && isAddMoreTest ){
        newTests?.weeks.map(week => {
          if(weeksMap[week[key]]){
              weeksMap[week[key]] = {...weeksMap[week[key]], items : [...weeksMap[week[key]].items, ...week.items]}
          }else{
            weeksMap[week[key]] =  week;
          }
        })
      }else{
        weeksMap = {};
        newTests?.weeks.map(week =>  {
          weeksMap[week[key]] = week;
        })
    }

    return weeksMap || {};
}

export function attemptedProcessTests(testArr){
    return testArr.map(e => {
        // e.isLive = true;
        if (e.details && e.summary) {
            e = {...e, details: {...e.details, isAttempted: true}};
            e = processTest(Object.assign({}, e.details, e.summary)) //(e.details)
        }
        return e;
    });
}

export function processTest(item){

    if(isServer()) {
        item.startTime  = (item.startTime);
        item.expiresOn  = (item.expiresOn);
        item.endTime    = (item.endTime);
        item.availFrom  = (item.availFrom);
        item.availTill  = (item.availTill);
    }

    if (item.startTime) {
        item.startTimeFull = GetStartTimeFull(item.startTime);
    }

    
    item.targetGroupId = item?.targetGroup && item.targetGroup.length &&  (item.targetGroup[0]._id || item.targetGroupId);
    item.targetGroup = item?.targetGroup && item.targetGroup.length &&  (item.targetGroup[0].title || item.targetGroup);

    
    item.targetSuperGroupId = item?.targetSuperGroup && item.targetSuperGroup.length &&  (item.targetSuperGroup[0]._id || item.targetSuperGroupId);
    item.targetSuperGroup = item?.targetSuperGroup && item.targetSuperGroup.length &&  (item.targetSuperGroup[0].title || item.targetSuperGroup);

    
    item.targetId=item?.target && item.target.length && (item.target[0]._id || item.targetId);
    item.targetName = item?.target && item.target.length && (item.target[0].title || item.targetName);
    
    if(item.isLive) {
        item.showExpiryInfo = (getExactDaysDiff(new Date(item.endTime),new Date()) < 15);
    }
    else {
        item.showExpiryInfo = (getExactDaysDiff(new Date(item.expiresOn),new Date()) < 15);
    }

    const now = Date.now();

    if(item.isLive) {
        setTestStatus(item,item.startTime,item.endTime)

    }
    else {
        setTestStatus(item,item.availFrom,item.availTill)
    }
    
    if(item?.isScholarship){
        generateUrls(item,'scholarship')
    }
    else if(item?.isQuiz){
        generateUrls(item,'quizzes')
    }else{
        generateUrls(item,'testSeries')
    }
    

    if(item.isLiveNow === false && !item.hasTestExpired && item.startTime){
     let timeLeft=(new Date(item.startTime).getTime()-now)/86400000;
     item.isCountdownStarted=(timeLeft <1 ) ? true :false;
    }
    else{
        item.isCountdownStarted=false; 
    }

    item.analysisAfter = item.analysisAfter || 30;

    if(item.endTime && item.analysisAfter){
        let ResultTime=new Date(item.endTime).getTime()+(item.analysisAfter*60000);
        item.isResultOut = (ResultTime - now < 0);
        item.resultTime=ResultTime;
    }

    if(!item?.languagesStr) {
        item.languagesStr = 'English';
    }

    processItemPurchase(item);
    
    processItemReattemptPurchase(item);   

    if(item.isAttempted) {
     item.progress = item.progress || {};

    let endtime = new Date(new Date(item.endTime).getTime() + (item.analysisAfter * 60000));
     item = {
         ...item,
         progress: {
             ...item.progress,
             id: item.id || item.progress.id,
             isAttempted : item.isAttempted || item.progress.isAttempted,
             rank : item.rank || item.progress.rank,
             totalStudents : item.totalStudents || item.progress.totalStudents,
             markScored : item.markScored != undefined ? item.markScored : item.progress.markScored,
             attemptedOn : item.attemptedOn || item.progress.attemptedOn,
             correct : item.correct || item.progress.correct,
             attemptedQuestions : item.attemptedQuestions || item.progress.attemptedQuestions,
             timeTaken : item.timeTaken || item.progress.timeTaken,
             attemptedOnStr : item.attemptedOnStr || item.progress.attemptedOnStr,
             resultsOn : getDateFormat(endtime.toISOString(), false, 'DD MM HH MM AMPM'),
             isResumableForNextAttempt:item.isResumableForNextAttempt || item.progress.isResumableForNextAttempt,
             maxAllowedAttempts:item.maxAllowedAttempts || item.progress.maxAllowedAttempts,
             attemptNo:item.attemptNo || item.progress.attemptNo,
             isReattemptable:item.isReattemptable ||item.progress.isReattemptable,
         }
     };
    }
     
    item.isPro = item?.purchaseInfo?.findIndex(ele => 'passPro' == ele.type) > -1;

    if (item.purchaseInfo && item.purchaseInfo[0]?.type === 'goalSubs'){
        item.goalId = item.purchaseInfo[0].id
    }
    return item;
}

export function processTestProgress(progressArr) {
    if(!progressArr) {
        return [];
    }
    progressArr.forEach(test => {
        if(test.progress){
            test.progress.attemptedOnStr = getOnlyDateFormat(test.progress.attemptedOn);
        }else{
            test.attemptedOnStr = getOnlyDateFormat(test.attemptedOn);
        }
    });
    return progressArr
}


//ToDo: Remove this fn
export function addTestAvailableKey(test) {
    if(!test.isPdfAvailable){
        test.isTestAvailable = true;
    }
    return test;
}

export function addTestAvailableKeys(testArr) {
    return testArr.map(addTestAvailableKey);
}

export function getCommaSeparatedTargetIds(targets) {
    return targets.map(i => i._id).join(',');
}

export function modifyToPypUrl(testArr) {
    testArr = testArr.map((test) => {
        let _test = {...test};
        _test.analysisUrl = _test.analysisUrl.replace('TS-', 'PYP-');
        _test.solutionsUrl = _test.solutionsUrl.replace('TS-', 'PYP-');
        _test.testUrl = _test.testUrl.replace('TS-', 'PYP-');
        return _test;
    });
    return testArr;
}
