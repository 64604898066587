import {Component, OnInit, ViewChild, ElementRef, HostListener, NgZone, ChangeDetectorRef} from '@angular/core';
import {TbcoreService as TBCore} from "@core/services/tbcore.service";
import {AuthenticationService} from "@core/services/authentication.service";
import {showAlert, setLocalStorage} from "@shared/utility/tb-common";
import {DomSanitizer} from '@angular/platform-browser';
import {getTimeLeft} from '@shared/utility/date-utilities';
import {padZero} from "@shared/utility/math-utilities";
import {clearStudentCookie} from "@shared/utility/user";
import { ActivatedRoute } from '@angular/router';
import {PlatformService} from "@core/services/platform-service";
import {isServer} from "@shared/utility/tb-common";
import {toggleElementVisibility, toggleModal} from "@shared/utility/dom";

if(typeof window !== 'undefined') {
    var mflmSSR = window['mflmSSR'];
    var checkForOTP = window['checkForOTP'];
    var isWebOTPAPISupported = window['isWebOTPAPISupported'];
}

@Component({
  selector: 'app-login-signup',
  templateUrl: './login-signup.component.html',
  styleUrls: ['./login-signup.component.scss']
})
export class LoginSignupComponent implements OnInit {

  isMobile: boolean = this.platformService.isMobile();
  isMobileFirstLoginModal : boolean = false;
  mobileNumber: string;
  userEmail: string;
  userName: string;
  nextUrl:string = '';
  callback: string ='';
  isSignUp: boolean;
  sid: string = '-1';
  status:string = 'init';
  OTP = ['', '', '', '', '', ''];
  entity;
  heading:string;
  targets:string;
  queryParam:string = '';
  classConditions = this.getClassConditions();

  resendCount = 0;
  OTPTimerStr = '';
  nextOTPRequestTime;

  showResend = false;
  noMoreChances = false;
  verifying = false;
  shouldModalSizeIncrease=false;

  ac :any;
  hideGraphic = false;
  hideTimer;
  @ViewChild('otp1') otp1 : ElementRef;
  @ViewChild('otp2') otp2 : ElementRef;
  @ViewChild('otp3') otp3 : ElementRef;
  @ViewChild('otp4') otp4 : ElementRef;
  @ViewChild('otp5') otp5 : ElementRef;
  @ViewChild('otp6') otp6 : ElementRef;
  OTPboxes=[]

  getClassConditions(){
      if(isServer()){
          return [];
      }
      return typeof window !== 'undefined' && window['classConditions'] || [];
  }
  constructor(private tbCore : TBCore,
              private auth:AuthenticationService,
              private sanitizer: DomSanitizer,
              private route: ActivatedRoute,
              private platformService: PlatformService,
              private ngZone : NgZone,
              private changeDetectorRef : ChangeDetectorRef) {
      if(!isServer() && isWebOTPAPISupported){
          this.ac = new AbortController();
      }
      if(this.platformService.getPathName(true) === '/login'){
        this.tbCore.setCanonicalLink('https://testbook.com/login');
      }
  }

  ngOnInit() {
    if(!isServer()){
        // this is actually good code, i promise.
        this.ngZone.runOutsideAngular(()=>{
            // makes it so that angular ignores all the 'message' events
            window.addEventListener("message",(e)=>{
                let data = e && e.data || {};
                if(!data.source || data.source != 'signupInitiated'){ return;}
                this.shouldModalSizeIncrease = data.isDesktopOnboarding;
                // and only triggers a changeDetection when we actually want it
                this.changeDetectorRef.markForCheck();
            });
        });
    }
    this.OTPboxes = [this.otp1,this.otp2,this.otp3,this.otp4, this.otp5, this.otp6];

      if(!isServer()) {
          if(!this.platformService.isLoggedIn()){
            clearStudentCookie();
          }
          try{
            this.tbCore.loginActions();
          } catch (e) {
            console.error(e);
          }
          this.targets = window['autoEnrollTargets'];
          this.route.queryParamMap.subscribe(queryParamsMap => {
              this.queryParam = queryParamsMap.get("mobileOnly");
          });
      }
      if(mflmSSR){
          mflmSSR._openModal = (params) => this.openModal(params);
          mflmSSR._request = (mobile, name, email) => this.request({mobile, name, email});
          if(mflmSSR.state.status === 'requested'){
              mflmSSR.closeModal();
              this.openModal({classConditions: mflmSSR.state.classConditions, entity:this.entity || mflmSSR.state.entity,url: this.nextUrl || mflmSSR.state.nextUrl, callback: mflmSSR.state.callback});
              this.status = mflmSSR.state.status;
              this.mobileNumber = mflmSSR.state.mobile;
              this.userName = mflmSSR.state.username;
              this.userEmail = mflmSSR.state.email;
          }
      }
  }

  openModal(params){

    if (typeof window !== 'undefined' && window['classConditions'] && window['classConditions'].length > 0) {
        this.classConditions = window['classConditions'];
    } else if (params.classConditions && params.classConditions.length > 0) {
        this.classConditions = params.classConditions;
    }
    this.status = 'open';
    this.heading = 'Register to get access';
    if(params && params.url){
        this.nextUrl = params.url;
    } else {
        this.nextUrl = undefined;
    }

    if(params && params.callback && typeof params.callback === 'function') {
        this.callback = params.callback;
    }

    if ( mflmSSR && this.nextUrl && this.platformService.getQueryParamValue('curriculum', this.nextUrl)) {
        var LS_LEAD_GENERATED_EVENT_INFO_KEY = 'LS_LEAD_GENERATED_EVENT_INFO_KEY';
        var eventInfo = {
            clickText : 'Download Course Curriculum',
            module : 'CourseCurriculumDownload',
            pagePath : window.location.pathname+ window.location.search,
            page : "SkillAcademyExternalPage - Specific Course Selling Page"
        }
        setLocalStorage(LS_LEAD_GENERATED_EVENT_INFO_KEY, JSON.stringify(eventInfo));
    }

    if(this.platformService.isLoggedIn()) {
        if (mflmSSR) {
            mflmSSR.verifyModal(this.nextUrl);
            return;
        } else {
            return this.postLogin();
        }
    }


    if(params && params.entity){
      this.entity = params.entity;
      this.entity.isTest = ['quiz','test','practice'].includes(this.entity.type);
      this.entity.isVideo = !this.entity.isTest;
      this.entity.thumbnail = this.sanitizer.bypassSecurityTrustStyle(this.entity.thumbnail)
    } else {
        this.entity = undefined;
    }
    this.isMobileFirstLoginModal = true;
    document.body.classList.add('modal-open');
  }
  closeModal(){
      this.isMobileFirstLoginModal = false;
      document.body.classList.remove('modal-open');
      toggleElementVisibility('#tbSignUpModal', false)
      toggleModal('#tbSignUpModal', false);
  }

  mobileEnforce($event){

      if (this.mobileNumber) {
         this.mobileNumber = this.mobileNumber.replace(/[^0-9]/g, '');
         if(this.mobileNumber.length >=10) {
            this.mobileNumber = this.mobileNumber.slice(0,10);
        }
      }
  }

  request(params){
      let mobile = params.mobile;
      let name = "";
      name = params.name;

    if(mobile.length !== 10 || !'6789'.includes(mobile[0])){
       return showAlert('Please enter 10 digit mobile number starting with 6,7,8 or 9 only');
    }

    if(!this.queryParam && !name){
        return showAlert('Please enter your name');
    }

    if(mflmSSR){
        mflmSSR.closeModal();
        this.openModal({entity:this.entity || mflmSSR.state.entity,url: this.nextUrl || mflmSSR.state.nextUrl, callback: this.callback || mflmSSR.state.callback});
    }

    this.status = 'requesting';
    this.mobileNumber = mobile;

    //start flow
    this.auth.generateOTP(params).subscribe( (res:any) => {
        this.status = 'requested';
        setTimeout( ()=> this.focusOTPBox(0),100);
        if (res && res.data && typeof res.data.isSignUp !== 'undefined') {
            this.isSignUp = res.data.isSignUp;
        }
        if (isWebOTPAPISupported && checkForOTP && mflmSSR) {
            checkForOTP(this.ac.signal).then((otp: {code: string; type: string}) => {
                this.OTP[0] = otp.code[0];
                this.OTP[1] = otp.code[1];
                this.OTP[2] = otp.code[2];
                this.OTP[3] = otp.code[3];
                this.OTP[4] = otp.code[4];
                this.OTP[5] = otp.code[5];
                this.verifyOTP();
            }).catch(err => {
              console.log(err);
            });
        }
    } ,_ => {
        showAlert('Unable to Request OTP : please check your number and try again');
    })


  }
  focusOTPBox(id){
      this.OTPboxes = [this.otp1,this.otp2,this.otp3,this.otp4, this.otp5, this.otp6];
      this.OTPboxes[id] && this.OTPboxes[id].nativeElement && this.OTPboxes[id].nativeElement.focus()
  }
  startTimer(){
     this.nextOTPRequestTime = new Date().getTime() + 2*60*1000;  //2 mins in future

     let timer = setInterval( () => {
          let timeLeft = getTimeLeft(this.nextOTPRequestTime);
          this.OTPTimerStr = `${padZero(timeLeft.m)}:${padZero(timeLeft.s)}`;

          if(timeLeft.m === timeLeft.s && timeLeft.s === 0){
              clearInterval(timer);
              this.showResend = true;
          }

      },1000);
  }
  requestOTP(){
      let params = {
          mobile: this.mobileNumber
      };
      if(!this.queryParam){
          if(this.userName){
            params["name"] = this.userName;
          }

          if(this.userEmail){
            params["email"] = this.userEmail;
          }
      }
      this.request(params);
      this.startTimer()
  }

  resetOTP(){
      this.OTP[0] = '';
      this.OTP[1] = '';
      this.OTP[2] = '';
      this.OTP[3] = '';
      this.OTP[4] = '';
      this.OTP[5] = '';
  }

  resendOTP(){
    this.showResend = false;
    this.resetOTP();
    if(this.resendCount < 3){
      let params = {
        mobile: this.mobileNumber
      };
      if(!this.queryParam){
        if(this.userName){
          params["name"] = this.userName;
        }

        if(this.userEmail){
          params["email"] = this.userEmail;
        }
    }

      this.request(params);
      this.startTimer();
      this.resendCount +=1;
    } else {
        this.noMoreChances = true;
    }
  }

  nextOTP($event,boxId){
      if($event.key === "Backspace"){
          this.OTP[boxId] = '';
          this.focusOTPBox(boxId-1);
          return;
      }
      if(!'0123456789'.includes(this.OTP[boxId])){
          this.OTP[boxId] = '';
      } else {
          this.focusOTPBox(boxId+1);
      }
  }
  postLogin(){
      if (mflmSSR) {
        //If we signed up through mflmSSR flow, fire signUp event else fire signIn event, and either way fire lead_generated event
        this.isSignUp ? window['pushSignUpDataToDL']() : window['pushSignInDataToDL']();
        window['pushLeadGeneratedDataToDL']();
        const registerDemoModule = this.callback || mflmSSR.state.callback;
        if (registerDemoModule && typeof registerDemoModule === 'function') {
            registerDemoModule(this.entity.id, this.entity.type, this.entity.classId, this.sid, true);
            return;
        }
      }
      if(this.nextUrl){
          window.location.href = this.nextUrl || '/home';
      } else {
          window.location.reload();
      }
  }
  onPaste($event){
      let clipboard = ($event.clipboardData || window['clipboardData']);
      let pastedText = clipboard && clipboard.getData('text');
      pastedText = pastedText.replace(/[^0-9]/g, '');
      if(pastedText.length == 6){
          this.OTP[0] = pastedText[0];
          this.OTP[1] = pastedText[1];
          this.OTP[2] = pastedText[2];
          this.OTP[3] = pastedText[3];
          this.OTP[4] = pastedText[4];
          this.OTP[5] = pastedText[5];
      }
  }
  verifyOTP($event?: MouseEvent ){
    if($event && this.ac) {
        this.ac.abort();
    }
    let otp = this.OTP.join('');

    if(otp.length !== 6) return showAlert('Please enter the OTP');
    this.verifying = true;
    this.auth.verifyOTP(this.mobileNumber,otp).subscribe( resp => {
        this.targets = this.targets || window['autoEnrollTargets'];

        if(this.targets) {
            this.auth.addTarget(this.targets).subscribe(() => this.postLogin(),() => this.postLogin())
        } else {
            this.postLogin();
        }
        this.sid = resp && resp.data && resp.data.sid || "-1";
    }, error =>{
        showAlert('Unable to Login : ' + error );
        this.verifying = false;
    })
  }

  boxFocus(){
      clearTimeout(this.hideTimer);
      this.hideGraphic = true;
  }

  boxBlur(){
      this.hideTimer = setTimeout( ()=> { this.hideGraphic = false; },100);

      let otp = this.OTP.join('');

      /*if(this.state === "open"){
          //attempt number auto submit
          setTimeout(() => {if(this.isMobileFirstLoginModal) return this.requestOTP() },500);
      } else if( this.state === "requested" && otp.length === 4){
          //otp auto submit
          setTimeout(() => {if(this.isMobileFirstLoginModal) return this.verifyOTP() },500);
      }*/
  }
  onHiddenInputChange(event) {
      const otp = event.target.value;
      this.OTP[0] = otp[0];
      this.OTP[1] = otp[1];
      this.OTP[2] = otp[2];
      this.OTP[3] = otp[3];
      this.OTP[4] = otp[4];
      this.OTP[5] = otp[5];
  }
}
