import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { ProductAccessService } from "@core/services/product-access.service";
import { TransferStateManager } from "@core/services/transferStateManager";
import { PlatformService } from "@core/services/platform-service";
import { getPassProPageInfo } from "@core/application-state/app.actions";
import { selectPassProDetails } from "@core/application-state/app.selectors";

@Component({
    selector: 'pass-pro-features',
    templateUrl: './pass-pro-feature-pitch.component.html',
    styleUrls: ['./pass-pro-feature-pitch.component.scss']
})

export class PassProFeaturePitchComponent implements OnInit, OnDestroy {
    @Input() cssModifierClass: string = '';
    @Input() iconType: string = 'light';
    @Input() showHeading = false;
    @Input() showComparisonBenefits = false;
    passProData$ = this.store.select(selectPassProDetails);
    isMobile = this.platformService.isMobile();  
    benefits: any[] = [];
    passPitch: any;
    hasPassAccess = false;
    hasProAccess = false;
    private passProPageData$: Subscription;

    constructor(
        private transferStateManager: TransferStateManager,
        private store: Store,
        private platformService: PlatformService,
        private productAccessService: ProductAccessService
    ) { }

    ngOnInit() {
        this.getProFeatures();
        this.getForPassAndPassProAccess();
    }

    ngOnDestroy() {
        if (this.passProPageData$) {
            this.passProPageData$.unsubscribe();
        }
    }

    getProFeatures() {
        this.passProPageData$ = this.transferStateManager.get(
            'passProPageInfo',
            getPassProPageInfo({ pageType: 'passProScreen' }),
            this.passProData$,
            (value) => {
                if (value) {
                    if (value?.getAccessTo?.length) {
                        if (!this.showComparisonBenefits) {
                            this.benefits = value.getAccessTo;
                            if (this.isMobile) {
                                this.passPitch = this.benefits[0];
                                this.benefits = this.benefits.slice(1);
                            }
                        } else {
                            this.benefits = value.benefits;
                        }
                    }
                }
            }
        );
    }

    getForPassAndPassProAccess() {
        this.productAccessService.passAndPassProActiveState().then(access => {
            this.hasPassAccess = !!access?.passAccess;
            this.hasProAccess = !!access?.passProAccess;
        });
    }
}
