import {TestSeries} from "@models/entity/test-series/test-series.adapter";
import {getCommaSeparatedString} from "@shared/utility/tb-common";
import {_Test} from "@models/entity/targets/target.test.adapter";

interface GAEvent {
     event:string;
     info:object;
}
// to keep ga-events free from tb-common dependencies
function isMobile() : boolean {
    if(isServer()) {
        return false;
    }
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

declare var global;
function isServer() {
    return (typeof global !== "undefined");
}

export class signUpEvent implements GAEvent {
    event:string = "signUp";
    info:object;
    sid:string = "";
    signUp = true;
    private static defaultInfo = {
        medium: 'Testbook',
        client: 'Website',
        module: '',
        clickText: '',
        page: '',
        pagePath: '',
    }

    constructor(eventInfo:object) {
        this.info = {...signUpEvent.defaultInfo,
            client: isMobile() ? 'Mweb' : 'Website',
            ...eventInfo};
    }
}

export class signInEvent implements GAEvent {
    event:string = "signIn";
    info:object;
    signUp = false;
    private static defaultInfo = {
        medium: 'Testbook',
        client:  'Website',
        module: '',
        clickText: '',
        page: '',
        pagePath: ''
    }

    constructor(eventInfo:object) {
        this.info = {...signInEvent.defaultInfo,
            client: isMobile() ? 'Mweb' : 'Website',
            ...eventInfo};
    }
}

export class LeadGeneratedEvent implements GAEvent {
    event:string = "lead_generated";
    info:object;
    private static defaultInfo = {
        module: '',
        clickText: '',
        page: '',
        pagePath: ''
    }

    constructor(eventInfo:object) {
        this.info = {...LeadGeneratedEvent.defaultInfo, ...eventInfo};
    }
}

export class onboardingPreference implements GAEvent {
    event:string = "OnboardingPreference";
    info:object;

    private static defaultInfo = {
        medium: 'TestbookMobile', //TestbookEmail, TestbookMobile
        client: 'Website',
        concern: 'login',// signup
        page:'',
        pagePath: ''
    }

    constructor(eventInfo:object) {
        this.info = {...onboardingPreference.defaultInfo,
            client: isMobile() ? 'Mweb' : 'Website',
            pagePath: (window.top.location.href !== window.location.href) ? document.referrer : window.location.pathname + window.location.search,
            ...eventInfo};
    }
}


export class onboardingModelOpen implements GAEvent {
    event:string = "OnboardingModelOpen";
    info:object;

    private static defaultInfo = {
        client: 'Website',
        concern: 'login',
        page: '',
        pagePath: '',
        isRedirect: false,
        isAmp: false
    }

    constructor(eventInfo:object) {
        this.info = {...onboardingModelOpen.defaultInfo,
            client: isMobile() ? 'Mweb' : 'Website',
            pagePath: (window.top.location.href !== window.location.href) ? document.referrer : window.location.pathname + window.location.search,
            isRedirect: (window.top.location.href === window.location.href) && !!document.referrer,
            isAmp: (window.top.location.href === window.location.href) && document.referrer.toLowerCase().includes('amp'), //todo validate
            ...eventInfo};
    }
}


export class AddedToMyDoubtsEvent implements GAEvent {
    event:string = "added_to_my_doubts";
    info:object;

    private static defaultInfo = {
       medium:'Testbook',
       client:'Website',
       module: 'DoubtsAndDiscussion',
       action:'AddedToMyDoubts',   }

   constructor(eventInfo:object) {
       this.info = {...AddedToMyDoubtsEvent.defaultInfo, ...eventInfo};
   }
}

export class RemovedFromMyDoubtsEvent implements GAEvent {
    event:string = "removed_from_my_doubts";
    info:object;

    private static defaultInfo = {
       medium:'Testbook',
       client:'Website',
       module: 'DoubtsAndDiscussion',
       action:'RemovedFromMyDoubts',   }

   constructor(eventInfo:object) {
       this.info = {...RemovedFromMyDoubtsEvent.defaultInfo, ...eventInfo};
   }
}

export class ReportADoubtButtonClickedEvent implements GAEvent {
    event:string = "report_a_doubt_button_clicked";
    info:object;

    private static defaultInfo = {
       medium:'Testbook',
       client:'Website',
       module: 'DoubtsAndDiscussion',
       action:'ReportedTheDoubt'
    }

   constructor(eventInfo:object) {
       this.info = {...ReportADoubtButtonClickedEvent.defaultInfo, ...eventInfo};
   }
}

export class ReportedTheDoubtSuccessfullyEvent implements GAEvent {
    event:string = "reported_the_doubt_successfully";
    info:object;

    private static defaultInfo = {
       medium:'Testbook',
       client:'Website',
       module: 'DoubtsAndDiscussion',
       action:'ReportedDoubtSuccessfully',
   }

   constructor(eventInfo:object) {
       this.info = {...ReportedTheDoubtSuccessfullyEvent.defaultInfo, ...eventInfo};
   }
}

export class PostAnswerButtonClickedEvent implements GAEvent {
    event:string = "post_answer_button_clicked";
    info:object;

    private static defaultInfo = {
       medium:'Testbook',
       client:'Website',
       module: 'DoubtsAndDiscussion',
       action:'AnswerPosted',
   }

   constructor(eventInfo:object) {
       this.info = {...PostAnswerButtonClickedEvent.defaultInfo, ...eventInfo};
   }
}

export class DoubtUpvoteEvent implements GAEvent {
    event:string = "doubt_upvote";
    info:object;

    private static defaultInfo = {
       medium:'Testbook',
       client:'Website',
       module: 'DoubtsAndDiscussion',
       action:'DoubtUpvoted',
   }

   constructor(eventInfo:object) {
       this.info = {...DoubtUpvoteEvent.defaultInfo, ...eventInfo};
   }
}

export class EntitySearchedEvent implements GAEvent {
    event:string = "entity_searched";
    info:object;

    private static defaultInfo = {
       medium:'Testbook',
       client:'Website',
       module: 'CourseEntity',
       action:'SearchKeyword',
   }

   constructor(eventInfo:object) {
       this.info = {...EntitySearchedEvent.defaultInfo, ...eventInfo};
   }
}

export class EntityClickedEvent implements GAEvent {
    event:string = "entity_clicked";
    info:object;

    private static defaultInfo = {
       medium:'Testbook',
       client:'Website',
       module: 'CourseEntity',
       action:'EntityClicked',
   }

   constructor(eventInfo:object) {
       this.info = {...EntityClickedEvent.defaultInfo, ...eventInfo};
   }
}

export class NotesAddedSuccessEvent implements GAEvent {
    event:string = "notes_added_success";
    info:object;

    private static defaultInfo = {
       medium:'Testbook',
       client:'Website',
       module: 'CourseEntity',
       action:'NoteAdded',
   }

   constructor(eventInfo:object) {
       this.info = {...NotesAddedSuccessEvent.defaultInfo, ...eventInfo};
   }
}

//Events for modals that appear while signing up
export class ModalViewedEvent implements GAEvent {
    event:string = "modal_viewed";
    info:object;

    private static defaultInfo = {}

   constructor(eventInfo:object) {
       this.info = {...ModalViewedEvent.defaultInfo, ...eventInfo};
   }
}

export class ExamPreferenceSelectedEvent implements GAEvent {
    event:string = "exam_preference_selected";
    info:object;

    private static defaultInfo = {}

   constructor(eventInfo:object) {
       this.info = {...ExamPreferenceSelectedEvent.defaultInfo, ...eventInfo};
   }
}
export class DemoModuleViewedEvent implements GAEvent {
    event:string = "demo_module_viewed";
    info:object;

    private static defaultInfo = {
        productCategory : 'SelectCourse',
        target : '',
        targetGroup : '',
        superGroup : '',
        moduleName: '',
        moduleID : '',
        moduleType: '',
        productName: '',
        productID: '',
        productValue: '',
        isFree : '',
        clickText: '',
        productCost: '',
        page: '',
        pagePath: '',
        type: ''
   }

   constructor(eventInfo:object) {
       this.info = {...DemoModuleViewedEvent.defaultInfo, ...eventInfo};
   }
}

export class InterestedInLearnCourseEvent implements GAEvent {
    event:string = "interested_in_learn_course";
    info:object;

    private static defaultInfo = {
        target : '',
        targetGroup : '',
        superGroup : '',
        targetID : '',
        targetGroupID : '',
        superGroupID : '',
        productName : '',
        productID : '',
        productValue : '',
        productCost : '',
        productType : 'SelectCourse',
        productDuration : '',
        liveLearnCourseStartDate : '',
        liveLearnCourseEndDate : '',
        clickText : '',
        module : '',
        isFree : '',
        pagePath: '',
        page: ''
   }

   constructor(eventInfo:object) {
       this.info = {...InterestedInLearnCourseEvent.defaultInfo, ...eventInfo};
   }
}

export class LearnCoursePageVisitedEvent implements GAEvent {
    event:string = "learn_course_page_visited";
    info:object;

    private static defaultInfo = {
        target : '',
        targetGroup : '',
        superGroup : '',
        targetID : '',
        targetGroupID : '',
        superGroupID : '',
        productName : '',
        productID : '',
        productValue : '',
        productCost : '',
        productType : '',
        productDuration : '',
        liveLearnCourseStartDate : '',
        liveLearnCourseEndDate : '',
        clickText : '',
        module : '',
        isFree : '',
        pagePath: '',
        page: '',
        isLoggedIn: false,
        partner : '',
   }

   constructor(eventInfo:object) {
       this.info = {...LearnCoursePageVisitedEvent.defaultInfo, ...eventInfo};
   }
}

export class InterestedInSelectCourseEvent implements GAEvent {
    event:string = "interested_in_select_course";
    info:object;

    private static defaultInfo = {
        target : '',
        targetGroup : '',
        superGroup : '',
        targetID : '',
        targetGroupID : '',
        superGroupID : '',
        productName : '',
        productID : '',
        productValue : '',
        productCost : '',
        productType : 'SelectCourse',
        productDuration : '',
        liveLearnCourseStartDate : '',
        liveLearnCourseEndDate : '',
        clickText : '',
        module : '',
        isFree : '',
        pagePath: '',
        page: '',
        isCostAvailable: false
    }

    constructor(eventInfo:object) {
        this.info = {...InterestedInSelectCourseEvent.defaultInfo, ...eventInfo};
    }
}

export class SelectCoursePageVisitedEvent implements GAEvent {
    event:string = "select_course_page_visited";
    info:object;

    private static defaultInfo = {

        target : '',
        targetGroup : '',
        superGroup : '',
        targetID : '',
        targetGroupID : '',
        superGroupID : '',
        productName : '',
        productID : '',
        productValue : '',
        productCost : '',
        productType : '',
        productDuration : '',
        liveLearnCourseStartDate : '',
        liveLearnCourseEndDate : '',
        clickText : '',
        module : '',
        isFree : '',
        pagePath: '',
        page: '',
        isCostAvailable: false,
        isLoggedIn: false,
        partner : '',
   }

   constructor(eventInfo:object) {
       this.info = {...SelectCoursePageVisitedEvent.defaultInfo, ...eventInfo};
   }
}
export class BannerImpressionEvent implements GAEvent {
    event:string = "banner_impression";
    info:object;

    private static defaultInfo = {
        bannerName: '',
        bannerStartDate: '',
        bannerEndDate: '',
        pagePath: '',
        imageURL:'',
        bannerUrl: '',
        page: '',
   }

   constructor(eventInfo:object) {
       this.info = {...BannerImpressionEvent.defaultInfo, ...eventInfo};
   }
}

export class BannerClickedEvent implements GAEvent {
    event:string = "banner_clicked";
    info:object;

    private static defaultInfo = {
        bannerName: '',
        bannerStartDate: '',
        bannerEndDate: '',
        imageURL:'',
        pagePath: '',
        bannerUrl: '',
        page: '',
    }

    constructor(eventInfo:object) {
        this.info = {...BannerClickedEvent.defaultInfo, ...eventInfo};
    }
}

export class TargetPageLoadEvent implements GAEvent {
    event:string = "target_visit";
    info:object;

    private static defaultInfo = {
        type: '',
        pagePath: '',
        page : '',
        browserFpId: '',
        client: '',
        stage: ''
    }

    constructor(eventInfo:object) {
        this.info = {...TargetPageLoadEvent.defaultInfo, ...eventInfo};
    }
}

export class TargetPageNavigationEvent implements GAEvent {
    event:string = "target_navigation";
    info:object;

    private static defaultInfo = {
        type: '',
        tabName: '',
        pagePath: '',
        page : '',
        browserFpId: '',
        client: '',
        action: '',
        stage: ''
    }

    constructor(eventInfo:object) {
        this.info = {...TargetPageNavigationEvent.defaultInfo, ...eventInfo};
    }
}

export class SkillAcademyProgramPageNavigationEvent implements GAEvent {
    event:string ="skill_individual_course_activity";
    info:object;

    private static defaultInfo = {
        productID:'',
        productName:'',
        productType:'',
        page : '',
        pagePath: '',
        type: '',
        tabName: '',
        browserFpId: '',
        client: '',
        action: '',
        stage: ''
    }
    constructor(eventInfo:object) {
        this.info = {...SkillAcademyProgramPageNavigationEvent.defaultInfo, ...eventInfo};
    }
}

export class SkillAcademyProgramPageCallbackRequestEvent implements GAEvent {
    event:string ="skill_callback_requested";
    info:object;

    private static defaultInfo = {
        productID:'',
        productName:'',
        productType:'',
        page : '',
        pagePath: '',
        type: '',
        tabName: '',
        browserFpId: '',
        client: '',
        action: '',
        stage: ''
    }

    constructor(eventInfo:object) {
        this.info = {...SkillAcademyProgramPageCallbackRequestEvent.defaultInfo, ...eventInfo};
    }
}

export class CouponUsedEvent implements GAEvent {
    event:string = "coupon_used";
    info:object;

    private static defaultInfo = {
        currency:'INR',
        productID: [],
        productName: [],
        productCost: 0,
        couponCode: '',
        discountValue:0,
        client:'web',
        finalAmount:0,
        page: '',
        pagePath: '',
        isSuccess:false,
        productType:''
   }

   constructor(eventInfo:object) {
       this.info = {...CouponUsedEvent.defaultInfo, ...eventInfo};
   }
}

export class CurriculumDownloadedEvent implements GAEvent {
    event:string = "curriculum_downloaded";
    info:object;

    private static defaultInfo = {
        productID:'',
        page:'',
        pagePath:''
   }

   constructor(eventInfo:object) {
       this.info = {...CurriculumDownloadedEvent.defaultInfo, ...eventInfo};
   }
}

export class SelectionProofDownloadedEvent implements GAEvent {
    event:string = "selectionProof_downloaded";
    info:object;

    private static defaultInfo = {
        productID:'',
        page:'',
        pagePath:''
    }

    constructor(eventInfo:object) {
        this.info = {...SelectionProofDownloadedEvent.defaultInfo, ...eventInfo};
    }
}

export class SelectionsSubmissionEvent implements GAEvent{
    event:string = "selections_form_submit"
    info:object

    private static defaultInfo = {
        leadEmailID:'',
        leadName:'',
        leadPhoneNo:''
    }
    constructor(eventInfo:object){
        this.info = {...SelectionsSubmissionEvent.defaultInfo, ...eventInfo}
    }
}


export class LearnCourseEnrollNowEvent implements GAEvent {
    event:string = "learn_course_enroll_now";
    info:object;

    private static defaultInfo = {
        target : '',
        targetGroup : '',
        superGroup : '',
        targetID : '',
        targetGroupID : '',
        superGroupID : '',
        productName : '',
        productID : '',
        productValue : '',
        productCost : '',
        productType : '',
        productDuration : '',
        liveLearnCourseStartDate : '',
        liveLearnCourseEndDate : '',
        clickText : '',
        module : '',
        isFree : '',
        pagePath: '',
        page: ''
   }

   constructor(eventInfo:object) {
       this.info = {...LearnCourseEnrollNowEvent.defaultInfo, ...eventInfo};
   }
}
export class SelectCourseEnrollNowEvent implements GAEvent {
    event:string = "select_course_enroll_now";
    info:object;

    private static defaultInfo = {
        target : '',
        targetGroup : '',
        superGroup : '',
        targetID : '',
        targetGroupID : '',
        superGroupID : '',
        productName : '',
        productID : '',
        productValue : '',
        productCost : '',
        productType : '',
        productDuration : '',
        liveLearnCourseStartDate : '',
        liveLearnCourseEndDate : '',
        clickText : '',
        module : '',
        isFree : '',
        pagePath: '',
        page: '',
        isCostAvailable: false,
   }

   constructor(eventInfo:object) {
       this.info = {...SelectCourseEnrollNowEvent.defaultInfo, ...eventInfo};
   }
}
export class RequestedCallbackEvent implements GAEvent {
    event:string = "requested_callback";
    info:object;

    private static defaultInfo = {
        productID:'',
        pagePath: '',
        page: ''
   }

   constructor(eventInfo:object) {
       this.info = {...RequestedCallbackEvent.defaultInfo, ...eventInfo};
   }
}
export class PypPdfViewedEvent implements GAEvent {
    event:string = "pyp_pdf_viewed";
    info:object;

    private static defaultInfo = {
        target: '',
        testID: '',
        isTestAvailable: false,
        language: '',
        page: '',
        pagePath: '',
        targetID: '',
        pdfName: '',
        pdfID: '',
        productType:'',
        userType:'',
   };

   constructor(eventInfo:object) {
       this.info = {...PypPdfViewedEvent.defaultInfo, ...eventInfo};
   }
}

export class PypPdfDownloadEvent implements GAEvent {
    event:string = "pyp_pdf_downloaded";
    info:object;

    private static defaultInfo = {
        target: '',
        testID: '',
        testName: '',
        isTestAvailable: false,
        isLoggedIn: false,
        language: '',
        page: '',
        pagePath: '',
        pageType:'',
        targetID: '',
        entityName: '',
        entityId: '',
        productType:'',
        userType:'',
   };

   constructor(eventInfo:object) {
       this.info = {...PypPdfDownloadEvent.defaultInfo, ...eventInfo};
   }
}

export class PdfDownloadEvent implements  GAEvent {
    event:string = "pdf_downloaded"; 
    info:object;

    private static defaultInfo = {
        type: 'Others',
        entityName:'',
        entityUrl:'',
        page:'',
        pagePath:'',
        pageType:'',
        isLoggedIn:''
    };

    constructor(eventInfo:object) {
        this.info = {...PdfDownloadEvent.defaultInfo, ...eventInfo};
    }
}

export class PypPdfSharedEvent implements GAEvent {
    event:string = "pyp_pdf_shared";
    info:object;

    private static defaultInfo = {
        target: '',
        testID: '',
        testName: '',
        isTestAvailable: false,
        isLoggedIn: false,
        language: '',
        page: '',
        pagePath: '',
        pageType:'',
        targetID: '',
        pdfName: '',
        pdfID: '',
        medium: ''
   };

   constructor(eventInfo:object) {
       this.info = {...PypPdfSharedEvent.defaultInfo, ...eventInfo};
   }
}

export class PypPdfLanguageChangedEvent implements GAEvent {
    event:string = "pyp_pdf_language_changed";
    info:object;

    private static defaultInfo = {
        target: '',
        testID: '',
        isTestAvailable: false,
        language: '',
        page: '',
        pagePath: '',
        targetID: '',
        pdfName: '',
        pdfID: '',
        previousLanguage: ''
   };

   constructor(eventInfo:object) {
       this.info = {...PypPdfLanguageChangedEvent.defaultInfo, ...eventInfo};
   }
}

export class PypPassAlertEvent implements GAEvent {
    event:string = "pyp_pass_alert";
    info:object;

    private static defaultInfo = {
        target: '',
        testID: '',
        testName: '',
        language: '',
        page: '',
        pagePath: '',
        targetID: ''
   };

   constructor(eventInfo:object) {
       this.info = {...PypPassAlertEvent.defaultInfo, ...eventInfo};
   }
}

export class PypFiltersAppliedEvent implements GAEvent {
    event:string = "pyp_filters_applied";
    info:object;

    private static defaultInfo = {
        target: '',
        language: '',
        page: '',
        pagePath: '',
        targetID: '',
        module: '',
        list: '',
        isTestAvailable: false
    };

    constructor(eventInfo:object) {
        this.info = {...PypFiltersAppliedEvent.defaultInfo, ...eventInfo};
    }
}

export class TestSeriesVisitEvent implements GAEvent {
    event:string = "test_series_visited";
    info:any;

    private static defaultInfo = {
        productName : '',
        productID : '',
        target : '',
        targetGroup : '',
        superGroup : '',
        page : '',
        pagePath : '',
        isLoggedIn: false,
        productExpiry : '',
        productStartDate : '',
        isFree : ''
    };

    constructor(testSeriesDetails:TestSeries) {
        let eventInfo = {
            productName: testSeriesDetails.name,
            productID: testSeriesDetails.id,
            target: testSeriesDetails.target && testSeriesDetails.target.length && getCommaSeparatedString(testSeriesDetails.target, 'title') || '',
            targetGroup : testSeriesDetails.targetGroup && testSeriesDetails.targetGroup.length && getCommaSeparatedString(testSeriesDetails.targetGroup, 'title') || '',
            superGroup : testSeriesDetails.targetSuperGroup && testSeriesDetails.targetSuperGroup.length && getCommaSeparatedString(testSeriesDetails.targetSuperGroup, 'title') || '',
            targetID: testSeriesDetails.target && testSeriesDetails.target.length && getCommaSeparatedString(testSeriesDetails.target, '_id') || '',
            targetGroupID : testSeriesDetails.targetGroup && testSeriesDetails.targetGroup.length && getCommaSeparatedString(testSeriesDetails.targetGroup, '_id') || '',
            superGroupID : testSeriesDetails.targetSuperGroup && testSeriesDetails.targetSuperGroup.length && getCommaSeparatedString(testSeriesDetails.targetSuperGroup, '_id') || '',
            isFree : testSeriesDetails.isFree,
            pagePath: window.location.pathname + window.location.search,
            branchesAvailable:!!testSeriesDetails?.branches?.values.length,
            showAnalysis:testSeriesDetails.showAnalysis
        };
        this.info = {...TestSeriesVisitEvent.defaultInfo, ...eventInfo};
    }
}

export class InterestedInTestSeriesEvent {
    event:string = "interested_in_test_series";
    info:any;

    private static defaultInfo =  {
        target : '',
        targetGroup : '',
        superGroup : '',
        targetID : '',
        targetGroupID : '',
        superGroupID : '',
        productName: '',
        productID: '',
        productCost: '',
        numberOfTests: '',
        isThirdParty: '',
        thirdPartyName : '',
        hasActiveCoursePass : '',
        hasExpiredCoursePass : '',
        module: '',
        category:'',
        hasActivePass : '',
        hasExpiredPass : '',
        isFree : '',
        pagePath: '',
        page: '',
        isLoggedIn: false,
    }
    constructor(testSeriesDetails:TestSeries) {
        let eventInfo = {
            productName: testSeriesDetails.name,
            productID: testSeriesDetails.id,
            target: testSeriesDetails.target && testSeriesDetails.target.length && getCommaSeparatedString(testSeriesDetails.target, 'title') || '',
            targetGroup: testSeriesDetails.targetGroup && testSeriesDetails.targetGroup.length && getCommaSeparatedString(testSeriesDetails.targetGroup, 'title') || '',
            superGroup: testSeriesDetails.targetSuperGroup && testSeriesDetails.targetSuperGroup.length && getCommaSeparatedString(testSeriesDetails.targetSuperGroup, 'title') || '',
            targetID: testSeriesDetails.target && testSeriesDetails.target.length && getCommaSeparatedString(testSeriesDetails.target, '_id') || '',
            targetGroupID: testSeriesDetails.targetGroup && testSeriesDetails.targetGroup.length && getCommaSeparatedString(testSeriesDetails.targetGroup, '_id') || '',
            superGroupID: testSeriesDetails.targetSuperGroup && testSeriesDetails.targetSuperGroup.length && getCommaSeparatedString(testSeriesDetails.targetSuperGroup, '_id') || '',
            isFree: testSeriesDetails.isFree,
            pagePath: window.location.pathname + window.location.search,
            numberOfTests: testSeriesDetails.freeTestCount + testSeriesDetails.paidTestCount
        };
        this.info = {...InterestedInTestSeriesEvent.defaultInfo, ...eventInfo};
    }
}

export class InterestedInPasses {
    event: string = 'interested_in_passes';
    info: any;

    private static defaultInfo = {
        productCategory:'',
        pagePath: '',
        page: '',
        pageType:'',
        hasExpiredPass: '',
        hasActivePass: '',
        isLoggedIn: false,
        isMobileVerified: '',
        hoursLeft: '',
        inGroup: false,
        clickText:''
    }

    constructor(eventObj) {
        this.info = {...InterestedInPasses.defaultInfo, ...eventObj};
    }
}

export class InterestedInPass {
    event: string = 'interested_in_pass';
    info: any;

    private static defaultInfo = {
        productCategory:'GlobalPass',
        productName: '',
        productID: '',
        productValue: '',
        pagePath: '',
        page: '',
        productDuration: '',
        hasExpiredPass: '',
        hasActivePass: '',
        isLoggedIn: false,
        isMobileVerified: '',
        hoursLeft: '',
        fromPassPopup: false,
        module: '',
        clickText: '',
        inGroup: false,
        quantity: 1,
        partner: ''
    }

    constructor(eventObj) {
        this.info = {...InterestedInPass.defaultInfo, ...eventObj};
    }
}

export class SendProductIds {
    event :string= 'send_product_ids';
    info: any;

    private static defaultInfo = {
        productIDs:[]
    }

    constructor(eventObj) {
        this.info = {...SendProductIds.defaultInfo, ...eventObj};
    }
}

export class EnrolledInTestSeries {
    event: string = 'test_series_enrolled';
    info: any;

    private static defaultInfo = {
        productName : '',
        productID : '',
        target : '',
        targetGroup : '',
        superGroup : '',
        page : '',
        pagePath : '',
        isFree : ''
    }

    constructor(testSeriesDetails: TestSeries) {
        let eventInfo = {
            productName: testSeriesDetails.name,
            productID: testSeriesDetails.id,
            target: testSeriesDetails.target && testSeriesDetails.target.length && getCommaSeparatedString(testSeriesDetails.target, 'title') || '',
            targetGroup: testSeriesDetails.targetGroup && testSeriesDetails.targetGroup.length && getCommaSeparatedString(testSeriesDetails.targetGroup, 'title') || '',
            superGroup: testSeriesDetails.targetSuperGroup && testSeriesDetails.targetSuperGroup.length && getCommaSeparatedString(testSeriesDetails.targetSuperGroup, 'title') || '',
            isFree: testSeriesDetails.isFree
        };

        this.info = {...EnrolledInTestSeries.defaultInfo, ...eventInfo};
    }
}

export class TestRegistered {
    event: string = 'test_registered';
    info: any;
    private static defaultInfo: any = {
        testName: '',
        testID: '',
        course: '',
        exam: '',
        examList: '',
        isLive: '',
        isFree: false,
        language: '',
        type: '',
        module:'',
        page: '',
        pagePath: '',
        pageType: '',
        isLoggedIn: false,
        fromLivePanel:false,
        liveTestStartTime: '',
        liveTestEndTime: ''
    };

    constructor(test: _Test) {
        let event = {
            testName: test.title,
            isFree: test.isFree || false,
            testID: test.id,
            exam: test.primaryTarget.title,
            type:test.isFree ? 'free' : 'paid',
            isLive: true,
            liveTestStartTime: test.startTime,
            liveTestEndTime: test.endTime
        }
        this.info = {...TestRegistered.defaultInfo, ...event};
    }
}

export class ScholarshipPageVisitedEvent implements GAEvent {
    event:string = "scholarship_page_visited";
    info:object;

    private static defaultInfo = {
      testID:'',
      testName:'',
      targetGroup:'',
      targetGroupID:'',
      superGroup:'',
      superGroupID:'',
      target: '',
      page: '',
      pagePath: '',
      targetID: '',
      category:''
    };

   constructor(eventInfo:object) {
       this.info = {...ScholarshipPageVisitedEvent.defaultInfo, ...eventInfo};
   }
}


export class RegisterTestEvent implements GAEvent {
    event:string = "test_registered";
    info:object;

    private static defaultInfo = {
        testName: '',
        testID: '',
        course: '',
        exam: '',
        examList: '',
        isLive: false,
        isFree: false,
        type:'',
        language:'',
        module:'',
        page: '',
        pagePath: '',
        pageType:'',
        fromLivePanel:false,
        liveTestStartTime: '',
        liveTestEndTime: '',
        isLoggedIn:''
    };

   constructor(eventInfo:object) {
       this.info = {...RegisterTestEvent.defaultInfo, ...eventInfo};
   }
}

interface items {
    item_id: string;
    item_name: string;
    coupon: string;
    discount: number;
    index: number;
    price: number;
    quantity: number;
}

export class Purchased {
    event: string = 'purchase';
    ecommerce: any;
    private static defaultInfo: any = {
        transaction_id: '',
        productID: '',
        productName: '',
        value: 0,
        currency: 'INR',
        coupon: '',
        items: [] as Array<items>
    };

    constructor(eventObj) {
        this.ecommerce = {...Purchased.defaultInfo, ...eventObj};
    }
}

export class Transacted {
    event: string = 'product_purchased';
    info: any;
    private static defaultInfo: any = {
        transID: '',
        productID: '',
        productName: '',
        finalAmount: 0,
        productDuration: '',
        page: '',
        pagePath: '',
        purchaseType: '',
        isEcard: false,
        quantity:1,
        subTotal:0,
        productCategory:'',
        couponCode:'',
        stopEvents:false,
        type: '', //emi or non-emi
        emiNum: 0 // emi number
    };

    constructor(eventObj) {
        this.info = {...Transacted.defaultInfo, ...eventObj};
    }
}

export class TransactionFailed {
    event : string = 'payment_failed';
    info  : any;
    private static defaultInfo : any = {
            transID:'',
            productID: '',
            productName: '',
            couponCode: '',
            isEcard:false,
            subTotal:0,
            finalAmount:0,
            page: '',
            pagePath: '',
            productCostArray:0,
            purchasedFrom:'web',
            productCategory:'',
            partner:'others',
            transUtmSource:'',
            transUtmMedium:'',
            transUtmCampaign:''
    }

    constructor(eventObj) {
        this.info = {...TransactionFailed.defaultInfo, ...eventObj};
    }
}


export class MasterClassSeriesVisitedEvent {
    event:string='masterclass_series_visited'
    info:any;
    private static defaultInfo : any = {
        productID:'',
        productName:'',
        target:'',
        pagePath:''
    }
    constructor(eventObj){
        this.info={...MasterClassSeriesVisitedEvent.defaultInfo, ... eventObj}
    }
}

export class MasterClassRemindMeEvent {
    event:string='masterclass_remind_me_clicked'
    info:any;
    private static defaultInfo : any = {
        entityID:'',
        entityName:'',
        productID:'',
        productName:'',
        target:'',
        pagePath:'',
        category:'',
        Module:'',
        LandingPage:'',
        Client:'',
        Language:'',
        Stage:'',
        TypeOfUser:'',
        FirstClick:'',
    }
    constructor(eventObj){
        this.info={...MasterClassRemindMeEvent.defaultInfo, ... eventObj}
    }
}

export class MasterClassSeriesJoinedEvent {
    event:string='masterclass_series_joined'
    info:any;
    private static defaultInfo : any = {
        superGroupID:'',
        superGroup:'',
        productID:'',
        productName:'',
        target:'',
        pagePath:'',
        targetID:''
    }
    constructor(eventObj){
        this.info={...MasterClassSeriesJoinedEvent.defaultInfo, ... eventObj}
    }
}

export class MasterClassLessonVisitedEvent {
    event:string='masterclass_lesson_page_visited'
    info:any;
    private static defaultInfo : any = {
        entityID:'',
        entityName:'',
        productID:'',
        productName:'',
        pagePath:'',
    }
    constructor(eventObj){
        this.info={...MasterClassLessonVisitedEvent.defaultInfo, ... eventObj}
    }
}


export class LivePanelActionEvent {
    event:string='live_panel_action'
    info:any;
    private static defaultInfo : any = {
        target: '',
        targetGroup: '',
        superGroup: '',
        targetID: '',
        targetGroupID: '',
        superGroupID: '',
        parentProductCategory: '',
        parentProductName: '',
        parentProductID: '',
        isParentFree: '',
        testName: '',
        testID: '',
        subject: '',
        servesOnDate: '',
        liveTestStartTime: '',
        liveTestEndTime: '',
        language: '',
        page: '',
        pagePath: '',
        method: '',
        category: '',
        isScholorship: false,
        fromLivePanel: false,
        panelName: '',
        clickText: '',
        tab: '',
        fromLivePage : true,
        module:'',
        firstClick:'',
        landingPage:'',
        client:'',
        currentlanguage:'',
        stage:'',
        typeOfUser:''
    }
    constructor(eventObj){
        let event = {
            target: eventObj.target && eventObj.target.length && getCommaSeparatedString(eventObj.target, 'title') || '',
            targetGroup : eventObj.targetGroup && eventObj.targetGroup.length && getCommaSeparatedString(eventObj.targetGroup, 'title') || '',
            superGroup : eventObj.targetSuperGroup && eventObj.targetSuperGroup.length && getCommaSeparatedString(eventObj.targetSuperGroup, 'title') || '',
            targetID: eventObj.target && eventObj.target.length && getCommaSeparatedString(eventObj.target, '_id') || '',
            targetGroupID : eventObj.targetGroup && eventObj.targetGroup.length && getCommaSeparatedString(eventObj.targetGroup, '_id') || '',
            superGroupID : eventObj.targetSuperGroup && eventObj.targetSuperGroup.length && getCommaSeparatedString(eventObj.targetSuperGroup, '_id') || '',
            parentProductCategory: '',
            parentProductName : eventObj.course && eventObj.course.title || "",
		    parentProductID : eventObj.course && eventObj.course.id,
            isParentFree: '',
		    testName : eventObj.title,
		    testID : eventObj.id || eventObj._id,
            subject: '',
		    servesOnDate : (!eventObj.servesOn || eventObj.servesOn === '0001-01-01T00:00:00Z') ? null : eventObj.servesOn,
            liveTestStartTime: new Date(eventObj.startTime).toISOString() || '',
            liveTestEndTime: new Date(eventObj.endTime).toISOString() || '',
            isScholarship : eventObj.isScholarship,
            language: eventObj.languagesStr,
            module: eventObj.module,
            method: '',
            productName: eventObj.name,
            productID: eventObj.id,
            clickText : eventObj.clickText,
            tab : eventObj.tab,
            panelName : eventObj.panelName,
            category : eventObj.category,
            testNo : eventObj.testNo,
            page : eventObj.page,
            pagePath : eventObj.pagePath,
            firstClick:eventObj.firstClick,
            stage:eventObj.stage,
            typeOfUser:eventObj.typeOfUser,
            landingPage:eventObj.landingPage,
            client:eventObj.client,
            currentlanguage:eventObj.language
        }
        this.info={...LivePanelActionEvent.defaultInfo, ... event}
    }
}

export class CategoryClickedEvent {
    event:string='category_clicked'
    info:any;
    private static defaultInfo : any = {
        clickText: '',
        clickSource: '',
        page: '',
        pagePath: '',
        module: '',
        fromLivePanel: false,
        fromLivePage : true
    }
    constructor(eventObj){
        let event = {
            clickText: eventObj.clickText,
            clickSource: eventObj.clickSource,
            page: eventObj.page,
            pagePath: eventObj.pagePath,
            module: eventObj.module
        }
        this.info={...CategoryClickedEvent.defaultInfo, ... event}
    }
}
export class MasterClassScreenshotCapturedEvent {
    event:string='masterclass_screenshot_captured'
    info:any
    private static defaultInfo : any = {
        entityID:'',
        entityName:'',
        productID:'',
        productName:'',
        pagePath:'',
        timestamp:'',
        target:'',
    }
    constructor(eventObj){
        this.info={...MasterClassScreenshotCapturedEvent.defaultInfo, ... eventObj}
    }
}

export class MasterClassLessonStarted{
    event:string='masterclass_lesson_started'
    info:any
    private static defaultInfo : any = {
        entityID:'',
        entityName:'',
        productID:'',
        productName:'',
        pagePath:'',
        clickText: '',
        type: ''
    }
    constructor(eventObj){
        this.info={...MasterClassLessonStarted.defaultInfo, ... eventObj}
    }
}

export class MasterClassPracticeStarted{
    event:string='masterclass_practice_started'
    info:any
    private static defaultInfo : any = {
        entityID:'',
        entityName:'',
        productID:'',
        productName:'',
        pagePath:'',
        target:'',
        clickText: '',
        type: ''
    }
    constructor(eventObj){
        this.info={...MasterClassPracticeStarted.defaultInfo, ... eventObj}
    }
}

export class MasterClassNotesRead{
    event:string='masterclass_notes_read'
    info:any
    private static defaultInfo : any = {
        entityID:'',
        entityName:'',
        clickText: '',
        type: ''
    }
    constructor(eventObj){
        this.info={...MasterClassNotesRead.defaultInfo, ... eventObj}
    }
}

export class MasterClassVideoCompleted{
    event:string='masterclass_video_completed'
    info:any
    private static defaultInfo : any = {
        entityID:'',
        entityName:'',
        clickText: '',
        type: '',
        pagePath: '',
        target: ''
    }
    constructor(eventObj){
        this.info={...MasterClassVideoCompleted.defaultInfo, ... eventObj}
    }
}

export class MasterClassVideoStarted{
    event:string='masterclass_video_started'
    info:any
    private static defaultInfo : any = {
        entityID:'',
        entityName:'',
        clickText: '',
        type: '',
        pagePath: '',
        target: ''
    }
    constructor(eventObj){
        this.info={...MasterClassVideoStarted.defaultInfo, ... eventObj}
    }
}

export class MasterClassLessonCompleted{
    event:string='masterclass_lesson_completed'
    info:any
    private static defaultInfo : any = {
        entityID:'',
        entityName:'',
        pagePath: '',
        target: '',
        productID: '',
        productName:''
    }
    constructor(eventObj){
        this.info={...MasterClassLessonCompleted.defaultInfo, ... eventObj}
    }
}

export class MasterClassLessonLeft{
    event:string='masterclass_left'
    info:any
    private static defaultInfo : any = {
        entityID:'',
        entityName:'',
        pagePath: '',
        target: '',
        productID: '',
        productName:''
    }
    constructor(eventObj){
        this.info={...MasterClassLessonLeft.defaultInfo, ... eventObj}
    }
}

export class MasterClassExploreActivity{
    event:string='masterclass_explore_activity'
    info:any
    private static defaultInfo : any = {
        category: '',
        clickText: '',
        pagePath: '',
        client: '',
        language: '',
        stage:'',
        firstClick:'',
        typeOfUser:'',
        module:''
    }
    constructor(eventObj){
        this.info={...MasterClassExploreActivity.defaultInfo, ... eventObj}
    }
}
export class OnboardingSelectionTabEvent {
    event:string='user_click'
    info:any;
    private static defaultInfo : any = {
        category:'',
        destinationPageCategory:'',
        pageURL:'',
        destinationURL:'',
        client:'',
        clickText:''
    }
    constructor(eventInfo){
        this.info={...OnboardingSelectionTabEvent.defaultInfo, ...eventInfo}
    }
}

export class OnboardingTargetRemovedEvent {
    event:string='target_removed'
    info:any;
    private static defaultInfo : any = {
        targetName:'',
        medium:'',
        page:'',
        pagePath:'',
        client:'',
        pageType:'',
        isLoggedIn: false,
    }
    constructor(eventInfo){
        this.info={...OnboardingTargetRemovedEvent.defaultInfo, ...eventInfo}
    }
}

export class OnboardingTargetSelectedEvent {
    event:string='target_selected'
    info:any;
    private static defaultInfo : any = {
        targetName:'',
        medium:'',
        page:'',
        type:'',
        category:'',
        pagePath:'',
        client:'',
        pageType:'',
        isLoggedIn: false,
    }
    constructor(eventInfo){
        this.info={...OnboardingTargetSelectedEvent.defaultInfo, ...eventInfo}
    }
}

export class OnboardingTargetSearchedSuccessEvent {
    event:string='target_searched'
    info:any;
    private static defaultInfo : any = {
        searchTerm:'',
        page:'',
        isSuccess:'',
        pagePath:'',
        client:''
    }
    constructor(eventInfo){
        this.info={...OnboardingTargetSearchedSuccessEvent.defaultInfo, ...eventInfo}
    }
}

export class OnboardingTargetSelectedCompletedEvent {
    event:string='target_selected_completed'
    info:any;
    private static defaultInfo : any = {
        page:'',
        numberOfTargetEnrolled:'',
        numberOfSuperGroupEnrolled:'',
        type:'',
        medium:'',
        pagePath:'',
        pageType:'',
        isLoggedIn: false,
        client:''
    }
    constructor(eventInfo){
        this.info={...OnboardingTargetSelectedCompletedEvent.defaultInfo, ...eventInfo}
    }
}

export class OnboardingTargetSearchedEvent {
    event:string='search'
    info:any;
    private static defaultInfo : any = {
        searchTerm:'',
        page:'',
        type:'',
        pagePath:'',
        client:''
    }
    constructor(eventInfo){
        this.info={...OnboardingTargetSearchedEvent.defaultInfo, ...eventInfo}
    }

}

export class LivePanelUserClick {
    event:string='user_click'
    info:any;
    private static defaultInfo : any = {
        category: '',
        clickText: '',
        pagePath: '',
        destinationPageCategory: '',
        destinationURL: '',
        pageURL: '',
        platForm:'',
        language:'',
        stage:'',
        firstClick:'',
        typeOfUser:'',
        module:'',
        landingPage:''

    }
    constructor(eventObj){
        this.info={...LivePanelUserClick.defaultInfo, ... eventObj}
    }
}

// SuperCoaching Events 


export class SupercoachingIndividualPageActivity{
    event:string = 'supercoaching_individual_page_activity';
    info:any;
    private static defaultInfo : any = {
        productName: '',
        productID: '',
        pagePath: '',
        category: ''
    }
    constructor(eventObj){
        this.info={...SupercoachingIndividualPageActivity.defaultInfo, ... eventObj}
    }
}
export class SupercoachingSearch{
    event:string = 'SEARCH';
    info:any;
    private static defaultInfo : any = {
        SEARCH_TERM: '',
        type: '',
        pagePath: ''
    }
    constructor(eventObj){
        this.info={...SupercoachingSearch.defaultInfo, ... eventObj}
    }
}

export class SupercoachingVideoCompleted{
    event:string = 'supercoaching_video_completed';
    info:any;
    private static defaultInfo : any = {
        productName: '',
        productID: '',
        entityID: '',
        type: '',
        pagePath: '',
        entityName:'',
        clickText:'',
        tabName:''
    }
    constructor(eventObj){
        this.info={...SupercoachingVideoCompleted.defaultInfo, ... eventObj}
    }
}

export class SupercoachingVideoResumed{
    event:string = 'supercoaching_video_resumed';
    info:any;
    private static defaultInfo : any = {
        productName: '',
        productID: '',
        entityID: '',
        type: '',
        pagePath: '',
        entityName:'',
        clickText:'',
        tabName:''
    }
    constructor(eventObj){
        this.info={...SupercoachingVideoResumed.defaultInfo, ... eventObj}
    }
}

export class SupercoachingNotesRead{
    event:string = 'supercoaching_notes_read';
    info:any;
    private static defaultInfo : any = {
        productName: '',
        productID: '',
        entityID: '',
        type: '',
        pagePath: '',
        entityName:'',
        clickText:''
    }
    constructor(eventObj){
        this.info={...SupercoachingNotesRead.defaultInfo, ... eventObj}
    }
}
export class SupercoachingVideoStarted{
    event:string = 'supercoaching_video_started';
    info:any;
    private static defaultInfo : any = {
        productName: '',
        productID: '',
        entityID: '',
        type: '',
        pagePath: '',
        entityName:'',
        clickText:'',
        tabName:''
    }
    constructor(eventObj){
        this.info={...SupercoachingVideoStarted.defaultInfo, ... eventObj}
    }
}

export class SupercoachingTestStarted{
    event:string = 'supercoaching_test_started';
    info:any;
    private static defaultInfo : any = {
        entityID: '',
        target: '',
        type: '',
        category: '', 
        pagePath: '',
        pageType:'',
        isLoggedIn: false,
        entityName:'',
        clickText:'',
        language:'',
    }
    constructor(eventObj){
        this.info={...SupercoachingTestStarted.defaultInfo, ... eventObj}
    }
}
export class SupercoachingPracticeStarted{
    event:string='supercoaching_practice_started'
    info:any
    private static defaultInfo : any = {
        entityID:'',
        entityName:'',
        pagePath:'',
        clickText: '',
        type: '',
        label: '',  
    }
    constructor(eventObj){
        this.info={...SupercoachingPracticeStarted.defaultInfo, ... eventObj}
    }
}
export class SupercoachingPracticeResumed{
    event:string='supercoaching_practice_resumed'
    info:any
    private static defaultInfo : any = {
        entityID:'',
        entityName:'',
        pagePath:'',
        clickText: '',
        type: '',
        label: '',  
    }
    constructor(eventObj){
        this.info={...SupercoachingPracticeResumed.defaultInfo, ... eventObj}
    }
}

export class SupercoachingPracticeCompleted{
    event:string='supercoaching_practice_completed'
    info:any
    private static defaultInfo : any = {
        entityID:'',
        entityName:'',
        pagePath:'',
        target:'',
        clickText: '',
        type: '',
        label: '',  
    }
    constructor(eventObj){
        this.info={...SupercoachingPracticeCompleted.defaultInfo, ... eventObj}
    }
}

export class StudyPracticeStarted{
    event:string='study_practice_started'
    info:any
    private static defaultInfo : any = {
        productType:'',
        userType:'',
        entityID:'',
        entityName:'',
        superGroup:'',
        target:'',
        chapterId:'',
        chapterName:'',
        sectionId:'',
        sectionName:'',
        page:'',
        pagePath:'', 
    }
    constructor(eventObj){
        this.info={...StudyPracticeStarted.defaultInfo, ... eventObj}
    }
}

export class StudyPracticeResumed{
    event:string='study_practice_resumed'
    info:any
    private static defaultInfo : any = {
        productType:'',
        userType:'',
        entityID:'',
        entityName:'',
        label:'',
        category:'',
        page:'',
        pagePath:'', 
        clickText:'',
        type:''
    }
    constructor(eventObj){
        this.info={...StudyPracticeResumed.defaultInfo, ... eventObj}
    }
}

export class StudyPracticeCompleted{
    event:string='study_practice_completed'
    info:any
    private static defaultInfo : any = {
        productType:'',
        userType:'',
        entityID:'',
        entityName:'',
        page:'',
        pagePath:'', 
        clickText:'',
        type:'',
        viewCount:'',
        watchTime:'',
        target:'',
    }
    constructor(eventObj){
        this.info={...StudyPracticeCompleted.defaultInfo, ... eventObj}
    }
}

export class HomePageUserClick {
    event:string='user_click'
    info:any;
    private static defaultInfo : any = {
        category: '',
        clickText: '',
        pagePath: '',
        destinationPageCategory: '',
        destinationURL: '',
        pageURL: '',
        client:'',
        language:'',
        stage:'',
        firstClick:'',
        typeOfUser:'',
        module:'',
        landingPage:''
    }
    constructor(eventObj){
        this.info={...HomePageUserClick.defaultInfo, ... eventObj}
    }
}

export class ReferralInvited {
    event:string='referral_invite_sent'
    info:any;
    private static defaultInfo : any = {
        medium:'',
        pagePath: '',
        client:'',
        language:'',
        stage:'',
        firstClick:'',
        typeOfUser:'',
        module:'',
        refCode:''
    }
    constructor(eventObj){
        this.info={...ReferralInvited.defaultInfo, ... eventObj}
    }
}

export class ReferralApplied {
    event:string='referral_code_applied'
    info:any;
    private static defaultInfo : any = {
        pagePath: '',
        client:'',
        language:'',
        stage:'',
        firstClick:'',
        typeOfUser:'',
        module:'',
        refCode:''
    }
    constructor(eventObj){
        this.info={...ReferralApplied.defaultInfo, ... eventObj}
    }
}

export class HomePageBannerViewPromotion {
    event:string='view_promotion'
    info:any;
    private static defaultInfo : any = {
        promotionName: '',
        promotionID:'',
        imageURL:'',
        showFrom:'',
        showTill:'',
        enableTimer:'',
        timerStartTime:'',
        promotionType:'',
        client:'',
        language:'',
        stage:'',
        clickText:'',
        firstClick:'',
        typeOfUser:'',
        module:'',
        landingPage:'',
        pagePath:'',
        id:'',
        bannerPosition:''
    }
    constructor(eventObj){
        this.info={...HomePageBannerViewPromotion.defaultInfo, ... eventObj}
    }
}

export class HomePageBannerSelectPromotion {
    event:string='select_promotion'
    info:any;

    private static defaultInfo : any = {
        promotionName: '',
        promotionID:'',
        imageURL:'',
        showFrom:'',
        showTill:'',
        enableTimer:'',
        timerStartTime:'',
        promotionType:'',
        client:'',
        language:'',
        stage:'',
        clickText:'',
        firstClick:'',
        typeOfUser:'',
        module:'',
        landingPage:'',
        pagePath:'',
        id:'',
        bannerPosition:''
    }
    constructor(eventObj){
        this.info={...HomePageBannerSelectPromotion.defaultInfo, ... eventObj}
    }
}


export class SupercoachingExploreActivity {
    event:string='supercoaching_explore_activity'
    info:any;

    private static defaultInfo : any = {
        category: '',
        page:'',
        clickText:'',
        goalID: '',
        goalName: ''
    }
    constructor(eventObj){
        this.info={...SupercoachingExploreActivity.defaultInfo, ... eventObj}
    }
}

export class SpecificSuperteacherExploreActivity {
    event:string='specific_superteacher_explore_activity'
    info:any;

    private static defaultInfo : any = {
        category: '',
        page:'',
        clickText:'',
    }
    constructor(eventObj){
        this.info={...SpecificSuperteacherExploreActivity.defaultInfo, ... eventObj}
    }
}

export class GlobalSuperteacherExploreActivity{
    event:string='global_superteacher_explore_activity'
    info:any;

    private static defaultInfo : any = {
        category: '',
        page:'',
        clickText:'',
    }
    constructor(eventObj){
        this.info={...GlobalSuperteacherExploreActivity.defaultInfo, ... eventObj}
    }
}

export class SupercoachingCourseExplored {
    event:string='supercoaching_course_explored'
    info:any;

    private static defaultInfo : any = {
        category: '',
        page:'',
        clickText:'',
    }
    constructor(eventObj){
        this.info={...SupercoachingCourseExplored.defaultInfo, ... eventObj}
    }
}

export class SupercoachingCallbackRequested{
    event:string='supercoaching_callback_requested'
    info:any;

    private static defaultInfo:any ={
        page:'',
        pagePath:'',
        productName:'',
        productID:'',
        goalID:'',
        goalName:''
    }
    constructor(eventObj){
        this.info={...SupercoachingCallbackRequested.defaultInfo, ... eventObj}
    }
}

export class SupercoachingProceededToPayment{
    event:string='supercoaching_proceeded_to_payment'
    info:any;

    private static defaultInfo:any ={
        productName:'',
        productID:'',
        duration:'',
        productCost:'', //database stored cost
        payableAmount:'',
        discountAmount:'',
        page:'',
        pageType:'',
        isLoggedIn: false,
        pagePath:'',
        goalName:'',
        goalID:'',
        medium: '',
        type: '',
        couponCode: '',
        couponApplied: '',
        emiPlanPrice: ''
    }
    constructor(eventObj){
        this.info={...SupercoachingProceededToPayment.defaultInfo, ... eventObj}
    }
}


export class SupercoachingEntityExplore{
    event:string='supercoaching_entity_explored'
    info:any;

    private static defaultInfo:any ={
        page:'',
        pagePath:'',
        productName:'',
        productID:'',
        category:'',
        clickText:'',
        goalID:'',
        goalName:'',
        pageType:'',
        isLoggedIn: false,
    }
    constructor(eventObj){
        this.info={...SupercoachingEntityExplore.defaultInfo, ... eventObj}
    }
}

export class SupercoachingApplyCouponClicked{
    event:string='apply_coupon_clicked'
    info:any;
    private static defaultInfo:any ={
        page:'',
        pagePath:'',
        productName:'',
        productID:'',
        productCost: '',
    }
    constructor(eventObj){
        this.info={...SupercoachingApplyCouponClicked.defaultInfo, ... eventObj}
    }
}

export class SupercoachingFreeLessonViewed{
    event:string='supercoaching_free_lesson_viewed'
    info:any;

    private static defaultInfo:any ={
        page:'',
        pagePath:'',
        productName:'',
        productID:'',
        teacherID:'',
        teacherName:'',
        isPaid:'',
        clickText:'',
        moduleType:'',
        moduleID:'',
        moduleName:'',
        goalID:'',
        goalName:''
    }
    constructor(eventObj){
        this.info={...SupercoachingFreeLessonViewed.defaultInfo, ... eventObj}
    }
}

export class SupercoachingTeacherPageVisited{
    event:string='supercoaching_teacher_page_visited'
    info:any;

    private static defaultInfo:any ={
        page:'',
        pagePath:'',
        pageType:'',
        isLoggedIn: false,
        productName:'',
        productID:'',
        teacherName:'',
        teacherID:'',
        isPaid:'',
        goalID:'',
        goalName:''
    }
    constructor(eventObj){
        this.info={...SupercoachingTeacherPageVisited.defaultInfo, ... eventObj}
    }
}


export class SupercoachingAllTeachersPageVisited{
    event:string='supercoaching_all_teachers_page_visited'
    info:any;

    private static defaultInfo:any ={
        page:'',
        pagePath:'',
        pageType:'',
        isLoggedIn: false,
        productName:'',
        productID:'',
        isPaid:'',
        goalID:'',
        goalName:''
    }
    constructor(eventObj){
        this.info={...SupercoachingAllTeachersPageVisited.defaultInfo, ... eventObj}
    }
}



export class SupercoachingGoalPageVisited {
  event = 'supercoaching_goal_page_visited';
  info: any;

  private static defaultInfo: any = {
    page: '',
    pagePath: '',
    productName: '',
    productID: '',
    isPaid: false,
    pageType: '',
    isLoggedIn: false,
  };
  constructor(eventObj) {
    this.info = {...SupercoachingGoalPageVisited.defaultInfo, ... eventObj};
  }
}

export class SupercoachingLiveClassClicked {
  event = 'supercoaching_live_class_clicked';
  info: any;

  private static defaultInfo: any = {
    page: '',
    pagePath: '',
    productName: '',
    productID: '',
    isPaid: false,
    liveClassName: '',
    liveClassID: '',
    liveSeriesName: '',
    liveSeriesID: ''
  };
  constructor(eventObj) {
    this.info = {...SupercoachingLiveClassClicked.defaultInfo, ... eventObj};
  }
}

export class SupercoachingCourseCurriculumViewed {
  event = 'supercoaching_course_curriculum_viewed';
  info: any;

  private static defaultInfo: any = {
    page: '',
    pagePath: '',
    productName: '',
    productID: '',
    isPaid: false,
    courseName: '',
    courseID: ''
  };
  constructor(eventObj) {
    this.info = {...SupercoachingCourseCurriculumViewed.defaultInfo, ... eventObj};
  }
}

export class SupercoachingTestSeriesClicked {
  event = 'supercoaching_test_series_clicked';
  info: any;

  private static defaultInfo: any = {
    page: '',
    pagePath: '',
    productName: '',
    productID: '',
    isPaid: false,
    testSeriesName: '',
    testSeriesID: '',
    goalID:'',
    goalName:''
  };
  constructor(eventObj) {
    this.info = {...SupercoachingTestSeriesClicked.defaultInfo, ... eventObj};
  }
}

export class SupercoachingPurchaseScreenVisited {
    event = "supercoaching_purchase_screen_visited";
    info: any;

    private static defaultInfo: any = {
        productID: '',
        productName: '',
        page: '',
        pagePath: '',
        pageType: '',
        isLoggedIn: false,
    }

    constructor(eventObj){
        this.info = {...SupercoachingPurchaseScreenVisited.defaultInfo, ...eventObj}
    }
}

export class SupercoachingBrochureDownloaded {
    event = "supercoaching_brochure_downloaded";
    info: any;

    private static defaultInfo: any = {
        productID: '',
        productName: '',
        page: '',
        pagePath: '',
        goalID:'',
        goalName:''
    }

    constructor(eventObj){
        this.info = {...SupercoachingBrochureDownloaded.defaultInfo, ...eventObj}
    }
}

export class SupercoachingNotesDownloaded {
    event = "supercoaching_notes_downloaded";
    info: any;

    private static defaultInfo: any = {
        productID: '',
        productName: '',
        page: '',
        pagePath: '',
    }

    constructor(eventObj){
        this.info = {...SupercoachingNotesDownloaded.defaultInfo, ...eventObj}
    }
}

export class SupercoachingCoursePageVisited {
    event = "supercoaching_course_page_visited";
    info: any;

    private static defaultInfo: any = {
        productID: '',
        productName: '',
        isPaid: '',
        courseName: '',
        courseID:'',
        goalID:'',
        goalName:'',
        pageType:'',
        isLoggedIn: false,
    }

    constructor(eventObj){
        this.info = {...SupercoachingCoursePageVisited.defaultInfo, ...eventObj}
    }
}

export class SupercoachingCurriculumDownloadedEvent implements GAEvent {
    event:string = "supercoaching_course_curriculum_viewed";
    info:object;

    private static defaultInfo = {
        productID:'',
        page:'',
        pagePath:'',
        productName: '',
        courseName:'',
        courseID: '',
        isPaid: '',
        goalID:'',
        goalName:''
   }

   constructor(eventInfo:object) {
       this.info = {...SupercoachingCurriculumDownloadedEvent.defaultInfo, ...eventInfo};
   }
}

export class PdfViewerUserClickEvent implements GAEvent {
    event:string = "user_click";
    info:object;

    private static defaultInfo = {
        category:'',
        pageURL:'',
        destinationPageCategory: '',
        destinationURL:'',
        client: '',
        module: '',
        landingPage:'',
        clickText:''
   }

   constructor(eventInfo:object) {
       this.info = {...PdfViewerUserClickEvent.defaultInfo, ...eventInfo};
   }
}

export class PypFreeTestUserClickEvent implements GAEvent {
    event:string = "user_click";
    info:object;

    private static defaultInfo = {
        category:'',
        pageURL:'',
        client: '',
        clickText:'',
        page:''
   }

   constructor(eventInfo:object) {
       this.info = {...PypFreeTestUserClickEvent.defaultInfo, ...eventInfo};
   }
}

export class SupercoachingReadmoreClicked{
    event = "supercoaching_readmore_clicked";
    info: any;

     private static defaultInfo: any = {
        productID: '',
        productName: '',
        page: '',
        pagePath: '',
        goalID:'',
        goalName:''
     } 

     constructor(eventObj){
        this.info = {...SupercoachingReadmoreClicked.defaultInfo, ...eventObj}
    }
}
export class ApplyCouponClicked implements GAEvent {
    event:string = "apply_coupon_clicked";
    info:object;

    private static defaultInfo ={
        goalID:'',
        goalName:'',
        productID:'',
        pagePath:'',
        page:''
    }

    constructor(eventInfo:object){
        this.info = {...ApplyCouponClicked.defaultInfo, ...eventInfo};
    }
}

export class SupercoachingExploredNowClicked{
    event: string = 'supercoaching_explore_now_clicked';
    info: any;

    private static defaultInfo: any = {
        page: '',
        pagePath: '',
        goalID: '',
        goalName: ''
    };
    constructor(eventObj){
        this.info = {...SupercoachingExploredNowClicked.defaultInfo, ... eventObj};
    }
}

export class CompletePendingPaymentClicked{
    event: string = 'complete_pending_payment_clicked';
    info: any;

    private static defaultInfo: any = {
        goalID: '',
        goalName: '',
        page: '',
        pagePath: '',
        productName: '',
        productID: '',
        productCost: '',
        payableAmount: '',
        couponApplied: '',
        couponCode: '',
        discountAmount: '',
    };
    constructor(eventObj){
        this.info = {...CompletePendingPaymentClicked.defaultInfo, ... eventObj};
    }
}

export class SelectionFormProgressClicked{
    event: string = 'selection_form_progress';
    info: any;

    private static defaultInfo: any = {
        page: '',
        pagePath: '',
        category: ''
    };
    constructor(eventObj){
        this.info = {...SelectionFormProgressClicked.defaultInfo, ... eventObj};
    }
}
export class TargetPagePopupClickEvent{
    event: string = 'user_click';
    info: any;

    private static defaultInfo: any = {
        category: '',
        destinationPageCategory: '',
        pageURL: '',
        destinationURL: '',
        client: '',
        module: '',
        page: '',
        pagePath: '',
        module_id: ''
    };
    constructor(eventObj){
        this.info = {...TargetPagePopupClickEvent.defaultInfo, ... eventObj};
    }
}

export class SupercoachingStudyPlanDownload{
    event: string = 'supercoaching_study_plan_downloaded';
    info: any;

    private static defaultInfo: any = {
        targetId: '',
        targetName: '',
        page: '',
        pagePath: '',
    };
    constructor(eventObj){
        this.info = {...SupercoachingStudyPlanDownload.defaultInfo, ... eventObj};
    }
}

export class SupercoachingWhatsappChatClicked{
    event: string = 'supercoaching_whatsapp_chat_clicked';
    info: any;

    private static defaultInfo: any = {
        page: '',
        pagePath: '',
    };
    constructor(eventObj){
        this.info = {...SupercoachingWhatsappChatClicked.defaultInfo, ... eventObj};
    }
}

export class GlobalTestSeriesPageEvent{
    event: string = 'global_test_series_page_explore_activity';
    info: any;

    private static defaultInfo: any = {
        category: '',
        client: '',
        page: '',
        pagePath: '',
        userType:'',
        clickText:'',
        landingPage:''
    };
    constructor(eventObj){
        this.info = {...GlobalTestSeriesPageEvent.defaultInfo, ... eventObj};
    }
}


export class TestSeriesSlugPageEvent{
    event: string = 'test_series_explore_activity';
    info: any;

    private static defaultInfo: any = {
        category: '',
        testSeriesID:'',
        testSeriesName:'',
        medium:'',
        userStage:'',
        examStage:'',
        client: '',
        page: '',
        pagePath: '',
        userType:'',
        selectedBranch:''
    };
    constructor(eventObj){
        this.info = {...TestSeriesSlugPageEvent.defaultInfo, ... eventObj};
    }
}

export class GameRewardWonEvent{
    event: string = 'game_reward_won';
    info: any;

    private static defaultInfo: any = {
        campaignID: '',
        campaignName: '',
        campaignType: '',
        couponCode: '',
        goalID: '',
        goalName: '',
        page: '',
        pagePath: '',
    };
    constructor(eventObj){
        this.info = {...GameRewardWonEvent.defaultInfo, ... eventObj};
    }
}

export class GoalPitchBannerClicked{
    event: string = 'goal_pitch_banner_clicked';
    info: any;

    private static defaultInfo: any = {
        goalID: '',
        goalName: '',
        page: '',
        pagePath: '',
        deepLink: '',
        key: ''
    };
    constructor(eventObj){
        this.info = {...GoalPitchBannerClicked.defaultInfo, ... eventObj};
    }
}


export class GameCouponAppliedEvent{
    event: string = 'game_coupon_claimed';
    info: any;

    private static defaultInfo: any = {
        campaignID: '',
        campaignName: '',
        campaignType: '',
        couponCode: '',
        goalID: '',
        goalName: '',
        page: '',
        pagePath: '',
    };
    constructor(eventObj){
        this.info = {...GameCouponAppliedEvent.defaultInfo, ... eventObj};
    }
}

export class QueryParamDynamicEvent {
    event: string = '';
    info: any;
    private static defaultInfo: any = {
        goalID: '',
        goalName: '',
        page: '',
        pagePath: '',
    };
    constructor(eventName,eventObj){
        this.event = eventName;
        this.info = {...QueryParamDynamicEvent.defaultInfo, ...eventObj};
    }
}

export class GoalPurchasePopupClosedEvent{
    event: string = 'goal_purchase_popup_closed';
    info: any;

    private static defaultInfo: any = {
        goalID: '',
        goalName: '',
        page: '',
        pagePath: '',
    };
    constructor(eventObj){
        this.info = {...GoalPurchasePopupClosedEvent.defaultInfo, ... eventObj};
    }
}

export class GoalPurchasePopupClickedEvent{
    event: string = 'goal_purchase_popup_clicked';
    info: any;

    private static defaultInfo: any = {
        goalID: '',
        goalName: '',
        page: '',
        pagePath: '',
    };
    constructor(eventObj){
        this.info = {...GoalPurchasePopupClickedEvent.defaultInfo, ... eventObj};
    }
}

export class GoalPurchasePopupViewedEvent{
    event: string = 'goal_purchase_popup_viewed';
    info: any;

    private static defaultInfo: any = {
        goalID: '',
        goalName: '',
        page: '',
        pagePath: '',
    };
    constructor(eventObj){
        this.info = {...GoalPurchasePopupViewedEvent.defaultInfo, ... eventObj};
    }
}

export class SignupActivityEvent{
    event: string = 'signup_activity';
    info: any;
    private static defaultInfo: any = {
        pagePath:'',
        type: '',
        category: '',
        page: ''
    };
    constructor(eventObj){
        this.info = {...SignupActivityEvent.defaultInfo, ... eventObj};
    }
}
export class ProductsOfferingTabClicked{
    event: string = 'products_offering_tab_clicked';
    info: any;

    private static defaultInfo: any = {
        goalID: '',
        goalName: '',
        page: '',
        pagePath: '',
        TabSwitched: '',
    };
    constructor(eventObj){
        this.info = {...ProductsOfferingTabClicked.defaultInfo, ... eventObj};
    }
}

export class SuperCoachingGoalRecommendationClicked {
    event: string = 'supercoaching_goal_recommendation_clicked';
    info: any;
    private static defaultInfo: any = {
        productID: '',
        productName: '',
        page: '',
        pagePath: '',
        goalID: '',
        goalName: '',
    };

    constructor(eventObj) {
        this.info = {...SuperCoachingGoalRecommendationClicked.defaultInfo, ...eventObj};
    }
}

export class PassProPopupActivity{
    event: string = 'passpro_popup_activity';
    info: any;

    private static defaultInfo: any = {
        page: '',
        pagePath: '',
        type:'',
        category:'',
        userType:'',
        isLoggedIn: false,
        referrer:'',
        pageType: ''
    };
    constructor(eventObj){
        this.info = {...PassProPopupActivity.defaultInfo, ... eventObj};
    }
}

export class PassProPlansPageVisited{
    event: string = 'passpro_plans_page_visited';
    info: any;

    private static defaultInfo: any = {
        userType:'',
        referrer:'',
        isLoggedIn: false,
        pageType: ''
    };
    constructor(eventObj){
        this.info = {...PassProPlansPageVisited.defaultInfo, ... eventObj};
    }
}

export class PassProProceededToPayment{
    event: string = 'passpro_proceeded_to_payment';
    info: any;

    private static defaultInfo: any = {
        userType:'',
        referrer:'',
        productID:'',
        productName:'',
        payableAmount:'',
        productCost:'',
        couponCode:'',
        previousPage:'',
        previousPagePath:'',
        isLoggedIn: false,
        discountAmount:'',
        pageType: '',
    };
    constructor(eventObj){
        this.info = {...PassProProceededToPayment.defaultInfo, ... eventObj};
    }
}

export class SupercoachingGoalSelectionPageVisited {
    event = 'goal_selection_page_visited';
    info: any;
    constructor(eventObj){
        this.info={...SupercoachingGoalSelectionPageVisited, ... eventObj}
    }
}

export class ReattemptTestEvent implements GAEvent {
    event:string = "reattempt_test_clicked";
    info:any;

    private static defaultInfo:any = {
        testName:'',
        testID:'',
        attemptNo:'',
        page:'',
        pagePath:'',
        type:'',
        userType:''
    };
    constructor(eventObj){
        this.info = {...ReattemptTestEvent.defaultInfo, ... eventObj};
    }
}
export class DeactivateAccountEvent implements GAEvent {
    event:string = "account_deactivated";
    info:any;

    private static defaultInfo:any = {
        page:'',
        pagePath:''
    }
    constructor(eventObj){
        this.info = {...DeactivateAccountEvent.defaultInfo, ... eventObj};
    }
}
export class UpdatePincodeEvent implements GAEvent {
    event:string = "pincode_updated";
    info:any;

    private static defaultInfo:any = {
        page:'',
        pagePath:'',
        pin:''
    };
    constructor(eventObj){
        this.info = {...UpdatePincodeEvent.defaultInfo, ... eventObj};
    }
}
export class UpdateCategoryEvent implements GAEvent {
    event:string = "category_updated";
    info:any;

    private static defaultInfo:any = {
        page:'',
        pagePath:'',
        category:''
    };
    constructor(eventObj){
        this.info = {...UpdateCategoryEvent.defaultInfo, ... eventObj};
    }
}
export class UpdateDegreeEvent implements GAEvent {
    event:string = "degrees_updated";
    info:any;

    private static defaultInfo:any = {
        page:'',
        pagePath:'',
        degrees:[]
    };
    constructor(eventObj){
        this.info = {...UpdateDegreeEvent.defaultInfo, ... eventObj};
    }
}
export class UpdateDobEvent implements GAEvent {
    event:string = "dob_updated";
    info:any;

    private static defaultInfo:any = {
        page:'',
        pagePath:'',
        birthday:''
    };
    constructor(eventObj){
        this.info = {...UpdateDobEvent.defaultInfo, ... eventObj};
    }
}
export class InterestedInEmailMentorship implements GAEvent {
    event:string = "interested_in_email_mentorship";
    info:any;

    private static defaultInfo:any = {
        goalID:'',
        goalName:'',
        page:'',
        pagePath:'',
    };
    constructor(eventObj){
        this.info = {...InterestedInEmailMentorship.defaultInfo, ... eventObj};
    }
}

export class EmailMentorshipEnrolled implements GAEvent {
    event:string = "email_mentorship_enrolled";
    info:any;

    private static defaultInfo:any = {
        goalID:'',
        goalName:'',
        email:'',
        page:'',
        pagePath:'',
    };
    constructor(eventObj){
        this.info = {...EmailMentorshipEnrolled.defaultInfo, ... eventObj};
    }
}

export class CombinedPassComparisonPageActivityEvent implements GAEvent {
    event: string = 'combined_pass_comparison_page_activity';
    info: any;

    private static defaultInfo: any = {
        category: '',
        page: '',
        pagePath: '',
        pageType: '',
        type: '',
        userType: ''
    };
    constructor(eventObj){
        this.info = {...CombinedPassComparisonPageActivityEvent.defaultInfo, ... eventObj};
    }
}
export class MainsAnswerCategoryChanged{
    event: string = 'answer_writing_card_category_changed';
    info: any;

    private static defaultInfo: any = {
        pagePath: '',
        page: '',
        pageType:'',
        clickText : '',
        goalID:'',
        goalName:'',

    };
    constructor(eventObj){
        this.info = {...MainsAnswerCategoryChanged.defaultInfo, ... eventObj};
    }
}

export class MainsUserAnswerSubmitted{
    event: string = 'answer_writing_submission_added';
    info: any;

    private static defaultInfo: any = {
        pagePath: '',
        page: '',
        pageType:'',
        goalID:'',
        goalName:'',
        doubtID:'',
        answerWritingCategory:''
    };
    constructor(eventObj){
        this.info = {...MainsUserAnswerSubmitted.defaultInfo, ... eventObj};
    }
}

export class MainsAnswerUserUpVoted{
    event: string = 'answer_writing_submission_upvoted';
    info: any;

    private static defaultInfo: any = {
        pagePath: '',
        page: '',
        pageType:'',
        goalID:'',
        goalName:'',
        doubtID:'',
        answerWritingCategory:''
    };
    constructor(eventObj){
        this.info = {...MainsAnswerUserUpVoted.defaultInfo, ... eventObj};
    }
}

export class MainsAnswerViewAllClicked{
    event: string = 'answer_writing_page_viewed';
    info: any;

    private static defaultInfo: any = {
        pagePath: '',
        page: '',
        pageType:'',
        goalID:'',
        goalName:'',
        answerWritingCategory:''
    };
    constructor(eventObj){
        this.info = {...MainsAnswerViewAllClicked.defaultInfo, ... eventObj};
    }
}

export class MainsAnswerCardClicked{
    event: string = 'answer_writing_card_clicked';
    info: any;

    private static defaultInfo: any = {
        category:'',
        pagePath: '',
        page: '',
        pageType:'',
        goalID:'',
        goalName:'',
        answerWritingCategory:'',
        doubtID:''
    };
    constructor(eventObj){
        this.info = {...MainsAnswerCardClicked.defaultInfo, ... eventObj};
    }
}

export class SuperCoachingEntityExploreEvent{
    event: string = 'supercoaching_entity_explored';
    info: any;

    private static defaultInfo: any = {
        category:'',
        pagePath: '',
        page: '',
        pageType:'',
        isLoggedIn: false,
        goalId:'',
        goalName:'',
        clickText:'',
        tabName:''
    };
    constructor(eventObj){
        this.info = {...SuperCoachingEntityExploreEvent.defaultInfo, ... eventObj};
    }
}

export class SuperCoachingTalkToExpert{
    event: string = 'supercoaching_talk_to_expert_clicked';
    info: any;

    private static defaultInfo: any = {
        pagePath: '',
        page: '',
        pageType:'',
        goalId:'',
        goalName:'',
        tabName: '',
        refferer:'',
        isLoggedIn:''
    };
    constructor(eventObj){
        this.info = {...SuperCoachingTalkToExpert.defaultInfo, ... eventObj};
    }
}

export class MentorshipButtonClicked{
    event: string = 'mentorship_button_clicked';
    info: any;

    private static defaultInfo: any = {
        pagePath: '',
        page: '',
        pageType:'',
        goalID:'',
        goalName:'',
        sessionsLeft:'',
        type:''
    };
    constructor(eventObj){
        this.info = {...MentorshipButtonClicked.defaultInfo, ... eventObj};
    }
}

export class MentorshipModalDismissed{
    event: string = 'mentorship_popup_dismissed';
    info: any;

    private static defaultInfo: any = {
        pagePath: '',
        page: '',
        pageType:'',
        goalID:'',
        goalName:'',
        sessionsLeft:'',
    };
    constructor(eventObj){
        this.info = {...MentorshipModalDismissed.defaultInfo, ... eventObj};
    }
}

export class BookMentorshipButtonClicked{
    event: string = 'book_mentorship_session_clicked';
    info: any;

    private static defaultInfo: any = {
        pagePath: '',
        page: '',
        pageType:'',
        goalID:'',
        goalName:'',
        sessionsLeft:'',
        referrer:''
    };
    constructor(eventObj){
        this.info = {...BookMentorshipButtonClicked.defaultInfo, ... eventObj};
    }
}

export class AppDownloadClickEvent{
    event: string = 'download_app_clicked';
    info: any;

    private static defaultInfo: any = {
        pagePath: '',
        page: '',
        pageType:'',
        isLoggedIn:false
    };
    constructor(eventObj){
        this.info = {...AppDownloadClickEvent.defaultInfo, ... eventObj};
    }
}

export class PageExploreActivity{
    event:string = 'page_explore_activity';
    info:any;

    private static defaultInfo: any = {
        pagePath: '',
        page: '',
        pageType:'',
        isLoggedIn:false,
        category:''
    };

    constructor(eventObj){
        this.info = {...PageExploreActivity.defaultInfo, ... eventObj};
    }
}

export class StudyPlanClickEvent{
    event:string = 'study_plan_clicked';
    info:any;

    private static defaultInfo: any = {
        pagePath: '',
        page: '',
        pageType:'',
        isLoggedIn: false
    };

    constructor(eventObj){
        this.info = {...StudyPlanClickEvent.defaultInfo, ... eventObj};
    }
}

export class StudyPlanGeneratedEvent{
    event:string = 'study_plan_generated';
    info:any;

    private static defaultInfo: any = {
        pagePath: '',
        page: '',
        pageType:'',
        isLoggedIn: false
    };

    constructor(eventObj){
        this.info = {...StudyPlanGeneratedEvent.defaultInfo, ... eventObj};
    }
}

export class PypChildPageExploreActivityEvent{
    event:string = 'pyp_childPage_explore_activity';
    info:any;

    private static defaultInfo: any = {
        pagePath: '',
        page: '',
        pageType:'',
        isLoggedIn: false,
        category:'',
        client:'',
        stage:'',
        type:''
    };

    constructor(eventObj){
        this.info = {...PypChildPageExploreActivityEvent.defaultInfo, ... eventObj};
    }
}

export class ViewEmiPlanClickEvent{
    event:string = 'view_emi_plans_clicked';
    info:any;

    private static defaultInfo:any = {
        pagePath: '',
        page: '',
        pageType:'',
        isLoggedIn:false,
        goalID:'',
        goalName:'',
        productID: '',
        productName:'',
        emiPlanPrice: '',
        payableAmount: -1,
        clickText: '',
        userType: ''
    }

    constructor(eventObj){
        this.info = {...ViewEmiPlanClickEvent.defaultInfo, ...eventObj};
    }
}

export class DownPaymentPopupViewEvent{
    event:string = 'emi_mandate_info_popup_viewed';
    info:any;

    private static defaultInfo:any = {
        pagePath: '',
        page: '',
        pageType:'',
        isLoggedIn:false,
        goalID:'',
        goalName:'',
        productID: '',
        productName:'',
        emiPlanPrice: '',
        payableAmount: -1,
        clickText: '',
        userType: ''
    }

    constructor(eventObj){
        this.info = {...DownPaymentPopupViewEvent.defaultInfo, ...eventObj};
    }
}

export class DownPaymentPopupClosedEvent{
    event:string = 'emi_mandate_info_popup_closed';
    info:any;

    private static defaultInfo:any = {
        pagePath: '',
        page: '',
        pageType:'',
        isLoggedIn:false,
        goalID:'',
        goalName:'',
        productID: '',
        productName:'',
        emiPlanPrice: '',
        payableAmount: -1,
        userType: ''
    }

    constructor(eventObj){
        this.info = {...DownPaymentPopupClosedEvent.defaultInfo, ...eventObj};
    }
}

export class SuperCoachingPurchaseModalViewed{
    event:string = 'supercoaching_purchase_screen_visited';
    info:any;

    private static defaultInfo: any = {
        pagePath: '',
        page: '',
        pageType:'',
        isLoggedIn:false,
        type: '', //emi or not-emi
        goalId: '',
        goalName: '',
        productId: '',
        productName: '',
        screen: '',
        subscriptionCategory: ''
    };

    constructor(eventObj){
        this.info = {...SuperCoachingPurchaseModalViewed.defaultInfo, ... eventObj};
    }
}

export class SupercoachingEmiProceededToPayment{
    event:string='supercoaching_proceeded_to_emi_payment'
    info:any;

    private static defaultInfo:any = {
        productName:'',
        productID:'',
        duration:'',
        productCost:'',
        payableAmount:'',
        discountAmount:'',
        page:'',
        pageType:'',
        isLoggedIn: false,
        pagePath:'',
        goalName:'',
        goalID:'',
        couponCode: '',
        couponApplied: '',
        emiPlanPrice: '',
        userType: ''
    }
    constructor(eventObj){
        this.info={...SupercoachingEmiProceededToPayment.defaultInfo, ... eventObj}
    }
}

export class EmiMandateSuccess{
    event:string='mandate_success'
    info:any;

    private static defaultInfo:any ={
        page:'',
        pageType:'',
        pagePath:'',
        isLoggedIn: false,
        goalName:'',
        goalID:'',
        productName:'',
        productID:'',
        productCost:'',
        payableAmount:'',
        emiPlanPrice: '', //monthly emi amount
        emiPlan: '' // 3 months plan or 6 months plan
    }
    constructor(eventObj){
        this.info={...EmiMandateSuccess.defaultInfo, ... eventObj}
    }
}

export class EnableAutoEmiPaymentClicked{
    event:string='enable_auto_emi_payment_clicked'
    info:any;

    private static defaultInfo:any = {
        page:'',
        pageType:'',
        pagePath:'',
        isLoggedIn: false,
        goalName:'',
        goalID:'',
        productName:'',
        productID:'',
        emiPlanPrice: '',
        payableAmount:'',
        userType: '',
    }
    constructor(eventObj){
        this.info={...EnableAutoEmiPaymentClicked.defaultInfo, ... eventObj}
    }
}

export class EmiMandateInformationCollected{
    event:string='emi_mandate_information_collected'
    info:any;

    private static defaultInfo:any = {
        page:'',
        pageType:'',
        pagePath:'',
        isLoggedIn: false,
        goalName:'',
        goalID:'',
        productName:'',
        productID:'',
        emiPlanPrice: '',
        payableAmount:'',
        userType: '',
    }
    constructor(eventObj){
        this.info={...EmiMandateInformationCollected.defaultInfo, ... eventObj}
    }
}

export class BlockerPopupViewedEvent {
    event:string = 'enable_auto_emi_popup_viewed';
    info:any;

    private static defaultInfo:any = {
        pagePath: '',
        page: '',
        pageType:'',
        isLoggedIn:false,
        goalID:'',
        goalName:'',
        productID: '',
        productName:'',
        emiPlanPrice: '',
        payableAmount: -1,
    }

    constructor(eventObj){
        this.info = {...BlockerPopupViewedEvent.defaultInfo, ...eventObj};
    }
}

export class EmiInfoScreenVisitedEvent {
    event:string = 'emi_info_collection_screen_visited';
    info:any;

    private static defaultInfo:any = {
        pagePath: '',
        page: '',
        pageType:'',
        isLoggedIn:false,
        goalID:'',
        goalName:'',
        productID: '',
        productName:'',
        emiPlanPrice: '',
        payableAmount: -1,
        userType: '',
        referrer: ''
    }

    constructor(eventObj){
        this.info = {...EmiInfoScreenVisitedEvent.defaultInfo, ...eventObj};
    }
}

export class NPSFeedbackEvent {
    event: string = 'nps_feedback_activity';
    info: any;

    private static defaultInfo: any = {
        pagePath: '',
        page: '',
        pageType: '',
        isLoggedIn: false,
        goalId: '',
        goalName: '',
        mode: '',
        type: '',
        activity: '',
        category: ''
    }

    constructor (eventObj) {
        this.info = {...NPSFeedbackEvent.defaultInfo, ...eventObj};
    }
}
export class PassWrappedViewPageEvent {
    event:string = 'passwrapped_view_page';
    info:any;

    private static defaultInfo:any = {
        pagePath: '',
        page: '',
        pageType:'',
        isLoggedIn:false,
        index:1,
        pageName:'',
        userType:''
    }

    constructor(eventObj){
        this.info = {...PassWrappedViewPageEvent.defaultInfo, ...eventObj};
    }
}

export class PassWrappedClickEvent {
    event:string = 'passwrapped_click_event';
    info:any;

    private static defaultInfo:any = {
        pagePath: '',
        page: '',
        pageType:'',
        isLoggedIn:false,
        index:1,
        pageName:'',
        category:'',
        userType:''
    }

    constructor(eventObj){
        this.info = {...PassWrappedClickEvent.defaultInfo, ...eventObj};
    }
}

export class ScholarshipTestClickEvent {
    event:string = 'scholarship_test_clicked';
    info:any;

    private static defaultInfo:any = {
        pagePath: '',
        page: '',
        pageType: '',
        isLoggedIn: false,
        clickText: '',
        targetId: '',
        targetName: '',
        goalId: '',
        goalName: '',
        testId: '',
        testName: ''
    }

    constructor(eventObj){
        this.info = {...ScholarshipTestClickEvent.defaultInfo, ...eventObj};
    }
}

export class AddressSubmittedEvent {
    event: string = 'address_submitted';
    info: any;

    private static defaultInfo: any = {
        client: isMobile() ? 'Mweb' : 'Web',
        page: '',
        pagePath: '',
        pageType: ''
    }

    constructor(eventObj) {
        this.info = {...AddressSubmittedEvent.defaultInfo, ...eventObj};
    }
}

export class BookOrdersClicked {
    event: string = 'supercoaching_individual_page_activity';
    info: any;

    private static defaultInfo: any = {
        client: isMobile() ? 'Mweb' : 'Web',
        category: '',
        page: '',
        pagePath: '',
        pageType: '',
        productID: '',
        productName: ''
    }

    constructor(eventObj) {
        this.info = {...BookOrdersClicked.defaultInfo, ...eventObj};
    }
}

export class PlutusEnquiryFormFilled {
    event: string = 'enquiry_form_filled';
    info: any;

    private static defaultInfo: any = {
        client: isMobile() ? 'Mweb' : 'Web',
        category: '',
        page: '',
        pagePath: '',
        pageType: '',
        productID: '',
        productName: ''
    }

    constructor(eventObj) {
        this.info = {...PlutusEnquiryFormFilled.defaultInfo, ...eventObj};
    }
}

export class PlutusDownloadBrochureClicked {
    event: string = 'home_page_brochure_downloaded';
    info: any;

    private static defaultInfo: any = {
        client: isMobile() ? 'Mweb' : 'Web',
        category: '',
        page: '',
        pagePath: '',
        pageType: '',
        productID: '',
        productName: ''
    }

    constructor(eventObj) {
        this.info = {...PlutusDownloadBrochureClicked.defaultInfo, ...eventObj};
    }
}

export class PlutusCourseClicked {
    event: string = 'course_clicked';
    info: any;

    private static defaultInfo: any = {
        client: isMobile() ? 'Mweb' : 'Web',
        category: '',
        page: '',
        pagePath: '',
        pageType: '',
        productID: '',
        productName: ''
    }

    constructor(eventObj) {
        this.info = {...PlutusCourseClicked.defaultInfo, ...eventObj};
    }
}

export class PlutusMiniCourseClicked {
    event: string = 'mini_course_clicked';
    info: any;

    private static defaultInfo: any = {
        client: isMobile() ? 'Mweb' : 'Web',
        category: '',
        page: '',
        pagePath: '',
        pageType: '',
        productID: '',
        productName: ''
    }

    constructor(eventObj) {
        this.info = {...PlutusMiniCourseClicked.defaultInfo, ...eventObj};
    }
}

export class PlutusCallBackRequested {
    event: string = 'homepage_call_back_requested';
    info: any;

    private static defaultInfo: any = {
        client: isMobile() ? 'Mweb' : 'Web',
        category: '',
        page: '',
        pagePath: '',
        pageType: '',
        productID: '',
        productName: ''
    }

    constructor(eventObj) {
        this.info = {...PlutusCallBackRequested.defaultInfo, ...eventObj};
    }
}

export class PlutusJoinWebinarClicked{
    event: string = 'webinar_join_now_clicked';
    info: any;

    private static defaultInfo: any = {
        client: isMobile() ? 'Mweb' : 'Web',
        category: '',
        page: '',
        pagePath: '',
        pageType: '',
        productID: '',
        productName: ''
    }

    constructor(eventObj) {
        this.info = {...PlutusJoinWebinarClicked.defaultInfo, ...eventObj};
    }
}
export class QRCodeScanned {
    event:string = 'qr_code_scanned';
    info:any;

    private static defaultInfo:any = {
        subMerchantId: '',
        distributorId: '',
        dateTime: '',
        location: ''
    }
    constructor(eventObj) { 
        this.info = {...QRCodeScanned.defaultInfo, ...eventObj};
    }
}

export class AmountEnteredEvent {
    event:string = 'amount_entered';
    info:any;

    private static defaultInfo:any = {
        subMerchantId: '',
        distributorId: '',
        dateTime: '',
        amount: 0
    }

    constructor(eventObj) {
        this.info = {...AmountEnteredEvent.defaultInfo, ...eventObj};
    }
}

export class ProceedToPay {
    event:string = 'proceed_to_pay';
    info:any;

    private static defaultInfo:any = {
        subMerchantId: '',
        distributorId: '',
        dateTime: '',
        userNumber: ''
    }

    constructor(eventObj) {
        this.info = {...ProceedToPay.defaultInfo, ...eventObj};
    }
}

export class NumberEntered {
    event: string = 'number_entered';
    info:any;

    private static defaultInfo:any = {
        subMerchantId: '',
        distributorId: '',
        dateTime: '',
        userStatus: '',
        userNumber: ''
    }

    constructor(eventObj) {
        this.info = {...NumberEntered.defaultInfo, ...eventObj};
    }
}

export class UnlockPassPro {
    event: string = 'unlock_pass_pro';
    info:any;

    private static defaultInfo:any = {
        subMerchantId: '',
        distributorId: '',
        dateTime: '',
        userPaymentOpted: false,
        userNumber: ''
    }

    constructor(eventObj) {
        this.info = {...UnlockPassPro.defaultInfo, ...eventObj};
    }
}

export class OrderStatus {
    event: string = 'order_status';
    info:any;

    private static defaultInfo:any = {
        subMerchantId: '',
        distributorId: '',
        dateTime: '',
        status: '',
        orderId: '',
        userNumber: ''
    }

    constructor(eventObj) {
        this.info = {...OrderStatus.defaultInfo, ...eventObj};
    }
}


