import {Injectable} from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    ActivatedRoute,
    UrlTree
} from '@angular/router';
import {Observable, of} from "rxjs";
import {PlatformService} from "@core/services/platform-service";
import { Store } from '@ngrx/store';
import {getQueryStringFromParams} from "@shared/utility/url";
import {isServer} from "@shared/utility/tb-common";


@Injectable()
export class RequiresLoginGuard implements CanActivate {

    constructor(private router:Router,private route: ActivatedRoute,private platformService: PlatformService,private store: Store<{}>) {
    }

    canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean|UrlTree> {
        let pathName = this.platformService.getPathName();
        let queryString = isServer() ? getQueryStringFromParams(this.platformService.query) : window.location.search;
        if(!this.platformService.isLoggedIn()){
            let url=`/login?redirect_url=`+encodeURIComponent(`${pathName}${queryString}`)
            this.platformService.redirect(url,302);
            return of(false);
        }else{
            return of(true);
        }
    }

}