import {Component, OnInit} from '@angular/core';
import {selectHeaderState} from "@core/application-state/app.selectors";
import {Store} from "@ngrx/store";
import {PlatformService} from "@core/services/platform-service";
import {mobileQuitForClassPlus} from "@angular-commons/shared/utility/tb-common";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    isLoggedIn = this.platformService.isLoggedIn();
    isMobile = this.platformService.isMobile();
    isEcardsFlowPage = ["/e-cards","/onboarding","/enrollment","/login"].includes(this.platformService.getPathName());
    showHeader: boolean = true;
    activationCode = "";
    isFromIOS: boolean = false;
    projectName = this.platformService.currentProjectName();

    constructor(private store: Store<{}>, private platformService: PlatformService) {

    }

    ngOnInit(): void {
        this.activationCode = this.platformService.getCookie('trans_code');
        this.store.select(selectHeaderState).subscribe( (value) => {
                this.showHeader = value;
            }
        )
        this.isFromIOS = this.platformService.getQueryParamValue('fromIOS') || false;
    }
    backBtn(){
        mobileQuitForClassPlus();
    }
}
