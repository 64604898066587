import { createAction ,props } from '@ngrx/store';
import {_CouponCTAData, _CouponProductsArray} from '@models/entity/coupon/coupon.adapter'

export const getStudentCoupons = createAction('[coupon] getStudentCoupons' , props<{prodIds: string[]}>());

export const studentCouponsLoaded = createAction('[coupon] studentCouponsLoaded' , props<{payload: any}>());

export const getCouponProducts = createAction('[coupon] getCouponProducts' , props<{coupon: string, from: string , itemId: string, itemType: string}>());

export const couponProductsLoaded = createAction('[coupon] couponProductsLoaded' , props<{payload: _CouponProductsArray}>());

export const couponProductsError = createAction('[coupon] couponProductsError' , props<{payload: any}>());

export const setCoupon = createAction('[coupon] Set Coupon', props<{coupon: {code: string, state: string}}>());

export const resetCoupon = createAction('[coupon] Reset Coupon');

export const getCouponCTAData = createAction('[coupon] getCouponCTAData');

export const couponCTADataLoaded = createAction('[coupon] couponCTADataLoaded' , props<{payload: _CouponCTAData}>());

export const showCouponSuccessModal = createAction('[coupon] Show Coupon Success Modal');

export const hideCouponSuccessModal = createAction('[coupon] Hide Coupon Success Modal');

