import {createAction, props} from "@ngrx/store";
import {
  GetCallbackStatusParams,
  RequestCallbackParams,
  _CallbackData,
  CreateLeadsParams, LeadsData
} from "@angular-commons/models/entity/request-callback/request-callback.adapter";

export const getCallbackStatus = createAction('[RequestCallback] getCallbackStatus', props<{params: GetCallbackStatusParams}>());
export const callbackStatusLoaded = createAction('[RequestCallback] callbackStatusLoaded', props<{payload: _CallbackData}>());

export const requestCallback = createAction('[RequestCallback] requestCallback', props<{details: RequestCallbackParams}>());
export const requestCallbackSuccess = createAction('[RequestCallback] requestCallbackSuccess', props<{mobile:string, classId:string, submitted:boolean}>());

export const createLeads = createAction('[RequestCallback] Create Leads', props<{details: CreateLeadsParams}>());
export const createLeadsSuccess = createAction('[RequestCallback] Create Leads Success', props<{mobile: string, goalId: string, submitted: boolean}>());

export const getLeadsData = createAction('[RequestCallback] Get Leads Data', props<{params: any}>());
export const getLeadsDataSuccess = createAction('[RequestCallback] Get Leads Data Success', props<{payload: LeadsData}>());

