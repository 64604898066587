import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TimerService} from "@core/services/timer.service";
import {getTimeLeft} from "@shared/utility/date-utilities";
import {pad} from "@shared/utility/string-utilities";
import {isServer} from "@shared/utility/tb-common";
import {getCloudBucketData} from "@core/application-state/app.actions";
import {Subscription} from "rxjs";
import {selectCloudBucketData} from "@core/application-state/app.selectors";
import {Store} from "@ngrx/store";
import {TransferStateManager} from "@core/services/transferStateManager";
import {PlatformService} from "@core/services/platform-service";

@Component({
  selector: 'sale-offer-strip',
  templateUrl: './sale-offer-strip.component.html',
  styleUrls: ['./sale-offer-strip.component.scss']
})
export class SaleOfferStripComponent implements OnInit {

  @Input() fileName;
  @Input() isInBanner = false;
  @Input() isInStrip = false;
  @Input() cssModifierClass?:string='';
  @Input() goalId;
  @Input() customTimerData;
  @Input() stripType = 'default';
  @Output() isSaleActive = new EventEmitter();
  public isMobile: boolean = this.platformService.isMobile();


  cloudBucketSub$: Subscription;
  cloudBucketData$ = this.store.select(selectCloudBucketData);

  salePitchText = '';
  timerText = '00:00:00';
  timerTitle = 'Sale ends in ';
  showSaleStrip = false;
  saleData;

  constructor(private timerService: TimerService,  private store: Store<{}>,
              private transferStateManager: TransferStateManager, private platformService: PlatformService) { }

  ngOnInit(): void {
    if (this.customTimerData) {
      this.saleData = this.customTimerData;
      this.timerTitle = this.customTimerData?.salePitchText;
      this.renderSaleTimer();
    }
    else {
      this.getSaleStripData();
    }
  }

  getSaleStripData() {
    if(!this.fileName) {return;}
    let key = this.fileName;
    this.cloudBucketSub$ = this.transferStateManager.get(key, getCloudBucketData({fileName: key}), this.cloudBucketData$,
    (value) => {
      if (value && Object.keys(value).length && value[key] && Object.keys(value[key]).length && value[key][this.goalId]) {
        let currObj = value[key][this.goalId];
        if (currObj && Object.keys(currObj).length) {
          this.saleData = {
            timerFrom:currObj.start_time,
            timerTill:currObj.end_time,
            goalId:currObj.goalId,
            isActive:currObj.active,
            saleName:currObj.saleName,
            salePitchText:currObj.salePitchText
          };
          this.renderSaleTimer();
        }
      }
    });
  }
  renderSaleTimer(){
    if (!this.saleData || !this.saleData?.timerFrom || !this.saleData?.timerTill || !this.saleData?.isActive) {
      this.showSaleStrip = false;
      this.isSaleActive.emit(this.showSaleStrip);
      return;
    }

    this.showSaleStrip = true;
    this.isSaleActive.emit(this.showSaleStrip);
    let now = Date.now();
    let timerFrom = new Date(this.saleData.timerFrom).getTime();
    let timerTill = new Date(this.saleData.timerTill).getTime();


    if (now < timerFrom || now > timerTill){
      this.showSaleStrip = false;
      this.isSaleActive.emit(this.showSaleStrip);
      return;
    }
    this.salePitchText = this.saleData.salePitchText;
    if(!isServer()){
      this.timerService.startTimer().subscribe(() => {
        if (timerTill < Date.now()){
          this.showSaleStrip = false;
          this.isSaleActive.emit(this.showSaleStrip);
        }
        let timeDiff = getTimeLeft(timerTill);
        this.timerText =  pad(timeDiff.h.toString(), '0', 2) + ':' + pad(timeDiff.m.toString(), '0', 2) + ':' + pad(timeDiff.s.toString(), '0', 2);
      });
    }
  }
}
