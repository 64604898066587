import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from "@angular/router";
import {CoreModule} from "@core/core.module";
import {FeatureVideoComponent} from './feature-components/feature-video-component/feature-video.component'
import {PassHeaderComponent} from './feature-components/pass-header-component/pass-header.component'
import {PassReferralComponent} from './feature-components/pass-referral-component/pass-referral.component'
import {PassCardsExportModule} from './feature-components/pass-cards-component/pass-cards.export.module';
import {PassPitchExportModule} from '@angular-commons/feature-modules/pass-pitch/pass-pitch-export.module';
import { ImageBubbleExportModule } from '@angular-commons/feature-modules/image-bubble/image-bubble.export.module';
import { PreparationHelpComponent } from './feature-components/preparation-help/preparation-help.component';
import { PassIncludesComponent } from './feature-components/pass-header-component/pass-includes/pass-includes.component';
import {SaleOfferStripModule} from "@features/sale-offer-strip/sale-offer-strip.module";
import {RequestCallbackModule} from "@features/request-callback/request-callback.module";
import {PassProFeaturePitchExportModule} from "@features/pass-pro-feature-pitch/pass-pro-feature-pitch.export.module";
import {PassUserAnnouncementExportModule} from "@features/pass-user-announcement/pass-user-announcement.export.module";
import {PassFeatureExportModule} from "@features/pass-feature-component/pass-feature.export.module";
import {PassStatusExportModule} from "@features/pass-status-component/pass-status.export.module";

@NgModule({
    declarations: [
        FeatureVideoComponent,PassHeaderComponent,PassReferralComponent,PreparationHelpComponent, PassIncludesComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        CoreModule,
        PassPitchExportModule,
        PassCardsExportModule,
        ImageBubbleExportModule,
        SaleOfferStripModule,
        RequestCallbackModule,
        PassProFeaturePitchExportModule,
        PassUserAnnouncementExportModule,
        PassFeatureExportModule,
        PassStatusExportModule
    ],
    exports: [
        FeatureVideoComponent,
        PassHeaderComponent,
        PassReferralComponent,
        PassCardsExportModule,
        PreparationHelpComponent,
        PassIncludesComponent
    ]
})
export class PassPageExportModule { }