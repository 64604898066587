export const mgoalMentorShip = {
    'mpsc-mentorship' : {
        dlPage:'mGoal-Mpsc',
        pageType:"mGoal-Mpsc",
        heroImage:'./assets/img/other/ravi-kapoor-hero-image.webp',
        heroImageClass:'hero',
        modalHeading: 'तुमचा तपशील भरल्याबद्दल धन्यवाद',
        modalSubtext: 'तुम्हाला तुमचा पहिला ईमेल लवकरच मिळेल!',
        mgoalContentMobileView:true,
        content:{
            heading:'ENROLLMENTS ARE OPEN',
            subtitle:'MPSC Aspirants साठी Free Mentorship Program (FMP) by Ravi Kapoor(Ex-IRS & MPSC Mentor) & Ajay Kharde(IRS & MPSC Mentor)',
            instructionTitle:'आम्ही MPSC च्या 2024 व 2025 मध्ये होणाऱ्या परीक्षेची तयारी करणाऱ्या Aspirants साठी Free Mentorship Program (FMP) ची नावनोंदणी सुरू केलेली आहे.',
            instructionSubTitle:'Free Mentorship Program (FMP) ची ठळक वैशिष्ट्ये:',
            InstructionsPoints:[
                'FMP मध्ये आपला प्रवेश निश्चित झाल्यास आपणास त्याबाबतचा ई-मेल पाठवण्यात येईल. यासाठी साधारण 24 तासांचा कालावधी लागू शकतो.',
                'फक्त serious <strong>MPSC Aspirants</strong> ने यासाठी अर्ज करावेत, ही विनंती.',
                '<strong>FMP</strong> मध्ये प्रवेश मिळालेल्या विद्यार्थ्यांना विविध assignments, नोट्स, इतर material <strong>निःशुल्क</strong> देण्यात येईल तसेच, समक्ष भेटही घेता येईल. (Offline interaction).'
            ],
            instructionTitle1:'महत्वाची सूचना:',
            InstructionsPoints1:[
                'आपला FMP प्रोग्राम हा पूर्णपणे <strong>निःशुल्क</strong> असला तरीही आपण तो शिस्तबद्धपणे व गंभीररित्या पूर्ण करणे अपेक्षित आहे.',
                'आम्ही ह्या Mentorship Program च्या गुणवत्तेवर अतिशय focused approach ने मनापासून मेहनत घेत आहोत. म्हणून प्रवेश ग्राह्य झालेल्या aspirants कडून तितक्याच मेहनतीची अपेक्षा करत आहोत.',
                'जर तुम्ही प्रामाणिकपणे व योग्यरित्या या प्रोग्राममध्ये स्वतःला झोकून दिले, तर तुमच्या अपेक्षेपेक्षा कितीतरी पटीने जास्त फायदा तुम्हाला होणार आहे, यात तीळमात्र शंका नाही.',
                'ह्या Mentorship Program च्या माध्यमातून तुमचा MPSC चा प्रवास अधिक सक्षम, सुखकर आणि SUCCESSFUL होणार आहे.'
            ],
            alertTitle:'ऑल द बेस्ट !!!',
            alertSubTitle:'<strong>टीप:</strong> ई-मेल मिळाल्यापासून ते प्रवेश निश्चितीपर्यंत 48 तासांचा कालावधी लागू शकतो.'
        },
        form:{
            name:'नाव*',
            email:'ई-मेल*',
            mobile:'दूरध्वनी क्रमांक*',
            submitAlert:'तुमची माहिती 100% सुरक्षित आहे आणि ती कधीही कोणाशीही शेअर केली जाणार नाही.',
            segementId:'65253c69ea30212199e900cc',
            parentId:'64bf98e8eb0e7f5016a14779',
            action:'fmp_enrolled',
            type:'goal',
            formBorderPresent:true,
            subjectTabEnable:false
        }
    },
    'upsc-mentorship':{
        dlPage:'mGoal-upsc',
        pageType:"mGoal-upsc",
        heroImage:'./assets/img/other/ravi-kapoor-hero-image.webp',
        heroImageClass:'hero',
        modalHeading: 'Thanks for filling your details',
        modalSubtext: 'You will be getting your first email soon!',
        mgoalContentMobileView:true,
        content:{
            heading:'ENROLLMENTS ARE OPEN',
            subtitle:'FREE UPSC MENTORSHIP PROGRAM by Ravi Kapoor, IRS',
            instructionTitle:'WHAT YOU NEED TO KNOW ABOUT THE FMP-',
            InstructionsPoints:[
                'If admitted, you will get a confirmation email along with details of the program.',
                'Only serious UPSC aspirants are encouraged to apply.',
                'Admitted students will receive lessons, feedback assignments, free resources and exclusive insights with opportunities for live interaction.',
                'It will be most beneficial for English medium students.'
            ],
            noticeTitle:'Important Notice:',
            noticeDescription:'This program is absolutely free but it should never be taken for granted. I work very very hard to keep this mentorship program at extra-high quality standards and I expect admitted students to work equally hard, if not more so. Be sincere, hard-working and disciplined and this program is guaranteed to empower you beyond your expectations.',
        },
        form:{
            name:'Your Name*',
            email:'Your Email*',
            mobile:'Your Mobile Number*',
            submitAlert:'Your Information is 100% secure and will never be shared with anyone.',
            segementId:'65253c69ea30212199e900cc',
            parentId:'64bf98e8eb0e7f5016a14779',
            showYouTubeLink:'https://www.youtube.com/watch?v=qY7ax6ai4iU&feature=youtu.be',
            heroSign:'./assets/img/other/ravi-kapoor-signature.webp',
            action:'fmp_enrolled',
            type:'goal',
            heroSignClass:'sign-img',
            formBorderPresent:true
        }
    },
    'upsc-enrolled':{
        dlPage:'mGoal-upsc',
        pageType:"mGoal-upsc",
        heroImage:'./assets/img/other/ravi-kapoor-hero-image.webp',
        heroImageClass:'hero',
        modalHeading: 'Thanks for filling your details',
        modalSubtext: 'You will be getting your first email soon!',
        mgoalContentMobileView:true,
        content:{
            heading:'GS Subject Mentorship',
            subtitle:'FREE UPSC MENTORSHIP PROGRAM by Ravi Kapoor, IRS',
            paragraph:`<p>Hello Students, <br>
            Welcome to this Stage of Our Free Mentorship Program, Where you can access subject-specific assignments tailored to your preparation needs. After completing one subject's assignment, you can proceed to another. Each subject assignment typically lasts 2-3 weeks. Choose your subject from the dropdown menu below to start receiving assignment emails for the next 2-3 weeks. Make the most of this opportunity and best of luck!</p>`
        },
        form:{
            name:'Your Name*',
            email:'Your Email*',
            mobile:'Your Mobile Number*',
            submitAlert:'Your Information is 100% secure and will never be shared with anyone.',
            segementId:'65253c69ea30212199e900cc',
            parentId:'64bf98e8eb0e7f5016a14779',
            showYouTubeLink:'https://www.youtube.com/watch?v=qY7ax6ai4iU&feature=youtu.be',
            heroSign:'./assets/img/other/ravi-kapoor-signature.webp',
            action:'fmp_enrolled',
            type:'goal',
            heroSignClass:'sign-img',
            formBorderPresent:true,
            subject:['Polity','Economy','Time Management','Essay Ethics'],
            subjectTabEnable:true,
            subjectHeading:'Which subject would you like to begin with?*'
        }
    },

    'judiciary-mentorship': {
        dlPage:'mGoal-judy',
        pageType:"mGoal-judy",
        heroImage:'https://storage.googleapis.com/testbook_web_promos/mgoalassets/1.png',
        heroImageClass:'hero',
        modalHeading: 'Thanks for filling your details',
        modalSubtext: 'You will be getting your first email soon!',
        mgoalContentMobileView:true,
        content:{
            heading:'ENROLLMENTS ARE OPEN',
            subtitle:'FREE JUDICIARY MENTORSHIP PROGRAM by Shubham Upadhyay',
            instructionTitle:'WHAT YOU NEED TO KNOW ABOUT THE FMP-',
            InstructionsPoints:[
                'If admitted, you will get a confirmation email along with details of the program.',
                'Only serious Judiciary aspirants are encouraged to apply.',
                'Admitted students will receive lessons, mains answer writing guidance, feedback, assignments, free resources and exclusive insights with opportunities for live interaction.'
            ],
            noticeTitle:'Important Notice:',
            noticeDescription:'This program comes at no cost, but it demands sincere dedication. I have invested significant effort to maintain high-quality standards in this mentorship, and I expect admitted students to reciprocate with equal or greater dedication. Success is a two-way street; be sincere, hardworking, and disciplined, and this program is designed to empower you beyond your expectations.',
        },
        form:{
            name:'Your Name*',
            email:'Your Email*',
            mobile:'Your Mobile Number*',
            submitAlert:'Your Information is 100% secure and will never be shared with anyone.',
            segementId:'6565ba9af3fe421ca375d7c8',
            parentId:'64bf98e8eb0e7f5016a14779',
            heroSign:'https://storage.googleapis.com/testbook_web_promos/mgoalassets/sign_fit2.png',
            action:'fmp_enrolled',
            type:'goal',
            heroSignClass:'sign-img',
            formBorderPresent:true,
            subjectTabEnable:false
        }
    },
    'business-analyst':{
        dlPage:'mGoal-skill-businessanalyst',
        pageType:"mGoal-skill-businessanalyst",
        heroImageClass:'course',
        heroImage:'https://cdn.testbook.com/mgoal-marketing/business-analyst.jpg',
        modalHeading: 'Thanks for filling your details',
        modalSubtext: 'You will receive confirmation along with the details of the program on your Whatsapp.',
        mgoalContentMobileView:false,
        content:{
            heading:'Business Analyst Career Program',
            subtitle:'Get <strong>100% Placement Support</strong> with Skill Academy',
        },
        form:{
            name:'Your Name*',
            email:'Your Email*',
            mobile:'Your Mobile Number(Whatsapp)*',
            submitAlert:'Your Information is 100% secure and will never be shared with anyone.',
            segementId:'65ae60f7f00b2c980eeb2691',
            productId:'65ae60f7f00b2c980eeb2691',
            heroSign:'https://cdn.testbook.com/mgoal-marketing/business-analyst-banner.jpg',
            action:'skill_enrolled',
            type:'SkillAcademy',
            productType:'career_program',
            formBorderPresent:false,
            subjectTabEnable:false,
        }
    },
    'full-stack-development':{
        dlPage:'mGoal-skill-fullstackdevelopment',
        pageType:"mGoal-skill-fullstackdevelopment",
        heroImage:'https://cdn.testbook.com/mgoal-marketing/full-stack-developer.jpg',
        heroImageClass:'course',
        modalHeading: 'Thanks for filling your details',
        modalSubtext: 'You will receive confirmation along with the details of the program on your Whatsapp.',
        mgoalContentMobileView:false,
        content:{
            heading:'Full Stack Development Career Program',
            subtitle:'Get <strong>100% Placement Support</strong> with Skill Academy',
        },
        form:{
            name:'Your Name*',
            email:'Your Email*',
            mobile:'Your Mobile Number(Whatsapp)*',
            submitAlert:'Your Information is 100% secure and will never be shared with anyone.',
            segementId:'65aa651f2c24f0906347ff25',
            productId:'65aa651f2c24f0906347ff25',
            heroSign:'https://cdn.testbook.com/mgoal-marketing/full-stack-developer-banner.jpg',
            action:'skill_enrolled',
            type:'SkillAcademy',
            productType:'career_program',
            formBorderPresent:false,
            subjectTabEnable:false
        }
    },
    'digital-marketing':{
        dlPage:'mgoal_skill-digitalmarketing',
        pageType:"mgoal_skill-digitalmarketing",
        heroImage:'https://cdn.testbook.com/mgoal-marketing/digital-marketing.jpg',
        heroImageClass:'course',
        modalHeading: 'Thanks for filling your details',
        modalSubtext: 'You will receive confirmation along with the details of the program on your Whatsapp.',
        mgoalContentMobileView:false,
        content:{
            heading:'Digital Marketing Career Program',
            subtitle:'Get <strong>100% Placement Support</strong> with Skill Academy',
        },
        form:{
            name:'Your Name*',
            email:'Your Email*',
            mobile:'Your Mobile Number(Whatsapp)*',
            submitAlert:'Your Information is 100% secure and will never be shared with anyone.',
            segementId:'647f505199750b8c4cf778b7',
            heroSign:'https://cdn.testbook.com/mgoal-marketing/digital-marketing-banner.jpg',
            productId:'647f505199750b8c4cf778b7',
            action:'skill_enrolled',
            type:'SkillAcademy',
            productType:'career_program',
            formBorderPresent:false,
            subjectTabEnable:false
        }
    }
}