import { Component, OnInit ,Output,EventEmitter} from '@angular/core';
import {selectKindOffer} from '@angular-commons/core/application-state/app.selectors'
import {Store} from "@ngrx/store";
import { hideKindOfferModal } from '@angular-commons/core/application-state/app.actions';
@Component({
  selector: 'kind-offer',
  templateUrl: './kind-offer.component.html',
  styleUrls: ['./kind-offer.component.scss']
})
export class KindOfferComponent implements OnInit {

  kindOffer$ = this.store.select(selectKindOffer);
  showKindOfferModal:boolean=false;
  kindOfferPass:any={}
  kindOfferDetails:any={}
  @Output() buyKindOfferPass=new EventEmitter<any>();
  constructor(private store:Store<{}>) { }

  ngOnInit(): void {
    this.kindOffer$.subscribe((data)=>{
      if(data){
        this.showKindOfferModal=data.show;
        if(data.pass && data.pass.offers){
          this.kindOfferPass=data.pass
          this.kindOfferDetails=data.pass.offers.luckyDeal
        }
      }
      
    })
  }

  buyPass(){
    this.buyKindOfferPass.emit(this.kindOfferPass);
  }

  closeKindOfferModal(){
    this.store.dispatch(hideKindOfferModal());
  }

}
