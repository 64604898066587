import { Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";

import {clientBuildPath, isServer} from "@shared/utility/tb-common";
import {HttpClient} from "@angular/common/http";
import {makeStateKey, StateKey, TransferState} from "@angular/platform-browser";

const pathToAssetsServer = '/src/assets';
const pathToAssetsClient = '/angular-ssr/src/assets';
const STATE_TRANSFER_PREFIX = 'translate';

function getKey(module,lang){
    return makeStateKey<number>(`${STATE_TRANSFER_PREFIX}-${module}-${lang}`);
}
declare var global;
declare var require;
export class TranslateServerLoader implements TranslateLoader {
    fs:any;
    constructor( private transferState: TransferState,private module: string = '' , private prefix: string = '' ,  private suffix: string = '.json') {
        this.fs = require('fs');
    }

    public getTranslation(lang: string): Observable<any> {
        return new Observable((observer) => {
            let filePath = `${this.prefix}/${lang}${this.suffix}`;
            let jsonData = {};
            // load and cache the language file in a global variable on server to reduce IO across requests
            if(global && global['langCache'] && global['langCache'][filePath]){
                jsonData = global['langCache'][filePath];
            } else {
                jsonData = JSON.parse(this.fs.readFileSync(`${this.prefix}/${lang}${this.suffix}`, 'utf8'));
                global && global['langCache'] && (global['langCache'][filePath] = jsonData);
            }
            const key: StateKey<number> = getKey(this.module,lang);
            this.transferState.set(key, jsonData);
            observer.next(jsonData);
            observer.complete();
        });
    }
}

export class customTranslateLoader implements TranslateLoader {
    internalLoader: TranslateLoader;
    constructor(private http: HttpClient, private transferState: TransferState,private module: string) {
        if(isServer()){
            this.internalLoader = new TranslateServerLoader(transferState,module,`${clientBuildPath()}${pathToAssetsServer}/lang/${module}`,'.json');
        } else {
            this.internalLoader = new TranslateHttpLoader(http,`${pathToAssetsClient}/lang/${module}/`,'.json');
        }
    }
    public getTranslation(lang : string): Observable<any> {
        if(!isServer()){ // use state transferred language on client if available
            const key: StateKey<number> = getKey(this.module,lang);
            const data = this.transferState.get(key, null);
            if(data){
                return new Observable<any>( (observer) =>{
                    observer.next(data);
                    observer.complete();
                })
            }
        }

        return this.internalLoader.getTranslation(lang);
    }
}

export function langChangeListener(translateService,tbcore, platformService){
    tbcore.currentLang$.subscribe((lang)=>{
        let currentLang = lang || platformService.getSiteLang();
        translateService.currentLang = '';
        translateService.use(currentLang);
    })
}
