import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportModalComponent } from './components/report-modal/report-modal.component';
import { LoginSignupComponent } from './components/login-signup/login-signup.component';
import { PaymentComponent } from './components/payment/payment.component';
import {tbCoupon} from "@wrappers/tb-coupon/tb-coupon";
import {FormsModule} from "@angular/forms";
import {ClickStopPropagationDirective} from "@core/components/click-stop-propogation/click-stop-propogation.directive";
import {EmptyState} from "@core/components/empty-state/empty-state";
import {CustomSelectComponent} from "@shared/components/custom-select/custom-select.component";
import {LazyLoadImageModule} from "ng-lazyload-image";
import { IvyCarouselModule } from '@angular-commons/feature-modules/carousel/carousel.module';
import {TimediffPipe} from "@core/pipes/timediff.pipe";
import {TranslateModule} from "@ngx-translate/core";
import { CouponCodeModule } from '@features/coupon-code/coupon-code.module';
import {SafeHtmlPipe} from "@core/pipes/safehtml.pipe";
import {SafeResourcePipe} from "@core/pipes/safeResource.pipe";
import {TooltipDirective} from "@core/components/tooltip/tooltip.directive";
import {ViewMoreComponent} from "@core/components/view-more/view-more.component";
import { PopularExamsComponent } from '../shared/components/popular-exams/popular-exams.component';
import {TargetListComponent} from "@shared/components/target-list/target-list.component";
import {RouterModule} from "@angular/router";
import {SearchBarModule} from "@features/search-bar/search-bar.module";
import { AlertToastComponent } from './components/alert-toast/alert-toast.component';
import {PaymentReceiptComponent} from "@shared/components/payment-receipt/payment-receipt.component";
import {OpenInAppDialogComponent} from "@features/open-in-app-dialog/open-in-app-dialog.component";
import { GoalDropdownComponent } from './components/goal-dropdown/goal-dropdown.component';
import {ScrollableTabsComponent} from "@core/components/scrollable-tabs/scrollable-tabs.component";
import { ElementEvents } from '@angular-commons/shared/utility/carousel/elementEvents.directive';
import {DecodehtmlPipe} from "@core/pipes/decodehtml.pipe";

@NgModule({
    declarations: [
        ReportModalComponent,
        LoginSignupComponent,
        PaymentComponent,
        tbCoupon,
        ClickStopPropagationDirective,
        EmptyState,
        CustomSelectComponent,
        PopularExamsComponent,
        TargetListComponent,
        CustomSelectComponent,
        TimediffPipe,
        SafeHtmlPipe,
        SafeResourcePipe,
        DecodehtmlPipe,
        TooltipDirective,
        ViewMoreComponent,
        AlertToastComponent,
        PaymentReceiptComponent,
        OpenInAppDialogComponent,
        GoalDropdownComponent,
        ScrollableTabsComponent,
        ElementEvents
    ],
  imports: [
      CommonModule,
      FormsModule,
      LazyLoadImageModule,
      TranslateModule,
      IvyCarouselModule,
      RouterModule,
      SearchBarModule,
  ],
    exports: [
        ReportModalComponent,
        LoginSignupComponent,
        PaymentComponent,
        tbCoupon,
        ClickStopPropagationDirective,
        CustomSelectComponent,
        EmptyState,
        LazyLoadImageModule,
        CouponCodeModule,
        TranslateModule,
        TimediffPipe,
        SafeHtmlPipe,
        SafeResourcePipe,
        DecodehtmlPipe,
        TooltipDirective,
        ViewMoreComponent,
        AlertToastComponent,
        PopularExamsComponent,
        TargetListComponent,
        SearchBarModule,
        PaymentReceiptComponent,
        OpenInAppDialogComponent,
        GoalDropdownComponent,
        ScrollableTabsComponent,
        ElementEvents
    ],
  providers:[]
})
export class CoreModule { }
