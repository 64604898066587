import { Component, OnInit,Input,SimpleChanges, SimpleChange } from '@angular/core';

@Component({
  selector: 'progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss']
})
export class ProgressIndicatorComponent implements OnInit {

  @Input() stageNameArray:any=[];
  @Input() formStage:number;
  @Input() stageCount:number=0;
  @Input() bgPath:string=""
  @Input() title:string="";
  @Input() cssModifierClass?: string="";
  stageCountArr:any=[]
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes && changes.stageCount && changes.stageCount.currentValue ){
      this.stageCountArr=new Array(parseInt(changes.stageCount.currentValue))
    }
  }
}
