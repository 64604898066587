<ng-container *ngIf="projectName === 'testbook'">
  <div class="header-wrapper" *ngIf="showHeader && !isLoggedIn">
    <app-header-logged-out></app-header-logged-out>
  </div>
  <div *ngIf="showHeader && isLoggedIn">
    <ng-container *ngIf="!isFromIOS">
      <app-header-logged-in></app-header-logged-in>
    </ng-container>
    <ng-container *ngIf="isFromIOS">
      <div class="back-cta">
        <img height="45" width="45" src="/assets/img/arrows/chevron-left.svg" (click)="backBtn()">
      </div>
    </ng-container>
  </div>
</ng-container>

<div *ngIf="projectName === 'plutus' && showHeader">
  <plutus-header></plutus-header>
</div>


<ng-container *ngIf="activationCode && isLoggedIn && isMobile && !isEcardsFlowPage">
  <div style="
    z-index:10029;
    position: fixed;
    bottom:60px;
    width: 100vw;
    background-color: #FFF;
    text-align: center;
    padding: 25px 5px 25px 5px;
    font-weight: 700;">
    Unlock your purchase by using this activation code: {{activationCode}} <a class="btn btn--cta"
      [href]="'/e-cards?code='+activationCode"> or click here</a>
  </div>
</ng-container>