import {Injectable} from "@angular/core";
import {DataRecipe} from "@core/services/model-manager/recipe";
import {IdentityAdapter} from "@models/mapping-framework";
import {
    _CouponProductsArray,
    CouponProductsApi,
    CouponsArray,
    StudentCouponsApi
} from "@models/entity/coupon/coupon.adapter";
import {ModelManager} from "@core/services/model-manager.service";
import {BehaviorSubject} from "rxjs";
import {showAlert} from "@core/application-state/app.actions";
import {Store} from "@ngrx/store";
import {PlatformService} from "@core/services/platform-service";
import {take} from "rxjs/operators";
import {makeStateKey, TransferState} from "@angular/platform-browser";
import {isServer} from "@shared/utility/tb-common";
import { PROJECT_PLUTUS } from "@angular-commons/shared/utility/constants";


@Injectable({
    providedIn: 'root'
})
export class CouponCodeService{
    private couponData: any = {coupons: {}, productIds: {}};
    private couponsObservable = new BehaviorSubject({});
    private appliedCouponSubject = new BehaviorSubject({});
    private couponCode;
    private currCouponObj: any = {};
    private couponStateDirty = false;
    private studentDataRecipe: DataRecipe = this.modelManager.makeDataRecipe(IdentityAdapter, CouponsArray, StudentCouponsApi);
    private couponProductRecipe: DataRecipe = this.modelManager.makeDataRecipe(IdentityAdapter, _CouponProductsArray, CouponProductsApi);
    private shouldReadFromTransferState = true;
    constructor(private modelManager: ModelManager, private store: Store, private platformService: PlatformService, private transferState: TransferState) {
    }
    fetchCoupons(pIds, productType){
        if(pIds?.length){
            const res = this.studentDataRecipe.get({prodIds: pIds})
            res.subscribe((data: any) => {
                if(data?.coupons){
                    this.couponData = {...this.couponData,
                        coupons: {...this.couponData.coupons, [productType]: data?.coupons},
                        productIds: {...this.couponData.productIds, [productType]: pIds}
                    };
                    if(isServer() && !this.platformService.isLoggedIn()){
                        let key = makeStateKey(`couponData_${productType}`);
                        this.transferState.set(key, this.couponData);
                        let hasCouponkey = makeStateKey(`hasCoupon_${productType}`);
                        const hasCoupon: any = true;
                        this.transferState.set(hasCouponkey, hasCoupon);
                    }
                    this.couponsObservable.next(this.couponData);
                } else if(isServer()) {
                    let key = makeStateKey(`hasCoupon_${productType}`);
                    const hasCoupon: any = false;
                    this.transferState.set(key, hasCoupon);
                }
            });
        }
    }
    get coupons(){
        return this.couponsObservable;
    }

    applyCoupon(coupon){
        if(coupon) {
            return new Promise((resolve) => {
                this.couponProductRecipe.get({coupon}).pipe(take(1)).subscribe({
                    next: (data: any) => {
                        if(data?.coupon?.code){
                            this.couponCode = data?.coupon?.code;
                            resolve(data);
                        }
                    },
                    error: (error) =>{
                        if(error?.error?.message){
                            this.couponCode = error?.coupon?.code;
                            resolve(error);
                        }
                    }});
            });
        }
    }
    checkCouponForProduct(productIds, coupon, productType) {
        if(!(productIds && Object.keys(productIds).length > 0) || !coupon) {
            let isPlutus = this.platformService.currentProjectName() === PROJECT_PLUTUS;
            let alertMsg = `Please check your internet connection or contact at support@${isPlutus ? 'plutus' : 'testbook'}.com`;
            this.store.dispatch(showAlert({message: alertMsg}));
            return;
        }
        if(!this.couponData.productIds?.[productType]?.length) {
            return;
        }

        this.couponStateDirty = true;
        this.shouldReadFromTransferState = false;
        if(this.isCouponValidForProduct(productIds, this.couponData.productIds?.[productType])) {
            this.currCouponObj = { ...this.currCouponObj,
                [productType]: {
                    code: this.couponCode,
                    coupon: {...coupon},
                    products: {...productIds},
                    state: 'couponApplied'
                }
            };
            if(isServer() && !this.platformService.isLoggedIn()){
                let key = makeStateKey(`couponAppliedState_${productType}`);
                this.transferState.set(key, this.currCouponObj);
            }
            this.appliedCouponSubject.next(this.currCouponObj);
        } else {
            this.store.dispatch(showAlert({message: 'Coupon not Applicable for this Product'}));
            let trigger = this.platformService.getQueryParamValue("trigger");
            if(trigger === 'quickPay'){
                this.currCouponObj[productType] = {
                    code: '',
                    state: 'default'
                };
                this.appliedCouponSubject.next(this.currCouponObj);
            }
        }
    }
    isCouponValidForProduct(couponProductIds, productIds){
        if(!productIds?.length) {
            return false;
        }
        
        let isValid = false;
        for (let prodId of productIds){
            if(couponProductIds[prodId]){
                isValid = true;
                break;
            }
        }
        return isValid;
    }
    get updatedCouponState(){
        return this.appliedCouponSubject;
    }

    removeCoupon(productType){
        this.currCouponObj[productType] = {
            code: '',
            state: 'default'
        };
        this.couponStateDirty = true;
        this.shouldReadFromTransferState = false;
        this.appliedCouponSubject.next(this.currCouponObj);
    }

    getCurrAppliedCoupon(productType){
        if(this.shouldReadFromTransferState){
            let key = makeStateKey(`couponAppliedState_${productType}`);
            return this.transferState.get(key, null)?.[productType];
        }
        return this.currCouponObj[productType];
    }

    getCouponStateDirty(){
        return this.couponStateDirty;
    }
}
