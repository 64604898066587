import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { Store } from "@ngrx/store";
import { PlatformService } from "@core/services/platform-service";
import { AuthenticationService } from "@core/services/authentication.service";
import { TransferStateManager } from "@core/services/transferStateManager";
import { ProductAccessService } from "@core/services/product-access.service";
import { getGoalBySlug } from "@core/application-state/app.actions";
import { selectGoalMap } from "@core/application-state/app.selectors";

@Injectable({
  providedIn: 'root'
})
export class TermsPageGuard implements CanActivate {
  transferStateValue;
  showGeneric:boolean;
  constructor(
    private router: Router,
    private store: Store<{}>,
    private platformService: PlatformService,
    private auth: AuthenticationService,
    private transferStateManager: TransferStateManager
  ) {
  }
  canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let goalSlug = route.params.goalSlug;
    const sub = new ReplaySubject<boolean>();
    if (goalSlug) {
      this.transferStateValue = this.transferStateManager.get(`currentGoalDetails${goalSlug}`,
          getGoalBySlug({ slug: goalSlug }), this.store.select(selectGoalMap), (goal) => {
            if (!(goal[goalSlug]?.id)) {
              this.platformService.gotoErrorPage();
              sub.next(false);
              sub.complete();
            }
            else {
              if (goal[goalSlug]?.properties?.genericComponent) {
                this.checkGenericData(goal[goalSlug]?.properties?.genericComponent);
              }
              if (!this.showGeneric) {
                this.platformService.gotoErrorPage();
                sub.next(false);
                sub.complete();
              }
              else {
                sub.next(true);
                sub.complete();
              }
            }
            if (this.transferStateValue) {
              this.transferStateValue.unsubscribe();
            }
          }
      );
      return sub;
    }
    return false
  }

  // checking generic data
  checkGenericData(param){
    param.forEach((dataArr)=>{
      if(!dataArr.isHidden){
        dataArr.content.forEach((obj)=>{
          this.showGeneric = !!(obj.type == 'tnc' && obj.value);
        })
      }
    })
  }
}
