import {getFingerPrintId, hideLivePanel, showLivePanel} from "@shared/utility/tb-common";

declare var window:any;

export const TRANSACTION_MODES = {
    PAYPAL: {
        pg: 4,
        name: 'Paypal'
    },
    PAYTM: {
        pg: 2,
        name: 'Paytm'
    }
};

export const PAYMENT_CONST_OBJ = {
    enrollUrl: 'v2/students/me/enroll',
    initiatePaymentUrl: 'v2/payment/log',
    razorPayCheckoutUrl: 'v2/payment/razorpay',
    createEmi : 'v2/emi'
};

export const PAYMENT_STAGES = {
    NOT_STARTED : 'NOT_STARTED',
    STARTED : 'STARTED',
    WAITING : 'WAITING',
    FINISHED : 'FINISHED',
    FAILED : 'FAILED',
}

export interface Enrollable {
    classId?     : string
}

export interface  PaymentParams {
    products        : string,
    coupon          : string,
    paymentGateway  : number,   //use pg when sending to BE
    fingerPrint     ?: string,
    enrollIn        ?: Enrollable,
    plan            ?: string,
    appVersion      ?: string
    isLoggedOutTrans?: boolean,
    name            ?:string,
    passIndex       ?:number,
    mobile          ?:string,
    oldCost         ?:number,
    isEmiFlow       ?:boolean,
    paymentsEmi     ?:string
}

export interface  APIParams {
    products        : string,
    coupon          : string,
    pg              : number,
    os              : string,
    browserFpId?    : string,
    ismobile        : boolean,
    trans_client    : string,
    itemType?       : string,
    itemId?         : string,
    plan            ?: string,
    fingerPrint     ?: string,
    appVersion      ?: string
    isLoggedOutTrans?: boolean,
    name            ?:string,
    mobile          ?:string,
    isEmiFlow       ?: boolean,
    paymentsEmi     ?:string
}

export interface EmiPaymentParams extends PaymentParams {
    emiPlanId        : string,
    productId        : string,
    coupon           : string,
    sId              : string,
    mode            ?: string,
    offerId         ?: string,
    interval        ?: string,
    source          ?: string,
    productName     ?: string
}

export interface VendorPaymentParams {
    distributorId: string,
    vendorId: string,
    mobile: string,
    event: string,
    eventAccepted?: boolean,
    amount: number
}

export function createEmiAPIParams(params:EmiPaymentParams){

    let emiParams = {
        emiPlanId: params.emiPlanId || '',
        sId: params.sId || '',
        interval: params.interval || 'monthly',
        mode: params.mode || 'enach',
        productId: params.productId || '',
        createdFrom:{
            source: params.source || 'online'
        }
    };

    if(params.productName){
        emiParams['productName'] = params.productName;
    }

    if(params.coupon){
        emiParams['coupon'] = {
            code: params.coupon
        }
    }

    if(params.offerId){
        emiParams['offer'] = {
            _id: params.offerId
        }
    }

    return emiParams;
}

function isMobile() : boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function createAPIParams(paymentParams:PaymentParams) : APIParams{
    let apiParams       : APIParams = {
        products        : paymentParams.products,
        coupon          : paymentParams.coupon || "",
        pg              : paymentParams.paymentGateway || 0,
        os              : navigator && navigator.userAgent,
        ismobile        : isMobile(),
        trans_client    : isMobile() ? 'mobile' : 'desktop',
        plan            : paymentParams.plan || '',
        fingerPrint     : paymentParams.fingerPrint || '',
        appVersion      : paymentParams.appVersion || '',
        isLoggedOutTrans : paymentParams.isLoggedOutTrans || false,
        name : paymentParams.name || "",
        mobile : paymentParams.mobile || "",
        isEmiFlow : paymentParams.isEmiFlow || false
    };
    if(paymentParams.paymentsEmi && paymentParams.isEmiFlow){
        apiParams.paymentsEmi = paymentParams.paymentsEmi;
    }
    let browserFingerPrintId = getFingerPrintId();
    if(browserFingerPrintId){
        apiParams.browserFpId = browserFingerPrintId;
    }
    if (window.paymentMeta) {
        apiParams.itemType = window.paymentMeta.itemType;
        apiParams.itemId = window.paymentMeta.itemId;
    }
    return apiParams;
}

export function getClassObject(classObj){
    let _classObj = {
        isClassCase     : true,
        classUrl        : classObj.classUrl,
        name            : classObj.name,
        classFrom       : classObj.classFrom,
        classTill       : classObj.classTill,
        stopEvents      : classObj.stopEvents,
    };
    return _classObj;
}

export function getDataLayerObject(value) {
    let DL_OBJ : any = {
        pid : value.pid || '',
        validity : value.duration || 0,
        cost : (value.data && value.data.razorpayClientParams && value.data.razorpayClientParams.amount || 0) / 100,     // because amount is in paisa
        subTotal : value.subTotal,
        name : value.data && value.data.razorpayClientParams && value.data.razorpayClientParams.description || '',
        coupon : value.coupon,
        stopEvents : value.stopEvents,
        module : value.module,
        passType : value.passType || '',
        transactionId : value.data.transactionId,
        origin_url : window.location.href,
        discount: value?.discount || 0,
        passIndex: value?.passIndex || 0
    };
    if (value.hasOwnProperty('productType')) {
        DL_OBJ.productType = value.productType;
    }
    if (value.hasOwnProperty('type')) {
        DL_OBJ.type = value.type || value.passType;
    }
    if (value.hasOwnProperty('quantity')) {
        DL_OBJ.quantity = Number(value.quantity);
    }

    return DL_OBJ;
}