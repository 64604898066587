import {
    arrayModel, pipeModel,objModel,
    SourceProjectionMapper
} from '../../mapping-framework';
import {baseModel, ENTITY_CLASS_ENTITY} from '../../types';
import { setupModel } from '../../types';
import { propertyMaps } from '../../mapping-framework';
import { Network } from '../../../core/services/model-manager/main';

import {Validator as _} from "../../../shared/utility/validator";
import { getDateFormat, getOnlyDateFormat, getTimeDifference,toShortFormat,createTimeText} from "../../../shared/utility/date-utilities";
import {addDays} from "../../../shared/utility/tb-common";
import { padZero } from '@angular-commons/shared/utility/math-utilities';
import { DEFAULT_DATE,isValidDate } from '@shared/utility/date-utilities';
import {CombineLanguage} from '@shared/utility/test-utilities';
import { Slug } from './classes.adapter';

const NOT_AVAILABLE = 0;
const AVAILABLE = 1;
const CROSSED_DEADLINE_BUT_AVAILABLE = 2;
const ALL_DEADLINES_CROSSED = 3;
export class _entity extends baseModel {

    @propertyMaps()
    public id;

    @propertyMaps()
    public title;

    @propertyMaps()
    public route;

    @propertyMaps()
    public altRoute;

    @propertyMaps()
    public isVideo;

    @propertyMaps()
    public isQuiz;

    @propertyMaps()
    public isPractice;

    @propertyMaps()
    public isCode;

    @propertyMaps()
    public isLesson;

    @propertyMaps()
    public isAssignment;

    @propertyMaps()
    public modules;

    @propertyMaps()
    public lessonCardText;

    @propertyMaps()
    public isComingSoon;

    @propertyMaps('isRegistered', _.bool)
    public isRegistered;

    @propertyMaps()
    public durationString;

    @propertyMaps()
    public reschedule;

    @propertyMaps()
    public durationInMins;

    @propertyMaps()
    public hasAccess;

    @propertyMaps()
    public isDemo;

    @propertyMaps()
    public isTest;

    @propertyMaps()
    public isNotes;

    @propertyMaps()
    public questionCount;

    @propertyMaps()
    public order;

    @propertyMaps()
    public totalTime;

    @propertyMaps()
    public maxMarks;

    @propertyMaps()
    public info;

    @propertyMaps()
    public isLive;

    @propertyMaps()
    public isLiveNow;

    @propertyMaps()
    public isLiveOver;

    @propertyMaps()
    public isLiveCurrently;

    @propertyMaps()
    public isRecordingAvailable;

    @propertyMaps()
    public singleStreamEnabled;

    @propertyMaps()
    public streamStatus;

    @propertyMaps()
    public widgetId

    @propertyMaps()
    public futureEntity;

    @propertyMaps()
    public availFrom;

    @propertyMaps()
    public availTill;

    @propertyMaps()
    public availFromDateString;

    @propertyMaps()
    public availFromDate;

    @propertyMaps()
    public liveInfo;

    @propertyMaps()
    public startTime;
    
    @propertyMaps()
    public endTime;

    @propertyMaps()
    public startTimeText;

    @propertyMaps()
    public deadlineText;

    @propertyMaps()
    public deadlineWithThreshold;

    @propertyMaps()
    public deadlineWithThresholdText;

    @propertyMaps()
    public assignmentState;

    @propertyMaps()
    public isNotAvailable;

    @propertyMaps()
    public isAvailable;

    @propertyMaps()
    public isDeadlineCrossed;

    @propertyMaps()
    public isDeadlineMissed;

    @propertyMaps()
    public rescheduleText;

    @propertyMaps()
    public isRescheduleVisible;

    @propertyMaps()
    public isRescheduled;

    @propertyMaps()
    public isCancelled;

    @propertyMaps()
    public rescheduleAccordionText;

    @propertyMaps()
    public type;

    @propertyMaps()
    public oldStartTime;

    @propertyMaps()
    public instructors;

    @propertyMaps()
    public subjects;

    @propertyMaps()
    public instructorsText;

    @propertyMaps()
    public startText;

    @propertyMaps()
    public thumbnail;
    
    @propertyMaps()
    public thumbnailSmall;

    @propertyMaps()
    public isPreLaunch;

    @propertyMaps()
    public isExternal;

    @propertyMaps()
    public timeToShow;

    @propertyMaps()
    public startDateText

    @propertyMaps()
    public startTimeOnlyText

    @propertyMaps()
    public languages

    @propertyMaps()
    public instructorDetails

    @propertyMaps('isMajor', _.bool)
    public isMajor;

    @propertyMaps('totalMarks',_.num)
    public totalMarks;
}
setupModel(_entity,{});



class _sections extends baseModel{

    @propertyMaps()
    public id;

    @propertyMaps()
    public name;

    @propertyMaps()
    public subject;


    @propertyMaps()
    public moduleCount;


    @propertyMaps('entities',arrayModel(_entity),_entity)
    public entities;
}
setupModel(_sections,{});


export class _subjects extends baseModel{
    @propertyMaps()
    public id;

    @propertyMaps()
    public name;
}
setupModel(_subjects,{});

export class _instructors extends baseModel{
    @propertyMaps()
    public id;

    @propertyMaps()
    public name;

    @propertyMaps()
    public image;

    @propertyMaps()
    public shortBio;

    @propertyMaps()
    public companyLogo;

}
setupModel(_instructors,{});

export class _ClassModules extends baseModel {
    @propertyMaps()
    public id;

    @propertyMaps()
    public title;

    @propertyMaps('entities', arrayModel(_entity), _entity)
    public entities;
}
setupModel(_ClassModules, {});

export class _DateWiseSchedule extends baseModel {
    @propertyMaps('classes', arrayModel(_ClassModules), _ClassModules)
    public classes;
}
setupModel(_DateWiseSchedule, {});

export class _schedule extends baseModel{

    @propertyMaps('sections',arrayModel(_sections),_sections)
    public sections;

    @propertyMaps('subjects',arrayModel(_subjects),_subjects)
    public subjects;

    @propertyMaps()
    public currentSection;
}
setupModel(_schedule,{});

function reduceToModuleCount(item) {
    let count = 0;
    if(item && item.moduleCount){
        for(let module in item.moduleCount){
            count+=_.num(item.moduleCount[module]);
        }
    }
    return count;
}

export class entity extends baseModel {

    @propertyMaps('isDemoClass',_.bool)
    public isDemo;

    @propertyMaps('hasAccess',_.bool)
    public hasAccess;

    @propertyMaps(['id','_id'])
    public id;

    @propertyMaps('preLaunch',_.bool)
    public isPreLaunch;

    @propertyMaps(['_id','id'])
    public _id;

    @propertyMaps('name',_.str)
    public title;

    @propertyMaps('isLiveCurrently',_.bool)
    public isLiveCurrently;

    @propertyMaps('widgetId',_.bool)
    public widgetId;
    
    @propertyMaps('isRecordingAvailable',_.bool)
    public isRecordingAvailable;

    @propertyMaps('singleStreamEnabled',_.bool)
    public singleStreamEnabled;

    @propertyMaps('streamStatus',_.str)
    public streamStatus;  

    @propertyMaps('entityName',_.str)
    public entityTitle;

    @propertyMaps('description',_.str)
    public description;

    @propertyMaps('type',_.str)
    public type;

    @propertyMaps('url',_.str)
    public url;

    @propertyMaps('rscInfo',_.obj)
    public reschedule;

    @propertyMaps('isExternal',_.bool)
    public isExternal;

    @propertyMaps('availableTill',_.date)
    public availTill;

    @propertyMaps('availableFrom',_.date)
    public availFrom;

    @propertyMaps('startTime',_.date)
    public startTime;

    @propertyMaps('endTime',_.date)
    public endTime;

    @propertyMaps('duration',_.num)
    public duration;

    @propertyMaps('maxM',_.num)
    public maxMarks;

    @propertyMaps('stage',_.str)
    public stage;

    @propertyMaps('qCount',_.num)
    public qCount;

    @propertyMaps('qsAdded',_.num)
    public qsAdded;

    @propertyMaps('order',_.num)
    public order;

    @propertyMaps('totalT',_.num)
    public totalTime;

    @propertyMaps('thumbnail',_.str)
    public thumbnail;

    @propertyMaps('thumbnailSmall',_.str)
    public thumbnailSmall;

    @propertyMaps('oldStartTime',_.date)
    public oldStartTime;
    
    @propertyMaps('subjects',arrayModel(_subjects),_subjects)
    public subjects;

    @propertyMaps('instructors',arrayModel(_instructors),_instructors)
    public instructors;

    @propertyMaps('instructors', mapInstructorsArr)
    public instructorsText; 

    @propertyMaps('modules', _.arr)
    public modules;

    @propertyMaps('lessonCardText', _.str)
    public lessonCardText;

    @propertyMaps('languages')
    public languages

    @propertyMaps('instructorDetails')
    public instructorDetails

    @propertyMaps('isMajor',_.bool)
    public isMajor;

    @propertyMaps('extraDays',_.num)
    public extraDays;

    @propertyMaps('deadline',_.date)
    public deadline;

    @propertyMaps('evaluationDays',_.num)
    public evaluationDays;

    @propertyMaps('totalMarks',_.num)
    public totalMarks;
}
setupModel(entity,{type:ENTITY_CLASS_ENTITY});

export function entityToUrl(entity,_projection?){
    if(_projection){
        return 1;
    }
    let typ = 'entity';
    if(entity.type === 'Video' || entity.type === 'Live Class' || entity.type === 'Doubt Class'){
        typ = 'video'
    } else if(entity.type === 'Notes') {
        typ = 'notes'
    } else if(entity.type === 'Lesson') {
        typ = 'lesson'
    } else if(entity.type === 'CodingProblem') {
        typ = 'code'
    }
    else if(entity.type === 'Assignment') {
        typ = 'assignment'
    }
    else {
        typ = 'test'
    }
    return [typ,entity.id || entity._id];

}

function mapInstructorsArr (instructors = []){
    return instructors.map(function(val) {
            return val && val.name;
        }).join(',')
}

export function transform(_item, _projectionMode){
    const currentTime:any = Date.now();
    let it:any = {};
    let item = {..._item}
    it.id = item.id || item._id;
    item.questionCount = item.qCount;
    it.hasAccess = item.hasAccess || item.isDemo;
    it.isDemo = item.isDemo;
    it.thumbnail = item.thumbnail || '';
    it.thumbnailSmall = item.thumbnailSmall || '';
    it.type = item.type;
    it.note = item.note;
    let durationInSecs = Math.floor(item.duration/1000000000);
    let minutes = Math.floor(durationInSecs / 60);
    let seconds = durationInSecs%60;
    it.durationString = `${padZero(minutes)}:${padZero(seconds)}`;
    it.durationInMins = padZero(minutes);
    it.totalTime = item.totalTime;
    it.maxMarks = item.maxMarks;
    it.isVideo = (item.type === 'Video' || item.type === 'Live Class' || item.type === 'Doubt Class');
    it.isVideoType = item.type === 'Video';
    it.isLive = item.type === 'Live Class' || item.type === 'Doubt Class';
    it.isQuiz = item.type === 'Quiz';
    it.isPractice = item.type === 'Practice';
    it.isCode = item.type ==='CodingProblem';
    it.isTest = item.type === 'Test';
    it.isNotes = item.type === 'Notes';
    it.isLesson = item.type === 'Lesson';
    it.isAssignment = item.type ==='Assignment';
    it.widgetId = item.widgetId;
    it.isRegistered = !!item.isRegistered;
    it.isMajor = !!item.isMajor;
    it.totalMarks = item.totalMarks;
    it.extraDays = item.extraDays;
    if(it.isQuiz || it.isTest || it.isPractice){
        it.miniAnalysis = item.miniAnalysis;
    }
    let notFreezed = (item.stage !== 'freezed') && (item.stage !== 'freeze') && (item.stage !== ''); //todo remove this hack after nikhil confirms notes case
    if(it.isVideo || it.isLesson){
        notFreezed = false; //we want to consider all videos as freezed
    }
    
    let testHasNoquestions = (it.isQuiz || it.isTest || it.isPractice) && item.questionCount === 0;

    let startTextPrefix = 'Was Live';

    it.title = item.title || item.entityTitle || item.name || item.entityName;
    it.route  = entityToUrl(item,_projectionMode);
    if (item.availableFrom) {
        item.availableFrom = (new Date(item.availableFrom));
    }
    if (item.availFrom) {
        item.availFrom = new Date(item.availFrom);
    }else{
        item.availFrom = item.availableFrom;
    }
    it.reschedule=item.reschedule;
    it.isPreLaunch = item.isPreLaunch;
    let futureEntity = !it.isPreLaunch && (new Date(isValidDate(item.availFrom,true) || DEFAULT_DATE).getTime() > currentTime);  // In case of live class & doubt class, futureEntity bool is decided based on start time. Refer #1
    it.isComingSoon = !futureEntity && (notFreezed || testHasNoquestions);
    it.futureEntity = futureEntity;
    if((it.isTest || it.isQuiz || it.isPractice) && item.availFrom && item.availFrom <= new Date() && (it.isPractice || !notFreezed)){
        item.questionCount = item.qsAdded;
        it.isComingSoon = item.questionCount===0;
    }
    if(it.isNotes){
        it.isExternal = item.isExternal;
        it.type = it.isExternal ? 'Link' : it.type;
    }
    if (item.availTill) {
        item.availTill = (new Date(item.availTill));
    }
    if (item.startTime) {
        item.startTime = (new Date(item.startTime));
    }
    if (item.endTime) {
        item.endTime = (new Date(item.endTime));
    }
    if (item.oldStartTime) {
        item.oldStartTime = (new Date(item.oldStartTime));
    }
    it.availFromDateString = new Date(isValidDate(item.availFrom,true) || DEFAULT_DATE).toDateString();
    it.availFrom = getDateFormat(isValidDate(item.availFrom,true) || isValidDate(item.availableFrom,true) || DEFAULT_DATE);
    it.availTill = getDateFormat(isValidDate(item.availTill,true) || DEFAULT_DATE);
    it.availFromDate = isValidDate(item.availFrom,true) || isValidDate(item.availableFrom,true) || DEFAULT_DATE;
    it.startTime = new Date(isValidDate(item.startTime,true) || isValidDate(item.availFrom,true) || DEFAULT_DATE);
    it.endTime = new Date(isValidDate(item.endTime,true) || isValidDate(item.availTill,true) || DEFAULT_DATE);
    it.rescheduleStartTime = new Date(isValidDate(item.startTime,true) || DEFAULT_DATE);
    it.startText = getDateFormat(isValidDate(item.startTime,true) || DEFAULT_DATE, true);
    it.startTimeText = getDateFormat(it.startTime,true,'HH MM AMPM DD MM');
    it.availFromText = getDateFormat(it.availFromDate,true,'HH MM AMPM DD MM');
    if(it.isAssignment){
        it.deadlineText = getDateFormat(item.deadline,true,'HH MM AMPM DD MM');
        let currentDate = new Date();
        let deadlineDate =  new Date(item.deadline);
        // let deadlineDateWithThreshold = deadlineDate.setDate(deadlineDate.getDate() + item.extraDays);
        let deadlineDateWithThreshold =new Date(item.deadline);
        deadlineDateWithThreshold.setDate(deadlineDate.getDate() + item.extraDays);
        it.deadlineWithThreshold = deadlineDateWithThreshold;
        it.deadlineWithThresholdText = getDateFormat(deadlineDateWithThreshold,true,'HH MM AMPM DD MM');
        it.assignmentState = transformAssignmentStates(currentDate,it.availFromDate,deadlineDate,deadlineDateWithThreshold);
        it.isNotAvailable = it.assignmentState === NOT_AVAILABLE;
        it.isAvailable = it.assignmentState === AVAILABLE;
        it.isDeadlineCrossed = it.assignmentState === CROSSED_DEADLINE_BUT_AVAILABLE;
        it.isDeadlineMissed = it.assignmentState === ALL_DEADLINES_CROSSED;
    }
    it.oldStartTime = new Date(isValidDate(item.oldStartTime,true) || DEFAULT_DATE);
    if(it.isLive){
        it.timeToShow = it.startTime || DEFAULT_DATE;
    }else{
        it.timeToShow = it.availFromDate || DEFAULT_DATE;
    }
    if(it.isVideo){
        it.startDateText=toShortFormat(it.startTime,true);
        it.startTimeOnlyText=createTimeText(new Date(it.startTime),true)
    }
    else{
        it.startDateText=toShortFormat(item.availFrom,true);
        it.startTimeOnlyText=createTimeText(new Date(item.availFrom),true)
    }
    it.order = item.order;
    it.info = Math.round(item.duration / (60*1000*1000*1000));
    if((it.isVideo || it.isLesson) && item.instructorDetails){
        it.instructorDetails=item.instructorDetails[0];
    }else{
        it.instructorDetails=[];
    }


    it.languages=(it.languages)? CombineLanguage(it.languages,',',false) : 'English'

    it.modules = item.modules || [];

    it.questionCount = item.questionCount || item.qsAdded || item.qCount;

    let livePrefix = '';

    it.isLiveNow = false;
    it.isLiveOver = false;
    it.subjects = (item.subjects || []).map(function(val) {
            return val && val.name;
        });
    it.instructors = item.instructors || [];
    it.instructorsText = item.instructorsText || '';
    it.isRescheduleVisible=false;
    it.isCancelled = false;
    it.isRescheduled = false;
    if(it.reschedule && it.reschedule.status){
            it.isRescheduleVisible=true;
            it.futureEntity = false;
            it.isComingSoon = false;
            it.reschedule = {...it.reschedule,
                status: it.reschedule.status.trim().toLowerCase()
            };
            it.isCancelled = it.reschedule.status==='cancelled'; 
            
            let scheduledTime = getDateFormat(it.startTime, true);
            it.altRoute = it.route;
            if(it.reschedule.status==="rescheduled" && new Date(it.reschedule.prevStartTime).getTime() === new Date(item.oldStartTime).getTime()){ //item.oldStartTime -> is same as previous time in status for new for rescheduled entity 
                it.isRescheduled = true;
                it.timeToShow = it.reschedule.prevStartTime;
                it.id = it.reschedule.oldEntityId;
            }
            it.route = [];
            if(it.reschedule.status==="rescheduled"){
                it.rescheduleText = 'Rescheduled to ' + scheduledTime;
                it.rescheduleAccordionText = 'Class rescheduled to ' + scheduledTime;
            }else if(it.reschedule.status==="cancelled"){
                it.rescheduleText = 'Class Cancelled';
                it.rescheduleAccordionText = 'This class has been cancelled';
            }else if(it.reschedule.status==="pending"){
                it.rescheduleText = 'Rescheduled, new timing will be announced soon';
                it.rescheduleAccordionText = 'Class Rescheduled, new timing will be announced soon';
            }else{
                it.rescheduleText = '';
                it.rescheduleAccordionText = '';
            }
            if(it.reschedule.status==="rescheduled" && new Date(it.reschedule.prevStartTime).getTime() != new Date(item.oldStartTime).getTime()){   //item.oldStartTime -> is default time for new for rescheduled entity 
                it.isRescheduleVisible=false;
                it.isRescheduled = false;
                it.route=it.altRoute;
            }
    }

    if(!it.isVideoType && !it.isRescheduleVisible && (item.isLiveCurrently || (new Date(it.startTime).getTime() <= currentTime) && (currentTime <= new Date(it.endTime).getTime()))){
        it.isLiveNow = true;
    }

    it.isLiveCurrently = item.isLiveCurrently;
    it.isClassOngoing = item.streamStatus === 'broadcasting' || item.streamStatus === 'broken';

    if(it.isLesson){
        for(let i = 0;i < it.modules.length; i++){
            if(it.modules[i].type !== 'Live Class' && it.modules[i].type !== 'Doubt Class'){continue;}
            it.isLive = true;
            if(!(new Date(it.modules[i].startTime).getTime() > currentTime) || !(new Date(it.modules[i].endTime).getTime() > currentTime)){continue;}
            if(new Date(it.modules[i].startTime).getTime() > currentTime){
                it.futureEntity = true;
                it.startTime = it.startTime || it.modules[i].startTime;
                break;
            }

            it.isLiveNow = true;
            break;
        }
    }

    if(it.isLive){
        it.futureEntity = (new Date(it.startTime).getTime() > currentTime);       //Reference #1
        let time = it.isRescheduleVisible?it.timeToShow:it.startTime;
        if(it.isLiveNow){
            livePrefix = 'Started at ';
            it.liveInfo = livePrefix + getDateFormat(time);

        } else if(isValidDate(it.endTime,false) && (new Date(it.endTime).getTime() + 360000 < currentTime) ) {
            livePrefix = 'Was live ';
            it.liveInfo = livePrefix + getOnlyDateFormat(time);
            it.isLiveOver = true;
        } else if (it.isLesson) {
            livePrefix = 'Live ';
            it.liveInfo = livePrefix + getDateFormat(it.startTime);
        } else {
            livePrefix = 'Live at ';
            it.liveInfo = livePrefix + getDateFormat(time);

        }
        if(it.isLiveOver){
            let timeDiff: any = getTimeDifference(new Date(it.startTime), new Date());
            it.startText = `${startTextPrefix} ${timeDiff}`;
        }
    }

    it.lessonCardText = '';
    if(it.isLesson){
        let featureArr= [];
        if(it.isLive && it.futureEntity){featureArr.push(getDateFormat(new Date(it.startTime)))}
        else if(it.isLive && it.isLiveOver){featureArr.push(it.liveInfo)};
        // if(it.isLiveNow){featureArr.push('Live Now')};
        let activityTxt = (it.modules && it.modules.length || 'All') + ' Activites';
        featureArr.push(activityTxt);

        it.lessonCardText = featureArr.join(' | ');
    }
    return it;
}


function transformAssignmentStates(currentDate,availFromDate,deadlineDate,deadlineDateWithThreshold){
    // 0 - not available , 1 - available , 2 - crossed deadline but available , 3 - not available(crossed deadline + threshold)
   
    if(availFromDate > currentDate){
        return NOT_AVAILABLE;
    }else if(currentDate < deadlineDate){
        return AVAILABLE;
    }else if(currentDate > deadlineDate && currentDate < deadlineDateWithThreshold){
        return CROSSED_DEADLINE_BUT_AVAILABLE;
    }
    return ALL_DEADLINES_CROSSED;
}


export function transformEntities(entities,_projectionMode){    //For Demo Entities
    let transformed:any = {};
    Object.keys(entities).forEach(key =>{ transformed[key] = transform(entities[key],_projectionMode) })
    return transformed;
}

export function transformEntity(items,_projectionMode){         //For Regular Entities
    return items.map(item => transform(item, _projectionMode));
}



class sections extends baseModel{

    @propertyMaps('id',_.str)
    public id;

    @propertyMaps('name',_.str)
    public name;

    @propertyMaps('subject',_.str)
    public subject;

    @propertyMaps('metadata',reduceToModuleCount)
    public moduleCount;

    @propertyMaps('moduleEntities',pipeModel(
        arrayModel(entity),
        transformEntity),entity)
    public entities;
}
setupModel(sections,{});

class LanguageTag extends baseModel{

    @propertyMaps('id',_.str)
    public id;

    @propertyMaps('title',_.str)
    public title;
}
setupModel(LanguageTag,{});


class Instructor extends baseModel{

    @propertyMaps('id',_.str)
    public id;

    @propertyMaps('name',_.str)
    public name;

    @propertyMaps('isInstructor',_.bool)
    public isInstructor;
}
setupModel(Instructor,{});

export class RedirectCourse extends baseModel{

    @propertyMaps('id',_.str)
    public id;

    @propertyMaps('title',_.str)
    public title;

    @propertyMaps('hasAccess',_.bool)
    public hasAccess;

    @propertyMaps('imageUrl',_.str)
    public imageUrl;

    @propertyMaps('moduleCount',_.num)
    public moduleCount;

    @propertyMaps('instructors',arrayModel(Instructor),Instructor)
    public instructors;

    @propertyMaps('languageTags',arrayModel(LanguageTag),LanguageTag)
    public languageTags;

    @propertyMaps('slug',objModel(Slug), Slug)
    public slug;
}
setupModel(RedirectCourse,{});

export class freeDemo extends baseModel{
    @propertyMaps('entities',pipeModel(transformEntities))
    public entities;
    @propertyMaps('sectionModule',arrayModel(sections),sections)
    public modules;
}
setupModel(freeDemo,{});

export class _freeDemo extends baseModel{
    @propertyMaps('entities')
    public entities;
    @propertyMaps('modules',arrayModel(_sections),_sections)
    public modules;
}
setupModel(_freeDemo,{});

export class subjects extends baseModel{
    @propertyMaps('id',_.str)
    public id;

    @propertyMaps('name',_.str)
    public name;
}
setupModel(subjects,{});

function addAllSubects(array){
    array.push({id:'all',name:'All Subjects'});
    return array;
}

export class schedule extends baseModel{

    @propertyMaps('sections',arrayModel(sections),sections)
    public sections;

    @propertyMaps('subjects',pipeModel(
        arrayModel(subjects),
        addAllSubects
    ),subjects)
    public subjects;

    @propertyMaps('currentSection',_.str)
    public currentSection;
}
setupModel(schedule,{__projection:true});

export class ClassModules extends baseModel {
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('titles', _.str)
    public title;

    @propertyMaps('moduleEntities',pipeModel(
        arrayModel(entity),
        transformEntity),entity)
    public entities;
}
setupModel(ClassModules, {__projection: true});

export class DateWiseSchedule extends baseModel {
    @propertyMaps('classes', arrayModel(ClassModules), ClassModules)
    public classes
}
setupModel(DateWiseSchedule, {__projection: true});

function getClassDaysArr(obj, _projectionMode) {
    if(_projectionMode) {
        return 1;
    }

    let res = [];
    for(let dateStr in obj) {
        let splitObj = dateStr.split('-').map(i=>parseInt(i));              //for some reason .map(parseInt) doesn't work.
        let month = (splitObj[1] - 1),
            year = splitObj[2],
            day = splitObj[0];
        let tempDate = new Date(year, month, day);
        res.push({
            date: tempDate,
            moduleCount: obj[dateStr]
        });
    }
    return res.sort((a,b) => a.date - b.date);
}

export class _ClassDay extends baseModel {
    @propertyMaps()
    public date;

    @propertyMaps()
    public moduleCount
}
setupModel(_ClassDay, {});

export class _ClassDays extends baseModel {
    @propertyMaps('classDays', arrayModel(_ClassDay), _ClassDay)
    public classDays;

    @propertyMaps('isPremium', _.bool)
    public isPremium;
}
setupModel(_ClassDays, {});

export class ClassDays extends baseModel {
    @propertyMaps('classDays', pipeModel(_.obj, getClassDaysArr))
    public classDays;

    @propertyMaps()
    public isPremium;
}
setupModel(ClassDays, {__projection: true});

export class getClassScheduleByEntityApi {
    static apiEndpoint = 'v1/products/cid/subject-schedule';
    static _apiEndpoint ='v1/products/';

    static projection;
    static get  __projection(){
        if(!getClassScheduleByEntityApi.projection){
            getClassScheduleByEntityApi.projection = JSON.stringify(new SourceProjectionMapper(schedule).map());
        }
        return getClassScheduleByEntityApi.projection;
    }

    static apiCall(network:Network,params:any){
        let apiParam;
        if(params.entityId){
            apiParam = {entityId:params.entityId, classId:params.classId, __projection:getClassScheduleByEntityApi.__projection}
        } else if(params.subjectId){
            apiParam = {subjectId:params.subjectId, classId:params.classId,__projection:getClassScheduleByEntityApi.__projection}
        }

        apiParam.supportsLesson = true;
        return network.get(getClassScheduleByEntityApi._apiEndpoint+params.classId+ '/subject-schedule',apiParam);
    }
}

export class getClassSectionByIdApi{
    static apiEndpoint  = 'v1/products/cid/items';
    static _apiEndpoint = 'v1/products/';

    static projection;
    static get  __projection(){ 
        if(!getClassSectionByIdApi.projection){
            getClassSectionByIdApi.projection = JSON.stringify(new SourceProjectionMapper(schedule).map());
        }
        return getClassSectionByIdApi.projection
    }
    static apiCall(network:Network,params:any){
        let apiParam = {sectionIds:params.sectionId,__projection:getClassSectionByIdApi.__projection};
        return network.get(getClassSectionByIdApi._apiEndpoint+params.classId+ '/items',apiParam);
    }
}

export class getDemoClassApi{
    static apiEndpoint  = 'v2.2/classes/cid/demo';
    static _apiEndpoint = 'v2.2/classes/';

    static projection;
    static get  __projection(){ 
        if(!getDemoClassApi.projection){
            getDemoClassApi.projection = JSON.stringify(new SourceProjectionMapper(freeDemo).map());
        }
        return getDemoClassApi.projection;
    }
    static apiCall(network:Network,params:any){
        let apiParam = {__projection:getDemoClassApi.__projection};
        return network.get(getDemoClassApi._apiEndpoint+params.classId+ '/demo',apiParam);
    }
}

export class GetDateWiseModulesApi {
    static apiEndpoint = 'v1/students/me/class-schedule?';

    static projection;
    static get  __projection(){ 
        if(!GetDateWiseModulesApi.projection){
            GetDateWiseModulesApi.projection = JSON.stringify(new SourceProjectionMapper(DateWiseSchedule).map());
        }
        return GetDateWiseModulesApi.projection;
    }

    //expects one param - ts: timestamp for the date from which module needs to be fetched. It'll return modules till the end of the day.
    static apiCall(network: Network, params: any) {
        let date = new Date(params.ts - 1000);
        let fromDateISOString = date.toISOString();
        let nextDay = params.endTs || date;
        if(params.endTs){
            nextDay = new Date(addDays(nextDay, 1));
        } else {
            nextDay = new Date(addDays(nextDay, 2));
        }
        let toDateISOString = new Date(nextDay.setHours(0,0,0,0) - 1000).toISOString();     //getting date for end of the day.

        let apiParams : any = {
            __projection: GetDateWiseModulesApi.__projection,
            modulesFrom: fromDateISOString,
            modulesTo: toDateISOString,
            isPremium: params.isPremium || false,
            supportsLesson: true
        };

        if(params.classId){
            apiParams.classId = params.classId
        }

        return network.get(GetDateWiseModulesApi.apiEndpoint, apiParams);
    }
}

export class GetClassDaysApi {
    static apiEndpoint = 'v1/products/{classId}/class-days';

    static projection;
    static get  __projection(){ 
        if(!GetClassDaysApi.projection){
            GetClassDaysApi.projection = JSON.stringify(new SourceProjectionMapper(ClassDays).map());
        }
        return GetClassDaysApi.projection;
    }

    static apiCall(network: Network, params: any) {
        let apiParams = {
            __projection: GetClassDaysApi.__projection
        };

        return network.get(GetClassDaysApi.apiEndpoint.replace('{classId}', params.classId), apiParams);
    }
}


export class GetRedirectCourseApi {
    static apiEndpoint = 'v2/classes/redirect/{courseId}';

    static projection;
    static get  __projection(){ 
        if(!GetRedirectCourseApi.projection){
            GetRedirectCourseApi.projection = JSON.stringify(new SourceProjectionMapper(RedirectCourse).map());
        }
        return GetRedirectCourseApi.projection;
    }

    static apiCall(network: Network, params: any) {
        let apiParams = {
            __projection: GetRedirectCourseApi.__projection
        };

        return network.get(GetRedirectCourseApi.apiEndpoint.replace('{courseId}', params.courseId), apiParams);
    }
}
