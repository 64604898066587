import { Validator as _ } from "@shared/utility/validator";
import {baseModel, setupModel} from "@models/types";
import {
    appendToTargetSlug,
    arrayModel,
    filterIf,
    pipeModel,
    propertyMaps,
    SourceProjectionMapper
} from "@models/mapping-framework";
import {Network} from "@core/services/model-manager/main";

export class TargetSlug extends baseModel{

    @propertyMaps('_id',_.str)
    public id;

    @propertyMaps('properties',_.obj)
    public properties;

    @propertyMaps('stats', _.obj)
    public stats;

    @propertyMaps('slug', _.str)
    public slug;

    @propertyMaps('entityCount', _.obj)
    public entityCount

}
setupModel(TargetSlug,{type: 'targets'});

function hasSlug(targetFromBE){
    return targetFromBE && targetFromBE.slug;
}

export class TargetSlugWrapper extends baseModel {
    @propertyMaps('targets', pipeModel(filterIf(hasSlug),arrayModel(TargetSlug)), TargetSlug)
    public targets;
}
setupModel(TargetSlugWrapper,{type: 'targets'});

export class PypTargetSlugWrapper extends baseModel {
    @propertyMaps('targets', pipeModel(filterIf(hasSlug), appendToTargetSlug('/previous-year-papers'), arrayModel(TargetSlug)), TargetSlug)
    public targets;
}
setupModel(PypTargetSlugWrapper,{type: 'targets'});

export class _PypTargetSlugWrapper extends baseModel {
    @propertyMaps('targets', arrayModel(TargetSlug))
    public targets;
}
setupModel(_PypTargetSlugWrapper,{type: 'targets'});

export class GetTargetsFromSupergroupApi{
    static apiEndpoint = 'v1/tsg/{targetSuperGroup}/targets';

    static projection;
    static get  __projection(){ 
        if(!GetTargetsFromSupergroupApi.projection){
            GetTargetsFromSupergroupApi.projection = JSON.stringify(new SourceProjectionMapper(TargetSlugWrapper).map());
        }
        return GetTargetsFromSupergroupApi.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(GetTargetsFromSupergroupApi.apiEndpoint.replace('{targetSuperGroup}', params.superGroupId),{__projection:GetTargetsFromSupergroupApi.__projection});
    }
}

export class GetPypTargetsFromSupergroupApi{
    static apiEndpoint = 'v1/previous-year-papers/{targetSuperGroup}/targets';

    static projection;
    static get  __projection(){ 
        if(!GetPypTargetsFromSupergroupApi.projection){
            GetPypTargetsFromSupergroupApi.projection = JSON.stringify(new SourceProjectionMapper(_PypTargetSlugWrapper).map());
        }
        return GetPypTargetsFromSupergroupApi.projection;
    }

    static apiCall(network:Network,params:any){
        return network.get(GetPypTargetsFromSupergroupApi.apiEndpoint.replace('{targetSuperGroup}', params.superGroupId),{pageType: params.pageType,__projection:GetPypTargetsFromSupergroupApi.__projection});
    }
}

