<div class="modal fade custom-login-modal" [ngClass]="{'modal-boarding-view':shouldModalSizeIncrease}" id="tbSignUpModal" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog" role="document">
        <div class="modal-content js-tb-modal-content" [ngClass]="{'is-mobile': isMobile}">
            <div class="modal-body pad-0 js-inject-iframe">
                <div class="js-inject-iframe-loader" style="position: absolute; left: 0; right: 0; top: 70px;text-align: center;">
                    <div class="main-loader"></div>
                </div>
                <div id="js-custom-close-btn"
                     data-dismiss="modal"
                     (click)="closeModal()" class="tb-close"></div>
                <iframe name="onBoardingIframeName" id="onBoardingIframe" class="w-100 js-tb-on-boarding loginIframe" src="about:blank" style="position: relative; width:100%" frameborder="0"></iframe>
            </div>
        </div>
    </div>
</div>

<div class="footer-modal-backdrop" *ngIf="isMobileFirstLoginModal" (click)="closeModal()"></div>
<div class="footer-modal" [ngClass]="{'footer-modal--big': !queryParam}" *ngIf="isMobileFirstLoginModal">
    <div class="">
        <div class="st-slider" *ngIf="!hideGraphic && !entity && classConditions.length == 0">
            <input type="radio" class="cs_anchor radio" name="slider" id="slide1"/>
            <input type="radio" class="cs_anchor radio" name="slider" id="slide2"/>
            <input type="radio" class="cs_anchor radio" name="slider" id="slide3"/>
            <input type="radio" class="cs_anchor radio" name="slider" id="slide4"/>
            <input type="radio" class="cs_anchor radio" name="slider" id="play1" checked=""/>

            <div class="custom-slider">
                <div class="custom-slider-inner">
                    <div class="custom-slider-item">
                        <div class="custom-slider">
                            <div class="mb-3">
                                <img class="js-lazy-load-img" src="assets/img/select/skills-internship.svg" width="81" height="81" alt="fig-1" >
                            </div>
                            <div class="mb-0">
                                <div class="ui-h4 mb-2">100% Guaranteed Internship</div>
                                <div class="help__text--small">All candidates will get an internship opportunity and internship certificate.</div>
                            </div>
                        </div>
                    </div>
                    <div class="custom-slider-item">
                        <div class="custom-slider">
                            <div class="mb-0">
                                <div class="mb-3">
                                    <img class="js-lazy-load-img" src="assets/img/select/skill-feature-f1.svg" width="81" height="81" alt="fig-2" >
                                </div>
                                <div class="mb-0">
                                    <div class="ui-h4 mb-2">Complete Job Support</div>
                                    <div class="help__text--small">Secure a job with complete assistance from building resumes to interview preparation.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="custom-slider-item">
                        <div class="custom-slider">
                            <div class="mb-0">
                                <div class="mb-3">
                                    <img class="js-lazy-load-img" src="assets/img/select/skill-feature-f2.svg" width="81" height="81" alt="fig-3" >
                                </div>
                                <div class="mb-0">
                                    <div class="ui-h4 mb-2">Industry-Recognized Skill Certificate</div>
                                    <div class="help__text--small">Share your achievements with the top employers and your professional network on LinkedIn.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="custom-slider-item">
                        <div class="custom-slider">
                            <div class="mb-0">
                                <div class="mb-3">
                                    <img class="js-lazy-load-img" src="assets/img/select/select-unique-f2.svg" width="81" height="81" alt="fig-4" >
                                </div>
                                <div class="mb-0">
                                    <div class="ui-h4 mb-2">Hands-on real world projects</div>
                                    <div class="help__text--small">Learn with Real-world capstone projects designed by industry experts with 1:1 project assistance.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="labels">
                <div class="fake-radio">
                    <label for="slide1" class="radio-btn"></label>
                    <label for="slide2" class="radio-btn"></label>
                    <label for="slide3" class="radio-btn"></label>
                    <label for="slide4" class="radio-btn"></label>
                </div>
            </div>
        </div>

        <div class="course-criteria" *ngIf="!hideGraphic && classConditions.length > 0">
            <h4 class="mb-6">Here’s a Note!</h4>
            <ul class="course-criteria__list">
                <li *ngFor="let condition of classConditions">{{condition}}</li>
            </ul>
            <img src="assets/img/select/mselectcourse.png" alt="select-img">
        </div>
        
        <div class="demo-card--modal"  *ngIf="!hideGraphic && entity && entity.isVideo && classConditions.length == 0">
            <div class="demo-preview--modal" style="background-size:cover;" [ngStyle]="{'background-image': 'url(' + entity.thumbnail + '), url(/assets/img/m-select/demo-fallback-blue.svg)'}"></div>
            <div class="demo-details">
                <div class="demo-title--modal">
                    {{ entity.title }}
                </div>
                <div class="tags-container mb-2">
                    <div>
                        <span class="tag_outline--blue">{{entity.subject}}</span>
                    </div>
                    <div>
                        <span class="ui-text_grey help__text--small">By: {{entity.instructor}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="quiz-preview_card"  *ngIf="!hideGraphic && entity && entity.isTest && classConditions.length == 0">
            <div class="quiz-preview-img"></div>
            <p class="quiz-preview-title">
                {{entity.title}}
            </p>
            <p class="help__text--small">{{entity.qCount}} Questions . {{entity.totalT}} Minutes</p>
        </div>

        <div class="registration-container" *ngIf="status==='open'">
            <div class="ui-h4 registration-heading">{{heading}}</div>
            <div class="login-input" *ngIf="!queryParam">
                    <input id="username" type="text" placeholder="{{'ENTER_NAME' | translate}}" [(ngModel)]="userName"
                    (focus)="boxFocus()" (blur)="boxBlur()">
            </div>
            <div class="login-input" *ngIf="!queryParam">
                    <input id="useremail" type="text" placeholder="{{'ENTER_EMAIL' | translate }}" [(ngModel)]="userEmail"
                    (focus)="boxFocus()" (blur)="boxBlur()">
            </div>
            <div class="input-grouped">
                <div class="input-grouped-prepend">
                    <span class="input-grouped-text">+91</span>
                </div>
                <input id="mobile" type="tel" placeholder="{{'ENTER_PHONE_NUMBER' | translate}}" [(ngModel)]="mobileNumber"
                       (keyup)="mobileEnforce($event)" (keydown)="mobileEnforce($event)"
                       (focus)="boxFocus()" (blur)="boxBlur()">
            </div>
            <button type="button" class="btn ui-btn--primary curved-btn otp-btn" (click)="requestOTP()" translate>
                {{'SEND_OTP'}}
            </button>
        </div>

        <div class="registration-container" *ngIf="status==='requesting'">
            <div class="ui-h4 mb-3" translate>{{'GENERATING_OTP'}}</div>
        </div>

        <div class="registration-container" *ngIf="status==='requested'">
            <div class="ui-h4 registration-heading" translate>{{'ENTER_OTP'}}</div>
            <input type="hidden" autocomplete="one-time-code" (change)="onHiddenInputChange($event)">
            <div class="grouped-textboxes">
                <input #otp1 type="tel" placeholder="X" class="otp-textbox" maxlength="1"
                       [(ngModel)]="OTP[0]" (keyup)="nextOTP($event,0)" (paste)="onPaste($event)"
                       (focus)="boxFocus()" (blur)="boxBlur()">

                <input #otp2 type="tel" placeholder="X" class="otp-textbox" maxlength="1"
                       [(ngModel)]="OTP[1]" (keyup)="nextOTP($event,1)" (paste)="onPaste($event)"
                       (focus)="boxFocus()" (blur)="boxBlur()">

                <input #otp3 type="tel" placeholder="X" class="otp-textbox" maxlength="1"
                       [(ngModel)]="OTP[2]" (keyup)="nextOTP($event,2)" (paste)="onPaste($event)"
                       (focus)="boxFocus()" (blur)="boxBlur()">

                <input #otp4 type="tel" placeholder="X" class="otp-textbox" maxlength="1"
                       [(ngModel)]="OTP[3]" (keyup)="nextOTP($event,3)" (paste)="onPaste($event)"
                       (focus)="boxFocus()" (blur)="boxBlur()">
                <input #otp5 type="tel" placeholder="X" class="otp-textbox" maxlength="1"
                       [(ngModel)]="OTP[4]" (keyup)="nextOTP($event,4)" (paste)="onPaste($event)"
                       (focus)="boxFocus()" (blur)="boxBlur()">
                <input #otp6 type="tel" placeholder="X" class="otp-textbox" maxlength="1"
                       [(ngModel)]="OTP[5]" (keyup)="nextOTP($event,5)" (paste)="onPaste($event)"
                       (focus)="boxFocus()" (blur)="boxBlur()">
            </div>

            <div class="resend-otp"  *ngIf="!showResend && !noMoreChances">
                <span class="otp-timer">{{OTPTimerStr}}</span>
            </div>

            <div class="resend-otp"  *ngIf="!showResend && noMoreChances">
                Too many Retries
            </div>

            <div class="mb-3 help__text" *ngIf="showResend">
                <a href="javascript:void(0)" (click)="resendOTP()" translate>{{'RESEND_OTP_TEXT'}}</a>
            </div>

            <button type="button" *ngIf="!verifying" class="btn ui-btn--primary curved-btn started-btn" (click)="verifyOTP($event)" translate>
                {{'GET_STARTED'}}
            </button>

            <button type="button" *ngIf="verifying" class="btn ui-btn--primary curved-btn started-btn disabled">
                Verifying...
            </button>
        </div>
    </div>
</div>
