import { Validator as _ } from "../../../shared/utility/validator";
import {arrayModel, pipeModel, propertyMaps, SourceProjectionMapper} from "@models/mapping-framework";
import {Network} from "@core/services/model-manager/main";
import {baseModel, setupModel} from "@models/types";
import {CourseModel, processCourse} from "@models/entity/classes/classes.adapter";

function processCourses(courses) {
  if(courses?.length) {
    return courses.map(course => processCourse(course));
  }
  return courses;
}

export class GoalClasses extends baseModel {
  @propertyMaps('classes', pipeModel(arrayModel(CourseModel), processCourses), CourseModel)
  public classes;
  @propertyMaps('total')
  public total;
}
setupModel(GoalClasses, {});



export class GetClassesByIdApi {
  static apiEndpoint = 'v2.1/classes';

  static projection;
  static get  __projection(){ 
    if(!GetClassesByIdApi.projection){
      GetClassesByIdApi.projection = JSON.stringify(new SourceProjectionMapper(GoalClasses).map());
    }
    return GetClassesByIdApi.projection;
  }

  static apiCall(network: Network, params) {
    return network.get(GetClassesByIdApi.apiEndpoint, { ...params, __projection: GetClassesByIdApi.__projection });
  }
}