import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CoreModule} from "@core/core.module";
import {PassCardsComponent} from './pass-cards.component';
import {PassCardComponent} from './pass-card-component/pass-card.component';
import { KindOfferComponent } from './kind-offer/kind-offer.component';
import {customTranslateLoader, langChangeListener} from "@shared/utility/translation-loaders";
import {HttpClient} from "@angular/common/http";
import {TransferState} from "@angular/platform-browser";
import {TbcoreService} from "@core/services/tbcore.service";
import {PlatformService} from "@core/services/platform-service";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {PriceBreakdownComponent} from "../price-breakdown/price-breakdown.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {PassStatusExportModule} from "@features/pass-status-component/pass-status.export.module";

@NgModule({
    declarations: [
        PassCardsComponent,
        PassCardComponent,
        KindOfferComponent,
        PriceBreakdownComponent
    ],
    imports: [
        CommonModule,
        CoreModule,
        TranslateModule.forChild({
            extend: true
        }),
        FormsModule,
        ReactiveFormsModule,
        PassStatusExportModule
    ],
    exports :[
        PassCardsComponent,
        PassCardComponent,
        KindOfferComponent,
        PriceBreakdownComponent
    ]
})
export class PassCardsExportModule {
    constructor(private translateService:TranslateService,private tbcore:TbcoreService, private platformService: PlatformService) {
        langChangeListener(this.translateService,this.tbcore, this.platformService);
      }
 }