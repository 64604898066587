export function transformToTrackerResultArray(result, dataKey?){
    let convertedArray=[];
    for(let type of Object.keys(result)){
        let typeObj={
            dids: [],
            dtyp:''
        };
        if(result[type].length > 0){
            typeObj={
                dids: [],
                dtyp:(type === 'pypTests') ? 'pyp':type,
            };

            for(let ele of result[type]){
                if(!dataKey){
                    typeObj.dids.push(ele.id || ele._id);
                } else {
                    typeObj.dids.push(ele?.[dataKey]?.id);
                }
            }
            convertedArray.push(typeObj)
        }
    }

    return convertedArray;
}