import {Component, HostListener, OnInit, Renderer2} from '@angular/core';
import {Store} from "@ngrx/store";
import {TbcoreService} from "@core/services/tbcore.service";
import {PlatformService} from "@core/services/platform-service";
import {constructOnBoardingUrl, isServer} from "@shared/utility/tb-common";
import {PYP_LOGIN_HEADER} from "@shared/utility/constants";
import {AuthenticationService} from "@core/services/authentication.service";
import {Router} from "@angular/router";
import {getGoalSelectionPageUrl} from "@shared/utility/goal-utility";
import { selectActivePageType } from '@angular-commons/core/application-state/app.selectors';


@Component({
  selector: 'app-header-logged-out',
  templateUrl: './header-logged-out.html',
  styleUrls: ['./header-logged-out.scss']
})
export class HeaderLoggedOut implements OnInit {
  //isLoggedIn: boolean = isLoggedIn();
  //isMobile: boolean = isMobile();

  goalSelectionPageUrl = getGoalSelectionPageUrl();
  headerData: any;
  studentData: any;
  isSideNavCollapsed: boolean;
  isLoggedIn: boolean = false;
  isMobile: boolean = this.platformService.isMobile();
  isServer: boolean = isServer();

  showTargets = false;
  showTargetsUntilClicked = false;

  showGoals = false;

  loginUrl = "/login";
  signupUrl = "/login?tile=signup";

  searchModalOpen = false;
  showHeaderMweb = false;
  goalListHandler = () => {};
  targetListHandler = () => {};
  mwebHeaderHandler = () => {};

  hasGoal = false;
  constructor(
      private tbCore: TbcoreService,
      private store: Store<{}>,
      private renderer: Renderer2,
      private platformService: PlatformService,
      private auth : AuthenticationService) {
    this.platformService.pageDetailsUpdated.subscribe(()=>{
        this.loginUrl = constructOnBoardingUrl({referrer: this.platformService.getPageText(), referrerType: this.platformService.getPageType()}, true, this.platformService.href,true);
        this.signupUrl = constructOnBoardingUrl({referrer: this.platformService.getPageText(), referrerType: this.platformService.getPageType()}, false, this.platformService.href,true);
    });
  }

  activePageType:string = '';

  ngOnInit() {
    this.hasGoal = !!this.auth.getGoal().id;
    this.store.select(selectActivePageType).subscribe((type)=>{
      this.activePageType = type;
    });
 }

  openLoginParams(){
    let pathName = this.platformService.getPathName();
    let isPYP = pathName.includes('previous-year-papers');
    let couponValue=this.platformService.getQueryParamValue('coupon');
    let couponParam = '';
    if(couponValue){
      couponParam='?coupon='+couponValue;
    }
    let params = {
      redirect_url: pathName === "/" ? "/home" : pathName + couponParam,
      referrer: this.platformService.getPageText(),
      referrerType: this.platformService.getPageType()
    };
    if(isPYP) {
      params['headerText'] = PYP_LOGIN_HEADER;
    }
    return params;
  }
  openLogin(event,isLogin){
    event.preventDefault();
    //document.body.classList.add('modal-open');
  }

  toggleSearchModal() {
    this.searchModalOpen = !this.searchModalOpen;
  }

  public targetDropdownTouched = false;
  showTargetDropdown(event?) {
    this.showTargets = true;
    this.targetDropdownTouched = true;
    if(event) {
      event.stopPropagation();
    }
    this.targetListHandler = this.renderer.listen(document, 'click', () => {this.hideTargetDropdown()});

  }

  hideTargetDropdown() {
    this.showTargets = false;
    this.targetListHandler();
  }


  showGoalsDropdown(event?) {
    this.showGoals = true;
    if(event) {
      event.stopPropagation();
    }
    this.goalListHandler = this.renderer.listen(document, 'click', () => {this.hideGoalsDropdown()});
  }

  hideGoalsDropdown(event?) {
    this.showGoals = false;
    this.goalListHandler();
  }

  toggleHeaderMweb(event?) {
    this.targetDropdownTouched = true;
    if(!this.showHeaderMweb) {
      this.mwebHeaderHandler = this.renderer.listen(document, 'click', () => {this.toggleHeaderMweb()});
    }
    else {
      this.mwebHeaderHandler();
    }

    if(event) {
      event.stopPropagation();
    }

    this.showHeaderMweb = !this.showHeaderMweb;
  }

}
