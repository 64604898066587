import { PlatformService } from '@angular-commons/core/services/platform-service';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { Store } from '@ngrx/store';
import { setEmiIframeState } from '@angular-commons/core/application-state/app.actions';
import { EMI_MANDATE } from '@angular-commons/shared/utility/constants';
import { Subscription } from 'rxjs';
import { TransferStateManager } from '@angular-commons/core/services/transferStateManager';
import { getMandateGuideLines } from '../emi-mandate-popup/feature-state/emi-mandate.actions';
import { selectMandateGuideLines } from '../emi-mandate-popup/feature-state/emi-mandate.selector';
import { TbcoreService } from '@angular-commons/core/services/tbcore.service';

@Component({
  selector: 'emi-blocker-popup',
  templateUrl: './emi-blocker-popup.component.html',
  styleUrls: ['./emi-blocker-popup.component.scss']
})
export class EmiBlockerPopup implements OnInit, OnDestroy {
    
  isMobile: boolean = this.platformService.isMobile();
  isLoggedIn: boolean = this.platformService.isLoggedIn();
  mandateGuideLines$: Subscription;
  mandateGuideLinesPdfLink: string = '';
  @Input() goalId:string = '';

  @Output() closeModal = new EventEmitter<any>();
  @Output() switchPopup = new EventEmitter<any>();
  @Output() blockerPopupViewEvent = new EventEmitter<any>();
  @Output() emiInfoScreenVisitedEvent = new EventEmitter<any>();

  constructor(private platformService: PlatformService,
    private  transferStateManager: TransferStateManager,
    private store:Store<{}>){}

  ngOnInit(): void {
    this.blockerPopupViewEvent.emit();
    this.mandateGuideLines$ = this.transferStateManager.get('mandateGuideLines', getMandateGuideLines({commonDataType:'digioGuidelines'}), this.store.select(selectMandateGuideLines), (data) => {
      if (data?.isLoaded && data?.pdfLink) {
        this.mandateGuideLinesPdfLink = data.pdfLink;
      }
    });
  }

  openMandatePopup(){
    if(this.goalId){
      this.switchPopup.emit({
        openModal: true, 
        modalType: EMI_MANDATE, 
        goalId: this.goalId
      });
    }
  }

  handleCloseModal(){
    this.closeModal.emit();
  }

  ngOnDestroy(): void {
    if(this.mandateGuideLines$){
      this.mandateGuideLines$.unsubscribe();
    }
  }

  fireEmiInfoScreenVisitedEvent() {
    this.emiInfoScreenVisitedEvent.emit();
  }
}
