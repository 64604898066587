import { Injectable } from '@angular/core';
import {
    AddedToMyDoubtsEvent,
    RemovedFromMyDoubtsEvent,
    ReportADoubtButtonClickedEvent,
    ReportedTheDoubtSuccessfullyEvent,
    PostAnswerButtonClickedEvent,
    DoubtUpvoteEvent,
    EntitySearchedEvent,
    EntityClickedEvent,
    NotesAddedSuccessEvent,
    ModalViewedEvent,
    ExamPreferenceSelectedEvent,
    DemoModuleViewedEvent,
    InterestedInLearnCourseEvent,
    BannerImpressionEvent,
    BannerClickedEvent,
    CouponUsedEvent,
    TargetPageLoadEvent,
    TargetPageNavigationEvent,
    LearnCoursePageVisitedEvent,
    CurriculumDownloadedEvent,
    SelectionProofDownloadedEvent,
    LeadGeneratedEvent,
    LearnCourseEnrollNowEvent,
    RequestedCallbackEvent,
    PypPdfViewedEvent,
    PypPdfDownloadEvent,
    PypPdfSharedEvent,
    PypPdfLanguageChangedEvent,
    PypPassAlertEvent,
    PypFiltersAppliedEvent,
    InterestedInSelectCourseEvent,
    SelectCoursePageVisitedEvent,
    SelectCourseEnrollNowEvent,
    TestSeriesVisitEvent,
    InterestedInTestSeriesEvent,
    InterestedInPasses,
    EnrolledInTestSeries,
    TestRegistered,
    InterestedInPass,
    SendProductIds,
    Transacted,
    TransactionFailed,
    RegisterTestEvent,
    ScholarshipPageVisitedEvent,
    MasterClassSeriesVisitedEvent,
    MasterClassRemindMeEvent,
    MasterClassLessonVisitedEvent,
    SelectionsSubmissionEvent,
    LivePanelActionEvent,
    CategoryClickedEvent,
    MasterClassScreenshotCapturedEvent,
    MasterClassPracticeStarted,
    MasterClassNotesRead,
    MasterClassExploreActivity,
    MasterClassLessonStarted,
    MasterClassVideoCompleted,
    MasterClassLessonCompleted,
    MasterClassLessonLeft,
    MasterClassVideoStarted,
    MasterClassSeriesJoinedEvent,
    OnboardingSelectionTabEvent,
    OnboardingTargetRemovedEvent,
    OnboardingTargetSearchedEvent,
    OnboardingTargetSelectedCompletedEvent,
    OnboardingTargetSelectedEvent,
    OnboardingTargetSearchedSuccessEvent,
    LivePanelUserClick,
    HomePageUserClick,
    ReferralInvited,
    ReferralApplied,
    HomePageBannerViewPromotion,
    HomePageBannerSelectPromotion,
    SupercoachingExploreActivity,
    SupercoachingCallbackRequested,
    SupercoachingProceededToPayment,
    SupercoachingGoalPageVisited,
    SupercoachingEntityExplore,
    SupercoachingFreeLessonViewed,
    SupercoachingTeacherPageVisited,
    SupercoachingAllTeachersPageVisited,

    SupercoachingLiveClassClicked,
    SupercoachingCourseCurriculumViewed,
    SupercoachingTestSeriesClicked,
    SupercoachingPurchaseScreenVisited,
    SupercoachingBrochureDownloaded,
    SupercoachingCoursePageVisited,
    SupercoachingCurriculumDownloadedEvent,
    PdfViewerUserClickEvent,
    SpecificSuperteacherExploreActivity,
    SupercoachingCourseExplored,
    GlobalSuperteacherExploreActivity,
    SupercoachingApplyCouponClicked,
    SupercoachingNotesDownloaded,
    SupercoachingReadmoreClicked,
    ApplyCouponClicked,
    SupercoachingExploredNowClicked,
    CompletePendingPaymentClicked,
    TargetPagePopupClickEvent,
    GlobalTestSeriesPageEvent,
    SelectionFormProgressClicked,
    SupercoachingStudyPlanDownload,
    PdfDownloadEvent,
    SupercoachingIndividualPageActivity,
    SupercoachingNotesRead,
    SupercoachingPracticeStarted,
    SupercoachingVideoStarted,
    SupercoachingVideoCompleted,
    SupercoachingPracticeResumed,
    SupercoachingPracticeCompleted,
    SupercoachingTestStarted,
    SupercoachingSearch,
    SupercoachingVideoResumed,
    SupercoachingWhatsappChatClicked,
    SkillAcademyProgramPageNavigationEvent,
    SkillAcademyProgramPageCallbackRequestEvent,
    TestSeriesSlugPageEvent,
    GameRewardWonEvent,
    GoalPitchBannerClicked,
    GameCouponAppliedEvent,
    QueryParamDynamicEvent,
    GoalPurchasePopupClosedEvent,
    GoalPurchasePopupClickedEvent,
    GoalPurchasePopupViewedEvent,
    ProductsOfferingTabClicked,
    SignupActivityEvent,
    SuperCoachingGoalRecommendationClicked,
    PassProPopupActivity,
    PassProPlansPageVisited,
    PassProProceededToPayment,
    PypFreeTestUserClickEvent,
    StudyPracticeStarted,
    StudyPracticeResumed,
    StudyPracticeCompleted,
    SupercoachingGoalSelectionPageVisited,
    ReattemptTestEvent,
    Purchased,
    DeactivateAccountEvent,
    UpdatePincodeEvent,
    UpdateCategoryEvent,
    UpdateDobEvent,
    UpdateDegreeEvent,
    InterestedInEmailMentorship,
    EmailMentorshipEnrolled,
    CombinedPassComparisonPageActivityEvent,
    MainsAnswerCategoryChanged,
    MainsAnswerViewAllClicked,
    MainsAnswerCardClicked,
    MainsUserAnswerSubmitted,
    MainsAnswerUserUpVoted,
    SuperCoachingEntityExploreEvent,
    SuperCoachingTalkToExpert,
 	MentorshipButtonClicked,
    BookMentorshipButtonClicked,
    MentorshipModalDismissed,
    AppDownloadClickEvent,
    PageExploreActivity,
    ViewEmiPlanClickEvent,
    DownPaymentPopupViewEvent,
    DownPaymentPopupClosedEvent,
    SuperCoachingPurchaseModalViewed,
    SupercoachingEmiProceededToPayment,
    EmiMandateSuccess,
    EnableAutoEmiPaymentClicked,
    EmiMandateInformationCollected,
    StudyPlanClickEvent,
    StudyPlanGeneratedEvent,
    PypChildPageExploreActivityEvent,
    BlockerPopupViewedEvent,
    EmiInfoScreenVisitedEvent,
    NPSFeedbackEvent,
    PassWrappedClickEvent,
    PassWrappedViewPageEvent,
    ScholarshipTestClickEvent,
    AddressSubmittedEvent,
    BookOrdersClicked,
    PlutusEnquiryFormFilled,
    PlutusDownloadBrochureClicked,
    PlutusCourseClicked,
    PlutusMiniCourseClicked,
    PlutusCallBackRequested,
    PlutusJoinWebinarClicked,
    QRCodeScanned,
    AmountEnteredEvent,
    ProceedToPay,
    NumberEntered,
    UnlockPassPro,
    OrderStatus
} from '../../shared/utility/ga-events'
import { Store } from '@ngrx/store';
import {first, take} from 'rxjs/operators';
import {getPageText, getPageType, isServer, setLocalStorage} from "@shared/utility/tb-common";
import {TestSeries} from "@models/entity/test-series/test-series.adapter";
import {_Test} from "@models/entity/targets/target.test.adapter";
import {
    FIRE_DYNAMIC_EVENT,
    reverseDynamicEventMap,
    TRANSACTION_LS_KEY
} from '@angular-commons/shared/utility/constants';
import { PlatformService } from '@angular-commons/core/services/platform-service';
import {getLocalStorage,removeLocalStorage} from '@angular-commons/shared/utility/tb-common';
import {clientOnly} from "@shared/utility/platform-decorators";
import {ActivatedRoute} from "@angular/router";
import { StudentService } from './student.service';
import { capitalize } from '@angular-commons/shared/utility/string-utilities';

declare var window:any;
@Injectable({
    providedIn: 'root',
})
export class GAEventService {
    eventsQueue = []
    constructor(private store: Store<{ app: boolean }>, private route: ActivatedRoute,private studentService:StudentService, private platformService: PlatformService) {
        if(!isServer()){
            window["pushToDataLayer"]=(obj)=>{
                this.pushToDataLayer(obj);
            };
            this.pushFromLocalStorage();
        }
    }

    async userTypeData(){
        let studentData;
        if(!isServer() && this.platformService.isLoggedIn()){
            studentData = await this.studentService.loadStudent(true);
            return studentData.userType.join(',');
        }
        else{
            return 'free';
        }
    }

    @clientOnly()
    pushToDataLayer(obj,isPageInfo=false) {
      obj["projectSource"] = this.platformService?.currentProjectName();
        this.route.queryParams.pipe(take(1)).subscribe(params => {
            const excludeDynamicEventList = params?.excludeEvent?.split(',');
            let eventId = reverseDynamicEventMap[obj.event];
            if(!excludeDynamicEventList?.includes(eventId)){
                try {
                    (window as any).top.gaEventService?.pushToDataLayer(obj, isPageInfo);
                }
                catch(e) {
                    (window as any).gaEventService?.pushToDataLayer(obj, isPageInfo);
                }
            }
        });
    }

    @clientOnly()
    pushFromLocalStorage(){
        // we don't want iframes to try to push ( because we do not want double events )
        if(window.top !== window.self){ return; }

        // read and clear the LS so that only current page instance can push
        var currentLocalStorageData;
        try{
            currentLocalStorageData  = JSON.parse(getLocalStorage("lsDatalayer") || '[]');
            setLocalStorage("lsDatalayer",JSON.stringify([]));
        } catch(e){ console.error(e); }

        // if there were any events found we fire them now
        if(currentLocalStorageData && currentLocalStorageData.length){
            currentLocalStorageData.forEach(event => {
                this.pushToDataLayer(event);
            });
        }
    }

    @clientOnly()
    pushToLocalStorage(event:any){
        var currentLocalStorageData;
        try{
            currentLocalStorageData  = JSON.parse(getLocalStorage("lsDatalayer") || '[]');
        } catch(e){ console.error(e); }

        if(!currentLocalStorageData){
            currentLocalStorageData = [];
        }
        currentLocalStorageData.push(event);
        try{
            setLocalStorage("lsDatalayer",JSON.stringify(currentLocalStorageData));
        } catch(e){ console.error(e); }
    }

    capitalizeWords(str) {
        return str.replace(/\w\S*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    trimSpaces(str) {
        return str.replace(/ /g,'');
    }

    populateDoubtDetails(eventInfo : object) {
        let doubtsMap : any;
        let doubtsMap$ = this.store.select((state:any) => {return ((state && state.doubts && state.doubts.doubtsMap) || [] )})
        let subjectMap : any;
        let subjectMap$ = this.store.select((state:any) => {return ((state && state.doubts && state.doubts.subjectMap) || [] )})
        if (doubtsMap$) {
            doubtsMap$.pipe(first()).subscribe( (doubts) => {
                if(doubts) {
                    doubtsMap = doubts;
                }
            })
        }
        if (subjectMap$) {
            subjectMap$.pipe(first()).subscribe( (value) => {
                if(value) {
                    subjectMap = value;
                }
            })
        }

        let fetchedEventInfo = {};
        const currentDoubt  = doubtsMap[eventInfo['doubtId']];
        if (currentDoubt) {
            let rawDoubtSubject;
            if (currentDoubt['subjectId'] && subjectMap[currentDoubt['subjectId']] && !eventInfo['doubtSubject']) {
                rawDoubtSubject = subjectMap[currentDoubt['subjectId']]['name'];
                rawDoubtSubject = this.trimSpaces(rawDoubtSubject);
                fetchedEventInfo['doubtSubject'] = rawDoubtSubject;
            }
            else if (currentDoubt['tags'] && currentDoubt['tags'].length > 0) {
                if (!eventInfo['doubtSubject']) {
                    for (let tag of currentDoubt['tags']) {
                        if (tag['type'] === "subject") {
                            rawDoubtSubject = tag['name'];
                            break;
                        }
                        else if (!rawDoubtSubject) {
                            rawDoubtSubject = tag['name'];
                        }
                    }
                    rawDoubtSubject = this.capitalizeWords(rawDoubtSubject);
                    rawDoubtSubject = this.trimSpaces(rawDoubtSubject);
                    fetchedEventInfo['doubtSubject'] = rawDoubtSubject;
                }
                if (!eventInfo['module']) {
                    fetchedEventInfo['module'] = 'GlobalDoubtsAndDiscussion';
                }
            }
        }
        if (!eventInfo['page']) {
            fetchedEventInfo['page'] = getPageText();
        }
        let finalEventInfo = {...fetchedEventInfo, ...eventInfo}
        return finalEventInfo;
    }

    sendAddedToMyDoubtsEvent(eventInfo:object) {
        let finalEventInfo = this.populateDoubtDetails(eventInfo);
        const addedToMyDoubtsEvent = new AddedToMyDoubtsEvent(finalEventInfo);
        this.pushToDataLayer(addedToMyDoubtsEvent);
    }

    sendRemovedFromMyDoubtsEvent(eventInfo:object) {
        let finalEventInfo = this.populateDoubtDetails(eventInfo);
        const removedFromMyDoubtsEvent = new RemovedFromMyDoubtsEvent(finalEventInfo);
        this.pushToDataLayer(removedFromMyDoubtsEvent);
    }

    sendReportADoubtButtonClickedEvent(eventInfo:object) {
        let finalEventInfo = this.populateDoubtDetails(eventInfo);
        const reportADoubtButtonClickedEvent = new ReportADoubtButtonClickedEvent(finalEventInfo);
        this.pushToDataLayer(reportADoubtButtonClickedEvent);
    }

    sendReportedTheDoubtSuccesfully(eventInfo:object) {
        let finalEventInfo = this.populateDoubtDetails(eventInfo);
        const reportedTheDoubtSuccessfullyEvent = new ReportedTheDoubtSuccessfullyEvent(finalEventInfo);
        this.pushToDataLayer(reportedTheDoubtSuccessfullyEvent);
    }

    sendPostAnswerButtonClickedEvent(eventInfo:object) {
        let finalEventInfo = this.populateDoubtDetails(eventInfo);
        const postAnswerButtonClickedEvent = new PostAnswerButtonClickedEvent(finalEventInfo);
        this.pushToDataLayer(postAnswerButtonClickedEvent);
    }

    sendDoubtUpvoteEvent(eventInfo:object) {
        let finalEventInfo = this.populateDoubtDetails(eventInfo);
        const doubtUpvoteEvent = new DoubtUpvoteEvent(finalEventInfo);
        this.pushToDataLayer(doubtUpvoteEvent);
    }

    sendEntitySearchedEvent(eventInfo:object) {

        let fetchedEventInfo = {};
        if (!eventInfo['courseName']) {
            let course$ = this.store.select((state: any) => {return ((state && state.courses && state.courses.courseEntity && state.courses.courseEntity.course ) || {} )})
            if (course$) {
                course$.pipe(first()).subscribe( (course) => {
                    if(course && course.title) {
                        fetchedEventInfo['courseName'] = course.title;
                    }
                })
            }
        }
        if (!eventInfo['page']) {
            fetchedEventInfo['page'] = getPageText();
        }
        let finalEventInfo = {...fetchedEventInfo, ...eventInfo};
        const entitySearchedEvent = new EntitySearchedEvent(finalEventInfo);
        this.pushToDataLayer(entitySearchedEvent);
    }

    sendEntityClickedEvent(eventInfo:object) {

        let fetchedEventInfo = {};
        if (!eventInfo['courseName']) {
            let course$ = this.store.select((state: any) => {return ((state && state.courses && state.courses.courseEntity && state.courses.courseEntity.course ) || {} )})
            if (course$) {
                course$.pipe(first()).subscribe( (course) => {
                    if(course && course.title) {
                        fetchedEventInfo['courseName'] = course.title;
                    }
                })
            }
        }
        if (!eventInfo['entityName'] || !eventInfo['wasLive']) {
            let entity$ = this.store.select((state: any) => {return ((state && state.courses && state.courses.courseEntity && state.courses.courseEntity.entity ) || {} )});
            if (entity$) {
                entity$.pipe(first()).subscribe( (entityObj) => {
                    if (entityObj) {
                        if (!eventInfo['entityName'] && entityObj['title']) {
                            fetchedEventInfo['entityName'] = entityObj['title'];
                        }
                        if (!eventInfo['wasLive']) {
                            fetchedEventInfo['wasLive'] = (entityObj['type'] && entityObj['type'] === 'Live Class') ? true : false;
                        }
                    }
                })
            }
        }
        if (!eventInfo['page']) {
            fetchedEventInfo['page'] = getPageText();
        }
        let finalEventInfo = {...fetchedEventInfo, ...eventInfo};
        const entityClickedEvent = new EntityClickedEvent(finalEventInfo);
        this.pushToDataLayer(entityClickedEvent);
    }

    sendNotesAddedSuccessEvent(eventInfo:object) {

        let fetchedEventInfo = {};
        if (!eventInfo['courseName']) {
            let course$ = this.store.select((state: any) => {return ((state && state.courses && state.courses.courseEntity && state.courses.courseEntity.course ) || {} )})
            if (course$) {
                course$.pipe(first()).subscribe( (course) => {
                    if(course && course.title) {
                        fetchedEventInfo['courseName'] = course.title;
                    }
                })
            }
        }
        if (!eventInfo['page']) {
            fetchedEventInfo['page'] = getPageText();
        }
        let finalEventInfo = {...fetchedEventInfo, ...eventInfo};
        const notesAddedSuccessEvent = new NotesAddedSuccessEvent(finalEventInfo);
        this.pushToDataLayer(notesAddedSuccessEvent);
    }

    sendModalViewedEvent(eventInfo:object) {

        let fetchedEventInfo = {};
        if (!eventInfo['page']) {
            fetchedEventInfo['page'] = getPageText();
        }
        let finalEventInfo = {...fetchedEventInfo, ...eventInfo};
        const modalViewedEvent = new ModalViewedEvent(finalEventInfo);
        this.pushToDataLayer(modalViewedEvent);
    }

    sendExamPreferenceSelectedEvent(eventInfo:object) {
        let fetchedEventInfo = {};
        if (!eventInfo['page']) {
            fetchedEventInfo['page'] = getPageText();
        }
        let finalEventInfo = {...fetchedEventInfo, ...eventInfo};
        const examPreferenceSelectedEvent = new ExamPreferenceSelectedEvent(finalEventInfo);
        this.pushToDataLayer(examPreferenceSelectedEvent);
    }

    sendDemoModuleViewedEvent(eventInfo:object,fromLesson=false) {
        let fetchedEventInfo = {};
        if (!eventInfo['page']) {
            fetchedEventInfo['page'] = getPageText();
        }
        let finalEventInfo = {...fetchedEventInfo, ...eventInfo};
        const demoModuleViewedEvent = new DemoModuleViewedEvent(finalEventInfo);
        if(fromLesson){
            this.pushToDataLayer(demoModuleViewedEvent);
        }else{
        this.pushToDataLayer(demoModuleViewedEvent);
        }
    }

    sendInterestedInLearnCourseEvent(eventInfo:object) {
        let fetchedEventInfo = {};
        if (!eventInfo['page']) {
            fetchedEventInfo['page'] = getPageText();
        }
        let finalEventInfo = {...fetchedEventInfo, ...eventInfo};
        const interestedInLearnCourseEvent = new InterestedInLearnCourseEvent(finalEventInfo);
        this.pushToDataLayer(interestedInLearnCourseEvent);
    }

    sendInterestedInSelectCourseEvent(eventInfo:object) {
        let fetchedEventInfo = {};
        if (!eventInfo['page']) {
            fetchedEventInfo['page'] = getPageText();
        }
        let finalEventInfo = {...fetchedEventInfo, ...eventInfo};
        const interestedInSelectCourseEvent = new InterestedInSelectCourseEvent(finalEventInfo);
        this.pushToDataLayer(interestedInSelectCourseEvent);
    }

    sendLearnCoursePageVisitedEvent(eventInfo:object) {
        let fetchedEventInfo = {};
        if (!eventInfo['page']) {
            fetchedEventInfo['page'] = getPageText();
        }
        let finalEventInfo = {...fetchedEventInfo, ...eventInfo};
        const learnCoursePageVisitedEvent = new LearnCoursePageVisitedEvent(finalEventInfo);
        this.pushToDataLayer(learnCoursePageVisitedEvent);
    }

    sendSelectCoursePageVisitedEvent(eventInfo:object) {
        let fetchedEventInfo = {};
        if (!eventInfo['page']) {
            fetchedEventInfo['page'] = getPageText();
        }
        let finalEventInfo = {...fetchedEventInfo, ...eventInfo};
        const selectCoursePageVisitedEvent = new SelectCoursePageVisitedEvent(finalEventInfo);
        this.pushToDataLayer(selectCoursePageVisitedEvent);
    }

    sendBannerImpressionEvent(eventInfo:object) {
        let fetchedEventInfo = {};
        let finalEventInfo = {...fetchedEventInfo, ...eventInfo};
        const bannerImpressionEvent = new BannerImpressionEvent(finalEventInfo);
        this.pushToDataLayer(bannerImpressionEvent);
    }

    sendBannerClickedEvent(eventInfo:object) {
        let fetchedEventInfo = {};
        let finalEventInfo = {...fetchedEventInfo, ...eventInfo};
        const bannerClickedEvent = new BannerClickedEvent(finalEventInfo);
        this.pushToDataLayer(bannerClickedEvent);
    }

    sendTargetLoadEvent(eventInfo: object) {
        const targetLoadEvent = new TargetPageLoadEvent(eventInfo);
        this.pushToDataLayer(targetLoadEvent);
    }

    sendTargetPageNavigationEvent(eventInfo: object) {
        const targetNavigationInfo = new TargetPageNavigationEvent(eventInfo);
        this.pushToDataLayer(targetNavigationInfo);
    }
    sendSkillAcademyProgramPageNavigationEvent(eventInfo: object) {
        const skillAcademyProgramPageNavigationEvent = new SkillAcademyProgramPageNavigationEvent(eventInfo);
        this.pushToDataLayer(skillAcademyProgramPageNavigationEvent);
    }
    sendSkillAcademyProgramPageCallbackRequestEvent(eventInfo: object) {
        const skillAcademyProgramPageCallbackRequestEvent = new SkillAcademyProgramPageCallbackRequestEvent(eventInfo);
        this.pushToDataLayer(skillAcademyProgramPageCallbackRequestEvent);
    }
    sendCouponUsedEvent(eventInfo:object) {
        let fetchedEventInfo = {};
        if (!eventInfo['page']) {
            fetchedEventInfo['page'] = getPageText();
        }
        let finalEventInfo = {...fetchedEventInfo, ...eventInfo};
        const couponUsedEvent = new CouponUsedEvent(finalEventInfo);
        this.pushToDataLayer(couponUsedEvent);
    }

    sendCurriculumDownloadedEvent(eventInfo:object) {
        let fetchedEventInfo = {};
        if (!eventInfo['page']) {
            fetchedEventInfo['page'] = getPageText();
        }
        let finalEventInfo = {...fetchedEventInfo, ...eventInfo};
        const curriculumDownloadedEvent = new CurriculumDownloadedEvent(finalEventInfo);
        this.pushToDataLayer(curriculumDownloadedEvent);
    }

    sendSelectionProofDownloadedEvent(eventInfo:object) {
        let fetchedEventInfo = {};
        let finalEventInfo = {...fetchedEventInfo, ...eventInfo};
        const selectionProofDownloadedEvent = new SelectionProofDownloadedEvent(finalEventInfo);
        this.pushToDataLayer(selectionProofDownloadedEvent);
    }

    sendSelectionSubmissionEvent(eventInfo:object){
        let fetchedEventInfo = {};
        let finalEventInfo = {...fetchedEventInfo, ...eventInfo};
        const selectionsSubmissionEvent = new SelectionsSubmissionEvent(finalEventInfo);
        this.pushToDataLayer(selectionsSubmissionEvent);
    }

    sendLeadGeneratedEvent(eventInfo:object) {
        let fetchedEventInfo = {};
        if (!eventInfo['page']) {
            fetchedEventInfo['page'] = getPageText();
        }
        let finalEventInfo = {...fetchedEventInfo, ...eventInfo};
        const leadGeneratedEvent = new LeadGeneratedEvent(finalEventInfo);
        this.pushToDataLayer(leadGeneratedEvent);
    }

    sendMasterClassRemindMeEvent(eventInfo:any){
        const masterClassRemindMeEvent = new MasterClassRemindMeEvent(eventInfo);
        this.pushToDataLayer(masterClassRemindMeEvent);
    }

    sendMasterClassSeriesJoinedEvent(eventInfo:any){
        const masterClassSeriesJoinedEvent = new MasterClassSeriesJoinedEvent(eventInfo)
        this.pushToDataLayer(masterClassSeriesJoinedEvent)
    }

    sendMasterClassSeriesVisitedEvent(eventInfo:any){
        const masterClassSeriesVisitedEvent = new MasterClassSeriesVisitedEvent(eventInfo);
        this.pushToDataLayer(masterClassSeriesVisitedEvent);
    }

    sendMasterClassLessonVisitedEvent(eventInfo:any){
        const masterClassLessonVisitedEvent = new MasterClassLessonVisitedEvent(eventInfo);
        this.pushToDataLayer(masterClassLessonVisitedEvent);
    }

    sendMasterClassScreenshotCapturedEvent(eventInfo:any){
        const masterClassScreenshotCapturedEvent = new MasterClassScreenshotCapturedEvent(eventInfo)
        this.pushToDataLayer(masterClassScreenshotCapturedEvent);
    }

    sendMasterClassLessonStarted(eventInfo:any){
        const masterClassLessonStarted = new MasterClassLessonStarted(eventInfo)
        this.pushToDataLayer(masterClassLessonStarted);
    }

    sendMasterClassLessonCompleted(eventInfo:any){
        const masterClassLessonCompleted = new MasterClassLessonCompleted(eventInfo)
        this.pushToDataLayer(masterClassLessonCompleted);
    }

    sendMasterClassLessonLeft(eventInfo:any){
        const masterClassLessonLeft = new MasterClassLessonLeft(eventInfo)
        this.pushToDataLayer(masterClassLessonLeft);
    }

    sendMasterClassVideoCompleted(eventInfo:any){
        const masterClassVideoCompleted = new MasterClassVideoCompleted(eventInfo)
        this.pushToDataLayer(masterClassVideoCompleted);
    }

    sendMasterClassVideoStarted(eventInfo:any){
        const masterClassVideoStarted = new MasterClassVideoStarted(eventInfo)
        this.pushToDataLayer(masterClassVideoStarted);
    }

    sendMasterClassPracticeStarted(eventInfo:any){
        const masterClassPracticeStarted = new MasterClassPracticeStarted(eventInfo)
        this.pushToDataLayer(masterClassPracticeStarted);
    }

    sendMasterClassNotesRead(eventInfo:any){
        const masterClassnotesRead = new MasterClassNotesRead(eventInfo)
        this.pushToDataLayer(masterClassnotesRead);
    }

    sendMasterClassExploreActivity(eventInfo:any){
        const masterClassExploreActivity = new MasterClassExploreActivity(eventInfo)
        this.pushToDataLayer(masterClassExploreActivity);
    }

    sendLearnCourseEnrollNowEvent(eventInfo:object) {
        let fetchedEventInfo = {};
        if (!eventInfo['page']) {
            fetchedEventInfo['page'] = getPageText();
        }
        let finalEventInfo = {...fetchedEventInfo, ...eventInfo};
        const learnCourseEnrollNowEvent = new LearnCourseEnrollNowEvent(finalEventInfo);
        this.pushToDataLayer(learnCourseEnrollNowEvent);
    }

    sendSelectCourseEnrollNowEvent(eventInfo:object) {
        let fetchedEventInfo = {};
        if (!eventInfo['page']) {
            fetchedEventInfo['page'] = getPageText();
        }
        let finalEventInfo = {...fetchedEventInfo, ...eventInfo};
        const selectCourseEnrollNowEvent = new SelectCourseEnrollNowEvent(finalEventInfo);
        this.pushToDataLayer(selectCourseEnrollNowEvent);
    }

    sendRequestedCallbackEvent(eventInfo:object) {
        let fetchedEventInfo = {};
        if (!eventInfo['page']) {
            fetchedEventInfo['page'] = getPageText();
        }
        let finalEventInfo = {...fetchedEventInfo, ...eventInfo};
        const requestedCallbackEvent = new RequestedCallbackEvent(finalEventInfo);
        this.pushToDataLayer(requestedCallbackEvent);
    }

    sendTransactedEvent(eventInfo:object) {
        const transcatedEvent = new Transacted(eventInfo);
        this.pushToDataLayer(transcatedEvent);
    }

    sendPurchasedEvent(eventInfo:object) {
        const purchasedEvent = new Purchased(eventInfo);
        this.pushToDataLayer(purchasedEvent);
    }

    sendTransactionFailedEvent(eventInfo:object) {
        const transactionFailed = new TransactionFailed(eventInfo);
        this.pushToDataLayer(transactionFailed);
    }

    async sendPypPdfViewedEvent(eventInfo: object) {
        const userType = await this.userTypeData();
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true) + this.platformService.getQueryString(true),
            'userType': userType
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const pypPdfViewedEvent = new PypPdfViewedEvent(finalEventInfo);
        this.pushToDataLayer(pypPdfViewedEvent);
    }

    sendPdfDownloadEvent(eventInfo: object){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true) + this.platformService.getQueryString(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn()
        };
        const pdfDownloadEvent = new PdfDownloadEvent({...defaultEventInfo, ...eventInfo});
        this.pushToDataLayer(pdfDownloadEvent);
    }
    async sendPypPdfDownloadEvent(eventInfo: object) {
        const userType = await this.userTypeData();
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true) + this.platformService.getQueryString(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
            'userType': userType
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const pypPdfDownloadEvent = new PypPdfDownloadEvent(finalEventInfo);
        this.pushToDataLayer(pypPdfDownloadEvent);
    }

    sendPypPdfSharedEvent(eventInfo: object) {
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true) + this.platformService.getQueryString(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const pypPdfSharedEvent = new PypPdfSharedEvent(finalEventInfo);
        this.pushToDataLayer(pypPdfSharedEvent);
    }

    sendPypPdfLanguageChangedEvent(eventInfo: object) {
        const pypPdfLanguageChangedEvent = new PypPdfLanguageChangedEvent(eventInfo);
        this.pushToDataLayer(pypPdfLanguageChangedEvent);
    }

    sendPypPassAlertEvent(eventInfo: object) {
        const pypPassAlertEvent = new PypPassAlertEvent(eventInfo);
        this.pushToDataLayer(pypPassAlertEvent);
    }

    sendPypFiltersAppliedEvent(eventInfo: object) {
        const pypFiltersAppliedEvent = new PypFiltersAppliedEvent(eventInfo);
        this.pushToDataLayer(pypFiltersAppliedEvent);
    }

    sendTestSeriesVisitedEvent(eventInfo: any) {
        const testSeriesVisitedEvent = new TestSeriesVisitEvent(eventInfo.testSeries);
        testSeriesVisitedEvent.info.page = eventInfo.dlPage;
        testSeriesVisitedEvent.info.isLoggedIn = eventInfo.isLoggedIn;
        this.pushToDataLayer(testSeriesVisitedEvent);
    }

    sendInterestedInTestSeriesEvent(eventInfo: any) {
        const testSeriesInterestedEvent = new InterestedInTestSeriesEvent(eventInfo.testSeries);
        testSeriesInterestedEvent.info.page = eventInfo.dlPage || getPageText();
        testSeriesInterestedEvent.info.pagePath = this.platformService.getPathName(true),
        testSeriesInterestedEvent.info.pageType = getPageType(),
        testSeriesInterestedEvent.info.isLoggedIn = eventInfo.isLoggedIn || this.platformService.isLoggedIn();
        testSeriesInterestedEvent.info.module = eventInfo.module;
        this.pushToDataLayer(testSeriesInterestedEvent);
    }

    sendInterestedInPassesEvent(eventInfo: any) {
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        let interestedInPassesEvent = new InterestedInPasses(finalEventInfo);
        this.pushToDataLayer(interestedInPassesEvent);
    }

    sendProductIds(eventInfo:any){
        let sendProductIds=new SendProductIds(eventInfo);
        this.pushToDataLayer(sendProductIds);
    }

    sendInterestedInPassEvent(eventInfo: any) {
        let defaultEventInfo = {
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
        };
        if (!eventInfo.page) {
            defaultEventInfo['page'] = getPageText();
        }
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const interestedInPassEvent = new InterestedInPass(finalEventInfo);
        this.pushToDataLayer(interestedInPassEvent);
    }

    sendEnrolledInTestSeriesEvent(testSeriesDetails: TestSeries) {
        let enrolledEvt = new EnrolledInTestSeries(testSeriesDetails);
        enrolledEvt.info.page = getPageText();
        enrolledEvt.info.pagePath = this.platformService.getPathName(true);

        this.pushToDataLayer(enrolledEvt);
    }

    sendRegisteredInTestEvent(test: _Test) {
        let evt = new TestRegistered(test);
        evt.info.page = getPageText();
        evt.info.pagePath = this.platformService.getPathName(true);
        evt.info.pageType = getPageType();
        evt.info.isLoggedIn = this.platformService.isLoggedIn();
        evt.info.language = capitalize(this.platformService.getSiteLang());

        this.pushToDataLayer(evt);
    }

    sendScholarshipPageVisitedEvent(eventInfo:object) {
        let defaultEventInfo = {
            'page':getPageText()
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const scholarshipPageVisitedEvent= new ScholarshipPageVisitedEvent(finalEventInfo);
        this.pushToDataLayer(scholarshipPageVisitedEvent);
    }

    sendRegisterTestEvent(eventInfo:object){
        let defaultEventInfo = {
            'page':getPageText(),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const registerTestEvent= new RegisterTestEvent(finalEventInfo);
        this.pushToDataLayer(registerTestEvent);
    }

    sendLivePanelActionEvent(eventInfo:object){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'tab' : window.location.href.includes('attempted') ? 'Attempted' : 'Daily Live'
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const livePanelActionEvent= new LivePanelActionEvent(finalEventInfo);
        this.pushToDataLayer(livePanelActionEvent);
    }

    sendCategoryClickedEvent(eventInfo:object){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const categoryClickedEvent= new CategoryClickedEvent(finalEventInfo);
        this.pushToDataLayer(categoryClickedEvent);
    }
    sendOnboardingTargetRemovedEvent(eventInfo:object) {
        let defaultEventInfo = {
            'page':getPageText(),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const onboardingTargetRemovedEvent= new OnboardingTargetRemovedEvent(finalEventInfo);
        this.pushToDataLayer(onboardingTargetRemovedEvent);
    }

    sendOnboardingSelectionTabEvent(eventInfo:object){
        const onboardingSelectionTabEvent= new OnboardingSelectionTabEvent(eventInfo);
        this.pushToDataLayer(onboardingSelectionTabEvent);
    }

    sendOnboardingTargetSearchedSuccessEvent(eventInfo:object) {
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const onboardingTargetSearchedSuccessEvent= new OnboardingTargetSearchedSuccessEvent(finalEventInfo);
        this.pushToDataLayer(onboardingTargetSearchedSuccessEvent);
    }

    sendOnboardingTargetSelectedEvent(eventInfo:object) {
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const onboardingTargetSelectedEvent= new OnboardingTargetSelectedEvent(finalEventInfo);
        this.pushToDataLayer(onboardingTargetSelectedEvent);
    }

    sendOnboardingTargetSelectedCompletedEvent(eventInfo:object) {
        let defaultEventInfo = {
            'page':getPageText(),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const onboardingTargetSelectedCompletedEvent= new OnboardingTargetSelectedCompletedEvent(finalEventInfo);
        this.pushToDataLayer(onboardingTargetSelectedCompletedEvent);
    }

    sendOnboardingTargetSearchedEvent(eventInfo:object) {
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const onboardingTargetSearchedEvent= new OnboardingTargetSearchedEvent(finalEventInfo);
        this.pushToDataLayer(onboardingTargetSearchedEvent);
    }

     sendLivePanelUserClick(eventInfo:object){
        const livePanelUserClick = new LivePanelUserClick(eventInfo);
        this.pushToDataLayer(livePanelUserClick);
    }

    sendSupercoachingVideoStarted(eventInfo:object){
        let defaultEventInfo = {
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        this.pushToDataLayer(new SupercoachingVideoStarted(finalEventInfo));
    }
    sendSupercoachingVideoResumed(eventInfo:object){
        let defaultEventInfo = {
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        this.pushToDataLayer(new SupercoachingVideoResumed(finalEventInfo));
    }
    sendSupercoachingVideoCompleted(eventInfo:object){
        let defaultEventInfo = {
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        this.pushToDataLayer(new SupercoachingVideoCompleted(finalEventInfo));
    }

    sendSupercoachingNotesRead(eventInfo:object){
        this.pushToDataLayer(new SupercoachingNotesRead(eventInfo));
    }
    sendSupercoachingSearch(eventInfo:object){
        this.pushToDataLayer(new SupercoachingSearch(eventInfo));
    }

    sendSupercoachingIndividualPageActivity(eventInfo:object){
        let defaultEventInfo = {
            'pagePath':this.platformService.getPathName(true),
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        this.pushToDataLayer(new SupercoachingIndividualPageActivity(finalEventInfo));
    }

    sendSupercoachingPracticeStarted(eventInfo:object){
        let defaultEventInfo = {
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        this.pushToDataLayer(new SupercoachingPracticeStarted(finalEventInfo));
    }

    sendSupercoachingPracticeResumed(eventInfo:object){
        let defaultEventInfo = {
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        this.pushToDataLayer(new SupercoachingPracticeResumed(finalEventInfo));
    }

    sendSupercoachingPracticeCompleted(eventInfo:object){
        let defaultEventInfo = {
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        this.pushToDataLayer(new SupercoachingPracticeCompleted(finalEventInfo));
    }

    async sendStudyPracticeStarted(eventInfo:object){
        let userType = await this.userTypeData();
        let defaultEventInfo = {
            'pagePath':this.platformService.getPathName(true),
            'page':getPageText(),
            userType
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        this.pushToDataLayer(new StudyPracticeStarted(finalEventInfo));
    }

    async sendStudyPracticeResumed(eventInfo:object){
        let userType = await this.userTypeData();
        let defaultEventInfo = {
            'pagePath':this.platformService.getPathName(true),
            'page':getPageText(),
            userType
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        this.pushToDataLayer(new StudyPracticeResumed(finalEventInfo));
    }

    async sendStudyPracticeCompleted(eventInfo:object){
        let userType = await this.userTypeData();
        let defaultEventInfo = {
            'pagePath':this.platformService.getPathName(true),
            'page':getPageText(),
            userType
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        this.pushToDataLayer(new StudyPracticeCompleted(finalEventInfo));
    }

    sendSupercoachingTestStarted(eventInfo:object){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        this.pushToDataLayer(new SupercoachingTestStarted(finalEventInfo));
    }

    sendHomePageUserClick(eventInfo:object){
         let defaultEventInfo = {
            'pagePath':this.platformService.getPathName(true),
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const homePageUserClick = new HomePageUserClick(finalEventInfo);
        this.pushToDataLayer(homePageUserClick);
    }

    sendhomePageBannerNavigationClick(eventInfo:object){
         let defaultEventInfo = {
            'pagePath':this.platformService.getPathName(true),
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const homePageBannerNavigationClick = new HomePageBannerViewPromotion(finalEventInfo);
        this.pushToDataLayer(homePageBannerNavigationClick);
    }

    sendReferralInvited(eventInfo:object){
         let defaultEventInfo = {
            'pagePath':this.platformService.getPathName(true),
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const referralInvited = new ReferralInvited(finalEventInfo);
        this.pushToDataLayer(referralInvited);
    }

    sendReferralApplied(eventInfo:object){
         let defaultEventInfo = {
            'pagePath':this.platformService.getPathName(true),
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const referralApplied = new ReferralApplied(finalEventInfo);
        this.pushToDataLayer(referralApplied);
    }

    sendHomePageBannerSelected(eventInfo:object){
         let defaultEventInfo = {
            'pagePath':this.platformService.getPathName(true),
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const homePageBannerSelected = new HomePageBannerSelectPromotion(finalEventInfo);
        this.pushToDataLayer(homePageBannerSelected);
    }

    sendSupercoachingExploreActivity(eventInfo:object){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
       const supercoachingExploreActivity = new SupercoachingExploreActivity(finalEventInfo);
       this.pushToDataLayer(supercoachingExploreActivity);
   }

   sendSpecificSuperteacherExploreActivity(eventInfo:object){
    let defaultEventInfo = {
        'page':getPageText(),
        'pagePath':this.platformService.getPathName(true)
    };
    let finalEventInfo = {...defaultEventInfo, ...eventInfo};
   const specificSuperteacherExploreActivity = new SpecificSuperteacherExploreActivity(finalEventInfo);
   this.pushToDataLayer(specificSuperteacherExploreActivity);
}

sendGlobalSuperteacherExploreActivity(eventInfo:object){
    let defaultEventInfo = {
        'page':getPageText(),
        'pagePath':this.platformService.getPathName(true)
    };
    let finalEventInfo = {...defaultEventInfo, ...eventInfo};
   const globalSuperteacherExploreActivity = new GlobalSuperteacherExploreActivity(finalEventInfo);
   this.pushToDataLayer(globalSuperteacherExploreActivity);
}

sendSupercoachingCourseExplored (eventInfo:object){
    let defaultEventInfo = {
        'page':getPageText(),
        'pagePath':this.platformService.getPathName(true)
    };
    let finalEventInfo = {...defaultEventInfo, ...eventInfo};
   const supercoachingCourseExplored  = new SupercoachingCourseExplored(finalEventInfo);
   this.pushToDataLayer(supercoachingCourseExplored );
}

   sendSupercoachingCallbackRequested(eventInfo:object){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const supercoachingCallbackRequested = new SupercoachingCallbackRequested(finalEventInfo);
        this.pushToDataLayer(supercoachingCallbackRequested);
   }

   sendSupercoachingApplyCouponClicked(eventInfo:object){
    let defaultEventInfo = {
        'page':getPageText(),
        'pagePath':this.platformService.getPathName(true)
    };
    let finalEventInfo = {...defaultEventInfo, ...eventInfo};
    const supercoachingApplyCouponClicked = new SupercoachingApplyCouponClicked(finalEventInfo);
    this.pushToDataLayer(supercoachingApplyCouponClicked);
    }

   sendSupercoachingProceededToPayment(eventInfo:object){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const supercoachingProceededToPayment = new SupercoachingProceededToPayment(finalEventInfo);
        this.pushToDataLayer(supercoachingProceededToPayment);
    }

    sendSupercoachingGoalPageVisited(eventInfo:object){
        let defaultEventInfo = {
            'page':getPageText(),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const supercoachingGoalPageVisited = new SupercoachingGoalPageVisited(finalEventInfo);
        this.pushToDataLayer(supercoachingGoalPageVisited);
    }

    sendSupercoachingEntityExplore(eventInfo:object){
        let defaultEventInfo = {
            'page':getPageText(),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const supercoachingEntityExplore = new SupercoachingEntityExplore(finalEventInfo);
        this.pushToDataLayer(supercoachingEntityExplore);
    }


    sendSupercoachingLiveClassClicked(eventInfo:object) {
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const supercoachingLiveClassClicked = new SupercoachingLiveClassClicked(finalEventInfo);
        this.pushToDataLayer(supercoachingLiveClassClicked);
    }

    sendSupercoachingCourseCurriculumViewed(eventInfo:object) {
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const supercoachingCourseCurriculumViewed = new SupercoachingCourseCurriculumViewed(finalEventInfo);
        this.pushToDataLayer(supercoachingCourseCurriculumViewed);
    }

    sendSupercoachingTestSeriesClicked(eventInfo:object) {
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const supercoachingTestSeriesClicked = new SupercoachingTestSeriesClicked(finalEventInfo);
        this.pushToDataLayer(supercoachingTestSeriesClicked);
    }

    sendSupercoachingPurchaseScreenVisited(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        let supercoachingPurchaseScreenVisited = new SupercoachingPurchaseScreenVisited(finalEventInfo)
        this.pushToDataLayer(supercoachingPurchaseScreenVisited);
    }

    sendSupercoachingBrochureDownloaded(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const supercoachingBrochureDownloaded = new SupercoachingBrochureDownloaded(finalEventInfo)
        this.pushToDataLayer(supercoachingBrochureDownloaded);
    }

    sendSupercoachingNotesDownloaded(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const supercoachingNotesDownloaded = new SupercoachingNotesDownloaded(finalEventInfo)
        this.pushToDataLayer(supercoachingNotesDownloaded);
    }

    sendSupercoachingCoursePageVisited(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const supercoachingcoursePageVisited = new SupercoachingCoursePageVisited(finalEventInfo);
        this.pushToDataLayer(supercoachingcoursePageVisited);
    }

    sendSupercoachingFreeLessonViewed(eventInfo:object){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const supercoachingFreeLessonViewed = new SupercoachingFreeLessonViewed(finalEventInfo);
        this.pushToDataLayer(supercoachingFreeLessonViewed);
    }

    sendSupercoachingTeacherPageVisited(eventInfo:object){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const supercoachingTeacherPageVisited = new SupercoachingTeacherPageVisited(finalEventInfo);
        this.pushToDataLayer(supercoachingTeacherPageVisited);
    }

    sendSupercoachingAllTeachersPageVisited(eventInfo:object){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const supercoachingAllTeachersPageVisited = new SupercoachingAllTeachersPageVisited(finalEventInfo);
        this.pushToDataLayer(supercoachingAllTeachersPageVisited);
    }

    sendSupercoachingCurriculumDownloadedEvent(eventInfo:object){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath': this.platformService.getPathName(true)
        }
        let finalEventInfo = {...defaultEventInfo, ...eventInfo}
        const supercoachingCurriculumDownloadedEvent = new SupercoachingCurriculumDownloadedEvent(finalEventInfo);
        this.pushToDataLayer(supercoachingCurriculumDownloadedEvent)
    }

      sendPdfViewerUserClickEvent(eventInfo:object){
        let defaultEventInfo = {
            'pageURL': this.platformService.getPathName(true),
            'page':getPageText()
        }
        let finalEventInfo = {...defaultEventInfo, ...eventInfo}
        const pdfViewerUserClickEvent = new PdfViewerUserClickEvent(finalEventInfo);
        this.pushToDataLayer(pdfViewerUserClickEvent)
    }

    sendPypFreeTestUserClickEvent(eventInfo:object){
        let defaultEventInfo = {
            'pageURL': this.platformService.getPathName(true),
            'page':getPageText()
        }
        let finalEventInfo = {...defaultEventInfo, ...eventInfo}
        const pypFreeTestUserClickEvent = new PypFreeTestUserClickEvent(finalEventInfo);
        this.pushToDataLayer(pypFreeTestUserClickEvent)
    }

    sendSupercoachingReadmoreClicked(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const supercoachingReadmoreClicked = new SupercoachingReadmoreClicked(finalEventInfo)
        this.pushToDataLayer(supercoachingReadmoreClicked);
    }

    sendApplyCouponClicked(eventInfo: any){
        let defaultEventInfo ={
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true)
        }
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const applyCouponClicked = new ApplyCouponClicked(finalEventInfo)
        this.pushToDataLayer(applyCouponClicked);
    }

    sendSupercoachingExploreNowClicked(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true)
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const supercoachingExploreNowClicked = new SupercoachingExploredNowClicked(finalEventInfo);
        this.pushToDataLayer(supercoachingExploreNowClicked);
    }

    sendCompletePendingPaymentClicked(eventInfo: any){
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true)
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const completePendingPaymentClicked = new CompletePendingPaymentClicked(finalEventInfo);
        this.pushToDataLayer(completePendingPaymentClicked);
    }

    sendSelectionFormProgressClicked(eventInfo: any){
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true)
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const selectionFormProgressClicked = new SelectionFormProgressClicked(finalEventInfo);
        this.pushToDataLayer(selectionFormProgressClicked);
    }
    sendTargetPagePopupClickEvent(eventInfo: any){
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true)
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const targetPagePopupClickEvent = new TargetPagePopupClickEvent(finalEventInfo);
        this.pushToDataLayer(targetPagePopupClickEvent);
    }
    sendSupercoachingStudyPlanDownload(eventInfo: any){
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true)
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const supercoachingStudyPlanDownload = new SupercoachingStudyPlanDownload(finalEventInfo);
        this.pushToDataLayer(supercoachingStudyPlanDownload);
    }

    sendSupercoachingWhatsappChatClicked(eventInfo: any){
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true)
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const supercoachingWhatsappChatClicked = new SupercoachingWhatsappChatClicked(finalEventInfo);
        this.pushToDataLayer(supercoachingWhatsappChatClicked);
    }

    sendGlobalTestSeriesPageEvent(eventInfo: any){
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true)
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const globalTestSeriesPageEvent = new GlobalTestSeriesPageEvent(finalEventInfo);
        this.pushToDataLayer(globalTestSeriesPageEvent);
    }

    async sendTestSeriesSlugPageEvent(eventInfo: any){
        let userType = await this.userTypeData();
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true),
            userType
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const testSeriesPageEvent = new TestSeriesSlugPageEvent(finalEventInfo);
        this.pushToDataLayer(testSeriesPageEvent);
    }

    sendGameRewardEvent(eventInfo: any){
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true)
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const gameRewardEvent = new GameRewardWonEvent(finalEventInfo);
        this.pushToDataLayer(gameRewardEvent);
    }

    sendGoalPitchBannerClickedEvent(eventInfo: any){
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true)
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const goalPitchBannerClickedEvent = new GoalPitchBannerClicked(finalEventInfo);
        this.pushToDataLayer(goalPitchBannerClickedEvent);
    }

    sendGameCouponAppliedEvent(eventInfo: any){
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true)
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const gameCouponAppliedEvent = new GameCouponAppliedEvent(finalEventInfo);
        this.pushToDataLayer(gameCouponAppliedEvent);
    }

    sendQueryParamDynamicEvent(_eventInfo: any){
        let eventList;
        if(!isServer()){
            try {
                eventList = getLocalStorage(FIRE_DYNAMIC_EVENT);
            } catch (e) {
                console.error(e);
            }
        }
        this.route.queryParams.pipe(take(1)).subscribe(params => {
            if(params?.triggerEvent || eventList){
                const triggerEventList = params?.triggerEvent?.split(',') || eventList?.split(',');
                let eventId = reverseDynamicEventMap[_eventInfo.event];
                if(triggerEventList.includes(String(eventId))){
                    removeLocalStorage(FIRE_DYNAMIC_EVENT);
                    this.pushToDataLayer(_eventInfo);
                }
            }
        });
    }

    sendGoalPurchasePopupClosedEvent(eventInfo: any){
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true)
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const goalPurchasePopupClosedEvent = new GoalPurchasePopupClosedEvent(finalEventInfo);
        this.pushToDataLayer(goalPurchasePopupClosedEvent);
    }

    sendGoalPurchasePopupClickedEvent(eventInfo: any){
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true)
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const goalPurchasePopupClickedEvent = new GoalPurchasePopupClickedEvent(finalEventInfo);
        this.pushToDataLayer(goalPurchasePopupClickedEvent);
    }

    sendGoalPurchasePopupViewedEvent(eventInfo: any){
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true)
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const goalPurchasePopupViewedEvent = new GoalPurchasePopupViewedEvent(finalEventInfo);
        this.pushToDataLayer(goalPurchasePopupViewedEvent);
    }

    sendProductsOfferingTabClicked(eventInfo: any){
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true),
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const productsOfferingTabClicked = new ProductsOfferingTabClicked(finalEventInfo);
        this.pushToDataLayer(productsOfferingTabClicked);
    }

    sendSuperCoachingGoalRecommendationClicked(eventInfo: any){
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true),
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const superCoachingGoalRecommendationClicked = new SuperCoachingGoalRecommendationClicked(finalEventInfo);
        this.pushToDataLayer(superCoachingGoalRecommendationClicked);
    }

    sendPassProPopupActivity(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath': this.platformService.getPathName(true),
            'pageType':getPageType()
        }
        const finalEventInfo = {...defaultEventInfo,...eventInfo};
        const passProPopupActivity = new PassProPopupActivity(finalEventInfo);
        this.pushToDataLayer(passProPopupActivity);
    }

    sendPassProPlansPageVisited(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath': this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
        }
        const finalEventInfo = {...defaultEventInfo,...eventInfo};
        const passProPlansPageVisited = new PassProPlansPageVisited(finalEventInfo);
        this.pushToDataLayer(passProPlansPageVisited);
    }
    

    sendInterestedInEmailMentorship(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath': this.platformService.getPathName(true)
        }
        const finalEventInfo = {...defaultEventInfo,...eventInfo};
        const interestedInEmailMentorship = new InterestedInEmailMentorship(finalEventInfo);
        this.pushToDataLayer(interestedInEmailMentorship);
    }
    sendEmailMentorshipEnrolled(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath': this.platformService.getPathName(true)
        }
        const finalEventInfo = {...defaultEventInfo,...eventInfo};
        const emailMentorshipEnrolled = new EmailMentorshipEnrolled(finalEventInfo);
        this.pushToDataLayer(emailMentorshipEnrolled);
    }

    sendPassProProceededToPayment(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath': this.platformService.getPathName(true),
            'pageType':getPageType()
        }
        const finalEventInfo = {...defaultEventInfo,...eventInfo};
        const passProProceededToPayment = new PassProProceededToPayment(finalEventInfo);
        this.pushToDataLayer(passProProceededToPayment);
    }

    sendSignupActivityEvent(eventInfo: any){
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true)
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const signupActivityEvent = new SignupActivityEvent(finalEventInfo);

        this.pushToDataLayer(signupActivityEvent);
        
    }

    sendSupercoachingGoalSelectionPageVisited(){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true)
        };
        const supercoachingGoalSelectionPageVisited = new SupercoachingGoalSelectionPageVisited(defaultEventInfo);
        

        this.pushToDataLayer(supercoachingGoalSelectionPageVisited);
    }   
    async sendReattemptTestEvent(eventInfo: any) {
        const userType = await this.userTypeData();
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true) + this.platformService.getQueryString(true),
            'userType': userType
        };
        const finalEventInfo = {...defaultEventInfo,...eventInfo};
        const reattemptTestEvent = new ReattemptTestEvent(finalEventInfo);
        this.pushToDataLayer(reattemptTestEvent);
    }

    sendDeactivateAccountEvent(){
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true)
        };
        const finalEventInfo = {...defaultEventInfo};
        const deactivateAccountEvent = new DeactivateAccountEvent(finalEventInfo);

        this.pushToDataLayer(deactivateAccountEvent);
        
    }

    sendUpdatePincodeEvent(eventInfo: any){
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true)
        };
        const finalEventInfo = {...defaultEventInfo,...eventInfo}; 
        const updatePincodeEvent = new  UpdatePincodeEvent(finalEventInfo);

        this.pushToDataLayer(updatePincodeEvent);
    }

    sendUpdateDegreesEvent(eventInfo: any){
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true)
        };
        const finalEventInfo = {...defaultEventInfo,...eventInfo}; 
        const updateDegreesEvent = new  UpdateDegreeEvent(finalEventInfo);

        this.pushToDataLayer(updateDegreesEvent);
    }

    sendUpdateCategoryEvent(eventInfo: any){
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true)
        };
        const finalEventInfo = {...defaultEventInfo,...eventInfo}; 
        const updateCategoryEvent = new  UpdateCategoryEvent(finalEventInfo);

        this.pushToDataLayer(updateCategoryEvent);
    }

    sendUpdateDobEvent(eventInfo: any){
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true)
        };
        const finalEventInfo = {...defaultEventInfo,...eventInfo}; 
        const updateDobEvent = new  UpdateDobEvent(finalEventInfo);

        this.pushToDataLayer(updateDobEvent);
    }

    sendCombinedPassComparisonPageActivityEvent(eventInfo: any) {
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true),
            'pageType': this.platformService.getPageType()
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const combinedPassComparisonPageActivityEvent = new CombinedPassComparisonPageActivityEvent(finalEventInfo);
        this.pushToDataLayer(combinedPassComparisonPageActivityEvent);
    }
    sendMainsAnswerCategoryChanged(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType()
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const mainsAnswerCategoryChanged = new MainsAnswerCategoryChanged(finalEventInfo);
        this.pushToDataLayer(mainsAnswerCategoryChanged);
    }

    sendMainsAnswerViewAllClicked(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType()
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const mainsAnswerViewAllClicked = new MainsAnswerViewAllClicked(finalEventInfo);
        this.pushToDataLayer(mainsAnswerViewAllClicked);
    }

    sendMainsAnswerCardClicked(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType()
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const mainsAnswerCardClicked = new MainsAnswerCardClicked(finalEventInfo);
        this.pushToDataLayer(mainsAnswerCardClicked);
    }

    sendMainsUserAnswerSubmitted(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType()
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const mainsUserAnswerSubmitted = new MainsUserAnswerSubmitted(finalEventInfo);
        this.pushToDataLayer(mainsUserAnswerSubmitted);
    }

    sendMainsAnswerUserUpVoted(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType()
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const mainsAnswerUserUpVoted = new MainsAnswerUserUpVoted(finalEventInfo);
        this.pushToDataLayer(mainsAnswerUserUpVoted);
    }

    sendSuperCoachingEntityExploreEvent(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const superCoachingEntityExploreEvent = new SuperCoachingEntityExploreEvent(finalEventInfo);
        this.pushToDataLayer(superCoachingEntityExploreEvent);
    }

    sendSuperCoachingTalkToExpert(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const superCoachingTalkToExpert = new SuperCoachingTalkToExpert(finalEventInfo);
        this.pushToDataLayer(superCoachingTalkToExpert);
    }
    
    sendMentorshipBookingInitiated(eventInfo: any){
            let defaultEventInfo = {
                'page':getPageText(),
                'pagePath':this.platformService.getPathName(true),
                'pageType':getPageType()
            };
            const finalEventInfo = {...defaultEventInfo, ...eventInfo};
            const mentorshipButtonClicked = new MentorshipButtonClicked(finalEventInfo);
            this.pushToDataLayer(mentorshipButtonClicked);
    }

    sendMentorshipModalDismissed(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType()
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const mentorshipModalDismissed = new MentorshipModalDismissed(finalEventInfo);
        this.pushToDataLayer(mentorshipModalDismissed);
    }

    sendBookMentorshipSession(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType()
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const bookMentorshipSession = new BookMentorshipButtonClicked(finalEventInfo);
        this.pushToDataLayer(bookMentorshipSession);
    }

    sendAppDownloadClickEvent(){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn()
        };
        const appDownloadedClick = new AppDownloadClickEvent(defaultEventInfo);
        this.pushToDataLayer(appDownloadedClick);
    }
    
    sendPageExploreActivity(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn()
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const pageExploreActivity = new PageExploreActivity(finalEventInfo);
        this.pushToDataLayer(pageExploreActivity);
    }

    sendStudyPlanClickEvent(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn()
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const studyPlanClickEvent = new StudyPlanClickEvent(finalEventInfo);
        this.pushToDataLayer(studyPlanClickEvent);
    }

    sendStudyPlanGeneratedEvent(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn()
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const studyPlanGeneratedEvent = new StudyPlanGeneratedEvent(finalEventInfo);
        this.pushToDataLayer(studyPlanGeneratedEvent);
    }

    async sendViewEmiPlanClickEvent(eventInfo: any){
        const userType = await this.userTypeData();
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
            userType
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const viewEmiPlanClickEvent = new ViewEmiPlanClickEvent(finalEventInfo);
        this.pushToDataLayer(viewEmiPlanClickEvent);
    }

    async sendDownPaymentPopupViewEvent(eventInfo: any){
        const userType = await this.userTypeData();
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
            userType
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const emiMandatePopupViewEvent = new DownPaymentPopupViewEvent(finalEventInfo);
        this.pushToDataLayer(emiMandatePopupViewEvent);
    }

    async sendDownPaymentPopupClosedEvent(eventInfo: any){
        const userType = await this.userTypeData();
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
            userType
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const emiMandatePopupClosedEvent = new DownPaymentPopupClosedEvent(finalEventInfo);
        this.pushToDataLayer(emiMandatePopupClosedEvent);
    }

    sendSuperCoachingPurchaseModalViewed(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn()
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const superCoachingPurchaseModalViewed = new SuperCoachingPurchaseModalViewed(finalEventInfo);
        this.pushToDataLayer(superCoachingPurchaseModalViewed);
    }

    async sendSupercoachingEmiProceededToPayment(eventInfo: any){
        const userType = await this.userTypeData();
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
            userType
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const supercoachingEmiProceededToPayment = new SupercoachingEmiProceededToPayment(finalEventInfo);
        this.pushToDataLayer(supercoachingEmiProceededToPayment);
    }

    sendEmiMandateSuccess(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn()
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const emiMandateSuccess = new EmiMandateSuccess(finalEventInfo);
        this.pushToDataLayer(emiMandateSuccess);
    }

    async sendEnableAutoEmiPaymentClicked(eventInfo: any){
        const userType = await this.userTypeData();
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
            userType
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const enableAutoEmiPaymentClicked = new EnableAutoEmiPaymentClicked(finalEventInfo);
        this.pushToDataLayer(enableAutoEmiPaymentClicked);
    }

    async sendEmiMandateInformationCollected(eventInfo: any){
        const userType = await this.userTypeData();
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
            userType
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const emiMandateInformationCollected = new EmiMandateInformationCollected(finalEventInfo);
        this.pushToDataLayer(emiMandateInformationCollected);
    }

    sendPypChildPageExploreActivityEvent(eventInfo: any){
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn()
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const pypChildPageExploreActivityEvent = new PypChildPageExploreActivityEvent(finalEventInfo);
        this.pushToDataLayer(pypChildPageExploreActivityEvent);
    }

    sendBlockerPopupViewEvent(eventInfo: any) {
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn()
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const blockerPopupViewEvent = new BlockerPopupViewedEvent(finalEventInfo);
        this.pushToDataLayer(blockerPopupViewEvent);
    }

    async sendEmiInfoScreenVisitedEvent(eventInfo: any) {
        const userType = await this.userTypeData();
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
            userType
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const emiInfoScreenVisitedEvent = new EmiInfoScreenVisitedEvent(finalEventInfo);
        this.pushToDataLayer(emiInfoScreenVisitedEvent);
    }
    

    async sendPassWrappedViewPageEvent(eventInfo: any) {
        const userType = await this.userTypeData();
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
            userType
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const passWrappedViewPageEvent = new PassWrappedViewPageEvent(finalEventInfo);
        this.pushToDataLayer(passWrappedViewPageEvent);
    }

    async sendPassWrappedClickEvent(eventInfo: any) {
        const userType = await this.userTypeData();
        let defaultEventInfo = {
            'page':getPageText(),
            'pagePath':this.platformService.getPathName(true),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
            userType
        };
        const finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const passWrappedClickEvent = new PassWrappedClickEvent(finalEventInfo);
        this.pushToDataLayer(passWrappedClickEvent);
    }

    async sendNPSFeedbackEvent(eventInfo: any) {
        let defaultEventInfo = {
            'page': getPageText(),
            'pagePath': this.platformService.getPathName(true),
            'pageType': getPageType(),
            'isLoggedIn': this.platformService.isLoggedIn(),
        };
        let finalEventInfo = { ...defaultEventInfo, ...eventInfo };
        const npsFeedbackEvent = new NPSFeedbackEvent(finalEventInfo);
        this.pushToDataLayer(npsFeedbackEvent);
    }

    sendScholarshipTestClickedEvent(eventInfo:object){
        let defaultEventInfo = {
            'page':getPageText(),
            'pageType':getPageType(),
            'isLoggedIn':this.platformService.isLoggedIn(),
            'pagePath':this.platformService.getPathName(true)
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const scholarshipTestClickEvent = new ScholarshipTestClickEvent(finalEventInfo);
        this.pushToDataLayer(scholarshipTestClickEvent);
    }

    sendAddressSubmittedEvent() {
        let defaultEventInfo = {
            page: getPageText(),
            pagePath: this.platformService.getPathName(true),
            pageType: getPageType()
        };
        const addressPopupEvent = new AddressSubmittedEvent(defaultEventInfo);
        this.pushToDataLayer(addressPopupEvent);
    }

    sendBookOrdersClickEvent(eventInfo: object) {
        let defaultEventInfo = {
            page: getPageText(),
            pagePath: this.platformService.getPathName(true),
            pageType: getPageType(),
            category: 'SuperCoachingBooks'
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const bookOrdersClickEvent = new BookOrdersClicked(finalEventInfo);
        this.pushToDataLayer(bookOrdersClickEvent);
    }

    sendPlutusEnquiryFormFilledEvent(eventInfo: object) {
        let defaultEventInfo = {
            source: "plutus",
            page: getPageText(),
            pagePath: this.platformService.getPathName(true),
            pageType: getPageType(),
            category: 'Plutus_form'
        };
        let finalEventInfo = {...defaultEventInfo, ...eventInfo};
        const enquiryFormFilledEvent = new PlutusEnquiryFormFilled(finalEventInfo);
        this.pushToDataLayer(enquiryFormFilledEvent);
    }

    sendPlutusDownloadBrochureEvent() {
        let defaultEventInfo = {
            source: "plutus",
            page: getPageText(),
            pagePath: this.platformService.getPathName(true),
            pageType: getPageType(),
            category: 'Plutus_download_brochure'
        };
        const downloadBrochureEvent = new PlutusDownloadBrochureClicked(defaultEventInfo);
        this.pushToDataLayer(downloadBrochureEvent);
    }

    sendPlutusCourseClickedEvent() {
        let defaultEventInfo = {
            source: "plutus",
            page: getPageText(),
            pagePath: this.platformService.getPathName(true),
            pageType: getPageType(),
            category: 'course_clicked'
        };
        const plutusCourseClickedEvent = new PlutusCourseClicked(defaultEventInfo);
        this.pushToDataLayer(plutusCourseClickedEvent);
    }

    sendPlutusMiniCourseClickedEvent() {
        let defaultEventInfo = {
            source: "plutus",
            page: getPageText(),
            pagePath: this.platformService.getPathName(true),
            pageType: getPageType(),
            category: 'mini_course_clicked'
        };
        const plutusCourseMiniClickedEvent = new PlutusMiniCourseClicked(defaultEventInfo);
        this.pushToDataLayer(plutusCourseMiniClickedEvent);
    }

    sendPlutusCallBackRequestedEvent() {
        let defaultEventInfo = {
            source: "plutus",
            page: getPageText(),
            pagePath: this.platformService.getPathName(true),
            pageType: getPageType(),
            category: 'Call_back_requested'
        };
        const plutusCallBackRequestedEvent = new PlutusCallBackRequested(defaultEventInfo);
        this.pushToDataLayer(plutusCallBackRequestedEvent);
    }

    sendPlutusJoinWebinarEvent() {
        let defaultEventInfo = {
            source: "plutus",
            page: getPageText(),
            pagePath: this.platformService.getPathName(true),
            pageType: getPageType(),
            category: 'Call_back_requested'
        };
        const plutusJoinWebinarEvent = new PlutusJoinWebinarClicked(defaultEventInfo);
        this.pushToDataLayer(plutusJoinWebinarEvent);
    }
    
    sendQRCodeScannedEvent(eventInfo: object) {
        const qrCodeScannedEvent = new QRCodeScanned(eventInfo);
        this.pushToDataLayer(qrCodeScannedEvent);
    }

    sendAmountEnteredEvent(eventInfo: object) {
        const amountEnteredEvent = new AmountEnteredEvent(eventInfo);
        this.pushToDataLayer(amountEnteredEvent);
    }

    sendProceedToPayEvent(eventInfo: object) {
        const proceedToPayEvent = new ProceedToPay(eventInfo);
        this.pushToDataLayer(proceedToPayEvent);
    }

    sendNumberEnteredEvent(eventInfo: object) {
        const numberEnteredEvent = new NumberEntered(eventInfo);
        this.pushToDataLayer(numberEnteredEvent);
    }

    sendUnlockPassProEvent(eventInfo: object) {
        const unlockPassProEvent = new UnlockPassPro(eventInfo);
        this.pushToDataLayer(unlockPassProEvent);
    }

    sendOrderStatusEvent(eventInfo: object) {
        const orderStatusEvent = new OrderStatus(eventInfo);
        this.pushToDataLayer(orderStatusEvent);
    }

}
