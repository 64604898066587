import {createAction, props} from '@ngrx/store';
import {_subFooter, PageSeoModel, SubFooterWrapperModel} from "@models/entity/page-seo/page-seo.adapter";
import {TargetSuperGroupWrapper} from "@models/entity/targets/target-supergroup.adapter";
import {_PypTargetSlugWrapper, TargetSlugWrapper} from "@models/entity/targets/target.slug.adapter";
import {BannerWrapper} from "@models/entity/promotion-banner/banner.adapter";
import {OnboardingParams} from "@shared/types/login";
import {
    DeleteNotificationParams,
    NotificationCountModel,
    NotificationModel
} from "@models/entity/notifications/notification.model";
import {RegisterModuleParams} from "@models/entity/tests/test.adapter";
import { accessBody } from '@angular-commons/models/entity/master-series/master-series.adapter';
import { EMI_WRAPPER_QUERY_PARAMS } from '@angular-commons/shared/utility/constants';

export const setProjectName = createAction('[app] setProjectName', props<{projectName: string}>());

export const login = createAction('[app] Login');

export const setCurrentPageGoal = createAction('[app] setCurrentPageGoal',props<{payload:any}>());

export const getStudentMe = createAction('[app] getStudentMe' );

export const studentMeLoaded = createAction('[app] StudentMeLoaded' , props<{payload:any}>());

export const showReportModal = createAction('[app] Show Report Modal', props<{entityId: string, entityType: string, entityTitle?: string}>());

export const hideReportModal = createAction('[app] Hide Report Modal');

export const getPasses = createAction('[app] getPasses', props<{passType: string,pIds?:string,sendRecommended?:boolean}>());

export const passesLoaded = createAction('[app] passesLoaded' , props<{payload:any}>());

export const getPassPageInfo =  createAction('[app] get Pass Page Info')

export const setReferredUserData = createAction('[app Pass] Set Referred User Data',props<{referrer:any,isReferredUser:boolean,username:string}>())

export const passPageInfoLoaded =  createAction('[app] Pass Page Info Loaded' , props<{payload:any}>())

export const getPageSeo = createAction('[app] Get Page Seo', props<{pathname: string}>());
export const getPageSeoLoaded = createAction('[app] Get Page Seo Loaded', props<{payload: PageSeoModel}>());

export const getSeoGroupSubFooter = createAction('[app] Get Seo SubFooter', props<{targetId: string, pathname?: string}>());
export const getSubFooterLoaded = createAction('[app] Get Seo SubFooter Loaded', props<{payload: SubFooterWrapperModel}>());
export const setSeoSubFooter = createAction('[app] Set Seo SubFooter', props<{payload: _subFooter[]}>());

export const getTargetSuperGroups = createAction('[app] Get Target SuperGroup');
export const getTargetSuperGroupsSuccess = createAction('[app] Get Target SuperGroup Loaded', props<{payload: TargetSuperGroupWrapper}>());

export const getPypTargetSuperGroups = createAction('[app] Get Pyp Target SuperGroup', props<{pageType: string}>());
export const getPypTargetSuperGroupsSuccess = createAction('[app] Get Pyp Target SuperGroup Loaded', props<{payload: TargetSuperGroupWrapper}>());

export const getTargetsOfSuperGroup = createAction('[app] Load Targets', props<{superGroupId: string}>());
export const getTargetsOfSuperGroupSuccess = createAction('[app] Load Targets Success', props<{payload: TargetSlugWrapper}>());

export const getPypTargetsOfSuperGroup = createAction('[app] Load Pyp Targets', props<{superGroupId: string, pageType: string}>());
export const getPypTargetsOfSuperGroupSuccess = createAction('[app] Load Pyp Targets Success', props<{payload: _PypTargetSlugWrapper}>());

export const getPromotionBanner = createAction('[app] Get Promotion Banner', props<{pageName: string}>());
export const getPromotionBannerSuccess = createAction('[app] Get Promotion Banner Success', props<{payload: BannerWrapper}>());

export const justLoggedIn = createAction('[app] User Logged In');

export const getNotificationsCount = createAction('[app] Load Notifications Count', props<{sid: string}>())
export const getNotificationsCountSuccess = createAction('[app] Load Notifications Count Success', props<{payload: NotificationCountModel}>());

export const getNotifications = createAction('[app] Get Notifications', props<{skip: number, limit: number, sid: string}>());
export const getNotificationsSuccess = createAction('[app] Get Notifications Success', props<{payload: NotificationModel}>());

export const deleteNotification = createAction('[app] Delete Notification', props<DeleteNotificationParams>());
export const deleteNotificationSuccess = createAction('[app] Delete Notification Success', props<{payload: any, id: string}>());

export const showCouponModal = createAction('[app] Show Coupon Modal',props<{ModalType?:string,productType?:string}>())
export const hideCouponModal = createAction('[app] Hide Coupon Modal');

export const sendProductDetails = createAction('[app] Send Product Info', props<{productIds: string[],productDetails: object}>());
export const autoCouponApply = createAction('[app] Auto Coupon Apply')
export const disableAutoCouponApply = createAction('[app] Disable Auto Coupon Apply',props<{disableAutoCouponApply?:boolean}>())
export const updateCouponObj = createAction('[app] Update Coupon Object', props<{couponObj: string[]}>());

export const showHeader = createAction('[app] Show Header');
export const hideHeader = createAction('[app] Hide Header');
export const showSidebar = createAction('[app] Show Sidebar');
export const hideSidebar = createAction('[app] Hide Sidebar');

export const showPricingModal = createAction('[app] Show Pricing Modal', props<{passType?:string ,module?: string, eventData?: any, leadData?: any, goalSlug?: string, referrer?: string, showComparisonModal?: boolean}>());
export const setPricingModalState = createAction('[app] Set Pricing Modal State', props<{passType?:string ,module?: string, eventData?: any, leadData?: any, goalSlug?: string, referrer?: string, showComparisonModal?: boolean}>());
export const hidePricingModal = createAction('[app] Hide Pricing Modal');

export const showBookCarouselModal = createAction('[app] Show Book Modal', props<{cardTitle: string, cardImages: any,cardDescription:any}>());
export const hideBookCarouselModal = createAction('[app] Hide Book Modal');

export const showStudentCounsellingModal = createAction('[app] Show Counselling Modal');
export const hideStudentCounsellingModal = createAction('[app] Hide Counselling Modal');

export const showProductInfoModal = createAction('[app] Show Product Info Modal', props<{popUpType: string, data: any}>());
export const hideProductInfoModal = createAction('[app] Hide Product Info Modal',props<{popUpType: string}>());

export const showVideoModal = createAction('[app] showVideoModal', props<{data: any}>());
export const hideVideoModal = createAction('[app] hideVideoModal');

export const showFooter = createAction('[app] Show Footer');
export const hideFooter = createAction('[app] Hide Footer');

export const showContinueInApp = createAction('[app] Show ContinueInAp');
export const hideContinueInApp = createAction('[app] Hide ContinueInAp');

export const showStickyFooter = createAction('[app] Show Sticky Footer');
export const hideStickyFooter = createAction('[app] Hide Sticky Footer');
export const stickyFooterType = createAction('[app] Sticky Footer Type', props<{footerType:string}>());

export const setOnboardingParams = createAction('[app] set onboarding params',props<{payload :OnboardingParams}>());

export const getProductEntityCount = createAction('[app] Product Entity Count', props<{type?: any}>());
export const productEntityCountSuccess = createAction('[app] Product Entity Count Success', props<{payload:any}>());

export const initiateRazorpayPayment = createAction('[app] Initiate Razorpay Payment', props<{details:{extraObj:{}, params: {}}, redirectUrl?: string, brand?:  string}>());
export const hideRazorpayIframe = createAction('[app] Hide Razorpay Iframe');
export const setRazorypayTransactionStatus = createAction('[app] Set Razorypay Transaction Status', props<{status: 'success'| 'failure' | ''}>());

export const getSearchSuggestions = createAction('[app] Get Search Suggestions', props<{sid: string}>());
export const getSearchSuggestionsSuccess = createAction('[app] Get Search Suggestions Success', props<{payload: any}>());

export const storeSearchSuggestion = createAction('[app] Store Search Suggestion', props<{query: string, sid: string}>());

export const showAlert = createAction('[app] Show Alert', props<{message: string,timer?:number,autoClose?:boolean, isCompletedAlert?:boolean, alertTitle?: string,alertType?: string}>());
export const hideAlert = createAction('[app] Hide Alert',props<{id: number}>());

export const showKindOfferModal = createAction('[app Pass] Show Kind Offer Modal' , props<{pass:any}>());
export const hideKindOfferModal = createAction('[app Pass] Hide Kind Offer Modal');

export const registerModule = createAction('[app] Register Module ', props<{request: RegisterModuleParams,id:string}>());
export const registerModuleSuccess = createAction('[app] Register Module Success',props<{payload:any}>());

export const transactionPopupAction = createAction('[app] transaction Popup Action',props<{ id : string , payloadtype : string, payload: any}>());

export const setIdForSubFooter = createAction('[app] Set Id for SubFooter', props<{id: string}>());
export const setSubFooterType = createAction('[app] Set SubFooter Type', props<{subFooterType: string}>());

export const setContinueInAppDeeplink = createAction('[app] Set Continue in App Data', props<{continueInAppDeeplinkData:any}>());

export const resetOnNavigation = createAction('[app] Reset On Navigation',props<{url?:string}>())

export const setOpenInAppDialogDeeplink = createAction('[app] Set Open in App Dialog Deeplink', props<{deeplink: string}>());
export const setOpenInAppDialogVisibility = createAction('[app] Show Open in App Dialog', props<{show: boolean}>());

export const getMultiAppJson = createAction('[app] Get Multi Apps JSON file');
export const getMultiAppJsonSuccess = createAction('[app] Get Multi Apps JSON file success',props<{payload: any}>());


export const setMasterSeriesAccess = createAction('[Master Series] setMasterSeriesAccess', props<{id:string,body:accessBody}>())
export const setMasterSeriesAccessSuccess = createAction('[Master Series] setMasterSeriesAccessSuccess', props<{payload:any}>())


export const createMasterLessonReminder = createAction('[Master Series] Create Master Lesson Reminder',props<{lessonId:string,masterSeriesId:string,reminderStatus:string}>());
export const MasterLessonReminderSuccess = createAction('[Master Series] Create Master Lesson Reminder Success', props<{lessonId:string,masterSeriesId:string,reminderStatus:string}>());

export const getMasterSeriesEnrollmentDetails = createAction('[Master Series] getMasterSeriesEnrollmentDetails',props<{id:string}>() )
export const masterSeriesEnrollmentDetailsLoaded = createAction('[Master Series] masterSeriesEnrollmentDetailsLoaded',props<{payload:any,id:string}>() )

export const getGoals = createAction('[app] get all goals');
export const goalLoaded = createAction('[app] loaded all goals',props<{payload:any}>());

export const getGoalById = createAction('[app] Get Goal by id', props<{id: string}>());
export const getGoalBySlug = createAction('[app] Get Goal by slug', props<{slug: string}>());
export const getGoalInfoSuccess = createAction('[app] Get Goal Info success', props<{payload: any}>());


export const getFacultyData = createAction('[app] getFacultyData',props<{ids:any}>());
export const getFacultyDataLoaded = createAction('[app] getFacultyDataLoaded' , props<{educatorData:any,ids:string}>());

export const getEducatorData = createAction('[app] getEducatorData',props<{idOrSlug:any, goalIdOrSlug: string}>());
export const getEducatorDataLoaded = createAction('[app] getEducatorDataLoaded' , props<{educatorData:any,idOrSlug:string}>());

export const getPassProducts = createAction('[app] get Products',props<{productIds:any}>());
export const getPassProductsLoaded = createAction('[app] loaded Products',props<{payload:any}>() );

export const getGoalPlans = createAction('[app] get Goal plans',props<{gid:string}>());
export const getRecommendedGoalPlan = createAction('[app] get Recommended Goal plan',props<{params}>());

export const goalPlansLoaded = createAction('[app] loaded Goal plans',props<{payload:any}>() );
export const recommendedGoalPlanLoaded = createAction('[app] recommended loaded Goal plans', props<{payload:any}>() );

export const showGoalSelectionModal = createAction('[app] Show Goal Selection Modal', props<{module?: string, eventData?: any}>());
export const hideGoalSelectionModal = createAction('[app] Hide Goal Selection Modal');

export const getSuggestedTestByGoalId = createAction('[app] get Attempted Tests by goal', props<{goalId: string}>());
export const getSuggestedTestByGoalIdSuccess = createAction('[app] get Attempted Tests by goal Success', props<{payload: any}>());

export const setEducatorsFollow = createAction('[app] setEducatorsFollow',props<{body:any}>());
export const setEducatorsUnfollow = createAction('[app] setEducatorsUnfollow',props<{activity:any,eid:string}>());
export const setEducatorsFollowStatus = createAction('[app] setEducatorsFollowStatus',props<{followStatus:boolean}>());

export const getTestSeriesByGoalId = createAction('[app] get Test series by goal', props<{goalId: string, skip?: number, limit?: number, notEnrolled?: boolean}>());
export const getTestSeriesByGoalIdSuccess = createAction('[app] get Test series by goal success', props<{testSeries: any, goalId: string, skip: number, limit: number}>());

export const getEnrolledTestSeriesByGoalId = createAction('[app] get Enrolled Test series by goal', props<{goalId: string, skip?: number, limit?: number}>());
export const getEnrolledTestSeriesByGoalIdSuccess = createAction('[app] get Enrolled Test series by goal success', props<{testSeries: any, goalId: string, skip: number, limit: number}>());

export const getSearchedTestSeries = createAction('[app] Get Searched Test Series', props<{searchParams: any, component?: string, studentId?: string, skip?: number, limit?: number}>());
export const getSearchedTestSeriesSuccess = createAction('[app] Get Searched Test Series Successful', props<{payload: any}>());

export const getSearchedPypTargets = createAction('[app] Get Searched Pyp Targets', props<{searchParams: any}>());
export const getSearchedPypTargetsSuccess = createAction('[app] Get Searched Pyp Targets Successful', props<{payload: any}>());

export const setGoalTestTabComponentState = createAction('[app] Goal Test Tab Component State', props<{componentState: any}>());
export const resetGoalTestTabComponentState = createAction('[app] Reset Goal Test Tab Component State');

export const resetGoalTestSeries = createAction('[app] Reset Goal Test Series');
export const resetGoalPypTargets = createAction('[app] Reset Goal PYP Targets');

export const getPypByGoal = createAction('[app] Get PYP by Goal', props<{goalId: any, skip?: number, limit?: number}>());
export const getPypByGoalSuccess = createAction('[app] PYP by Goal Success', props<{targets: any, goalId: string, skip: number, limit: number}>());

export const getCustomTagsByGoal = createAction('[app] Get Custom Tags by Goal', props<{goalSlug: any}>());
export const getCustomTagsByGoalSuccess = createAction('[app] Get Custom Tags by Goal Success', props<{payload: any}>());

export const getGoalPageSequence = createAction('[app] Get Goal Page Sequence', props<{pageType: any, client: string,slug?:string}>());
export const getGoalPageSequenceSuccess = createAction('[app] Get Goal Page Sequence Success', props<{pageType, payload: any,slug?:string}>());

export const markTestSeriesEnrolled = createAction('[app] Mark TestSeries Enrolled', props<{tsId: string}>());
export const markTestSeriesUnEnrolled  = createAction('[app] Mark TestSeries UnEnrolled', props<{tsId: string}>());

export const markTestSeriesEnrolledSuccess = createAction('[app] Mark TestSeries Enrolled Success', props<{payload: any, testSeriesId: string}>());
export const markTestSeriesUnEnrolledSuccess = createAction('[app] Mark TestSeries UnEnrolled Success', props<{payload: any, testSeriesId: string}>());
export const setActivePageType = createAction('[app] setActivePageType', props<{pageType:string}>())
export const showMobileSignupAndVerificationModal = createAction('[app] Show Mobile Signup And Verification Modal', props<{data: any, isSkippable?:boolean,showModal?:boolean}>());
export const hideMobileSignupAndVerificationModal = createAction('[app] Hide Mobile Signup And Verification Modal');

export const showWhatsappModal = createAction('[app] Show Whatsapp Modal');
export const hideWhatsappModal = createAction('[app] Hide Whatsapp Modal');

export const mobileVerificationSuccess = createAction('[app] Mobile Verification Success');

export const setEventExtras = createAction('[app] setEventExtras',props<{itemId:string,itemType:string}>())

export const getGoalPitchMap = createAction('[app] getGoalPitchMap')
export const getGoalPitchMapLoaded = createAction('[app] getGoalPitchMapLoaded',props<{payload:any}>())

export const getGoalCourses = createAction('[app] getGoalCourses',props<{goalIds:any,skip:any,limit:any,sortBy:any,onlyDemo:any,modulesCount:any}>());
export const getGoalCoursesLoaded = createAction('[app] getGoalCoursesLoaded' , props<{coursesData:any,goalIds:any}>());

export const getSuperTeachersModules = createAction('[app] getSuperTeachersModules',props<{goalIds:any}>())
export const getSuperTeachersModulesLoaded = createAction('[app] getSuperTeachersModulesLoaded',props<{teachersData:any,goalIds:any}>())

export const getResumeFlowActivities = createAction('[app] getResumeFlowActivities', props<{goalIds:any,skip:any,limit:any,sortBy:any,onlyDemo:any,modulesCount:any}>())
export const getResumeFlowActivitiesLoaded = createAction('[app] getResumeFlowActivitiesLoaded', props<{teachersData:any, gaolIds:any, coursesData:any}>())

export const updateAccountBlockedModalState = createAction('[app] Update Account Blocked Modal State',props<{data:any}>())
export const getClassesById = createAction('[app] Get Classes by goal id', props<{goalIds: string, customTagIds: string, skip: number, limit: number , courseLanguages?: string, viewMore?: boolean}>());
export const getClassesByIdSuccess = createAction('[app] Get Classes by goal id success', props<{payload: any, customTagId: string, goalIds: string, viewMore?: boolean}>());
export const resetClassesById = createAction('[app] reset classes by id', props<{goalIds:string}>());

export const getMasterSeriesByGoalId = createAction('[app] getMasterSeriesByGoalId',props<{params:any}>())
export const getMasterSeriesByGoalIdLoaded = createAction('[app] getMasterSeriesByGoalIdLoaded',props<{masterSeriesData:any,skip:any,limit:any,goalIds:any,customTagIds:any}>())

export const getGoalPageBanner = createAction('[app] getGoalPageBanner');
export const getGoalPageBannerLoaded = createAction('[app] getGoalPageBannerLoaded', props<{payload: any}>());

export const getCloudBucketData = createAction('[app] getCloudBucketData', props<{fileName: string, key?: string}>());
export const getCloudBucketDataLoaded = createAction('[app] getCloudBucketDataLoaded', props<{payload: any, key: string}>());

export const getGlobalPopupBanner = createAction('[app] getGlobalPopupBanner');
export const getGlobalPopupBannerLoaded = createAction('[app] getGlobalPopupBannerLoaded', props<{payload: any}>());


export const getPurchaseState = createAction('[app] getPurchaseState', props<{slug?: string, goalId?: string}>());
export const getPurchaseStateLoaded = createAction('[app] getPurchaseStateLoaded', props<{payload: any}>());

export const getSeoMenuById = createAction('[app] getSeoMenuById', props<{id: any, language:string}>());
export const getSeoMenuByIdLoaded = createAction('[app] getSeoMenuByIdLoaded', props<{payload: any}>());
export const showRequestCallBackModal = createAction('[app] showRequestCallBackModal');
export const hideRequestCallBackModal = createAction('[app] hideRequestCallBackModal');
export const goalPagePopupState = createAction('[app] goalPagePopupState', props<{status: any}>());

export const getPersonalisedBannerData = createAction('[app] getPersonalisedBannerData', props<{page_category:string, isMobile:boolean}>());
export const getPersonalisedBannerDataLoaded = createAction('[app] getPersonalisedBannerDataLoaded', props<{payload: any}>());

export const getExamCalendar = createAction('[app] getExamCalendar');
export const getExamCalendarLoaded = createAction('[app] getExamCalendarLoaded', props<{payload: any}>());

export const getSimilarGoalPacks = createAction('[app] getSimilarGoalPacks', props<{params: any}>());
export const getSimilarGoalPacksLoaded = createAction('[app] getSimilarGoalPacksLoaded', props<{id, payload: any}>());

export const getJarvisBanner = createAction('[app] Get Home Page Banner', props<{params: any}>());
export const getJarvisBannerLoaded = createAction('[app] Get Home Page Banner Loaded', props<{key, payload: any}>());

export const setSearchBarQuery = createAction('[app] Reset search bar query',  props<{payload: any}>());

export const setContentLanguage = createAction('[app] Set Content Language', props<{payload: any, currLang: string}>());

export const getDailyCurrentAffairs = createAction('[app] Get Daily Current Affairs',  props<{language: string, date: string, tags?: string, viewMore?: boolean, isCurDateEmpty?: boolean, lastSentNote?: string}>());
export const dailyCurrentAffairsLoaded = createAction('[app] Daily Current Affairs Loaded',  props<{payload: any, date: string, tags?: string, viewMore?: boolean}>());

export const getMonthlyCurrentAffairs = createAction('[app] Get Monthly Current Affairs',  props<{language: string, date: string, month: number, year: string, viewMore?: boolean, skip?: number , limit?: number}>());
export const monthlyCurrentAffairsLoaded = createAction('[app] Monthly Current Affairs Loaded',  props<{payload: any, date: string, month: number, year: string, viewMore?: boolean}>());

export const getPassSaleBanner = createAction('[app] getPassSaleBanner', props<{fileName: string}>());
export const getPassSaleBannerLoaded = createAction('[app] getPassSaleBannerLoaded', props<{payload: any}>());
export const showReattemptModal = createAction('[app] loaded Goal plans',props<{showModal:boolean,isReattemptAvailable?:boolean,testId?:string,interfaceType?:string}>());

export const sendClientErrorLogToBackend = createAction('[app] sendClientErrorLogToBackend', props<{payload: any}>());
export const sendClientErrorLogToBackendLoaded = createAction('[app] sendClientErrorLogToBackendLoaded', props<{payload: any}>());

export const getPopupData = createAction('[app] getPopupData', props<{popupType?: string, pageCategory?: string, goalId?: string, targetId?: string, isMobile?: boolean, isLocationBased?: boolean}>());
export const getPopupDataLoaded = createAction('[app] getPopupDataLoaded', props<{payload: any}>());

export const showPassProIntroPopup = createAction('[app] show Pass Pro Intro Popup');
export const hidePassProIntroPopup = createAction('[app] hide Pass Pro Intro Popup');

export const getPassProPageInfo =  createAction('[app] get Pass Pro Page Info', props<{pageType: string}>());
export const passProPageInfoLoaded =  createAction('[app] get Pass Pro Page Info Loaded', props<{payload: any}>());
export const getPassProPageWidgets =  createAction('[app] get Pass Pro Page Widgets', props<{widgetType: string}>());
export const passProPageWidgetsLoaded =  createAction('[app] Pass Pro Page Widgets Loaded', props<{payload: any}>());
export const setStudentGCLID =  createAction('[app] set user GCLID', props<{gclid: string}>());
export const setStudentGCLIDLoaded =  createAction('[app] get user GCLID', props<{payload: any}>());

export const setLoggedOutPurchaseModalState = createAction('[app] set logged out purchase modal open/close state',  props<{open:boolean,goalId?:string,pId?:string,pType?:string,stageOne?:boolean, coupon?:any}>());
export const setLoggedOutTransactionState = createAction('[app] setLoggedOutTransactionState',  props<{status:boolean}>());
export const setLoggedOutPurchaseModalSelectedPass = createAction('[app] set logged out purchase modal selected pass',  props<{selectedPass:any}>());
export const setLoggedOutPurchaseModalActivationCode = createAction('[app] set logged out purchase modal activation code',  props<{code:string}>());
export const setLoggedOutPurchaseModalUserDetails = createAction('[app] set logged out purchase modal user details',  props<{user:any}>());
export const setLoggedOutPurchaseModalStateUsingIFrame = createAction('[app] set logged out purchase modal open/close state using iframe',  props<{open:boolean,goalId?:string,pId?:string,pType?:string,stageOne?:boolean, coupon?:any}>());

export const setLoggedOutPurchaseModalCouponCode = createAction('[app] set logged out purchase modal activation code',  props<{code: string}>());

export const showGoalFeatureModal = createAction('[showGoalFeatureModal] showGoalFeatureModal', props<{popupData: any}>());
export const hideGoalFeatureModal = createAction('[hideGoalFeatureModal] hideGoalFeatureModal');

export const getSelectedTab = createAction('[getSelectedTab] get selected tab', props<{commonDataType: any}>());
export const getSelectedTabLoaded = createAction('[getSelectedTabLoaded] get selected tab loaded', props<{payload: any}>());
export const setEmiIframeState = createAction('[app] set logged out purchase modal open/close state',  props<EMI_WRAPPER_QUERY_PARAMS>());

export const setSelectedPass = createAction('[setSelectedPlan] Set Select Plan', props<{selectedPass}>());