import {NgModule} from '@angular/core';
import {PassUserAnnouncementComponent} from "@features/pass-user-announcement/pass-user-announcement.component";
import {CommonModule} from "@angular/common";


@NgModule({
    declarations: [PassUserAnnouncementComponent],
    imports: [
        CommonModule
    ],
    exports: [PassUserAnnouncementComponent]
})

export class PassUserAnnouncementExportModule { }