import { setLoggedOutPurchaseModalState, setLoggedOutTransactionState, setLoggedOutPurchaseModalSelectedPass, setLoggedOutPurchaseModalActivationCode, setLoggedOutPurchaseModalCouponCode, setLoggedOutPurchaseModalUserDetails, setLoggedOutPurchaseModalStateUsingIFrame } from "@angular-commons/core/application-state/app.actions";
import { createReducer, on } from "@ngrx/store";


export const loggedoutModalInitialState = {
   
    loggedoutPurchaseModalState:{
        open:false,
        stageOne:false,
        couponCode:"",
        pType:"globalPass",
        goalId:"",
        pId:"",
    },
    loggedoutPurchaseModalStateInIFrame:{
        open:false,
        stageOne:false,
        selectedPass:null,
        couponCode:"",
        userDetails:null,
        pType:"globalPass",
        goalId:"",
        pId:"",
        transactionCompleted:false,
        activationCode:""
    }
};

const _loggedoutModalReducer = createReducer(loggedoutModalInitialState,

    on(setLoggedOutPurchaseModalState,(_state, {open,goalId,pId, pType,stageOne, coupon})=>{
        let state = {..._state}
        if(open){
            let couponCode = '';
            if(coupon){
                couponCode = coupon;
            }
            state.loggedoutPurchaseModalState = {...state.loggedoutPurchaseModalState, pType: pType ,open:open,goalId:goalId,pId:pId,stageOne:!!stageOne, couponCode: couponCode}
        } else {
            state.loggedoutPurchaseModalState = {...state.loggedoutPurchaseModalState, open:open,pType:"globalPass",pId:"",goalId:"",stageOne:false, couponCode: ''}
        }

        return Object.assign({},state);
    }),
    on(setLoggedOutTransactionState,(_state, {status})=>{
        let state = {..._state};
        state.loggedoutPurchaseModalStateInIFrame = {...state.loggedoutPurchaseModalStateInIFrame,transactionCompleted:status }
        return Object.assign({},state);
    }),
    on(setLoggedOutPurchaseModalSelectedPass,(_state, {selectedPass})=>{
        let state = {..._state}
        state.loggedoutPurchaseModalStateInIFrame = {...state.loggedoutPurchaseModalStateInIFrame, selectedPass:selectedPass}
        return Object.assign({},state);
    }),
    on(setLoggedOutPurchaseModalActivationCode,(_state, {code})=>{
        let state = {..._state}
        state.loggedoutPurchaseModalStateInIFrame = {...state.loggedoutPurchaseModalStateInIFrame, activationCode:code}
        return Object.assign({},state);
    }),
    on(setLoggedOutPurchaseModalCouponCode,(_state, {code})=>{
        let state = {..._state}
        state.loggedoutPurchaseModalStateInIFrame = {...state.loggedoutPurchaseModalStateInIFrame, couponCode:code}
        return Object.assign({},state);
    }),
    on(setLoggedOutPurchaseModalUserDetails,(_state, {user})=>{
        let state = {..._state}
        state.loggedoutPurchaseModalStateInIFrame = {...state.loggedoutPurchaseModalStateInIFrame, userDetails:user}
        return Object.assign({},state);
    }),
    on(setLoggedOutPurchaseModalStateUsingIFrame,(_state, {open,goalId,pId, pType,stageOne, coupon})=>{
        let state = {..._state};
        if(open){
            state.loggedoutPurchaseModalStateInIFrame = {...state.loggedoutPurchaseModalStateInIFrame, pType: pType ,open:open,goalId:goalId,pId:pId,stageOne:!!stageOne, couponCode: coupon || ''}
        } else {
            state.loggedoutPurchaseModalStateInIFrame = {...state.loggedoutPurchaseModalStateInIFrame, open:open,pType:"globalPass",pId:"",goalId:"",transactionCompleted:false,stageOne:false, couponCode: ''}
        }
        return Object.assign({},state);
    }),
);

export function loggedoutModalReducer(state, action) {
    return _loggedoutModalReducer(state, action);
}
