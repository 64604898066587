<div class="popup">
    <div class="popup__content">
        <button class="popup__close" *ngIf="!disableCloseBtn" (click)="handleCloseModal()"></button>
        <div class="popup__header">
            <div class="h3">Auto EMI Payment</div>
        </div>
        <div class="popup__body">
            <div class="h4">Before we continue, please enter your details</div>
            <form [formGroup]="mandateForm" (ngSubmit)="onSubmit()" novalidate>
                <div class="form-group">
                    <div class="form-group__holder">
                        <svg width="16" height="16"><use  xlink:href="#user-icon"></use></svg>
                        <input class="form-control" type="text" formControlName="name" placeholder="Enter your Full Name" [ngClass]="{'error': message && message.name}">
                    </div>
                    <div class="text-danger text-sm" *ngIf="message && message.name">{{ message.name}}</div>
                    <div>As per your bank account</div>
                </div>
                <div class="form-group">
                    <div class="form-group__holder">
                        <svg width="16" height="16"><use  xlink:href="#phone-icon"></use></svg>
                        <input class="form-control" type="text" formControlName="mobile" placeholder="Enter your Number" [ngClass]="{'error': message && message.mobile}">
                    </div>
                    <div class="text-danger text-sm" *ngIf="message && message.mobile">{{ message.mobile}}</div>
                    <div>Registered with your bank account</div>
                </div>
                <div class="popup-helper">
                    <svg width="17" height="16">
                        <use  xlink:href="#secure-icon"></use>
                    </svg> This process is completely secure
                </div>
                <button type="submit" class="btn btn-secondary" [disabled]="mandateForm.invalid">Continue</button>
            </form>
        </div>
        <div class="popup__footer">
            <a href="mailto:support@testbook.com">Need help? Contact us at support@testbook.com</a>
        </div>
    </div>
</div>


<svg style="display: none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <symbol id="user-icon" viewBox="0 0 16 16">
        <g id="People">
            <g id="Union">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8 2C6.60167 2 5.5 3.17168 5.5 4.57692C5.5 5.27164 5.76735 5.9842 6.19206 6.52679C6.61533 7.06754 7.24684 7.5 8 7.5C8.75316 7.5 9.38467 7.06754 9.80794 6.52679C10.2326 5.9842 10.5 5.27164 10.5 4.57692C10.5 3.17168 9.39833 2 8 2ZM6.5 4.57692C6.5 3.68806 7.18919 3 8 3C8.81081 3 9.5 3.68806 9.5 4.57692C9.5 5.02926 9.31963 5.52824 9.02049 5.91042C8.7199 6.29444 8.35141 6.5 8 6.5C7.64859 6.5 7.2801 6.29444 6.97951 5.91042C6.68037 5.52824 6.5 5.02926 6.5 4.57692Z" fill="#5B5F69"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8 8.5C6.18244 8.5 4.47138 9.35741 3.3834 10.8134L3.1782 11.088C2.28055 12.2893 3.1378 14 4.63741 14H11.3626C12.8622 14 13.7194 12.2893 12.8218 11.088L12.6166 10.8134C11.5286 9.35741 9.81756 8.5 8 8.5ZM4.18446 11.412C5.08365 10.2086 6.49781 9.5 8 9.5C9.50218 9.5 10.9163 10.2086 11.8155 11.412L12.0207 11.6866C12.4256 12.2284 12.039 13 11.3626 13H4.63741C3.96103 13 3.57438 12.2284 3.97926 11.6866L4.18446 11.412Z" fill="#5B5F69"/>
            </g>
        </g>
    </symbol>
    <symbol id="phone-icon" viewBox="0 0 16 16">
        <g xmlns="http://www.w3.org/2000/svg" id="053-Phone">
            <path id="Vector" d="M8.5 16.048H1.5C1.10218 16.048 0.720644 15.8844 0.43934 15.5931C0.158035 15.3019 0 14.9069 0 14.495V2.0715C0 1.65963 0.158035 1.26463 0.43934 0.973401C0.720644 0.682168 1.10218 0.518555 1.5 0.518555H8.5C8.89782 0.518555 9.27936 0.682168 9.56066 0.973401C9.84196 1.26463 10 1.65963 10 2.0715V14.495C10 14.9069 9.84196 15.3019 9.56066 15.5931C9.27936 15.8844 8.89782 16.048 8.5 16.048ZM1.5 1.55385C1.36739 1.55385 1.24021 1.60839 1.14645 1.70546C1.05268 1.80254 1 1.93421 1 2.0715V14.495C1 14.6323 1.05268 14.764 1.14645 14.8611C1.24021 14.9581 1.36739 15.0127 1.5 15.0127H8.5C8.63261 15.0127 8.75979 14.9581 8.85355 14.8611C8.94732 14.764 9 14.6323 9 14.495V2.0715C9 1.93421 8.94732 1.80254 8.85355 1.70546C8.75979 1.60839 8.63261 1.55385 8.5 1.55385H1.5Z" fill="#656F79"/>
            <path id="Vector_2" d="M5.25 13.9767H4.75C4.6837 13.9767 4.62011 13.9222 4.57322 13.8251C4.52634 13.728 4.5 13.5963 4.5 13.4591C4.5 13.3218 4.52634 13.1901 4.57322 13.093C4.62011 12.9959 4.6837 12.9414 4.75 12.9414H5.25C5.3163 12.9414 5.37989 12.9959 5.42678 13.093C5.47366 13.1901 5.5 13.3218 5.5 13.4591C5.5 13.5963 5.47366 13.728 5.42678 13.8251C5.37989 13.9222 5.3163 13.9767 5.25 13.9767Z" fill="#656F79"/>
        </g>
    </symbol>
    <symbol id="secure-icon" viewBox="0 0 17 16">
        <g id="secure 1">
            <path id="Vector" d="M15.4488 2.23927C15.42 2.03298 15.2716 1.86329 15.071 1.80751L8.64029 0.0191373C8.54861 -0.00637908 8.45177 -0.00637908 8.36002 0.0191373L1.92935 1.80751C1.7287 1.86329 1.58028 2.03291 1.55155 2.23927C1.51425 2.50747 0.66336 8.84444 2.84584 11.9969C5.02575 15.1456 8.24157 15.9525 8.37738 15.9853C8.41775 15.9951 8.45888 15.9999 8.50015 15.9999C8.54143 15.9999 8.58256 15.995 8.62293 15.9853C8.7588 15.9525 11.9746 15.1456 14.1545 11.9969C16.3369 8.84451 15.4861 2.50754 15.4488 2.23927ZM12.6471 5.93886L8.26075 10.3252C8.15868 10.4273 8.02483 10.4784 7.89104 10.4784C7.75725 10.4784 7.6234 10.4274 7.52133 10.3252L4.80928 7.61318C4.71119 7.51515 4.65611 7.38213 4.65611 7.24347C4.65611 7.1048 4.71126 6.97178 4.80928 6.87376L5.34777 6.33527C5.55197 6.13114 5.88306 6.13107 6.08719 6.33527L7.89104 8.13912L11.3692 4.66088C11.4672 4.56279 11.6002 4.50771 11.7389 4.50771C11.8776 4.50771 12.0106 4.56279 12.1086 4.66088L12.6471 5.19937C12.8513 5.40357 12.8513 5.73466 12.6471 5.93886Z" fill="#12B870"/>
        </g>
    </symbol>
</svg>
