<div class="wrapper" *ngIf="!isInModal && !isForCoaching && !showDynamicComponent">
    <div class="card card--skill">
        <h2 [innerHTML]="'BOOK_COUNSELLING_SESSION_WITH_EXPERTS' | translate"></h2>
        <div *ngIf="!bannerMeta.submitted">
            <p translate>{{'RECEIVE_CALL_FROM_EXPERTS'}}</p>
            <div class="card__input-holder">
                <label class="card__input">
                    <input type="text" [(ngModel)]="mobile" placeholder="{{'PHONE_NO' | translate}}" cb-persist="mobilenoinput" (keyup)="mobileKeys($event)" (keydown)="mobileKeys($event)">
                </label>
                <button class="btn" (click)="requestCallback()" cb-await="" cb-await-id="requestcallbackcta" translate>{{'SUBMIT'}}</button>
            </div>
        </div>
        <div *ngIf = "bannerMeta.submitted">
            <h4 translate>{{'RECEIVED_CALL_DETAILS'}}</h4>
            <div>
                <span [innerHtml]="'EXPERT_WILL_CALL_SOON' | translate: {'mobile' : bannerMeta.mobile }"></span>
                <a href="javascript:void(0)" (click)="changeNumber()" cb-await="" cb-await-id="changenumbercta" translate>{{'CHANGE_NUMBER'}}</a>
            </div>
        </div>
    </div>
</div>

<div class="wrapper" *ngIf="!isInModal && showDynamicComponent">
    <div class="card card--tutor">
        <img loading="lazy" *ngIf="icon?.lightIcon" class="card__img" [src]="icon?.lightIcon" alt="">
        <img loading="lazy" *ngIf="!icon?.lightIcon" class="card__img" src="/assets/img/super/study-councelling.svg" alt="">
        <div class="card__details">
            <span class="badge-free">FREE</span>
            <div class="h3" *ngIf="title">{{title}}</div>
            <div class="h3" *ngIf="!title" [innerHTML]="'BOOK_COUNSELLING_SESSION_WITH_EXPERTS' | translate"></div>
            <p *ngIf="description" [innerHTML]="description | safeHtml"></p>
            <p *ngIf="!description" [innerHTML]="'RECEIVE_CALL_FROM_EXPERTS' | translate"></p>
            <div class="card__action">
                <div class="form-group" *ngIf="!bannerMeta?.submitted">
                    <label>+91<input type="text" [(ngModel)]="mobile" placeholder="{{'PHONE_NO' | translate}}" cb-persist="mobilenoinput" (keyup)="mobileKeys($event)" (keydown)="mobileKeys($event)"></label>
                    <button class="btn" (click)="requestCallback()" cb-await="" cb-await-id="requestcallbackcta" translate>{{ctaText?.text || 'BOOK_FREE_CALL'}}</button>
                </div>
                <div class="form-sucess" *ngIf="bannerMeta?.submitted">
                    <img class="form-sucess-img" src="./assets/img/ui-kit/green-tick.svg" alt="" >
                    <div class="form-sucess-text">
                        <div class="h4">Your Free Session is Booked</div>
                        <span class="text-dark-sm">Our expert will contact you soon </span>
                    </div>
                </div>
                <div class="text-light-sm text-bottom" *ngIf="isNotPlutus">
                    Or Contact <p class="h5"><img width="12" height="12" loading="lazy" src="/assets/img/super/phone-call-black.svg" alt="call">1800 203 0577</p>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="wrapper" *ngIf="!isInModal && isForCoaching && !showDynamicComponent"> 
    <div class="card card--goal" [ngStyle]="from === 'plutus' ? {'background': 'linear-gradient(95.69deg, #0197F6 -10.5%, #0F3F99 130.88%)'} : null">
        <div class="h2">
            <span [innerHTML]="'BOOK_COUNSELLING_SESSION_WITH_EXPERTS' | translate" [ngClass]="{'text-nowrap': isMobile}"></span>
        </div>
        <p translate *ngIf="!bannerMeta.submitted" translate>{{'RECEIVE_CALL_FROM_EXPERTS'}}</p>
        <div class="card__action" *ngIf="!bannerMeta.submitted">
            <div class="form-group">
                <label>+91<input type="text" [(ngModel)]="mobile" placeholder="{{'PHONE_NO' | translate}}" cb-persist="mobilenoinput" (keyup)="mobileKeys($event)" (keydown)="mobileKeys($event)"></label>
                <button class="btn" [ngStyle]="from === 'plutus' ? {'background': 'white', color: 'black'} : null" (click)="requestCallback()" cb-await="" cb-await-id="requestcallbackcta" translate>{{'BOOK_FREE_CALL'}}</button>
            </div>
            <div class="contact-us" *ngIf="!isMobile && isNotPlutus">
                Or Contact <p class="h5"><img src="/assets/img/super/call-white.svg" alt="call">1800 203 0577</p>
            </div>
        </div>
        <div class="card__submitted" *ngIf="bannerMeta.submitted">
            <div class="h4" translate>{{'RECEIVED_CALL_DETAILS'}}</div>
            <div class="text-submited">
                <span [innerHtml]="'EXPERT_WILL_CALL_SOON' | translate: {'mobile' : bannerMeta.mobile }"></span>
                <a href="javascript:void(0)" (click)="changeNumber()" cb-await="" cb-await-id="changenumbercta" translate>{{'CHANGE_NUMBER'}}</a>
            </div>
        </div>

        <img *ngIf="from !== 'plutus'" class="card__img" src="/assets/img/super/call-back-goal.svg" alt="img">
        <img *ngIf="from === 'plutus'" class="card__img" src="/angular/assets/img/plutus-call.png" alt="img">
    </div>
</div>


<div class="modal modal--callback show" *ngIf="isInModal && showModal">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-close" (click)="closeModal()"></div>
            <div *ngIf="!bannerMeta.submitted" class="modal-body modal-body--normal">
                <h3 *ngIf="!course?.isSkillCourse && course.source != 'plutus'" class="text-title" [innerHTML]="'PLAN_CALLBACK_TITLE' | translate :{'planTitle':goal?.properties?.title} | safeHtml"></h3>
                <h3 *ngIf="course?.isSkillCourse || course.source == 'plutus'" class="text-title">Book a Free Career Counselling Session</h3>
            <!--        for without translation-->
            <!-- <h3 class="text-title"><span>Want to know the</span> best way <span>to</span> prepare for {{goal?.properties?.title}}</h3>-->
                <p class="text-sub-title" translate>{{'PLAN_CALLBACK_SUBTITLE'}}</p>
                <img class="callback-illus" src="/assets/img/pass/callback-illus.svg" alt="callback-illus">
                <div class="form-blurb">
                    <div class="form-blurb__input">
                        +91 <input type="text" [(ngModel)]="mobile" placeholder="{{'PHONE_NO' | translate}}" cb-persist="mobilenoinput" (keyup)="mobileKeys($event)" (keydown)="mobileKeys($event)">
                    </div>
                    <button class="btn" (click)="requestCallback()" cb-await="" cb-await-id="requestcallbackcta" translate>{{'SUBMIT'}}</button>
                </div>
                <p *ngIf="isNotPlutus" class="text-help" [innerHTML]="'CONTACT_US' | translate"></p>
            </div>
            <div *ngIf="bannerMeta.submitted" class="modal-body modal-body--submitted">
                <p><img src="/assets/img/coupon/green-tick.svg" alt="success-icon" width="64" height="64"></p>
                <h3 class="text-title" translate>{{'PLAN_REGI_SUCCESS'}}</h3>
                <p class="text-sub-title" translate="">{{'MENTORS_CALL_YOU'}}</p>
                <p class="text-help" *ngIf="isNotPlutus"><span translate="">{{'WE_CALL_YOU'}} </span>
                    <img src="/assets/img/ui-kit/call-dark.svg" alt="call-icon">
                    <b> {{bannerMeta.mobile}} <a href="javascript:void(0)" (click)="changeNumber()" cb-await="" cb-await-id="changenumbercta" translate>{{'CHANGE_NUMBER'}}</a></b>
                </p>
            </div>
        </div>
    </div>
    <div class="modal-backdrop" (click)="closeModal()"></div>
</div>
