import { PlatformService } from '@angular-commons/core/services/platform-service';
import { getQueryStringFromParams } from '@angular-commons/shared/utility/url';
import { Component, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { changeFormStage } from 'angular-tb-ssr/src/app/route-modules/selections-page/application-state/SelectionsActions';

@Component({
  selector: 'ecards',
  templateUrl: './ecards.component.html',
  styleUrls: ['./ecards.component.scss']
})
export class EcardsComponent {
  @Input() activationCode: string ;
  @Input() userDetails: any ;
  @Input() selectedPass: any ;
  url:string="/e-cards";
  host :string = this.platformService.getHost();
  isCodeCopied:boolean = false;
  isUrlCopied:boolean = false;
  productTitle = 'Testbook PASS';

  constructor(private router: Router,private platformService: PlatformService) { }

  ngOnInit(): void {
      if(this.selectedPass?.type === 'goalSubs'){
          this.productTitle = 'SuperCoaching';
      } else if(this.selectedPass?.type === 'passPro'){
          this.productTitle += ' PRO';
      }
  }

  redirectToEcards($event){
    this.platformService.redirect(this.generateURL(false) ,302,true);
  }

  generateURL(fullPath:boolean):string{
    let qp  = getQueryStringFromParams({code:this.activationCode});
    let urlRedirect;
    if(!fullPath){
      urlRedirect=`/login?m=`+this.userDetails.phone+`&redirect_url=` + this.url + qp;
    }else{
      urlRedirect= this.platformService.getProtocol() + this.platformService.getHost() +`/login?m=`+this.userDetails.phone+`&redirect_url=` + this.url + qp;
    }
    return urlRedirect;
  }

   copyToClipboard(text:string,isCouponCopied:boolean) {
    if(isCouponCopied){
      this.isCodeCopied = true;
    }else{
      this.isUrlCopied = true;
    }
    var input = document.createElement('input');
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
  }
}
