//This is a seperate file so that any angular-only changes can be made here ./model-manager must remain a TS / ES6 module with no angular dependancy

import {Injectable, NgZone} from '@angular/core';
import { ModelManager as _ModelManager , Network} from './model-manager/main';
import { NetworkService } from "./network.service";
import { PlatformService } from './platform-service';
import {isServer} from "@shared/utility/tb-common";

@Injectable()
export class ModelManager extends _ModelManager{
    network = {};
    platformService = {};
    constructor(networkService: NetworkService, platformService: PlatformService, ngZone : NgZone){
        super(isServer());
        if(isServer()){
            ngZone.runOutsideAngular( () => {
                const global = (globalThis as any);
                if (!global.hasTimer) {
                    global.clearInterval(global.cachePruneTimer);
                    global.cachePruneTimer = global.setInterval(() => {
                        global.globalCache.checkSizeAndPrune(20);
                    }, 10000);
                    global.hasTimer = true;
                }
            });
        }
        this.network = networkService;
        this.platformService = platformService;
    }
}
