import {BrowserModule, TransferState} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {SeoService} from "@core/services/seo.service";
import {NetworkService} from "@core/services/network.service";
import {ModelManager} from "@core/services/model-manager.service";
import {AuthenticationService} from "@core/services/authentication.service";
import {TbcoreService, TbcoreService as TBCore} from "@core/services/tbcore.service";
import {EffectsModule} from "@ngrx/effects";
import {AppEffects} from "@core/application-state/app.effects";
import {StoreModule} from "@ngrx/store";
import {appReducer} from "@core/application-state/app.reducers";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {environment} from "@angular-commons/environments/environment";
import {TrackerService} from "@angular-commons/core/services/tracker.service";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {TimerService} from "@core/services/timer.service";
import {WildCardRoutesGuard} from "./route-guards/wild-card-routes.guard";
import {CoreClientModule} from "@core/core.client.module";
import { GlobalSearchBarComponent } from '@angular-commons/feature-modules/header/components/global-search-bar/global-search-bar.component';
import { SideNavComponent } from '@angular-commons/feature-modules/header/components/side-nav/side-nav.component';
import { LanguageSelectorComponent } from '@angular-commons/feature-modules/header/components/language-selector/language-selector.component';
import { ProfileDropdownComponent } from '@angular-commons/feature-modules/header/components/profile-dropdown/profile-dropdown.component';
import {customTranslateLoader, langChangeListener} from "@shared/utility/translation-loaders";
import {HeaderModule} from "@features/header/header.module";
import { EnrolledExamsModule } from '../../../angular-commons/feature-modules/enrolled-exams/enrolled-exams.module';
import {PlatformService} from "@core/services/platform-service";
import {FooterModule} from "@features/footer/footer.module";
import {StickyFooterModule} from "../../../angular-commons/feature-modules/sticky-footer/sticky-footer.module";
import {TestSeriesGuard} from "./route-guards/test-series-guard";
import {MobileVerificationModule} from "@features/mobile-verification-module/mobile-verification.module";
import {ScholarshipGuard} from "./route-guards/scholarship-guard";
import {MasterSeriesGuard} from './route-guards/master-series-guard';
import {SelectionsGuard} from './route-guards/selections-guard';
import {PassPageGuard} from './route-guards/pass-page-guard';
import {MasterClassLessonGuard} from './route-guards/master-class-lesson-guard';
import { SelectPageGuard } from './route-guards/select-page-guard';
import {ViewportScroller} from "@angular/common";
import {Router, Scroll, UrlSerializer} from "@angular/router";
import {filter} from "rxjs/operators";
import {isServer} from "@shared/utility/tb-common";
import {CustomUrlSerializer} from "@core/customSerializer";
import { OnboardingGuard } from './route-guards/onboarding-guard';
import {WildCardChildRoutesGuard} from "./route-guards/wild-card-child-routes.guard";
import { ExploreTestsQuizzesGuard } from './route-guards/explore-tests-quizzes-guard';
import { MasterExploreGuard } from './route-guards/master-explore-gurad';
import { MasterExploreChildGuard } from './route-guards/master-explore-child-guard';
import { EducatorGuard } from './route-guards/educator-guard';
import {GoalSelectionGuard} from "./route-guards/goal-selection-guard";
import {GoalPlansGuard} from "./route-guards/goal-plans-guard";
import { EducatorsGuard } from './route-guards/educators-guard';
import { HomeGuard } from './route-guards/home-guard';
import {ProductAccessService} from "@core/services/product-access.service";
import {RequiresLoginGuard} from "./route-guards/requires-login-guard";
import { TestPromotionGuard } from './route-guards/test-promotion-guard';
import { AccountBlockModule } from '@angular-commons/feature-modules/account-block/account-block.module';
import {WebSignupStickyFooterModule} from '@angular-commons/feature-modules/web-signup-sticky-footer/web-signup-sticky-footer.module';
import {getTextFragment} from "@shared/utility/url";
import { MobileSignupAndVerificationWrapperModule } from '@angular-commons/feature-modules/mobile-signup-and-verification-wrapper/mobile-signup-and-verification-wrapper.module';
import {PassProPageGuard} from "./route-guards/pass-pro-page-guard";
import { StudentService } from '@angular-commons/core/services/student.service';
import { LoggedOutPurchaseIframeWrapperModule } from './route-modules/logged-out-purchase/logged-out-purchase-wrapper.module';
import { MentorshipGuard } from './route-guards/mentorship.guard';
import {EmiModule} from "./route-modules/emi/emi.module";
export function translateLoaderFactory(http: HttpClient,transferState :TransferState) {
    return new customTranslateLoader(http,transferState,'global');
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        WebSignupStickyFooterModule,
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        CoreClientModule,
        MobileSignupAndVerificationWrapperModule,
        FormsModule,
        HeaderModule,
        FooterModule,
        AccountBlockModule,
        StickyFooterModule,
        LoggedOutPurchaseIframeWrapperModule,
        EmiModule,
        EffectsModule.forRoot([AppEffects]),
        StoreModule.forRoot({
            app: appReducer
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoaderFactory,
                deps: [HttpClient, TransferState]
            }
        }),
        // Instrumentation must be imported after importing StoreModule (config is optional)
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production // Restrict extension to log-only mode
        }),
        EnrolledExamsModule,
        MobileVerificationModule
    ],
    bootstrap: [AppComponent],
    providers: [
        SeoService,
        NetworkService,
        TrackerService,
        StudentService,
        ModelManager,
        AuthenticationService,
        ProductAccessService,
        TBCore,
        TimerService,
        RequiresLoginGuard,
        WildCardRoutesGuard,
        WildCardChildRoutesGuard,
        TestSeriesGuard,
        ScholarshipGuard,
        MasterSeriesGuard,
        EducatorGuard,
        EducatorsGuard,
        SelectPageGuard,
        PassPageGuard,
        PassProPageGuard,
        ExploreTestsQuizzesGuard,
        SelectionsGuard,
        MasterClassLessonGuard,
        TestPromotionGuard,
        OnboardingGuard,
        MasterExploreGuard,
        MasterExploreChildGuard,
        GoalSelectionGuard,
        GoalPlansGuard,
        MentorshipGuard,
        HomeGuard,
        { provide: UrlSerializer, useClass: CustomUrlSerializer}
    ],
    exports: [
        AppComponent,
        HeaderModule,
        SideNavComponent,
        GlobalSearchBarComponent,
        LanguageSelectorComponent,
        ProfileDropdownComponent,
    ]
})
export class AppModule {
    fragment;
    constructor(
        private translateService:TranslateService,
        private tbcore:TbcoreService,
        private platformService: PlatformService,
        private router: Router,
        private viewportScroller: ViewportScroller) {
        langChangeListener(this.translateService,this.tbcore, this.platformService);
        if(!isServer()){
            setTimeout(()=>{
                (<any>window).enableAngularScrollRestoration = true;
            },500);
            this.fragment = getTextFragment();
        }
        this.fragment = getTextFragment();
        router.events.pipe(
            filter((e: any): e is Scroll => {
                return e instanceof Scroll;
            })
        ).subscribe(e => {
            if(!isServer() && !(<any>window).enableAngularScrollRestoration) { return; }
            if (e.position) {
                // backward navigation
                viewportScroller.scrollToPosition(e.position);
            } else if (e.anchor) {
                // anchor navigation
                viewportScroller.scrollToAnchor(e.anchor);
            }
            else if(this.fragment && this.fragment.includes('#:~:text=')){
                // text highlighting to be done
            }
            else {
                // forward navigation
                viewportScroller.scrollToPosition([0, 0]);
            }
        });
    }
}
