import {EMPTY, Observable} from "rxjs";

export class Validator {
    public static str(value:string){
        return value || "";
    }

    public static resolvedPromise(value: Promise<any>) {
        let resPromise = new Promise<any>( (resolve, reject) => {resolve(undefined)}).catch(() => {});
        return value || resPromise;
    }

    public static rejectedPromise(value: Promise<any>) {
        let rejPromise = new Promise<any>( (resolve, reject) => {reject(undefined)}).catch(() => {});
        return value || rejPromise;
    }

    public static obs(value: Observable<any>) {
        return value || EMPTY;
    }

    public static num(value:number){
        return value || 0;
    }

    public static defaultNum(value: number) {
        return (val: number) => {
            return val || value;
        }
    }

    public static bool(value:boolean){
        return value || false;
    }

    public static arr(value:any){
        return value || []
    }

    public static ObjArr(value:any,forceNotEmpty){
        if(forceNotEmpty){
            return value || [{}]
        }
        return value || []
    }

    public static obj(value:any){
        return value || {};
    }

    public static date(value:Date){
        return (value && new Date(value)) || new Date(0);
    }

    public static backgroundImage(value :string){ // replace brackets in url to encoded versions to allow use in ngStyle
        return Validator.iconUrl(value).replace("(","%28").replace(")","%29");
    }

    public static profilePic(value: string) {
        return value || 'angular/assets/img/default-avatar.svg'
    }

    public static iconUrl(value: string) {
        return value || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
    }

    public static isAValidMobile(mobile: string) {
        return (/^[6-9]\d{9}$/). test(mobile);
    }

    public static logoValidator(value: string) {
        return value || 'https://cdn.testbook.com/mailer/common/white-icon.png'
    }

    //not super accurate but works for basic cases
    public static url(url:string){
        if(url && (url.startsWith("http") || url.startsWith("//"))){
            return url;
        }
        try {
            new URL(url);
        } catch (_) {
            return "";
        }
        return url;
    }


    public static safeJsonParse(json){
        try{
            json = json || '{}'
            return JSON.parse(json)
        }
        catch(e){
            console.error("Invalid JSON",e);
            return {};
        }
    }

    public static numToFixed(value:number){
        return value && parseFloat(value?.toFixed(1)) || 0;
    }

    public static formatNumberWithCommas(number) {
        let localNumber=Number(number);
        if(isNaN(localNumber) || typeof localNumber !== 'number') return 0;
        const formattedNumber = localNumber.toLocaleString('en-IN', { maximumFractionDigits: 0 });
        return formattedNumber;
    }


    public static formatNumberInLakhsAndCrores(number) {
        // Check if the input is a valid number
        if (isNaN(number) || typeof number !== 'number') {
            return 'Invalid input. Please provide a number.';
        }

        // If the number is less than 1000, simply return the number
        if (number < 1000) {
            return `${number}`;
        }

        // If the number is less than 100000, format with a comma for thousands
        if (number < 100000) {
            return number.toLocaleString('en');
        }
    
        // Convert the number to crores and lakhs
        const crore = Math.floor(number / 10000000);
        const remainingAfterCrore = number % 10000000;
        const lakh = Math.floor(remainingAfterCrore / 100000);
    
        // Format the result
        let result = '';
    
        if (crore > 0) {
            result += `${crore}`;
            const decimalPart = remainingAfterCrore % 10000000 / 10000000; // Corrected calculation for decimal part in crores
            if (decimalPart > 0) {
                const formattedDecimal = decimalPart.toLocaleString('en', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
                result += `.${formattedDecimal.split('.')[1]}`; // Ensure one decimal place
            }
            result += ' Crore';
        } else if (lakh > 0) {
            result += `${lakh} Lakhs`;
        }
    
        // Remove trailing ".0" if present
        result = result.replace(/\.0$/, '');
    
        return result.trim();
    }
}


export function isValidEmail(email){
    var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
    return pattern.test(email);
};
export function isValidMobile(mobile){
    let tempNumber = mobile;
    if(mobile[0]==='0'){
        tempNumber = mobile.slice(1,11);
    }
    if(tempNumber.length === 10 && '6789'.includes(tempNumber[0])){
        return true;
    }
    return false;
}

export function getValidMobileNumber(mobile){
    if(mobile[0]==='0'){
        return mobile.slice(1,11);
    }else{
        return mobile.slice(0,10);
    }
}

export function nonEmptyParams(params){
    let _params : any = {};
    Object.keys(params).forEach( key =>{
        if(params[key] !== ""){
            _params[key] = params[key]
        }
    })
    return _params;
}

//this is intended to be a pure function
export function filterObjectKeys(obj,keysToremove=[]){
    let finalObj : any = {};
    Object.keys(obj).filter( key => {
       return !keysToremove.includes(key);
    }).forEach(key => {
        finalObj[key] = obj[key];
    });
    return finalObj;
}

export function retainObjectKeys(obj, keysToRetain = []) {
    let finalObj : any = {};
    Object.keys(obj).filter( key => {
        return keysToRetain.includes(key);
    }).forEach(key => {
        finalObj[key] = obj[key];
    });
    return finalObj;
}