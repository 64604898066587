import { Network } from "@angular-commons/core/services/model-manager/main";
import { arrayModel, keyFromObject, objModel, pipeModel, propertyMaps, SourceProjectionMapper } from "@angular-commons/models/mapping-framework";
import { baseModel, setupModel } from "@angular-commons/models/types";
import { Validator as _ } from "../../../shared/utility/validator";


export class MetaTag extends baseModel{
    @propertyMaps('title', _.str)
    public title
    @propertyMaps('description', _.str)
    public description
    @propertyMaps('ogTitle', _.str)
    public ogTitle
    @propertyMaps('ogDescription', _.str)
    public ogDescription
    @propertyMaps('ogImage', _.str)
    public ogImage
    @propertyMaps('twitterTitle', _.str)
    public twitterTitle
    @propertyMaps('twitterDescription', _.str)
    public twitterDescription
    @propertyMaps('noIndex', _.bool)
    public noIndex
    @propertyMaps('canonical', _.str)
    public canonical
    @propertyMaps('contentBlockOnTop', _.str)
    public contentBlockOnTop
    @propertyMaps('contentBlockAtEnd', _.str)
    public contentBlockAtEnd
    @propertyMaps('otherRelatedTopics', _.arr)
    public otherRelatedTopics
    @propertyMaps('otherRelatedSubject', _.arr)
    public otherRelatedSubject
    @propertyMaps('otherRelatedChapter', _.arr)
    public otherRelatedChapter
  }
  setupModel(MetaTag, {});
  
  export class SeoProperties extends baseModel{
    @propertyMaps('slug', _.str)
    public slug
    @propertyMaps('meta', objModel(MetaTag),MetaTag)
    public metaTag
  }
  setupModel(SeoProperties, {});
export class Properties extends baseModel {
    @propertyMaps('title', _.str)
    public title;
    @propertyMaps('logo', _.str)
    public logo
    @propertyMaps('seoProperties', objModel(SeoProperties),SeoProperties)
    public seoProperties
}
setupModel(Properties, {});

export class Meta extends baseModel {

    @propertyMaps('questionCount', _.num)
    public totalQuestions;

    @propertyMaps('noteCount', _.num)
    public totalNotes;
}
setupModel(Meta, {});

export class Summary extends baseModel {

    @propertyMaps('completedQuestionCount', _.num)
    public completedQuestions;

    @propertyMaps('completedNoteCount', _.num)
    public completedNotes;
}
setupModel(Summary, {});

export class ChapterModel extends baseModel {
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('properties', objModel(Properties), Properties)
    public properties;

    @propertyMaps('meta', objModel(Meta), Meta)
    public meta;

    @propertyMaps('summary', objModel(Summary), Summary)
    public summary;
    @propertyMaps('groupId', _.str)
    public groupId
    @propertyMaps('groupSlug', _.str)
    public groupSlug
}
setupModel(ChapterModel, {});


export class ChaptersModel extends baseModel {
    @propertyMaps('chapters', pipeModel(arrayModel(ChapterModel), processChapters), ChapterModel)
    public chapters;
}
setupModel(ChaptersModel, {});

export class ChapterWrapperModel extends baseModel {
    @propertyMaps('chapter', pipeModel(objModel(ChapterModel), processChapter), ChapterModel)
    public chapter;
}
setupModel(ChapterWrapperModel, {});

export interface CountStats {
    total: number,
    completed: number,
    progressPercentage: number,
}
export interface Chapter {
    id: string,
    title: string,
    chapterSlug?: string,
    questionsStats?: CountStats,
    notesStats?: CountStats,
    groupId?: string,
    groupSlug?: string;
    properties?: Properties,
}

export function processChapters(chapters: ChapterModel[]) {
    if (!chapters || chapters.length == 0) {
        return new Array<Chapter>();
    }
    let processedChapters: Array<Chapter | {}> = chapters.map(chapter => {
        return processChapter(chapter);
    })  
    return processedChapters;
}

export function processChapter(chapter: ChapterModel) {
    if (!chapter) {
        return {};
    }
    let processedChapter: Chapter =  {
            id: chapter.id,
            title: chapter.properties.title,
            chapterSlug:chapter.properties?.seoProperties?.slug,
            questionsStats: {
                total: chapter.meta.totalQuestions,
                completed: chapter.summary.completedQuestions,
                progressPercentage: (100*chapter.summary.completedQuestions)/chapter.meta.totalQuestions,
            },
            notesStats: {
                total: chapter.meta.totalNotes,
                completed: chapter.summary.completedNotes,
                progressPercentage: (100*chapter.summary.completedNotes)/chapter.meta.totalNotes,
         },
        properties: chapter?.properties,
        groupId: chapter?.groupId,
        groupSlug: chapter?.groupSlug
        }
    return processedChapter;
}
export class GetRecentChaptersApi {
    static apiEndpoint = 'v2/study-tab/recent-chapters';
  
    static projection;
    static get  __projection(){ 
        if(!GetRecentChaptersApi.projection){
            GetRecentChaptersApi.projection = JSON.stringify(new SourceProjectionMapper(ChaptersModel).map());
        }
        return GetRecentChaptersApi.projection;
    }
  
    static apiCall(network: Network, params) {
      return network.get(GetRecentChaptersApi.apiEndpoint, { groupId: params.groupId, type: params.metaType, __projection: GetRecentChaptersApi.__projection });
    }
}

export class GetChaptersBySubjectApi {
    static apiEndpoint = 'v2/study-tab/subjects/{subjectId}/chapters';
  
    static projection;
    static get  __projection(){ 
        if(!GetChaptersBySubjectApi.projection){
            GetChaptersBySubjectApi.projection = JSON.stringify(new SourceProjectionMapper(ChaptersModel).map());
        }
        return GetChaptersBySubjectApi.projection;
    }
  
    static apiCall(network: Network, params: {subjectId: string, metaType: string, limit : number}) {
      return network.get( 
          GetChaptersBySubjectApi.apiEndpoint.replace('{subjectId}',params.subjectId),
            {   type: params.metaType,
                limit:params.limit ?? 10,
                __projection: GetChaptersBySubjectApi.__projection 
            }
        );
    }
}
export class GetChapterBySubjectApi {
    static apiEndpoint = 'v2/study-tab/subjects/{subjectId}/chapters/{chapterId}';

    static projection;
    static get  __projection(){ 
        if(!GetChapterBySubjectApi.projection){
            GetChapterBySubjectApi.projection = JSON.stringify(new SourceProjectionMapper(ChapterModel).map());
        }
        return GetChapterBySubjectApi.projection;
    }
  
    static apiCall(network: Network, params) {
      return network.get(GetChapterBySubjectApi.apiEndpoint.replace('{subjectId}',params.subjectId).replace('{chapterId}',params.chapterId), { __projection: GetChapterBySubjectApi.__projection });
    }
}

export class GetChapterByIdApi {
    static apiEndpoint = 'v2/study-tab/chapters/{chapterIdOrSlug}';

    static projection;
    static get  __projection(){ 
        if(!GetChapterByIdApi.projection){
           GetChapterByIdApi.projection = JSON.stringify(new SourceProjectionMapper(ChapterWrapperModel).map());
        }
        return GetChapterByIdApi.projection;
    }
  
    static apiCall(network: Network, params: any) {
        let chapterIdOrSlug = params.slug || params.chapterId;
        return network.get(GetChapterByIdApi.apiEndpoint.replace('{chapterIdOrSlug}', chapterIdOrSlug), { __projection: GetChapterByIdApi.__projection });
    }
}

