import {Component, OnDestroy, OnInit, Input, SimpleChanges, EventEmitter, Output} from '@angular/core';
import { ActivatedRoute} from "@angular/router";
import { Store} from "@ngrx/store";
import {
    hidePricingModal,
    hideKindOfferModal,
    getGoalPlans,
    getGoals,
    getPassProducts,
    setEventExtras,
    showWhatsappModal,
    showRequestCallBackModal,
    getGoalBySlug,
    showAlert,
    getGoalById,
    setLoggedOutPurchaseModalSelectedPass,
    setLoggedOutPurchaseModalUserDetails,
    setLoggedOutPurchaseModalStateUsingIFrame,
    setLoggedOutPurchaseModalState, setEmiIframeState, setSelectedPass
} from "@core/application-state/app.actions";
import {getPageText, isServer, setLocalStorage} from "@shared/utility/tb-common";
import {TbcoreService} from "@core/services/tbcore.service";
import {PlatformService} from "@core/services/platform-service";
import {TransferStateManager} from "@core/services/transferStateManager";
import {clientOnly} from "@shared/utility/platform-decorators";
import { GAEventService } from '@angular-commons/core/services/ga-event.service';
import {_passModel, costPerMonth, costPerDay} from '@angular-commons/models/entity/passes/passes.adapter';
import {getPassPurchaseUrl} from '@angular-commons/shared/utility/pass-utils';
import {Subscription} from "rxjs";
import {getPasses} from "@core/application-state/app.actions";
import {
    selectPasses,
    selectStudent,
    selectReferredUser,
    selectGoalPlans,
    selectGoals, selectStickyFooterState, selectPassProducts, selectGoal
} from "@core/application-state/app.selectors";
import {PaymentService} from "@core/services/payment.service";
import {getQueryStringFromParams} from "@shared/utility/url";
import {getGoalDashboardPageUrl, getGoalPageUrl, getGoalPlansPageUrl} from "@shared/utility/goal-utility";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    EMI_DOWN_PAYMENT,
    passTypeProducts,
    PG_SIMPL,
    REDIRECT_PAYMENT_GATEWAYS,
    SIMPL_TRANSACTION_LS_KEY
} from "@shared/utility/constants";
import {ProductAccessService} from "@core/services/product-access.service";
import {CouponCodeService} from "@core/services/coupon-code-service";
import { StudentService } from '@angular-commons/core/services/student.service';
import { loggedoutPurchaseModalStateInIFrame } from '../../../logged-out-purchase/feature-state/logged-out-purchase-iframe.selector';
import { LeadGenerationService } from '@angular-commons/core/services/lead-generation.service';

@Component({
  selector: 'pass-cards',
  templateUrl: './pass-cards.component.html',
  styleUrls: ['./pass-cards.component.scss']
})

export class PassCardsComponent implements OnInit,OnDestroy {

    @Input() passType? = "globalPass"; // or goalSubs
    referredData:any={};
    @Input() openPricingModal:any;
    @Input() referralDiscount:any=5;
    @Input() showTBPass = false;
    @Input() couponModalType:string='default'
    @Input() cardType:string='Header'
    @Input() ctaSticky:boolean=false;
    @Input() couponStatusSourceType?:string='onPage'
    @Input() goalTitle;
    @Input() showModal;
    @Input() isInGoalPageHeader = false;
    @Input() forcedLimit = 0;
    @Input() recommendedPass;
    @Input() setCoupon;
    @Input() isLogoutGoalSubs: boolean ;
    @Input() isLogoutPaymentPage: boolean ;
    @Input() hideStickyPlan = false ;
    @Input() cssModifierClass?: string="";
    @Input() goalSlug?: string;
    @Input() isPassProModal : boolean = false;
    @Input() referrer : string = '';

    @Input() goalId;
    @Input() pId;
    @Input() announcementPitch = '';
    @Input() couponCodeFromLoggedOutIframeUrl;
    @Input() showEMIPlans = false;

    loggedoutPurchaseModalState$: any = this.store.select(loggedoutPurchaseModalStateInIFrame);
    tbGoalPlans$ = this.store.select(selectGoalPlans);
    tbProducts$ = this.store.select(selectPassProducts);
    tbPasses$ = this.store.select(selectPasses);
    referredData$=this.store.select(selectReferredUser);
    passesSub$: Subscription;
    passes:any;
    productIds=[];
    productDetails={};
    stickyFooterHidden = false;
    goal : any = {};
    hasRecommendedPass = false;
    isServer:boolean=isServer();
    hiddenCouponCode:boolean = true;
    dlPage: string;
    sendProductIdsEventFired = false;

    isLoggedIn:boolean;
    isMobile:boolean;
    _selectedPass:any;
    userSelectedPass:any;
    couponObj:any={};
    productDetailsSend:boolean=false;
    isPassesValueSet:boolean=false
    disableClosingModal:boolean=false;

    goalsTransferState$
    student$ = this.store.select(selectStudent);
    student:any;

    isReferredDataSet:boolean;
    priceBreakDownObj:any;

    @Input() coldbootParams;
    coldbootsub:any;
    scrolled: boolean = false;
    plansPageUrl
    defaultCoupon: any;
    PG_SIMPL = PG_SIMPL;
    passProBuyCtaText = 'Upgrade To Pass Pro';
    hasPassAccess = false;
    hasProAccess = false;
    userType = ['free'];
    passIndex: number = 0;
    couponAppliedStateSubscriber;
    isCouponApplied:boolean=false;
    couponData;
    passesWithEmi: any;
    isEmiToggleLeadFired = false;
    isInIframe: boolean = false;

    set selectedPass(v){
        this._selectedPass = v;
        if(this.passType === 'passPro'){
            this.productAccessService.passAndPassProActiveState().then(access => {
                if(access?.passAccess){
                    this.hasPassAccess = access.passAccess;
                }
                if(access?.passProAccess){
                    this.hasProAccess = access.passProAccess;
                }
                if(access?.passProAccess || (!access?.passAccess && !access?.passProAccess)){
                    if(!this.isMobile){
                        this.passProBuyCtaText = `Buy ${v.title} Now`;
                    } else {
                        this.passProBuyCtaText = `Buy Now`;
                    }
                }
            });
        }
    }
    get selectedPass(){
        return this._selectedPass;
    }
    goals$: any = this.store.select(selectGoals);
    goalsInfo:any
    selectedGoal:any;

    isViewEmiClickFired: boolean = false;
    isEnrollNowClicked: boolean = false;

    logoutPaymentInfo:FormGroup = this.fb.group({
        name:['', Validators.required],
        phone: ['', [Validators.required]],
      })
      currentSelectedPid:string=''
    constructor(
        private tbCore: TbcoreService,
        private store:Store<{}>,
        private studentService:StudentService,
        private transferStateManager :TransferStateManager,
        private platformService: PlatformService,
        private route:ActivatedRoute,
        private gaEventService:GAEventService,
        private paymentService:PaymentService,
        private fb: FormBuilder,
        private productAccessService: ProductAccessService,
        private couponCodeService: CouponCodeService,
        private leadGenService:LeadGenerationService
        )
    {

    }
    processPasses(value){
        if(value && value.passes && value.passes.length>0){
            if(!(!this.isLoggedIn && this.isReferredDataSet)){
                let _passes = value.passes;
                if(this.forcedLimit > 0) {
                    let recommendedPass = value.passes.filter((pass) => pass.isHighlight)
                    if(recommendedPass?.length){
                        _passes = recommendedPass.slice(0,this.forcedLimit)
                    } else {
                        _passes = value.passes.slice(0,this.forcedLimit)
                    }
                }
                this.passes = structuredClone(_passes);
                this.passesWithEmi = this.passes.filter(pass => pass?.emis?.length);
                this.hasRecommendedPass = this.passes.some(p => p.isHighlight);
            }
            this.setProductDetails();
            if(!this.isPreservedValue){
                this.setInitialSelectedPass();
            }

            if(this.couponObj?.code){
                this.couponApply(this.couponObj);
            }
            if(this.recommendedPass?.isCouponApplied && !this.isCouponApplied) {
                this.selectedPass = this.recommendedPass;
                if(this.isMobile){
                    this.setPriceBreakDownObj(this.selectedPass);
                }
            }
            if(this.pId){
                this.selectedPass = this.passes.find(i => i.id == this.pId) || this.selectedPass;
                this.setPriceBreakDownObj(this.selectedPass);
            }
            this.checkPaymentFlow();
        }
    }
    getGoalPlan(){
        let productIds = this.platformService.getQueryParamValue('productIds');
        if(productIds && productIds.length){
            this.passesSub$ = this.transferStateManager.get('goalProducts',getPassProducts({productIds}),this.tbProducts$,(value)=>{
                this.processPasses(value);
            });
        } else {
            this.passesSub$ = this.transferStateManager.get('goalPlans', getGoalPlans({gid:this.goal?.id || this.goalId}), this.tbGoalPlans$,
                (value)=>{
                    this.processPasses(value);
                });
        }
    }
    getGlobalPass(){
        this.passesSub$ = this.transferStateManager.get(`passes_${this.passType}`, getPasses({passType: this.passType,pIds:this.currentSelectedPid}), this.tbPasses$,
            (_value)=>{
                let value = {..._value};
                value.passes = value.passes.filter(i => i.type == this.passType);
                this.processPasses(value);
            },true);
    }
    isMGoal:boolean=false;
    isWhatsappFlow:boolean=false;
    isSimplActiveOnGoal;
    ngOnInit(): void {
        if(this.setCoupon && this.recommendedPass){
            this.couponObj = {
                code: this.setCoupon,
                products: {
                    [this.recommendedPass?.id]: this.recommendedPass
                }
            }
        }

        this.route.queryParams.subscribe(params => {
            this.isInIframe = params['isInIframe'] || false;
        });
        let goalSlug = this.route.snapshot.params?.goalSlug || this.goalSlug;
        if(this.passType == "goalSubs" && ( this.goalId || goalSlug)){
            this.plansPageUrl = getGoalPlansPageUrl(goalSlug);
            this.transferStateManager.get(
                `goal_${this.goalId || goalSlug}`,
                this.goalId ? getGoalById({id:this.goalId}) : getGoalBySlug({slug: goalSlug}),
                this.store.select(selectGoal),
                value => {
                    this.goal = value;
                    this.isSimplActiveOnGoal = this.goal?.properties?.allowedPaymentPartners.find(partner => partner.name === 'simpl');
                    if(this.passType == "goalSubs"){
                        let eventExtras:any={itemId:'',itemtype:''}
                        this.getGoalPlan();
                        eventExtras.itemType='goalSubs'
                        eventExtras.itemId=this.goal?.id
                        this.store.dispatch(setEventExtras({... eventExtras}))
                    }
                }
            )
        }

        this.goalsTransferState$ = this.transferStateManager.get('allGoals', getGoals(), this.goals$,(value)=>{
            if(value){
                this.goalsInfo = value.goals;
            }
        });
        this.store.select(selectStickyFooterState).subscribe( (value)=>{
            this.stickyFooterHidden =  !value;
        })

        this.sendProductIdsEventFired = false;
        this.isLoggedIn=this.platformService.isLoggedIn()
        this.isMobile=this.platformService.isMobile();
        this.isMGoal = this.platformService.getQueryParamValue('mGoal') === '1'
            || this.platformService.getQueryParamValue('mPage') === '1';
        this.isWhatsappFlow = this.platformService.getQueryParamValue('wa_chat') === '1';
        let eventExtras:any={itemId:'',itemtype:''}
        if(passTypeProducts.includes(this.passType)){
            this.currentSelectedPid= this.platformService.getQueryParamValue("pId");
            this.getGlobalPass();
            eventExtras.itemType='globalPass'
            this.store.dispatch(setEventExtras({... eventExtras}))
        }
        this.checkPaymentFlow();

        if(this.isLoggedIn){
            this.getStudent();
        }

        if (!isServer()) {
            this.coldbootsub = this.coldbootParams && this.coldbootParams.then((params)=>{
              switch(params.awaitId){
                case "initiatePaymentcta":
                    this.initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass');
                    break;
              }
            })
          }


        this.referredData$.subscribe((data)=>{
            if(data){
                if(!this.platformService.isLoggedIn() && data.isReferredUser){
                    let passes = this.passes.map( pass => {
                        pass.cost = Math.round(pass.cost * (1 - (this.referralDiscount/100)));
                        return pass;
                    });
                    this.passes = structuredClone(passes);
                    this.passesWithEmi = this.passes.filter(pass => pass?.emis?.length);
                    this.isReferredDataSet=true;
                }
                this.referredData=data;
            }
        })

        if(this.isMobile){
            this.platformService.addClass('intercom-move-up')
            this.platformService.addClass('scrolled-up');
        }
        this.couponAppliedStateSubscriber = this.couponCodeService.updatedCouponState.subscribe(couponObj => {
            if(couponObj[this.passType]){
                this.couponApply(couponObj[this.passType]);
                if(couponObj[this.passType]?.code && !this.isServer){
                    this.gaEventService.sendCouponUsedEvent({
                        finalAmount: this.selectedPass?.cost,
                        discountValue: this.selectedPass?.oldCost - this.selectedPass?.cost,
                        couponCode: couponObj[this.passType].code,
                        productID: `"${this.selectedPass?.id}"`,
                        productName: `"${this.selectedPass?.title}"`,
                        client: !this.isMobile ? 'web' : 'mweb',
                        productCost: this.selectedPass?.oldCost,
                        pagePath: this.platformService.getPathName() + this.platformService.getQueryString() || "",
                        isSuccess: true,
                        productType: this.passType
                    })
                    if(this.passType === 'goalSubs'){
                        this.gaEventService.sendApplyCouponClicked({
                            goalID:this.goal?.id,
                            goalName:this.goalTitle,
                            productID:this.goal?.id,
                            productName:this.goalTitle
                        })
                    }
                }
            }
        })
        this.couponData = this.couponCodeService.coupons.subscribe((couponData: any) => {
            if(this.isLogoutPaymentPage && couponData.productIds?.[this.passType] && !this.couponCodeService.getCouponStateDirty() && this.route.snapshot.queryParams['coupon']){
                this.couponCodeService.applyCoupon(this.route.snapshot.queryParams['coupon']).then((data:any) => {
                    if (data.products && Object.keys(data.products).length > 0) {
                        this.couponCodeService.checkCouponForProduct(data.products, data.coupon, this.passType);
                    }
                })
            }
        })
        if(this.route.snapshot.queryParams?.showEmiPlans?.toLowerCase() === 'true'){
            this.showEMIPlans = true;
        }
    }
    globalPassState
    @clientOnly()
    getStudent(){
        this.studentService.loadStudent(true).then((student: any)=>{
            if(student && student?.id !== '-1' && student.globalPassState){
                this.student=student;
                let goalId=this.goal?.id;
                this.selectedGoal = this.goalsInfo.find( goal => goal.id == goalId)
                if(student.globalPassState && this.passType === 'globalPass'){
                    this.globalPassState=student.globalPassState;
                }
                else if(student.goalPlanState && student.goalPlanState[goalId] && this.passType === 'goalSubs'){
                    this.globalPassState=student.goalPlanState[goalId];
                }
            }
        })
    }

    ngOnChanges(changes : SimpleChanges){
        if(changes?.passType?.currentValue){
            this.hiddenCouponCode = false;
        }
        if(changes?.showEMIPlans?.currentValue !== changes?.showEMIPlans?.previousValue){
            this.setInitialSelectedPass();
            if(changes?.showEMIPlans?.currentValue) {
                this.fireViewEmiPlansClickedEvent();
            }
        }
        // if(changes.referredUser && changes.referredUser.currentValue){
        //     if(changes.referredUser.currentValue.show){
        //         // only need to do this for logged out users
        //         this.passes = this.passes.map( pass => {
        //             pass.cost = Math.round(pass.cost * (1 - (this.referralDiscount/100)));
        //             return pass;
        //         });
        //         this.passes=[...this.passes];
        //     }
        // }
    }

    setInitialSelectedPass(){
        if(!this.passes?.length){
            return;
        }
        let passes = [...this.passes];
        if(this.showEMIPlans && this.passesWithEmi?.length){
            passes = [...this.passesWithEmi];
        }
        let ptypeParam=this.route.snapshot.queryParams['ptype'] || '';
        let ptype;
        if(ptypeParam){
            try {
               ptype = JSON.parse(ptypeParam);
            }
            catch (e){
                console.log(e);
            }
        }
        if(ptypeParam && ptypeParam !== 'undefined' && ptype){
            let highlightedPass = passes.find(pass => pass.id === ptype.id);
            if(highlightedPass){
                this.selectedPass = highlightedPass;
            }
            this.setPriceBreakDownObj(this.selectedPass);
        }
        else {
            let highlightedPass = passes.find(pass => pass.isHighlight);
            let cheapestCouponAppliedPass = _passModel.getCheapestAfterCouponApplied(passes);
            if(highlightedPass){
                this.selectedPass = highlightedPass;
                this.setPriceBreakDownObj(this.selectedPass);
            } else if(cheapestCouponAppliedPass?.afterCouponCost === 0){
                this.selectedPass = cheapestCouponAppliedPass;
                this.setPriceBreakDownObj(this.selectedPass);
            } else if (this.setCoupon && this.recommendedPass) {
                this.selectedPass = this.recommendedPass;
                this.setPriceBreakDownObj(this.recommendedPass);

            } else {
                this.selectedPass = _passModel.getCheapest(passes);
                this.setPriceBreakDownObj(this.selectedPass);
            }

            if(this.showEMIPlans && !this.selectedPass?.emis?.length) {
                this.selectedPass = _passModel.getCheapest(passes);
                this.setPriceBreakDownObj(this.selectedPass);
            }
        }

        if(this.pId){
            this.selectedPass = passes.find(i => i.id == this.pId) || this.selectedPass;
            this.setPriceBreakDownObj(this.selectedPass);
        }

        const isEmiInQp = this.route.snapshot.queryParams['showEmiPlans'];
        if(isEmiInQp && !this.isViewEmiClickFired) {
            this.fireViewEmiPlansClickedEvent();
        }
        const enroll = this.route.snapshot.queryParams['enrollNow'];
        if(enroll && !this.isEnrollNowClicked && this.selectedPass) {
            this.isEnrollNowClicked = true;
            this.firePurchaseScreenVisitedEvent();
        }
        this.store.dispatch(setSelectedPass({selectedPass: this.selectedPass}));
    }

    setProductDetails=()=>{
        this.productIds=[];
        this.productDetails={};
        this.passes.forEach(element => {
            this.productIds.push(element.id);
            this.productDetails[element.id]={
                id:element.id,
                name:element.title,
                cost:element.cost,
                productType: element.type,
            }
        });
        let eventInfo={
            productIDs:this.productIds
        }
        if(!isServer() && !this.sendProductIdsEventFired){
            this.sendProductIdsEventFired = true;
            this.gaEventService.sendProductIds(eventInfo);
        }

        if(!this.productDetailsSend){
            this.couponCodeService.fetchCoupons(this.productIds, this.passType);
            this.productDetailsSend=true;
        }

    }

    autoFlowTriggered = false;
    pidFromUrl;
    triggerFromUrl;
    @clientOnly()
    checkPaymentFlow(){
        if(this.autoFlowTriggered) {return;}
        //if url has triggers set keys locally to trigger payment after passes are loaded
        let coupon=this.route.snapshot.queryParams['coupon'] || '';
        let trigger=this.route.snapshot.queryParams['trigger'] || ''
        let ptype=this.route.snapshot.queryParams['ptype'] || '';
        let pid=this.route.snapshot.queryParams['pid'] || '';
        let pg = this.route.snapshot.queryParams['pg'] || '';
        this.disableClosingModal = this.route.snapshot.queryParams['disableClosingModal'] === 'true' || false;
        let selectedPassId=this.route.snapshot.queryParams['id'] || '';
        let passType= this.route.snapshot.queryParams['passType'] || '';
        pid = pid == "undefined" ?  "" : pid;
        this.pidFromUrl = pid;
        this.triggerFromUrl = trigger;
        if(trigger === 'paymentflow' && ((ptype && ptype != "undefined") || passType && passType != "undefined" )){
            if(selectedPassId){
                if(!this.selectedPass){
                    this.selectedPass = { id: selectedPassId };
                }
            }
            if(!ptype){
                this.initiatePayment(this.selectedPass.id, coupon);
            }else{
                ptype=JSON.parse(ptype);
                if(!pg){
                    this.initiatePayment(ptype.id, coupon);
                } else{
                    this.initiatePayment(ptype.id, coupon, '', '', Number(pg));
                }
            }
            this.autoFlowTriggered = true;
        }
        if(trigger === 'paymentflow' && ptype && ptype != "undefined"){
            ptype=JSON.parse(ptype);
            if(!pg){
                this.initiatePayment(ptype.id, coupon);
            } else{
                this.initiatePayment(ptype.id, coupon, '', '', Number(pg));
            }
            this.autoFlowTriggered = true;
        } else if(!this.isLogoutGoalSubs && trigger === 'quickPay' && this.passes?.length && this.productIds?.length){
            if(!this.platformService.isLoggedIn()){
                //firing this action to open up logged out purchase modal using Iframe
                let currentCouponObj;
                let couponCode;
                currentCouponObj = this.couponCodeService.getCurrAppliedCoupon(this.passType);
                couponCode = currentCouponObj?.code;
                if(!couponCode){
                    couponCode = this.route.snapshot.queryParams['coupon'];
                }
                this.store.dispatch(setLoggedOutPurchaseModalState({open:true,goalId:this.goalId || this.goal?.id,pId: pid,pType:this.passType, coupon: couponCode}));
                // this.initiateLoggedOutPayment(this.goalId || this.goal?.id,pid,this.passType);
            } else if(pid){
                this.quickPaymentRazorPay();
            }
            this.autoFlowTriggered = true;
        }

    }
    quickPaymentRazorPay(){
        if(isServer()){return;}
        let count = 0;
        let pollTimer = setInterval(()=>{
            count++;
            let appliedCoupon = (<any>window).couponCode;
            let gotCoupons = (<any>window).gotCoupons;
            if(this.pidFromUrl && this.triggerFromUrl == 'quickPay' && this.passes?.length && (count > 50 || appliedCoupon || gotCoupons)){
                clearInterval(pollTimer);
                let selectedPass = this.passes.find((obj)=> obj.id === this.pidFromUrl);
                if(selectedPass){
                    this.selectedPass = selectedPass;
                }
                this.initiatePayment(this.pidFromUrl,this.selectedPass?.appliedCoupon?.code || "");
                this.store.dispatch(setSelectedPass({selectedPass: this.selectedPass}));
            }
        },100);
    }
    pushInterestedInPassEvent=(id,module,clickText)=>{
        let passObj:any;
        let extraObj:any={};

        passObj=this.passes.find((obj)=> obj.id === id)

        if(this.isLoggedIn){
            extraObj.hasExpiredPass=this.student.globalPassState.passExpired;
            extraObj.hasActivePass=!this.student.globalPassState.passExpired || this.student.globalPassState.passExpiring
            extraObj.isLoggedIn=true;
            extraObj.isMobileVerified=(this.student.mobileVerified === '')? false:true;
            extraObj.hoursLeft= this.student.globalPassState.expiryInDays * 24;
        }
        let eventInfo={
            productName:passObj.title,
            productID:passObj.id,
            productValue: passObj.cost || 0,
            pagePath: this.platformService.getPathName(true) + this.platformService.getQueryString(true),
            productDuration:  Math.round(passObj.validity / (86400000 * 1000000)) || '',
            module:module,
            clickText: clickText || ''
        }

        eventInfo={...eventInfo,...extraObj}
        this.gaEventService.sendInterestedInPassEvent(eventInfo);
    }

    async loadStudentAndFireEventAsync(isFireEvent = false,eventType = 'popupActivity'){
        if(this.isLoggedIn){
            const student = await this.studentService.loadStudent(true);
            this.userType = student?.userType;
        }
        if(isFireEvent){
            eventType == 'popupActivity' ? this.firePassProPopupActivity('UpgradeToPassProClicked') : this.fireProceedToPaymentEvent();
        }
    }

    fireProceedToPaymentEvent(){
        let amount = 0
        if(this.selectedPass?.afterCouponCost || this.selectedPass?.afterCouponCost == 0){
            amount = this.selectedPass?.afterCouponCost
        }
        else{
            amount = this.selectedPass?.cost
        }
        this.gaEventService.sendPassProProceededToPayment({
            userType : this.userType.join(','),
            referrer:this.isPassProModal ? 'popup' : 'purchaseScreen',
            productID:this.selectedPass?.id,
            productName:this.selectedPass?.title,
            payableAmount:amount || '',
            productCost:this.selectedPass?.oldCost,
            couponCode:this.selectedPass?.coupon,
            isLoggedIn: this.platformService.isLoggedIn(),
            discountAmount:this.selectedPass?.oldCost - amount,
        })
    }

    firePassProPopupActivity(category = ''){
        this.gaEventService.sendPassProPopupActivity({
            type:'PurchasePopup',
            category,
            userType : this.userType.join(','),
            isLoggedIn:this.isLoggedIn,
            referrer:this.referrer
        });
    }

    @Output() purchaseButtonClick:EventEmitter<any>=new EventEmitter()

    initiateLoggedOutPayment(goalId,pId,pType,stageOne=false){
        let currentCouponObj = this.couponCodeService.getCurrAppliedCoupon(this.passType);
        // this.store.dispatch(setLoggedOutPurchaseModalStateUsingIFrame({open:true,goalId:goalId,pId: pId,pType:pType,stageOne:stageOne, coupon: currentCouponObj?.code}));
        let qp = getQueryStringFromParams(this.route?.snapshot?.queryParams, {trigger: 'paymentflow', passType: 'passPro',pg:0,id:pId})
        let params =  {
            redirect_url : `${this.platformService.getPathName()}${qp}`,
        };
        this.tbCore.openSignUpSignInModal(params, false);
    }
    initiatePayment=(ptype,coupon,module?,clickText?,paymentGateway?,type?)=>{
        if(this.showEMIPlans){
            this.initiateEMIPaymentFlow();
            return;
        }
        if(this.passType == "passPro" && this.isLogoutPaymentPage){
            this.fireProceedToPaymentEvent()
        }
        if(this.passType == "goalSubs" && !this.isLoggedIn){
            let amount = 0
            if(this.selectedPass?.afterCouponCost >= 0){
                amount = this.selectedPass?.afterCouponCost
            }
            else{
                amount = this.selectedPass?.cost
            }
            this.gaEventService.sendSupercoachingProceededToPayment({
                goalName:this.selectedGoal?.properties?.title || this.goal?.properties?.title,
                goalID:this.selectedGoal?.id || this.goal?.id,
                productName:this.selectedPass?.title,
                productID:this.selectedPass?.id,
                payableAmount:amount,
                productCost:this.selectedPass?.oldCost,
                discountAmount:this.selectedPass?.oldCost - amount,
                couponCode: this.selectedPass?.appliedCoupon?.code || '',
                couponApplied: Boolean(this.selectedPass?.appliedCoupon?.code),
                duration:this.selectedPass?.validity,
                medium: paymentGateway === PG_SIMPL ? 'paymentMethod-simpl' : '',
                type: 'loggedOutPayment'
            })
        }
        if(this.isLogoutGoalSubs && !this.isLogoutPaymentPage && !REDIRECT_PAYMENT_GATEWAYS.includes(paymentGateway)){
            this.initiateLoggedOutPayment(this.goalId || this.goal?.id,this.selectedPass.id || this.pId,this.passType);
            return ;
        }
        if(clickText){
            this.showModal = "paymentFlow"
        }
        if(!ptype || this.showModal==="pricingModal"){
            return;
        }
        let extraObj:any = {
            module:module || '',
            passInfo : {}
        };

        if(this.platformService.isLoggedIn() && this.selectedPass){
            if(this.passType == "goalSubs" && Object.keys(this.goal).length && Object.keys(this.goal?.properties).length){
                extraObj.redirect_url = getGoalDashboardPageUrl(this.goal?.properties?.slug);
                this.gaEventService.sendSupercoachingEntityExplore({productID:this.goal?.id,
                    productName:this.goalTitle,
                    category:'EnrollNow',
                    clickText: 'Enroll Now',
                    goalID:this.goal?.id,
                    goalName:this.goalTitle,
                })
            }
            this.purchaseButtonClick.emit({pType:this.selectedPass.title,pId:this.selectedPass.id})
        }

        if(!this.disableClosingModal){
            this.store.dispatch(hidePricingModal())
        }

        if(ptype === 'selectedPass'){
            ptype=this.selectedPass.id;
            coupon=this.selectedPass.coupon || this.selectedPass?.recommendedPassCoupon || this.setCoupon;
        }


        if(!coupon){
            coupon="";
        }

        var params = {
            pg: 0,
            products: ptype,
            coupon: coupon
        };

        if(module && this.passType == "globalPass"){
            this.pushInterestedInPassEvent(ptype,module,clickText)
        }
        // add goal and pass info into payment flow to handle post payment flows
        extraObj.passInfo = {
            passType:this.passType,
            pass:{id:this.selectedPass?.id,title:this.selectedPass?.title,type:this.selectedPass?.type},
            goal:{id: this.goal?.id, slug: this.goal?.properties?.slug,
            title: this.goal?.properties?.title}}
        if(ptype && !this.selectedPass && this.passes){
            let currPass = this.passes?.find(pass => {
                if(pass.id===ptype)
                return pass
            })
            this.selectedPass = currPass;
            this.setPriceBreakDownObj(this.selectedPass);
            this.store.dispatch(setSelectedPass({selectedPass: this.selectedPass}));
        }

        if (this.platformService.isLoggedIn()) {
            this.showModal = "paymentFlow"
            if(this.passType == "goalSubs"){
                let PaymentType:string = ''
                if(type == 'LowAOV-Firstfold')
                {
                    PaymentType = 'LowAOV-Firstfold'
                }
                else
                {
                    this.dlPage = getPageText();
                    if (this.dlPage.includes("SuperCoachingExam"))
                    {
                        PaymentType = 'Goal-landing'
                    }
                    else if(this.dlPage.includes("Plans"))
                    {
                        PaymentType = 'Plan-page'
                    }
                }
                let amount = 0
                if(this.selectedPass?.afterCouponCost >= 0){
                    amount = this.selectedPass?.afterCouponCost
                }
                else{
                    amount = this.selectedPass?.cost
                }
                this.gaEventService.sendSupercoachingProceededToPayment({
                    goalName:this.selectedGoal?.properties?.title || this.goal?.properties?.title,
                    goalID:this.selectedGoal?.id || this.goal?.id,
                    productName:this.selectedPass?.title,
                    productID:this.selectedPass?.id,
                    payableAmount:amount,
                    productCost:this.selectedPass?.oldCost,
                    discountAmount:this.selectedPass?.oldCost - amount,
                    couponCode: this.selectedPass?.appliedCoupon?.code || this.selectedPass?.coupon || '',
                    couponApplied: Boolean(this.selectedPass?.appliedCoupon?.code || this.selectedPass?.coupon),
                    duration:this.selectedPass?.validity,
                    medium: paymentGateway === PG_SIMPL ? 'paymentMethod-simpl' : '',
                    type: PaymentType
                })
            }
            if(this.passType == "passPro"){
                if(!isServer() && this.userType?.length){
                    this.loadStudentAndFireEventAsync(true,'proceedToPayment');
                    if(this.isPassProModal){
                        this.loadStudentAndFireEventAsync(true);
                    }
                }
            }
            if(!REDIRECT_PAYMENT_GATEWAYS.includes(paymentGateway)){
                this.paymentService.initiatePayment({
                    paymentGateway: params.pg,
                    products : params.products,
                    coupon   : params.coupon,
                    passIndex: this.passIndex,
                    oldCost: this.selectedPass.oldCost
                },extraObj);
            } else {
                if(!isServer()){
                    let simpl_transaction = {
                        platform: 'web',
                        isGoalCourse: true,
                        goal: {id: this.goal?.id, slug: this.goal?.properties?.slug,
                            title: this.goal?.properties?.title},
                        redirectSlug: this.platformService.getPathName(true)
                    }
                    setLocalStorage(SIMPL_TRANSACTION_LS_KEY, JSON.stringify(simpl_transaction), 1);
                }
                let qp = getQueryStringFromParams(this.route?.snapshot?.queryParams,{pid: params.products, coupon: params.coupon, plan: 'payIn3', pg: paymentGateway});
                this.platformService.redirect('/initiate-payment'+qp, 302, true);
            }
        }
        else if(!this.platformService.isLoggedIn() && (this.isMGoal || this.passType == "goalSubs")){
            let qp
            if(this.passType === "goalSubs" && this.goal){
                qp = getQueryStringFromParams(this.route?.snapshot?.queryParams, {trigger: 'paymentflow', coupon: coupon, passType: 'goalSubs', productFirst:1, ptype: JSON.stringify({id:ptype})})
            } else{
                qp = getQueryStringFromParams(this.route?.snapshot?.queryParams,{trigger:'paymentflow', passType: this.passType, ptype, coupon});
            }
            if(REDIRECT_PAYMENT_GATEWAYS.includes(paymentGateway)){
                qp += `&pg=${paymentGateway}`;
            }
            let params = {};
            if(this.isMGoal){
                params =  {
                    isMobileSignUpVerification: true,
                    headerText: 'Get access to free demos by registering your mobile number',
                    redirect_url : `${this.platformService.getPathName()}${qp}`,
                    isMobileVerified : false
                };
            }
            if(this.passType === "goalSubs" && this.goal && !this.isMGoal){
                params =  {
                    redirect_url : `${this.platformService.getPathName()}${qp}`,
                };
            }
            this.tbCore.openSignUpSignInModal(params, false);
        }
        else if(this.isWhatsappFlow){
              this.store.dispatch(showWhatsappModal());
        }
        else {
            var url = (this.passType == "globalPass") ? getPassPurchaseUrl(ptype,coupon) : this.platformService.getPathName();

            if(this.passType==="goalSubs" && this.goal){
                url+= getQueryStringFromParams(this.route?.snapshot?.queryParams, {trigger:'paymentflow', productFirst:1, ptype:JSON.stringify({id:ptype}), coupon:coupon, enrollNow:1, goalId:this.goal?.id, passType:'goalSubs'})
            }
            if (REDIRECT_PAYMENT_GATEWAYS.includes(paymentGateway)){
                url += `&pg=${paymentGateway}`;
            }
            let onBoardingUrlParams = {
                redirect_url: url,
                heading: 'Sign Up to Buy Pass',
              };
            if(REDIRECT_PAYMENT_GATEWAYS.includes(paymentGateway)){
                this.tbCore.openSignUpSignInModal(onBoardingUrlParams, false);
            } else if(this.isLogoutGoalSubs && this.isLogoutPaymentPage){

                if(this.logoutPaymentInfo.value.name.trim() == ""){
                    this.store.dispatch(showAlert({message:'Please enter your name'}));
                    return;
                }
                let regex = /^[a-zA-Z ]*$/;
                if(!regex.test(this.logoutPaymentInfo.value.name.trim())) {
                    this.store.dispatch(showAlert({message:'Please enter valid name'}));
                    return;
                }
                if(this.logoutPaymentInfo.value.phone.length !== 10 || !('6789'.includes(this.logoutPaymentInfo.value.phone[0]))){
                    this.store.dispatch(showAlert({message:'Invalid Number! Please enter 10 digit mobile number starting with 6,7,8 or 9 only'}));
                    return;
                }
                this.store.dispatch(setLoggedOutPurchaseModalSelectedPass({selectedPass:this.selectedPass}));
                this.store.dispatch(setLoggedOutPurchaseModalUserDetails({user:this.logoutPaymentInfo.value}));
                this.paymentService.initiatePayment({
                    paymentGateway: params.pg,
                    products : params.products,
                    coupon   : params.coupon,
                    name: this.logoutPaymentInfo.value.name,
                    mobile: this.logoutPaymentInfo.value.phone,
                    isLoggedOutTrans : (this.isLogoutGoalSubs && this.isLogoutPaymentPage),
                    passIndex: this.passIndex,
                    oldCost: this.selectedPass.oldCost
                },extraObj);
              }else{
                if(ptype === this.selectedPass.id){
                    let currentCouponObj = this.couponCodeService.getCurrAppliedCoupon(this.passType);
                    this.initiateLoggedOutPayment('',this.selectedPass.id,this.passType)
                    // this.store.dispatch(setLoggedOutPurchaseModalState({open:true,goalId:this.goalId || this.goal?.id,pId: this.pId || this.selectedPass.id,pType:this.passType,stageOne:false, coupon: currentCouponObj?.code}));
                }else{
                    let currentCouponObj = this.couponCodeService.getCurrAppliedCoupon(this.passType);
                    this.initiateLoggedOutPayment('',this.selectedPass.id,this.passType)
                    // this.store.dispatch(setLoggedOutPurchaseModalState({open:true,goalId:this.goalId || this.goal?.id,pId: "",pType:this.passType,stageOne:false, coupon: currentCouponObj?.code}));
                }
              }
        }
    }

    ngOnDestroy(): void {
        if(this.isMobile){
            this.platformService.removeClass('intercom-move-up');
            this.platformService.removeClass('scrolled-up');
        }
        this.couponAppliedStateSubscriber.unsubscribe();
        if(this.passesSub$){
            this.passesSub$.unsubscribe();
        }
        this.couponData.unsubscribe();
    }

    isPreservedValue:boolean=false;
    simplProduct;
    setSelectedPass(passObj){
        this.selectedPass=passObj.pass;
        this.setPriceBreakDownObj(this.selectedPass);
        this.isPreservedValue=passObj.isPreserved;
        this.passIndex = passObj.passIndex;
        this.store.dispatch(setSelectedPass({selectedPass: this.selectedPass}));
    }


    couponApply(e){
            this.couponObj = e;
            let couponApplied = false;
            for (let i in this.passes) {
                let element = {...this.passes[i]}
                if (e.products && e.products[element.id] && e.code) {
                    element.isCouponApplied = true;
                    element.afterCouponCost = e.products[element.id].cost;
                    element.coupon = e.code;
                    element.appliedCoupon = e.coupon;
                    if(e.products[element.id]?.emis?.length){
                        element['couponAppliedEmis'] = e.products[element.id]?.emis[0];
                    }
                    couponApplied = true;
                    element.costPerMonthAfterCoupon = costPerMonth(element.afterCouponCost, element.validity);
                    element.costPerDayAfterCoupon = costPerDay(element.afterCouponCost, element.validity);
                    if (e.coupon?.discountType != "passDays") {
                        element.discountPercentage = (element.oldCost !== 0) ? Math.round(((element.oldCost - element.afterCouponCost) * 100) / element.oldCost) : 0;
                    }
                } else {
                    element.isCouponApplied = false;
                    element.afterCouponCost = -1;
                    element.costPerMonthAfterCoupon = -1;
                    element.coupon = ""
                    element.appliedCoupon = {};
                }

                this.passes[i] = {...element}
            }

            this.isCouponApplied = couponApplied;
            if (!this.isCouponApplied) {
                this.setCoupon = '';
            }
            this.passes = [...this.passes];
            this.passesWithEmi = this.passes.filter(pass => pass?.emis?.length);
            this.setInitialSelectedPass();

    }

    buyKindOfferPass(pass){
        this.selectedPass=pass;
        this.setPriceBreakDownObj(this.selectedPass);
        this.store.dispatch(hideKindOfferModal())
        this.initiatePayment('selectedPass','', 'Pass Banner', 'BuyPass');
        this.store.dispatch(setSelectedPass({selectedPass: this.selectedPass}));
    }

    openRequestCallbackModal(){
        this.store.dispatch(showRequestCallBackModal());
    }

    processPriceBreakDown(selectedPass){
        let finalObj:any = {};
        if(selectedPass && Object.keys(selectedPass)?.length) {
            let offer = selectedPass?.oldCost - selectedPass?.cost;
            let couponDiscount = selectedPass?.isCouponApplied ? selectedPass?.cost - selectedPass?.afterCouponCost : 0;
            let totalSavings = offer + couponDiscount;
            let finalAmount = selectedPass?.isCouponApplied ? selectedPass?.afterCouponCost : selectedPass?.oldCost - offer

            finalObj = {
                price: selectedPass?.oldCost,
                offer,
                couponDiscount: couponDiscount < 0 ? 0 : couponDiscount,
                totalSavings,
                finalAmount,
                isCouponApplied: selectedPass?.isCouponApplied,
                emiDownPaymentAmount: this.getEmiDownPaymentAmount(selectedPass),
            };
        }
        return finalObj
    }

    getEmiDownPaymentAmount(selectedPass){
        if(!selectedPass?.emis?.length){
            return 0
        }
        if(selectedPass?.isCouponApplied){
            return selectedPass?.couponAppliedEmis?.payments?.[0]?.amount || selectedPass?.emis[0]?.payments[0]?.amount;
        } else {
            return selectedPass?.emis[0]?.payments[0]?.amount;
        }
    }
    setPriceBreakDownObj(selectedPass){
        this.priceBreakDownObj = this.processPriceBreakDown(selectedPass)
    }

    changePlan(event){
        if(this.passType == "goalSubs"){
            this.initiateLoggedOutPayment(this.goalId || this.goal?.id,"",this.passType,true);
        } else if(passTypeProducts.includes(this.passType)){
            this.initiateLoggedOutPayment("","",this.passType,true);

        }
    }

    proceedToEmiPaymentEvent() {
        const emiPlan: any[] = this.selectedPass?.isCouponApplied ? this.selectedPass?.couponAppliedEmis?.payments : this.selectedPass?.emis?.[0]?.payments;
        let payableAmount = 0;
        if(emiPlan) {
            payableAmount = emiPlan.reduce((total, curr)=> total+curr?.amount, 0) || 0;
        }
        this.gaEventService.sendSupercoachingEmiProceededToPayment({
            goalID: this.goal?.id || '',
            goalName: this.goal?.properties?.title || '',
            productID: this.selectedPass?.id || '',
            productName: this.selectedPass?.title || '',
            duration: this.selectedPass?.validity || 0,
            productCost: this.selectedPass?.oldCost || 0,
            couponApplied: this.selectedPass?.isCouponApplied || false,
            couponCode: this.selectedPass?.coupon || '',
            discountAmount: (this.selectedPass?.oldCost - (this.selectedPass?.isCouponApplied ? this.selectedPass?.afterCouponCost : this.selectedPass?.cost)) || 0,
            emiPlanPrice: (this.selectedPass?.isCouponApplied ? this.selectedPass?.couponAppliedEmis?.payments?.[0]?.amount : this.selectedPass?.emis?.[0]?.payments?.[0]?.amount) || 0,
            payableAmount
        });
    }

    initiateEMIPaymentFlow() {
        let goalPlansUrl = getGoalPlansPageUrl(this.goal?.properties?.slug);
        let goalPageUrl = getGoalPageUrl(this.goal?.properties?.slug);
        let qp = getQueryStringFromParams(this.route?.snapshot?.queryParams,{showEmiPlans: true});
        if(this.isLoggedIn){
            if(this.selectedPass?.emis?.length){
                this.proceedToEmiPaymentEvent();
                if(this.isInIframe) {
                    let qp = '';
                    if(this.selectedPass?.emis?.length && this.selectedPass?.emis?.[0]?.id && this.selectedPass?.id){
                        const productDetails = {
                        coupon: this.selectedPass?.coupon || '',
                        planId: this.selectedPass?.emis[0]?.id || '',
                        productId: this.selectedPass?.id || ''
                        }
                        qp = getQueryStringFromParams({},{showEmiPlans: true, triggerDownPaymentModal: true, ...productDetails});
                    } else {
                        qp = getQueryStringFromParams({},{showEmiPlans: true});
                    }
                    this.platformService.redirect((goalPlansUrl + qp),301,true);
                }
                else {
                    const params = {
                        openModal: true,
                        modalType: EMI_DOWN_PAYMENT,
                        productId: this.selectedPass?.id || '',
                        planId: this.selectedPass?.emis[0]?.id || '',
                        coupon: this.selectedPass?.coupon || '',
                        goalId: this.goalId || this.goal?.id ||  '',
                        productName: this.selectedPass?.title || '',
                        module: "Goal Pass Cards",
                        passType: this.passType || 'goalSubs',
                        goalSlug: this.goalSlug || this.goal?.properties?.slug || '',
                        goalTitle: this.goalTitle || this.goal?.properties?.title || '',
                        redirectUrl: goalPageUrl
                    }
                    this.store.dispatch(setEmiIframeState(params));
                }
            } else {
                this.platformService.redirect((goalPlansUrl + qp), 302, true);
            }
        } else {
            let qp;
            if(this.selectedPass?.emis?.length){
                qp = getQueryStringFromParams(this.route?.snapshot?.queryParams,{showEmiPlans: true, triggerDownPaymentModal: true, coupon: this.selectedPass?.coupon, planId: this.selectedPass?.emis[0]?.id, productId: this.selectedPass?.id});
            } else {
                qp = getQueryStringFromParams(this.route?.snapshot?.queryParams,{showEmiPlans: true});
            }
            let params = {
                redirect_url : `${goalPlansUrl}${qp}`,
            };
            this.tbCore.openSignUpSignInModal(params, false);
        }
    }

    fireViewEmiPlansClickedEvent() {
        if(this.selectedPass?.couponAppliedEmis && this.goal) {
            this.isViewEmiClickFired = true;
            const emiPlan: any[] = this.selectedPass?.isCouponApplied ? this.selectedPass?.couponAppliedEmis?.payments : this.selectedPass?.emis?.[0]?.payments;
            let payableAmount = 0;
            if(emiPlan) {
                payableAmount = emiPlan.reduce((total, curr)=> total+curr?.amount, 0) || 0;
            }
            this.gaEventService.sendViewEmiPlanClickEvent({
                goalID: this.goal?.id || '',
                goalName: this.goal?.properties?.title || '',
                productID: this.selectedPass?.id || '',
                productName: this.selectedPass?.title || '',
                emiPlanPrice: emiPlan?.[0].amount || 0,
                payableAmount,
                clickText: 'EMI toggle clicked'
            });

            if(this.isLoggedIn && !this.isEmiToggleLeadFired){
                this.studentService.loadStudent(true).then(student => {
                    this.isEmiToggleLeadFired = true;
                    this.leadGenService.generateLead({
                        action: 'SUPER_ONLINE_EMI_VIEWED',
                        client: this.isMobile ? 'mweb' : 'web',
                        parentId: this.goal?.id  || '',
                        prodId: this.selectedPass?.id || '',
                        sid: student?.id || '',
                        mobile: student?.mobile?.length === 12 ? student?.mobile.substring(2,12) : student?.mobile,
                        email: student?.email || ''
                    })
                })
            }
        }
    }

    toggleViewEmis() {
        this.showEMIPlans = !this.showEMIPlans;
        this.setInitialSelectedPass();
        if(this.showEMIPlans) {
            this.fireViewEmiPlansClickedEvent();
        }
    }

    firePurchaseScreenVisitedEvent() {
        const eventInfo = {
          type : this.goal?.properties?.isClientEMIAvailable ? 'EMI' : 'Non-EMI',
          productID: this.selectedPass?.id || '',
          productName: this.selectedPass?.title || '',
          goalID: this.goal?.id || '',
          goalName: this.goal?.properties?.title || '',
        }

        this.productAccessService.getSubcategoryOfUser(this.goal?.id).then(value => {
            const subscriptionCategory = value?.subCategory;
            if(this.isLoggedIn) {
                eventInfo['subscriptionCategory'] = subscriptionCategory;
            }
            this.gaEventService.sendSupercoachingPurchaseScreenVisited(eventInfo);
        });
    }
}
