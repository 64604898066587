import { pipe } from "rxjs";
import {filter, skipWhile} from "rxjs/operators";
import {PlatformService} from "@core/services/platform-service";

export function skipInitialState() {
    return pipe(filter((res:any) =>  !res.isInitialState));
}

export function skipEmptyArray() {
    return pipe(skipWhile((res:any) =>  res.length === 0));
}


export function onlyWhenChanged(key,initialValue) {
    let lastValue = initialValue;
    return filter((data:any) => {
        if(data[key] !== lastValue){
            lastValue = data[key];
            return true;
        }
        return false;
    });
}