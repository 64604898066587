export type validLangs = ('English'|'Hindi')

export let langMap = {
    'english':'en',
    'hindi':'hi',
    'bengali':'bn',
    'marathi':'mr'
}

export let languageCodeMap = {
    'en':'english',
    'hi': 'hindi',
    'bn': 'bengali',
    'mr': 'marathi'
}
