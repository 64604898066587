import {Network} from "@core/services/model-manager/main";


export class GetCloudBucketDataApi {
    static apiEndpoint = 'v1/cloud-bucket-json';
    static  __projection = 1;
    static apiCall(network: Network, _params) {
        var params :any = {};
        if(_params.fileName){
            params.fileName = _params.fileName;
        }
        if(_params.key){
            params.key = _params.key;
        }
        return network.get(GetCloudBucketDataApi.apiEndpoint, {...params, __projection: GetCloudBucketDataApi.__projection}, 'node');
    }
}

