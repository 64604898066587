<ng-container *ngIf="showMandate">
    <emi-mandate-popup 
    (closeModal)="handleClose()" 
    [goalId]="queryParams?.goalId" 
    [disableCloseBtn]="queryParams?.disableCloseBtn"
    (mandatePopupViewedEvent)="handleGtmEvents('mandatePopupViewed')"
    (mandateInfoCollectedEvent)="handleGtmEvents('madateInfoCollected')"></emi-mandate-popup>
</ng-container>

<ng-container *ngIf="showBlocker">
    <emi-blocker-popup (closeModal)="handleClose()"
    [goalId]="queryParams?.goalId"
    (switchPopup)="switchPopup($event)"
    (blockerPopupViewEvent)="handleGtmEvents('blockerPopupView')"
    (emiInfoScreenVisitedEvent)="handleGtmEvents('emiInfoScreenVisited')"></emi-blocker-popup>
</ng-container>

<ng-container *ngIf="showDownPayment">
    <down-payment-popup (closeModal)="handleClose()" [coupon]="queryParams?.coupon"
    [planId]="queryParams?.planId" 
    [productId]="queryParams?.productId" 
    [productName]="queryParams?.productName"
    [products]="queryParams?.products"
    [module]="queryParams?.module"
    [redirectUrl]="queryParams?.redirectUrl"
    [goalId]="queryParams?.goalId"
    [goalSlug]="queryParams?.goalSlug"
    [goalTitle]="queryParams?.goalTitle"
    [passId]="queryParams?.passId"
    [passType]="queryParams?.passType"
    [passTitle]="queryParams?.passTitle"
    ></down-payment-popup>
</ng-container>
