import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoggedOutPurchaseModalComponent } from './logged-out-purchase-modal.component';
import { ProgressIndicatorExportModule } from '@angular-commons/feature-modules/progress-indicator/progress-indicator.export.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoggedOutPurchaseStageOneComponent } from './logged-out-purchase-stage-one/logged-out-purchase-stage-one.component';
import { LoggedOutPurchaseStageTwoComponent } from './logged-out-purchase-stage-two/logged-out-purchase-stage-two.component';
import { PassPageExportModule } from 'angular-tb-ssr/src/app/route-modules/pass-page/pass-page.export.module';
import { EcardsComponent } from './ecards/ecards.component';



@NgModule({
  declarations: [
    LoggedOutPurchaseModalComponent,
    LoggedOutPurchaseStageOneComponent,
    LoggedOutPurchaseStageTwoComponent,
    EcardsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressIndicatorExportModule,
    PassPageExportModule,
  ],
  exports:[
    LoggedOutPurchaseModalComponent
  ]
})
export class LoggedOutPurchaseModalModule { }
