 <assistance-about-course *ngIf="bannerType === 'header'" [bannerMeta] = "bannerMeta"  [coldbootParams]="coldbootParams" (requestCallbackEvent)="requestCallback($event)"></assistance-about-course>
 <book-counselling-session *ngIf="bannerType === 'banner'" [description]="description" [title]="title" [ctaText]="ctaText" [icon]="icon" [showDynamicComponent]="showDynamicComponent" [course]="course" [bannerMeta] = "bannerMeta" [isInModal]="isInModal" [goal]="goal" [isForCoaching] = "true"  [coldbootParams]="coldbootParams" (requestCallbackEvent)="requestCallback($event)" [from]="from"></book-counselling-session>
 <inline-request-callback *ngIf="bannerType === 'inline'" [bannerMeta] = "bannerMeta"  [coldbootParams]="coldbootParams" (requestCallbackEvent)="requestCallback($event)"></inline-request-callback>
 <request-course-batch *ngIf="bannerType === 'request-course-batch'" [bannerMeta] = "bannerMeta" [coldbootParams]="coldbootParams"  (requestCallbackEvent)="requestCallback($event)"></request-course-batch>
 <div
 *ngIf="bannerType === 'headerbtn'"
 (click)="requestCallback('')"
 class="btn btn--block"
 translate
 [ngStyle]="{'background': 'rgba(1, 151, 246, 1)'}"
 >Request callback
</div>