import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmiBlockerPopup } from './emi-blocker-popup.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@angular-commons/core/core.module';
import { LoaderModule } from '../loader/loader-export.module';

@NgModule({
  declarations: [EmiBlockerPopup],
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    LoaderModule
  ],
  exports:[EmiBlockerPopup]
})
export class EmiBlockerPopupModule { }
