<div class="modal modal--mobile-verification" [ngClass]="{'show': uiState.showModal}">
    <div class="modal-dialog modal-dialog--bottom" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <div id="js-custom-close-btn" *ngIf="uiState.isSkippable" data-dismiss="modal" (click)="closeModal()" class="tb-close"></div> -->
                <h2 class="modal-title" translate>{{'VERIFICATION_HEADING'}}</h2>
                <a class="modal-close" href="javascript:void(0)" (click)="closeModal()" *ngIf="!disableCloseBtn"></a>
            </div>
            <div class="modal-body">
                <div class="input-group mb-6 info">
                    <div class="d-flex a-center">
                        <div class="input-icon phone-icon"></div>
                        <input type="text" placeholder="Enter Mobile Number" [(ngModel)]="uiState.mobile.mobileNumber" [disabled]="!uiState.editMode"
                          (keyup)="mobileKeys($event)" (keydown)="mobileKeys($event)">
                        <div class="input-icon edit-icon" (click)="changeNumber()" *ngIf="!uiState.editMode"></div>
                    </div>
                    <div class="bottom-bar"></div>
                        <div class="info-message" translate>{{'OTP_SENT_ON'}}</div>
                        <div [ngClass]="{'error':uiState.mobile.error}">
                            <div class="error-message" [hidden]="!uiState.mobile.error">{{uiState.mobile.error | translate}}</div>
                        </div>
                </div>

                <div class="input-group info" *ngIf="!uiState.editMode">
                    <div class="d-flex a-center">
                        <div class="input-icon otp-icon"></div>
                        <input type="tel" placeholder="OTP" autocomplete="one-time-code" [(ngModel)]="uiState.otp.value" maxlength="6"
                                (keyup)="requiredField(uiState.otp,$event)" (keydown)="requiredField(uiState.otp,$event)" (keydown.enter)="verifyOtp()" (blur)="otpField()">
                    </div>
                    <div class="bottom-bar"></div>
                    <div [ngClass]="{'error':uiState.otp.error}">
                        <div class="error-message" [hidden]="!uiState.otp.error">{{uiState.otp.error | translate}}</div>
                    </div>
                    <div class="info-message mt-4" *ngIf="uiState.otp.nextRequestIn > 0">{{'RESEND_OTP_TIME' | translate: {'otpTime' : uiState.otp.nextRequestIn } }}
                    </div>
                </div>

                <div *ngIf="uiState.otp.nextRequestIn <= 0 && !uiState.editMode">
                    <button class="btn-link" (click)="requestOTP()" translate>{{'RESEND_OTP_TEXT'}}</button>
                    <span class="text-light-sm mx-2">|</span>
                    <button class="btn-link" (click)="requestOTP(true)" translate>{{'GET_OTP_TEXT'}}</button>
                </div>

                <div class="mt-6 mb-4">
                    <button type="button" class="btn btn--cta w-100"
                            (click)="verifyOtp()" translate *ngIf="!uiState.editMode">{{'Verify OTP'}}</button>
                    <button type="button" class="btn btn--cta w-100"
                         translate *ngIf="uiState.editMode" (click)="validateMobile()">{{'Send OTP'}}</button>
                    <div>

                    </div>
                </div>

            </div>
            <!-- <div data-dismiss="modal" (click)="closeModal()" class="tb-close"></div> -->
        </div>
    </div>
    <div class="modal-backdrop show"></div>
</div>