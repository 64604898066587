import {Validator as _} from "../shared/utility/validator";
import { transform } from "./entity/classes/schedule.adapter";

export class SourceFieldsMapper{
    _propertyMapping: any;
    _target:any;
    parent = "";
    constructor(type:any,parent:string = ''){
        this._target = new type(true);
        this.parent = parent || "";
        this._propertyMapping = this._target.constructor._propertyMap;
    }
    map(){
        let propMap = this._propertyMapping;
        let __fields = [];
        if(propMap){
            for(var key in propMap){
                for(let i of propMap[key].sourceProperty){
                    let path = this.parent ? `${this.parent}.${i}` : i;
                    if(propMap[key].projectionType){
                        __fields = __fields.concat(__fields,new SourceFieldsMapper(propMap[key].projectionType,path).map());
                    } else {
                        __fields.push(path);
                    }
                }
            }
        }
        let unique = {};
        __fields.forEach( i => unique[i] = true);
        return Object.keys(unique);
    }
}

export class SourceProjectionMapper{
    _propertyMapping: any;
    _target:any;

    constructor(type:any){
        this._target = new type(true);
        this._propertyMapping = this._target.constructor._propertyMap;
    }

    map(){
        let propMap = this._propertyMapping;
        let __projection = {};
        if(propMap){
            for(var key in propMap){
                // let sourceKey = propMap[key].sourceProperty;
                for(let i of propMap[key].sourceProperty){
                    __projection[i] = 1;
                    if(propMap[key].projectionType){
                        __projection[i] = new SourceProjectionMapper(propMap[key].projectionType).map();
                    } 
                }
                
            }
            return __projection;
        }
        return false;
    }
}

export class ModelMapper {
    _propertyMapping: any;
    _target: any;
    _forceNotEmptyObjectArrays : any;
    _cacheStore: any;

    constructor(type:any , _forceNotEmptyObjectArrays, cacheStore){
        this._target = new type();
        this._cacheStore = cacheStore;
        this._propertyMapping = this._target.constructor._propertyMap;
        this._forceNotEmptyObjectArrays = _forceNotEmptyObjectArrays;
    }

    map(source){
        Object.keys(this._propertyMapping).forEach((key) => {
            const mappedKey = this._propertyMapping[key];
            if(mappedKey){

                let isArray = Array.isArray(source);
                let transformSource = source;
                if(!isArray && source){
                    for(let propertyName of mappedKey.sourceProperty){
                        transformSource=source[propertyName];
                        if(source[propertyName]){  break; }
                    }
                }
                if(typeof mappedKey.mapperFn === 'function'){
                    try {
                        this._target[key] = mappedKey.mapperFn(transformSource,this._forceNotEmptyObjectArrays,this._cacheStore);
                    } catch (err) {
                        console.error(err);
                        throw new MappingFrameWorkException(`in MODEL: ${this._target.constructor.name}`);
                    }
                } else {
                    this._target[key] = transformSource;
                }
            }
        });

        return this._target;
    }
}

// Ensure that propertyMaps is called on unique classes. If classes are shared, it may cause issues on server
export function propertyMaps(sourceProperty?:string|any,mapperFn?:any,projectionType?:any) {
    return function (target: any, propertyKey: string) {
        if(!target.constructor._propertyMap){
            target.constructor._propertyMap ={};
        }

        if(sourceProperty && !Array.isArray(sourceProperty)){
            sourceProperty=[sourceProperty]
        }

        target.constructor._propertyMap[propertyKey] = {sourceProperty:sourceProperty||[propertyKey],mapperFn,projectionType};
    }
}

export const IdentityAdapter = {adapt: i => i,__metadata:{type:'identity'}};

export function pipeModel(...transforms){
    return function (item, _forceNotEmptyObjectArrays,cacheStore){
        let current = item;
        for(let transform of transforms ){
            current = transform(current , _forceNotEmptyObjectArrays , cacheStore);
        }
        return current;
    }
}

export function withFallback(fallback){
    return (current , _forceNotEmptyObjectArrays , cacheStore) => {
        if(!current){
            return fallback;
        }
        return current;
    }
}

export function asArray() {
    return (items) => {
        if (Array.isArray(items)) {
            return items
        } else {
            return Object.values(items);
        }
    }
}

export function filterIf(predicate) {
    return (item) => {
        return _.arr(item).filter(predicate);
    }
}

export function ItemsAsObjectKey(key) {
    return (item) => {
        return _.arr(item).map(i => {
            let obj = {};
            obj[key] = i;
            return obj
        });
    }
}

export function objectKeyAsItem(key) {
    return (item) => {
        return _.arr(item).map(i => {
            i = i[key];
            return i;
        })
    }
}

export function keyFromObject(key) {
    return (item) => {
        return item[key];
    }
}

export function arrayToMap(key) {
    return function(arr) {
        let newObj = {};
        for (let item of arr) {
            newObj[item[key]] = item;
        }
        return newObj;
    }
}

export function wrapInObj(key) {
    return function(obj) {
        return { [key]: obj};
    }
}

export function transformValueOfKeyToLowercase(key) {
    return (item) => {
        return _.arr(item).map(i => {
            i[key] = i[key].toLowerCase();
            return i;
        });
    };
}

export function arrayToMapModel(model,mapto){
    return function (item, _forceNotEmptyObjectArrays,cacheStore) {
        return _.arr(item).reduce((t,i)=>{
            if(i.hasOwnProperty(mapto)){
                t[i[mapto]] = model.adapt(_.obj(i),_forceNotEmptyObjectArrays,cacheStore);
            }
            return t;
        },{})
    }
}

export function arrayModel(model){
    return function (item, _forceNotEmptyObjectArrays,cacheStore) {
        return _.ObjArr(item,_forceNotEmptyObjectArrays).map(
            (i) => {
                let adapted = model.adapt(_.obj(i),_forceNotEmptyObjectArrays,cacheStore);
                return adapted;
            })
    }
}

export function objModel(model){
    return function (item,_forceNotEmptyObjectArrays,cacheStore) {
        let adapted = model.adapt(_.obj(item),_forceNotEmptyObjectArrays,cacheStore);
        return adapted;
    }
}

export function wrapArrayByObj(model, withKey){
    return function (item,_forceNotEmptyObjectArrays,cacheStore) {
        let adapted;
        if (Array.isArray(item)) {
            adapted = item.map(data => new ModelMapper(model,_forceNotEmptyObjectArrays,cacheStore).map(data));
            adapted = {
                [withKey]: adapted
            }
        } else {
            adapted = new ModelMapper(model,_forceNotEmptyObjectArrays,cacheStore).map(item);
        }

        return adapted;
    }
}

export function sortBy(key, type, predicate) {
    return function (item, _forceNotEmptyObjectArrays, cacheStore) {
        if(Array.isArray(item)) {
            item.sort((item1,item2) => {
                let a = item1[key];
                let b = item2[key];

                let ret = 0;

                if(type === 'date') {
                    ret = new Date(a).getTime() - new Date(b).getTime();
                }
                if(type === 'num') {
                    ret = item1[key] - item2[key];
                }
                if(predicate === 'desc') {
                    return -ret;
                }
                return ret;
            })
        }

        return item;
    }

}

export function appendToTargetSlug(suffix) {
    return (targets) => {
        return _.arr(targets).map(item => {
            item.slug = item.slug + suffix;
            return item;
        });
    }
}

export class MappingFrameWorkException {
    message = '';
    constructor(message) {
        this.message = message;
    }
}

export function sliceArray(startIndex, endIndex) {
    return (items) => {
        return _.arr(items).slice(startIndex, endIndex);
    }
}

export function arrayOfObjectToCSV(arr, key) {
    return arr.reduce((acc, elem) => acc += elem[key] + ',', '');
}

//WARNING DO NOT USE THIS FOR REFERENCE TYPES
export function mergeArraysUnique(arr1, arr2) {
    if(arr2 && arr2.length > 0){
        return Array.from(new Set([...arr1, ...arr2]));
    }
    return arr1;
}
//DO USE THIS FOR REFERENCE TYPES
export function mergeArraysUniqueByKey(arr1, arr2,getKey = (item) => item['id']) {
    let keymap = {};
    arr1?.forEach( item => { keymap[getKey(item)] = item });
    arr2?.forEach( item => { keymap[getKey(item)] = item });
    return Object.values(keymap);
}


export function extractValueByKey(ArrOfObj, key) {
    if(!ArrOfObj.length || key === '' || undefined){return []};
    return ArrOfObj.map(obj => obj[key]);
}
