import {Injectable} from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    ActivatedRoute,
    UrlTree
} from '@angular/router';
import {Observable, of} from "rxjs";
import {Store} from "@ngrx/store";

import {PlatformService} from "@core/services/platform-service";
import {isServer} from "@shared/utility/tb-common";
import {getQueryStringFromParams} from "@shared/utility/url";


@Injectable()
export class PassProPageGuard implements CanActivate {

    constructor(private router:Router,
                private route: ActivatedRoute,
                private store: Store<{}>,
                private platformService: PlatformService) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean|UrlTree> {

        let pathname = state.url.split('?')[0]
        // smart /my redirection
        let shouldBeSlashMy = this.platformService.isLoggedIn() && !pathname.endsWith("/my");
        let shouldBePassProPage = !this.platformService.isLoggedIn() && pathname.endsWith("/my");
        if(shouldBeSlashMy || shouldBePassProPage){
            let path="/pass-pro";
            if(route.params["refLink"]){
                path=`/invite/${route.params["refLink"]}`
            }
            let url = path + getQueryStringFromParams(route.queryParams);
            if(shouldBeSlashMy){
                url = `${path}/my` + getQueryStringFromParams(route.queryParams);
            }
            if(isServer()){
                this.platformService.redirect(url,302);
            } else {
                this.router.navigateByUrl(url);
            }
            return;
        }

        return of(true);
    }

}
