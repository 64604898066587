import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { PlatformService } from "@core/services/platform-service";
import { mgoalMentorShip } from '@angular-commons/shared/utility/mgoal-mentorship-mapping';

@Injectable({
  providedIn: 'root'
})
export class MgoalMentorShipGuard implements CanActivate {
    mgoalMentorShipMap:any
    constructor(
        private platformService: PlatformService,
    ) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let mgoalSlug = route.params.mgoalSlug;
        if (mgoalSlug) {
            this.mgoalMentorShipMap = mgoalMentorShip[mgoalSlug];
            if(this.mgoalMentorShipMap && this.mgoalMentorShipMap?.heroImage) {
                return true; 
            }
            this.platformService.gotoErrorPage();
            return false;
        }
        return of(true);   
    }
}
