<div *ngIf="!isServer">
    <ng-container *ngIf="!activationCode">
        <button class="btn btn--cta" *ngIf="passState.passUnpurchased && passProState.passUnpurchased" (click)="goToPass()">Get Pass</button>
        <ng-container *ngIf="!passState.passUnpurchased || !passProState.passUnpurchased">
            <button class="btn btn--outline btn--hover-brand" *ngIf="!passProState.passUnpurchased && !passProState.passExpired" (click)="goToPassPro()">Pro</button>
            <ng-container *ngIf="!(!passProState.passUnpurchased && !passProState.passExpired)" >
                <button class="btn btn--cta" *ngIf="passProState.passExpired" (click)="purchasePro()">Renew Pass Pro</button>
                <ng-container *ngIf="!passProState.passExpired">
                    <button class="btn btn--cta" *ngIf="passState.passExpired" (click)="purchasePro()">Get Pass Pro</button>
                    <button class="btn btn--cta" *ngIf="!passState.passExpired" (click)="purchasePro()">Upgrade to Pass Pro</button>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
    <a class="btn btn--cta" *ngIf="activationCode" [href]="'/e-cards?code='+activationCode">Activate Pass</a>
</div>
<div *ngIf="isServer" class="skeleton">
    <button class="btn sk-t sk-anim">Pass</button>
</div>