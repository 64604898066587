<div class="assistance">
    <div class="assistance__img">
        <div *ngIf = "!bannerMeta.submitted">
            <img src="./assets/img/select/call-assistance.svg" alt="call-assistance-icon" height="68" width="68">
        </div>
        <div *ngIf = "bannerMeta.submitted">
            <img src="./assets/img/select/like.svg" alt="like-icon" height="68" width="68">
        </div>
    </div>
    <div class="assistance__content">
        <div *ngIf = "bannerMeta.submitted">
            <h3 translate>{{'RECEIVED_CALL_DETAILS'}}</h3>
            <p [innerHtml]="'EXPERT_WILL_CALL_SOON' | translate: {'mobile' : bannerMeta.mobile }"></p>
            <div>
                <a href="javascript:void(0)" (click)="changeNumber()" cb-await="" cb-await-id="changenumbercta" translate>{{'CHANGE_NUMBER'}}</a>
            </div>
        </div>
        <div *ngIf = "!bannerMeta.submitted">
            <h3 translate>{{'BOOK_COUNSELLING_SESSION'}}</h3>
            <p translate>{{'WE_WILL_CALL_YOU'}}</p>
            <div class="assistance__input-holder">
                <label class="assistance__input">
                    <input type="text" [(ngModel)]="mobile" placeholder="{{'PHONE_NO' | translate}}"  cb-persist="mobilenoinput">
                </label>
                <button type="submit" class="btn btn--outline-primary" (click)="requestCallback()" cb-await="" cb-await-id="requestcallbackcta" translate>{{'CALL_ME'}}</button>
            </div>
        </div>
    </div>
</div>