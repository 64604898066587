import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {PlatformService} from "@core/services/platform-service";
import {TransferState} from "@angular/platform-browser";
import {AuthenticationService} from "@core/services/authentication.service";
import { hideLoader, isServer, showLoader } from "@shared/utility/tb-common";
import { updateAccountBlockedModalState } from '@angular-commons/core/application-state/app.actions';
import { getFromSessionStorage, setToSessionStorage } from '@angular-commons/shared/utility/tb-common';
import { KEY_SID_CHECK } from '@angular-commons/core/services/authentication.service';
@Component({
  selector: 'account-block',
  templateUrl: './account-block.component.html',
  styleUrls: ['./account-block.component.scss']
})
export class AccountBlockComponent implements OnInit {

  sid: any;
  icon: any;
  data: any;
  title: any;
  checkBoxText: any;
  description: any;
  isFirstTimeBlocked: boolean=false;
  isSelfUnblockWait: boolean=false;
  isBlockedUser: boolean=false;
  selfUnblockAt: any= "0001-01-01T00:00:00Z";
  isShowWarning: boolean=false;
  isBtnDisabled: boolean = false;
  accountBlockedData: any;
  loaded = false;
  defaultTime: any = "0001-01-01T00:00:00Z";

  public isServer:boolean = isServer();

  constructor(
    private store: Store<any>,
    private auth: AuthenticationService, 
    private activeroute: ActivatedRoute,
    private transferState: TransferState,
     private platformService: PlatformService,
  ) { }


  updateCheckboxState() {
    this.isBtnDisabled = !this.isBtnDisabled;
  }
  // setting the HTML MarkUpData
  handleMarkUpData(markUpData) {
    this.loaded = true;
    this.icon = markUpData.icon;
    this.title = markUpData.title;
    this.checkBoxText = markUpData.submitText;
    this.description = markUpData.description;

  }
  // Handle the response account blocked data
  handleAccountBlockedData(response: any) {
    
    if (response.loginState && response.markupData.title ) {
      if (response.loginState.showWarning) {
        this.isShowWarning = response.loginState.showWarning
        // Dispatching the login state for handle the session hide and show condition
        this.store.dispatch(updateAccountBlockedModalState({ data: response.loginState.showWarning }));
        this.handleMarkUpData(response.markupData)
      }
      if (response.loginState.firstTimeBlocked || response.loginState.selfUnblockWait || response.loginState.blockedUser) {
       
        if (response.loginState.firstTimeBlocked) {
          this.isFirstTimeBlocked = response.loginState.firstTimeBlocked;
        }
       
        if (response.loginState.selfUnblockWait) {
          this.isSelfUnblockWait = response.loginState.selfUnblockWait;
        }

        if (response.loginState.selfUnblockAt) {
          this.selfUnblockAt = response.loginState.selfUnblockAt;
        }
        
        if (response.loginState.blockedUser) {
          this.isBlockedUser = response.loginState.blockedUser;
        }
        this.handleMarkUpData(response.markupData)

      }
    }
    if (!this.isShowWarning && this.platformService.isLoggedIn()) {
      setToSessionStorage(KEY_SID_CHECK, '')
    }
    if ((!this.isBlockedUser && !this.isFirstTimeBlocked && !this.platformService.isLoggedIn() && !this.isSelfUnblockWait )&&( this.defaultTime==this.selfUnblockAt)) {
           this.platformService.redirect('/login',302)
        }
  }
  handleAccountBlockedApi() {
    // API Call from Authentication Service
    this.auth.accountBlockedApi(this.sid).subscribe({
      next: (res: any) => {
        if (res.success && res.data && res.data.loginState ) {
          if (!this.isServer) {
          setTimeout(()=>hideLoader(), 1000)
        }
          this.handleAccountBlockedData(res.data);
        }
        
      },
      error: (error) => {
        console.error(error.error.message)
      }      
    });

  }
  isCheckedLoginKey() {

    //Getting sid from session storage or query Params
    getFromSessionStorage(KEY_SID_CHECK) ? this.sid = getFromSessionStorage(KEY_SID_CHECK) : this.activeroute.queryParams.subscribe(d => this.sid = d.sid);

    if (this.sid) {

      if (!this.isServer) {
        this.handleAccountBlockedApi();
      }
    }
    else if (!this.sid && !this.platformService.isLoggedIn()){
      this.platformService.redirect('/login',302)
    }
  }

  // Hide the warning model on browser or client side
  hideWarningModal() {
    if (!isServer()) { setToSessionStorage(KEY_SID_CHECK, '') };
   
    this.store.dispatch(updateAccountBlockedModalState({ data: false }));
  }

  restRequestApi() {
    this.auth.accountBlockedResetApi(this.sid).subscribe({

      next: (response: any) => {

        if (response.success && response.data.markupData) {
            this.isSelfUnblockWait = true;
            this.handleMarkUpData(response.data.markupData)
        }
      },
      error: (error) => {
        console.error(error.error.message)
      }
    })
  }
  ngOnInit(): void {
    this.isCheckedLoginKey();
  }
}
