import { Validator as _ } from "../../../shared/utility/validator";
import { baseModel ,setupModel } from "../../types";
import {propertyMaps, arrayModel, SourceProjectionMapper, objModel, pipeModel} from "../../mapping-framework";
import { Network } from '../../../core/services/model-manager/main';
import {getMinsDiff} from "../../../shared/utility/tb-common";

//frontend model

export class _DoubtDetailsModel extends baseModel {

    @propertyMaps('title', _.str)
    public title;
 
    @propertyMaps('text', _.str)
    public text;

    @propertyMaps('images', _.arr)
    public images;
}
setupModel(_DoubtDetailsModel, {});


export class _Author extends baseModel {

    @propertyMaps('sid', _.str)
    public sid;

    @propertyMaps('name', _.str)
    public name;
}
setupModel(_Author, {});

export class _User extends baseModel {
    @propertyMaps('id', _.str)
    public id;

    @propertyMaps('name', _.str)
    public name;

    @propertyMaps('email', _.str)
    public email;

    @propertyMaps('image', _.profilePic)
    public image;
}

setupModel(_User, {type: 'doubts'});

function reduceToModAnswer(modInfo:any,__projectionMode){
    if(__projectionMode == true){
        return 1;
    }
    let modAnswer;
    //tentative logic
    if(modInfo && modInfo.answers && modInfo.answers.length > 0){
        modAnswer = modInfo.answers[0] && modInfo.answers[0].answerId;
    }
    return modAnswer;
}

export class _DoubtModel extends baseModel{ 
    @propertyMaps('id', _.str)
    public id;

    @propertyMaps('entityType', _.str)
    public entityType;

    @propertyMaps('entityId', _.str)
    public entityId;

    @propertyMaps()
    public on;

    @propertyMaps('myAnswersCount', _.num)
    public myAnswersCount;

    @propertyMaps('subjectId', _.str)
    public subjectId;

    @propertyMaps('subjectName', _.str)
    public subjectName;

    @propertyMaps('details', objModel(_DoubtDetailsModel), _DoubtDetailsModel)
    public details;

    @propertyMaps('upVotes', _.num)
    public upVotes;

    @propertyMaps('isSolved', _.bool)
    public isSolved;

    @propertyMaps('isPinned', _.bool)
    public isPinned;

    @propertyMaps('answersCount', _.num)
    public answersCount;

    @propertyMaps('requiresReview',_.bool)
    public requiresReview;

    @propertyMaps()
    public modAnswer;

    @propertyMaps('liked', _.bool)
    public liked;

    @propertyMaps('images', _.arr)
    public images;

    @propertyMaps('user', objModel(_User), _User)
    public user;

    @propertyMaps()
    public addedToMyDoubt;

    @propertyMaps()
    public bestAnswerExists;

    @propertyMaps()
    public answered;

    @propertyMaps()
    public tags;

    @propertyMaps('maCategoryId', _.arr)
    public maCategoryId;

    @propertyMaps('maSubjectId', _.str)
    public maSubjectId;

    @propertyMaps('maSubjectName', _.str)
    public maSubjectName;

    @propertyMaps('qNum', _.num)
    public qNum;

}
setupModel(_DoubtModel,{type: 'doubts'});

export class _IndividualDoubtModel extends baseModel {
    @propertyMaps('doubt', objModel(_DoubtModel), _DoubtModel)
    public doubt;
}
setupModel(_IndividualDoubtModel, {type: 'doubts'});

export class _DoubtsModel extends baseModel{

    @propertyMaps('doubts',arrayModel(_DoubtModel),_DoubtModel)
    public doubts;

}
setupModel(_DoubtsModel,{type: 'doubts'});

//api model
export class DoubtDetailsModel extends baseModel {

    @propertyMaps('title', _.str)
    public title;

    @propertyMaps('text', _.str)
    public text;

    @propertyMaps('images', _.arr(''))
    public images;
}
setupModel(DoubtDetailsModel, {});

export class Author extends baseModel {

    @propertyMaps('sid', _.str)
    public sid;

    @propertyMaps('name', _.str)
    public name;
}
setupModel(Author, {});

export class User extends baseModel {
    @propertyMaps('id', _.str)
    public id;

    @propertyMaps('name', _.str)
    public name;

    @propertyMaps('email', _.str)
    public email;

    @propertyMaps('image', _.str)
    public image;
}
setupModel(User, {});

export class DoubtModel extends baseModel{
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('entityType', _.str)
    public entityType;

    @propertyMaps('entityId', _.str)
    public entityId;

    @propertyMaps('subjectId', _.str)
    public subjectId;

    @propertyMaps('subjectName', _.str)
    public subjectName;

    @propertyMaps('details', objModel(DoubtDetailsModel), DoubtDetailsModel)
    public details;

    @propertyMaps('user', objModel(User), User)
    public user;

    @propertyMaps('on', _.str)
    public on;

    @propertyMaps()
    public upVotes;

    @propertyMaps()
    public isSolved;

    @propertyMaps('answersCount', _.num)
    public answersCount;

    @propertyMaps('requiresReview',_.bool)
    public requiresReview;

    @propertyMaps('modInfo',pipeModel(reduceToModAnswer))
    public modAnswer;

    @propertyMaps()
    public isPinned;

    @propertyMaps()
    public liked;

    @propertyMaps()
    public images;

    @propertyMaps('addedToMyDoubt', _.bool)
    public addedToMyDoubt;

    @propertyMaps()
    public bestAnswerExists;

    @propertyMaps()
    public answered;

    @propertyMaps()
    public tags;

    @propertyMaps('maCategoryId', _.arr)
    public maCategoryId;

    @propertyMaps('maSubjectId', _.str)
    public maSubjectId;

    @propertyMaps('maSubjectName', _.str)
    public maSubjectName;

    @propertyMaps('qNum', _.num)
    public qNum;

}
setupModel(DoubtModel,{});

let doubtsProcessor = (item) => {
    item.timeTillMins = getMinsDiff(new Date(item.on), new Date());
    return item;
};

export class DoubtsModel extends baseModel{
    @propertyMaps('doubts',arrayModel(DoubtModel),DoubtModel)
    public doubts;

}
setupModel(DoubtsModel, {type: 'doubts', __projection: true});

export class IndividualDoubtModel extends baseModel {
    @propertyMaps('doubt', objModel(DoubtModel), DoubtModel)
    public doubt;
}
setupModel(IndividualDoubtModel, {type: 'doubts', __projection: true});

export class DeleteDoubtParams extends baseModel {
    @propertyMaps('doubt', objModel(_DoubtModel), _DoubtModel)
    public doubt;
}
setupModel(DeleteDoubtParams, {});

export class SubjectiveCategory extends baseModel {

    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('title', _.str)
    public title;
}
setupModel(SubjectiveCategory, {});

export class SubjectiveCategories extends baseModel {
    @propertyMaps('tags', arrayModel(SubjectiveCategory), SubjectiveCategory)
    public tags
}
setupModel(SubjectiveCategories, {});
export class GetAllDoubtsApi{
    static apiEndpoint = {
        product: 'v1/doubt',
        global: 'v1/doubts/global',
        mainsAnswer: 'v1/doubts/global'
    };

    static projection;
    static get  __projection(){ 
        if(!GetAllDoubtsApi.projection){
            GetAllDoubtsApi.projection = JSON.stringify(new SourceProjectionMapper(DoubtsModel).map());
        }
        return GetAllDoubtsApi.projection;
    }

    static apiCall(network:Network,params){
        let queryParams: any = {};
        let apiEndpoint: string = GetAllDoubtsApi.apiEndpoint[params.entityType];
        queryParams.entityType = params.entityType;
        if(params.entityType === 'product') {
            queryParams.entityId = params.entityId
        }
        if(params?.entityType === 'mainsAnswer' ) {
            params?.maCategoryIds && (queryParams.maCategoryIds = params.maCategoryIds);
            params?.goalIds && (queryParams.goalIds = params.goalIds);
        }
        else{
            if(params?.tagIds) {
                queryParams.tagFilter = params.tagIds;
            }
            if(params?.subjectIds) {
                queryParams.subjectIds = params.subjectIds;
            }
        }
        queryParams.skip = params.skip || 0;
        queryParams.limit = params.limit || 10;
        queryParams.__projection = GetAllDoubtsApi.__projection;
        return network.get(apiEndpoint,queryParams)
    }
}

export class GetIndividualDoubtApi {
    static apiEndpoint = 'v1/doubt/{doubtId}';

    static projection;
    static get  __projection(){ 
        if(!GetIndividualDoubtApi.projection){
            GetIndividualDoubtApi.projection = JSON.stringify(new SourceProjectionMapper(IndividualDoubtModel).map());;
        }
        return GetIndividualDoubtApi.projection;
    }

    static apiCall(network: Network, doubtId: string) {
        return network.get(GetIndividualDoubtApi.apiEndpoint.replace('{doubtId}', doubtId), {__projection: GetIndividualDoubtApi.__projection});
    }
}

export class GetMyDoubtsApi {
    static apiEndpoint = 'v1/doubt/me';

    static projection;
    static get  __projection(){ 
        if(!GetMyDoubtsApi.projection){
            GetMyDoubtsApi.projection = JSON.stringify(new SourceProjectionMapper(DoubtsModel).map());
        }
        return GetMyDoubtsApi.projection;
    }

    static apiCall(network:Network, params){
        return network.get(GetMyDoubtsApi.apiEndpoint,{skip: params.skip,goalIds:params?.goalIds || "", limit: params.limit, entityType: params.entityType, entityId: params.entityId,__projection: GetMyDoubtsApi.__projection})
    }
}

export class DeleteDoubtApi {
    static apiEndpoint = 'v1/doubt/{doubtId}';

    static apiCall(network: Network, params) {
        return network.delete(DeleteDoubtApi.apiEndpoint.replace('{doubtId}', params.doubt.id));
    }
}

export class GetSubjectiveCategoriesApi {
    static apiEndpoint = 'v1/doubt/ma-categories';

    static projection;
    static get  __projection(){ 
        if(!GetSubjectiveCategoriesApi.projection){
            GetSubjectiveCategoriesApi.projection = JSON.stringify(new SourceProjectionMapper(SubjectiveCategories).map());
        }
        return GetSubjectiveCategoriesApi.projection;
    }
    static apiCall(network: Network, params) {
        return network.get(GetSubjectiveCategoriesApi.apiEndpoint,{__projection: GetSubjectiveCategoriesApi.__projection});
    }
}
