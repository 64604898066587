import {Store} from "@ngrx/store";
import { Injectable} from '@angular/core';
import {PlatformService} from "@core/services/platform-service";
import { isServer } from '@angular-commons/shared/utility/tb-common';
import { TransferStateManager } from '@angular-commons/core/services/transferStateManager';
import {AuthenticationService} from "../../../../angular-commons/core/services/authentication.service";
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import { seoApiIndividualGroup } from '../route-modules/free-practice/feature-state/free-practice.actions';
import { selectIndividualSeoGroup } from '../route-modules/free-practice/feature-state/free-practice.selectors';
import { CURRENT_PRACTICE_GROUP_DATA_KEY } from "@angular-commons/shared/utility/constants";
@Injectable({
    providedIn: 'root'
  })
export class CurrentPracticeGroupSelectionGuard implements CanActivate {

    constructor(private router:Router,
                private store: Store<{}>,
                private platformService: PlatformService,
                private transfermanager: TransferStateManager,
                private auth: AuthenticationService) {
    }
  payload: any;
  isServer: any = isServer();
    groupIdOrSlug: any;
    isLoggedIn = this.platformService.isLoggedIn();
    seoApiIndividualGroup$=this.store.select(selectIndividualSeoGroup)

  handleGroupSlug(currentPracticeGroup) {
    this.payload = { groupId:currentPracticeGroup }
    this.store.dispatch(seoApiIndividualGroup({ payload: this.payload }));
 this.seoApiIndividualGroup$.subscribe((value) => {
      if (value && value?.group?.properties?.seoProperties?.slug) {
          this.groupIdOrSlug = value?.group?.properties?.seoProperties?.slug || currentPracticeGroup
            let url = `${this.groupIdOrSlug}-questions`;
            this.platformService.redirect(url);
            return false;
      }
    })
      
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        if (this.isLoggedIn ) {
            let currentPracticeGroup =this.platformService.getCookie(CURRENT_PRACTICE_GROUP_DATA_KEY);    
            if (currentPracticeGroup) {
                this.handleGroupSlug(currentPracticeGroup)
            }
        }
        return true;
    }
}