import { Network } from "@angular-commons/core/services/model-manager/main";
import { arrayModel, propertyMaps, SourceProjectionMapper } from "@angular-commons/models/mapping-framework";
import { baseModel, setupModel } from "@angular-commons/models/types";
import { Validator as _ } from "@shared/utility/validator";



export class PaymentDetails extends baseModel {
    @propertyMaps('_id',_.str)
    public id;

    @propertyMaps('amount',_.num)
    public amount;

    @propertyMaps('amountToPay',_.num)
    public amountToPay;

    @propertyMaps('minCost',_.num)
    public minCost;

    @propertyMaps('passDays',_.num)
    public passDays;

    @propertyMaps('status',_.str)
    public status;

    @propertyMaps("dueOn",_.str)
    public dueOn;

    @propertyMaps("paidDate",_.date)
    public paidDate;
}
setupModel(PaymentDetails,{});

export class EmiData extends baseModel {
    @propertyMaps('payments', arrayModel(PaymentDetails),PaymentDetails)
    public payments;
}
setupModel(EmiData, {});




export class getEmiDetailsApi {
    
    static apiEndpoint = 'v2/emi/preview';

    static projection;
    static get  __projection(){ 
        if(!getEmiDetailsApi.projection){
            getEmiDetailsApi.projection = JSON.stringify(new SourceProjectionMapper(EmiData).map());;
        }
        return getEmiDetailsApi.projection;
    }


    static apiCall(network:Network, params:any){
        params = {...params, __projection : getEmiDetailsApi.__projection};
        return network.get(getEmiDetailsApi.apiEndpoint, params);
    }

}

