import { createReducer, on } from "@ngrx/store";
import {resetOnNavigation, setEmiIframeState} from "@core/application-state/app.actions";


export const emiIframeInitialState = {
    openModal: false,
    modalType: '',
    productId: '',
    planId: ''
};

const _emiIframeReducer = createReducer(emiIframeInitialState,
    on(setEmiIframeState, (_state, payload) => {
        let {type, ...emiIframeParams} = payload;
        let state = {..._state}
        state.openModal = emiIframeParams.openModal;
        state.modalType = emiIframeParams.modalType;
        state = {...state, ...emiIframeParams};
        return state;
    }),
    on(resetOnNavigation, (_state, {url}) => {
        let state = {..._state};
        state.openModal = false;
        state.modalType = '';
        state.productId = '';
        state.planId = '';
        return state;
    })
);

export function emiIframeReducer(state, action) {
    return _emiIframeReducer(state, action);
}
