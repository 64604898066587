import { PlatformService } from '@angular-commons/core/services/platform-service';
import {Component, Input, OnInit} from '@angular/core';
import {showCouponModal} from "@core/application-state/app.actions";
import {Store} from "@ngrx/store";

@Component({
  selector: 'price-breakdown',
  templateUrl: './price-breakdown.component.html',
  styleUrls: ['./price-breakdown.component.scss']
})
export class PriceBreakdownComponent implements OnInit {

  @Input() priceBreakDownObj;
  @Input() isLogoutGoalSubs:boolean = false;
  @Input() showTBPass = false;
  @Input() passType? = "goalSubs"; // or globalPass
  @Input() cssModifierClass?:string='';
  @Input() showEMIPlans = false;
  isMobile:boolean = this.platformService.isMobile();

  constructor(private store:Store<{}>,private platformService:PlatformService) { }

  ngOnInit(): void {}

  openCouponModal(){
    this.store.dispatch(showCouponModal({ModalType:'default',productType: this.passType}));
  }

}
