import { NgModule ,SkipSelf} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressIndicatorComponent } from './progress-indicator.component';
import { CoreModule } from '@angular-commons/core/core.module';
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {customTranslateLoader, langChangeListener} from "@shared/utility/translation-loaders";
import {HttpClient} from "@angular/common/http";
import {TransferState} from "@angular/platform-browser";
import {TbcoreService} from "@core/services/tbcore.service";
import {PlatformService} from "@core/services/platform-service";

export function translateLoaderFactory(http: HttpClient,transferState :TransferState) {
  return new customTranslateLoader(http,transferState,'selections');
}



@NgModule({
  declarations: [ProgressIndicatorComponent],
  imports: [
    CommonModule,
    CoreModule,
    TranslateModule,
      TranslateModule.forChild({
        loader: {
            provide: TranslateLoader,
            useFactory: translateLoaderFactory,
            deps: [HttpClient,[new SkipSelf(), TransferState]]
        },
              extend: true
    })
  ],
  exports : [ProgressIndicatorComponent]
})
export class ProgressIndicatorExportModule { 
    constructor(private translateService:TranslateService,private tbcore:TbcoreService, private platformService: PlatformService) {
        langChangeListener(this.translateService,this.tbcore, this.platformService);
    }
}
