import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ModelManager} from "@angular-commons/core/services/model-manager.service";
import {EMPTY, of} from "rxjs";
import {switchMap, map,catchError} from 'rxjs/operators';
import { DataRecipe } from '@angular-commons/core/services/model-manager/recipe';
import { getEmiDetails, getEmiDetailsLoaded } from './down-payment.action';
import { IdentityAdapter } from '@angular-commons/models/mapping-framework';
import { EmiData, getEmiDetailsApi } from '@angular-commons/models/entity/emi-payment/down-payment.adapter';



@Injectable()
export class EmiDetailsEffects{
    
    getEmiDetailsRecipe : DataRecipe;

    GoalCourseAnnouncements = createEffect( () => this.action$.pipe(
        ofType(getEmiDetails),
        switchMap((action) => {
        return this.getEmiDetailsRecipe.get(action).pipe(
            map((data: any) => getEmiDetailsLoaded({payload: data})),
            catchError(() => EMPTY)
        );
        })
    ));


    constructor(private modelManager: ModelManager, private action$ : Actions){
        this.getEmiDetailsRecipe =   modelManager.makeDataRecipe(IdentityAdapter, EmiData, getEmiDetailsApi);

    }
}
