import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router'
import { CouponCodeComponent } from './features/coupon-code-modal/coupon-code.component';
import {EffectsModule} from "@ngrx/effects";
import {StoreModule} from "@ngrx/store";
import {CouponEffects} from "./feature-state/coupon.effects";
import {couponReducer} from "./feature-state/coupon.reducers";
import { FormsModule } from '@angular/forms';
import { CouponCodeStatusComponent } from './features/coupon-code-status/coupon-code-status.component';
import {TranslateModule} from "@ngx-translate/core";
import { CouponSuccessModalComponent } from './features/coupon-success-modal/coupon-success-modal.component';
import {CoreModule} from "@core/core.module";

@NgModule({
  declarations: [
    CouponCodeComponent, 
    CouponCodeStatusComponent, 
    CouponSuccessModalComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        EffectsModule.forFeature([CouponEffects]),
        StoreModule.forFeature('coupon', couponReducer),
        RouterModule,
    ],
  exports: [
    CouponCodeComponent, 
    CouponCodeStatusComponent, 
    CouponSuccessModalComponent
  ]
})
export class CouponCodeModule { }
