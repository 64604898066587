
<ng-container *ngIf="!isCreativeLogin">
    <ng-container *ngIf="!uiState?.isLogin && !uiState?.isSignUp && !uiState?.isVerification">
        <div class="mobile-verification input-container {{cssModifierClass}}" *ngIf="onboardingParams?.methods?.mobile" [ngClass]="{'signup-current-affairs': isCurrentAffairs,'signup':isMobile && callReq || isMgoal}">
            <p class="input-container__heading" *ngIf="!isCurrentAffairs">{{uiState?.strings?.headerText}}</p>
            <p class="input-container__heading" *ngIf="isCurrentAffairs" translate>{{'TEST_EXAM_KNOWLEDGE'}}</p>
            <div class="user-count" *ngIf="uiState?.subscriberCount">
                <img class="user-img" src="/assets/img/social/usersBubble.svg" alt="usersBubble">
                <span>{{uiState?.subscriberCount}}+</span>
                <span class="text-dark"> Active Users</span>
            </div>
            <div class="input-container__group">
                <div class="input-group" [ngClass]="{'error' : uiState?.user?.mobile?.error}">
                    <div class="d-flex a-center">
                        <div class="input-icon phone-icon"></div>
                        <input type="tel" placeholder="{{'ENTER_MOBILE_NUMBER' | translate}}" name="mob" cb-persist="signupmobile"
                            [(ngModel)]="uiState.user.mobile.value" (blur)="enforceMobile($event)" (keyup)="mobileKeys($event)"
                            (keydown)="mobileKeys($event)" (keypress)="signupOnEnter($event)" (focus)="truecallerOnFocus()">
                    </div>
                    <div class="bottom-bar"></div>
                    <div class="error-message" [hidden]="!uiState?.user?.mobile?.error">{{uiState.user.mobile.error}}</div>
                </div>
                <button *ngIf="onPage" type="button" class="btn btn--cta btn-custom" (click)="mobileSignup()" cb-await=""
                    cb-await-id="mobilesignupcta">{{uiState?.ctaText || 'SIGNUP_AND_TAKE_FREE_TESTS' | translate}}</button>
                <button *ngIf="!onPage" type="button" class="btn btn--cta btn-custom" (click)="mobileSignup()" cb-await=""
                    cb-await-id="mobilesignupcta" translate>{{ uiState?.ctaText || signupCtaText | translate}}</button>
            </div>
            <div class="user-count" *ngIf="userCount"><span>{{userCount}}+</span> Enrolled this test series</div>
            <div class="user-counts count--current-affair" *ngIf="isCurrentAffairs">
                <img width="20" height="20" src="/angular/assets/img/template-img/user.png" alt="userImage"/>
                <img width="20" height="20" src="/angular/assets/img/user-image-1.jpg" alt="userImage"/>
                <img width="20" height="20" src="/angular/assets/img/user-image-2.jpg" alt="userImage"/>
                <strong> 1306.3k+</strong> Enrolled in test series
            </div>
        </div>
        <!-- TrueCaller Login/Signup -->
        <ng-container *ngIf="isTrueCallerFlow">
            <div class="login-with-truecaller" [ngClass]="{'error' : uiState?.truecaller.error}"
            *ngIf="!onboardingParams.hideSocialSignups && !onboardingParams.isInModal && isMobile">
                <button type="button" (click)="initTruecallerFlow()" class="social-btn">
                    <i class="social-icon icon-truecaller"></i>
                    <span translate>{{'CONNECT_TRUECALLER'}}</span>
                    <div class="loader" [hidden]="!uiState.truecaller.loading"><div></div><div></div><div></div><div></div></div>
                </button>
                <div class="error-message" [hidden]="!uiState.truecaller.error">{{uiState.truecaller.error}}</div>
            </div>
        </ng-container>
    </ng-container>


    <!--Signup with OTP -->
    <ng-container *ngIf="uiState?.isSignUp">
        <div class="mobile-verification login-container {{cssModifierClass}}" [ngClass]="{'signup': isMgoal}">
            <div class="mb-12">
                <p class="text-light-sm">{{'OTP_SENT_ON' | translate}}</p>
                <p class="d-flex a-center">
                    <span class="mr-2">{{uiState?.user?.mobile?.value}}</span>
                    <span class="input-icon edit-icon" (click)="signupMethod(false,false, false)"></span>
                </p>
            </div>
            <div class="input-group mb-6" [ngClass]="{'error':uiState?.user?.otp?.error}">
                <div class="d-flex a-center">
                    <div class="input-icon otp-icon"></div>
                    <input type="tel" name="otp" placeholder="OTP" autocomplete="one-time-code"
                        [(ngModel)]="uiState.user.otp.value" (keypress)="signupOnEnter($event)"
                        (keyup)="requiredField(uiState.user.otp)" (keydown)="requiredField(uiState.user.otp)"
                        (blur)="otpField(uiState?.user?.otp)" #otpAndMobileInput>
                </div>
                <div class="bottom-bar"></div>
                <div class="error-message" [hidden]="!uiState?.user?.otp?.error">{{uiState?.user?.otp?.error}}</div>
            </div>

            <div class="text-light-sm" *ngIf="uiState.otp.nextRequestIn > 0">
                {{'RESEND_OTP_TIME' | translate: {'otpTime' : uiState?.otp?.nextRequestIn} }}
            </div>

            <div *ngIf="uiState?.otp?.nextRequestIn <= 0">
                <button type="button" class="btn-link" (click)="requestOTP()">{{'RESEND_OTP_TEXT' | translate}}</button>
                <span class="text-light-sm mx-2">|</span>
                <button type="button" class="btn-link" (click)="requestOTP(true)">{{'GET_OTP_TEXT' | translate}}</button>
            </div>

            <div class="mt-6 mb-4">
                <button type="button" class="btn btn--cta w-100" (click)="signupOTP()">{{'SIGNUP' | translate}}</button>
                <div [ngClass]="{'error':uiState.otp.error}">
                    <div class="error-message" [hidden]="!uiState?.otp?.error">{{uiState?.otp?.error}}</div>
                </div>
            </div>
        </div>
    </ng-container>

    <!--Login with OTP -->
    <ng-container *ngIf="uiState.isLogin">
        <div class="mobile-verification login-container {{cssModifierClass}}" [ngClass]="{'signup': isMgoal}">
            <h2 class="sub-heading mb-4">{{'WELCOME_BACK_PERSONAL' | translate : {'userName': uiState?.user?.name?.value} }}</h2>

        <div class="input-group info">
            <div class="d-flex a-center">
                <div class="input-icon phone-icon"></div>
                <input type="text" name="mobileOrEmail" [(ngModel)]="uiState.user.mobile.value" disabled>
                <div class="input-icon edit-icon" (click)="signupMethod(false,false, false)"></div>
            </div>
            <div class="bottom-bar"></div>
            <div class="info-message">{{'OTP_SENT_ON' | translate}}</div>
        </div>

        <div class="input-group info">
            <div class="d-flex a-center">
                <div class="input-icon otp-icon"></div>
                <input type="tel" name="otp" placeholder="OTP" autocomplete="one-time-code"
                    [(ngModel)]="uiState?.user?.otp.value" (keyup)="requiredField(uiState?.user?.otp)"
                    (keydown)="requiredField(uiState?.user?.otp)" (keydown.enter)="loginOTP()"
                    (blur)="otpField(uiState?.user?.otp)">
            </div>
            <div class="bottom-bar"></div>
            <div [ngClass]="{'error':uiState?.user?.otp?.error}">
                <div class="error-message" [hidden]="!uiState?.user?.otp?.error">{{uiState?.user?.otp?.error}}</div>
            </div>
            <div class="info-message mt-4" *ngIf="uiState?.otp?.nextRequestIn > 0">
                {{'RESEND_OTP_TIME' | translate: {'otpTime' : uiState?.otp?.nextRequestIn} }}
            </div>

        </div>

        <div *ngIf="uiState?.otp?.nextRequestIn <= 0">
            <button class="btn-link" (click)="requestOTP()" type="button">{{'RESEND_OTP_TEXT' | translate}}</button>
            <span class="text-light-sm mx-2">|</span>
            <button class="btn-link" (click)="requestOTP(true)" type="button">{{'GET_OTP_TEXT' | translate}}</button>
        </div>

        <div class="mt-6 mb-4">
            <button type="button" class="btn btn--cta w-100" (click)="loginOTP()">{{'LOGIN' | translate}}</button>
        </div>
        </div>
    </ng-container>

    <!--Verify with OTP -->
    <ng-container *ngIf="uiState?.isVerification" >
        <div class="mobile-verification login-container {{cssModifierClass}}" [ngClass]="{'signup': isMgoal}">
            <h2 class="sub-heading">{{uiState?.strings?.headerText}}</h2>

            <div class="input-group mb-6 info">
                <div class="d-flex a-center">
                    <div class="input-icon phone-icon"></div>
                    <input type="text" name="mobileOrEmail" [(ngModel)]="uiState?.user?.mobile.value" disabled>
                    <div class="input-icon edit-icon" (click)="signupMethod(false,false, false)"></div>
                </div>
                <div class="bottom-bar"></div>
                <div class="info-message">{{'OTP_SENT_ON' | translate}}</div>
            </div>

            <div class="input-group info">
                <div class="d-flex a-center">
                    <div class="input-icon otp-icon"></div>
                    <input type="tel" name="otp" placeholder="OTP" autocomplete="one-time-code"
                        [(ngModel)]="uiState?.user?.otp.value" (keyup)="requiredField(uiState?.user?.otp)"
                        (keydown)="requiredField(uiState?.user?.otp)" (keydown.enter)="verifyOtp()"
                        (blur)="otpField(uiState?.user.otp)">
                </div>
                <div class="bottom-bar"></div>
                <div [ngClass]="{'error':uiState?.user?.otp?.error}">
                    <div class="error-message" [hidden]="!uiState?.user?.otp?.error">{{uiState?.user?.otp?.error}}</div>
                </div>
                <div class="info-message mt-4" *ngIf="uiState?.otp?.nextRequestIn > 0">
                    {{'RESEND_OTP_TIME' | translate: {'otpTime' : uiState?.otp?.nextRequestIn} }}
                </div>

            </div>

            <div *ngIf="uiState?.otp?.nextRequestIn <= 0">
                <button class="btn-link" (click)="requestOTP()" type="button">{{'RESEND_OTP_TEXT' | translate}}</button>
                <span class="text-light-sm mx-2">|</span>
                <button class="btn-link" (click)="requestOTP(true)" type="button">{{'GET_OTP_TEXT' | translate}}</button>
            </div>

            <div class="mt-6 mb-4">
                <button type="button" class="btn btn--cta w-100" (click)="verifyOtp()">{{'VERIFY' | translate}}</button>
                <div [ngClass]="{'error':uiState?.otp.error}">
                    <div class="error-message" [hidden]="!uiState?.otp?.error">{{uiState?.otp?.error}}</div>
                </div>
            </div>
        </div>
    </ng-container>

</ng-container>


<ng-container *ngIf="isCreativeLogin">
        <div class="popup" [ngClass]="{'popup-target': popupType === 'target' && popupCategory != 'leaving',
        'popup-target-child': popupType === 'targetChild','popup-generic':popupType === 'generic','leaving-popup-generic':popupCategory === 'leaving'}" style="height: 320px;">
            <!-- target page popup -->
            <div class="popup-container">
                <div *ngIf="!uiState.isLogin && !uiState?.isSignUp" class="popup-heading" [innerHTML]="creativeLoginParams[popupType]?.[popupCategory]?.popupHeading(popupTitle) | safeHtml"></div>
                <div *ngIf="!uiState.isLogin && !uiState?.isSignUp" class="popup-subheading" [innerHTML]="creativeLoginParams[popupType]?.[popupCategory]?.popupSubHeading| safeHtml"></div>
                <ng-container *ngIf="!uiState?.isLogin && !uiState?.isSignUp && !uiState?.isVerification">
                    <div class="mobile-verification input-container-creative {{cssModifierClass}}" *ngIf="onboardingParams?.methods?.mobile">
                        <!-- <p class="input-container__heading">{{uiState?.strings?.headerText}}</p> -->
                        <div class="user-count" *ngIf="uiState?.subscriberCount">
                            <img class="user-img" src="/assets/img/social/usersBubble.svg" alt="usersBubble">
                            <span>{{uiState?.subscriberCount}}+</span>
                            <span class="text-dark"> Active Paid Users</span>
                        </div>
                        <div class="input-container__group">
                            <div class="input-group" [ngClass]="{'error' : uiState?.user?.mobile?.error}">
                                <div class="d-flex a-center">
                                    <div class="input-icon phone-icon"></div>
                                    <input [ngClass]="{'popup-input': isCreativeLogin}" type="tel" placeholder="{{'ENTER_MOBILE_NUMBER' | translate}}" name="mob" cb-persist="signupmobile"
                                        [(ngModel)]="uiState.user.mobile.value" (blur)="enforceMobile($event)" (keyup)="mobileKeys($event)"
                                        (keydown)="mobileKeys($event)" (keypress)="signupOnEnter($event)">
                                </div>
                                <div class="bottom-bar"></div>
                                <div class="error-message" [hidden]="!uiState?.user?.mobile?.error">{{uiState.user.mobile.error}}</div>
                            </div>
                            <button *ngIf="!onPage" type="button" class="btn btn--cta btn-custom" (click)="mobileSignup()" cb-await=""
                                cb-await-id="mobilesignupcta">Sign Up Now  - It’s FREE</button>
                        </div>
                    </div>
                </ng-container>

                <!--Signup with OTP -->
                <ng-container *ngIf="uiState?.isSignUp">
                    <div class="mobile-verification login-container-creative {{cssModifierClass}}">
                        <div class="mb-12">
                            <p class="text-light-sm">{{'OTP_SENT_ON' | translate}}</p>
                            <p class="d-flex a-center">
                                <span class="mr-2">{{uiState?.user?.mobile?.value}}</span>
                                <span class="input-icon edit-icon" (click)="signupMethod(false,false, false)"></span>
                            </p>
                        </div>
                        <div class="input-group mb-6" [ngClass]="{'error':uiState?.user?.otp?.error}">
                            <div class="d-flex a-center">
                                <div class="input-icon otp-icon"></div>
                                <input type="tel" name="otp" placeholder="OTP" autocomplete="one-time-code"
                                    [(ngModel)]="uiState.user.otp.value" (keypress)="signupOnEnter($event)"
                                    (keyup)="requiredField(uiState.user.otp)" (keydown)="requiredField(uiState.user.otp)"
                                    (blur)="otpField(uiState.user.otp)" #otpAndMobileInput>
                            </div>
                            <div class="bottom-bar"></div>
                            <div class="error-message" [hidden]="!uiState?.user?.otp?.error">{{uiState.user.otp.error}}</div>
                        </div>

                        <div class="text-light-sm" *ngIf="uiState.otp.nextRequestIn > 0">
                            {{'RESEND_OTP_TIME' | translate: {'otpTime' : uiState?.otp?.nextRequestIn} }}
                        </div>

                        <div *ngIf="uiState?.otp?.nextRequestIn <= 0">
                            <button type="button" class="btn-link" (click)="requestOTP()">{{'RESEND_OTP_TEXT' | translate}}</button>
                            <span class="text-light-sm mx-2">|</span>
                            <button type="button" class="btn-link" (click)="requestOTP(true)">{{'GET_OTP_TEXT' | translate}}</button>
                        </div>

                        <div>
                            <button type="button" class="btn btn--cta w-100" (click)="signupOTP()">{{'SIGNUP' | translate}}</button>
                            <div [ngClass]="{'error':uiState.otp.error}">
                                <div class="error-message" [hidden]="!uiState?.otp?.error">{{uiState?.otp?.error}}</div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!--Login with OTP -->
                <ng-container *ngIf="uiState.isLogin">
                    <div class="mobile-verification login-container-creative {{cssModifierClass}}">
                        <h2 class="sub-heading mb-4 p-0">{{'WELCOME_BACK_PERSONAL' | translate : {'userName': uiState?.user?.name?.value} }}</h2>

                    <div class="input-group info">
                        <div class="d-flex a-center">
                            <div class="input-icon phone-icon"></div>
                            <input type="text" name="mobileOrEmail" [(ngModel)]="uiState.user.mobile.value" disabled>
                            <div class="input-icon edit-icon" (click)="signupMethod(false,false, false)"></div>
                        </div>
                        <div class="bottom-bar"></div>
                        <div class="info-message">{{'OTP_SENT_ON' | translate}}</div>
                    </div>

                    <div class="input-group info">
                        <div class="d-flex a-center">
                            <div class="input-icon otp-icon"></div>
                            <input type="tel" name="otp" placeholder="OTP" autocomplete="one-time-code"
                                [(ngModel)]="uiState.user.otp.value" (keyup)="requiredField(uiState.user.otp)"
                                (keydown)="requiredField(uiState.user.otp)" (keydown.enter)="loginOTP()"
                                (blur)="otpField(uiState.user.otp)">
                        </div>
                        <div class="bottom-bar"></div>
                        <div [ngClass]="{'error':uiState.user.otp.error}">
                            <div class="error-message" [hidden]="!uiState?.user?.otp?.error">{{uiState?.user?.otp?.error}}</div>
                        </div>
                        <div class="info-message mt-4" *ngIf="uiState?.otp?.nextRequestIn > 0">
                            {{'RESEND_OTP_TIME' | translate: {'otpTime' : uiState?.otp?.nextRequestIn} }}
                        </div>

                    </div>

                    <div *ngIf="uiState?.otp?.nextRequestIn <= 0">
                        <button class="btn-link" (click)="requestOTP()" type="button">{{'RESEND_OTP_TEXT' | translate}}</button>
                        <span class="text-light-sm mx-2">|</span>
                        <button class="btn-link" (click)="requestOTP(true)" type="button">{{'GET_OTP_TEXT' | translate}}</button>
                    </div>

                    <div>
                        <button type="button" class="btn btn--cta w-100" (click)="loginOTP()">{{'LOGIN' | translate}}</button>
                    </div>
                    </div>
                </ng-container>

                <!--Verify with OTP -->
                <ng-container *ngIf="uiState?.isVerification" >
                    <div class="mobile-verification login-container {{cssModifierClass}}">
                        <h2 class="sub-heading">{{uiState?.strings?.headerText}}</h2>

                        <div class="input-group mb-6 info">
                            <div class="d-flex a-center">
                                <div class="input-icon phone-icon"></div>
                                <input type="text" name="mobileOrEmail" [(ngModel)]="uiState.user.mobile.value" disabled>
                                <div class="input-icon edit-icon" (click)="signupMethod(false,false, false)"></div>
                            </div>
                            <div class="bottom-bar"></div>
                            <div class="info-message">{{'OTP_SENT_ON' | translate}}</div>
                        </div>

                        <div class="input-group info">
                            <div class="d-flex a-center">
                                <div class="input-icon otp-icon"></div>
                                <input type="tel" name="otp" placeholder="OTP" autocomplete="one-time-code"
                                    [(ngModel)]="uiState.user.otp.value" (keyup)="requiredField(uiState.user.otp)"
                                    (keydown)="requiredField(uiState.user.otp)" (keydown.enter)="verifyOtp()"
                                    (blur)="otpField(uiState.user.otp)">
                            </div>
                            <div class="bottom-bar"></div>
                            <div [ngClass]="{'error':uiState?.user?.otp?.error}">
                                <div class="error-message" [hidden]="!uiState?.user?.otp?.error">{{uiState?.user?.otp?.error}}</div>
                            </div>
                            <div class="info-message mt-4" *ngIf="uiState?.otp?.nextRequestIn > 0">
                                {{'RESEND_OTP_TIME' | translate: {'otpTime' : uiState?.otp?.nextRequestIn} }}
                            </div>

                        </div>

                        <div *ngIf="uiState?.otp?.nextRequestIn <= 0">
                            <button class="btn-link" (click)="requestOTP()" type="button">{{'RESEND_OTP_TEXT' | translate}}</button>
                            <span class="text-light-sm mx-2">|</span>
                            <button class="btn-link" (click)="requestOTP(true)" type="button">{{'GET_OTP_TEXT' | translate}}</button>
                        </div>

                        <div class="mt-6 mb-4">
                            <button type="button" class="btn btn--cta w-100" (click)="verifyOtp()">{{'VERIFY' | translate}}</button>
                            <div [ngClass]="{'error':uiState.otp.error}">
                                <div class="error-message" [hidden]="!uiState?.otp?.error">{{uiState?.otp?.error}}</div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="user-counts" *ngIf="popupType === 'target' && popupCategory === 'landing'" [innerHTML]="creativeLoginParams[popupType]?.[popupCategory]?.popupFooterText | safeHtml"></div>
                <div class="user-counts" *ngIf="popupType !== 'target' || popupCategory !== 'landing'">
                    <img width="20" height="20" src="https://storage.googleapis.com/testbook/static/dp/5a12990f7505ef0f4dd8cd5c_tb" alt="userImage"/>
                    <img width="20" height="20" src="https://storage.googleapis.com/testbook/static/dp/5a1190c575ce2c0f3fdcdd31_tb" alt="userImage"/>
                    <img width="20" height="20" src="https://storage.googleapis.com/testbook/static/dp/5a12990f7505ef0f4dd8cd5c_tb" alt="userImage"/>
                    <span class="ml-3" [innerHTML]="creativeLoginParams[popupType]?.[popupCategory]?.popupFooterText | safeHtml"></span>
                </div>
            </div>
            <img loading="lazy" width="210" height="200" [src]="creativeLoginParams[popupType]?.[popupCategory]?.popupTypeImage">
        </div>


</ng-container>
