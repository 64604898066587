import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable, of, ReplaySubject } from 'rxjs';
import {Store} from "@ngrx/store";
import {PlatformService} from "@core/services/platform-service";
import {selectGoal, selectGoalMap} from "../../../../angular-commons/core/application-state/app.selectors";
import {getGoalBySlug} from "../../../../angular-commons/core/application-state/app.actions";
import {GOAL_EXPIRY} from "../../../../angular-commons/shared/utility/constants";
import {AuthenticationService} from "../../../../angular-commons/core/services/authentication.service";
import { isServer } from '@angular-commons/shared/utility/tb-common';
import { TransferStateManager } from '@angular-commons/core/services/transferStateManager';
import {ProductAccessService} from "@core/services/product-access.service";
import {TbcoreService} from "@core/services/tbcore.service";

@Injectable({
  providedIn: 'root'
})
export class GoalEntityGuard implements CanActivate {
    private isLoggedIn: boolean = this.platformService.isLoggedIn();
    private isServer: boolean = isServer();
    transferStateValue
    constructor(
      private router:Router, 
      private store: Store<{}>,
      private platformService: PlatformService,
      private auth: AuthenticationService,
      private transferStateManager : TransferStateManager,
      private productAccess : ProductAccessService,
      private tbCore: TbcoreService) {
    }

    canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree>{
      
      if (!this.isLoggedIn) {
        const url = `/login?tile=login&redirect_url=`+encodeURI(state.url)
        this.platformService.redirect(url,302);
        return of(false);
      }
      let oldGoal = this.auth.getGoal();
      let goalSlug = route.params.goalSlug;
      if(goalSlug) {
        const sub = new ReplaySubject<boolean>();
        this.transferStateValue = this.transferStateManager.get(`currentGoalDetails${goalSlug}`,
          getGoalBySlug({slug: goalSlug}),
          this.store.select(selectGoalMap),
          (goal)=>{
              if(!(goal && goal[goalSlug] && goal[goalSlug]?.id)) {
                  this.platformService.gotoErrorPage();
                  sub.next(false);
                  sub.complete();
              } else {
                  if(goalSlug){
                      if(!oldGoal || (oldGoal.id != goal[goalSlug].id)){
                        this.auth.setGoal({id: goal[goalSlug]?.id,slug: goal[goalSlug]?.properties?.slug},GOAL_EXPIRY,false,true);
                      }
                  }
                  //if current goal is not purchased by the user then redirect to the main page
                  //this redirect is done at the server level as well,to avoid user getting a view of the page in post purchase state for a flash
                  this.productAccess.hasGoalSubscription(goal[goalSlug]?.id).then( hasAccess => {
                      if(!hasAccess && !isServer()){
                          this.platformService.redirect(`/${goalSlug}-coaching`,302);
                      }
                  }).finally(()=>{
                      if(this.transferStateValue){
                        this.transferStateValue.unsubscribe()
                      }
                      sub.next(true);
                      sub.complete();
                  });
              }
              if(this.transferStateValue){
                this.transferStateValue.unsubscribe()
              }
        });

        return sub;
      }

      return of(false);
    }
}
