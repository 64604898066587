<div *ngIf="!isServer" (click)="showModal()">
    <svg width="22" height="22">
        <use xlink:href="#search"></use>
    </svg>
</div>
<div *ngIf="isServer">
    <a href="/search">
        <svg width="22" height="22">
            <use xlink:href="#search"></use>
        </svg>
    </a>
</div>
<div class="global-search" *ngIf="isModalOpen" [ngClass]="{'show':isModalOpen}">
    <div class="global-search__header">
        <div class="global-search__back" (click)="hideModal()"></div>
        <global-search-bar (suggestions)="suggestions = $event" hideSuggestions="true"></global-search-bar>
    </div>
    <div class="global-search__body">
        <div class="global-search__list" *ngFor="let suggestion of suggestions">{{suggestion}}</div>
    </div>

<!--    <div class="global-search global-search&#45;&#45;mweb">-->
<!--        <ul class="global-search__history" *ngIf="modal.history && modal.history.length>0">-->
<!--            <li ng-repeat="search in modal.history | orderBy:'-timestamp'" ng-click="modal.useHistorical(search)">{{search.query}}</li>-->
<!--        </ul>-->
<!--    </div>-->
</div>
