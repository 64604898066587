import { Injectable} from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    ActivatedRoute,
    UrlTree
} from '@angular/router';
import {Observable, of} from "rxjs";
import {Store} from "@ngrx/store";
import {map} from "rxjs/operators";
import {PlatformService} from "@core/services/platform-service";
import {getMasterSeriesPageDetails} from "../route-modules/master-class-series/application-state/masterClassSeries.actions";
import {selectMasterSeriesDetails} from "../route-modules/master-class-series/application-state/masterClassSeries.selector";

import { skipInitialState } from '@angular-commons/shared/utility/ngrx-utils';



@Injectable()
export class MasterSeriesGuard implements CanActivate {

    constructor(private router:Router,
                private route: ActivatedRoute,
                private store: Store<{}>,
                private platformService: PlatformService) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean|UrlTree> {

        let id = route.params["idOrSlug"];

        if(id) {
            this.store.dispatch(getMasterSeriesPageDetails({id:id}));

            // had to skip 1 to skip first store default select response.
            return this.store.select(selectMasterSeriesDetails).pipe(skipInitialState(), map((item: any) => {
                if (!(item && item.id)) {
                    this.platformService.gotoErrorPage();
                    return false;
                }
                else{
                    return true;
                }

            }));
        }
        return of(true);
    }

}
