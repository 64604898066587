import {capitalize} from "@shared/utility/string-utilities";
import { getMultiLanguageUrl, wildCardRoutesSchemaJsonData } from "./tb-common";
import { getLanguageSegmentUrl } from "./url";

export const PAGE_SEO_SUBFOOTER = 'pageSeo';
export const SEO_GROUP_SUBFOOTER = 'seoGroup';
const LANG_CODES = {
  english: "en",
  hindi: "hi",
  marathi: "mr",
  bengali: "bn",
};

export function getHrefLang(lang) {
    let hreflangMap = {
        english: [{code: 'hi', lang: 'hindi'}, {code: 'mr', lang: 'marathi'}, {code: 'bn', lang: 'bengali'}],
        hindi:   [{code: 'en', lang: 'english'}, {code: 'mr', lang: 'marathi'}, {code: 'bn', lang: 'bengali'}],
        marathi: [{code: 'hi', lang: 'hindi'}, {code: 'en', lang: 'english'}, {code: 'bn', lang: 'bengali'}],
        bengali: [{code: 'hi', lang: 'hindi'}, {code: 'mr', lang: 'marathi'}, {code: 'en', lang: 'english'}],
    };
    return hreflangMap[lang.toLowerCase()];
}

export function getCurrentLangCode(lang) {
    return LANG_CODES[lang.toLowerCase()];
}

export function getCurrentAffairsPathName(lang){
   lang = lang.toLowerCase();
   return lang === 'english' ? '/current-affairs' : '/current-affairs-in-' + lang;
}

export function getCurrentAffairsChildPathName(childPath){
    return `/current-affairs/${childPath}`;
}

export function getValidOgImageURL(url : string = ''){
    if(url.startsWith('//')){
        url = 'https:' + url;
    }
    return url;
}

export function getBreadcrumbsSchemaJson(schemaJsonData:wildCardRoutesSchemaJsonData,language:string, isLanguageSegmentFlow:boolean = false){

    let schemaJson = [];
    let isBreadCrumbArrayValid = false;
    schemaJson.push({
        '@type': 'ListItem',
        position: 1,
        item: {
          '@type': 'WebSite',
          '@id': 'https://testbook.com',
          name: 'Home'
        }
    })

    if(schemaJsonData?.breadcrumbs?.length){
        schemaJsonData?.breadcrumbs.forEach((breadcrumb,index) => {
            if(breadcrumb?.url && breadcrumb?.title){
                isBreadCrumbArrayValid = true;
                schemaJson.push({
                    '@type': 'ListItem',
                    position: index + 2,
                    item: {
                        '@type': 'WebPage',
                        '@id': getMultiLanguageUrl(isLanguageSegmentFlow,language,breadcrumb.url),
                        name: breadcrumb.title
                    }
                });
            }
        });
    }
    if(schemaJsonData?.slug && schemaJsonData?.title && !isBreadCrumbArrayValid){
        let url = 'https://testbook.com/' + schemaJsonData?.slug;
        schemaJson.push({
              '@type': 'ListItem',
              position: 2,
              item: {
                '@type': 'WebPage',
                '@id': getMultiLanguageUrl(isLanguageSegmentFlow,language,url),
                name: schemaJsonData?.title
              }
            }
        );
    }

    if(schemaJsonData.childPageInfo && schemaJsonData.childPageInfo?.slug && schemaJsonData.childPageInfo?.title) {
        let url = 'https://testbook.com/' + schemaJsonData?.slug + '/' + schemaJsonData.childPageInfo?.slug;
        schemaJson.push({
            '@type': 'ListItem',
            position: schemaJson.length + 1,
            item: {
              '@type': 'WebPage',
              '@id': getMultiLanguageUrl(isLanguageSegmentFlow,language,url),
              name: schemaJsonData.childPageInfo?.title || ''
            }
          }
        );
    }

    return schemaJson;
}

export const META_TAGS_TO_MERGE = ['robots','viewport']; //add the name of the meta tags to merge into one single meta tag with the updated content.
