import { getGoalBySlug } from '@angular-commons/core/application-state/app.actions';
import { selectGoalMap } from '@angular-commons/core/application-state/app.selectors';
import { PlatformService } from '@angular-commons/core/services/platform-service';
import { TransferStateManager } from '@angular-commons/core/services/transferStateManager';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'logged-out-purchase-stage-one',
  templateUrl: './logged-out-purchase-stage-one.component.html',
  styleUrls: ['./logged-out-purchase-stage-one.component.scss']
})
export class LoggedOutPurchaseStageOneComponent implements OnInit {

  @Input() passType: any ;
  @Input() goalId: any ;
  constructor( ) { }


  ngOnInit(): void {
  }

  
}
