import { Injectable } from '@angular/core';
import { PlatformService } from './platform-service';
import { HttpClient} from '@angular/common/http';
import { getDeferredPromise } from '@angular-commons/shared/utility/defer-promise';
import { isServer } from '@angular-commons/shared/utility/tb-common';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { selectStudent } from '../application-state/app.selectors';
import { getStudentMe } from '../application-state/app.actions';

@Injectable({
    providedIn: 'root',
})
export class StudentService {
    student$: Observable<any>;
    student: any;
    hasStudentDataRequested = false;
    sid:string = '-1';
    studentSubscriber$:any;
    studentPromise = getDeferredPromise();
    
    constructor(private platformService:PlatformService,private store: Store<{ app: boolean }>) { 
        this.student$ = store.pipe(select(selectStudent));
    }

    loadStudent(skipRefetch = false){
        if(isServer() || !this.platformService.isLoggedIn()) {
            this.studentPromise.reject();
            return this.studentPromise.promise;
        }
        if(!this.hasStudentDataRequested && (!skipRefetch || this.sid === '-1')){
            this.store.dispatch(getStudentMe());
            this.hasStudentDataRequested = true;
            this.studentSubscriber$ = this.student$.subscribe( student => {
                if(this.sid !== student.id){
                    this.sid = student.id;
                    this.student = student;
                    this.hasStudentDataRequested = false;
                    /*if(this.student && this.student.goalPlanState){
                        //set student's purchased goals
                        this.platformService.setCookie(TB_GOALS_PURCHASED,goalPlan.allPlansSerialiser(this.student.goalPlanState),1);
                    }*/
                    this.studentPromise.resolve(student);
                    return this.studentPromise.promise;
                }
            })
        }
        return this.studentPromise.promise;
    }


    


}
