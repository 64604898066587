import { createAction, props } from "@ngrx/store";

export const getVendorById = createAction('[Book Vendor Payment Page] Get Vendor Details', props<{vId: string, dId: string}>());
export const vendorDetailsLoaded = createAction('[Book Vendor Payment Page] Vendor Details Loaded', props<{payload: any}>());

export const getStudentStatus = createAction('[Book Vendor Payment Page] Get Student Status', props<{mobile:string, event:string}>());
export const studentStatusLoaded = createAction('[Book Vendor Payment Page] Student Status Loaded', props<{payload: any}>());

export const toggleSuccessScreen = createAction('[Book Vendor Payment Page] Toggle Success Screen', props<{show: boolean}>());

export const showErrorState = createAction('[Book Vendor Payment Page] Show Error State', props<{showError: boolean}>());
