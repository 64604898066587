import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    ActivatedRoute,
    UrlTree
} from '@angular/router';
import {Observable, of} from "rxjs";
import {Store} from "@ngrx/store";
import {map} from "rxjs/operators";
import {isServer} from "@shared/utility/tb-common";
import {getScholarshipDetail} from "../route-modules/scholarship/scholarship-components/application-state/ScholarshipActions";
import {selectScholarshipDetail} from "../route-modules/scholarship/scholarship-components/application-state/ScholarshipSelector";
import { skipInitialState } from '@angular-commons/shared/utility/ngrx-utils';
import {PlatformService} from "@core/services/platform-service";


@Injectable()
export class ScholarshipGuard implements CanActivate {

    constructor(private router:Router,
                private route: ActivatedRoute,
                private store: Store<{}>,
                private platformService:PlatformService) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean|UrlTree> {

        let idOrSlug = route.params["slug"];

        if(idOrSlug) {
            this.store.dispatch(getScholarshipDetail({slug:idOrSlug}));

            // had to skip 1 to skip first store default select response.
            return this.store.select(selectScholarshipDetail).pipe(skipInitialState(), map((item: any) => {
                if (!(item.test && item.test.id)) {
                    this.platformService.gotoErrorPage();
                    return false;
                }

                return true;
            }));
        }
        return of(true);
    }

}
