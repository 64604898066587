import {arrayModel, objModel, pipeModel, propertyMaps, SourceProjectionMapper} from "@models/mapping-framework";
import {Network} from "@core/services/model-manager/main";
import {baseModel, setupModel} from "@models/types";
import {Validator as _} from "@shared/utility/validator";
import {of} from "rxjs";

export class MenuModel extends baseModel {
    @propertyMaps('_id',  _.str)
    public id;
    @propertyMaps('properties',_.obj)
    public properties;
}
setupModel(MenuModel, {});

export class SeoMenuModelDetails extends baseModel {
    @propertyMaps('_id',  _.str)
    public id;
    @propertyMaps('name',_.str)
    public name;
}
setupModel(SeoMenuModelDetails, {});

export class SeoMenuWrapper extends baseModel {
    @propertyMaps('top',  objModel(SeoMenuModelDetails),SeoMenuModelDetails)
    public top;
    @propertyMaps('side',objModel(SeoMenuModelDetails),SeoMenuModelDetails)
    public side;
}
setupModel(SeoMenuWrapper, {});

export class SeoMenuModelWrapper extends baseModel {
    @propertyMaps('menu', objModel(MenuModel),MenuModel)
    public menu;

}
setupModel(SeoMenuModelWrapper, {});


export class GetSeoMenuApi {
    static apiEndpoint = 'v1/menus/{menuId}';
    static _apiEndpoint = 'v1/menus/';

    static projection;
    static get  __projection(){ 
        if(!GetSeoMenuApi.projection){
            GetSeoMenuApi.projection = JSON.stringify(new SourceProjectionMapper(SeoMenuModelWrapper).map());
        }
        return GetSeoMenuApi.projection;
    }

    static apiCall(network: Network, params: any){
        return network.get(GetSeoMenuApi._apiEndpoint + params.id, {language: params.language, __projection: GetSeoMenuApi.__projection});
    }
}
