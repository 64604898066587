import {isServer} from "@shared/utility/tb-common";

declare var window: any;


export class elementWatcher{
    startTime;
    selector;
    maxWait;
    pollingRate;

    private _scb;
    private _fcb;
    private _timer;
    private _alreadyFound;
    private _alreadyFailed;

    private getElements(selector){
        return document.querySelectorAll(selector);
    }
    private _poll(){
        let element = this.getElements(this.selector);
        if(element){
            this._found(element);
        } else {
            this._notFoundYet();
        }
    }

    private _notFoundYet(){
        if(this.startTime.getTime() + this.maxWait < new Date().getTime()){
            clearInterval(this._timer);
            this._alreadyFailed = true;
            if(this._fcb){
                this._fcb();
            }
        }
    }

    private _found(element){
        clearInterval(this._timer);
        this._alreadyFound = element;
        if(this._scb){
            this._scb(this._alreadyFound)
        }
    }

    private _start(){
        this.startTime = new Date();
        this._timer = setInterval(() => this._poll() ,this.pollingRate)
    }

    found(cb){
        if(isServer()) return this;
        if(!this._timer) this._start();
        if(this._scb) throw "elementWatcher cannot chain found";

        this._scb = cb;
        if(this._alreadyFound){
            this._scb(this._alreadyFound);
        }
        return this;
    }

    notFound(cb){
        if(!this._timer) this._start();
        if(this._fcb) throw "elementWatcher cannot chain notFound";

        this._fcb = cb;
        if(this._alreadyFailed){
            this._fcb();
        }
        return this;
    }

    constructor(selector,pollingRate = 100,maxWait = 5000){
        this.selector = selector;
        this.maxWait = maxWait;
        this.pollingRate = pollingRate;
    }
}

export function scrollToSpecificElement(elem, offset= 0) {
    let windowScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (windowScrollTop <= 100){
      window.scroll(0, 100) ;
    }
    setTimeout(() => {
        let topOffset = offset || 0;
        let scrollTop = (getElementBySelector(elem)?.getBoundingClientRect()?.top + window.scrollY) - topOffset;
        if (scrollTop < 300) {
            scrollTop = 0;
          }
        window.scrollTo({top: scrollTop, behavior: 'smooth'});
    }, 0);
}

export function scrollParentToChild(parentElem, childElem, offset = 0, duration = 300) {
    let scrollY = 0,
    oldTimestamp = null,
    distance = childElem.offsetTop - childElem.offsetHeight - offset;

    if (parentElem.scrollTop === distance) return;

    function step(newTimestamp) {
        if (oldTimestamp !== null) {
            scrollY += distance * (newTimestamp - oldTimestamp) / duration;
            if (scrollY >= distance) {
                return parentElem.scrollTop = distance;
            }
            parentElem.scrollTop = scrollY;
        }
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
    }
    window.requestAnimationFrame(step);

}

export function processExternalLinks(parentElem,newTab = true, noFollow = true){
    if(!parentElem) { return; }
    Array.from(parentElem.querySelectorAll('a')).forEach((anchor:any) => {
        if(anchor.href && anchor.href.startsWith("http") && !anchor.href.includes("//testbook.com/")){
            if(newTab){
                anchor.setAttribute('target', `_blank`);
            }
            if(noFollow){
                anchor.setAttribute('rel','nofollow');
            }
        }
    })
}

export function processAllLinks(parentElem,newTab = true, noFollow = true){
    if(!parentElem) { return; }
    Array.from(parentElem.querySelectorAll('a')).forEach((anchor:any) => {
        if(anchor.href && anchor.href.startsWith("http")){
            if(newTab){
                anchor.setAttribute('target', `_blank`);
            }
            if(noFollow){
                anchor.setAttribute('rel','nofollow');
            }
        }
    })
}

export function toggleModal(selector, show= true) {
    if (selector) {
        let element = getElementBySelector(selector);
        if (element){
            show ? element.classList.add('modal-open') : element.classList.remove('modal-open');
        }
    }
}

export function toggleElementVisibility(selector, show = true){
    if (selector) {
        let element = getElementBySelector(selector);
        if (element){
            show ? element.style.display = 'block' :  element.style.display = 'none';
        }
    }
}

export function getElementBySelector(selector, index = 0) : HTMLElement {
    if (!isServer()){
        if (index === 0){
            return document.querySelector(selector);
        }
        let nodeList = document.querySelectorAll(selector);
        if (nodeList?.length) {
            return nodeList[index];
        }
    }
}

export function getElementListBySelector(selector) {
    if (!isServer()){
        let nodeList = document.querySelectorAll(selector);
        return nodeList;
    }
}

export function slideUpELem(elem, speed = 0.2, callback?) {
    if (!elem){
        return;
    }
    elem.style.transition = `all ${speed}s ease-in-out`;
    elem.style.height = '0px';
    if (callback && typeof(callback) === 'function'){
        callback();
    }
}