import { Injectable} from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    ActivatedRoute,
    UrlTree
} from '@angular/router';
import {Observable, of} from "rxjs";
import {Store} from "@ngrx/store";
import {PlatformService} from "@core/services/platform-service";

@Injectable()
export class ExploreTestsQuizzesGuard implements CanActivate {

    constructor(private router:Router,
                private route: ActivatedRoute,
                private store: Store<{}>,
                private platformService: PlatformService) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean|UrlTree> {
        let isLogin = this.platformService.isLoggedIn();
        let attemptedPageLoggedIn =  state.url.includes('attempted');
        if(!isLogin && attemptedPageLoggedIn){
            let url=`/login?redirect_url=`+encodeURI(state.url)
            this.platformService.redirect(url,302);
            return;
        }else{
            return of(true)
        }
    }

}
