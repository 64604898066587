import {isServer} from "@shared/utility/tb-common";

export function checkWaffleComponent(componentId,force = false){
    if(!isServer() && (<any>window).checkWaffleComponent){
        (<any>window).checkWaffleComponent(componentId,force);
    }
}

export function unbindWaffleComponents(){
    if(!isServer() && (<any>window).unbindWaffleComponents){
        (<any>window).unbindWaffleComponents();
    }
}

export async function getFingerprintID(){
    if(!isServer() && (<any>window).getFingerprintID()){
       return await (<any>window).getFingerprintID();
    }
    return "";
}