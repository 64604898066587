import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageBubbleComponent } from './image-bubble.component';


  

@NgModule({
  declarations: [ImageBubbleComponent],
  imports: [
      CommonModule
  ],
  exports: [
    ImageBubbleComponent
    
  ]
})
export class ImageBubbleExportModule { }
