import { Validator as _ } from "@shared/utility/validator";
import {baseModel, setupModel} from "@models/types";
import {objModel, pipeModel, propertyMaps} from "@models/mapping-framework";
import {getTimeDifference} from "@shared/utility/date-utilities";
import {getDaysDiff} from "@shared/utility/tb-common";
import {hexToHsl} from "@shared/utility/color-util";

export function processOffersForClass(classObj) {
    let now = Date.now();
    if(now > new Date(classObj.offerStartTime).getTime() && now < new Date(classObj.offerEndTime).getTime()) {
        classObj.isClassInOffer = true;
        classObj.offerTimeLeft = getTimeDifference(now, classObj.offerEndTime);
    }
    return classObj;

}

export function addEnrollmentString(classProperty) {
    let enrollmentDaysLeft = 0;
    if(classProperty && classProperty.classType && classProperty.classType.lastEnrollmentDate) {

        enrollmentDaysLeft = getDaysDiff(classProperty.classType.lastEnrollmentDate, Date.now());
    }
    return enrollmentDaysLeft;
}

export function transformFeatures(item) {
    if(item.features) {
        let tempObj = {};
        let featuresString = '';
        let featureSeparator;

        for(let feature of item.features) {
            tempObj[feature.type] = feature;
        }
        if(tempObj['Live Class']) {
            featureSeparator = (tempObj['Video'] || tempObj['Quiz'] || tempObj['Notes']) ? '. ' : '';
            featuresString += tempObj['Live Class'].count + ' Live classes ' + featureSeparator;
        }

        if(tempObj['Video']) {
            featureSeparator = (tempObj['Quiz'] || tempObj['Notes']) ? '. ' : '';
            featuresString += tempObj['Video'].count + ' Videos ' + featureSeparator;
        }

        if(tempObj['Quiz']) {
            featureSeparator = (tempObj['Notes']) ? '. ' : '';
            featuresString += tempObj['Quiz'].count + ' Quizzes ' + featureSeparator;
        }

        if(tempObj['Notes']) {
            featuresString += tempObj['Notes'].count + ' Notes ';
        }

        if(tempObj['CodingProblem']) {
            featuresString += tempObj['CodingProblem'].count + ' Coding Problem ';
        }

        item.featureString = featuresString;
        item.features = tempObj;
    }
    return item;

}

export function addClassSlug(classObj,key = 'url') {
    if (!key) {
        key = 'url';
    }
    if(!classObj) return classObj;
    let slugs = (classObj.classProperties && classObj.classProperties.slug) || (classObj.slug);
    let isPremium = classObj.isPremium;

    if (slugs && slugs.prefix && slugs.suffix) {
        let prefix = slugs.prefix;
        let suffix = '';

        if (classObj?.isGoalCourse) {
            suffix = `${slugs.suffix}-course`;
        } else {
            suffix = isPremium ? `online-coaching-${slugs.suffix}` : `${slugs.suffix}-online-course`;
        }
        classObj[key] = `/${prefix}/${suffix}`;

    } else {
        let id = classObj.id;
        let title = classObj.titles || 'course';
        let slug = title.toLowerCase().replace(/\W+/g,"-").replace(/-$/,'');
        let courses = isPremium ? 'select' : 'courses';
        classObj[key] = `/${courses}/${id}/${slug}`;
    }
    return classObj;
}

export function addSlugInClassArr(classArr) {
    return classArr.map((classObj) => addClassSlug(classObj, 'url'));
}

export function filterClassTill(classArr) {
    let now = Date.now();
    return classArr.filter(item => {
        if(item && item.classProperties && item.classProperties.classType && item.classProperties.classType.classTill) {
            return new Date(item.classProperties.classType.classTill).getTime() > now;
        }
        return false;
    });
}

export function addHSLFromColor(classProperty) {
    if(classProperty) {
        classProperty.color = classProperty.color || '#4568f2';

        let hsl = hexToHsl(classProperty.color);
        classProperty.hue = hsl[0];
        classProperty.saturation = hsl[1];
        classProperty.lightness = hsl[2];
    }
    return classProperty;
}

export class _ClassFeatures extends baseModel {
    @propertyMaps('features')
    public features
}
setupModel(_ClassFeatures, {type: 'classes'});

export class _ClassInfo extends baseModel {
    @propertyMaps('classFeature', pipeModel(objModel(_ClassFeatures), transformFeatures), _ClassFeatures)
    public classFeature;

    @propertyMaps()
    public facultiesImage;
}
setupModel(_ClassInfo, {type: 'classes'});

//frontend model for courses
export class _CourseModel extends baseModel{
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps()
    public titles;

    @propertyMaps('specificExams')
    public specificExams;

    @propertyMaps('courses')
    public courses;

    @propertyMaps('shortDesc', _.str)
    public shortDesc;

    @propertyMaps('coachingName', _.str)
    public coachingName;

    @propertyMaps()
    public availTill;

    @propertyMaps('classInfo', objModel(_ClassInfo), _ClassInfo)
    public classInfo;

    @propertyMaps('classProperties', addHSLFromColor)
    public classProperties;

    @propertyMaps('classProperties', addEnrollmentString)
    public enrollmentDaysLeft;

    @propertyMaps()
    public cost;

    @propertyMaps('oldCost', _.num)
    public oldCost;

    @propertyMaps('weight', _.num)
    public weight;

    @propertyMaps('numPurchased', _.num)
    public numPurchased;

    @propertyMaps('slugUrl', _.str)
    public slugUrl;

    @propertyMaps()
    public courseLogo;

    @propertyMaps()
    public offerStart;

    @propertyMaps()
    public offerEnd;

    @propertyMaps()
    public descriptions;

    @propertyMaps()
    public summary;

    @propertyMaps('isPremium', _.bool)
    public isPremium;

    public url;

    public gradient;

    public isLive;
}
setupModel(_CourseModel,{type: 'classes'});

export class CourseModel extends baseModel{
    @propertyMaps()
    public id;

    @propertyMaps()
    public availTill;

    @propertyMaps()
    public classInfo;

    @propertyMaps()
    public classProperties;

    @propertyMaps()
    public cost;

    @propertyMaps()
    public courseLogo;

    @propertyMaps()
    public descriptions;

    @propertyMaps()
    public summary;

    @propertyMaps('isPremium', _.bool)
    public isPremium;

    @propertyMaps('titles', _.str)
    public title;

    @propertyMaps('enrollmentDaysLeft')
    public enrollmentDaysLeft;

    public url;

    public isLive;
}
setupModel(CourseModel, {});

