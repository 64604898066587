import {Inject, Injectable, Optional} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Store} from "@ngrx/store";
import {PlatformService} from "@core/services/platform-service";
import {AuthenticationService} from "../../../../angular-commons/core/services/authentication.service";
import {getGoalPageUrl, getGoalPlansPageUrl} from "@shared/utility/goal-utility";
import {getQueryStringFromParams} from "@shared/utility/url";
import {ProductAccessService} from "@core/services/product-access.service";


@Injectable()
export class GoalSelectionGuard implements CanActivate {

    constructor(private router:Router,
                private store: Store<{}>,
                private platformService: PlatformService,
                private auth: AuthenticationService,
                private productAccess: ProductAccessService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean|UrlTree {
        if(!this.platformService.isLoggedIn()){
            return true;
        }
        let goal = this.auth.getGoal();
        let skipRedirect = this.platformService.getQueryParamValue("stay") === "1";
        let redirectTo = this.platformService.getQueryParamValue("redirectTo");
        if(!skipRedirect) {
            if (redirectTo === 'goalPlans') {
                if (goal && goal.slug) {
                    let url = getGoalPlansPageUrl(goal.slug);
                    let qp:any = {...route.queryParams, redirectTo: 'false'};
                    if(url === '/super-coaching'){
                        qp = {...qp, stay:1};
                    }
                    let redirectUrl = url + getQueryStringFromParams(qp);
                    this.platformService.redirect(redirectUrl);
                } else {
                    this.productAccess.getPlansPageUrlFromCurrentGoal().then(url => {
                        if (url) {
                            let qp:any = {...route.queryParams, redirectTo: 'false'};
                            if(url === '/super-coaching'){
                                qp = {...qp, stay:1};
                            }
                            let redirectUrl = url + getQueryStringFromParams(qp);
                            this.platformService.redirect(redirectUrl);
                        }
                    });
                    return false;
                }
            } else if (goal && goal.slug) {
                this.productAccess.getGoalPageOrDashboardUrl(goal.id, goal.slug).then(url => {
                    if (url) {
                        let redirectUrl = url + getQueryStringFromParams(route.queryParams);
                        this.platformService.redirect(redirectUrl);
                    }
                });
                return false;
            }
        }
        return true;
    }

}
