import { baseModel ,setupModel } from "../../types";
import {propertyMaps, arrayModel, SourceProjectionMapper, objModel, pipeModel} from "../../mapping-framework";
import {Validator as _} from "../../../shared/utility/validator";
import {Network} from "../../../core/services/model-manager/main";
import {getDateByFormat,createTimeText, getExactDaysDiff, shortMonths, isToday} from '@angular-commons/shared/utility/date-utilities';
import { padZero } from '@angular-commons/shared/utility/math-utilities';
import {getTimeTextWithDay,getDurationFromMilliSeconds} from '@angular-commons/shared/utility/date-utilities'
import { transformFeatures, _ClassFeatures } from "../classes/class.model";
import { sanitizeImageUrl } from "@angular-commons/shared/utility/url";
import { getCourseURL } from "@angular-commons/shared/utility/course-utils";
import { getMasterClassSlug } from "@angular-commons/shared/utility/master-class-utility";
import { SeoMenuWrapper } from "../page-seo/seo-menu.adapter";

export function transformMarketingDetails(item:any){
    item.slug=item && item.slug 
    item.pitchId=item.pitchCids && item.pitchCids[0];
    return item;
}

export function transformSeriesProperties(item:any){
    item.startDateText=getDateByFormat(item.startTime,'long','long');
    item.startTimeText=createTimeText(new Date(item.startTime),true);
    item.schedule.fromTimeText=createTimeText(new Date(item.schedule.from),true);
    if(item.target){
        item.targets=item.target;
    }
    item.target=item.target && item.target[0] && item.target[0].title;
   

    let targetSuperGroupId=item.targetSuperGroup && item.targetSuperGroup[0] && item.targetSuperGroup[0]._id;

    item.explorePageLink= (targetSuperGroupId) ? `/free-live-classes/${getMasterClassSlug(targetSuperGroupId)}` : `/free-live-classes`
    return item;
}


export interface accessBody{
    enroll:boolean,
    isAutoEnroll:boolean,
    unroll:boolean
}



export class MasterSeriesMarketing extends baseModel{
    @propertyMaps('slug')
    public slug;

    @propertyMaps('meta')
    public meta;

    @propertyMaps('thumb')
    public thumbnail;

    @propertyMaps('pitchCids')
    public pitchCids;

    public pitchId
}
setupModel(MasterSeriesMarketing, {});




export class MasterSeriesProperties extends baseModel{
    @propertyMaps('title')
    public title

    @propertyMaps('description')
    public description

    @propertyMaps('lang')
    public language

    @propertyMaps('startTime')
    public startTime

    @propertyMaps('endTime')
    public endTime

    @propertyMaps('seoDescription')
    public seoDescription

    @propertyMaps('startDateText')
    public startDateText;

    @propertyMaps('startTimeText')
    public startTimeText;

    @propertyMaps('target')
    public target;

    @propertyMaps('menus', objModel(SeoMenuWrapper), SeoMenuWrapper)
    public menus;

    @propertyMaps('targetSuperGroup')
    public targetSuperGroup

    @propertyMaps('servesFrom')
    public servesFrom

    @propertyMaps('schedule')
    public schedule
}
setupModel(MasterSeriesProperties, {});

export class MasterSeriesDetails extends baseModel{
    @propertyMaps('_id')
    public id

    @propertyMaps('properties',pipeModel(objModel(MasterSeriesProperties),transformSeriesProperties),MasterSeriesProperties)
    public properties

    @propertyMaps('modules')
    public modules;

    @propertyMaps('marketingDetails', pipeModel(objModel(MasterSeriesMarketing),transformMarketingDetails),MasterSeriesMarketing)
    public marketingDetails

    @propertyMaps('isEnrolled')
    public isEnrolled;
}
setupModel(MasterSeriesDetails,{})



export class MasterSeries extends baseModel{
    @propertyMaps('masterclassSeries',objModel(MasterSeriesDetails))
    public masterclassSeries;
}
setupModel(MasterSeries, {});




export class getMasterSeriesApi {
    static apiEndpoint = 'v1/mclass-series/{masterSeriesId}';

    static projection;
    static get  __projection(){ 
        if(!getMasterSeriesApi.projection){
            getMasterSeriesApi.projection = JSON.stringify(new SourceProjectionMapper(MasterSeries).map());
        }
        return getMasterSeriesApi.projection;
    }
    
    static apiCall(network: Network, params) {
        return network.get(getMasterSeriesApi.apiEndpoint.replace('{masterSeriesId}',params.id), {__projection:getMasterSeriesApi.__projection});
    }
}





export function transormSeriesLessonsProperties(item){
    const currentTime:any = Date.now();
    let it:any={};
    it.title=item.name;
    it.startTime=item.startTime;
    it.endTime=item.endTime;
    it.isDemo = item.isDemo;
    it.instructorName=item.instructors && item.instructors[0] && item.instructors[0].name;
    it.instructorId = item.instructors && item.instructors[0] && item.instructors[0].id;
    it.isLiveNow=(new Date(it.startTime).getTime() <= currentTime) && (currentTime <= new Date(it.endTime).getTime());
    it.isUpcoming=(new Date(it.startTime).getTime() > currentTime) 
    it.isPast=(currentTime > new Date(it.endTime).getTime());
    if(it.isUpcoming || it.isLiveNow){
        if(isToday(new Date(it.startTime))){
            it.timeText=`Live Today at ${createTimeText(new Date(item.startTime))}`;
        }
        else{
            it.timeText=`Live on ${getTimeTextWithDay(new Date(item.startTime))}`;
        }    
    }
    else if(it.isPast){
        it.timeText=`Was Live at ${createTimeText(new Date(item.startTime))}`
    }
    it.startDate = padZero(new Date(it.startTime).getDate());;
    it.startMonth = shortMonths[new Date(it.startTime).getMonth()];
    let timeLeft=(new Date(item.endTime).getTime())-(new Date(item.startTime).getTime())
    let durationObj=getDurationFromMilliSeconds(timeLeft);
    it.durationString = `${padZero(durationObj.m)} mins`;
    if(item.globalConcepts && item.globalConcepts.length){
        it.subject = item.globalConcepts[0].subject?.title || '';
    }


    return it;
}

export class Subject extends baseModel{
    @propertyMaps('id', _.str)
    public id;

    @propertyMaps('title', _.str)
    public title;
}
setupModel(Subject,{});

export class GlobalConcepts extends baseModel{
    @propertyMaps('s', objModel(Subject), Subject)
    public subject;
}

setupModel(GlobalConcepts,{});


export class LessonsProperties extends baseModel{
    @propertyMaps('name')
    public name;

    @propertyMaps('startTime')
    public startTime;

    @propertyMaps('endTime')
    public endTime;

    @propertyMaps('instructorName')
    public instructorName;

    @propertyMaps('instructorId')
    public instructorId;

    @propertyMaps('isLiveNow')
    public isLiveNow;

    @propertyMaps('isUpcoming')
    public isUpcoming;

    @propertyMaps('isPast')
    public isPast;

    @propertyMaps('timeText')
    public timeText;

    @propertyMaps('instructors')
    public instructors;

    @propertyMaps('durationString')
    public durationString;

    @propertyMaps('startDate')
    public startDate;

    @propertyMaps('startMonth')
    public startMonth;

    @propertyMaps('title')
    public title;

    @propertyMaps('globalConcepts', arrayModel(GlobalConcepts), GlobalConcepts)
    public globalConcepts;

    @propertyMaps('isDemo')
    public isDemo;

}
setupModel(LessonsProperties,{})


export class instructorsDetails extends baseModel{
    @propertyMaps('_id',_.str)
    public id;

    @propertyMaps('name',_.str)
    public name;

    @propertyMaps('image',_.str)
    public image;

}
setupModel(instructorsDetails, {});
export class _classId extends baseModel{
    @propertyMaps('id',_.str)
    public id;
    @propertyMaps('access',_.str)
    public access;
}
setupModel(_classId, {});
export class _classIds extends baseModel{
    @propertyMaps('classIds',arrayModel(_classId),_classId)
    public classIds;
}
setupModel(_classIds, {});
export class LessonsDetails extends baseModel{
    @propertyMaps('_id')
    public id;

    @propertyMaps('accessObject',objModel(_classIds),_classIds)
    public accessObject;
    
    @propertyMaps('properties',pipeModel(objModel(LessonsProperties),transormSeriesLessonsProperties),LessonsProperties)
    public properties;

    @propertyMaps('reminderFlag')
    public remindMe;

    @propertyMaps('modules')
    public modules;

    @propertyMaps('instructors',arrayModel(instructorsDetails),instructorsDetails)
    public instructors;
    
    @propertyMaps('mcSeriesId')
    public masterSeriesId;

}
setupModel(LessonsDetails, {});



export class Lessons extends baseModel{
    @propertyMaps('lessons',arrayModel(LessonsDetails),LessonsDetails)
    public lessons;
}
setupModel(Lessons, {});

export class Lesson extends baseModel{
    @propertyMaps('lesson',objModel(LessonsDetails))
    public lesson;
}
setupModel(Lesson, {});


export class getMasterSeriesLessonsApi {
    static apiEndpoint = 'v1/mclass-series/{slugOrId}/lessons';

    static projection;
    static get  __projection(){ 
        if(!getMasterSeriesLessonsApi.projection){
            getMasterSeriesLessonsApi.projection = JSON.stringify(new SourceProjectionMapper(Lessons).map());
        }
        return getMasterSeriesLessonsApi.projection;
    }

    static apiCall(network: Network, params) {
        return network.get(getMasterSeriesLessonsApi.apiEndpoint.replace('{slugOrId}',params.id), 
        {
            __projection:getMasterSeriesLessonsApi.__projection,
            lessonType: params.lessonType,
            skip:params.skip,
            limit:params.limit,
        }
        );
    }
}

export class MasterSeriesInstructor extends baseModel {
    @propertyMaps('id', _.str)
    public id;

    @propertyMaps('name', _.str)
    public name;

    @propertyMaps('shortBio', _.str)
    public shortBio;

    @propertyMaps('fullBio', _.str)
    public longBio;

    @propertyMaps('photo', _.str)
    public photo;

    @propertyMaps('image', _.str)
    public image;
}
setupModel(MasterSeriesInstructor,{})
;
export class MasterSeriesInstructors extends baseModel{

    @propertyMaps('instructors',arrayModel(MasterSeriesInstructor),MasterSeriesInstructor)
    public instructors;
}
setupModel(MasterSeriesInstructors,{});

export class MasterSeriesInstructorsApi{
    static apiEndPoint = 'v1/mclass-series/{mseriesId}/instructors';

    static projection;
    static get  __projection(){ 
        if(!MasterSeriesInstructorsApi.projection){
            MasterSeriesInstructorsApi.projection = JSON.stringify(new SourceProjectionMapper(MasterSeriesInstructors).map());
        }
        return MasterSeriesInstructorsApi.projection;
    }

    static apiCall(network:Network,params){
        return network.get(MasterSeriesInstructorsApi.apiEndPoint.replace('{mseriesId}',params.id),{__projection:MasterSeriesInstructorsApi.__projection});
    }
}


export class MasterSeriesCourseInfo extends baseModel{
    @propertyMaps('courseSellingImage',_.str)
    public courseSellingImage;

    @propertyMaps('facultiesImage',_.str)
    public facultiesImage;

    @propertyMaps('classFeature', pipeModel(objModel(_ClassFeatures), transformFeatures), _ClassFeatures)
    public classFeature;
    
}
setupModel(MasterSeriesCourseInfo,{});

export class MasterSeriesClassType extends baseModel{
    @propertyMaps('type', _.str)
    public type;

    @propertyMaps('classFrom', _.date)
    public classFrom;

    @propertyMaps('classTill', _.date)
    public classTill;

    @propertyMaps('lastEnrollmentDate', _.date)
    public lastEnrollmentDate;
}
setupModel(MasterSeriesClassType,{});

export class MasterSeriesClassSlug extends baseModel {
    @propertyMaps('prefix', _.str)
    public prefix;

    @propertyMaps('suffix', _.str)
    public suffix;
}
setupModel(MasterSeriesClassSlug,{});


export class MasterSeriesCourseProperties extends baseModel{
    @propertyMaps('deeplink',_.str)
    public deeplink;

    @propertyMaps('classType',objModel(MasterSeriesClassType),MasterSeriesClassType)
    public classType;

    @propertyMaps('slug',objModel(MasterSeriesClassSlug),MasterSeriesClassSlug)
    public slug;
}
setupModel(MasterSeriesCourseProperties,{});


export class MasterSeriesCourseProduct extends baseModel{
    @propertyMaps('_id',_.str)
    public id;

    @propertyMaps('title',_.str)
    public title;

    @propertyMaps('courseLogo',_.str)
    public courseLogo;

    @propertyMaps('isPremium',_.bool)
    public isPremium;

    @propertyMaps('classProperties',objModel(MasterSeriesCourseProperties),MasterSeriesCourseProperties)
    public classProperties;

    @propertyMaps('classInfo',objModel(MasterSeriesCourseInfo),MasterSeriesCourseInfo)
    public classInfo;

    @propertyMaps('specificExams')
    public specificExams;

    @propertyMaps('quantity',_.num)
    public quantity;

    @propertyMaps('numPurchased',_.num)
    public numPurchased;

    @propertyMaps()
    public url;

    @propertyMaps()
    public classFrom;

    @propertyMaps()
    public classTill;

    @propertyMaps()
    public lastEnrollmentDate;

    @propertyMaps()
    public dateToShow;

    @propertyMaps()
    public monthToShow;

    @propertyMaps()
    public timeToShow;

    @propertyMaps()
    public isEnrollmentOver;

    @propertyMaps()
    public daysToEnroll;

    @propertyMaps()
    public startsOnString;

    @propertyMaps()
    public startedOnString;

    @propertyMaps()
    public examName;

}
setupModel(MasterSeriesCourseProduct,{});

export class MasterSeriesCourse extends baseModel{
    @propertyMaps('product',pipeModel(objModel(MasterSeriesCourseProduct),processMasterSeriesProduct),MasterSeriesCourseProduct)
    public product;
}
setupModel(MasterSeriesCourse,{});

export class getMasterSeriesPitchApi{
    static apiEndpoint = 'v1/products/{pitchId}';

    static projection;
    static get  __projection(){ 
        if(!getMasterSeriesPitchApi.projection){
            getMasterSeriesPitchApi.projection = JSON.stringify(new SourceProjectionMapper(MasterSeriesCourse).map());
        }
        return getMasterSeriesPitchApi.projection;
    }

    static apiCall(network:Network, params) {
        return network.get(getMasterSeriesPitchApi.apiEndpoint.replace('{pitchId}',params.id) ,{__projection:getMasterSeriesPitchApi.__projection});
    }
}

function processMasterSeriesProduct(course){
    const currTime = new Date();
    let newCourse = course;
    let urlObj= getCourseURL(course); 
    newCourse.url = urlObj.url
    newCourse.classFrom = (new Date(course.classProperties.classType.classFrom));
    newCourse.classTill = (new Date(course.classProperties.classType.classTill));
    newCourse.lastEnrollmentDate = (new Date(course.classProperties.classType.lastEnrollmentDate));
    newCourse.daysToEnroll  = getExactDaysDiff(newCourse.lastEnrollmentDate, currTime);
    newCourse.isEnrollmentOver = (newCourse.daysToEnroll <= 0) || (course.quantity == -1) || (course.quantity <= course.numPurchased)
    newCourse.startsOnPrefix = newCourse.classFrom < currTime ? 'Started on' : 'Starts on';
    newCourse.startsOnString = getDateByFormat(newCourse.classFrom, 'short', 'long');
    newCourse.dateToShow = newCourse.classFrom.getDate();
    newCourse.monthToShow = shortMonths[newCourse.classFrom.getMonth()];
    newCourse.timeToShow = createTimeText(newCourse.classFrom)
    if (newCourse.isEnrollmentOver) {
        newCourse.startsOnPrefix = 'Batch closes on';
        newCourse.startsOnString = getDateByFormat(newCourse.lastEnrollmentDate, 'short', 'long')
        newCourse.dateToShow = newCourse.lastEnrollmentDate.getDate();
        newCourse.monthToShow = shortMonths[newCourse.lastEnrollmentDate.getMonth()];
        newCourse.timeToShow = createTimeText(newCourse.lastEnrollmentDate)
    }
    if(course.specificExams && course.specificExams.length>0){
        newCourse.examName = course.specificExams[0].name;
    }
    newCourse.courseLogo = course.courseLogo && sanitizeImageUrl(course.courseLogo);
    return newCourse;
}





export class MasterSeriesEnrollment extends baseModel{
    @propertyMaps('isEnrolled')
    public isEnrolled

    @propertyMaps('isAutoEnrolled')
    public isAutoEnrolled
}
setupModel(MasterSeriesEnrollment,{})

export class getMasterSeriesEnrollmentApi{
    static apiEndpoint = 'v1/mclass-series/{seriesId}/enrollment';

    static projection;
    static get  __projection(){ 
        if(!getMasterSeriesEnrollmentApi.projection){
            getMasterSeriesEnrollmentApi.projection = JSON.stringify(new SourceProjectionMapper(MasterSeriesEnrollment).map());
        }
        return getMasterSeriesEnrollmentApi.projection;
    }

    static apiCall(network:Network, params) {
        return network.get(getMasterSeriesEnrollmentApi.apiEndpoint.replace('{seriesId}',params.id) ,{__projection:getMasterSeriesEnrollmentApi.__projection});
    }
}

export class setMasterSeriesAccessApi{
    static apiEndpoint = 'v1/mclass-series/{seriesId}/activity';

    static  __projection = 1

    static apiCall(network:Network, params) {
        return network.post(setMasterSeriesAccessApi.apiEndpoint.replace('{seriesId}',params.id) ,params.body,{__projection:setMasterSeriesAccessApi.__projection});
    }
}
export class masterLessonsReminderApi{
    static apiEndPoint = "v1/reminders";

    static apiCall(network:Network,params){
        return network.post(masterLessonsReminderApi.apiEndPoint,{childId:params.lessonId,childType:'Lesson',parentType:'MasterclassSeries',parentId:params.masterSeriesId,reminderFlag:params.reminderStatus});
    }
}
