import {Component, Input, OnInit} from "@angular/core";
import {TbcoreService} from "@core/services/tbcore.service";
import {ProductAccessService} from "@core/services/product-access.service";


@Component({
    selector: 'pass-user-announcement',
    templateUrl: './pass-user-announcement.component.html',
    styleUrls: ['./pass-user-announcement.component.scss']
})
export class PassUserAnnouncementComponent implements OnInit{

    @Input() showComparisonBenefits = false;
    hasPassAccess;
    hasProAccess;
    constructor(private tbCore: TbcoreService, private productAccessService: ProductAccessService) {
    }
    ngOnInit() {
        this.checkForValidPassUser();
    }
    checkForValidPassUser(){
        this.productAccessService.passAndPassProActiveState().then(access => {
            if(access?.passAccess){
                this.hasPassAccess = access.passAccess;
            }
            if(access?.passProAccess){
                this.hasProAccess = access.passProAccess;
            }
        });
    }
}
