import {isServer} from "@shared/utility/tb-common";
import {APIConstants} from "@angular-commons/environments/environment";

export function getGoalSelectionPageUrl() {
  return `/super-coaching`;
}
export function getGoalPageUrl(goalSlug) {
  return `/${goalSlug}-coaching`;
}
export function getEducatorsPageUrl() {
  return `/super-teachers`;
}
export function getAllEducatorsPageUrl(goalSlug) {
  return `/${goalSlug}-coaching/super-teachers`;
}
export function getIndividualEducatorPageUrl(teacherSlug) {
  return `/${teacherSlug}-super-teacher`;
}

export function getGoalPlansPageUrl(goalSlug) {
  return `/super-coaching/${goalSlug}/plans`;
}
export function getGoalDashboardPageUrl(goalSlug) {
  return `/super-coaching/${goalSlug}/dashboard`;
}
export function getGoalPracticePageUrl(goalSlug) {
  return `/super-coaching/${goalSlug}/practice`;
}
export function getGoalTestsPageUrl(goalSlug) {
  return `/super-coaching/${goalSlug}/tests`;
}
export function getGoalCoursesPageUrl(goalSlug) {
  return `/super-coaching/${goalSlug}/courses`;
}
export function getGoalNotesPageUrl(goalSlug) {
  return `/super-coaching/${goalSlug}/notes`;
}

export function goalImagePopupUrl(goalSlug, isMobile) {
  if (!isMobile){
    return `https://cdn.testbook.com/qb_resources/${goalSlug ? goalSlug + '_web' : 'common_web'}.jpg`;
  } else {
    return `https://cdn.testbook.com/qb_resources/${goalSlug ? goalSlug + '_mweb' : 'common_mweb'}.jpg`;
  }
}

export function goalQuickBuyImageUrl(goalSlug, isMobile) {
  if (!isMobile){
    return `https://cdn.testbook.com/qb_resources/quickbuy/${goalSlug ? goalSlug + '_web' : 'common_web'}.jpg`;
  } else {
    return `https://cdn.testbook.com/qb_resources/quickbuy/${goalSlug ? goalSlug + '_mweb' : 'common_mweb'}.jpg`;
  }
}

export const goalCouponCodeMap = {
  "62bc01f57e5b79b8b3fa2859": "RAILWAYS30",
  "62b2ca20300a2f23b5885605": "BIHAR30",
  "62b076cfabac16bece581bd5": "BANKEXAMS30",
  "628371bfaf01a9ffa02374a2": "SSCEXAMS30",
  "62d6aacd540913fc16b4e56a": "CTET30",
  "62973a64d586df79efc748a2": "BPSC25",
  "624ff334ea0f9057d3f11a91": "UPSC20",
  "62bbfeb54a6ab4958508174e": "DEFENCE25",
  "62e621487cbf34075dc7e9c2": "UPPSC30",
  "62f647943235b45ce930adb4": "PET50",
  "62f779023ee4efade1bad95e": "CGL50",
  "62f77213cbf7d4e21f757339": "POL50",
  "62b2cce2f4404c0c536ae861": "CUETEXAMS25",
  "629f3571e1ba553aa3b91bff": "AEJEME25",
  "629bb250c7b88903140ab781": "AEJEEE25",
  "629f359f7c4c3d752a464f86": "AEJECE25",
  "62b20f77b5e26373ba4e697d": "GATEME20",
  "62baf912a71729a10a088d6a": "GATECE20",
  "62bc38893d1eae0b548eba7a": "GATEEE20",
  "62bca84d2c4f0e9ac6ff873f": "GATECSE20",
  "62c7ea1bb6f96bd5da884d6a": "GATEECE20"
}

export function setGoalPopupImageAttributes(imageElement: string, AnchorElement: string, goalId: string, goalSlug: string, isMobile: boolean){
  if(isServer()){ return; }
  let ele = document.getElementById(imageElement);
  let navigateEle = document.getElementById(AnchorElement);
  let navigateUrl: string = '';
  if (ele && navigateEle){
    ele.setAttribute('src', goalImagePopupUrl(goalSlug, isMobile));
    if (!goalSlug){
      navigateUrl = getGoalSelectionPageUrl();
    } else{
      navigateUrl = getGoalPageUrl(goalSlug);
      navigateUrl += '?productFirst=1';
      if (goalCouponCodeMap[goalId]){
        navigateUrl += `&coupon=${goalCouponCodeMap[goalId]}`;
      }
    }
    navigateEle.setAttribute('href', navigateUrl);
  }
}

export const studyPlanToGoalIdMap = {
  'SSC GD Study Plan': '6363bbee44dffe328f0769ce',
  'UPSC Study Plan': '624ff334ea0f9057d3f11a91',
  'SSC CGL Study Plan': '628371bfaf01a9ffa02374a2',
  'CTET Study Plan': '6315f44f52381006c663a5c4',
  'SSC MTS Study Plan' : '63bd3a1ad81711d97406f4ea',
  'LIC AAO Study Plan' : '63c64a19530540e093177161'
};

export const GameContainerTypeId = 'POP_UP';

export const GameContentTypeId = 'CAMPAIGN';

export const gamificationObject = {
  writeKey: APIConstants.CustomerGluWriteKey,
  userIdentification: {
    userId: '',
    anonymousId: '',
    userToken: ''
  },
  userAttributes: {
    gluAttributes: {},
    customAttributes: {}
  },
  onLoadError: function () {
    console.error('Game SDK not loaded');
  }
};

// Hardcoded for ssc-cgl goal.
export const forceShowGlobalPopup = ['5e6189da5f66e94f14a21f58'];

export const handlefaultyPitchMap = {
  '5e6189da5f66e94f14a21f58': '639428f33155978ab7b8f4a6'
};

export const showPricingTab = ['upsc-cse', 'upsc-cse-nirnay-ias-1']
export const showStickyDownloadBrochure = ['6479e89115e6a9d75c451aba', '64a2c777415e22414a43ba4e'];

export function addLazyLoadingToFrames(htmlString) {
  if(!htmlString || !htmlString.includes('<iframe')){
    return htmlString || '';
  }
  htmlString = htmlString.replace(/<iframe/gi, '<iframe loading="lazy"');
  return htmlString;
}




