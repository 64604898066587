<div class="announcement announcement-pass" *ngIf="!showComparisonBenefits && hasPassAccess && !hasProAccess">
    <img src="/assets/img/pass-new/pro-announce.svg" alt="announce">
    <div>Limited time offer only for Pass Users!</div>
    <div class="h3">Upgrade Now <span class="text-danger">at the best price possible</span></div>
</div>

<!--<h3 *ngIf="hasPassAccess && !hasProAccess" class="pro-offer">Limited time offer only for Pass Users!</h3>-->

<div class="announcement announcement--compare" *ngIf="showComparisonBenefits && hasPassAccess && !hasProAccess">
    <img src="/assets/img/pass-new/pro-announce.svg" alt="announce">
    <div><b>Limited time offer!</b></div>
    <div class="text-danger text-sm">Grab the deal now at the best price possible</div>
</div>
