<!-- isCompletedAlert is false for normal alert -->
<!-- isCompletedAlert is true for live page alert -->
<div class="alert-group" *ngIf="(messages.length>0 && !isCompletedAlert)">
    <div class="alert alert--dark" *ngFor="let message of messages" [ngClass]="{'alert--series': alertType === 'masterSeries'}">
        <div *ngIf="alertType === 'masterSeries'">
            <svg width="56" height="49">
                <use xlink:href="#master-series-tick"></use>
            </svg>
            <strong>{{alertTitle}}</strong>
        </div>
        <span [innerHtml]="message.message | safeHtml"></span>
        <button class="alert__close" (click)="dismissAlert(message.id)"></button>
    </div>
</div>

<div class="alert-completed" *ngFor="let message of messages"[ngClass]="{'alert-success': alertType === 'successAlert'}" [hidden]="!(messages.length>0 && isCompletedAlert)" >
    <div class="alert-completed__check" *ngIf="alertType != 'successAlert'">
        <svg width="19" height="15">
            <use xlink:href="#green-tick"></use>
        </svg>
    </div>
    <div *ngIf="alertType === 'successAlert'" >
        <svg width="56" height="56">
            <use xlink:href="#live-page-tick"></use>
        </svg>
    </div>
    <div class="alert-completed__content">
        <h4>{{alertTitle}}</h4>
        <div>{{message.message}}</div>
    </div>
    <button class="alert__close" (click)="dismissAlert(message.id)"></button>
</div>


<svg style="display: none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <symbol id="master-series-tick" viewBox="0 0 56 49">
        <defs xmlns="http://www.w3.org/2000/svg">
            <filter id="lmplx3o6ta" width="103.2%" height="141.2%" x="-1.6%" y="-20.6%" filterUnits="objectBoundingBox">
                <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2.5"/>
                <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.0440443841 0 0 0 0 0.0440443841 0 0 0 0 0.0440443841 0 0 0 0.5 0"/>
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"/>
                    <feMergeNode in="SourceGraphic"/>
                </feMerge>
            </filter>
            <path id="u0s1bciz8b" d="M0.167 0.318L5.618 0.318 5.618 2.912 0.167 2.912z"/>
        </defs>
        <g xmlns="http://www.w3.org/2000/svg" fill="none" fill-rule="evenodd">
            <g>
                <g filter="url(#lmplx3o6ta)" transform="translate(-356.000000, -104.000000) translate(347.000000, 103.000000)">
                    <g>
                        <g>
                            <path fill="#25BA74" d="M14.37 3.2h1.66c3.16 0 5.26.517 7.075 1.487 1.814.97 3.238 2.394 4.208 4.208.97 1.815 1.487 3.915 1.487 7.075v1.66c0 3.16-.517 5.26-1.487 7.075-.97 1.814-2.394 3.238-4.208 4.208-1.815.97-3.915 1.487-7.075 1.487h-1.66c-3.16 0-5.26-.517-7.075-1.487-1.814-.97-3.238-2.394-4.208-4.208C2.117 22.89 1.6 20.79 1.6 17.63v-1.66c0-3.16.517-5.26 1.487-7.075.97-1.814 2.394-3.238 4.208-4.208C9.11 3.717 11.21 3.2 14.37 3.2z" transform="translate(17.000000, 9.000000)"/>
                            <path fill="#9F4EE3" d="M17.626 28.753c.35.659.438 1.465-.026 3.226.766.08 1.451-.066 2.297-.535.168-1.358.16-2.18-.243-3.444-.616.507-1.093.634-2.028.753" transform="translate(17.000000, 9.000000) translate(18.799998, 29.999998) rotate(-40.000000) translate(-18.799998, -29.999998)"/>
                            <path fill="#DA6267" d="M1.626 9.553c.35.659.438 1.465-.026 3.226.766.08 1.451-.066 2.297-.535.168-1.358.16-2.18-.243-3.444-.616.507-1.093.634-2.028.753" transform="translate(17.000000, 9.000000) translate(2.800000, 10.799999) rotate(-220.000000) translate(-2.800000, -10.799999)"/>
                            <path fill="#F5CB1C" d="M13.644 2.94c.234.525.288 1.038-.044 1.743 2.071.214 3.417.114 5.387-.14.318-1.053.218-1.692.095-2.143-1.99.69-3.36.705-5.438.54" transform="translate(17.000000, 9.000000) translate(16.399999, 3.600000) rotate(50.000000) translate(-16.399999, -3.600000)"/>
                            <g transform="translate(17.000000, 9.000000) translate(33.152001, 29.692193)"/>
                        </g>
                        <path stroke="#FFF" stroke-width="2.71" d="M10.4 16.088L13.291 20 20.8 14.4" transform="translate(17.000000, 9.000000)"/>
                    </g>
                </g>
            </g>
        </g>
    </symbol>
    <symbol id="live-page-tick" viewBox="0 0 56 49">
        <g fill="none" fill-rule="evenodd">
            <g>
                <g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path fill="#25BA74" d="M25.212 6.234h6.23c5.765 0 8.34.696 10.783 2.002 2.443 1.307 4.36 3.224 5.666 5.666 1.307 2.443 2.003 5.02 2.003 10.783v7.194c0 5.764-.696 8.34-2.003 10.783-1.306 2.443-3.223 4.36-5.666 5.666-2.443 1.306-5.018 2.002-10.783 2.002h-6.23c-5.764 0-8.34-.696-10.783-2.002-2.443-1.306-4.36-3.223-5.666-5.666-1.306-2.443-2.002-5.019-2.002-10.783v-7.194c0-5.764.696-8.34 2.002-10.783 1.306-2.442 3.223-4.36 5.666-5.666 2.443-1.306 5.019-2.002 10.783-2.002z" transform="translate(-811.000000, -143.000000) translate(240.000000, -864.000000) translate(562.000000, 1004.000000) translate(9.000000, 3.000000) translate(0.044000, 0.000000)"/>
                                    <path fill="#9F4EE3" d="M42.183 47.71c.55 1.053.674 2.347-.098 5.184 1.224.114 2.324-.132 3.685-.9.292-2.185.294-3.507-.328-5.53-.995.826-1.76 1.04-3.259 1.247" transform="translate(-811.000000, -143.000000) translate(240.000000, -864.000000) translate(562.000000, 1004.000000) translate(9.000000, 3.000000) translate(0.044000, 0.000000) translate(44.022460, 49.692693) rotate(-40.000000) translate(-44.022460, -49.692693)"/>
                                    <path fill="#DA6267" d="M1.838 16.562c.55 1.052.674 2.346-.098 5.183 1.224.115 2.324-.132 3.684-.9.293-2.185.295-3.507-.327-5.529-.995.825-1.76 1.038-3.26 1.246" transform="translate(-811.000000, -143.000000) translate(240.000000, -864.000000) translate(562.000000, 1004.000000) translate(9.000000, 3.000000) translate(0.044000, 0.000000) translate(3.677240, 18.543994) rotate(-220.000000) translate(-3.677240, -18.543994)"/>
                                    <path fill="#F5CB1C" d="M26.775 3.776c.384.845.48 1.666-.04 2.79 3.33.378 5.491.242 8.651-.13.493-1.68.321-2.705.116-3.429-3.185 1.069-5.386 1.07-8.727.77" transform="translate(-811.000000, -143.000000) translate(240.000000, -864.000000) translate(562.000000, 1004.000000) translate(9.000000, 3.000000) translate(0.044000, 0.000000) translate(31.220400, 4.899305) rotate(50.000000) translate(-31.220400, -4.899305)"/>
                                </g>
                                <path stroke="#FFF" stroke-width="4.055" d="M20.371 26.742L25.465 33.788 38.696 23.7" transform="translate(-811.000000, -143.000000) translate(240.000000, -864.000000) translate(562.000000, 1004.000000) translate(9.000000, 3.000000) translate(0.044000, 0.000000)"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </symbol>
</svg>
