import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'image-bubble',
  templateUrl: './image-bubble.component.html',
  styleUrls: ['./image-bubble.component.scss']
})
export class ImageBubbleComponent implements OnInit {

  @Input() bubbleImages:any=[];
  @Input() cssModifierClass?:string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
