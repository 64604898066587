import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    Output,
    EventEmitter,
    NgZone,
    ChangeDetectorRef
} from '@angular/core';
import {Store} from "@ngrx/store";
import {
    getGoalById,
    getGoalBySlug,
    getGoalPlans,
    getPasses,
    showPricingModal
} from "@core/application-state/app.actions";
import {
    selectPasses,
    selectStudent,
    selectGoalPlans, selectGoal, selectGoalMap
} from "@core/application-state/app.selectors";
import {isServer} from "@shared/utility/tb-common";
import {PlatformService} from "@core/services/platform-service";
import {TransferStateManager} from "@core/services/transferStateManager";
import {Subscription} from "rxjs";
import {AuthenticationService} from "@core/services/authentication.service";
import {globalPass, pass} from "@models/entity/passes/passes.adapter";
import {getCurrentVisitSource} from "@shared/utility/user";
import {LeadGenerationService} from "@core/services/lead-generation.service";
import {skipInitialState} from "@shared/utility/ngrx-utils";
import {ActivatedRoute} from "@angular/router";
import {passTypeProducts} from "@shared/utility/constants";

@Component({
  selector: 'pass-header',
  templateUrl: './pass-header.component.html',
  styleUrls: ['./pass-header.component.scss']
})

export class PassHeaderComponent implements OnInit,OnDestroy {

    @Input() passType? = "globalPass"; // or goalSubs
    @Input() passPageInfo? :any={};
    @Input() couponModalType:string='default';
    @Input() cssModifierClass ?:string = '';
    @Input() isGoalCourse: boolean = false;
    @Input() isLogoutGoalSubs: boolean ;
    @Input() isLogoutPaymentPage: boolean;
    @Input() goalTitle;
    @Input() isInGoalPageHeader = false;
    @Input() showStaticGoalHeading = false;
    @Input() isPrimaryGoalPage = false;
    @Input() hideStickyPlan = false;
    @Input() forcedLimit = 0;
    @Input() hideInBanner:boolean = false;
    @Input() recommendedPass;
    @Input() setCoupon;

    @Input() goalId;
    @Input() pId;
    @Input() benefits;
    @Input() iconType? = 'light';
    @Input() announcementPitch = '';
    @Input() couponCode

    isServer=isServer();
    isLoggedIn=this.platformService.isLoggedIn()
    isMobile=this.platformService.isMobile();

    isLeftSideBarOpen:boolean=true;
    isStickyOnScroll = true;
    student$ = this.store.select(selectStudent);
    goal: any;
    tbGoalPlans$ = this.store.select(selectGoalPlans);
    tbPasses$ = this.store.select(selectPasses);

    passesSub$: Subscription;

    activePassStudents : any=[];

    coldbootParams;

    referralDiscount:number;
    selectedPass: pass;
    showEMIPlans = false;
    currentSelectedPid:string='';

    constructor(
        private store:Store<{}>,
        private transferStateManager :TransferStateManager,
        private platformService: PlatformService,
        private ngZone : NgZone,
        private changeDetectorRef : ChangeDetectorRef,
        private leadGenerationService: LeadGenerationService,
        private route:ActivatedRoute
    ){
        if(!isServer() && (<any>window).coldboot) {
            this.coldbootParams = (<any>window).coldboot.onComplete;
          }
    }

    goalPitch = "<strong>Join Coaching</strong> and start your preparation now";

    getGoalPlan() {
        let productIds = this.platformService.getQueryParamValue('productIds');
        if(productIds && productIds.length){
            this.isStickyOnScroll = false;
        }
       
        this.passesSub$ = this.transferStateManager.get('goalPlans', getGoalPlans({gid:this.goal?.id || this.goalId}), this.tbGoalPlans$,
        (value)=>{
            this.processPasses(value);
            if(value.goalProperties && value.goalProperties.heading){
                this.goalPitch = value.goalProperties.heading;
            }
        });
    }
    getGlobalPass(){
        this.passesSub$ = this.transferStateManager.get(`passes_${this.passType}`, getPasses({passType: this.passType,pIds:this.currentSelectedPid}), this.tbPasses$,
    (_value)=>{
            let value = {..._value};
            value.passes = value.passes.filter(i => i.type == this.passType);
            this.processPasses(value);
        }, true);
    }
    processPasses(value){
        if(value && value.passes.length && value.metaData){
            let activePassStudentsArray=value.metaData.activePassStudents
            this.activePassStudents=activePassStudentsArray?.map(student => student?.image);
            this.referralDiscount=value.metaData.referralDiscount;
        }
    }

    ngOnInit(): void {
        let goalSlug = this.route.snapshot.params?.goalSlug;
        if(this.passType == "goalSubs"){
            let action:any;
            let goalIdentifier='';
            if(goalSlug){
                action = getGoalBySlug({slug: goalSlug})
                goalIdentifier = goalSlug;
            }
            if(this.goalId){
                action = getGoalById({id:this.goalId});
                goalIdentifier = this.goalId;
            }
            if(goalIdentifier){
                this.transferStateManager.get(`currentGoalDetails${goalIdentifier}`,action,this.store.select(selectGoalMap),(value)=>{
                    if(value && value[goalIdentifier]){
                        this.goal = value[goalIdentifier];
                        this.getGoalPlan();
                    }
                })
            }
        }

        if(passTypeProducts.includes(this.passType)){
            this.currentSelectedPid= this.platformService.getQueryParamValue("pId");
            this.getGlobalPass();
        }

        if(!this.isServer){
            // makes it so that angular ignores all the 'message' events
            this.ngZone.runOutsideAngular(()=>{
                window?.top?.addEventListener('message',(e)=>{
                    let data = e && e.data || {};
                    if(!data.source || data.source != "courseSidebar"){return;}
                    this.isLeftSideBarOpen = data.isSideBarVisible;
                    //only detect changes when we know we have a change
                    this.changeDetectorRef.markForCheck();
                })
            })
        }
        if(this.route.snapshot.queryParams?.showEmiPlans?.toLowerCase() === 'true'){
            this.showEMIPlans = true;
        }
    }


    openPricingModal(){
        let goalSlug = this.goal?.properties?.slug || this.goal?.slug;
        this.store.dispatch(showPricingModal({passType:this.passType,eventData:{}, goalSlug}))
    }

    generateLeadEvent(e){
        if(this.goal){
            this.leadGenerationService.generateLead({
                action: e || 'request_callback',
                type:"goal",
                parentId:this.goal?.id || this.goal?.properties?.id || '',
                prodType:"",
                client:(this.platformService.isMobile()) ? 'mweb' :"web",
                utm:getCurrentVisitSource(),
            })
        }
    }


    ngOnDestroy(): void {
        if(this.passesSub$){
            this.passesSub$.unsubscribe()
        }
    }

    @Output() purchaseButtonClick:EventEmitter<any>=new EventEmitter()
    purchaseButtonLead(e){
        this.purchaseButtonClick.emit(e)
    }

    toggleViewEmis(){
        this.showEMIPlans = !this.showEMIPlans;
    }

}
