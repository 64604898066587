import { Injectable} from '@angular/core';
import {
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    ActivatedRoute,
    UrlTree,
    CanActivateChild
} from '@angular/router';
import {Observable, of} from "rxjs";
import {Store} from "@ngrx/store";
import {map} from "rxjs/operators";import { getSuperGroups } from '@angular-commons/feature-modules/master-class/feature-state/masterClass.action';
import { selectMasterClassSuperGroups } from '@angular-commons/feature-modules/master-class/feature-state/masterClass.selector';
import {  validateMasterClassSlugOrId } from '@angular-commons/shared/utility/master-class-utility';
import { skipEmptyArray } from '@angular-commons/shared/utility/ngrx-utils';


@Injectable()
export class MasterExploreChildGuard implements CanActivateChild {

    constructor(private router:Router,
                private route: ActivatedRoute,
                private store: Store<{}>) {
    }

    canActivateChild(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean|UrlTree> {

        let slug = route.params["slug"];
        if(!slug){
            return of(true);
        }
        else{
                this.store.dispatch(getSuperGroups({}));
                return this.store.select(selectMasterClassSuperGroups).pipe(skipEmptyArray(), map((items:any) => {
                    if(items && items.length>0){
                        let idFromSlug= validateMasterClassSlugOrId(slug);
                          if(!idFromSlug || !this.doSuperGroupExistInGroups(items,idFromSlug)){
                            this.router.navigateByUrl('/free-live-classes');
                            return false;
                          }
                          else{ return true }  
                    }
                }));
        }

    }

    doSuperGroupExistInGroups(superGroups,id){
        return superGroups.find((item)=> item.id === id)
    }

}