import {Component, Inject, OnInit} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {langMap} from "@shared/utility/language";
import {TbcoreService} from "@core/services/tbcore.service";
import {PlatformService} from "@core/services/platform-service";
import {hideLoader, isServer, showLoader} from "@shared/utility/tb-common";
import { Store } from '@ngrx/store';
import { selectContentLang } from '@angular-commons/core/application-state/app.selectors';
import { ActivatedRoute, Router} from '@angular/router';
import { getLanguageSegmentUrl, getQueryStringFromParams, getUrlQueryParams } from '@angular-commons/shared/utility/url';
import { currentAffairsSlug } from '@angular-commons/shared/utility/constants';

@Component({
  selector: 'app-lang-dropdown',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  selectedLang: string;
  public isMobile: boolean = this.platformService.isMobile();
  public isServer = isServer();
  public pathName = this.platformService.getPathName();
  isCurrentAffairs = this.pathName.startsWith('/current-affairs');
  availableLangs: any= [
    { name: 'english' }, { name: 'hindi' }
  ];
  availableContentLanguages:any;
  languageMap: any = {};
  langArray = [];
  globalTranslationLang:string='english';

  constructor(@Inject(DOCUMENT) private document: Document, private tbCore:TbcoreService,private platformService: PlatformService,
              private store:Store, private route: ActivatedRoute, private router: Router) {
              this.selectedLang = this.platformService.getLanguageSegmentFromUrl() || this.platformService.getQueryParamValue('language');
              if(this.selectedLang.toLowerCase()=='english'){
                this.handleLanguageSegmentFlow();
              }
  }

  ngOnInit() {
    this.router.events.subscribe((e) => {
        this.pathName = this.platformService.getPathName();
        this.isCurrentAffairs = this.pathName.startsWith("/" + currentAffairsSlug);
    });
    this.tbCore.currentLang$.subscribe(lang => {
      this.globalTranslationLang=lang;
    });

    this.store.select(selectContentLang).subscribe(data =>{
      if (data?.availableLang?.length > 0) {
        this.availableContentLanguages = data.availableLang;
        let lang = data?.currLang || this.platformService.getCookie('language');
        let isLanguageAvailable = false;
        let isEnglishAvailable = false;
        this.availableContentLanguages.forEach((ele,_)=>{
          if(ele.lang.toLowerCase() === lang.toLowerCase()){
            isLanguageAvailable = true;
          }
          if(ele.lang.toLowerCase() === 'english'){
            isEnglishAvailable = true;
          }
        });
        if (lang && !isLanguageAvailable && this.availableContentLanguages && this.availableContentLanguages.length > 0 && !isServer() && !this.isCurrentAffairs){
          if(isEnglishAvailable){
            this.selectedLang = 'English';
          }else{
            this.selectedLang = this.availableContentLanguages[0].lang;
          }
          this.platformService.setCookie('language', this.selectedLang.toLowerCase());
          this.handleLanguageSegmentFlow();
        }
        let currLang = lang || this.selectedLang;
        if(this.globalTranslationLang != currLang){
          this.tbCore.currentLang$.next(currLang.toLowerCase());
        }

        if (this.isCurrentAffairs && currLang) {
          this.tbCore.currentLang$.next(currLang.toLowerCase());
          this.prepareCurrentAffairsUrlMap(this.availableContentLanguages);
        }

        if(!this.isCurrentAffairs && this.availableContentLanguages?.length){
          this.availableContentLanguages = this.availableContentLanguages.map((ele, _) => {
            return {...ele, url: getLanguageSegmentUrl(ele.lang, this.platformService.href)}
          });
        }
      }
    });
  }

  switchLang(lang) {
    showLoader("Loading");
    this.selectedLang = lang || this.selectedLang;
    if(this.isCurrentAffairs){
      this.prepareCurrentAffairsUrlMap(this.availableContentLanguages || []);
    }
    if (this.availableContentLanguages && this.availableContentLanguages.length>0) {
        if(['hindi','english'].includes(this.selectedLang.toLowerCase())) {
          this.platformService.setCookie('language', this.selectedLang.toLowerCase());
        }
        if(this.isCurrentAffairs){
          this.handleQueryLanguage();
        }else{
          this.handleLanguageSegmentFlow();
        }
    }
    else{
      this.platformService.setCookie('language', this.selectedLang);
      this.document.documentElement.lang = langMap[this.selectedLang];
      this.tbCore.currentLang$.next(this.selectedLang);
    }
    hideLoader();
  }

  handleQueryLanguage(){
    let redirectUrl = `${this.platformService.getPathName()}`;
    let qp = getUrlQueryParams(this.platformService.href);
    if (this.isCurrentAffairs &&  this.pathName.split('/').length === 2) {
      redirectUrl = this.getCurrentAffairsUrl(redirectUrl, this.selectedLang);
    }
    else {
      qp['language'] = this.selectedLang.toLowerCase();
      if (this.selectedLang.toLowerCase() === 'english'){
        delete qp['language'];
      }
      redirectUrl += getQueryStringFromParams(qp);
    }
    this.platformService.redirect(redirectUrl);
  }

  handleLanguageSegmentFlow(){
    let redirectUrl = `${this.platformService.getPathName()}`;
    let qp = getUrlQueryParams(this.platformService.href);
    if (this.isCurrentAffairs &&  this.pathName.split('/').length === 2) {
      redirectUrl = this.getCurrentAffairsUrl(redirectUrl, this.selectedLang);
    }
    else{
      if(qp['language']){
        delete qp['language'];
      }
       //qp['language'] = this.selectedLang.toLowerCase();
      //if (this.selectedLang.toLowerCase() === 'english'){
      //}
      redirectUrl = getLanguageSegmentUrl(this.selectedLang,this.platformService.href) + getQueryStringFromParams(qp);
    }
    this.platformService.redirect(redirectUrl);
  }

  prepareCurrentAffairsUrlMap(langArray) {
    this.langArray = langArray;
    langArray.map((obj) => this.languageMap[obj.lang] = this.getCurrentAffairsUrl(this.pathName, obj.lang));
  }

  getCurrentAffairsUrl(url, lang) {
    // handle child page
    if (url.split('/').length > 2) {
      if (lang.toLowerCase() !== 'english') {
        return url + '?language=' + lang;
      }
      return url;
    }
    else if (lang.toLowerCase() !== 'english') {
      return `/current-affairs-in-${lang.toLowerCase()}`;
    }
    return '/current-affairs';
  }

}
