import {Injectable} from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    ActivatedRoute,
    UrlTree
} from '@angular/router';
import {Observable, of} from "rxjs";
import {Store} from "@ngrx/store";
import {PlatformService} from "@core/services/platform-service";


@Injectable()
export class TestPromotionGuard implements CanActivate {

    constructor(private router:Router,
                private route: ActivatedRoute,
                private store: Store<{}>,
                private platformService: PlatformService) {
    }

    canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean|UrlTree> {

        let pathName=encodeURI(this.platformService.getPathName());
        // let parentSlugOrId=route.params['parentSlugOrId']
        // smart /my redirection
        let canNavigateToTestPromotion = this.platformService.isLoggedIn() && pathName.endsWith('promotions');
        if(!canNavigateToTestPromotion){
            let url=`/login?redirect_url=`+encodeURI(state.url)
            this.platformService.redirect(url,302);
            return of(false);
        }else{
            return of(true);
        }
    }

}
