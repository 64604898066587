import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {DataRecipe, ToBackendRecipe} from "@core/services/model-manager/recipe";
import {ModelManager} from "@core/services/model-manager.service";

import {EMPTY} from "rxjs";
import {switchMap, map,catchError} from 'rxjs/operators';
import {
  CallbackData,
  GenericSuccessResponse,
  GetCallbackStatusApi,
  RequestCallbackApi,
  RequestCallbackParams,
  _CallbackData,
  CreateLeadsParams,
  CreateLeadsApi,
  LeadsData, GetLeadsDataApi
} from "@models/entity/request-callback/request-callback.adapter";

import {
  getCallbackStatus, callbackStatusLoaded,
  requestCallback, requestCallbackSuccess, createLeads, createLeadsSuccess, getLeadsData, getLeadsDataSuccess
} from "@features/request-callback/feature-state/request-callback.actions";
import {IdentityAdapter} from "@models/mapping-framework";

@Injectable()
export class RequestCallbackEffects{

    getCallbackStatusRecipe : DataRecipe;
    getLeadsDataRecipe :      DataRecipe;
    requestCallbackRecipe:    ToBackendRecipe;
    createLeadsRecipe:        ToBackendRecipe;

    loadCallbackStatus$ = createEffect(()=> this.action$.pipe(
        ofType(getCallbackStatus),
        switchMap((action)=>{
            return this.getCallbackStatusRecipe.get(action.params).pipe(
                map((data:_CallbackData) =>  {
                    return callbackStatusLoaded({payload: data})
                }),
                catchError(() => EMPTY)
            );
        })
    ));


    requestCallback$ = createEffect(() => this.action$.pipe(
        ofType(requestCallback),
        switchMap(action => this.requestCallbackRecipe.send(action.details).pipe(
            map(res => requestCallbackSuccess({mobile:action.details.body.mobile, classId:action.details.body.classId, submitted:true}),
            )
        )
    )));

    createLeads$ = createEffect(() => this.action$.pipe(
        ofType(createLeads),
        switchMap(action => this.createLeadsRecipe.send(action.details).pipe(
            map(res => createLeadsSuccess({mobile: action.details.body.mobile, goalId: action.details.body.goalId, submitted: true}),
            )
        )
    )));

    getLeadsData$ = createEffect(() => this.action$.pipe(
      ofType(getLeadsData),
      switchMap((action) => {
        return this.getLeadsDataRecipe.get(action.params).pipe(
          map((data: LeadsData) =>  {
            return getLeadsDataSuccess({ payload: data});
          }),
          catchError(() => EMPTY)
        );
      })
    ));



    constructor(private modelManager: ModelManager,private action$ : Actions) {
        this.getCallbackStatusRecipe =  modelManager.makeDataRecipe(_CallbackData, CallbackData, GetCallbackStatusApi );
        this.requestCallbackRecipe =    modelManager.makeToBackendRecipe( GenericSuccessResponse , RequestCallbackParams, RequestCallbackApi, true);
        this.getLeadsDataRecipe =       modelManager.makeDataRecipe(IdentityAdapter, LeadsData, GetLeadsDataApi );
        this.createLeadsRecipe =        modelManager.makeToBackendRecipe( GenericSuccessResponse , CreateLeadsParams, CreateLeadsApi, true);
    }
}

