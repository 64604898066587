import {NgModule, SkipSelf} from '@angular/core';
import { CommonModule } from '@angular/common';
import {CoreModule} from "@core/core.module";
import {PassStatusComponent} from "@features/pass-status-component/pass-status.component";
import {ImageBubbleExportModule} from "@features/image-bubble/image-bubble.export.module";
import {HttpClient} from "@angular/common/http";
import {TransferState} from "@angular/platform-browser";
import {customTranslateLoader} from "@shared/utility/translation-loaders";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";

export function translateLoaderFactory(http: HttpClient, transferState: TransferState) {
    return new customTranslateLoader(http, transferState, 'pass');
}
@NgModule({
    declarations: [PassStatusComponent],
    imports: [
        CommonModule,
        CoreModule,
        ImageBubbleExportModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoaderFactory,
                deps: [HttpClient, [new SkipSelf(), TransferState]]
            },
            extend: true
        })
    ],
    exports: [PassStatusComponent]
})
export class PassStatusExportModule {
}
