import {createReducer, on} from "@ngrx/store";
import { getEmiDetailsLoaded } from "./down-payment.action";

export const initialState = {
    downPayments: {
        payments : [],
        isLoaded:false
    }
};

const _emisDetailReducer= createReducer(initialState,   
    on(getEmiDetailsLoaded,(_state, {payload})=>{

        let state = {..._state};
        state.downPayments = {
            payments: payload?.payments?.length ? payload.payments : [],
            isLoaded: true
        }
        return state;
    }),
);

export function emisDetailReducer(action, state){
    return _emisDetailReducer(action, state);
}
