import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {Store} from "@ngrx/store";
import {getSearchSuggestions, storeSearchSuggestion} from "@core/application-state/app.actions";
import {selectSearchHistory, selectStudent} from "@core/application-state/app.selectors";
import {AutoUnsub} from "@shared/utility/tb-common";
import {PlatformService} from "@core/services/platform-service";
import { GAEventService } from '@angular-commons/core/services/ga-event.service';
import {isServer} from "@shared/utility/tb-common";

@Component({
  selector: 'global-search-bar',
  templateUrl: './global-search-bar.component.html',
  styleUrls: ['./global-search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsub()
export class GlobalSearchBarComponent implements OnInit {

  partialQuery: string = '';

  showPastQueries: boolean = false;

  searchHistory$ = this.store.select(selectSearchHistory);
  student$ = this.store.select(selectStudent);

  isLoggedIn: boolean = this.platformService.isLoggedIn();

  @Input() hideSuggestions? = false;
  @Output() suggestions? = new EventEmitter<any>();

  pastQueries = [];
  sid = '';
  filteredQueries = [{}];

  suggestionLimit = 4;

  public isServer = isServer();
  public isMobile = this.platformService.isMobile();
  constructor(private store: Store<{}>, private platformService: PlatformService, private changeDetectorRef: ChangeDetectorRef, private gaEventService:GAEventService) {
  }

  ngOnInit(): void {
    this.isLoggedIn = this.platformService.isLoggedIn();
    this.searchHistory$.subscribe(data => {
      if(data.length > 0) {
        this.pastQueries = data;
        this.changeDetectorRef.markForCheck();
      }
    });

    if(this.isLoggedIn) {
      //ToDo: When replaced with backend implementation, dispatch this event on filterSuggestions()
      this.student$.subscribe(data => {
        if (data.id && data.id !== '-1') {
          this.sid = data.id;
          this.store.dispatch(getSearchSuggestions({sid: this.sid}));
        }
      });
    }
  }


  performSearch(query?) {
    let searchQuery = query || this.partialQuery;
    if(searchQuery){
      if(!isServer()){
      var pageUrl = this.platformService.getPathName();
      if(pageUrl.includes('super-coaching')==true){
          var tabType='';
          if(pageUrl.includes('dashboard')){
            tabType = 'dashboard';
          }
          else if(pageUrl.includes('notes')){
            tabType = 'notes';
          }
          else if(pageUrl.includes('tests')){
            tabType = 'tests';
          }
          else if(pageUrl.includes('courses')){
            tabType = 'courses';
          }
          else if(pageUrl.includes('practice')){
            tabType = 'practice';
          }
          if(tabType){
            this.gaEventService.sendSupercoachingSearch({
              SEARCH_TERM: searchQuery,
              type: tabType,
              pagePath: pageUrl
          })
          }
      }
    }
      this.store.dispatch(storeSearchSuggestion({sid: this.sid, query: searchQuery}));
      setTimeout(() => {
        //ToDo: Implement actual search logic
        window.open('/search/' + this.partialQuery);
      }, 20)

    }
  }

  filterSuggestions() {
    if(this.pastQueries.length > 0 && this.partialQuery.length > 0) {
      this.filteredQueries = this.pastQueries.map(i=>i.query.toLowerCase()).filter( i => i.includes(this.partialQuery.toLowerCase())).slice(0,this.suggestionLimit);
      this.showPastQueries = true;
      this.suggestions.emit(this.filteredQueries);
    }
    else {
      this.showPastQueries = false;
    }
  }

}
