import { Component, Input, OnInit } from '@angular/core';
import { PlatformService } from '@angular-commons/core/services/platform-service';

@Component({
  selector: 'pass-includes',
  templateUrl: './pass-includes.component.html',
  styleUrls: ['./pass-includes.component.scss']
})
export class PassIncludesComponent implements OnInit {

  @Input() passPageInfo:any={};
  @Input() passType = "globalPass"; // or goalSubs
  isMobile = this.platformService.isMobile();

  constructor(private platformService: PlatformService) { }

  ngOnInit(): void {
  }

}
