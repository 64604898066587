import {createAction, props} from "@ngrx/store";

export const getEmiMandateData = createAction('[EMI Mandate] Get EMI Mandate Data', props<{emiId:string,name:string,mobile:string}>());
export const getEmiMandateDataLoaded = createAction('[EMI Mandate] Get EMI Mandate Data Loaded', props<{payload: any}>());

export const getStudentPartialEmi = createAction('[Student EMI] Get Student Partial EMI', props<{goalId:string,productType?:string}>());
export const getStudentPartialEmiLoaded = createAction('[Student EMI] Get Student Partial EMI Loaded', props<{payload: any }>());

export const setErrorMessage  = createAction('[EMI Mandate] SET Error Message', props<{message:string}>());

export const getMandateGuideLines = createAction('[EMI Mandate] Get Mandate GuideLines', props<{ commonDataType:string }>());
export const getMandateGuideLinesLoaded = createAction('[EMI Mandate] Get Mandate GuideLines Loaded', props<{ payload:any }>());