import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EmiWrapperComponent} from "@features/emi-wrapper/emi-wrapper.component";
import { EmiBlockerPopupModule } from '../emi-blocker-popup/emi-blocker-popup.module';
import { EmiMandatePopupModule } from '../emi-mandate-popup/emi-mandate-popup.module';
import { DownPaymentPopupModule } from '../down-payment-popup/down-payment-popup.module';


@NgModule({
    declarations: [EmiWrapperComponent],
    imports: [
        CommonModule,
        EmiBlockerPopupModule,
        EmiMandatePopupModule,
        CommonModule,
        DownPaymentPopupModule
    ],
    exports: [EmiWrapperComponent]
})

export class EmiWrapperExportModule { }