import { Injectable } from '@angular/core';
import { clientOnly } from '@angular-commons/shared/utility/platform-decorators';
import {getLocalStorage} from "@shared/utility/tb-common";

@Injectable({
    providedIn: 'root',
})
export class LeadGenerationService {
    constructor() {}

    @clientOnly()
    generateLead(body,isSetSid = false) {
        if (!body){
            return;
        }
        let tb_student_data;
        try {
            tb_student_data = JSON.parse(getLocalStorage('tb_student_data'));
        }
        catch (e){
            console.error('Error parsing student data', e);
        }
        if (tb_student_data?.mobile || tb_student_data?._id || body?.sid || body?.email || body?.mobile) {
            if (!body.mobile){
                body['mobile'] = tb_student_data?.mobile || '';
            }
            if(!body.sid && !isSetSid) {
                body['sid'] = tb_student_data?._id || '';
            }
            return (window as any).leadGenService.generateLead(body);
        }
    }
}
