import {setClientCookie as setCookie, removeLocalStorage, getClientCookie, setLocalStorage,getLocalStorage} from "./tb-common";
import {isValidDate} from "@shared/utility/date-utilities";
import {goalPlan} from "@models/entity/passes/passes.adapter";
declare var sbjs;
declare var phonePeSdk :any;

export const KEY_TOKEN = "tb_token";
export const KEY_SELECTED_GOAL = "tb_selected_goalv2";
export const KEY_SELECTED_GOAL_SLUG = "tb_selected_goal_slugv2";
export const TB_STUDENT_DATA='tb_student_data';
export const TB_STUDENT_ID ='tb_student_id';
export const TB_GOALS_PURCHASED = `tb_goals_purchased`;
export const TB_USER_ROLE = `userRole`;
export const TB_USER_TARGET_ENROLLED = `userTargetEnrolled`;
// export const GOAL_RESUME_FLOW_STATUS='goalResumeFlowStatus';
// export const SHOW_RESUME_FLOW='1'
// export const SHOW_ENROLLED_COURSES='0'

export function clearStudentData(){
    removeLocalStorage(TB_STUDENT_DATA);
    removeLocalStorage(TB_STUDENT_ID);
    setCookie(TB_GOALS_PURCHASED, "", -1);
    clearGoalCookie();
    // clearGoalResumeFlowCookie();
}

export function setStudentData(data) {
    setLocalStorage(TB_STUDENT_ID, JSON.stringify(data._id));
    setLocalStorage(TB_STUDENT_DATA, JSON.stringify(data));
    let goalPlanState  = {};
    if(data.goalSubs){
        data.goalSubs.forEach(sub => {
            goalPlanState[sub.goalId] = goalPlan.getStuPassState(sub);
        })
    }
    setCookie(TB_GOALS_PURCHASED, "", -1);
    /*if(data && goalPlanState){
        //set student's purchased goals
        setCookie(TB_GOALS_PURCHASED, goalPlan.allPlansSerialiser(goalPlanState),1);
    }*/
}

export function getStudentId(){
    return (JSON.parse(getLocalStorage(TB_STUDENT_ID) || '""')) || '';
}

export function clearStudentCookie() {
	if(typeof phonePeSdk !== 'undefined') {
		phonePeSdk.setItem('token', KEY_TOKEN, '');
	}
	setCookie(KEY_TOKEN, '',-1);
    setCookie(TB_USER_ROLE, "", -1);
    setCookie(TB_USER_TARGET_ENROLLED, "", -1);
    clearStudentData();
}
export function getClientToken () {
    return getClientCookie(KEY_TOKEN);
};

export function setClientToken (t, expiry) { // in secs
    clearStudentCookie();
    setCookie(KEY_TOKEN, t, expiry / (60 * 60 * 24));
    return getClientCookie(KEY_TOKEN);
};


export function clearGoalCookie() {
    setCookie(KEY_SELECTED_GOAL, "", -1);
    setCookie(KEY_SELECTED_GOAL_SLUG, "", -1);
}

export function setClientGoal({id,slug}, expiry) { // in secs
    if(!(id && slug)){ return; }
    clearGoalCookie();
    setCookie(KEY_SELECTED_GOAL, id, expiry / (60 * 60 * 24));
    setCookie(KEY_SELECTED_GOAL_SLUG, slug, expiry / (60 * 60 * 24));
};


export function getClientGoal() {
    let goalId = getClientCookie(KEY_SELECTED_GOAL);
    let goalSlug = getClientCookie(KEY_SELECTED_GOAL_SLUG);
    if(goalId && goalSlug){
        return {id:goalId,slug:goalSlug}
    }
    return {};
};

export function getFirstVisitSource() {
    if (typeof sbjs === "object") {
        var date = sbjs.get.first_add.fd;

        //Since safari doesn't recognize new Date('2018-12-14 ::') but recognizes new Date('2018/12/14 ::')
        date = (date && date.replace(new RegExp('-', 'g'), '/')) || (new Date());

        var returnObj = {
            type: sbjs.get.first.typ,
            utm_source: sbjs.get.first.src,
            utm_medium: sbjs.get.first.mdm,
            utm_campaign: sbjs.get.first.cmp,
            utm_content: sbjs.get.first.cnt,
            utm_term: sbjs.get.first.trm,
            timestamp: new Date(date).toISOString(),
            entrance: sbjs.get.first_add.ep,
            referralUrl: sbjs.get.first_add.rf,
        }

        Object.keys(returnObj).forEach( (key)=>{ if(returnObj[key] === undefined || returnObj[key] === "(none)"){ delete returnObj[key]}})
        return returnObj;
    }
};

export function getCurrentVisitSource() {
    if (typeof sbjs === "object") {
        var date = sbjs.get.current_add.fd;
        const curr = new Date().getTime();

        //Since safari doesn't recognize new Date('2018-12-14 ::') but recognizes new Date('2018/12/14 ::')
        date = (date && date.replace(new RegExp('-', 'g'), '/')) || (new Date());
        var returnObj;
        if(curr - new Date(date).getTime() >= 30 * 60 * 1000){
            returnObj = {};
        } else {
            returnObj = {
                type: sbjs.get.current.typ,
                utm_source: sbjs.get.current.src,
                utm_medium: sbjs.get.current.mdm,
                utm_campaign: sbjs.get.current.cmp,
                utm_content: sbjs.get.current.cnt,
                utm_term: sbjs.get.current.trm,
                timestamp: new Date(date).toISOString(),
                entrance: sbjs.get.current_add.ep,
                referralUrl: sbjs.get.current_add.rf
            }
        }
        Object.keys(returnObj).forEach( (key)=>{ if(returnObj[key] === undefined || returnObj[key] === "(none)"){ delete returnObj[key]}})
        return returnObj;
    }
};

export function getFakeFp(){
    let fpkey = "fakefp";
    let fp = getLocalStorage(fpkey);
    if(!fp){
        fp = fpkey + Math.floor(Math.random() * 10e12);
    }
    setLocalStorage(fpkey,fp, 30);
    return fp;
}

export function hasActivePass(pass) {
    if(pass && pass.expiry && isValidDate(pass.expiry)) {
        return Date.now() < new Date(pass.expiry).getTime();
    }
    return false;
}
