import { Component, Input, Output, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { GAEventService } from '@angular-commons/core/services/ga-event.service';
import { getCurrentVisitSource } from '@angular-commons/shared/utility/user';
import { LeadGenerationService } from '@angular-commons/core/services/lead-generation.service';
import { CouponCodeService } from '@core/services/coupon-code-service';
import {
  CouponCTAData,
  CouponDataObject,
  _CouponProductsArray,
} from '../../../../models/entity/coupon/coupon.adapter';
import {
  selectCouponCTAData,
  selectCoupon,
  selectCouponProducts,
} from '../../feature-state/coupon.selectors';
import { isServer, getPageText } from '@shared/utility/tb-common';
import { PlatformService } from '@core/services/platform-service';
import { skillPassType } from '@shared/utility/constants';
import { getCouponCTAData, getCouponProducts, resetCoupon } from '../../feature-state/coupon.actions';
import { selectEventExtras, selectPricingModal } from '@angular-commons/core/application-state/app.selectors';
import { showCouponModal } from '@angular-commons/core/application-state/app.actions';
import { TransferStateManager } from '@angular-commons/core/services/transferStateManager';

@Component({
  selector: 'coupon-code-status',
  templateUrl: './coupon-code-status.component.html',
  styleUrls: ['./coupon-code-status.component.scss']
})
export class CouponCodeStatusComponent implements OnInit, OnDestroy {
  onServer = isServer();
  isMobile:boolean = this.platformService.isMobile();
  couponState:string = 'default';
  couponcode:string;
  couponDiscount:number
  ctaDetails:CouponCTAData = {
    "offersText": "Get exciting offers inside",
    "viewOffersCTA": "Apply Coupon",
    "moreOffersCTA": "More Offers"
  };
  private isServer = isServer();
  @Input() productDetails: object = {};
  @Input() productType:string = '';
  @Input() productIds: string[];
  @Input() ignoreUrl:boolean = false;
  @Input() fullWidth:boolean = false;
  @Input() couponType:string = 'default';
  @Input() couponModalType:string = 'default';
  @Input() isAppliedCouponValidOnPass: boolean;
  @Output() couponApply: EventEmitter<any> = new EventEmitter();
  @Input() pageType:string = '';
  @Input() sourceType: string = 'onPage';
  @Input() goal:any;
  @Input() goalTitle:string;
  @Input() course: any;
  @Input() showCouponText:boolean = true;
  @Input() cssModifierClass:string = '';
  @Input() isSkill:boolean = true;
  coupon$: Observable<CouponDataObject>;
  couponProducts$: Observable<_CouponProductsArray>;
  couponObj: any;
  couponSub$: Subscription;
  couponCta$: Subscription;
  showEvents:boolean = true;
  isLoggedIn:boolean = this.platformService.isLoggedIn();
  isPricingModalOpen:boolean = false;
  typeInPricingModal:string = '';
  couponAppliedStateSubscriber: Subscription;
  eventExtras:any={}

  constructor(
    private transferState: TransferState,
    private transferStateManager:TransferStateManager,
    private store: Store<{}>,
    private platformService: PlatformService,
    private gaEventService: GAEventService,
    private route: ActivatedRoute,
    private leadGenerationService: LeadGenerationService,
    private couponCodeService: CouponCodeService
  ) {
    this.coupon$ = this.store.select(selectCoupon);
    this.couponProducts$ = this.store.select(selectCouponProducts);
  }
  

  ngOnInit(): void {

    this.store.select(selectEventExtras).subscribe((data) => {
      if (data) {
        this.eventExtras = data;
      }
    });

    this.store.select(selectPricingModal).subscribe((data) => {
      if (data) {
        this.isPricingModalOpen = data.show;
        this.typeInPricingModal = data.passType;
      }
    });

    if (!this.onServer) {
      this.couponcode = this.platformService.getQueryParamValue('coupon') || '';
      if (this.couponcode && !this.ignoreUrl) {
        this.couponcode = this.couponcode && this.couponcode.toUpperCase();
        this.store.dispatch(
          getCouponProducts({
            coupon: this.couponcode,
            from: getPageText(),
            itemId: this.eventExtras.itemId,
            itemType: this.eventExtras.itemType,
          })
        );
      }
    }

    this.couponAppliedStateSubscriber = this.couponCodeService.updatedCouponState.subscribe((couponObj) => {
      if (couponObj[this.productType]) {
        let couponData = couponObj[this.productType];
        this.couponState = couponData?.state;
        this.couponObj = couponData?.coupon;
        this.couponcode = couponData?.code;
        if (this.course?.id && couponData?.products) {
          let productObj = couponData.products[this.course.id];
          this.couponDiscount = productObj.oldCost - productObj.cost;
        }
        this.couponApply.emit(couponData);
      }
    });

    if (this.couponModalType === 'default') {
      this.route.queryParams.subscribe((param) => {
        if (param?.couponModal) {
          this.openCouponModal();
        }
      });
    }
  }

  openCouponModal() {
    if (this.isLoggedIn) {
      this.handleLeadGenerationApi('apply_coupon_clicked');
    }
    if (this.isSkill) {
      this.store.dispatch(
        showCouponModal({ ModalType: 'default', productType: this.productType })
      );
    } else {
      this.store.dispatch(showCouponModal({ModalType:this.couponModalType,productType:this.productType}));
      if (this.goal && this.productType === 'goalSubs' && this.platformService.isLoggedIn()) {
        this.gaEventService.sendSupercoachingEntityExplore({
          productName: this.goalTitle,
          productID: this.goal?.id,
          category: 'EnrollNow',
          goalID: this.goal?.id,
          goalName: this.goalTitle,
          clickText: 'Enroll Now',
        });
        this.gaEventService.sendApplyCouponClicked({
          goalID: this.goal?.id,
          goalName: this.goalTitle,
          productID: this.goal?.id,
          productName: this.goalTitle,
        });
      }
    }
  }

  cancelCode() {
    this.couponState = 'default';
    this.couponcode = '';
    this.store.dispatch(resetCoupon());
    const couponObj = {};
    this.couponCodeService.removeCoupon(skillPassType);
    this.couponApply.emit(couponObj);
  }

  handleLeadGenerationApi(leadActionText) {
    if (this.course?.isSkillCourse) {
      const leadObj = {
        action: leadActionText,
        childId: '',
        childType: '',
        client: this.isMobile ? 'mweb' : 'web',
        mobile: '',
        parentId: '',
        prodId: this.course?.id,
        prodType: this.course.isCareerProgram ? 'career_program' : 'mini_course',
        type: 'SkillAcademy',
        utm: getCurrentVisitSource(),
      };
      this.leadGenerationService.generateLead(leadObj);
    }
  }

  pushCouponUsedEvent(id, product) {
    if (this.isServer || (this.isPricingModalOpen && this.typeInPricingModal === this.productType && this.sourceType !== 'pricingModal')) {
      return;
    }
    if (this.showEvents && !this.isServer) {
      this.gaEventService.sendCouponUsedEvent({
        finalAmount: product?.cost,
        discountValue: product?.oldCost - product?.cost,
        couponCode: this.couponcode,
        productID: id,
        productName: this.productDetails[id]?.name,
        client: 'web',
        productCost: product?.oldCost,
        pagePath: window.location.pathname + window.location.search || '',
        isSuccess: true,
        productType: this.productType === 'goalSubs' ? 'SuperCoaching' : this.productDetails[id]?.productType,
      });
      this.gaEventService.sendApplyCouponClicked({
        goalID: this.goal?.id,
        goalName: this.goalTitle,
        productID: this.goal?.id,
        productName: this.goalTitle,
      });
      this.showEvents = false;
    }
  }

  ngOnDestroy() {
    if (this.couponCta$) {
      this.couponCta$.unsubscribe();
    }
    if (this.couponSub$) {
      this.couponSub$.unsubscribe();
    }
    this.couponAppliedStateSubscriber.unsubscribe();
  }
}
