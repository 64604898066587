interface TrackerEvent {
    apiEndpoint:string,
    info:object;
}

export class selectCourseEntityEvent implements TrackerEvent {
    apiEndpoint:string='events/entity-click';
    info:object;
    private static defaultInfo = {
        cmp: "SelectCourseSelling", //component from which we are trying to call
        cta: "Testbook Select", // CTA name - currently static might be dynamic in future for others
        did: "",
        dtyp: "courses",
        eId: '',
        eType: "selectClass", // productType
        idx: 0,
        itr: 0,
        rtm: new Date().getTime(), //cur Time
        sid: '',
        srid: "",
        url: '',
        cver: "1.2",
        sver: "1.0",
        lang: ''
    }

    constructor(eventInfo:object) {
        this.info = {...selectCourseEntityEvent.defaultInfo,...eventInfo};
    }
}


export class pypSearchResultEvent implements TrackerEvent {
    apiEndpoint:string='events/search-result';
    info:object;
    private static defaultInfo = {
        id:'',
        q: '',
        sid: '',
        url: '',
        lang: '',
        rtm: new Date().getTime(),
        skip: 0,
        limit: 0,
        p: "web",
        loc: {
            lat: 0,
            lng: 0,
            acc: 0
        },
        sver: "1.0",
        cver: "1.0",
        rfr: '',
        cmp: 'pyp-search',
        act: 'searchButton',
        rslt: [],
        fltr: [],
    }

    constructor(eventInfo:object) {
        this.info = {...pypSearchResultEvent.defaultInfo,...eventInfo};
    }
}

export class SearchResultEvent implements TrackerEvent {
    apiEndpoint:string = 'events/search-result';
    info:object;
    private static defaultInfo = {
        id:'',
        q: '',
        sid: '',
        url: '',
        lang: '',
        rtm: new Date().getTime(),
        p: "web",
        sver: "1.0",
        cver: "1.0",
        rfr: '',
        cmp: '',
        act: 'searchButton',
        rslt: [],
        fltr: [],
    };
    constructor(eventInfo:object) {
        this.info = {...SearchResultEvent.defaultInfo, ...eventInfo};
    }
}

