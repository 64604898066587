import { hideFooter, hideHeader, hideStickyFooter, setLoggedOutPurchaseModalState, setLoggedOutPurchaseModalStateUsingIFrame } from '@angular-commons/core/application-state/app.actions';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
  selector: 'logged-out-purchase-iframe',
  template: '<logged-out-purchase-modal></logged-out-purchase-modal>',
  styles: ['']
})
export class LoggedOutPurchaseIframeComponent implements OnInit {


  goalId:string;
  pId:string;
  pType:string;
  stageOne;
  coupon;
  constructor(private store: Store<any>,private route:ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params)=>{
      if(params && Object.keys(params).length){
        if(params['isIframe'] === '1'){
          this.goalId = params['goalId']
          this.pId=params['pId'];
          this.pType=params['pType'];
          this.stageOne = params['stageOne'] === "true";
          this.coupon = params['coupon']
          this.store.dispatch(setLoggedOutPurchaseModalStateUsingIFrame({open:true,goalId:this.goalId,pId:this.pId,pType:this.pType,stageOne:this.stageOne, coupon: this.coupon}));
          this.store.dispatch(hideHeader());
          this.store.dispatch(hideFooter());
          this.store.dispatch(hideStickyFooter());
        }
      }
    })
  }

}
