import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmiMandatePopup } from './emi-mandate-popup.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@angular-commons/core/core.module';
import { LoaderModule } from '../loader/loader-export.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EmiMandateReducer } from './feature-state/emi-mandate.reducer';
import { EmiMandateEffects } from './feature-state/emi-mandate.effects';

@NgModule({
  declarations: [EmiMandatePopup],
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    LoaderModule,
    StoreModule.forFeature('emiMandate', EmiMandateReducer),
    EffectsModule.forFeature([EmiMandateEffects]),
  ],
  exports:[EmiMandatePopup]
})
export class EmiMandatePopupModule { }