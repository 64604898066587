import {propertyMaps, SourceProjectionMapper} from "@models/mapping-framework";
import {baseModel, setupModel} from "@models/types";
import {Network} from "@core/services/model-manager/main";
import {Validator as _} from "@shared/utility/validator";

export class productEntityCountData extends baseModel {
    @propertyMaps('mockTestCount', _.num)
    public mockTestCount;

    @propertyMaps('targetCount', _.num)
    public targetCount;

    @propertyMaps('tipsCount', _.num)
    public tipsCount;

    @propertyMaps('testsDiscussionCount', _.num)
    public testsDiscussionCount;

    @propertyMaps('pdfCount', _.num)
    public pdfCount;

    @propertyMaps('selfPacedCount', _.num)
    public selfPacedCount;

    @propertyMaps('totalPYPCount', _.num)
    public totalPYPCount;

    @propertyMaps('recordedVideoCount', _.num)
    public recordedVideoCount;

    @propertyMaps('practiceCount', _.num)
    public practiceCount;
}
setupModel(productEntityCountData, {});

export class productEntityCountApi {
    static apiEndpoint = 'v1/common/data';

    static projection;
    static get  __projection(){ 
        if(!productEntityCountApi.projection){
            productEntityCountApi.projection = JSON.stringify(new SourceProjectionMapper(productEntityCountData).map());
        }
        return productEntityCountApi.projection;
    }

    static apiCall(network:Network, params:any){
        params.type = 'entityCount';
        return network.get(productEntityCountApi.apiEndpoint,{...params,__projection:productEntityCountApi.__projection});
    }
}
