import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
    ActivatedRoute
} from '@angular/router';
import {Observable, of, ReplaySubject} from 'rxjs';
import {Store} from "@ngrx/store";
import {PlatformService} from "@core/services/platform-service";
import { TransferStateManager } from '@angular-commons/core/services/transferStateManager';
import {getCloudBucketData, setCurrentPageGoal} from "@core/application-state/app.actions";
import {selectCloudBucketData} from "@core/application-state/app.selectors";
import {AuthenticationService} from "@core/services/authentication.service";
import {getGoalPlansPageUrl} from "@shared/utility/goal-utility";
import {LeadGenerationService} from "@core/services/lead-generation.service";
import {getQueryStringFromParams, getUrlQueryParams, getUrlWithoutQueryParams} from "@shared/utility/url";


@Injectable({
    providedIn: 'root'
})
export class NewMgoalGuard implements CanActivate {
    constructor(
        private router:Router,
        private store: Store<{}>,
        private platformService: PlatformService,
        private transferStateManager : TransferStateManager,
        private auth: AuthenticationService,
        private leadGenerationService: LeadGenerationService,
        private route: ActivatedRoute) {
    }
    goalSlug;
    mGoalData
    isLoggedIn = this.platformService.isLoggedIn();
    isMobile = this.platformService.isMobile();
    canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree>{
        this.goalSlug = route.params?.goalSlug;
        if(this.goalSlug) {
            const sub = new ReplaySubject<boolean>();
            let transferStateKey = `mGoalCloudData_${this.goalSlug}`;
            let fileName = 'newMgoal.json';
            let isLoggedOutFlow = this.platformService.getQueryParamValue('logout')  === '1';
            this.mGoalData = this.transferStateManager.get(
                transferStateKey,
                getCloudBucketData({fileName: fileName, key: this.goalSlug}),
                this.store.select(selectCloudBucketData),
                (value) => {
                    if(!(value && value[fileName] && value[fileName]?.parentSlug)){
                        this.platformService.redirect(`/super-coaching`,302);
                        sub.next(false);
                        sub.complete();
                    } else{
                        if(this.isLoggedIn){
                            let prodId = '';
                            let parentId = '';
                            if(value[fileName]?.type === 'SkillAcademy'){
                                prodId = value[fileName]?.parentId;
                            }
                            if(value[fileName]?.type === 'goal'){
                                parentId = value[fileName]?.parentId;
                            }
                            let qp = getQueryStringFromParams(this.route?.snapshot?.queryParams, {
                                fireLead: true,
                                action: "complete_signup",
                                type: value[fileName]?.type,
                                parentId: parentId,
                                prodId: prodId,
                                parentName: value[fileName]?.parentName
                            })
                            if(isLoggedOutFlow){
                                let url = this.platformService.href;
                                this.platformService.redirect(`/logout?redirect_url=${encodeURIComponent(url)}`);
                                return sub.next(false);
                            }else if(value && value[fileName] && value[fileName].cta_link){
                                let ctaLinkQueryParams = getUrlQueryParams(value[fileName].cta_link);
                                let leadQueryParams = getUrlQueryParams(qp);
                                let finalQueryStringParams = getQueryStringFromParams(ctaLinkQueryParams,leadQueryParams,true);
                                let ctaLinkUrl = getUrlWithoutQueryParams(value[fileName].cta_link);
                                this.platformService.redirect(ctaLinkUrl + finalQueryStringParams,302);
                                return sub.next(false);
                            }else if(value[fileName]?.type === 'goal'){
                                this.platformService.redirect(getGoalPlansPageUrl(this.goalSlug) + qp, 302);
                            } else if(value[fileName]?.type === 'SkillAcademy'){
                                this.platformService.redirect(`/courses/${value[fileName]?.parentId}/online-coaching` + qp, 302);
                            }
                            sub.next(true);
                            sub.complete();
                        } else {
                            if(value[fileName]?.type === 'goal'){
                                this.store.dispatch(setCurrentPageGoal({payload: {id: value[fileName]?.parentId}}));
                            }
                            if(this.mGoalData){
                                this.mGoalData.unsubscribe();
                            }
                            sub.next(true);
                            sub.complete();
                        }
                    }
                    if(this.mGoalData){
                        this.mGoalData.unsubscribe();
                    }
                }, true)
            return sub;
        }
        return of(false);
    }
}