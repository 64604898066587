// This function gives the deeplink for Opening in App 
export const ContinueInAppPageMap ={
  'ExamPage' : {
          trackingInfo:'CIA-Target-mWeb',
          id:'',
          deeplinkPath:''},
  'HomePage' : {
          trackingInfo:'CIA-Fallback-mWeb',
          id:'',
          deeplinkPath:'testbook://tbapp/home/'},
  'ExamChildPage' : {
          trackingInfo:'CIA-TargetChild-mWeb',
          id:'',
          deeplinkPath:'testbook://tbapp/landing/super/'},
  'customPage' : {
          trackingInfo:'CIA-Custom-mWeb',
          id:'',
          deeplinkPath:'testbook://tbapp/landing/super/' },
   'customChildPage' : {
          trackingInfo:'CIA-CustomChild-mWeb',
          id:'',
          deeplinkPath:'testbook://tbapp/landing/super/'  },
    'PYPSpecificExamPage' :{
          trackingInfo:'https://link.testbook.com/CIA-PYP-mWeb',
          id:'',
          deeplinkPath:'testbook://tbapp/landing/super/' },
    'SuperCoachingGlobal': {
          trackingInfo:'CIA-SuperGoalSelection-mWeb',
          id:'',
          deeplinkPath:'testbook://tbapp/landing/super/' },
   'superCoaching':{
          trackingInfo:'CIA-SuperGoalSelection-mWeb',
          id:'',
          deeplinkPath:'testbook://tbapp/landing/super/' },
   'AllTestSeriesPage': {
          trackingInfo:'CIA-AllTestSeries-mWeb',
          id:'',
          deeplinkPath:'testbook://tbapp/testseries/' },
   'TestSeriesPage': {
          trackingInfo:'CIA-AllTestSeries-mWeb',
          id:'',
          deeplinkPath:'testbook://tbapp/testseries/'},
   'Default': {
          trackingInfo:'CIA-Fallback-mWeb',
          id:'',
          deeplinkPath:'testbook://tbapp/landing/super/' },
   'Others':{
          trackingInfo:'CIA-Practice-mWeb',
          id:'',
          deeplinkPath:'testbook://tbapp/landing/super/' },
   'AllMasterClassPage': {
          trackingInfo:'CIA-AllLiveSeries-mWeb',
          id:'',
          deeplinkPath:'' },
   'PYPGlobalPage':{
          trackingInfo:'CIA-PYP-mWeb',
          id:'',
          deeplinkPath:'testbook://tbapp/pyp' },
   'selectionsPage':{
          trackingInfo:'CIA-OurSelections-mWeb',
          id:'',
          deeplinkPath:'testbook://tbapp/landing/super/' },
   'ExamGlobalPage': {
          trackingInfo:'CIA-AllExams-mWeb',
          id:'',
          deeplinkPath:'' },
   'SkillAcademyExternalPage - Global Course Page':{
          trackingInfo:'CIA-Fallback-mWeb',
          id:'',
          deeplinkPath:''},
   'ExamCalendarPage':{
          trackingInfo:'CIA-Fallback-mWeb',
          id:'',
          deeplinkPath:''},
    'AllSavedQuestionsPage':{
          trackingInfo:'CIA-SavedQuestions-mWeb',
          id:'',
          deeplinkPath:'https://link.testbook.com/CIA-SavedQuestions-mWeb'},
    'practicePage':{
            trackingInfo:'CIA-AllPractice-mWeb',
            id:'',
            deeplinkPath:'https://link.testbook.com/CIA-AllPractice-mWeb'},
    'AllReportedQuestionsPage':{
            trackingInfo:'CIA-ReportedQuestions-mWeb',
            id:'',
            deeplinkPath:'https://link.testbook.com/CIA-ReportedQuestions-mWeb'},
    'freePracticePage':{
            trackingInfo:'',
            id:'',
            deeplinkPath:'testbook://tbapp/practice'},
    'liveSeries':{
            trackingInfo:'',
            id:'',
            deeplinkPath:'testbook://tbapp/masterclass/landing/?groupTag='},
   '':{
          trackingInfo:'CIA-Fallback-mWeb',
          id:'',
          deeplinkPath:'testbook://tbapp/landing/super/' }
}

export function getAppModuleLink (courseId, moduleId) {
 return `testbook://tbapp/videomodule?courseId=${courseId}&moduleId=${moduleId}`;
}
export function getOpenInAppTargetPageDeeplink(examId) {
  return 'https://link.testbook.com/f6iRJGAA3ib?$deeplink_path=testbook://tbapp/examPage/' + examId;
}
export function getOpenInAppTargetChildPageDeeplink(targetSlug, childSlug) {
  return `https://link.testbook.com/f6iRJGAA3ib?$deeplink_path=testbook://tbapp/examOverview/${targetSlug}/child/${childSlug}`;
}
export function getOpenInAppCustomPageDeeplink(customPageSlug) {
  return `https://link.testbook.com/f6iRJGAA3ib?$deeplink_path=testbook://tbapp/question-answer/rpath--${customPageSlug}`;
}
export function getOpenInAppCustomChildPageDeeplink(customPageSlug, customChildPageSlug) {
  return `https://link.testbook.com/f6iRJGAA3ib?$deeplink_path=testbook://tbapp/question-answer/rpath--${customPageSlug}---${customChildPageSlug}`;
}
export function getOpenInAppTargetPypChildPageDeeplink(targetId, targetName) {
  return `https://link.testbook.com/Dpcw2TEA3ib?$deeplink_path=testbook://tbapp/pyp/target/${targetId}/${targetName}`;
}
export function getOpenInAppTestSeriesPageDeeplink(testSeriesId) {
  return 'https://link.testbook.com/8BwcPOvA3ib?$deeplink_path=testbook://tbapp/testseries/' + testSeriesId;
}
export function getOpenInAppSlashPageDeeplink() {
  return 'https://link.testbook.com/jfCQLoTyulb';
}
export function getMasterClassDeepLink(masterClassId,lessonId,masterClassName){
  return `https://link.testbook.com/vw3FANbVwkb?$deeplink_path=testbook://tbapp/masterclass/${masterClassId}/${lessonId}/${masterClassName}`;
}
export function getPlaystoreUrl(packageName='com.testbook.tbapp') {
  return `https://play.google.com/store/apps/details?id=${packageName}`
}
export function getMultiAppJsonUrl(){
  return 'https://cdn.testbook.com/global_resources/multi-app.json';
}
export function getOpenInAppTestUrlPageDeeplink(testId,linkType){
  if(linkType == 'oldLink'){
    return 'https://testbook.app.link/Dh6nfer1M7?$deeplink_path=testbook://tbapp/test/' + testId;
  }else{
     return 'https://link.testbook.com/QDkojnLkdsb?$deeplink_path=testbook://tbapp/test/' + testId;
  }
  
}
export function getOpenInAppLiveClassPageDeeplink(superGroupId){
  return 'https://link.testbook.com/thcjGYvfcsb?$deeplink_path=testbook://tbapp/masterclass_supergroup/' + superGroupId;
}
export function getAppInstallPopupPageDeeplink(trackingInfo, id, deeplinkPath){
  return 'https://link.testbook.com/' + trackingInfo + '?$deeplink_path='+ deeplinkPath + id;
}
