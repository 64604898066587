import { Validator as _ } from "@shared/utility/validator";
import {baseModel, setupModel} from "@models/types";
import {arrayModel, propertyMaps} from "@models/mapping-framework";
import {Network} from "@core/services/model-manager/main";
import {getLocalStorage, LS_KEY_FOR_SEARCH_HISTORY, setLocalStorage} from "@shared/utility/tb-common";
import {Observable} from "rxjs";

export class _SearchSuggestion extends baseModel{

    @propertyMaps()
    public query;

    @propertyMaps()
    public timestamp;

}
setupModel(_SearchSuggestion,{type:'search'});

export class SearchSuggestion extends baseModel{

    @propertyMaps('query',_.str)
    public query;

    @propertyMaps('timestamp',_.str)
    public timestamp;

}
setupModel(SearchSuggestion,{type: 'search'});

export class SearchSuggestions extends baseModel {
    @propertyMaps('suggestions', arrayModel(SearchSuggestion), SearchSuggestion)
    public suggestions;
}
setupModel(SearchSuggestions, {type: 'search'});

export class StoreSearchSuggestionParams extends baseModel {
    @propertyMaps()
    public query;

    @propertyMaps()
    public sid;
}
setupModel(StoreSearchSuggestionParams, {type: 'search'});

export class GetSearchSuggestionsAPI{
    // ToDo: implement actual api implementation
    // Implementing with LS instead
    static apiEndpoint = 'NA';



    // static  __projection = JSON.stringify(new SourceProjectionMapper(SearchSuggestion).map());

    static apiCall(network:Network,params:any){
        return new Observable((subscriber) => {
            if(typeof localStorage !== "undefined") {
                let lsSuggestions = getLocalStorage(LS_KEY_FOR_SEARCH_HISTORY + params.sid);
                if(lsSuggestions) {
                    subscriber.next({
                        data: {
                            suggestions: JSON.parse(lsSuggestions)
                        },
                        success: true
                    });
                    subscriber.complete();
                    return;
                }
            }
            subscriber.next({
                data: {},
                success: false
            });
            subscriber.complete();
        });
    }
}

export class StoreSearchSuggestionsAPI {

    static apiCall(network: Network, params: any) {
        return new Observable((subscriber) => {
            if(typeof localStorage !== "undefined") {
                let lsSuggestions = JSON.parse(getLocalStorage(LS_KEY_FOR_SEARCH_HISTORY + params.sid) || '{}');

                var index = lsSuggestions.findIndex(e => e.query === params.query);
                if(index>-1){
                    lsSuggestions.splice(index,1);
                }
                if(lsSuggestions.length >= 100){
                    lsSuggestions.shift()
                }
                lsSuggestions.push({query: params.query,timestamp: Date.now()});
                setLocalStorage(LS_KEY_FOR_SEARCH_HISTORY+params.sid,JSON.stringify(lsSuggestions));
                subscriber.next({
                    success: true
                });
                subscriber.complete();
                return;
            }
            subscriber.next({
                success: false
            });
            subscriber.complete();
        })
    }
}
