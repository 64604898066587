import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from "@ngrx/store";
import {getPageSeo, getSeoGroupSubFooter} from "@core/application-state/app.actions";
import {selecSeoGroupSubFooter} from "@core/application-state/app.selectors";
import {PlatformService} from "@core/services/platform-service";
import {isServer} from "@shared/utility/tb-common";
import {makeStateKey} from "@angular/platform-browser";
import {PAGE_SEO_SUBFOOTER, SEO_GROUP_SUBFOOTER} from "@shared/utility/seo-utilities";
import { Subscription } from 'rxjs';
import { TransferStateManager } from '@angular-commons/core/services/transferStateManager';

@Component({
  selector: 'seo-sub-footer',
  templateUrl: './sub-footer.component.html',
  styleUrls: ['./sub-footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubFooterComponent implements OnInit {

  isServer = isServer();
  params:any;
  action:any;
  subFooterData:any;
  subFooterSub$:Subscription;
  isSubFooterPresent: boolean = false;
  currentPathName:any = this.platformService.getPathName();
  selecSeoGroupSubFooter$ = this.store.select(selecSeoGroupSubFooter);
  subFooterKey :any= makeStateKey(this.currentPathName +'_subFooter');

  constructor(private store: Store<{}>, private platformService: PlatformService,private transferStateManager : TransferStateManager) {}

  ngOnInit() {}

  ngAfterViewInit(){
    this.selecSeoGroupSubFooter$.subscribe((subFooter)=>{
      if (!subFooter.isSubFooterLoaded && subFooter.type) {
        this.isSubFooterPresent=false;
        this.currentPathName = this.platformService.getPathName();
        switch (subFooter.type) {
          case SEO_GROUP_SUBFOOTER:
            if(subFooter?.id) {
              this.action=getSeoGroupSubFooter;
              this.params={targetId: subFooter.id, pathname: this.currentPathName};
              this.subFooterDatas();
            }
            break;
          case PAGE_SEO_SUBFOOTER:
            this.action= getPageSeo;
            this.params={pathname: this.currentPathName};
            this.subFooterDatas();
            break;
          default:
            this.action= getPageSeo;
            this.params={pathname: this.currentPathName};
            this.subFooterDatas();
            break;
        }
      }
      else if(subFooter.isSubFooterLoaded && subFooter?.data?.length > 0 && !this.isSubFooterPresent) {
        this.isSubFooterPresent = true;
        this.subFooterData = subFooter.data;
      }else{
        this.isSubFooterPresent = false;
        this.subFooterData = [];
      }
    });
  }

  subFooterDatas(){
    this.subFooterKey = makeStateKey(this.currentPathName +'_subFooter');
    if(this.action && Object.keys(this.params).length > 0 ){
      this.subFooterSub$ = this.transferStateManager.get(this.subFooterKey,
        this.action(this.params),
        this.selecSeoGroupSubFooter$,(subFooter)=>{
          if(subFooter?.data?.length > 0 && !this.isSubFooterPresent){
            this.isSubFooterPresent = true;
            this.subFooterData = subFooter.data;
          }
        } 
      );
    }
  }

  getNthElements(arr, n, offset) {
    let resultArr = [];
    for(let i = offset; i < arr.length; i+=n) {
      if (arr[i].link !== this.currentPathName) {
        resultArr.push(arr[i]);
      }
    }
    return resultArr;
  }

}
