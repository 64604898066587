import {Component, Input, OnInit} from '@angular/core';
import {PlatformService} from "@core/services/platform-service";
import {Store} from "@ngrx/store";
import {selectStudent} from "@core/application-state/app.selectors";

@Component({
  selector: 'pass-pitch',
  templateUrl: './pass-pitch.component.html',
  styleUrls: ['./pass-pitch.component.scss']
})
export class PassPitchComponent implements OnInit {
  @Input() showPassPitch: boolean = false;
  isPassUnPurchased: boolean = true;
  isLoggedIn = this.platformService.isLoggedIn();

  constructor(private platformService: PlatformService, private store: Store) { }

  ngOnInit(): void {
    this.checkPassPurchased();
  }
  checkPassPurchased() {
    this.store.select(selectStudent).subscribe(data => {
        this.isPassUnPurchased = (data?.globalPassState !== undefined) ? data?.globalPassState?.passUnpurchased : true;
    });
  }
}
