import { createReducer, on } from '@ngrx/store';
import {
    callbackStatusLoaded,
    createLeadsSuccess,
    getLeadsDataSuccess,
    requestCallbackSuccess
} from './request-callback.actions';
export interface leadMeta {
    mobile: string;
    goalId: string;
    submitted: boolean;
}
export const initialState = {
    requestData: {},
    leadsData: {},
    bannerMeta: {
        mobile: "",
        classId:"",
        submitted: false,
    },
    leadMeta: {}
};

const _requestCallbackReducer = createReducer(initialState,
    on(callbackStatusLoaded, (state,{payload}) => {
        let nextState = {...state}
        nextState.requestData = {...payload};
        return nextState;
    }),
    on(requestCallbackSuccess, (_state,{mobile, classId, submitted}) => {
        let state = {..._state}
        state.bannerMeta = {mobile, classId, submitted};
        return {...state};
    }),
    on(createLeadsSuccess, (_state, {mobile, goalId, submitted}) => {
        let nextState = {..._state};
        nextState.leadMeta = {
            ...nextState.leadMeta,
            [goalId]: {mobile, goalId, submitted}
        };
        return nextState;
    }),
    on(getLeadsDataSuccess, (_state, { payload}) => {
        let nextState = {..._state};
        nextState.leadsData = payload;
        return nextState;
    }),
);


export function requestCallbackReducer(state, action) {
    return _requestCallbackReducer(state, action);
}