import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BannerMeta } from '../../features/request-callback/request-callback.component';
import { Validator as _ } from "@shared/utility/validator";
import { Store } from '@ngrx/store';
import { showAlert } from '@angular-commons/core/application-state/app.actions';
import { isServer } from '@angular-commons/shared/utility/tb-common';

@Component({
  selector: 'assistance-about-course',
  templateUrl: './assistance-about-course.component.html',
  styleUrls: ['./assistance-about-course.component.scss']
})
export class AssistanceAboutCourseComponent implements OnInit {

  @Input() bannerMeta: BannerMeta;
  @Input() coldbootParams;
  mobile: string;
  coldbootsub:any;
  isServer =  isServer();
  @Output() requestCallbackEvent:EventEmitter<string> = new EventEmitter();

  constructor(private store: Store<{}>) { }

  ngOnInit(): void {
    this.mobile = this.bannerMeta.mobile;

    if (!this.isServer) {
      this.coldbootsub = this.coldbootParams && this.coldbootParams.then((params)=>{
        switch(params.awaitId){
          case "changenumbercta":
            this.changeNumber();
            break;
          case "requestcallbackcta":
            this.requestCallback();
            break;
        }
      })
    }
  }

  requestCallback() {
    if (!_.isAValidMobile(this.mobile)) {
      this.store.dispatch(showAlert({message:'Invalid Number! Please enter 10 digit mobile number starting with 6,7,8 or 9 only'}))
      return;
    }
    this.requestCallbackEvent.emit(this.mobile);
  }

  changeNumber() {
    this.bannerMeta.submitted = false;
  }
}
