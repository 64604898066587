import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, switchMap} from "rxjs/operators";
import {EMPTY, of} from "rxjs";
import {DataRecipe} from "@core/services/model-manager/recipe";
import {ModelManager} from "@core/services/model-manager.service";
import { EmiMandate, StudentPartialEmi, emiMandateApi, StudentMandateGuideLines, studentPartialEmiApi } from "@angular-commons/models/entity/emi/emi.adapter";
import { getEmiMandateData, getEmiMandateDataLoaded, getMandateGuideLines, getMandateGuideLinesLoaded, getStudentPartialEmi, getStudentPartialEmiLoaded, setErrorMessage } from "./emi-mandate.actions";
import { IdentityAdapter } from "@angular-commons/models/mapping-framework";
import { commonDataApi } from "@angular-commons/models/entity/common-data/common-data.adapter";


@Injectable()
export class EmiMandateEffects {
    emiMandateRecipe: DataRecipe;
    studentPartialEmiRecipe: DataRecipe;
    mandateGuideLines: DataRecipe;

    emiMandateRecipe$ = createEffect(()=> this.action$.pipe(
        ofType(getEmiMandateData),
        switchMap((action) => {
            return this.emiMandateRecipe.get(action).pipe(
                map((data: EmiMandate) => getEmiMandateDataLoaded({ payload: data.mandateDetail || {} })),
                catchError((error) => of(setErrorMessage({message: error?.error?.message || 'Something went wrong, Please try again!'})))
            );
        })
    ));

    studentPartialEmiRecipe$ = createEffect(()=> this.action$.pipe(
        ofType(getStudentPartialEmi),
        switchMap((action) => {
            return this.studentPartialEmiRecipe.get(action).pipe(
                map((data: StudentPartialEmi) => getStudentPartialEmiLoaded({ payload: data.emis || [] })),
                catchError((error) => of(setErrorMessage({message: error?.error?.message || 'Something went wrong, Please try again!'})))
            );
        })
    ));

    mandateGuideLines$ = createEffect(() => this.action$.pipe(
        ofType(getMandateGuideLines),
        switchMap((action) => {
            return this.mandateGuideLines.get(action).pipe(
                map((data: any) => getMandateGuideLinesLoaded({ payload: data })),
                catchError((error) => of(setErrorMessage({message: error?.error?.message || 'Something went wrong, Please try again!'})))
            );
        })
    ));

    constructor(private modelManager: ModelManager, private action$: Actions) {
        this.emiMandateRecipe = this.modelManager.makeDataRecipe(IdentityAdapter, EmiMandate, emiMandateApi);
        this.studentPartialEmiRecipe = this.modelManager.makeDataRecipe(IdentityAdapter, StudentPartialEmi, studentPartialEmiApi);
        this.mandateGuideLines = this.modelManager.makeDataRecipe(IdentityAdapter, StudentMandateGuideLines, commonDataApi);
    }
}
