import {NgModule, SkipSelf} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from "@angular/forms";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {CoreModule} from "@core/core.module";
import {HttpClient} from "@angular/common/http";
import { TransferState } from '@angular/platform-browser';
import {customTranslateLoader, langChangeListener} from "@shared/utility/translation-loaders";
import {PlatformService} from "@core/services/platform-service";
import {TbcoreService} from "@core/services/tbcore.service";
import { MobileSignupAndVerificationComponent } from './mobile-signup-and-verification.component';

export function translateLoaderFactory(http: HttpClient, transferState: TransferState) {
  return new customTranslateLoader(http, transferState, 'global');
}

@NgModule({
  declarations: [MobileSignupAndVerificationComponent],
  imports: [
    CommonModule,
    FormsModule,
    CoreModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient, [new SkipSelf(), TransferState]]
      },
      extend: true
    })
  ],
  exports: [MobileSignupAndVerificationComponent]
})
export class MobileSignupAndVerificationModule {
  constructor(private translateService: TranslateService, private tbcore: TbcoreService, private platformService: PlatformService) {
    langChangeListener(this.translateService,this.tbcore, this.platformService);
  }
}
