export interface IDeferPromise{
    promise: Promise<any> ;
    resolve: (value?: any) => void ;
    reject: (value?: any) => void ;
}

export function getDeferredPromise(): IDeferPromise{
    let promise, $resolve, $reject;
    promise = new Promise<any>( (resolve, reject) => { $resolve = resolve; $reject = reject; } );
    // catch with no op to avoid browser's complaints(Uncaught promise error)
    promise = promise.catch(() => {});
    return { promise , resolve: $resolve , reject: $reject };
}
