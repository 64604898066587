import {Component, ElementRef, HostListener, Inject, OnInit, Renderer2} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Store} from "@ngrx/store";
import {
  selectDeletedNotificationId,
  selectJustLoggedIn,
  selectNotifications,
  selectNotificationsCount,
  selectStudent
} from "@core/application-state/app.selectors";
import {
  deleteNotification,
  getNotifications,
  getNotificationsCount,
  justLoggedIn
} from "@core/application-state/app.actions";
import {PlatformService} from "@core/services/platform-service";


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  unseenCount: number = 0;
  notifCount: number = 0;

  showNotifs: boolean;
  loading: boolean = true;

  sid: string = '';

  skip = 0;
  limit = 3;

  student$ = this.store.select(selectStudent);

  notifications: Array<any> = [];

  notificationsCount$ = this.store.select(selectNotificationsCount);
  notifications$ = this.store.select(selectNotifications);
  selectDeletedNotificationId$ = this.store.select(selectDeletedNotificationId);

  handler = () => {};

  clickout(event) {
    // Handling click event outside this component
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.showNotifs = false;
    }
  }

  constructor(private translateService: TranslateService, private store: Store<{}>, private eRef: ElementRef, private renderer: Renderer2, private platformService: PlatformService) {

    store.select(selectJustLoggedIn).subscribe(data => {
      if(data) {
        this.init();
      }
    });

    this.notificationsCount$.subscribe(data => {
      if(data) {
        this.notifCount = data.total || 0;
        this.unseenCount = data.unseen || 0;
      }
    });

    this.notifications$.subscribe(data => {
      if(data && data.length > 0) {
        this.notifications.push(...data);
      }
      this.loading = false;
    });

    this.selectDeletedNotificationId$.subscribe(data => {
        this.notifications = this.notifications.filter(item => item.id != data);
    });

  }

  init() {
    if(this.platformService.isLoggedIn()) {
      this.student$.subscribe(data => {
        if (data.id && data.id !== '-1') {
          this.sid = data.id;
          this.store.dispatch(getNotificationsCount({sid: data.id}))
        }
      });
    }
  }

  ngOnInit() {
    this.init();
  }

  showNotifications() {
    if(this.sid) {
      if(!this.showNotifs) {
        this.handler = this.renderer.listen(document, "click", (event) => this.clickout(event))
      }
      else {
        this.handler();
      }
      this.showNotifs = !this.showNotifs;
      if(this.showNotifs && this.notifications.length === 0 && this.platformService.isLoggedIn()) {
        this.loadMoreNotifs();
      }
    }
    else {
      console.error('SID not found to show notifications');
    }
  }

  loadMoreNotifs (event?) {
    this.store.dispatch(getNotifications({
      sid: this.sid,
      skip: this.skip,
      limit: this.limit
    }));

    this.skip += this.limit;
    if(event) {
      event.stopPropagation();
    }
  }

  cardClick(notification, event) {
    event.stopPropagation();
    if(notification?.actionWeb) {
      this.platformService.redirect(notification?.actionWeb)
    }
  }

  closeNotification(_notification, event) {
    let notification = {..._notification}
    notification.removed = true;
    setTimeout(() => {
      this.store.dispatch(deleteNotification({sid: this.sid, activities: notification.activityIds, id: notification.id}));
    }, 500);

    event.stopPropagation();

  }

  buttonClick(action) {

  }

}
