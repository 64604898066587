<div class="modal hero-modal show" *ngIf="showCouponModal  && couponModalType === 'default' && !showCouponText" tabindex="-1" [ngClass]="{'modal--iframe': isInIframe}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header" translate>
                <img src="/assets/img/arrows/chevron-left.svg" width="25" height="25" style="top: 0px; position: relative;left: -10px;" (click)="closeModal()" />
                Apply Offer
            </div>
            <div class="modal-body">
                <div class="coupon-apply-input">
                    <svg  width="14" height="14" viewBox="0 0 20 20">
                        <path d="M14.7 13.3c.9-1.2 1.4-2.6 1.4-4.2 0-3.9-3.1-7.1-7-7.1S2 5.2 2 9.1c0 3.9 3.2 7.1 7.1 7.1 1.6 0 3.1-.5 4.2-1.4l3 3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-3-3.1zm-5.6.8c-2.8 0-5.1-2.2-5.1-5S6.3 4 9.1 4s5.1 2.3 5.1 5.1-2.3 5-5.1 5z" fill="#86A1AE" fill-rule="evenodd"/>
                    </svg>
                    <input type="text" placeholder="{{'Enter Coupon Code'}}" [(ngModel)]="couponcode" (keyup.enter)="applyCoupon()">
                    <button (click)="applyCoupon()">
                        <span *ngIf="state !== 'isLoading'" class="uppercase" translate>Apply</span>
                        <img *ngIf="state === 'isLoading'" class="loader-img" src="./assets/img/arrows/reload-white.svg" alt="reload">
                    </button>
                </div>
                <div *ngIf="state === 'invalid'" class="coupon-invalid" translate>Invalid Coupon. Please try again!</div>
                <div class="coupon-offers_state">
                    <p class="coupon-offers-title" style="padding-top: 15px">
                       <span *ngIf="!isLoading && coupons.length > 0" class="uppercase" translate>Available Coupons</span>
                    </p>
                    <div class="coupon-offers-card" *ngFor="let coupon of coupons">
                        <div class="coupon-offers-header">
                            <img loading="lazy" [src]="coupon?.image" alt="offer-img">
                        </div>
                        <div class="coupon-offers-body">
                            <h2>{{coupon?.shortDesc}}</h2>
                            <span>{{coupon?.longDesc}}</span>
                        </div>
                        <div class="coupon-offers-footer">
                            <div class="coupon-offers-code">Coupon Code: <span>{{coupon?.code}}</span></div>
                            <a href="javascript:void(0)" (click)="applyCoupon(coupon)" translate>Apply</a>
                        </div>
                    </div>
                    <div class="coupon-not-available" *ngIf="!isLoading && coupons.length === 0" translate>
                        No Coupons Available
                    </div>
                    <div class="coupon-loading-state" *ngIf="isLoading || onServer">
                        <img src = "./assets/img/utility/loader.gif">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop show" (click)="closeModal()"></div>
</div>

<div class="modal modal-lg modal-center modal-coupon show" *ngIf="showCouponModal && couponModalType === 'center' &&
!showCouponText"  [ngClass]="{'modal--coaching':passType==='goalSubs'}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div (click)="closeModal()" class="modal-icon">
                <span class="modal-close"></span>
            </div>
            <div class="modal-body">
                <span (click)="closeModal()" class="modal-arrow"></span>
                <img loading="lazy" [src]="'/assets/img/pass-new/logo_pass.svg'" *ngIf="passType==='globalPass'" alt="logo" width="134" height="32">
                <img loading="lazy" [src]="'/assets/img/super/super-coaching.svg?v=2'" *ngIf="passType==='goalSubs'" alt="logo" width="134" height="32">
                <h3 translate *ngIf="passType==='globalPass'">ONE_PASS_TO_ACE</h3>
                <h3 translate *ngIf="passType==='goalSubs'">Complete Coaching with India’s Top Faculty</h3>
                <div class="coupon-apply-input">
                    <input type="text" placeholder="{{'Enter Coupon Code Here'}}" [(ngModel)]="couponcode" (keyup.enter)="applyCoupon()">
                    <button (click)="applyCoupon()" class="btn">
                        <span *ngIf="state !== 'isLoading'" translate>Apply Coupon</span>
                        <img *ngIf="state === 'isLoading'" class="loader-img" src="./assets/img/arrows/reload-white.svg">
                    </button>
                </div>
                <div *ngIf="state === 'invalid'" class="coupon-invalid" translate>Invalid Coupon. Please try again!</div>
                <div class="coupon-offers_state">
                    <p class="coupon-offers-title">
                        <span *ngIf="!isLoading && coupons.length > 0" class="uppercase" translate>Available Offers</span>
                     </p>
                    <div class="coupon-offers-card" *ngFor="let coupon of coupons">
                        <div class="coupon-offers-header">
                            <div class="coupon-offers-code">
                                <img loading="lazy" [src]="coupon?.image" alt="offer-img" height="25" width="25">
                                <span>{{coupon?.code}}</span>
                            </div>
                        </div>
                        <div class="coupon-offers-body">
                            <div>
                                <h2>{{coupon?.shortDesc}}</h2>
                                <span>{{coupon?.longDesc}}</span>
                            </div>
                            <button class="btn btn-primary" (click)="applyCoupon(coupon)" translate>Apply</button>
                        </div>
                    </div>
                    <div class="coupon-not-available" *ngIf="!isLoading && coupons.length === 0" translate>
                        <img loading="lazy" [src]="'/assets/img/pass-new/no-coupon.svg'" alt="no-coupon-icon" height="148" width="145">
                        <p>No Coupons Available!</p>
                        <p class="text-light-sm">We’re sorry, but coupons are not available at this time. Please check back soon for new exciting offers.</p>
                    </div>
                    <div class="coupon-loading-state" *ngIf="isLoading || onServer">
                        <img loading="lazy" [src]="'./assets/img/utility/loader.gif'" alt="loader">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop show" (click)="closeModal()"></div>
</div>

<div *ngIf="onPage && !showSkeleton"  tabindex="-1" [ngClass]="{'modal-view':isModel}">
    <div class="coupon-offers_state {{cssModifierClass}}">
        <div class="coupon-offers-title" *ngIf="!isModel && !showCouponText">
            <span translate>Special Offers for You!</span>
            <div class="text-link" (click)="openModal()">Apply Coupon</div>
        </div>
        <div class="coupon-card-wrapper"
             [ngClass]="{'offer-goal-pass': !isModel,'goal-pass-more':!isModel}">
            <div class="coupon-offers-card" [ngClass]="{'coupon-offers-card--applied':coupon?.code === getAppliedCoupon()?.code,'coupon-offers-card--game': coupon?.medium?.type === 'customerGlu' && coupon?.code === getAppliedCoupon()?.code}"
                 *ngFor="let coupon of coupons, let i = index" (click)="cardClick(coupon)" >
                <div class="coupon-offers-header" *ngIf="!showCouponText">
                        <img loading="lazy" [src]="coupon?.image" alt="offer-img">
                        <span class="h2">{{coupon?.shortDesc}}</span>
                        <span *ngIf="isModel" class="coupon-description">{{coupon?.longDesc}}</span>
                </div>
                <div class="coupon-offers-body" *ngIf="!isModel && !showCouponText">
                    <span>{{coupon?.longDesc}}</span>
                </div>
                <div class="coupon-offers-footer" *ngIf="!showCouponText">
                    <div class="text-link remove" translate> Remove </div>
                    <div class="text-link apply" translate> Apply</div>
                </div>
                <div class="course-offers-best-offer" *ngIf="showCouponText">
                    <div class="course-offers-discount">
                        <strong class="badge-offer">Best Offer</strong>
                        <strong class="offer-text">{{coupon?.shortDesc}}</strong>
                    </div>
                    <div class="text-link remove" translate> Remove </div>
                    <div class="text-link apply" translate> Apply</div>
                </div>
                <div *ngIf="i==0 && !isModel && !showCouponText" class="best-coupon-offer">Best Coupon</div>
            </div>
        </div>
    </div>
</div>
<div class="skeleton" *ngIf="showSkeleton">
    <div class="coupon-offers-title" *ngIf="!isModel && !showCouponText">
        <span translate>Special Offers for You!</span>
        <div class="text-link" (click)="openModal()">Apply Coupon</div>
    </div>
    <div>
        <div class="coupon-offers-card coupon-offers-card--applied" style="border-width: 2px; height: 83px"
             *ngFor="let coupon of [1], let i = index">
            <div class="coupon-offers-header" *ngIf="!showCouponText">
                <img src="/assets/img/super/super-img-logo.svg" alt="super-img">
                <span class="sk-t sk-anim">Short Description JFGiaudhfsdjk</span>
            </div>
            <div class="coupon-offers-body">
                <span class="sk-t sk-anim">Long Description</span>
            </div>
        </div>
    </div>
</div>


<div class="offer-goal-pass offer-goal-pass--no-offers" *ngIf="onPage && !coupons.length && !isModel">
    <div class="coupon-offers-card" *ngIf="!isLoggedIn" (click)="login()">
        <div class="coupon-offers-header">
            <img src="/assets/img/super/super-img-logo.svg" alt="super-img">
            <span class="h2">Exciting offers available</span>
        </div>
        <div class="coupon-offers-body">
            <span>Grab the best deals now, Hurry up!</span>
        </div>
        <div class="coupon-offers-footer">
            <div class="text-link apply">Login For Offers</div>
        </div>
    </div>
</div>

