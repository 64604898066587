import { createAction, props } from "@ngrx/store";

export const getMasterSeriesPageDetails = createAction('[Master Series] Get Master Series Details',props<{id:string}>());

export const masterSeriesPageDetailsLoaded = createAction('[Master Series] Get Master Series Details Success',props<{payload:any}>());

export const getMasterSeriesLessons = createAction('[Master Series] Get Master Series Lessons',props<{id:string, lessonType:string, skip?:number, limit?:number}>());

export const masterSeriesLessonsLoaded = createAction('[Master Series] Get Master Series Lessons Success',props<{payload:any, lessonType:string, masterSeriesId:string, skip?:number, limit?:number}>());

export const getMasterSeriesInstructors = createAction('[Master Series] Get Master Series Instructors',props<{id:string}>());

export const masterSeriesInstructorsLoaded = createAction('[Master Series] Get Master Series Instructors Success',props<{payload:any}>());

export const getMasterSeriesPitch = createAction('[Master Series] Get Master Series Pitch',props<{id:string}>());

export const masterSeriesPitchLoaded = createAction('[Master Series] Get Master Series Pitch Success',props<{payload:any}>());

export const setMasterSeriesEnrollmentStatus = createAction('[Master Series] setMasterSeriesEnrollmentStatus',props<{status:boolean,checkType:string}>())

export const getLessonData=createAction('[Master Series] getLessonData',props<{lessonId:string,parentId:string,parentType:string}>())
export const lessonDataLoaded=createAction('[Master Series] lessonDataLoaded',props<{payload:any}>())



