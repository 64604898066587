import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    ActivatedRoute,
    UrlTree
} from '@angular/router';
import {Observable, merge} from "rxjs";
import {Store} from "@ngrx/store";
import {map} from "rxjs/operators";


import {PlatformService} from "@core/services/platform-service";
import {isServer} from "@shared/utility/tb-common";
import { skipInitialState } from '@angular-commons/shared/utility/ngrx-utils';
import { getCourse } from '@angular-commons/feature-modules/course-individual/feature-state/course-individual.actions';
import { selectCourse, selectCourseError } from '@angular-commons/feature-modules/course-individual/feature-state/course-individual.selector';


@Injectable()
export class SelectPageGuard implements CanActivate {

    constructor(
        private router:Router, 
        private route: ActivatedRoute, 
        private store: Store<{}>,
        private platformService: PlatformService
        ) {}

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean|UrlTree> {
            

        let slug1 = route.params.slug1;
        let slug2 = route.params.slug2;
       
        this.store.dispatch(getCourse({params: {prefix: slug1, suffix: slug2}}));

        let course$ = this.store.select(selectCourse)
            .pipe(skipInitialState(), map((course) => {

                if (!(course && course.id)) {
                    this.handleError();
                    return false;
                }
                return true;
            }));
        
        let courseError$ =  this.store.select(selectCourseError)
            .pipe(skipInitialState(), map(() => {

                this.handleError();
                return false;

            }));

        return merge(course$, courseError$)

    }

    handleError() {
        this.platformService.gotoErrorPage();
    }

}
