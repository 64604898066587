import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentService } from '@angular-commons/core/services/payment.service';
import { EmiPaymentParams } from '@angular-commons/shared/utility/payment-utilities';
import { Store } from '@ngrx/store';
import { selectEmisDetails } from './feature-state/down-payment.selector';
import {isServer} from "@shared/utility/tb-common";
import { getEmiDetails } from './feature-state/down-payment.action';
import {TransferStateManager} from "@core/services/transferStateManager";
import { ActivatedRoute } from '@angular/router';
import { StudentService } from '@angular-commons/core/services/student.service';
import { PlatformService } from '@angular-commons/core/services/platform-service';
import { GAEventService } from '@angular-commons/core/services/ga-event.service';

@Component({
  selector: 'down-payment-popup',
  templateUrl: './down-payment-popup.component.html',
  styleUrls: ['./down-payment-popup.component.scss']
})
export class DownPaymentPopupComponent implements OnInit {

  @Input() coupon:string = '';
  @Input() products:string = '';
  @Input() planId:string = '';
  @Input() productId:string = '';
  @Input() productName:string = '';
  @Input() module:string = '';
  @Input() redirectUrl:string = '';
  @Input() passId:string = '';
  @Input() passTitle:string = '';
  @Input() passType:string = '';
  @Input() goalId:string = '';
  @Input() goalTitle:string = '';
  @Input() goalSlug:string = '';
  @Output() closeModal = new EventEmitter<any>();

  emiDetails: Array<any> = [];
  emiDetails$ = this.store.select(selectEmisDetails);
  emiDetailsTransferState$ : any;
  isLoggedIn:boolean = this.platformService.isLoggedIn();
  isServer:boolean = isServer();
  student:any = {};
  isLoading:boolean = true;
  dealAmount: number = 0;

  constructor(private paymentService: PaymentService,
    private platformService: PlatformService,
    private store: Store<any>, 
    private studentService: StudentService,
    private transferStateManager: TransferStateManager, 
    private activatedRoute: ActivatedRoute,
    private gaEventService: GAEventService) { }

  ngOnInit(): void {
    if(this.isLoggedIn && !this.isServer){
      this.studentService.loadStudent(true).then((student)=>{
        this.student = student;
      });
    }

    const queryParams = this.activatedRoute.snapshot.queryParams;
    const params = {
      productId : queryParams['productId'] || '',
      planId : queryParams['planId'] || '',
      couponCode : queryParams['coupon'] || ''
    }

    if(!this.isServer){
      this.emiDetailsTransferState$ = this.transferStateManager.get('emiDetails', getEmiDetails(params), this.emiDetails$, (value)=>{
        if(value?.isLoaded && value?.payments?.length) {
          this.emiDetails = value.payments;
          this.dealAmount = this.emiDetails.reduce((total, curr) => total+(curr?.amountToPay || 0), 0);
          this.fireDownPaymentPopupViewedEvent();
        }
        this.isLoading = !value?.isLoaded;
      });
    }
  }

  payDownPaymentEMI(){
    let emiPaymentParams:EmiPaymentParams = {
      coupon: this.coupon || "",
      paymentGateway: 0,
      products: this.productId || "",
      emiPlanId: this.planId || "",
      productId: this.productId || "",
      productName: this.productName || "",
      sId: this.student?.id || ""
    }

    let extraObj:any = {
      module: this?.module || "",
      passInfo: {
        passType: this?.passType || "",
        pass: {
          id: this?.productId || "",
          title: this?.productName || "",
          type: this?.passType,
          emiPlanPrice: this.emiDetails?.[0]?.amountToPay,
          payableAmount: this.dealAmount
        },
        goal: {
          id: this?.goalId || "",
          slug: this?.goalSlug || "",
          title: this?.goalTitle || ""
        }
      }
    }

    if(this.redirectUrl){
      extraObj['redirect_url'] = this.redirectUrl;
    }

    this.paymentService.emiInitiatePayment(emiPaymentParams, extraObj);
  }

  handleCloseModal(){
    this.fireDownPaymentPopupViewedEvent(true);
    this.closeModal.emit();
  }

  fireDownPaymentPopupViewedEvent(isClosed=false) {
    const eventInfo = this.getEventData();
    if(isClosed) {
      const {clickText, ...closedEventInfo} = eventInfo;
      this.gaEventService.sendDownPaymentPopupClosedEvent(closedEventInfo);
    }
    else {
      this.gaEventService.sendDownPaymentPopupViewEvent(eventInfo);
    }
  }

  getEventData() {
    return {
      goalID: this.goalId || '',
      goalName: this.goalTitle || '',
      productID: this.productId || '',
      productName: this.productName || '',
      emiPlanPrice: this.emiDetails?.[0]?.amountToPay || 0,
      payableAmount: this.dealAmount,
      clickText: `Pay Down Payment - ₹${this.emiDetails?.[0]?.amountToPay}`
    };
  }
}