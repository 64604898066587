import { arrayModel, objModel, pipeModel, propertyMaps, SourceProjectionMapper } from '@angular-commons/models/mapping-framework';
import {Network} from '@core/services/model-manager/main';
import {baseModel, setupModel} from "@models/types";
import { Validator as _ } from "../../../shared/utility/validator";
import { ChapterModel, processChapters } from '../study-tabs/chapter.adapter';
import {mapTestSeriesArr, processTestSeriesArr, TestSeries} from "@models/entity/test-series/test-series.adapter";
import { NotesModel } from '../study-tabs/study-notes.adapter';
import { PracticesModel } from '../study-tabs/practice.adapter';
import {_Target} from "@models/entity/targets/target.adapter";

export class Results extends baseModel {
    @propertyMaps('studyTabChapter', pipeModel(arrayModel(ChapterModel), processChapters), ChapterModel)
    public chapters;

    @propertyMaps('studyTabNote', arrayModel(NotesModel), NotesModel)
    public notes;

    @propertyMaps('studyTabPractices', arrayModel(PracticesModel), PracticesModel)
    public practices;

    @propertyMaps('testSeries', pipeModel(arrayModel(TestSeries), processTestSeriesArr, mapTestSeriesArr), TestSeries)
    public testSeries;

    @propertyMaps('targets', pipeModel(arrayModel(_Target)), _Target)
    public targets;
}
setupModel(Results, {});

export class SearchModel extends baseModel {
    
    @propertyMaps('searchId', _.str)
    public searchId;

    @propertyMaps('results', objModel(Results), Results)
    public results;

    @propertyMaps('extraDetails', _.obj)
    public extraDetails;

    @propertyMaps('totalCount', _.obj)
    public totalCount;

    @propertyMaps('filters', _.obj)
    public filters;
}
setupModel(SearchModel,{});

export class IndividualSearchApi {
  static apiEndpoint = 'v1/search/individual';

  static projection;
  static get  __projection(){ 
    if(!IndividualSearchApi.projection){
        IndividualSearchApi.projection = JSON.stringify(new SourceProjectionMapper(SearchModel).map());;
    }
    return IndividualSearchApi.projection;
  }

  static apiCall(network: Network, params: any) {
    return network.get(IndividualSearchApi.apiEndpoint, {...params.searchParams, __projection: IndividualSearchApi.__projection});
  }
}
