import {createAction, props} from "@ngrx/store";
import {Course, GetClassesParams, GetCourseParams, PDF_TYPE, _CourseProduct, classes} from "@models/entity/classes/classes.adapter";
import { GetDemoEntitiesParams, RegisterDemoModuleParams, _DemoData } from "@angular-commons/models/entity/classes/demo.adapter";
import { _Subjects } from "@angular-commons/models/entity/classes/subjects.adapter";

export const getCourse = createAction('[CourseIndividual] Get Course By Slug', props<{ params: GetCourseParams}>());
export const courseLoaded = createAction('[CourseIndividual] Course Loaded', props<{payload: _CourseProduct}>());
export const restoreCourseLoaded = createAction('[CourseIndividual] Restore Course Loaded', props<{payload: Course}>());
export const errorInLoadingCourse = createAction('[CourseIndividual] Error in Loading Course', props<{payload: any}>());

export const getDemoData = createAction('[CourseIndividual] Get Demo Data', props<{params: GetDemoEntitiesParams}>());
export const demoDataLoaded = createAction('[CourseIndividual] Demo Data Loaded', props<{payload:any}>());

export const registerDemoModule = createAction('[CourseIndividual] Register Demo Module', props<{details: RegisterDemoModuleParams}>());
export const registerDemoModuleSuccess = createAction('[CourseIndividual] Register Demo Module Success', props<{response: any}>());

export const showCoursePriceInfoModal = createAction('[CourseIndividual] Show Course Price Info Modal');
export const hideCoursePriceInfoModal = createAction('[CourseIndividual] Hide Course Price Info Modal');

export const getSchedule = createAction('[CourseIndividual] getSchedule' , props<{classId:string,entityId?:string,subjectId?:string}>());
export const courseScheduleLoaded = createAction('[CourseIndividual] courseScheduleLoaded' , props<{payload:any}>());

export const getSubjects = createAction('[CourseIndividual] getSubjects', props<{classId: string}>());
export const subjectsLoaded = createAction('[CourseIndividual] Subjects Loaded', props<{payload: _Subjects}>());

export const getCourseProgress =  createAction('[CourseIndividual] getCourseProgress' , props<{classId:string,sid:string}>());
export const courseProgressLoaded =  createAction('[CourseIndividual] courseProgressLoaded' , props<{payload:any}>());

export const setSelectionsProofLead =createAction('[CourseIndividual] setSelectionsProofLead',props<{courseId:string,pdfType:PDF_TYPE}>())
export const setSelectionsProofLeadSuccess =createAction('[CourseIndividual] setSelectionsProofLeadSuccess')

export const enrollCourse = createAction('[app] Set enrollCourse',props<{payload:any,courseId:string}>());
export const enrollCourseSuccess = createAction('[app] enrollCourseSuccess');

export const getClassesSeoSkillData = createAction('[CourseIndividual] Get Classes Seo Skill Data By Slug', props<{ params: GetClassesParams}>());
export const classesSeoSkillDataLoaded = createAction('[CourseIndividual] Classes Seo Skill Data Loaded', props<{payload: classes}>());