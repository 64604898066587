import { Validator as _ } from "../../../shared/utility/validator";
import { baseModel ,setupModel } from "../../types";
import {propertyMaps, arrayModel, SourceProjectionMapper, objModel, pipeModel} from "../../mapping-framework";
import { Network } from '@core/services/model-manager/main';
import { map } from "rxjs/operators";

export class GenericSuccessResponse extends baseModel {
    @propertyMaps('success', _.bool)
    public success: boolean;
}
setupModel(GenericSuccessResponse, {});

export class RequestCallbackBody extends baseModel {
    @propertyMaps('mobile', _.num)
    public mobile;

    @propertyMaps('classId', _.str)
    public classId;

    @propertyMaps('utm', _.obj)
    public utm;

    @propertyMaps('action', _.str)
    public action;
}
setupModel(RequestCallbackBody, {});

export class RequestCallbackParams extends baseModel{
    @propertyMaps('body', objModel(RequestCallbackBody), RequestCallbackBody)
    public body;
}

setupModel(RequestCallbackParams, {});

export class PostContactDetails extends baseModel {
    @propertyMaps('pageId', _.str)
    public pageId;

    @propertyMaps('pageType', _.str)
    public pageType;

    @propertyMaps('sid', _.str)
    public sid;

    @propertyMaps('mobile', _.str)
    public mobile;
}
setupModel(PostContactDetails, {});

class _RequestInfo extends baseModel {

    @propertyMaps('id', _.str)
    public id;

    @propertyMaps('classId', _.str)
    public classId;

    @propertyMaps('mobile', _.str)
    public mobile;

    @propertyMaps('sid', _.str)
    public sid;

}
setupModel(_RequestInfo, {});

export class _CallbackData extends baseModel {

    @propertyMaps('requested', arrayModel(_RequestInfo), _RequestInfo)
    public requested;
}
setupModel(_CallbackData, {});

class RequestInfo extends baseModel {

    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('classId', _.str)
    public classId;

    @propertyMaps('mobile', _.str)
    public mobile;

    @propertyMaps('sid', _.str)
    public sid;

}
setupModel(RequestInfo, {});

export class CallbackData extends baseModel {

    @propertyMaps('requested', arrayModel(RequestInfo), RequestInfo)
    public requested;
}
setupModel(CallbackData, {});

export interface GetCallbackStatusParams {
    id: string;
}

class LeadInfo extends baseModel {

    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('goalId', _.str)
    public goalId;

    @propertyMaps('mobile', _.str)
    public mobile;

    @propertyMaps('sid', _.str)
    public sid;

    @propertyMaps('action', _.str)
    public action;

    @propertyMaps('on', _.str)
    public on;

}
setupModel(LeadInfo, {});

export class LeadsData extends baseModel {
    @propertyMaps('leads', arrayModel(LeadInfo), LeadInfo)
    public leads;
}
setupModel(LeadsData, {});


export class CreateLeadsBody extends baseModel {
    @propertyMaps('mobile', _.num)
    public mobile;

    @propertyMaps('action', _.str)
    public action;

    @propertyMaps('on', _.str)
    public on;

    @propertyMaps('goalId', _.str)
    public goalId;

    @propertyMaps('prodId', _.str)
    public prodId;

    @propertyMaps('prodType', _.str)
    public prodType;

    @propertyMaps('type', _.str)
    public type;

    @propertyMaps('client', _.str)
    public client;

    @propertyMaps('utm', _.obj)
    public utm;

    @propertyMaps('source', _.str)
    public source;

    @propertyMaps('prodName', _.str)
    public prodName;
}
setupModel(CreateLeadsBody, {});

export class CreateLeadsParams extends baseModel {
    @propertyMaps('body', objModel(CreateLeadsBody), CreateLeadsBody)
    public body;
}
setupModel(CreateLeadsParams, {});


export class GetCallbackStatusApi {
    static apiEndpoint = 'v1/classes/request-callback/';

    static projection;
    static get  __projection(){ 
        if(!GetCallbackStatusApi.projection){
            GetCallbackStatusApi.projection = JSON.stringify(new SourceProjectionMapper(CallbackData).map());
        }
        return GetCallbackStatusApi.projection;
    }

    static apiCall(network:Network,params:GetCallbackStatusParams) {
        return network.get(GetCallbackStatusApi.apiEndpoint + params.id,{__projection:GetCallbackStatusApi.__projection});
    }
}

export class RequestCallbackApi {
    static apiEndpoint = 'v1/classes/request-callback';

    static apiCall(network: Network, params: RequestCallbackParams) {
        return network.post(RequestCallbackApi.apiEndpoint, params.body).pipe(map(res => ({data: res})));
    }
}

export class GetLeadsDataApi {
    static apiEndpoint = 'v1/leads';

    static projection;
    static get  __projection(){ 
        if(!GetLeadsDataApi.projection){
            GetLeadsDataApi.projection = JSON.stringify(new SourceProjectionMapper(LeadsData).map());
        }
        return GetLeadsDataApi.projection;
    }

    static apiCall(network: Network, params) {
        return network.get(GetLeadsDataApi.apiEndpoint, { parentId: params.id, __projection: GetLeadsDataApi.__projection});
    }
}

export class CreateLeadsApi {
    static apiEndpoint = 'v1/leads';

    static apiCall(network: Network, params: CreateLeadsParams) {
        return network.post(CreateLeadsApi.apiEndpoint, params.body).pipe(map(res => ({data: res})));
    }
}

export class counsellingRequestCallbackApi {
    static apiEndpoint = 'v1/classes/request-callback';
    static apiCall(network: Network, params) {
        return network.post(counsellingRequestCallbackApi.apiEndpoint, params);
    }
}
