import {createAction, props} from "@ngrx/store";

export interface MasterClassCardsParam{
    skip: number,
    limit: number,
    superGroupId?:string,
    courseIds?:string,
    lessonType:string,
    isRecommended?:boolean,
    goalIds?:string,
    purchaseType?:string,
    isSkillCourse?:boolean,
}

export const getSuperGroups = createAction('[MasterClass] getSuperGroups',props<{isSkillCourse?:any}>());
export const superGroupsLoaded = createAction('[MasterClass] superGroupsLoaded', props<{payload: any}>());

export const getSuperGroupsDetails = createAction('[MasterClass] getSuperGroupsDetails');
export const superGroupsDetailsLoaded = createAction('[MasterClass] superGroupsDetailsLoaded', props<{payload: any}>());

export const setSelectedSuperGroups = createAction('[MasterClass] setSelectedSuperGroups',props<{superGroupId:string}>());


export const getMasterClassCards = createAction('[MasterClass] getMasterClassCards',props<{params:MasterClassCardsParam}>())
export const masterClassCardsLoaded = createAction('[MasterClass] masterClassCardsLoaded',props<{masterClassCardsDetails:any,id:string,lessonType?:string,superGroupId?:string,skip?:number,limit?:number,isRecommended?:boolean, purchaseType?:string}>())

export const getPastMasterClass = createAction('[MasterClass] getPastMasterClass',props<{params:any}>())
export const pastMasterClassLoaded=createAction('[MasterClass] pastMasterClassLoaded',props<{pastMasterClassDetails:any,superGroupId?:string,skip?:number,limit?:number}>())

export const getOngoingMasterSeries = createAction('[MasterClass] Get Ongoing Master Series', props<{params:{parentId:string, skip?:number, limit?:number,isRecommended?:boolean}}>());
export const ongoingMasterSeriesLoaded = createAction('[MasterClass] Ongoing Master Series Loaded',props<{ongoingMasterClassDetails:any, parentId:string, skip?:number, limit?:number,isRecommended?:boolean,showAll?:boolean}>())

export const getLessonData=createAction('[MasterClass] getLessonData',props<{lessonId:string,parentId:string,parentType:string}>())
export const lessonDataLoaded=createAction('[MasterClass] lessonDataLoaded',props<{payload:any}>())