import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { selectAccountBlockedModal, selectStudent } from '@angular-commons/core/application-state/app.selectors';
@Component({
  selector: 'account-block-modal',
  templateUrl: './account-block-modal.component.html',
  styleUrls: ['./account-block-modal.component.scss']
})
  
export class AccountBlockModalComponent implements OnInit {
  
  constructor(
    private store: Store<any>
  ) { }
  
  showModel: boolean=false;

  subscribeModalState() {
    this.store.select(selectAccountBlockedModal).subscribe((response: any) => {
      if (response) {
        this.showModel = response.showWarning;
      }
    });
  }

  ngOnInit(): void {
    this.subscribeModalState();
  }
  
}
