import { Component, OnInit } from '@angular/core';
import {hideFooter, hideHeader, hideStickyFooter} from "@core/application-state/app.actions";
import {Store} from "@ngrx/store";

@Component({
    selector: 'emi-iframe',
    templateUrl: './emi-iframe.component.html',
    styles: ['']
})
export class EmiIframeComponent implements OnInit {
    constructor(private store: Store) {
    }

    ngOnInit(): void {
        this.store.dispatch(hideHeader());
        this.store.dispatch(hideFooter());
        this.store.dispatch(hideStickyFooter());
    }

}
