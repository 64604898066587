import { getDeferredPromise} from "../../../shared/utility/defer-promise";
import {isServer} from "@shared/utility/tb-common";
import {roughSizeOfObject} from "@shared/utility/memory";
import { APIConstants } from "@angular-commons/environments/environment";

const CLIENT_TTL = 2 * 60 * 1000; // 2 mins
const SERVER_TTL =  30 * 60 * 1000; //30 mins
const ALPHA_SERVER_TTL =  0;

export const DEFAULT_CACHE_CONTROLS = {
    optimistic : true,
    alwaysStale : false,
    cacheOnServer: false
};

export interface cacheControls {
    optimistic : boolean,
    alwaysStale : boolean,
    cacheOnServer: boolean //this is unused currently
};


function getApproximateSize(key,obj){
    if(obj.__inFlight__){
        return key.length/10e6;
    }
    return (key.length + roughSizeOfObject(obj))/10e6;
}

class DataStore{
    private data = {};
    public totalSize = 0;
    set(key,value){
        const size = getApproximateSize(key,value);
        const lastUpdatedTime = Date.now();
        const metadata = { size, lastUpdatedTime, lastAccessedTime : 0};
        this.totalSize += size;
        this.data[key] = { value, metadata};
    }
    get(key){
        let value = this.data[key];
        if(value?.metadata){
            value.metadata.lastAccessedTime = Date.now();
        }
        return this.data[key];
    }
    clear(key) {
        const value = this.data[key];
        if(!value){
            return;
        }
        this.totalSize -= value.metadata.size;
        delete this.data[key];
    }
    prune(maxSize){
        //clear all stale data
        let lruList =  Object.keys(this.data).filter( key => {
            if(Cache.isStale(this.data[key])){
                this.clear(key);
                return false;
            }
            return true;
        });
        // clear enough keys to be atleast 10% below limit, clearing the least recently used one first
        if(this.totalSize < maxSize * 0.9){ return; }
        lruList = lruList.sort((a, b) => this.data[a].metadata.lastAccessedTime - this.data[b].metadata.lastAccessedTime);
        for (const item of lruList) {
            this.clear(item);
            if(this.totalSize < maxSize * 0.9){
                break;
            }
        }
    }
}

class EntityStore{
    private entities = {};
    get totalSize(){
        let size = 0;
        Object.keys(this.entities).forEach( key => {
            size += this.entities[key].totalSize;
        });
        return size;
    }
    set(type,id,data){
        if(this.entities[type] === undefined){
            this.entities[type] = new DataStore();
        }
        this.entities[type].set(id,data);
    }
    get(type,id){
        if(!this.entities[type]){
            return undefined;
        }
        return this.entities[type].get(id);
    }
    clear(type, id) {
        this.entities[type].clear(id);
    }
    prune(){ // since there are limited entity flows it's simpler to just get rid of the whole thing
        this.entities = {};
    }
}

export class Cache {
    private entity = new EntityStore();
    private data = new DataStore();

    private static returnIfValid(data ){
        return data || false;
    }
    public checkSizeAndPrune(maxSize = 20){
        let currentSize =  this.data.totalSize + this.entity.totalSize;
        if(!(APIConstants.isProduction || APIConstants.isProdpointed)){
            console.info(`current cache size ${currentSize} `);
        }
        if( currentSize > maxSize){
            this.data.prune(maxSize);
            this.entity.prune();
            currentSize =  this.data.totalSize + this.entity.totalSize;
            console.info(`cache size after pruning ${currentSize}`);
        }
    }

    public static isStale(data) : boolean{
        return (new Date().getTime() - data.metadata.lastUpdatedTime) > ( Cache.getTTL() );
    }

    public static getTTL(){
        if(isServer()){
            return APIConstants.isProduction || APIConstants.isProdpointed ? SERVER_TTL : ALPHA_SERVER_TTL;
        }
        return CLIENT_TTL;
    }

    public getData(key){
       return Cache.returnIfValid(this.data.get(key))
    }

    public getEntity(type,id){
       return Cache.returnIfValid(this.entity.get(type,id));
    }

    public invalidateData(key) {
        let inCache = this.getData(key);
        if(inCache && inCache.value.__inFlight__)
        {
            inCache.value.__inFlight__.reject();
        }
        this.data.clear(key);
    }

    public invalidateEntity(type,id) {
        let inCache = this.getEntity(type,id);
        if(inCache && inCache.value.__inFlight__)
        {
            inCache.value.__inFlight__.reject();
        }
        this.entity.clear(type,id);
    }

    public setData(key,data){
        let inCache = this.getData(key);
        if(inCache && inCache.value.__inFlight__)
        {
            inCache.value.__inFlight__.resolve({value:data , metadata:{ lastUpdatedTime : new Date().getTime() }});
        }
        this.data.set(key,data);
    }

    public setEntity(type,id,data){
        let inCache = this.getEntity(type,id);
        if(inCache && inCache.value.__inFlight__)
        {
            inCache.value.__inFlight__.resolve({value:data , metadata:{ lastUpdatedTime : new Date().getTime() }});
        }
        this.entity.set(type,id,data);
    }

    public waitForData(key){
        let inCache = this.getData(key);
        if(!(inCache && inCache.value.__inFlight__))
        {
            this.data.set(key,{__inFlight__: getDeferredPromise()});
        }
    }

    public waitForEntity(type,id){
        let inCache = this.getEntity(type,id);
        if(!(inCache && inCache.value.__inFlight__))
        {
            this.entity.set(type,id,{__inFlight__: getDeferredPromise()});
        }
    }

    public clear(){
        this.entity = new EntityStore();
        this.data   = new DataStore();
    }
}
