import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthenticationService } from '@core/services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { PlatformService } from '@core/services/platform-service';
import { TranslateService } from "@ngx-translate/core";
import { selectSignupAndVerificationModal } from '@angular-commons/core/application-state/app.selectors';
import { hideMobileSignupAndVerificationModal } from '@angular-commons/core/application-state/app.actions';

@Component({
	selector: 'mobile-signup-and-verification-wrapper',
	templateUrl: './mobile-signup-and-verification-wrapper.component.html',
	styleUrls: ['./mobile-signup-and-verification-wrapper.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class MobileSignupAndVerificationWrapperComponent implements OnInit {
	isMobile = this.platformService.isMobile();
	uiState: any = {
		isSkippable: false,
		showModal: false,
		strings: {
			headerText: ''
		}
	}


	constructor(private store: Store<{}>, private authenticationService: AuthenticationService, private route: ActivatedRoute, private platformService: PlatformService, private translateService: TranslateService) {
	}

	ngOnInit(): void {
		this.store.select(selectSignupAndVerificationModal).subscribe(data => {
			if (data) {
				if (data?.data?.show) {
					this.uiState.isSkippable = data.isSkippable;
					this.uiState.strings.headerText = data.data?.headerText;
				}
				this.uiState.showModal = data.showModal;
			}
		});
	}

	closeModal() {
		this.store.dispatch(hideMobileSignupAndVerificationModal());
	}
}