import {Network} from "@core/services/model-manager/main";

export class GetPersonalisedBannerDataApi {
    static apiEndpoint = '/v1/location-popup';
    static  __projection = 1;
    static apiCall(network: Network, params:any) {
        return network.get(GetPersonalisedBannerDataApi.apiEndpoint, params,);
    }
}

