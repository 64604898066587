import {Injectable} from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    ActivatedRoute,
    UrlTree
} from '@angular/router';
import {Observable, of} from "rxjs";
import {Store} from "@ngrx/store";

import {PlatformService} from "@core/services/platform-service";



@Injectable()
export class MasterExploreGuard implements CanActivate {

    constructor(private router:Router,
                private route: ActivatedRoute,
                private store: Store<{}>,
                private platformService: PlatformService) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean|UrlTree> {

        if(!this.platformService.isLoggedIn() && state.url.includes('/recommended')){
            let url=`/login?redirect_url=`+encodeURI(state.url)
            this.platformService.redirect(url,302);
            return;
        }

        return of(true)
    }

}