
import {hideRequestCallBackModal, showAlert} from '@angular-commons/core/application-state/app.actions';
import { isServer } from '@angular-commons/shared/utility/tb-common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Validator as _ } from "@shared/utility/validator";
import { BannerMeta } from '../../features/request-callback/request-callback.component';
import { ActivatedRoute } from '@angular/router';
import {selectRequestCallBackModal} from "@core/application-state/app.selectors";
import {PlatformService} from "@core/services/platform-service";
import { Course } from '@angular-commons/models/entity/classes/classes.adapter';


@Component({
  selector: 'book-counselling-session',
  templateUrl: './book-counselling-session.component.html',
  styleUrls: ['./book-counselling-session.component.scss']
})
export class BookCounsellingSessionComponent implements OnInit {

  @Input() bannerMeta: BannerMeta;
  @Input() isForCoaching: Boolean = false;
  @Input() coldbootParams;
  @Input() cssModifierClass?: string = '';
  @Input() isInModal = false;
  @Input() goal: any;
  @Input() course:Course;
  @Input() showDynamicComponent = false;
  @Input() description:string;
  @Input() icon;
  @Input() ctaText;
  @Input() title:string;
  @Input() from:string = '';
  isNotPlutus:boolean = this.platformService.currentProjectName() != 'plutus';

  mobile: string;
  isServer = isServer();
  coldbootsub: any;
  showModal = false;
  isMobile: boolean = this.platformService.isMobile();
  @Output() requestCallbackEvent:EventEmitter<string> = new EventEmitter();

  constructor(private store: Store<{}>, private activatedRoute: ActivatedRoute, private platformService: PlatformService) {
    this.store.select(selectRequestCallBackModal).subscribe((data: any) => {
      if (data){
        this.showModal = data.show;
      }
    });
  }

  ngOnInit(): void {
    this.mobile = this.bannerMeta.mobile;
    if(this.activatedRoute.snapshot.queryParams['requestCallback']){
      this.activatedRoute.queryParams.subscribe(params => {
        this.mobile = params['requestCallback'];
        this.requestCallbackEvent.emit(this.mobile);
      });
    }

    if (!this.isServer) {
      this.coldbootsub = this.coldbootParams && this.coldbootParams.then((params)=>{
        switch(params.awaitId){
          case "changenumbercta":
            this.changeNumber();
            break;
          case "requestcallbackcta":
            this.requestCallback();
            break;
        }
      })
    }
  }

  requestCallback() {
    if (!_.isAValidMobile(this.mobile)) {
      this.store.dispatch(showAlert({message: 'Invalid Number! Please enter 10 digit mobile number starting with 6,7,8 or 9 only'}))
      return;
    }
    this.requestCallbackEvent.emit(this.mobile);
  }

  changeNumber() {
    this.bannerMeta.submitted = false;
  }

  mobileKeys($event: any) {
    let key = ($event.key || '').toLowerCase();
    // prevent keys
    if (key.length === 1 && (key.match(/[^0-9]/g) || this.mobile.length >= 10) && (!$event.ctrlKey && !$event.metaKey) ) {
      $event.preventDefault();
    }
    // enforce for cases like copy paste
    this.mobile = this.mobile.replace(/[^0-9]/g, '');
    if (this.mobile.length >= 10) {
      this.mobile = this.mobile.slice(0, 10);
    }
  }

  closeModal(){
    this.store.dispatch(hideRequestCallBackModal());
  }

}
