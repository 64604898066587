import {Injectable} from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    ActivatedRoute,
    UrlTree
} from '@angular/router';
import {Observable, of} from "rxjs";
import {Store} from "@ngrx/store";

import {PlatformService} from "@core/services/platform-service";
import {isServer} from "@shared/utility/tb-common";
import {getQueryStringFromParams} from "@shared/utility/url";
import {TransferStateManager} from "@core/services/transferStateManager";
import {getSelectedTab} from "@core/application-state/app.actions";
import {selectAutoSelectTabData} from "@core/application-state/app.selectors";
import {productTypeFromUrlMap} from "@shared/utility/constants";


@Injectable()
export class PassPageGuard implements CanActivate {

    constructor(private router:Router,
                private route: ActivatedRoute,
                private store: Store<{}>,
                private platformService: PlatformService,
                private transferStateManager: TransferStateManager) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean|UrlTree> {

        let pathname = state.url.split('?')[0]
        // smart /my redirection
        let shouldBeSlashMy = this.platformService.isLoggedIn() && !pathname.endsWith("/my");
        let shouldBePassPage = !this.platformService.isLoggedIn() && pathname.endsWith("/my");
        if(shouldBeSlashMy || shouldBePassPage){
            let path="/pass";
            if(route.params["refLink"]){
                path=`/invite/${route.params["refLink"]}`
            }
            let url = path + getQueryStringFromParams(route.queryParams);
            if(shouldBeSlashMy){
                url = `${path}/my` + getQueryStringFromParams(route.queryParams);
            }
            if(isServer()){
                this.platformService.redirect(url,302);
            } else {
                if(!route.queryParams['tab']){
                    this.redirectWithTabQp(url, route);
                } else {
                    this.router.navigateByUrl(url);
                }
            }
            return;
        } else if(!route.queryParams['tab'] && !isServer()){
            this.redirectWithTabQp(pathname, route);
        } else {
            return of(true);
        }
    }

    redirectWithTabQp(pathname, route){
        this.transferStateManager.get(
            'autoSelectTabData',
            getSelectedTab({commonDataType: 'defaultSelectedPassTab'}),
            this.store.select(selectAutoSelectTabData),
            (value) => {
                if(value){
                    let tab = productTypeFromUrlMap[value?.tab?.toLowerCase()] || 'passPro';
                    let qp = getQueryStringFromParams(route.queryParams, {tab});
                    if(isServer()){
                        this.platformService.redirect(pathname+qp,302);
                    } else {
                        this.router.navigateByUrl(pathname+qp);
                    }
                }
            })
    }

}
