import { showAlert } from '@angular-commons/core/application-state/app.actions';
import { isServer } from '@angular-commons/shared/utility/tb-common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Validator as _ } from "@shared/utility/validator";
import { BannerMeta } from '../../features/request-callback/request-callback.component';

@Component({
  selector: 'request-course-batch',
  templateUrl: './request-course-batch.component.html',
  styleUrls: ['./request-course-batch.component.scss']
})
export class RequestCourseBatchComponent implements OnInit {

  @Input() bannerMeta: BannerMeta;
  @Input() coldbootParams;
  mobile: string;
  isServer = isServer();
  coldbootsub: any;
  @Output() requestCallbackEvent:EventEmitter<string> = new EventEmitter();
  showBannerInput: boolean = false;
  constructor(private store: Store<{}>) { }

  ngOnInit(): void {
    this.mobile = this.bannerMeta.mobile;

    if (!this.isServer) {
      this.coldbootsub = this.coldbootParams && this.coldbootParams.then((params)=>{
        switch(params.awaitId){
          case "requestnewbatchcta":
            this.setShowBannerInput(true);
            break;
          case "exploreothercoursescta":
            this.goToSelectPage();
            break;
          case "requestcallbackcta":
            this.requestCallback();
            break;
        }
      })
    }
  }

  requestCallback() {
    if (!_.isAValidMobile(this.mobile)) {
      this.store.dispatch(showAlert({message: 'Invalid Number! Please enter 10 digit mobile number starting with 6,7,8 or 9 only'}))
      return;
    }
    this.requestCallbackEvent.emit(this.mobile);
  }

  changeNumber() {
    this.bannerMeta.submitted = false;
  }

  setShowBannerInput(state) {
    this.showBannerInput = state;
  }

  goToSelectPage() {
    window.location.href = '/select';
  }

}
