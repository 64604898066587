import {isServer} from "@shared/utility/tb-common";


// use ONLY on server to inject some js in window for initial state
export function addInternalScript(scriptText:string,_document){
    if(!isServer()){ return; }
    const body = <HTMLBodyElement> _document.body;
    const script = _document.createElement('script');
    script.innerHTML = scriptText;
    body.appendChild(script);
    return script;
}

export function loadScript(url: string,_document=document) {
    let test = _document.querySelector(`[src='${url}']`);
    if(test) {
        if(!isServer()){
            setTimeout(() => { test.dispatchEvent(new Event("load")); },0);
        }
        return test;
    }

    const body = <HTMLBodyElement> _document.body;
    const script = _document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
    return script;
}

export function loadStyle(url: string,_document=document) {
    let test = _document.querySelector(`[href='${url}']`);
    if(test) {
        if(!isServer()){
            setTimeout(() => { test.dispatchEvent(new Event("load")); },0);
        }
        return test;
    }
    const body = <HTMLBodyElement> _document.body;
    const style = _document.createElement('link');
    style.innerHTML = '';
    style.rel="stylesheet";
    style.type="text/css";
    style.href = url;
    body.appendChild(style);
    return style;
}
