import {arrayModel, propertyMaps, SourceProjectionMapper} from "@models/mapping-framework";
import {Network} from "@core/services/model-manager/main";
import {baseModel, setupModel} from "@models/types";
import {getDateByFormat} from "@shared/utility/date-utilities";


export function transformPublishDate(date) {
   return getDateByFormat('20' + date, 'long', 'long');
}

export class CurrentAffairsNote extends baseModel{
    @propertyMaps('_id')
    public id;
    @propertyMaps('note')
    public news;
    @propertyMaps('image')
    public image;
    @propertyMaps('video')
    public video;
    @propertyMaps('servesOn', transformPublishDate)
    public publishDate;
    @propertyMaps('languages')
    public languages;
}
setupModel(CurrentAffairsNote, {});


export class CurrentAffairsNotes extends baseModel{
    @propertyMaps('notes', arrayModel(CurrentAffairsNote), CurrentAffairsNote)
    public notes;
    @propertyMaps('count')
    public count;
}
setupModel(CurrentAffairsNotes, {});

export class DailyCurrentAffairsApi {
    static apiEndpoint = 'v1/current-affairs/daily';

    static projection;
    static get  __projection(){ 
        if(!DailyCurrentAffairsApi.projection){
            DailyCurrentAffairsApi.projection = JSON.stringify(new SourceProjectionMapper(CurrentAffairsNotes).map());
        }
        return DailyCurrentAffairsApi.projection;
    }

    static apiCall(network: Network, params: any) {
        return network.get(DailyCurrentAffairsApi.apiEndpoint, {...params,isCurDateEmpty :true, __projection: DailyCurrentAffairsApi.__projection});
    }
}

export class MonthlyCurrentAffairsApi {
    static apiEndpoint = 'v1/current-affairs/monthly';

    static projection;
    static get  __projection(){ 
        if(!MonthlyCurrentAffairsApi.projection){
            MonthlyCurrentAffairsApi.projection = JSON.stringify(new SourceProjectionMapper(CurrentAffairsNotes).map());
        }
        return MonthlyCurrentAffairsApi.projection;
    }

    static apiCall(network: Network, params: any) {
        return network.get(MonthlyCurrentAffairsApi.apiEndpoint, {...params, __projection: MonthlyCurrentAffairsApi.__projection});
    }
}
