<div class="pro-aside {{cssModifierClass}}" *ngIf="!showComparisonBenefits">
    <div *ngIf="showHeading" class="feature-header">
        <div *ngIf="!hasPassAccess && !hasProAccess" class="h2">
            Unlock All Pro benefits With
            <img loading="lazy" src="/assets/img/pass-new/pass-pro-light-icon.svg?v=05092023" alt="pass-pro">
        </div>
        <div *ngIf="hasPassAccess && !hasProAccess" class="h2">
            Upgrade to
            <img loading="lazy" src="/assets/img/pass-new/pass-pro-light-icon.svg?v=05092023" alt="pass-pro">
            to Unlock All Pro Benefits
        </div>
        <div *ngIf="hasProAccess" class="h2">
            <img src="/assets/img/pass-new/pass-pro-light-icon.svg?v=05092023" alt="pass-pro">
        </div>
        <div class="h5 desktop">Pass Pro Benefits -</div>
    </div>
    <div class="pro-ribbon">
        <span class="badge badge-pro-dark">PRO</span>Subscription gives you access to
    </div>
    <div class="pro-aside__content">
        <ng-container *ngIf="passPitch?.title">
            <div class="flex-icon flex-icon--test">
                <img loading="lazy" *ngIf="iconType === 'light'" [src]="passPitch?.lightIconUrl" alt="icon">
                <img loading="lazy" *ngIf="iconType === 'dark'" [src]="passPitch?.darkIconUrl" alt="icon">
                <div [innerHTML]="passPitch?.pitchTitle"></div>
            </div>
        </ng-container>
        <div class="flex-icon flex-icon--bdr" *ngIf="benefits?.length">
            <span class="badge badge-pro-bdr">Also Includes</span>
        </div>
        <div class="flex-icon" *ngFor="let benefit of benefits; let i = index">
            <img loading="lazy" *ngIf="iconType === 'light'" [src]="benefit?.lightIconUrl" alt="icon">
            <img loading="lazy" *ngIf="iconType === 'dark'" [src]="benefit?.darkIconUrl" alt="icon">
            <div [innerHTML]="benefit?.pitchTitle"></div>
        </div>
    </div>
</div>

<div class="container-bg--golden" *ngIf="showComparisonBenefits">
    <div class="wrapper wrapper--product">
        <div class="card-caption">
            <div class="h2 caption-border">
                Why is <strong class="text-pro">Testbook Pass Pro</strong> a must-have?
            </div>
        </div>
        <div class="card">
            <div class="grid-icon" *ngFor="let benefit of benefits; let i = index">
                <img loading="lazy" *ngIf="iconType === 'light'" [src]="benefit?.lightIconUrl" alt="icon">
                <img loading="lazy" *ngIf="iconType === 'dark'" [src]="benefit?.darkIconUrl" alt="icon">
                <div class="h3" [innerHTML]="benefit?.pitchOnlyTitle"></div>
                <p>{{benefit?.subTitle}}</p>
            </div>
        </div>
    </div>
</div>
