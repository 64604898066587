import { Injectable} from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    ActivatedRoute,
    UrlTree
} from '@angular/router';
import {Observable, ReplaySubject, of} from "rxjs";
import {Store} from "@ngrx/store";
import {PlatformService} from "@core/services/platform-service";
import {isServer} from "@shared/utility/tb-common";
import {getQueryStringFromParams} from "@shared/utility/url";
import { TransferStateManager } from '@angular-commons/core/services/transferStateManager';
import { getTestSeriesDetails } from '../route-modules/test-series/application-state/test-series.actions';
import { selectTestSeriesData } from '../route-modules/test-series/application-state/test-series.selector';
import { TransferState, makeStateKey } from '@angular/platform-browser';


@Injectable()
export class TestSeriesGuard implements CanActivate {
    transferStateValue:any;
    isBranchExist:boolean=false;
    testSeriesBranchKey:any;
    isLoggedIn = this.platformService.isLoggedIn();

    constructor(private router:Router,
                private route: ActivatedRoute,
                private store: Store<{}>,
                private transferState: TransferState,
                private transferStateManager : TransferStateManager,
                private platformService: PlatformService) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean|UrlTree> {

        let idOrSlug = route.params.idOrSlug;
        
        if(idOrSlug === "online") {
            this.platformService.redirect('/online-test-series',301);
            return;
        }

        let pathname = state.url.split('?')[0]
        // smart /my redirection
        let shouldBeSlashMy = this.platformService.isLoggedIn() && !pathname.endsWith("/my");
        let shouldBeTestSeries = !this.platformService.isLoggedIn() && pathname.endsWith("/my");
        if(shouldBeSlashMy || shouldBeTestSeries){
            let url = `/${idOrSlug}/test-series` + getQueryStringFromParams(route.queryParams);
            if(shouldBeSlashMy){
                url = `/${idOrSlug}/test-series/my` + getQueryStringFromParams(route.queryParams);
            }
            if(isServer()){
                this.platformService.redirect(url,302);
            } else {
                this.router.navigateByUrl(url);
            }
            return;
        }

        if(idOrSlug) {
            this.testSeriesBranchKey = makeStateKey(`testSeriesBranchExist_${idOrSlug}`);
            this.isBranchExist =  this.transferState.get(this.testSeriesBranchKey,false)
            const sub = new ReplaySubject<boolean>();
            this.transferStateValue = this.transferStateManager.get(`testSeries_${idOrSlug}`,
            getTestSeriesDetails({idOrSlug: idOrSlug}), this.store.select(selectTestSeriesData),
            (item)=>{
                if(!(item.details && item.details.slug)) {
                this.platformService.gotoErrorPage();
                sub.next(false);
                sub.complete();
                } else {
                    if(idOrSlug) {
                        this.transferState.set(this.testSeriesBranchKey, item?.details?.branches?.values?.length > 0);
                        sub.next(true);
                        sub.complete();
                    }
                }
                if(this.transferStateValue){
                    this.transferStateValue.unsubscribe()
                }
            },this.isBranchExist || this.isLoggedIn);
            return sub;
        }
        return of(true);
    }

}
