import {baseModel, setupModel} from "@models/types";
import {arrayModel, objModel, propertyMaps, SourceProjectionMapper} from "@models/mapping-framework";
import {filterObjectKeys, Validator as _} from "@shared/utility/validator";
import {Network} from "@core/services/model-manager/main";
import {AllowedPaymentPartners, Emi} from "@models/entity/passes/passes.adapter";

export class Coupon extends baseModel{
    @propertyMaps('shortDesc', _.str)
    public shortDesc;

    @propertyMaps('code', _.str)
    public code;
    @propertyMaps('expiresOn', _.str)
    public expiresOn;
}
setupModel(Coupon, {type: Coupon});

export class Offers extends baseModel{
    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('title', _.str)
    public title;

    @propertyMaps('productId', _.str)
    public productId;

    @propertyMaps('productName', _.str)
    public productName;

    @propertyMaps('productType', _.str)
    public productType;
}
setupModel(Offers, {type: Offers});

export class Subscription extends baseModel{
    @propertyMaps('title', _.str)
    public title;

    @propertyMaps('_id', _.str)
    public id;

    @propertyMaps('type', _.str)
    public type;

    @propertyMaps('oldCost', _.num)
    public oldCost;

    @propertyMaps('minCost', _.num)
    public minCost;

    @propertyMaps('cost', _.num)
    public cost;

    @propertyMaps('offers', objModel(Offers), Offers)
    public offers;

    @propertyMaps('allowedPaymentPartners', arrayModel(AllowedPaymentPartners), AllowedPaymentPartners)
    public allowedPaymentPartners;

    @propertyMaps('emis', arrayModel(Emi), Emi)
    public emis;
}
setupModel(Subscription, {type: Subscription});

export class PurchaseState extends baseModel{
    @propertyMaps('subscription', objModel(Subscription), Subscription)
    public subscription;

    @propertyMaps('coupon', objModel(Coupon), Coupon)
    public coupon;

    @propertyMaps('showComponent', _.bool)
    public showComponent;

    @propertyMaps('discountedCost', _.num)
    public discountedCost;
}
setupModel(PurchaseState, {type: 'PurchaseState'});

export class PurchaseStateApi{
    static apiEndpoint = 'v1/goals/purchase-state';

    static projection;
    static get  __projection(){ 
        if(!PurchaseStateApi.projection){
            PurchaseStateApi.projection = JSON.stringify(new SourceProjectionMapper(PurchaseState).map());
        }
        return PurchaseStateApi.projection;
    }

    static apiCall(network: Network, params: any){
        const _params = filterObjectKeys(params, ['type']);
        return network.get(PurchaseStateApi.apiEndpoint, { __projection: PurchaseStateApi.__projection, ..._params});
    }
}
