import {Network} from "@core/services/model-manager/main";

//This is a special, general purpose api that is meant to give generic JSON
export class commonDataApi {
    static apiEndpoint = 'v1/common/data';
    static  __projection = 1;
    static apiCall(network:Network, _params:any){
        let params = {..._params}
        if(params?.commonDataType){
            params.type = params.commonDataType;
        }
        return network.get(commonDataApi.apiEndpoint,{...params,__projection:commonDataApi.__projection});
    }
}